<template>
  <div class="constraints hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Contraintes</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToConstraint">Ajouter une contrainte</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES PACKS -->
    <div class="body">
      <p class="description">La contrainte permet de contrôler l’affichage des options, des bases ou de frais annexes en fonction de certaines caractéristiques sur la version ou le terrain</p>
      <app-table :headers="tableHeaders" :data="constraints" :loading="isLoading" @line-cliked="goToConstraint" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import constraintApi from '@/services/api/constraint';

export default {
  name: 'constraints',
  metaInfo: {
    title: 'Contraintes',
  },
  data() {
    return {
      constraints: [],
      tableHeaders: [
        { label: 'Nom de la contrainte', key: 'name', size: 3 },
      ],
      isLoading: true,
      isCreateModalOpen: null,
    };
  },
  mounted() {
    this.getConstraints();
  },
  methods: {
    async getConstraints() {
      this.isLoading = true;
      try {
        this.constraints = await constraintApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des contraintes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    goToConstraint(constraint) {
      if (constraint && constraint.constraintId) {
        this.$router.push({ name: 'styles-constraints-constraintId', params: { constraintId: constraint.constraintId } });
      } else {
        this.$router.push({ name: 'styles-constraints-new' });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.constraints
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .description
    @include description
</style>
