<template>
  <div class="constraint hedifys-21">
    <form ref="constraint" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="constraint && constraint.name && constraint.constraintId"
            :items="[
              { route: { name: 'styles-constraints'}, name: 'contraintes'},
              { route: { name: 'styles-constraints-constraintId', params: { constraintId: $route.params.constraintId }}, name: constraint.name }
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <div v-if="!isLoading">
            <h1 v-if="$route.params.constraintId">{{ constraint.name }}</h1>
            <h1 v-else>Ajouter une contrainte</h1>
          </div>
        </div>
        <div class="cell shrink" v-if="$route.params.constraintId">
          <app-button theme="warning" size="large" @click="openDeleteModal" :disabled="isLoading">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit" :disabled="isLoading">{{ $route.params.constraintId ? 'Sauvegarder' : 'Ajouter' }}</app-button>
        </div>
      </div>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <div class="grid-x">
            <div class="cell auto">
              <h3>Nom</h3>
            </div>
            <div class="cell auto">
              <h3>Conditions</h3>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto name">
              <app-input v-model="constraint.name" label="Nom de la contrainte" placeholder="Nom..." required />
            </div>
            <div class="cell auto">
              <p class="conditions">Les options selectionnées sont affichées si les conditions suivantes sont respectées :</p>
              <app-select v-model="constraintCondition" :options="conditions" required />
            </div>
          </div>
        </div>
        <div class="card">
          <h3>Options concernées</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell shrink">
              <app-search size="small" v-model="search" placeholder="Rechercher" />
            </div>
            <!-- FILTER MODAL -->
            <div class="cell shrink button">
              <filter-modal
                @change="filterOptions"
                @reset="resetFilter"
                :numberOfInputs="Object.values(filters).filter(i => !!i).length"
              >
                <div class="grid-x grid-margin-x row">
                  <div class="cell">
                    <app-select
                      label="Catégorie"
                      v-model="filters.categoryId"
                      @input="resetLotOptions"
                      :options="categoryOptions"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x row">
                  <div class="cell">
                    <app-select
                      label="Lot"
                      v-model="filters.lotId"
                      @input="resetThemeOptions"
                      :options="lotOptions"
                      :disabled="lotOptions.length === 0"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x row">
                  <div class="cell">
                    <app-select
                      label="Thème"
                      v-model="filters.themeId"
                      :options="themeOptions"
                      :disabled="themeOptions.length === 0"
                    />
                  </div>
                </div>
              </filter-modal>
            </div>
          </div>
          <div class="options grid-x row grid-margin-x">
            <div class="cell auto">
              <app-table :headers="tableHeaders" :data="filteredOptions" :loading="isLoading" small>
                <template slot="loading">
                  <app-spinner />
                </template>
                <template slot="empty-table">
                  <p>Aucune données de disponible</p>
                </template>
                <template slot="isIncluded" slot-scope="{ data }">
                  <app-checkbox
                    v-model="selectedOptions"
                    :value="data.optionId"
                  />
                </template>
                <template slot="name" slot-scope="{ data }">
                  <strong>{{ data.name }}</strong>
                </template>
              </app-table>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import constraintApi from '@/services/api/constraint';
import optionApi from '@/services/api/option';
import categoryApi from '@/services/api/category';
import lotApi from '@/services/api/lot';
import themeApi from '@/services/api/theme';
import constraintsConditions from '@/services/data/constraintsConditions.json';
import utils from '@/services/utils/utils';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import FilterModal from '@/components/general/FilterModal.vue';

export default {
  name: 'constraint',
  components: {
    Breadcrumb,
    FilterModal,
  },
  metaInfo() {
    return {
      title: this.constraint.name ? `${this.constraint.name} – Contrainte` : 'Contrainte',
    };
  },
  data() {
    return {
      constraint: {
        name: null,
        conditions: [],
        type: null,
      },
      conditions: [],
      constraintCondition: null,
      filters: {
        categoryId: null,
        themeId: null,
        lotId: null,
      },
      isLoading: null,
      tableHeaders: [
        { label: '', key: 'isIncluded', size: 1 },
        { label: 'Option', key: 'name', size: 10 },
      ],
      options: [],
      selectedOptions: [],
      filteredOptions: [],
      categories: [],
      categoryOptions: [],
      lots: [],
      lotOptions: [],
      themes: [],
      themeOptions: [],
      search: null,
    };
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    $route() {
      if (!this.isLoading) {
        this.getData();
      }
    },
    filters: {
      handler(value) {
        this.filters.categoryId = value.categoryId;
        this.filters.lotId = value.lotId;
        this.filters.themeId = value.themeId;
      },
      deep: true,
    },
    'filters.categoryId': {
      handler() {
        this.lotOptions = this.getLotOptions();
        this.themeOptions = this.getThemeOptions();
      },
    },
    constraintCondition(e) {
      const condition = constraintsConditions.find((el) => el.name === e);
      this.constraint.conditions = condition.details;
      this.constraint.type = condition.type;
    },
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.filterOptions, 500);
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getConstraint();
      await this.getOptions();
      await this.getCategories();
      await this.getLots();
      await this.getThemes();
      this.categoryOptions = utils.formatOptions(
        this.categories,
        (o) => o.categoryId,
        (o) => o.name,
        'choose',
      );
      this.lotOptions = await this.getLotOptions();
      this.themeOptions = await this.getThemeOptions();
      this.conditions = utils.formatOptions(
        constraintsConditions,
        (c) => c.name,
        (c) => c.label,
      );
      this.isLoading = false;
    },
    async getConstraint() {
      try {
        if (this.$route.params.constraintId) {
          this.constraint = await constraintApi.getById(this.$route.params.constraintId);
          const constraintMatches = constraintsConditions.find((el) => JSON.stringify(el.details) === JSON.stringify(this.constraint.conditions));
          this.constraintCondition = constraintMatches ? constraintMatches.name : null;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des contraintes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getOptions() {
      try {
        this.options = await optionApi.getAll();
        this.filteredOptions = this.options;
        if (this.$route.params.constraintId) {
          const options = await optionApi.getByConstraint(this.$route.params.constraintId);
          this.selectedOptions = options.map((el) => el.optionId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des options',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCategories() {
      try {
        this.categories = await categoryApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLots() {
      try {
        this.lots = await lotApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getThemes() {
      try {
        this.themes = await themeApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des thèmes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    getLotOptions() {
      if (this.filters.categoryId && this.filters.categoryId !== 'choose') {
        return utils.formatOptions(
          this.lots.filter((lotOption) => (lotOption.categoryId === this.filters.categoryId)),
          (o) => o.lotId,
          (o) => o.name,
          'choose',
        );
      }
      return utils.formatOptions(
        this.lots,
        (o) => o.lotId,
        (o) => o.name,
        'choose',
      );
    },
    getThemeOptions() {
      if (this.filters.lotId && this.filters.lotId !== 'choose') {
        return utils.formatOptions(
          this.themes.filter((themeOption) => (themeOption.lot.lotId === this.filters.lotId)),
          (o) => o.themeId,
          (o) => o.name,
          'choose',
        );
      }
      return utils.formatOptions(
        this.themes,
        (o) => o.themeId,
        (o) => o.name,
        'choose',
      );
    },
    filterOptions() {
      this.filteredOptions = this.options;
      if (this.search && this.search.length) {
        this.filteredOptions = this.filteredOptions.filter((o) => o.name && o.name.toLowerCase().includes(this.search.toLowerCase()));
      }
      if (this.filters.categoryId) {
        this.filteredOptions = this.filteredOptions.filter((o) => o.category.categoryId === this.filters.categoryId);
      }

      if (this.filters.themeId) {
        this.filteredOptions = this.filteredOptions.filter((o) => o.theme.themeId === this.filters.themeId);
      }

      if (this.filters.lotId) {
        this.filteredOptions = this.filteredOptions.filter((o) => o.lot.lotId === this.filters.lotId);
      }
    },
    resetFilter() {
      this.closeFilterModal();
      this.search = null;
      this.filteredOptions = this.options;
    },
    resetLotOptions() {
      this.filters.lotId = undefined;
    },
    resetThemeOptions() {
      this.filters.themeId = undefined;
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.$route.params.constraintId) {
          await constraintApi.update(this.$route.params.constraintId, {
            name: this.constraint.name,
            conditions: this.constraint.conditions,
            type: this.constraint.type,
          });
          await optionApi.updateConstraint(this.$route.params.constraintId, {
            options: this.selectedOptions,
          });
          this.$notification.show({ text: 'Cette contrainte a été mise à jour avec succès !' });
          this.getData();
        } else {
          const constraint = await constraintApi.create(this.constraint);
          await optionApi.updateConstraint(constraint.constraintId, {
            options: this.selectedOptions,
          });
          this.$notification.show({ text: 'Cette contrainte a été créée avec succès !' });
          this.$router.push({ name: 'styles-constraints-constraintId', params: { constraintId: constraint.constraintId } });
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: `Il y a eu un problème lors de la sauvergarde du constraint. ${er.message}`,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer la contrainte',
        text: 'Êtes vous sûr de vouloir supprimer cette contrainte ?',
        confirmText: 'Supprimer la contrainte',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteConstraint();
        },
      });
    },
    async deleteConstraint() {
      if (this.$route.params.constraintId) {
        this.isLoading = true;
        try {
          const { constraintId } = this.$route.params;
          await constraintApi.delete(constraintId);
          this.$notification.show({ text: 'Cette contrainte a été supprimé avec succès !' });
          this.$router.push({ name: 'styles-constraints' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression de la contrainte',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.constraint
  .body
    @include screen
    .options
      min-height: 345px
      .data
        flex: auto
  form > .header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  .conditions
    @include details
    margin-bottom: 1rem
  .name
    display: flex
    align-items: flex-end
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
</style>
