import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère l'ensemble des rubriques
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/document-categories`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une rubrique via son id
 */
const getOneById = async (categoryId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/document-categories/${categoryId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const documentCategories = {};
documentCategories.getAll = getAll;
documentCategories.getOneById = getOneById;

export default documentCategories;
