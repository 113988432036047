import axios from 'axios';
import ls from 'local-storage';
import member from './api/member';

// Utilisation de require pour contourner un bug de webpack
const router = require('../router');

const { VUE_APP_API_URL } = process.env;
let TOKEN = ls('TOKEN') || false;

// Token du local storage
if (TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;
}

/* eslint no-param-reassign: "error" */
axios.interceptors.request.use((config) => {
  config.withCredentials = true;
  return config;
});

// Récupération par axios d'une 401 (unauthorized) et redirection login
axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    ls.remove('TOKEN');

    if (router.default.currentRoute.path === '/connexion' && ['Wrong username/password combination', 'Related brand not found'].includes(error.response.data.message)) {
      return Promise.reject(error);
    }

    router.default.push({ path: '/connexion' });
    return new Promise(() => {});
  }

  return Promise.reject(error);
});

// Récupération du token
function getToken() {
  return ls('TOKEN');
}

// L'utilisateur est-il loggué
function isLoggedIn() {
  return !!getToken();
}

// Login
const login = async (email, password) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/login`, { email, password });
    TOKEN = response.data.token;
    ls('TOKEN', TOKEN);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;
    return response.data;
  } catch (er) {
    throw er;
  }
};

// Login
const loginAs = async (memberId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/login/impersonate`, { memberId });
    TOKEN = response.data.token;
    ls('TOKEN', TOKEN);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;
    return response.data;
  } catch (er) {
    throw er;
  }
};

// Logout
const logout = () => {
  ls.remove('TOKEN');
  router.default.push({ name: 'login' });
};

// Brand
const getBrand = async () => {
  let brandId = ls('BRAND');
  if (!brandId) {
    const me = await member.getMe();
    /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
    brandId = me.brandId;
  }
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/brands/${brandId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

// Brand
const getPublicBrand = async (brandId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${brandId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

function getBrandId() {
  return ls('BRAND');
}

// Vérification de la validité de la double authentification
const isTwoFactorAuthentified = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/auth/two-factor/validity`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

// Envoi du code de double authentification
const sendTwoFactorCode = async () => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/two-factor/send-code`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

// Vérification du code de double authentification@
const verifyTwoFactorCode = async (code) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/two-factor/verify-code`, { code });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const auth = {};
auth.login = login;
auth.loginAs = loginAs;
auth.logout = logout;
auth.getToken = getToken;
auth.isLoggedIn = isLoggedIn;
auth.getBrand = getBrand;
auth.getBrandId = getBrandId;
auth.getPublicBrand = getPublicBrand;
auth.isTwoFactorAuthentified = isTwoFactorAuthentified;
auth.sendTwoFactorCode = sendTwoFactorCode;
auth.verifyTwoFactorCode = verifyTwoFactorCode;

export default auth;
