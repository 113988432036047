<template>
  <div class="dropdown-actions">
    <a
      class="dropdown-link"
      v-if="!onlyDropdown"
      :class="titleStyle"
      @click.prevent="toggle()">
      <div class="container">
        {{ title }}
        <span class="dots"></span>
      </div>
    </a>
    <ul
      v-if="isOpen && actions.length > 0"
      class="content"
      :style="{
        top: position ? position.y : 'initial',
        left: position ? position.x : 'initial',
        position: position ? 'absolute' : 'initial',
        width: position ? '500px' : 'fit-content',
        }">
      <li v-for="(action, index) in actions" :key="`action-${index}`">
        <a @click="onClick(action)">
          {{ action.title ? action.title : '' }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
/*
  Simple dropdown avec callback,
  les items sont passés en paramêtres avec les différentes actions provoquées par le clique
*/
export default {
  name: 'dropdown',
  props: {
    title: {
      type: String,
    },
    titleStyle: {
      type: String,
    },
    actions: {
      type: Array,
    },
    icon: {
      type: String,
    },
    initialValue: {
      type: Boolean,
    },
    onlyDropdown: {
      type: Boolean,
    },
    position: {
      type: Object,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (this.initialValue === true || this.initialValue === false) {
      this.isOpen = this.initialValue;
    }
    setTimeout(() => {
      window.addEventListener('click', this.close);
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.close);
    this.isOpen = false;
  },
  methods: {
    onClick(item) {
      this.isOpen = false;
      item.callback();
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    close(event) {
      if (!this.$el.contains(event.target) && !this.onlyDropdown) {
        this.isOpen = false;
        this.$emit('dropdownClosed');
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown-actions {
  width: 100%;
  height: 100%;
  > a {
    text-decoration: none;
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 15px;
  }
  .container {
    text-align: center;
    width: 100%;
  }
  .content {
    z-index: 5;
    position: absolute;
    margin: 3px 0;
    white-space: nowrap;
    background: white;
    box-shadow: 0 3px 3px 3px rgba(0,0,0,0.15);
    padding-left: 0;
    right: 0;
    ul {
      width: 100%;
    }
    li {
      display: flex;
      flex-direction: row-reverse;
      ~ li {
        border-top: 1px solid $gray-light;
      }
      a {
        display: inline-block;
        padding: 15px;
        width: 100%;
        font-size: 12px;
        font-weight: normal;
        color: $gray-dark;
        text-align: left;
      }
    }
  }
  .dots::after {
    content: '\2807';
    font-size: 25px;
  }
  .dots {
    content: '\2807';
    font-size: 25px;
    width: 12px;
    display: block;
    margin: auto;
    color: #BAC3D4;
    padding: 0;
  }
}
</style>
