<template>
  <div class="diffusion-ubiflow">
    <form ref="diffusion-ubiflow" @submit.prevent="save">

      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Configuration diffusion</h1>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>

      <!-- TABS -->
      <tab-nav :tabs="tabs"/>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="grid-x title">
          <div class="cell auto">
            <h3>Identifiants Ubiflow par agence</h3>
          </div>
        </div>
        <div class="table grid-x">
          <div class="cell auto">
            <app-table class="agencies" :headers="tableHeaders" :data="tableData" :loading="isLoading">
              <template slot="empty-table">
                <p>Aucune données de disponible</p>
              </template>
              <template slot="agency" slot-scope="{ data }">
                <strong>{{ data.name }}</strong>
              </template>
              <template slot="ubiflowId" slot-scope="{ data }">
                <app-input v-model="data.ubiflowId" type="text" placeholder="Identifiant..." size="small" />
              </template>
              <template slot="ubiflowPassword" slot-scope="{ data }">
                <app-input v-model="data.ubiflowPassword" type="text" placeholder="Mot de passe..." size="small" />
              </template>
            </app-table>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import agencyApi from '@/services/api/agency';

export default {
  name: 'diffusion-ubiflow',

  components: {
    TabNav,
  },

  metaInfo: {
    title: 'Configuration diffusion',
  },

  data() {
    return {
      tabs: [
        {
          route: {
            name: 'diffusions-quotas',
            params: {},
          },
          label: 'Gestion',
        },
        {
          route: {
            name: 'diffusions-statistics',
            params: {},
          },
          label: 'Statistiques',
        },
        {
          route: {
            name: 'diffusions-templates',
            params: {},
          },
          label: 'Templates',
        },
        {
          route: {
            name: 'diffusions-ubiflow',
            params: {},
          },
          label: 'Ubiflow',
        },
        {
          route: {
            name: 'diffusions-advertisement-packs',
            params: {},
          },
          label: 'Packs',
        },
        {
          route: {
            name: 'diffusions-logs',
            params: {},
          },
          label: 'Logs',
        },
      ],
      isLoading: null,
      tableHeaders: [
        { label: 'Agence', key: 'agency', size: 'auto' },
        { label: 'Identifiant', key: 'ubiflowId', size: 'auto' },
        { label: 'Mot de passe', key: 'ubiflowPassword', size: 'auto' },
      ],
      tableData: [],
      agencies: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      await this.getAgencies();
      this.tableData = this.agencies;
    },
    async getAgencies() {
      try {
        this.agencies = await agencyApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        const agencyToUpdate = this.tableData.map((agency) => agencyApi.update(agency.agencyId, agency));
        await Promise.all(agencyToUpdate);
        this.$notification.show({ text: 'La configuration Ubiflow a été mise à jour avec succès !' });
        await this.getData();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: `Il y a eu un problème lors de la sauvegarde des quotas. ${er}`,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.diffusion-ubiflow
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .card
    @include card
  .title
    margin-bottom: 28px
</style>
