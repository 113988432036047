<template>
  <div class="floors hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Distribution</h1>
      </div>
    </div>

    <!-- TABS -->
    <tab-nav :tabs="tabs" />

    <!-- CONTENT -->
    <div class="body">

      <!-- TABLE -->
      <div class="grid-x">
        <div class="cell auto">
          <app-table :headers="tableHeader" :data="floors" :loading="isLoading">
            <template slot="loading">
              <app-spinner />
            </template>
            <template slot="name" slot-scope="{ data }">
              <strong>{{ data.name }}</strong>
            </template>
            <template slot="createdAt" slot-scope="{ data }">
              <p>{{ $dayjs(data.createdAt).format("DD/MM/YYYY") }}</p>
            </template>
            <template slot="empty-table">
              <p>Aucune donnée de disponible</p>
            </template>
          </app-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import floor from '@/services/api/floor';

export default {
  name: 'floors',

  components: {
    TabNav,
  },

  metaInfo: {
    title: 'Distribution',
  },

  data() {
    return {
      // Navigation
      tabs: [
        { route: { name: 'categories-rooms' }, label: 'Pièces' },
        { route: { name: 'categories-floors' }, label: 'Niveaux' },
      ],

      // Table
      tableHeader: [
        { label: 'Niveaux', key: 'name', size: 11 },
        { label: 'Crée le', key: 'createdAt', size: 1 },
      ],
      isLoading: false,

      // Floor
      floors: [],
    };
  },

  mounted() {
    this.getFloors();
  },

  methods: {
    async getFloors() {
      this.isLoading = true;

      try {
        this.floors = await floor.getAll();
      } catch (error) {
        throw error;
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.floors
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
</style>
