<template>
  <div class="company hedifys-21">
    <form ref="company" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="company && company.companyId && company.name"
            :items="[
              { route: { name: 'companies'}, name: 'Partenaires'},
              { route: { name: 'companies-companyId', params: { companyId: $route.params.companyId }}, name: company.name}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x" :class="{ 'includes-tabs': $route.params.companyId }">
        <div class="cell auto">
          <div v-if="!isLoading">
            <h1 v-if="$route.params.companyId && company">{{ company.name }}</h1>
            <h1 v-else>Ajouter un partenaire</h1>
          </div>
        </div>
        <div class="cell shrink" v-if="$route.params.companyId">
          <app-button theme="warning" size="large" icon="remove" @click="openDeleteCompanyModal">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button v-if="$route.params.companyId" theme="primary" size="large" type="submit">Sauvegarder</app-button>
          <app-button v-else theme="primary" icon="add" size="large" type="submit">Ajouter</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav v-if="$route.params.companyId" :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading && company">
        <!-- OLD FORM  (Hedifys chantier) -->
        <div class="card">
          <div class="grid-x">
            <div class="cell auto">
              <h3>Société</h3>
            </div>
            <div class="cell auto">
              <h3>Coordonnées</h3>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="company.name" label="Dénomination" placeholder="Dénomination..." required />
            </div>
            <div class="cell auto">
              <app-input v-model="company.address.addressLine1" label="Adresse" placeholder="Adresse..." required />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-textarea
                label="Observation"
                placeholder="Vos observations..."
                v-model="company.observation"
              />
            </div>
            <div class="cell auto">
              <div class="grid-x">
                <div class="cell small-3 postal-code">
                  <app-input v-model="company.address.postalCode" label="Code postal" placeholder="Code postal..." required />
                </div>
                <div class="cell auto">
                  <app-input v-model="company.address.city" label="Ville" placeholder="Ville..." required />
                </div>
              </div>
            </div>
          </div>
          <div class="grid-x">
            <div class="cell auto">
              <h3>Répertoire agence</h3>
            </div>
            <div class="cell auto"></div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell auto">
              <div>
                <app-label>Agence liée</app-label>
              </div>
              <div  class="agency-registered">
                  <p v-if="company.registeredAgency">
                    <strong>{{ company.registeredAgency.name }}</strong>
                  </p>
                  <p v-else>
                    <strong>Aucune agence liée</strong>
                  </p>
              </div>
            </div>
            <div class="cell auto">
              <div class="grid-x select-agency-mb" v-if="member.isBrandAdmin">
                <app-select required :value="null" v-model="agencyId" :options="agencies" />
              </div>

              <div class="grid-x">
                <app-checkbox
                  v-model="register"
                  value="favorite"
                >Partenaire favoris</app-checkbox>
              </div>
            </div>
          </div>
        </div>
        <section class="hedifys-chantier" id="company">
          <vk-grid gutter="medium" class="wrapper uk-child-width-expand uk-padding uk-margin-top uk-margin-remove-left">
            <div class="uk-width-expand@m">
              <h3>Contacts</h3>
              <vk-grid class="uk-child-width-expand" gutter="small" v-for="(contact, index) in selectedContacts" :key="`${index}-${contact ? contact.contactId : ''}`">
                <div class="uk-width-auto@m icon-favorite" @click="() => updateFavorite(index)">
                  <favorite-empty :class="{'favorite-full': contact.isFavorite}" @click.stop="updateFavorite(index)"/>
                </div>
                <div>
                  <label>Nom *</label>
                  <input class="uk-input" required type="text" v-model="contact.lastname" />
                </div>
                <div>
                  <label>Complément</label>
                  <input class="uk-input" type="text" v-model="contact.firstname" />
                </div>
                <div>
                  <label>Téléphone</label>
                  <input class="uk-input" type="tel" v-model="contact.phone" />
                </div>
                <div>
                  <label>Email *</label>
                  <input class="uk-input" required type="email" v-model="contact.email" />
                </div>
                <div class="uk-width-auto@m">
                  <div class="icon-delete-contact">
                    <vk-button
                      v-show="selectedContacts.length > 1"
                      @click="contact && contact.contactId ? openDeleteModal(contact.contactId) : deleteContact(index)">
                    </vk-button>
                  </div>
                </div>
              </vk-grid>
              <vk-grid class="uk-child-width-expand" gutter="small">
                <div class="uk-width-expand@m">
                </div>
                <div class="uk-width-auto@m">
                  <div class="icon-add-contact">
                    <vk-button @click="addContact"></vk-button>
                  </div>
                </div>
              </vk-grid>
              <span class="foot-note">Les contacts favoris servent à l'envoi de rapports. Définissez-les en cliquant sur l'étoile.</span>
            </div>
          </vk-grid>

          <div class="categories uk-margin-top uk-padding">
            <vk-grid gutter="small" class="uk-child-width-expand">
              <div>
                <vk-grid gutter="collapse" class="uk-child-width-1-1">
                  <div>Catégories</div>
                  <div class="uk-margin-top">Métiers</div>
                </vk-grid>
              </div>
              <div v-for="(type, tindex) in Object.keys(types.companyCategoryType)" :key="`company-categories-${tindex}`">
                <label class="type uk-margin-bottom">
                  {{ types.companyCategoryType[type] ? types.companyCategoryType[type].charAt(0).toUpperCase() + types.companyCategoryType[type].slice(1) : '' }}
                </label>
                <vk-grid gutter="collapse" class="companies uk-child-width-1-1">
                  <div v-for="(companyCategory, cindex) in companyCategories.filter(cc => cc.type === type)" :key="`company-companies-${cindex}`">
                    <vk-grid class="uk-child-width-1-2 uk-flex-middle" gutter="collapse">
                      <input :id="companyCategory.name" class="uk-width-auto uk-checkbox uk-margin-small-right" type="checkbox" :value="companyCategory.companyCategoryId" :checked="company.companyCategories.includes(companyCategory.companyCategoryId)" @change="onCompayCategoryChanged" />
                      <label :for="companyCategory.name">{{ companyCategory.name }}</label>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>
            </vk-grid>
          </div>
          <vk-grid gutter="medium" class="wrapper uk-child-width-expand uk-padding uk-margin-top uk-margin-remove-left">
            <div class="uk-width-expand@m">
              <h3>Préférences rapport partenaires</h3>
              <div>
                <label>Préférence d'envoi</label>
                <app-input type="number" v-model="company.periodPreference" unit="semaine(s)" size="small"/>
              </div>
            </div>
          </vk-grid>
        <vk-modal class="delete-modal" center :show="true" :style="{ display: showDeleteModal ? 'block': 'none' }">
          <vk-modal-close @click="closeDeleteModal"></vk-modal-close>
          <vk-modal-title @click.stop="">Attention</vk-modal-title>
          <div @click.stop="" class="content">
            <span>
              Êtes vous sûr de vouloir supprimer ce contact ?<br>
              Il est peut-être employé par d'autres utlisateurs.
            </span>
          </div>
          <div>
            <vk-grid gutter="collapse" class="container uk-child-width-expand">
              <div class="column uk-width-expand@m"></div>
                <div class="column uk-width-auto@m">
                  <vk-button @click="closeDeleteModal" class="secondary button">Annuler</vk-button>
                </div>
                <div class="column uk-width-auto@m">
                  <vk-button @click="deleteContact" class="primary button">Supprimer</vk-button>
                </div>
              </vk-grid>
            </div>
          </vk-modal>
        </section>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

import companyCategory from '@/services/api/companyCategory';
import contact from '@/services/api/contact';
import favoriteContact from '@/services/api/favoriteContact';
import companyApi from '@/services/api/company';
import auth from '@/services/auth';
import types from '@/services/data/types.json';
import favorite from '@/services/api/favorite';
import member from '@/services/api/member';
import agencyApi from '@/services/api/agency';
import utils from '@/services/utils/utils';

import FavoriteEmpty from '@/assets/logos/favoriteEmpty.svg?inline';

export default {
  name: 'company',
  components: {
    TabNav,
    Breadcrumb,
    FavoriteEmpty,
  },
  metaInfo() {
    return {
      title: this.company.name ? `${this.company.name} –  Partenaire` : 'Partenaire',
    };
  },
  data() {
    return {
      company: {
        companyId: null,
        name: null,
        address: {
          addressLine1: null,
          postalCode: null,
          city: null,
        },
        registeredAgency: null,
        observation: null,
        companyCategories: [],
        isFavorite: false,
        isAgencyRegistered: false,
        periodPreference: 3,
      },
      isPerson: false,
      companyCategories: [],
      types,
      register: [],
      agencies: [],
      agencyId: null,
      contacts: [],
      selectedContacts: [],
      contactToDelete: null,
      showDeleteModal: false,
      member: {
        isBrandAdmin: true,
      },

      isLoading: false,
    };
  },
  computed: {
    tabs() {
      let tabs = [
        {
          route: {
            name: 'companies-companyId',
            params: { companyId: null },
          },
          label: 'Informations',
        },
      ];
      if (this.company && this.company.companyCategories && this.company.companyCategories.length && this.companyCategories && this.companyCategories.length) {
        const isSupplierOrContractor = this.companyCategories.filter(
          (category) => (category.type === 'SUPPLIER' || category.type === 'SUBCONTRACTOR')
            && this.company.companyCategories.includes(category.companyCategoryId),
        ).length > 0;
        if (isSupplierOrContractor) {
          tabs = tabs.concat([
            {
              route: {
                name: 'companies-companyId-projects',
                params: { companyId: null },
              },
              label: 'Chantiers',
            },
            {
              route: {
                name: 'companies-companyId-schedule',
                params: { companyId: null },
              },
              label: 'Disponibilités',
            },
          ]);
        }

        const isDeveloper = this.companyCategories.filter(
          (category) => category.type === 'DEVELOPER'
            && this.company.companyCategories.includes(category.companyCategoryId),
        ).length > 0;
        if (isDeveloper) {
          tabs = tabs.concat([
            {
              route: {
                name: 'companies-companyId-allotments',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Lotissements',
            },
            {
              route: {
                name: 'companies-companyId-lands',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Terrains',
            },
          ]);
        }
      }

      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { companyId: this.$route.params.companyId },
        },
        label: el.label,
      }));
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getMember();
    if (this.member.isBrandAdmin) {
      await this.getAgencies();
    }
    try {
      // récupération des métiers
      const companyCategories = await companyCategory.getAll();

      if (companyCategories && companyCategories.length > 0) {
        this.companyCategories = companyCategories.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.companyCategories = [];
      }
    } catch (er) {
      this.$message.show({
        title: 'Erreur',
        text: 'Il y a eu un problème lors du chargement des métiers',
        cancelText: 'Ok',
        hasCancel: true,
      });
    }
    if (this.$route.params.companyId) {
      try {
        await this.getCompany();
        this.agencyId = this.company.registeredAgency ? this.company.registeredAgency.agencyId : null;
        // récupération des contacts
        await this.getContacts();

        if (this.selectedContacts.length === 0) {
          this.selectedContacts = [{
            companyId: this.company.companyId,
            firstname: null,
            lastname: 'Contact entreprise',
            phone: null,
            email: null,
            isFavorite: true,
          }, {
            companyId: this.company.companyId,
            firstname: null,
            lastname: 'Contact principal',
            phone: null,
            email: null,
            isFavorite: true,
          }];
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du chargement du partenaire',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    } else {
      this.selectedContacts = [{
        firstname: null,
        lastname: 'Contact entreprise',
        phone: null,
        email: null,
        isFavorite: true,
      }, {
        firstname: null,
        lastname: 'Contact principal',
        phone: null,
        email: null,
        isFavorite: true,
      }];
    }
    this.isLoading = false;
  },
  methods: {
    // New methods
    async deleteCompany() {
      if (this.$route.params.companyId) {
        try {
          await companyApi.delete(this.$route.params.companyId);
          this.$router.push({ name: 'companies' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors du chargment du partenaire',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    async getMember() {
      try {
        this.member = await member.getMe();
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du chargement des données de l\'utilisateur',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    openDeleteCompanyModal() {
      this.$message.show({
        title: 'Supprimer le partenaire',
        text: 'Êtes vous sûr de vouloir supprimer ce partenaire ?',
        confirmText: 'Supprimer le partenaire',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteCompany();
        },
      });
    },
    // Old Methods
    openDeleteModal(contactId) {
      this.contactToDelete = contactId;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    deleteContact(index) {
      let i = index;

      if (typeof i !== 'string') {
        i = this.selectedContacts.findIndex((c) => c && c.contactId === this.contactToDelete);
      }
      if (this.selectedContacts && this.selectedContacts.length > 1) {
        this.selectedContacts.splice(i, 1);
        if (this.selectedContacts.length === 1) {
          this.selectedContacts[0].isFavorite = true;
        }
      }
      this.contactToDelete = null;
      this.showDeleteModal = false;
    },
    async getContacts() {
      try {
        if (this.company && this.company.companyId) {
          // récupération des contacts par partenaire
          this.contacts = await contact.getByCompany(this.company.companyId);
          this.selectedContacts = this.contacts.slice().map((c, index) => ({ index, ...c }));

          // Si on a pas de favoris, on note le contact principal comme favoris
          const favorites = this.selectedContacts.find((c) => c.isFavorite);
          if (!favorites) {
            this.selectedContacts[0].isFavorite = true;
          }
        }
      } catch (er) {
        //
      }
    },

    async getAgencyId() {
      try {
        const agencies = await agencyApi.getAll();
        this.agencyId = agencies[0].agencyId;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de l\'agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll();
        const parsedAgencies = utils.formatOptions(
          agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agencies = parsedAgencies;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    addContact() {
      const index = Math.max(...this.selectedContacts.map((c) => (c.index ? c.index : 0)));

      this.selectedContacts.push({
        companyId: this.company.companyId,
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        isFavorite: false,
        index: index + 1,
      });
    },
    updateFavorite(index) {
      if (this.selectedContacts[index]) {
        this.selectedContacts[index].isFavorite = !this.selectedContacts[index].isFavorite;

        const favorites = this.selectedContacts.find((c) => c.isFavorite);

        // Si on a pas de favoris, on note le contact principal comme favoris
        if (!favorites) {
          if (this.selectedContacts[0]) {
            this.selectedContacts[0].isFavorite = true;
          }
        }
      }
    },
    async updateFavoriteCompany() {
      try {
        if (this.register.includes('favorite') && !this.company.isFavorite) {
          await favorite.create({ companyId: this.company.companyId });
        } else if (this.company.isFavorite && !this.register.includes('favorite')) {
          await favorite.delete({ companyId: this.company.companyId });
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors la mise à jour du favoris',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;

      if (!this.member.isBrandAdmin) {
        await this.getAgencyId();
      }

      // Mise à jour du partenaire
      if (this.company.companyCategories.length === 0) {
        this.$message.show({
          title: 'Erreur',
          text: 'Veuillez sélectionner au minimum un métier.',
          cancelText: 'Ok',
          hasCancel: true,
        });
        return;
      }
      if (this.$route.params.companyId) {
        try {
          if (!this.company.address.city || !this.company.address.addressLine1.trim() || !this.company.address.postalCode) throw new Error('L\'adresse n\'est pas valide. Valeurs attendues: adresse, code postal et ville.');
          await companyApi.edit({ ...this.company, agencyId: this.agencyId });
          this.$notification.show({ text: 'Ce partenaire a été mis à jour avec succès !' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la mise à jour du partenaire',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      } else {
        try {
          if (!this.company.address.city || !this.company.address.addressLine1.trim() || !this.company.address.postalCode) throw new Error('L\'adresse n\'est pas valide. Valeurs attendues: adresse, code postal et ville.');
          this.company.brandId = await auth.getBrandId();
          this.company = await companyApi.create({
            address: this.company.address,
            name: this.company.name,
            observation: this.company.observation,
            companyCategories: this.company.companyCategories,
            brandId: this.company.brandId,
            periodPreference: this.company.periodPreference,
            agencyId: this.agencyId,
          });
          this.$notification.show({ text: 'Le partenaire a été ajouté avec succès !' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la création du partenaire',
            cancelText: 'Ok',
            hasCancel: true,
          });
          this.isLoading = false;
          return;
        }
      }

      await this.updateFavoriteCompany();
      await this.getCompany();

      // Mise à jour des contacts
      try {
        // Ajout / modification des nouveaux contacts
        const promisesNew = this.selectedContacts.map((c) => {
          if (c && c.lastname && c.email) {
            if (c.contactId) {
              return contact.edit({
                contactId: c.contactId,
                companyId: c.companyId,
                firstname: c.firstname ? c.firstname : '',
                lastname: c.lastname,
                email: c.email,
                phone: c.phone,
              });
            }
            return contact.create({
              companyId: this.company.companyId,
              firstname: c.firstname ? c.firstname : '',
              lastname: c.lastname,
              email: c.email,
              phone: c.phone,
            });
          }
          return null;
        });
        await Promise.all(promisesNew);

        // Ajout / modification des favoris
        const promisesFavorite = this.selectedContacts.map((c) => {
          if (c && c.lastname && c.email && c.isFavorite && c.contactId) {
            return favoriteContact.create(c.contactId);
          } if (c && c.lastname && c.email && !c.isFavorite && c.contactId) {
            return favoriteContact.delete(c.contactId);
          }
          return null;
        });
        await Promise.all(promisesFavorite);

        // Suppression des favoris
        const promisesDeletionFavorite = this.contacts.map((c) => {
          const match = this.selectedContacts.find((sc) => sc.contactId === c.contactId);
          if (!match && c.isFavorite) {
            return favoriteContact.delete(c.contactId);
          }
          return null;
        });
        await Promise.all(promisesDeletionFavorite);

        // Suppression des contacts
        const promisesDeletion = this.contacts.map((c) => {
          const match = this.selectedContacts.find((sc) => sc.contactId === c.contactId);
          if (!match) {
            return contact.delete(c.contactId);
          }
          return null;
        });
        await Promise.all(promisesDeletion);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour des contacts',
          cancelText: 'Ok',
          hasCancel: true,
        });
        this.isLoading = false;
        return;
      }

      // Si au moins un contact viens d'être crée
      await this.getContacts();

      if (!this.$route.params.companyId) {
        this.$router.push({ name: 'companies-companyId', params: { companyId: this.company.companyId } });
      }
      // on rajouter les tabs des lotisseurs si besoin
      this.isLoading = false;
    },
    onCompayCategoryChanged(event) {
      const index = this.company.companyCategories.indexOf(event.target.value);
      if (index === -1) {
        this.company.companyCategories.push(event.target.value);
      } else {
        this.company.companyCategories.splice(index, 1);
      }
    },

    async getCompany() {
      this.company = await companyApi.getById(this.company.companyId || this.$route.params.companyId);
      if (this.company.isAgencyRegistered) {
        this.register.push('agency-registered');
      }
      if (this.company.isFavorite) {
        this.register.push('favorite');
      }
    },
  },
};
</script>

<!-- New style -->
<style lang='sass' scoped>
.company
  .body
    @include screen
  .header
    @include header
  .save
    margin-left: 16px
  .breadcrumbs
    @include breadcrumbs
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
  h3
    color: $primary
    margin-bottom: 10px
  svg
    width: 30px
  .select-agency-mb
    margin-bottom: 0.5rem
  .favorite-full path
    fill: $blue-medium
  .is-agency-registered
    margin-bottom: 1rem
  .postal-code
    margin-right: 1rem
  .agency-registered
    display: flex
    flex-direction: column
    gap: 0.5rem
    margin-top: 0.5rem
    padding: 0
    p
      display: flex
      padding: 0.75rem 1rem
      background-color: $gray-very-light
      border-radius: $global-border-radius
      border: 1px solid $line
</style>

<!-- Old style -->
<style lang="scss">
  #company {
    label {
      display: block;
      @include heading-xs;
      color: $label;
      margin-bottom: 5px;
    }
    input, select {
      margin: 0;
      padding: 5px;
      height: 35px;
      border-radius: $global-border-radius;
      border: 1px solid $gray-light;
      @include paragraph
    }
    .wrapper {
      border-radius: $global-border-radius;
      box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);
      background-color: $white;
      p {
        color: $blue-medium;
        font-weight: 500;
        font-size: 18px;
      }
      &:not(.app-input) {
        p {
          color: $label;
          font-weight: initial;
          font-size: initial;
        }
      }
    }
    .categories {
      border-radius: $global-border-radius;
      box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);
      background-color: $white;
      input[type="checkbox"] {
        height: 25px;
        width: 25px;
      }
      .type {
        padding: 10px;
        text-align: center;
        border: 1px solid $gray-regular;
      }
    }
    .companies > div {
      margin-bottom: 5px;
    }
    .icon-favorite {
      margin-top: 1.25rem;
      img {
        height: 40px;
      }
    }
    .icon-add-contact, .icon-delete-contact {
      position: relative;
      button {
        width: 25px;
        height: 25px;
        border: 0;
        border-radius: 20px;
        background-color: $blue-medium;
        padding: 10px;

        &::before, &::after {
          content: '';
          background-color: #ffffff;
          width: 12px;
          height: 2px;
          position: absolute;
          top: 15px;
          left: 7px;
        }
      }
    }
    .icon-add-contact {
      button {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
    .icon-delete-contact {
      margin-top: 18px;
      min-width: 25px;
    }

    .loading {
      display: flex;
      width: 100%;
      justify-content: center;
      height: auto;
      align-items: center;
      margin: 10% 0;
    }
    .foot-note {
      font-size: 12px;
    }
  }
</style>
