import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des lots métrés spécifications
 * @param {string} versionId: id de la version
*/
const getPublicByVersionId = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/versions/${versionId}/lots-surveys-specifications`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des lots métrés spécifications
 * @param {string} versionId: id de la version
 */
const getByVersionId = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions/${versionId}/lots-surveys-specifications`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mise à jour des lots métrés spécifications
 * @param {string} versionId: id de la version
 * @param {array} data: lots métrés à mettre à jour
 */
const update = async (versionId, data) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/versions/${versionId}/lots-surveys-specifications`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const lotSurveySpecification = {};
lotSurveySpecification.getByVersionId = getByVersionId;
lotSurveySpecification.update = update;
lotSurveySpecification.getPublicByVersionId = getPublicByVersionId;

export default lotSurveySpecification;
