<template>
  <div id="app">
    <div class="grid-x" v-if="!$route.meta.print">
      <!-- Main side navigation -->
      <side-nav v-if="!$route.meta.hideSideNav" />
      <div class="page-container cell auto"
        :class="{
          'print': $route.meta.print,
          'no-scroll':
            $route.name === 'customers-projects-drafts-new' ||
            $route.name === 'customers-projects-drafts-edit' ||
            $route.name === 'customers-projects-drafts-projectDraftId-edit' ||
            $route.name === 'customers-projects-drafts-new-configuration' ||
            $route.name === 'customers-projects-drafts-projectDraftId-configuration'
        }">
        <account-nav v-if="!$route.meta.hideTopbar" />
        <div class="page" :class="{'with-nav': !$route.meta.hideTopbar}">
          <router-view />
        </div>
      </div>
    </div>
    <div v-else class="print">
      <router-view />
    </div>
    <!-- Global basics components integration -->
    <app-message />
    <app-notifications />
    <vk-notification :messages.sync="$root.notificationMessages" :timeout="3500"></vk-notification>
  </div>
</template>
<script>

import SideNav from '@/components/layouts/SideNav.vue';
import AccountNav from '@/components/layouts/AccountNav.vue';

export default {
  name: 'App',
  metaInfo: {
    title: '🏠',
    titleTemplate: '%s | Hedifys',
  },
  components: {
    AccountNav,
    SideNav,
  },
  methods: {
    onQueryChange(query) {
      this.$router.push({ name: this.$route.name, query: { ...query } });
    },
  },
};
</script>

<style lang="sass">
  @import "@/assets/sass/design_system/_foundation.scss"
  @import "@/assets/sass/hedifys.scss"
  @import "./assets/sass/icons.scss"

  html, body, #app
    margin: 0
    padding: 0
    color: $gray-deep
    background-color: #f3f4f9
    -webkit-print-color-adjust: exact
  body
    overflow-x: hidden
    overflow-y: hidden
  html
    height: auto
    overflow-y: auto!important
  html, body, #app, p, a, span, ul li, button
    font-family: 'Helvetica Neue', sans-serif !important
  .page-container
    height: 100vh
    overflow-y: scroll!important
    position: relative
    .page
      height: 100%
      &.with-nav
        margin-top: -60px
  .print
    height: 100%
  // STYLES GLOBAUX DE HEDIFYS 21
  // .hedifys-21
  h1, h2, h3, h4, h5, h6
    margin: 0
    color: inherit
  h1
    @include heading-xl
    color: $title
  h2
    @include heading-lg
  h3
    @include heading-md
  h4
    @include heading-sm-bold
  h5
    @include heading-sm
  h6
    @include heading-xs
  p, span
    margin: 0
    @include paragraph
  strong
    margin: 0
    @include paragraph-bold
  .no-scroll
    overflow-y: hidden!important

  // STYLES DES ANCIENNES PAGES D'HEDIFYS CHANTIER
  .hedifys-chantier, .uk-modal
    input[type="text"], input[type="tel"], input[type="email"], select
      width: 100%
      border-radius: 5px
      border: 1px solid $gray-light
      transition: .2s ease-in-out
      transition-property: color,background-color,border
      height: 40px
      vertical-align: middle
      display: inline-block
      padding: 0 10px
      @include paragraph
      &:focus
        outline: none
        box-shadow: 0 0 2px 1px $blue-medium
      &:disabled
        background-color: $gray-very-light
    textarea
      padding: 10px
      border-radius: 5px
      color: $gray-medium
      border: 1px solid $gray-light
      font-size: 14px
      resize: none
      white-space: pre-wrap
      width: calc(100% - 22px)

    .uk-notification
      z-index: 5000
    .vdp-datepicker
      .vdp-datepicker__calendar
        width: 100%
        border: none
        border-radius: 5px
        box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1)
        .prev::after
          border-right: 10px solid $blue-light
        .next::after
          border-left: 10px solid $blue-light
        .cell
          height: auto
          line-height: initial
          &.day, &.month, &.year
            padding: 10px 0
            &.selected, &:hover
              color: $white
              background-color: $blue-light
    .datepicker-modal
      .content
        padding: 20px
      .actions
        padding: 20px
        button
          color: $white
          border: none
          border-radius: 30px
          background-color: $blue-light
  .uk-notification-message
    width: 25rem
  .uk-modal
      width: 100% !important
      z-index: 1100
      .uk-modal-title
        margin-bottom: 0
        background-color: $white
        small
          display: block
      .button
        margin-bottom: 20px!important
        margin: 20px 2rem
      button.primary
        color: #ffffff
        border: none
        border-radius: 30px
        background-color: $blue-light
      button:disabled
        cursor: initial
        background-color: $gray-light
      button.secondary
        border: none
        border-radius: 30px
      label
        display: block
        @include heading-xs
        color: $label
        padding-bottom: 5px
      .right-col
        margin-left: 10px
      input[type="checkbox"]
        height: 40px
        width: 40px
        margin-top: 0px
        border: 1px solid $gray-light
        background-color: white
        border-radius: 5px
        background-size: 25px
        &:checked
          background-color: $blue-medium
          border-color: transparent
      .uk-modal-dialog
        box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1)
        background-color: $gray-very-light
        .uk-modal-body
          padding: 0
          .uk-modal-title
            margin: 0
            padding: 20px 30px
            font-weight: 500
            font-size: 25px
            color: $gray-dark
            small
              font-size: 40%
              text-transform: uppercase
              color: #999999
          .content
            padding: 20px 30px
          .actions
            margin: 50px 0 0 0
            button
              &.cancel, &.submit
                padding: 0 30px
                height: 35px
                border-radius: 30px !important
                border: none !important
                text-transform: uppercase
              &.cancel
                margin: 0 10px 0 0
                color: $gray-regular !important
                background-color: $gray-white !important
              &.submit
                color: #ffffff !important
                background-color: $blue-medium !important
          .uk-close
            background-color: transparent
</style>
