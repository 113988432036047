<template>
  <div class="document-sub-categories">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb v-if="category"
            :items="[
              { route: { name: 'documents-space'}, name: 'Espace Docs'},
              { route: { name: 'documents-space-documents', params: {documentCategoryId: category.documentCategoryId} }, name: category.name }
            ]"
          />
        </div>
     </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Sous-rubriques</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="openAddSubCategoryModal">Ajouter une sous-rubrique</app-button>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- BODY / LISTE DES SOUS RUBRIQUES -->
    <div class="body">
      <app-table :headers="tableHeaders" :data="subCategories.data" :loading="isLoading">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="sub-category" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="more" slot-scope="{ data }">
          <div class="button-container">
            <more-options :id="data.documentSubCategoryId" @update="openUpdateSubCategoryModal" @delete="tryDelete"></more-options>
          </div>
        </template>
      </app-table>
      <!-- PAGINATION -->
      <app-pagination
        v-if="subCategories.metadata"
        :limit="limit"
        :offset="subCategories.metadata.offset"
        :count="subCategories.metadata.count"
      />
    </div>
    <!-- MODALE AJOUT DE SOUS-RUBRIQUE  -->
    <app-modal class="modal" :show="isAddSubCategoryModalOpen" size="small" title="Ajouter une sous-rubrique"
      @update:show="isAddSubCategoryModalOpen = false">
      <form ref="subCategory" @submit.prevent="createSubCategory">
        <app-select label="Rubrique" :value="this.$route.params.documentCategoryId" :options="categoriesOptions" disabled required/>
        <app-input v-model="subCategory.name" label="Sous-rubrique" placeholder="Nom de la sous-rubrique..."
        pattern="^(?!\s*$).+" required />
        <app-button theme="primary" size="large" type="submit">Ajouter</app-button>
      </form>
    </app-modal>
    <!-- MODALE MODIFICATION -->
    <app-modal class="modal" :show="isUpdateSubCategoryModalOpen" size="small" title="Modifier une sous-rubrique"
      @update:show="isUpdateSubCategoryModalOpen = false">
      <form ref="subCategory" @submit.prevent="updateSubCategory">
        <app-input v-model="subCategory.name" label="Sous-rubrique" placeholder="Nom de la sous-rubrique..." pattern="^(?!\s*$).+" required />
        <app-button theme="primary" size="large" type="submit">Modifier</app-button>
      </form>
    </app-modal>
  </div>
</template>

<script>
// Services
import auth from '@/services/auth';
import utils from '@/services/utils/utils';
import documentCategoryApi from '@/services/api/documentCategory';
import documentSubCategoryApi from '@/services/api/documentSubCategory';
import documentApi from '@/services/api/document';

// Components
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import MoreOptions from './MoreOptions.vue';

export default {
  name: 'documents-space-subcategories',
  components: {
    TabNav,
    MoreOptions,
    Breadcrumb,
  },
  data() {
    return {
      subCategory: {
        name: null,
        documentCategoryId: this.$route.params.documentCategoryId,
      },
      subCategoryId: null,
      category: null,
      categories: [],
      subCategories: {
        data: [],
        metadata: null,
      },
      brandId: null,
      tabs: [
        {
          route: {
            name: 'documents-space',
          },
          label: 'Accueil',
        },
        {
          route: {
            name: 'documents-space-documents',
            params: {
              documentCategoryId: this.$route.params.documentCategoryId,
            },
          },
          label: 'Liste documents',
        },
        {
          route: {
            name: 'documents-space-subcategories',
            params: {
              documentCategoryId: this.$route.params.documentCategoryId,
            },
          },
          label: 'Sous-rubriques',
        },
      ],
      tableHeaders: [
        { label: 'Sous-rubrique', key: 'sub-category', size: 10 },
        { label: '', key: 'more', size: 2 },
      ],
      limit: 20,
      isLoading: false,
      isAddSubCategoryModalOpen: false,
      isUpdateSubCategoryModalOpen: false,
    };
  },
  async mounted() {
    await this.getData();
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    categoriesOptions() {
      return utils.formatOptions(
        this.categories,
        (option) => option.documentCategoryId,
        (option) => option.name,
        'choose',
      );
    },
  },
  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getSubCategories();
        this.isLoading = false;
      }
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.brandId = await auth.getBrandId();
      await this.getCategories();
      await this.getCategory();
      this.getSubCategories();
      this.isLoading = false;
    },
    async getCategories() {
      try {
        this.categories = await documentCategoryApi.getAll();
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des rubriques',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCategory() {
      try {
        this.category = await documentCategoryApi.getOneById(this.$route.params.documentCategoryId);
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la rubrique',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getSubCategories() {
      try {
        this.subCategories = await documentSubCategoryApi.getSubCategoriesByCategory(this.$route.params.documentCategoryId, this.limit, this.computeOffset);
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des sous-rubriques',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async createSubCategory() {
      this.isLoading = true;
      try {
        this.subCategory.name.trim();
        await documentSubCategoryApi.createSubCategory(this.subCategory);
        this.$notification.show({ text: 'Sous-rubrique créée avec succès !' });
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la création de la sous-rubrique',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isAddSubCategoryModalOpen = false;
      this.subCategory.name = null;
      this.getSubCategories();
      this.isLoading = false;
    },
    openAddSubCategoryModal() {
      this.subCategory.name = null;
      this.isAddSubCategoryModalOpen = true;
    },
    async openUpdateSubCategoryModal(subCategoryId) {
      const subCategory = await documentSubCategoryApi.getOneSubCategoryById(subCategoryId);
      this.subCategory.name = subCategory.name;
      this.subCategoryId = subCategoryId;
      this.isUpdateSubCategoryModalOpen = true;
    },
    async updateSubCategory() {
      this.isLoading = true;
      try {
        this.subCategory.name.trim();
        await documentSubCategoryApi.updateSubCategory(this.subCategoryId, this.subCategory);
        this.$notification.show({ text: 'Sous-rubrique modifiée avec succès !' });
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la modification de la sous-rubrique',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isUpdateSubCategoryModalOpen = false;
      this.getSubCategories();
      this.isLoading = false;
    },
    async tryDelete(subCategoryId) {
      const subCategory = await documentSubCategoryApi.getOneSubCategoryById(subCategoryId);
      const documents = await documentApi.getAllBySubCategory(subCategoryId);
      if (documents.length > 0) {
        this.$message.show({
          title: 'Supprimer une sous-rubrique',
          text: `Il y a des documents dans cette sous-rubrique (${documents.length}).
            Si vous la supprimez, ces derniers le seront également.
            Souhaitez-vous supprimer la sous-rubrique ${subCategory.name} ?`,
          confirmText: 'Supprimer',
          hasConfirm: true,
          cancelText: 'Annuler',
          hasCancel: true,
          onConfirm: async () => {
            await this.deleteSubCategory(subCategoryId);
          },
        });
      } else {
        this.$message.show({
          title: 'Supprimer une sous-rubrique',
          text: `Il n'y a aucun document dans cette sous-rubrique. Vous pouvez la supprimer en toute sécurité.
          Souhaitez-vous supprimer la sous-rubrique ${subCategory.name} ?`,
          confirmText: 'Supprimer',
          hasConfirm: true,
          cancelText: 'Annuler',
          hasCancel: true,
          onConfirm: async () => {
            await this.deleteSubCategory(subCategoryId);
          },
        });
      }
    },
    async deleteSubCategory(subCategoryId) {
      this.isLoading = true;
      try {
        await documentSubCategoryApi.deleteSubCategory(subCategoryId);
        this.$notification.show({ text: 'Sous-rubrique supprimée avec succès !' });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression de la sous-rubrique',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.getSubCategories();
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass'>
.document-sub-categories
  .body
    @include screen
  >.header
    @include header
  .breadcrumbs
    @include breadcrumbs

.document-sub-categories
  .app-table
    .data
      overflow: visible

    .button-container
      display: flex
      flex-direction: column
      align-items: flex-end
      position: relative

  .modal
    form
      padding: 0 50px

      .app-input
        margin: 50px 0
      .app-button
        margin: auto

</style>
