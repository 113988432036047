import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/company-categories`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getByCompany = async (companyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/companies/${companyId}/company-categories`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getById = async (companyCategoryId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/company-categories/${companyCategoryId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const create = async (companyCategory) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/company-categories`, companyCategory);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const edit = async (companyCategory) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/company-categories/${companyCategory.companyCategoryId}`, {
      name: companyCategory.name,
      label: companyCategory.label,
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const deleteOne = async (companyCategoryId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/company-categories/${companyCategoryId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const companyCategory = {};
companyCategory.getByCompany = getByCompany;
companyCategory.getAll = getAll;
companyCategory.getById = getById;
companyCategory.create = create;
companyCategory.edit = edit;
companyCategory.delete = deleteOne;

export default companyCategory;
