<template>
  <nav class="breadcrumb">
    <ol>
      <li v-for="item in items" :key="item.route.name">
        <p>
          <span>/ </span>
          <router-link :to="item.route">{{ item.name }}</router-link>
        </p>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'breadcrumb',
  props: {
    items: {
      // Format : [{ route: { name: '', params:{} }, label: 'home' }, ...]
      type: Array,
      default: () => {},
    },
  },
};
</script>

<style lang='sass' scoped>
.breadcrumb
  display: flex
  color: $secondary-light

  ol
    list-style: none
    padding: 0
    margin: 0
    display: flex
    flex-direction: row

  li
    margin-left: 4px

  a
    color: $secondary-light

    &:hover
      text-decoration: underline
</style>
