<template>
  <div class="pushes hedifys-21">
    <!-- HEADER / TITRE -->
    <div class='header grid-x'>
      <div class='cell auto'>
        <h1>Push de prix</h1>
      </div>
      <div class="cell shrink">
        <app-button variable="primary" size="large" icon="add" @click="showCreatePushModale = true">
          Ajouter un push de prix
        </app-button>
      </div>
    </div>
    <!-- CONTENT -->
    <div class="body">
      <p class="description">Vous pouvez ici mettre à jour la valeur d’un lot métré à travers toutes les versions.</p>

      <!-- TABLE -->
      <app-table :headers="tableHeader" :data="pushes" :loading="isLoading">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="memberPhoto" slot-scope="{ data }">
          <div class="photo">
            <img v-if="data.member && data.member.photo" :src="data.member.photo" />
            <avatar v-else />
          </div>
        </template>
        <template slot="memberName" slot-scope="{ data }">
          <strong>{{ data.member ? `${data.member.firstname} ${data.member.lastname}` : '' }}</strong>
        </template>
        <template slot="lotSurveyName" slot-scope="{ data }">
          <p>{{ data.lotSurvey ? data.lotSurvey.name : '' }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY - HH:mm') }}</p>
        </template>
        <template slot="coefficient" slot-scope="{ data }">
          <p>{{ data.coefficient }}</p>
        </template>
        <template slot="models" slot-scope="{ data }">
          <p v-if="rangesEnum[data.model]">{{ rangesEnum[data.model] }} ({{ data.nbVersion }})</p>
          <p v-else-if="data.model">{{ data.model }} ({{ data.nbVersion }})</p>
          <p v-else>-</p>
        </template>
        <template slot="empty-table">
          <p>Aucune donnée de disponible</p>
        </template>
      </app-table>
    </div>
    <!-- EDIT MODAL -->
    <app-modal class="add-push" :show="showCreatePushModale" size="small" title="Ajouter un push"
      @update:show="closeAddPushModale">
      <form ref="variable" @submit.prevent="createPush">
        <div class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-select label="Lot métré concerné" v-model="push.lotSurveyId" :options="lotSurveyOptions" required />
          </div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-input label="Coefficient à appliquer" placeholder="Coefficient..." v-model.number="push.coefficient"
              type="number" :step="0.01" required />
          </div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-select label="Gamme" v-model="rangeId" :options="ranges" />
          </div>
        </div>
        <div class="grid-x grid-margin-x row">
          <label class="cell auto">Modèle</label>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell shrink">
            <app-radio name="model-choice" value="all" v-model="modelChoice">Tous</app-radio>
          </div>
          <div class="cell shrink">
            <app-radio name="model-choice" value="one" v-model="modelChoice">Choisir un modèle</app-radio>
          </div>
        </div>
        <div v-if="modelsFormatted && modelsFormatted.length && modelChoice === 'one'" class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-select label="Modèles" v-model="push.model" :options="modelsFormatted" required />
          </div>
        </div>
        <div class="edit-variable-input-submit-container grid-x grid-margin-x">
          <div class="cell auto" />
          <div class='cell shrink'>
            <app-button variable="primary" size="large" icon="add" type="submit">
              Ajouter un push
            </app-button>
          </div>
          <div class="cell auto" />
        </div>
      </form>
    </app-modal>
  </div>
</template>

<script>
import logApi from '@/services/api/log';
import lotSurveyApi from '@/services/api/lotSurvey';
import rangeApi from '@/services/api/range';
import modelApi from '@/services/api/model';
import avatar from '@/assets/img/avatar.svg?inline';
import utils from '@/services/utils/utils';
import AppModal from '@/basics/components/AppModal.vue';

import rangesEnum from '@/services/enums/ranges.enum';

export default {
  name: 'pushes',
  components: {
    avatar,
    AppModal,
  },
  metaInfo: {
    title: 'Pushes de prix',
  },
  data() {
    return {
      rangesEnum,
      tableHeader: [
        { label: 'Membre ayant réalisé le push', key: 'memberPhoto', size: 'shrink' },
        { label: '', key: 'memberName', size: 'auto' },
        { label: 'Paramètre', key: 'lotSurveyName', size: 3 },
        { label: 'Date', key: 'createdAt', size: 2 },
        { label: 'Coefficient', key: 'coefficient', size: 2 },
        { label: 'Modèle (nombre versions)', key: 'models', size: 2 },
      ],
      pushes: [],
      push: {
        coefficient: null,
        lotSurveyId: null,
        model: null,
      },
      isLoading: true,
      lotsSurveys: null,
      lotSurveyOptions: null,

      ranges: null,
      rangeId: null,
      models: null,
      modelsFormatted: null,
      modelChoice: 'all',

      showCreatePushModale: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getAllModels();
    await this.getLotsSurveys();
    await this.getRanges();
    await this.getPushes();

    this.isLoading = false;
  },
  watch: {
    rangeId: {
      handler() {
        this.filterModels();
      },
    },
  },
  methods: {
    async getPushes() {
      try {
        this.pushes = await logApi.getAllPushes();
        this.pushes = this.pushes.map((push) => {
          if (push.model && !this.rangesEnum[push.model]) {
            const model = this.models.find((m) => m.modelId === push.model).name;
            return { ...push, model };
          }
          return push;
        });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des pushes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLotsSurveys() {
      try {
        this.lotsSurveys = await lotSurveyApi.getAll();
        this.lotSurveyOptions = utils.formatOptions(
          this.lotsSurveys,
          (o) => o.lotSurveyId,
          (o) => o.name,
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getRanges() {
      try {
        const ranges = await rangeApi.getAll();
        this.ranges = utils.formatOptions(
          ranges,
          (o) => o.rangeId,
          (o) => o.name,
          'choose',
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des gammes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAllModels() {
      try {
        this.models = (await modelApi.getAll(
          null,
          null,
          null,
          null,
          true,
        )).data;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des modèles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.filterModels();
    },
    filterModels() {
      const models = this.rangeId ? this.models.filter((m) => m.range.rangeId === this.rangeId) : this.models;
      this.modelsFormatted = utils.formatOptions(
        models,
        (o) => o.modelId,
        (o) => o.name,
        'choose',
      );
    },
    closeAddPushModale() {
      this.showCreatePushModale = false;
      this.push = {
        lotSurveyId: null,
        coefficient: null,
        model: null,
      };
      this.rangeId = null;
      this.modelsFormatted = null;
    },
    async createPush() {
      try {
        if (this.modelChoice === 'all') {
          const range = this.ranges.find((r) => r.name === this.rangeId);
          this.push.model = this.rangesEnum[range.label] || 'ALL';
        }
        await logApi.pushLotSurveyPrices(this.push);
        this.closeAddPushModale();

        this.$notification.show({ text: 'Ce push a été crée avec succès !' });

        this.isLoading = true;
        this.getPushes();
        this.isLoading = false;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la création du push des prix ',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.pushes
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .description
    @include description
  .photo
    background-color: $line
    border-radius: 50%
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    svg
      margin-top: 9px
      width: 30px
      height: 30px
      fill: $off-white
</style>
