<template>
  <div class="reset-password hedifys-21 grid-x">
    <div class="form-container cell auto">
      <div>
        <div class="row">
          <h1>Changer votre mot de passe perdu</h1>
        </div>
        <div class="row">
          <h3>Entrez un nouveau mot de passe pour votre compte Hedifys.</h3>
        </div>
        <form @submit.prevent="updatePassword">
          <div class="row">
            <app-input type="password" v-model="password" label="Mot de passe" placeholder="Mot de passe..." required />
          </div>
          <div class="row">
            <app-button theme="primary" type="submit">Modifier</app-button>
          </div>
        </form>
      </div>
      <div class="copyright">
        <p>© {{ $dayjs().year() }} - STARLYD</p>
      </div>
    </div>
    <div class="logo-container cell auto">
      <div>
        <hedifys />
        <h1>HEDIFYS</h1>
      </div>
    </div>
  </div>
</template>

<script>
import hedifys from '@/assets/logos/hedifys.svg?inline';
import member from '@/services/api/member';

export default {
  name: 'reset-password',
  components: {
    hedifys,
  },
  metaInfo: {
    title: 'Changer votre mot de passe perdu',
  },
  data() {
    return {
      password: null,
    };
  },
  methods: {
    async updatePassword() {
      try {
        if (this.$route.query.token) {
          await member.updatePassword(this.$route.query.token, this.password);
        }
        this.$notification.show({ text: 'Votre mot de passe a été mofié' });
        this.$router.push({ name: 'login' });
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du changement du mot de passe',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.reset-password
  height: 100%
  h3
    color: $label
  .row
    @include row
  .form-container
    @include centered-container
    position: relative
    > div
      width: 75%
      min-width: 250px
    .copyright
      position: absolute
      bottom: 24px
      text-align: center
      p
        color: $info
    button
      width: 100%
    a
      @include hyperlink
      text-align: center
      width: 100%
      display: block
  .logo-container
    background-image: linear-gradient($gradient-light, $gradient-dark)
    @include centered-container
    position: relative
    svg
      width: 135px
    h1
      color: #ffffff
      font-weight: 300
      text-align: center
      margin-top: 16px
    .version
      position: absolute
      bottom: 24px
      color: white
      .bubble
        background-color: white
        border-radius: 15px
        margin-left: 20px
        color: $title
        padding: 5px 10px
</style>
