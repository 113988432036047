var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-members hedifys-21"},[_c('div',{staticClass:"header grid-x"},[_vm._m(0),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","type":"submit","icon":"add"},on:{"click":function($event){_vm.isInviteModalOpen = true}}},[_vm._v("Ajouter un membre")])],1)]),_c('tab-nav',{attrs:{"tabs":_vm.tabs}}),(!_vm.isLoading)?_c('div',{staticClass:"body"},[_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.members,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"photo",fn:function(ref){
var data = ref.data;
return [(data.photo)?_c('img',{staticClass:"photo",attrs:{"alt":"Photo de profil","src":data.photo}}):_c('div',{staticClass:"avatar"},[_c('avatar')],1)]}},{key:"lastname",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.lastname))])]}},{key:"firstname",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.firstname))])]}},{key:"email",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.email))])]}},{key:"phone",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.phone))])]}},{key:"secondaryPhone",fn:function(ref){
var data = ref.data;
return [(data.secondaryPhone)?_c('p',[_vm._v(_vm._s(data.secondaryPhone))]):_c('p',[_vm._v("Non-renseigné")])]}},{key:"jobTitle",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.formatJob(data.jobTitle)))])]}},{key:"rules",fn:function(ref){
var data = ref.data;
return [(data.memberId !== _vm.me.memberId)?_c('app-button',{attrs:{"theme":"warning","size":"small","icon":"remove","type":"button"},on:{"click":function($event){return _vm.removeMember(data)}}},[_vm._v("Enlever les droits")]):_vm._e()]}}],null,false,864070407)},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2)],1):_c('div',{staticClass:"body"},[_c('div',{staticClass:"card spinner-container"},[_c('app-spinner')],1)]),_c('app-modal',{attrs:{"show":_vm.isInviteModalOpen,"size":"small","title":"Inviter un membre"},on:{"update:show":function($event){_vm.isInviteModalOpen = false}}},[_c('form',{ref:"agency",on:{"submit":function($event){$event.preventDefault();return _vm.inviteMember($event)}}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-input',{attrs:{"label":"Email du membre","placeholder":"Email...","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x"},[_c('div',{staticClass:"cell auto"}),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add","type":"submit"}},[_vm._v("Inviter le membre")])],1),_c('div',{staticClass:"cell auto"})])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Membres")])])}]

export { render, staticRenderFns }