<template>
  <div class="project hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="project && project.reference"
          :items="[
            { route: { name: 'projects'}, name: 'Chantiers'},
            { route: { name: 'projects-projectId', params: { projectId: $route.params.projectId }}, name: `${project.reference} ${customerName}` }
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="new-header grid-x">
      <div class="cell auto">
        <h1>{{ project && project.reference ? `${project.reference} ${customerName}` : '' }}</h1>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body" v-if="!isLoading">
      <section class="hedifys-chantier" id="project-project-step">
        <pdf-creation-modal
          :showModal="showPdfModal"
          :project="project"
          @close="showPdfModal = false"
          type="project"
          :companies="projectCompanies"
        />
        <companies-add-modal
          v-if="companies && companyCategories && projectStepsWithPartner"
          @companies-updated="getData"
          :show="showCompaniesModal"
          @close="showCompaniesModal = false"
          :companies="companies"
          :companyCategories="companyCategories"
          :projectStepsWithPartner="projectStepsWithPartner"
        />
        <div v-if="projectSteps && projectSteps.length > 0 && !isLoading">
          <vk-grid gutter="collapse" class="uk-child-width-expand uk-flex-middle header">
            <h1 class="title uk-width-1-4@m">Planning de chantier</h1>
            <div class="uk-width-expand@m"></div>
            <div class="title uk-width-auto@m">
              <vk-button class="format-choice active" @click="showPdfModal = true">Exporter</vk-button>
              <vk-button class="format-choice partner" @click="showCompaniesModal = true">
                <img src="/partner_add.svg" alt="" />
              </vk-button>
            </div>
          </vk-grid>
          <project-header :project="project" />
          <vk-grid gutter="collapse" class="uk-child-width-expand uk-flex-middle header filters-bar">
            <div
              class="pending uk-width-auto@m"
              v-if="isListFormat"
              :class="{ checked: pendingFilter }"
            >
              <input
                type="checkbox"
                name="filter-pending"
                id="filter-pending"
                class="uk-checkbox"
                v-model="pendingFilter"
                @change="filterChanged($event, 'pending')"
              />
              <label for="filter-pending">
                <div class="tag-container">
                  <h5 class="tag tag-pending">En attente</h5>
                </div>
              </label>
            </div>
            <div
              class="in-progress uk-width-auto@m"
              v-if="isListFormat"
              :class="{ checked: inProgressFilter }"
            >
              <input
                type="checkbox"
                class="uk-checkbox"
                name="filter-in-progress"
                id="filter-in-progress"
                v-model="inProgressFilter"
                @change="filterChanged($event, 'in_progress')"
              />
              <label for="filter-in-progress">
                <div class="tag-container">
                  <h5 class="tag tag-progress">En cours</h5>
                </div>
              </label>
            </div>
            <div class="todo uk-width-auto@m" v-if="isListFormat" :class="{ checked: todoFilter }">
              <input
                type="checkbox"
                name="filter-todo"
                id="filter-todo"
                class="uk-checkbox"
                v-model="todoFilter"
                @change="filterChanged($event, 'todo')"
              />
              <label for="filter-todo">
                <div class="tag-container">
                  <h5 class="tag tag-todo">À faire</h5>
                </div>
              </label>
            </div>
            <div class="delay uk-width-auto@m" v-if="isListFormat" :class="{ checked: lateFilter }">
              <input
                type="checkbox"
                name="filter-late"
                id="filter-late"
                class="uk-checkbox"
                v-model="lateFilter"
                @change="filterChanged($event, 'late')"
              />
              <label for="filter-late">
                <div class="tag-container">
                  <h5 class="tag tag-late">En retard</h5>
                </div>
              </label>
            </div>
            <div class="done uk-width-auto@m" v-if="isListFormat" :class="{ checked: doneFilter }">
              <input
                type="checkbox"
                id="filter-done"
                name="filter-done"
                class="uk-checkbox"
                v-model="doneFilter"
                @change="filterChanged($event, 'done')"
              />
              <label for="filter-done">
                <div class="tag-container">
                  <h5 class="tag tag-done">Fait</h5>
                </div>
              </label>
            </div>
            <div
              class="to-confirm uk-width-auto@m"
              v-if="isListFormat"
              :class="{ checked: toConfirmFilter }"
            >
              <input
                type="checkbox"
                id="filter-to-confirm"
                name="filter-to-confirm"
                class="uk-checkbox"
                v-model="toConfirmFilter"
                @change="filterChanged($event, 'to_confirm')"
              />
              <label for="filter-to-confirm">
                <div class="tag-container">
                  <h5 class="tag tag-to-confirm">À confirmer</h5>
                </div>
              </label>
            </div>
            <div class="uk-width-expand@m"></div>
            <div class="uk-width-auto@m header-week" v-if="isListFormat">
              <span>Filtrer par semaine</span>
              <toggle-button
                :sync="true"
                v-model="isWeeklyDisplay"
                color="#008DFF"
                @change="filterChanged($event, 'week_display')"
              />
            </div>
            <div class="uk-width-auto@m">
              <vk-button
                class="format-choice"
                :class="{ active: isListFormat }"
                @click="isListFormat = true"
                >Liste</vk-button
              >
              <vk-button
                class="format-choice"
                :class="{ active: !isListFormat }"
                @click="(isListFormat = false), setFocusedStep()"
                >Gantt</vk-button
              >
            </div>
          </vk-grid>
          <vk-grid
            v-if="isWeeklyDisplay && isListFormat"
            gutter="collapse"
            class="uk-child-width-expand header weekly"
          >
            <div class="uk-width-expand@m week week-name">
              <span>{{
                `${("Semaine " + this.selectedWeek.number).toUpperCase()} - du ${$dayjs(
                  selectedWeek.monday
                ).format("DD MMMM YYYY")} au ${$dayjs(selectedWeek.sunday).format("DD MMMM YYYY")}`
              }}</span>
            </div>
            <div class="uk-width-auto@m actions">
              <vk-grid  gutter="collapse">
                <div class="previous">
                  <!-- eslint-disable-next-line -->
                  <div class="icon-arrow_left" @click="filterChanged($event, 'week_previous')"></div>
                </div>
                <div class="datepicker">
                  <datepicker
                    v-model="selectedWeek.monday"
                    format="dd MMMM yyyy"
                    :language="french"
                    :disabledDates="{ days: [2, 3, 4, 5, 6, 0] }"
                    :monday-first="true"
                    @input="filterChanged($event, 'week_monday')"
                  />
                </div>
                <div class="next">
                  <div class="icon-arrow_right" @click="filterChanged($event, 'week_next')"></div>
                </div>
              </vk-grid>
            </div>
          </vk-grid>
          <section v-if="isListFormat">
            <div v-if="filteredProjectSteps && filteredProjectSteps.length > 0">
              <vk-grid
                v-for="projectStep in filteredProjectSteps"
                :key="`project-step-${projectStep.projectStepId}`"
                gutter="collapse"
                class="project-steps uk-flex uk-flex-middle"
              >
                <project-step
                  :projectStep="projectStep"
                  :projectSteps="filteredProjectSteps"
                  listName="project"
                  :companies="companies"
                  :companyCategories="companyCategories"
                  :projectId="projectStep.project.projectId"
                  :key="`project-step-${projectStep.projectStepId}`"
                  @update="getData"
                  @delayFollowingProjectSteps="openDelayModal"
                  @openModal="openProjectStepModal"
                  @addProjectStep="addProjectStep"
                  :isProjectStarted="
                    projectSteps.some(ps => ps.type === 'START' && ps.status === 'DONE')
                  "
                  :isProjectClosed="
                    projectSteps.some(ps => ps.type === 'CLOSE' && ps.status === 'DONE')
                  "
                />
              </vk-grid>
            </div>
          </section>

        </div>
        <div class="nothing" v-else-if="!isLoading">
          <div v-if="project && project.buildFilter && project.buildFilter.buildFilterId" class="date">
            <h3>Planning du chantier</h3>
            <p>
              Vous devez indiquer une date prévisionnelle d'ouverture de chantier pour générer le
              planning.
            </p>
            <label>Date d'ouverture du chantier <sup>*</sup> :</label>
            <datepicker
              v-model="generationStart"
              :language="french"
              :value="
                project.constructionPermitPlannedDate
                  ? $dayjs(project.constructionPermitPlannedDate)
                      .add(60, 'days')
                      .startOf('isoWeek')
                      .toDate()
                  : null
              "
              :disabledDates="{
                days: [6, 5, 4, 3, 2, 0]
              }"
            />
            <vk-button class="primary button" @click="generate">Valider</vk-button>
          </div>
          <div v-else-if="project" class="date">
            <p class="title">Planning du chantier</p>
            <p class="details">Vous devez d'abord ajouter un plan de travail</p>
            <router-link
              :to="{
                name: 'projects-projectId',
                params: { projectId: project.projectId }
              }"
            >
              <button class="primary button">Retour au projet</button>
            </router-link>
          </div>
        </div>
        <project-step-modal
          :project="project"
          :projectStep="selectedProjectStep"
          :projectSteps="projectSteps"
          :companies="companies"
          :companyCategories="companyCategories"
          :modalType="modalType"
          :openModal="showProjectStepModal"
          :listName="'project'"
          :dropdownPosition="dropdownPosition"
          @close="showProjectStepModal = false"
          @changeType="changeType"
          @update="getData"
        />
      </section>
    </div>
    <div class="body" v-else>
      <div class="spinner-container">
        <app-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';

import projectStep from '@/services/api/projectStep';
import project from '@/services/api/project';
import company from '@/services/api/company';
import companyCategory from '@/services/api/companyCategory';
import customer from '@/services/api/customer';
import ProjectStep from '@/views/projectStep/ProjectStep.vue';
import ProjectHeader from '@/views/project/ProjectHeader.vue';
import PdfCreationModal from '@/views/pdf/PdfCreationModal.vue';
import projectStepModal from '@/views/projectStep/ProjectStepModal.vue';
import CompaniesAddModal from '@/views/project/CompaniesAddModal.vue';

export default {
  name: 'project-project-step',
  components: {
    TabNav,
    Breadcrumb,
    Datepicker,
    ProjectStep,
    ProjectHeader,
    PdfCreationModal,
    projectStepModal,
    CompaniesAddModal,
  },
  metaInfo() {
    return {
      title: this.project && this.project.reference ? `${this.project.reference} ${this.customerName} – Chantier` : 'Chantier',
    };
  },
  data() {
    return {
      tabs: [
        {
          route: {
            name: 'projects-projectId',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'projects-projectId-steps',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Tâches',
        },
        {
          route: {
            name: 'projects-projectId-notes',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Notes',
        },
      ],

      french: fr,
      steps: [],
      filteredSteps: [],
      conductors: [],
      selectedConductor: null,
      selectedProjectStep: null,
      projectSteps: [],
      filteredProjectSteps: [],
      companies: [],
      companyCategories: [],
      projects: [],
      project: null,
      generationStart: null,
      isLoading: true,

      pendingFilter: true,
      inProgressFilter: true,
      todoFilter: true,
      lateFilter: true,
      doneFilter: false,
      toConfirmFilter: true,
      isListFormat: true,

      taskToDelay: null,
      showDelayModal: false,
      delayInWeeks: null,

      // Gantt
      dayWidth: 40,
      showPdfModal: false,
      isSticky: false,
      stickyOffsetLeft: 0,
      stepFocusedId: null,
      focusedStepPositionX: 0,
      focusedStepPositionY: 0,
      focusedStepWidth: 0,
      ganttPositionY: 0,

      // Type de modal à ouvrir depuis une projetStep
      modalType: null,
      showProjectStepModal: false,
      dropdownPosition: null,

      days: null,
      weeks: null,
      months: null,
      startDate: null,
      endDate: null,

      projectCompanies: [],

      projectStepsWithPartner: [],
      showCompaniesModal: false,

      isWeeklyDisplay: false,
      selectedWeek: {
        monday: null,
        sunday: null,
        number: null,
        current: {
          monday: null,
          sunday: null,
          number: null,
        },
        year: null,
      },

      allCompanies: null,
      allCompanyCategories: null,
    };
  },
  watch: {
    showCompaniesModal(value) {
      if (value) {
        this.getProjectStepsWithPartner();
      }
    },
    isListFormat(value) {
      if (!value) {
        this.$router.push({ name: 'projects-projectId-steps-gantt' });
      }
    },
  },
  computed: {
    customerName() {
      if (this.project && this.project.customer) {
        return this.project.customer.type === 'COMPANY' ? this.project.customer.companyName : customer.getCustomersNames(this.project.customer);
      }
      return '';
    },
  },
  async mounted() {
    this.isLoading = true;
    // Ces requêtes ne vont pas changer en fonction de la page,
    // comme les project steps, on peut donc ne les charger qu'une fois
    this.allCompanies = (await company.getAll()).data;
    this.allCompanyCategories = await companyCategory.getAll();

    await this.getData();
    this.getProjectStepsWithPartner();

    this.selectedWeek = {
      monday: this.$dayjs()
        .year(new Date().getFullYear())
        .isoWeek(this.$dayjs(new Date()).isoWeek())
        .startOf('isoWeek')
        .format(),
      sunday: this.$dayjs()
        .year(new Date().getFullYear())
        .isoWeek(this.$dayjs(new Date()).isoWeek())
        .endOf('isoWeek')
        .format(),
      number: this.$dayjs(new Date()).isoWeek(),
      current: {
        monday: this.$dayjs()
          .year(new Date().getFullYear())
          .isoWeek(this.$dayjs(new Date()).isoWeek())
          .startOf('isoWeek')
          .format(),
        sunday: this.$dayjs()
          .year(new Date().getFullYear())
          .isoWeek(this.$dayjs(new Date()).isoWeek())
          .endOf('isoWeek')
          .format(),
        number: this.$dayjs(new Date()).isoWeek(),
      },
      year: new Date().getFullYear(),
    };
    this.isLoading = false;
  },
  directives: {
    sticky: {
      inserted(el, binding) {
        const offsetTop0 = el.offsetTop;
        const f = (evt) => {
          binding.value(evt, el, offsetTop0);
          const rect = el.getBoundingClientRect();
          if (rect.width === 0) {
            window.removeEventListener('scroll', f);
          }
        };
        window.addEventListener('scroll', f);
      },
    },
  },
  methods: {
    async getData() {
      try {
        this.project = await project.getById(this.$route.params.projectId);

        this.generationStart = this.project.constructionPermitPlannedDate
          ? this.$dayjs(this.project.constructionPermitPlannedDate)
            .add(60, 'days')
            .startOf('isoWeek')
            .toDate()
          : null;
        this.projectSteps = await projectStep.getByProject(this.$route.params.projectId);
        this.filterShowToConfirm();

        this.filteredProjectSteps = this.projectSteps;
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } catch (error) {
        //
      }
      if (this.allCompanies && this.allCompanies.length > 0) {
        const alphaSorted = this.allCompanies.sort((a, b) => a.name.localeCompare(b.name));
        const onlyFavorite = alphaSorted.filter((c) => c.isFavorite);
        const noFavorite = alphaSorted.filter((c) => !c.isFavorite);

        this.companies = onlyFavorite.concat(noFavorite);
      } else {
        this.companies = [];
      }

      if (this.projectSteps && this.projectSteps.length > 0) {
        this.projectCompanies = this.companies.filter((c) => {
          const relatedProjectStep = this.projectSteps.find(
            (ps) => ps.company && ps.company.companyId === c.companyId,
          );
          return relatedProjectStep;
        });
      }
      try {
        this.companyCategories = this.allCompanyCategories.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        //
      }

      this.openModal = false;

      this.updateBoundaries();

      // this.isLoading = false;
    },
    updateBoundaries() {
      this.startDate = null;
      this.endDate = null;

      if (this.projectSteps && this.projectSteps.length > 0) {
        this.projectSteps.forEach((task) => {
          if (this.endDate < task.closedAt || !this.endDate) {
            this.endDate = task.closedAt;
          }
          if (this.startDate > task.startedAt || !this.startDate) {
            this.startDate = task.startedAt;
          }
        });

        this.startDate = this.$dayjs(this.startDate)
          .subtract(1, 'day')
          .startOf('month')
          .startOf('day');
        this.endDate = this.$dayjs(this.endDate)
          .add(1, 'day')
          .endOf('month')
          .startOf('day');

        this.days = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'day') + 1;
        this.weeks = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'week');
        this.months = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'month') + 1;
        if (
          this.$dayjs(this.endDate)
            .startOf('isoWeek')
            .isoWeek()
          === this.$dayjs({ ...this.startDate })
            .add(this.weeks, 'week')
            .isoWeek()
        ) {
          this.weeks -= 1;
        }
      }
    },
    setFocusedStep() {
      // Si le projet est en statut "En cours", on affiche la 1ère tâche dont le statut de démarrage est soit :
      // "à faire", "en cours" ou "en retard"
      if (this.project.status === 'IN_PROGRESS') {
        // On boucle sur tous les projectSteps jusqu'au 1er dont la valeur de startStatus correspond
        for (let i = 0; i < this.projectSteps.length; i += 1) {
          if (
            this.projectSteps[i].startStatus === 'TODO'
            || this.projectSteps[i].startStatus === 'IN_PROGRESS'
            || this.projectSteps[i].startStatus === 'LATE'
          ) {
            // On définit la valeur de stepFocusedId à l'ID du projectStep dont on souhaite récupérer la position
            this.stepFocusedId = this.projectSteps[i].projectStepId;
            return;
          }
        }
      }

      // On définit la valeur de stepFocusedId à l'ID du 1er projectStep si le projectStatus n'est pas à IN_PROGRESS
      // ou si aucun projectStep ne correspond aux statuts "à faire", "en cours" ou "en retard"
      this.stepFocusedId = this.projectSteps[0].projectStepId;
    },
    getFocusedStepPosition(value) {
      // On assigne les valeurs de position et de dimension du projectStep récupéré à focusedStepPositionX, focusedStepPositionY et focusedStepWidth
      this.focusedStepPositionX = value.positionX;
      this.focusedStepPositionY = value.positionY;
      this.ganttPositionY = this.$refs.scroll.$el.offsetTop;
      this.focusedStepWidth = value.width;

      this.setScrollPosition();
    },
    setScrollPosition() {
      // On attend le nouveau chargement du DOM pour avoir accès à la ref du gantt
      this.$nextTick(() => {
        // On calcule la valeur de scrollLeft pour placer la tâche souhaitée au centre du gantt horizontalement
        this.$refs.scroll.$el.scrollLeft = this.focusedStepPositionX - (window.innerWidth / 2 - this.focusedStepWidth / 2);

        // On calcule la valeur du scroll vertical pour placer la tâche souhaitée au centre du gantt verticalement
        const scrollY = this.focusedStepPositionY
          + this.ganttPositionY
          - (window.innerHeight + (96 + 60 - 40)) / 2;

        // On appel la fonction scrollAnimation avec pour destination la position en Y du gantt sur 1 seconde
        this.scrollAnimation(scrollY, 1000, this.enableUserScroll);
      });
    },
    scrollAnimation(destination, duration, callback) {
      // On récupère la position de la fenêtre et on créer un horodatage
      const start = window.pageYOffset;
      const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

      // On récupère les infoamrtions de la taille de la fenêtre ainsi que de la destination
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
      );
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
      const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
          ? documentHeight - windowHeight
          : destinationOffset,
      );

      // On définit la fonction de easeInOutQuad pour créer une animation non linéaire
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

      // Si requestAnimationFrame n'est pas supporté par le navigateur
      if (!('requestAnimationFrame' in window)) {
        window.scroll(0, destinationOffsetToScroll);
        return;
      }

      // On désactive le scroll utilisateur durant le scroll auto
      window.addEventListener('wheel', this.disableUserScroll, { passive: false });

      // Si non si requestAnimationFrame est supporté par le navigateur
      function scroll() {
        const now = 'now' in window.performance ? performance.now() : new Date().getTime();
        const time = Math.min(1, (now - startTime) / duration);
        const timeFunction = easeInOutQuad(time);

        // On scroll verticalement
        window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start));

        // On vérifie si la destination est atteinte ou si le temps de l'animation est écoulé
        if (window.pageYOffset === destinationOffsetToScroll || now >= startTime + duration) {
          callback();
          return;
        }

        requestAnimationFrame(scroll);
      }

      scroll();
    },
    enableUserScroll() {
      window.removeEventListener('wheel', this.disableUserScroll);
    },
    disableUserScroll(event) {
      event.preventDefault();
    },
    openProjectStepModal(selectedProjectStep, modalType, position) {
      this.selectedProjectStep = selectedProjectStep;
      this.modalType = modalType;
      this.dropdownPosition = position;

      if (modalType === 'dropdown' && this.modalType === 'dropdown' && this.showProjectStepModal) {
        this.showProjectStepModal = false;
      } else {
        this.showProjectStepModal = true;
      }
    },
    sortByProjects() {
      const projectIds = [];
      this.projects = [];

      this.filteredProjectSteps.forEach((pS) => {
        if (!projectIds.includes(pS.project.projectId)) {
          projectIds.push(pS.project.projectId);
        }
      });

      projectIds.forEach((projectId) => {
        const projectStepsByProjectId = this.filteredProjectSteps.filter(
          (pS) => pS.project.projectId === projectId,
        );
        this.projects.push({
          project: {
            customer: projectStepsByProjectId[0].customer,
            member: projectStepsByProjectId[0].member,
            ...projectStepsByProjectId[0].project,
          },
          projectSteps: projectStepsByProjectId,
        });
      });
    },
    filterProjectSteps(pending, todo, inProgress, late, done, weekDisplay, toConfirm) {
      this.filteredProjectSteps = this.projectSteps.filter(
        (pS) => ((pending && pS.startStatus === 'PENDING' && pS.closeStatus === 'PENDING')
            || (todo && (pS.startStatus === 'TODO' || pS.closeStatus === 'TODO'))
            || (inProgress && pS.statusToDisplay === 'IN_PROGRESS')
            || (toConfirm && pS.showToConfirmThumbnail)
            || (late && (pS.statusToDisplay === 'LATE'))
              || (pending && pS.startStatus === 'PENDING' && pS.closeStatus === 'PENDING')
              || (done && pS.statusToDisplay === 'DONE'))
          && ((weekDisplay
            && (this.$dayjs(pS.startedAt).isBetween(
              this.$dayjs(this.selectedWeek.monday),
              this.$dayjs(this.selectedWeek.sunday),
            )
              || this.$dayjs(pS.closedAt).isBetween(
                this.$dayjs(this.selectedWeek.monday),
                this.$dayjs(this.selectedWeek.sunday),
              )
              || (this.$dayjs(pS.startedAt).isBefore(this.selectedWeek.monday)
                && this.$dayjs(pS.closedAt).isAfter(this.selectedWeek.sunday))))
            || !weekDisplay),
      );
      this.sortByProjects();
    },
    filterChanged(event, filterName) {
      if (filterName === 'pending') {
        this.filterProjectSteps(
          event.target.checked,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'todo') {
        this.filterProjectSteps(
          this.pendingFilter,
          event.target.checked,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'in_progress') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          event.target.checked,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'late') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          event.target.checked,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'done') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          event.target.checked,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'week_display') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          event.value,
          this.toConfirmFilter,
        );
      } else if (filterName === 'week_previous') {
        this.selectedWeek.monday = this.$dayjs(this.selectedWeek.monday)
          .subtract(1, 'week')
          .startOf('isoWeek')
          .format();
        this.selectedWeek.sunday = this.$dayjs(this.selectedWeek.monday)
          .endOf('isoWeek')
          .format();
        this.selectedWeek.number = this.$dayjs(this.selectedWeek.monday).isoWeek();
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'week_next') {
        this.selectedWeek.monday = this.$dayjs(this.selectedWeek.monday)
          .add(1, 'week')
          .startOf('isoWeek')
          .format();
        this.selectedWeek.sunday = this.$dayjs(this.selectedWeek.monday)
          .endOf('isoWeek')
          .format();
        this.selectedWeek.number = this.$dayjs(this.selectedWeek.monday).isoWeek();
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'week_monday') {
        this.selectedWeek.sunday = this.$dayjs(event)
          .endOf('isoWeek')
          .format();
        this.selectedWeek.number = this.$dayjs(event).isoWeek();
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'to_confirm') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          event.target.checked,
        );
      }
    },
    getdayjsObject(date) {
      return this.$dayjs(date)
        .utc()
        .startOf('day');
    },
    async generate() {
      try {
        await project.generate(this.project.projectId, this.generationStart);
        await this.getData();
        this.getProjectStepsWithPartner();

        this.showCompaniesModal = true;
      } catch (er) {
        //
      }
    },
    isInteger(event) {
      /* Vérifie que le charactère est bien un chiffre */
      const { key } = event;
      if (key === '.' || key === ',') {
        event.preventDefault();
        return false;
      }

      return true;
    },
    openDelayModal(task) {
      /* Delay the tasks after a task */
      this.taskToDelay = task;
      this.showDelayModal = true;
    },
    async delayFollowingProjectSteps() {
      this.isLoading = true;
      this.showDelayModal = false;

      /* Faire les mises à jour des tâches */
      await projectStep.delayFollowingProjectSteps(
        this.taskToDelay,
        this.projectSteps,
        this.delayInWeeks,
      );

      /* Rafraichir la liste */
      await this.getData();
      this.getProjectStepsWithPartner();

      this.isLoading = false;
    },
    async addProjectStep(newStep) {
      this.isLoading = true;

      /* Faire les mises à jour des tâches */
      await projectStep.add(newStep, this.projectSteps);

      /* Rafraichir la liste */
      await this.getData();
      this.getProjectStepsWithPartner();

      this.isLoading = false;
    },
    changeType(type) {
      this.modalType = type;
      this.openModal = true;
    },
    getProjectStepsWithPartner() {
      // obtenir les project_steps avec les partenaires
      const projectStepsWithPartner = this.projectSteps.filter(
        (step) => step.type !== 'START' && step.type !== 'CLOSE' && step.companyCategory.companyCategoryId,
      );

      // reordrer par companyCategoryId
      const tmpFilteredProjectStepsWithPartner = [];
      for (let index = 0; index < projectStepsWithPartner.length; index += 1) {
        const isCategoryExist = tmpFilteredProjectStepsWithPartner.some(
          (element) => element.companyCategory.companyCategoryId
            === projectStepsWithPartner[index].companyCategory.companyCategoryId,
        );

        if (!isCategoryExist) {
          tmpFilteredProjectStepsWithPartner.push(projectStepsWithPartner[index]);
        } else {
          const lastIndexElementWithCategory = tmpFilteredProjectStepsWithPartner
            .map((element) => element.companyCategory.companyCategoryId)
            .lastIndexOf(
              projectStepsWithPartner[index].companyCategory.companyCategoryId,
              tmpFilteredProjectStepsWithPartner.length - 1,
            );
          tmpFilteredProjectStepsWithPartner.splice(
            lastIndexElementWithCategory + 1,
            0,
            projectStepsWithPartner[index],
          );
        }
      }
      this.projectStepsWithPartner = tmpFilteredProjectStepsWithPartner;
    },
    filterShowToConfirm() {
      for (let i = 0; i < this.projectSteps.length; i += 1) {
      // On calcule la date correspondant au début de la tâche moins la période de préférence de l'entreprise
        const companyPeriodPreferenceWeekAgo = this.$dayjs(this.projectSteps[i].startedAt).subtract(this.projectSteps[i].company.periodPreference, 'week');

        // On vérifie si on se trouve entre le début de la tache et la date calculé précédemment
        const isBetween = this.$dayjs().isBetween(
          companyPeriodPreferenceWeekAgo,
          this.projectSteps[i].startedAt,
          null,
          '[]',
        );

        // On vérifie si la date de début de la tâche a été dépassé
        const isAfter = this.$dayjs().isAfter(this.projectSteps[i].startedAt);

        // On réunit les valeurs calculés afin de déterminer si on affiche ou non le label "À confirmer"
        if (
          (isBetween || isAfter)
          && this.projectSteps[i].company.companyId
          && this.projectSteps[i].showToConfirm
          && !this.projectSteps[i].confirmed
        ) {
          this.projectSteps[i].showToConfirmThumbnail = true;
        } else {
          this.projectSteps[i].showToConfirmThumbnail = false;
        }
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.project
  .body
    @include screen
  .new-header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  h3
    color: $primary
    margin: 0 16px 32px 0px
</style>

<style lang="scss">
#project-project-step {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: white;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $blue-medium;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  margin-bottom: 200px;
  .loading {
    display: flex;
    width: 100%;
    justify-content: center;
    height: auto;
    align-items: center;
    margin-top: 10%;
  }
  .vdp-datepicker {
    position: initial;
  }
  .vdp-datepicker__calendar {
    max-width: 450px;
  }
  .header-week > span {
    margin-right: 8px;
  }
  .header {
    .title {
      font-weight: 500;
      font-size: 25px;
      color: $gray-dark;
    }
    button {
      color: #ffffff;
      border-radius: $global-border-radius;
      border: none;
      background-color: $blue-medium;
    }
    input {
      margin-right: $global-border-radius;
      padding-left: 20px;
    }
    input[type="text"] {
      border-radius: $global-border-radius;
    }
    .filters {
      margin: 20px 0 10px 0;
      align-items: center;
      > div {
        margin: 0 0 0 10px;
        select {
          border-radius: $global-border-radius;
        }
      }
    }
    .format-choice {
      background: white;
      color: #3d455a;
      text-transform: initial;
      @include paragraph;
      padding: 12px 20px 10px;
      text-transform: uppercase;
      margin-left: 10px;
      &.active {
        background: $blue-medium;
        color: white;
      }
      &.partner {
        background-color: transparent;
        img {
          height: 30px;
        }
      }
    }

    .tag-pending {
      @include tag-secondary;
    }

    .tag-todo {
      @include tag-success;
    }

    .tag-progress {
      @include tag-primary;
    }

    .tag-late {
      @include tag-warning;
    }

    .tag-done {
      @include tag-neutral;
    }

    .tag-to-confirm {
      @include tag-danger;
    }

    .in-progress,
    .todo,
    .delay,
    .done,
    .pending,
    .to-confirm {
      position: relative;
      &:not(:first-child) {
        margin-left: 1rem;
      }

      label {
        position: relative;
        display: flex;
        cursor: pointer;
      }

      input {
        display: none;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;

        &:not(:checked) ~ label {
          opacity: 0.5;
        }
      }
    }
  }
  .filters-bar {
    margin-bottom: 30px;
  }
  .date {
    padding: 10px 30px;
    background: white;
    .details {
      color: $gray-medium;
    }
    label {
      display: inline-block;
      padding: 0.5rem 0;
      font-size: 0.85rem;
      @include heading-xs;
      color: $label;
    }
    input[type="text"] {
      max-width: 100%;
      width: 100%;
      border: 0 none;
      padding: 0 10px;
      background: #fff;
      color: #666;
      border: 1px solid #e5e5e5;
      border-radius: $global-border-radius;
      transition: 0.2s ease-in-out;
      transition-property: color, background-color, border;
      height: 40px;
      vertical-align: middle;
      display: inline-block;
    }
    button {
      margin-top: 30px;
      padding: 0.85rem 1.6rem 0.8rem;
      color: #a1b1c5;
      font-size: 1rem;
      line-height: 1;
      z-index: 1;
      text-align: center;
      border: 0;
      display: block;
      color: #ffffff;
      border: none;
      border-radius: $global-border-radius;
      background-color: $primary;
    }
    .vdp-datepicker__calendar header span {
      padding: 0.8rem 1rem;
    }
  }
  .nothing {
    background-color: transparent !important;
    .date {
      padding: 40px;
      border-radius: $global-border-radius;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
