<template>
  <div class="edito">
    <div class="left">
    </div>
    <div class="right">
      <div :key="data.announcementImage" class="img-container"><img v-if="data.announcementImage" class="image"
          :src="data.announcementImage" alt="Image">
      </div>
      <div class="text">
        <p class="title">{{ data.announcementTitle.toUpperCase() }}</p>
        <p class="description">{{ data.announcementDescription }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'edito',
  props: {
    data: Object,
  },
};
</script>
<style lang="sass">
.edito
  height: 15rem
  max-width: 72rem
  margin-top: 3rem
  display: flex
  flex-direction: row
  .left
    border-top-left-radius: 3px
    border-bottom-left-radius: 3px
    min-width: 4px
    background: #FFF
  .right
    border-top-right-radius: 3px
    border-bottom-right-radius: 3px
    width: 100%
    background: #044D97
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    padding: 12px 24px
    .img-container
      display: inline-block
      flex: 1
      max-height: 14rem
      display: flex
      justify-content: center
      align-items: center
      overflow: hidden
    .image
      width: 100%
    .text
      margin-left: 20px
      flex: 2
      .title
        color: #368DF7
        font-size: 1.1rem
        font-weight: 400
        width: 80%
        text-overflow: ellipsis
        overflow: hidden
        margin-bottom: 0.2rem
      .description
        color: #FFF
        font-size: 1rem
        font-weight: 400
        text-overflow: ellipsis
        overflow: hidden
        white-space: initial
</style>
