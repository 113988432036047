<template>
  <div class="rooms hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Distribution</h1>
      </div>
    </div>

    <!-- TABS -->
    <tab-nav :tabs="tabs" />

    <!-- CONTENT -->
    <div class="body">

      <!-- FILTER -->
      <div class="grid-x filter">
        <div class="cell medium-3">
          <app-select v-model="type" :options="roomTypes" />
        </div>
      </div>

      <!-- TABLE -->
      <div class="grid-x">
        <div class="cell auto">
          <app-table :headers="tableHeader" :data="rooms" :loading="isLoading">
            <template slot="loading">
              <app-spinner />
            </template>
            <template slot="icon" slot-scope="{ data }">
              <img :src="data.icon" alt="icon" class="room-icon"/>
            </template>
            <template slot="name" slot-scope="{ data }">
              <strong>{{ data.name }}</strong>
            </template>
            <template slot="type" slot-scope="{ data }">
              <p>{{ roomTypes.find(t => t.name === data.type).label }}</p>
            </template>
            <template slot="empty-table">
              <p>Aucune donnée de disponible</p>
            </template>
          </app-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import room from '@/services/api/room';
import roomTypes from '@/services/data/roomTypes.json';

export default {
  name: 'rooms',
  components: {
    TabNav,
  },
  metaInfo: {
    title: 'Distribution',
  },
  data() {
    return {
      // Navigation
      tabs: [
        { route: { name: 'categories-rooms' }, label: 'Pièces' },
        { route: { name: 'categories-floors' }, label: 'Niveaux' },
      ],

      // Filters
      roomTypes,
      type: this.$route.query.type || roomTypes[0].name,

      // Table
      tableHeader: [
        { label: 'Visuel', key: 'icon', size: 1 },
        { label: 'Pièce', key: 'name', size: 3 },
        { label: 'Habitabilité', key: 'type', size: 8 },
      ],
      isLoading: false,

      // Rooms
      rooms: [],
    };
  },

  mounted() {
    this.getRooms();
  },

  watch: {
    // Met à jour la query type dans la route pour déclencher
    type() {
      this.$router.push({
        query: {
          ...this.$route.query,
          type: this.type || undefined,
        },
      });

      this.getRooms();
    },
  },

  methods: {
    // Récupération des pièces
    async getRooms() {
      this.isLoading = true;

      try {
        this.rooms = await room.getAll(this.$route.query.type);
      } catch (error) {
        throw error;
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.rooms
  .body
    @include screen

  .header
    @include header
    padding-bottom: 4px

  .filter
    padding-bottom: 2rem

  .room-icon
    width: 25px
    height: 25px
</style>
