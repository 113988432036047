<template>
  <div class="hedifys-chantier" id="project-steps">
    <vk-grid gutter="collapse" class="uk-child-width-expand header" v-if="$route.params.companyId">
      <h1 class="title uk-width-1-2@m">Planning des chantiers</h1>
      <div class="uk-width-expand@m"></div>
      <div class="title uk-width-auto@m">
        <vk-button class="format-choice active" @click="showPdfModal = true">Exporter</vk-button>
      </div>
    </vk-grid>
    <div class="grid-x grid-margin-x">
      <div class="cell small-4 search">
        <app-search v-model="search" placeholder="Rechercher un chantier" size="small" />
      </div>
      <div class="cell small-3">
        <app-select class="large"
          v-if="companyCategoriesLabel && companyCategoriesLabel.length"
          v-model="filters.companyCategoryId"
          :options="companyCategoriesLabel"
          @input="getProjectSteps()"
        />
      </div>
      <div class="cell small-3">
        <app-select class="large"
          v-if="projectStepsTypes && projectStepsTypes.length"
          v-model="filters.type"
          :options="projectStepsTypes"
          @input="getProjectSteps()"
        />
      </div>
    </div>
    <vk-grid gutter="collapse" class="uk-child-width-expand header">
      <div class="pending uk-width-auto@m" v-if="$route.params.companyId" :class="{ checked : pendingFilter }">
        <label for="filter-pending">En attente</label>
        <input
          type="checkbox"
          name="filter-pending"
          id="filter-pending"
          class="uk-checkbox"
          v-model="pendingFilter"
          @change="filterChanged($event, 'pending')" />
      </div>
      <div class="in-progress uk-width-auto@m" :class="{ checked : inProgressFilter }">
        <label for="filter-in-progress">En cours</label>
        <input
          type="checkbox"
          class="uk-checkbox"
          name="filter-in-progress"
          id="filter-in-progress"
          v-model="inProgressFilter"
          @change="filterChanged($event, 'in_progress')" />
      </div>
      <div class="todo uk-width-auto@m" :class="{ checked : todoFilter }">
        <label for="filter-todo">A faire</label>
        <input
          type="checkbox"
          name="filter-todo"
          id="filter-todo"
          class="uk-checkbox"
          v-model="todoFilter"
          @change="filterChanged($event, 'todo')" />
      </div>
      <div class="delay uk-width-auto@m" :class="{ checked : lateFilter }">
        <label for="filter-late">En retard</label>
        <input
          type="checkbox"
          name="filter-late"
          id="filter-late"
          class="uk-checkbox"
          v-model="lateFilter"
          @change="filterChanged($event, 'late')" />
      </div>
      <div class="done uk-width-auto@m" v-if="$route.params.companyId" :class="{ checked : doneFilter }">
        <label for="filter-done">Finis</label>
        <input
          type="checkbox"
          id="filter-done"
          name="filter-done"
          class="uk-checkbox"
          v-model="doneFilter"
          @change="filterChanged($event, 'done')" />
      </div>
      <div class="to-confirm uk-width-auto@m" :class="{ checked : toConfirmFilter }">
        <label for="filter-to-confirm">À confirmer</label>
        <input
          type="checkbox"
          id="filter-to-confirm"
          name="filter-to-confirm"
          class="uk-checkbox"
          v-model="toConfirmFilter"
          @change="filterChanged($event, 'to_confirm')" />
      </div>
      <div class="uk-width-expand@m"></div>
    </vk-grid>
    <div class="loader-container" v-if="isLoading">
      <spinner></spinner>
    </div>
    <div v-else-if="projects && projects.length > 0">
      <div v-for="(project, index) in projects" :key="`project-step-grid-${project.projectId ? project.projectId : index}`">
        <project-detail :project="project.project" />
        <vk-grid v-for="(projectStep) in project.projectSteps"
          :key="`project-step-${projectStep.projectStepId}`" gutter="collapse"
          class="project-steps uk-flex uk-flex-middle">
          <project-step
            :projectStep="projectStep"
            :projectSteps="project.projectSteps"
            listName="tasks"
            :companies="companies"
            :companyCategories="companyCategories"
            :projectId="projectStep.project.projectId"
            :key="`project-step-${projectStep.projectStepId}`"
            @update="getData"
            @openModal="openProjectStepModal"/>
        </vk-grid>
      </div>
    </div>
    <div class="nothing" v-else>
      <p>Aucun projet en cours</p>
    </div>
    <project-step-modal
      :project="selectedProject"
      :projectStep="selectedProjectStep"
      :projectSteps="projectSteps"
      :companies="companies"
      :companyCategories="companyCategories"
      :modalType="modalType"
      :openModal="showProjectStepModal"
      :listName="'tasks'"
      :dropdownPosition="dropdownPosition"
      @close="showProjectStepModal = false"
      @changeType="changeType"
      @update="getData"
    />
    <pdf-creation-modal
      :showModal="showPdfModal"
      :projects="companyProjects"
      @close="showPdfModal = false"
      type="company"
      :company="company"
    />
  </div>
</template>

<script>
import utils from '@/services/utils/utils';
import { fr } from 'vuejs-datepicker/dist/locale';
import projectStep from '@/services/api/projectStep';
import company from '@/services/api/company';
import companyCategory from '@/services/api/companyCategory';
import ProjectStep from '@/views/projectStep/ProjectStep.vue';
import PdfCreationModal from '@/views/pdf/PdfCreationModal.vue';
import ProjectStepModal from '@/views/projectStep/ProjectStepModal.vue';
import projectDetail from '@/views/project/ProjectDetail.vue';
import Spinner from '@/components/general/Spinner.vue';
import projectStepsTypes from '@/services/data/projectStepsTypes.json';
import companyCategoryTypeLabel from '@/services/enums/companyCategoryTypeLabel.enum';

export default {
  name: 'project-steps',
  components: {
    ProjectStep,
    PdfCreationModal,
    ProjectStepModal,
    projectDetail,
    Spinner,
  },
  data() {
    return {
      steps: [],
      filteredSteps: [],
      conductors: [],
      selectedConductor: null,
      projectSteps: [],
      filteredProjectSteps: [],
      companies: [],
      company: null,
      companyCategories: [],
      companyCategoriesLabel: [], // Utilisé par l'app-select
      companyCategoryTypeLabel,
      projectStepsTypes,
      projects: [],
      pendingFilter: true,
      inProgressFilter: true,
      todoFilter: true,
      lateFilter: true,
      doneFilter: true,
      toConfirmFilter: true,
      filters: {
        companyCategoryId: this.$route.query.companyCategoryId ? this.$route.query.companyCategoryId : null,
        type: this.$route.query.type ? this.$route.query.type : null,
      },

      showPdfModal: false,

      selectedProjectStep: null,
      // Type de modal à ouvrir depuis une projetStep
      modalType: null,
      showProjectStepModal: false,
      dropdownPosition: null,
      selectedProject: null,
      companyProjects: null,

      french: fr,
      isLoading: false,

      search: null,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getData();
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
  },
  methods: {
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
            companyCategoryId: this.filters.companyCategoryId || undefined,
            type: this.filters.type || undefined,
          },
        });
      }
    },
    async getData() {
      try {
        if (this.$route.params.companyId) {
          this.projectSteps = await projectStep.getByCompany(
            this.$route.params.companyId,
            this.filters.companyCategoryId,
            this.filters.type,
            this.search,
          );

          this.company = await company.getById(this.$route.params.companyId);
        } else {
          this.projectSteps = await projectStep.getActive(
            this.filters.companyCategoryId,
            this.filters.type,
          );
        }
        this.filterShowToConfirm();
        this.sortByProjects();
      } catch (error) {
        //
      }
      try {
        const allCompanies = (await company.getAll()).data;
        if (allCompanies && allCompanies.length > 0) {
          const alphaSorted = allCompanies.sort((a, b) => a.name.localeCompare(b.name));
          const onlyFavorite = alphaSorted.filter((c) => c.isFavorite);
          const noFavorite = alphaSorted.filter((c) => !c.isFavorite);

          this.companies = onlyFavorite.concat(noFavorite);
        } else {
          this.companies = [];
        }
      } catch (error) {
        //
      }
      try {
        const allCompanyCategories = await companyCategory.getAll();
        this.companyCategories = allCompanyCategories.sort((a, b) => a.name.localeCompare(b.name));

        this.companyCategoriesLabel = this.companyCategories.map((a) => ({
          name: a.companyCategoryId, // companyId appelé name pour l'app-select
          label: `${a.label}  (${this.companyCategoryTypeLabel[a.type]})`,
        }));
        this.companyCategoriesLabel.unshift({ name: null, label: 'Tout les métiers' });
      } catch (error) {
        //
      }

      this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      this.isLoading = false;
    },
    updateQuery(newQuery) {
      this.$router.push({
        query: {
          ...this.$route.query,
          search: this.search || null,
          ...newQuery,
        },
      });
    },
    async getProjectSteps() {
      try {
        if (this.$route.params.companyId) {
          this.projectSteps = await projectStep.getByCompany(
            this.$route.params.companyId,
            this.filters.companyCategoryId,
            this.filters.type,
          );

          this.company = await company.getById(this.$route.params.companyId);
        } else {
          this.projectSteps = await projectStep.getActive(
            this.filters.companyCategoryId,
            this.filters.type,
          );
        }
        this.updateQuery(
          {
            companyCategoryId: this.filters.companyCategoryId,
            type: this.filters.type,
          },
        );
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      } catch (error) {
        //
      }
    },
    sortByProjects() {
      const projectIds = [];
      this.projects = [];

      this.filteredProjectSteps.forEach((pS) => {
        if (!projectIds.includes(pS.project.projectId)) {
          projectIds.push(pS.project.projectId);
        }
      });

      projectIds.forEach((projectId) => {
        const projectStepsByProjectId = this.filteredProjectSteps.filter((pS) => pS.project.projectId === projectId);
        this.projects.push({
          project: {
            customer: projectStepsByProjectId[0].customer,
            member: projectStepsByProjectId[0].member,
            ...projectStepsByProjectId[0].project,
          },
          projectSteps: projectStepsByProjectId,
        });
      });
      this.companyProjects = this.projects.sort((a, b) => a.project.reference.localeCompare(b.project.reference));
    },
    filterProjectSteps(pending, todo, inProgress, late, done, toConfirm) {
      if (this.$route.params.companyId) {
        this.filteredProjectSteps = this.projectSteps.filter((pS) => (
          (
            (
              pending && (pS.startStatus === 'PENDING' && pS.closeStatus === 'PENDING')
            )
            || (
              todo && (pS.startStatus === 'TODO' || pS.closeStatus === 'TODO')
            )
            || (
              inProgress && (pS.statusToDisplay === 'IN_PROGRESS')
            )
            || (
              toConfirm && (pS.showToConfirmThumbnail)
            )
            || (
              late && (pS.statusToDisplay === 'LATE')
            )
            || (
              done && (pS.statusToDisplay === 'DONE')
            )
          )
        ));
        this.sortByProjects();
      } else {
        this.filteredProjectSteps = this.projectSteps.filter((pS) => (
          (
            (
              toConfirm && (pS.showToConfirmThumbnail)
            )
              || ((
                todo && (pS.startStatus === 'TODO' || pS.closeStatus === 'TODO'))
              )
              || ((
                inProgress && (pS.statusToDisplay === 'IN_PROGRESS'))
              )
              || ((
                late && (pS.statusToDisplay === 'LATE'))
              )
          )
        ));
        this.sortByProjects();
      }
    },
    filterChanged(event, filterName) {
      if (filterName === 'pending') {
        this.filterProjectSteps(event.target.checked, this.todoFilter, this.inProgressFilter, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      } else if (filterName === 'todo') {
        this.filterProjectSteps(this.pendingFilter, event.target.checked, this.inProgressFilter, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      } else if (filterName === 'in_progress') {
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, event.target.checked, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      } else if (filterName === 'late') {
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, event.target.checked, this.doneFilter, this.toConfirmFilter);
      } else if (filterName === 'done') {
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, this.lateFilter, event.target.checked, this.toConfirmFilter);
      } else if (filterName === 'to_confirm') {
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, this.lateFilter, this.doneFilter, event.target.checked);
      }
    },
    openProjectStepModal(selectedProjectStep, modalType, position, project) {
      this.selectedProjectStep = selectedProjectStep;
      this.modalType = modalType;
      this.showProjectStepModal = true;
      this.openModal = true;
      this.dropdownPosition = position;
      this.selectedProject = project;
    },
    changeType(type) {
      this.modalType = type;
      this.openModal = true;
    },
    filterShowToConfirm() {
      for (let i = 0; i < this.projectSteps.length; i += 1) {
      // On calcule la date correspondant au début de la tâche moins la période de préférence de l'entreprise
        const companyPeriodPreferenceWeekAgo = this.$dayjs(this.projectSteps[i].startedAt).subtract(this.projectSteps[i].company.periodPreference, 'week');

        // On vérifie si on se trouve entre le début de la tache et la date calculé précédemment
        const isBetween = this.$dayjs().isBetween(companyPeriodPreferenceWeekAgo, this.projectSteps[i].startedAt, null, '[]');

        // On vérifie si la date de début de la tâche a été dépassé
        const isAfter = this.$dayjs().isAfter(this.projectSteps[i].startedAt);

        // On réunit les valeurs calculés afin de déterminer si on affiche ou non le label "À confirmer"
        if ((isBetween || isAfter) && this.projectSteps[i].company.companyId && this.projectSteps[i].showToConfirm && !this.projectSteps[i].confirmed) {
          this.projectSteps[i].showToConfirmThumbnail = true;
        } else {
          this.projectSteps[i].showToConfirmThumbnail = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
#project-steps {
    min-height: 700px;
    .loader-container {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .format-choice{
      transform: scale(0.9);
      background: white;
      color: #3D455A;
      &.active {
        background: $blue-light;
        color: white;
      }
    }
  .search > div > label {
    height: 40px;
  }
  .header {
    padding: 30px 0;
    .to-confirm.checked label {
      background-color: $toConfirm;
      color: white;
    }
    .done.checked label {
      background-color: $done;
      color: white;
    }
    .pending.checked label {
      background-color: $pending;
      color: white;
    }
    .in-progress.checked label {
      background-color: $in-progress;
      color: white;
    }
    .todo.checked label {
      background-color: $todo;
      color: white;
    }
    .delay.checked label {
      background-color: $late;
      color: white;
    }
    .done, .pending, .in-progress, .todo, .delay, .to-confirm {
      margin: 0 0 0 10px;
    }
    .in-progress, .todo, .delay, .done, .pending, .to-confirm {
      position: relative;
      label {
        position: relative;
        padding: 12px 20px 10px;
        border-radius: 30px;
        color: $white;
        text-transform: uppercase;
        z-index: 1;
        cursor: pointer;
        background-color: $gray-very-light;
        color: #3D455A;
        transition: all ease-in-out 0.2s;
        font-size: $font-sm
      }
      input {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
      }
    }
    .title {
      font-weight: 500;
      font-size: 25px;
      color: $gray-dark;
    }
    button {
      color: #ffffff;
      border-radius: 30px;
      border: none;
      background-color: $blue-medium;
    }
    input {
      margin-right: 30px;
      padding-left: 20px;
    }
    input[type="text"] {
      margin-right: 0;
      border-radius: 20px;
    }
    input[type="checkbox"] {
      height: 25px;
      width: 25px;
      border-radius: 5px;
    }
    label{
      padding-right: 10px;
      margin: auto;
    }
    .filters {
      margin: 20px 0 10px 0;
      align-items: center;
      > div {
        margin: 0 0 0 10px;
        select {
          border-radius: 5px;
        }
      }
    }
  }
  .nothing {
    padding: 30px;
    background-color: $white;
  }
}
</style>
