<template>
  <div class="customer-contact hedifys-21">

    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="$route.params.customerId && customer && customer.lastname"
          :items="[
            {
              route: { name: routeStatus },
              name: routeStatus  === 'customers-unqualified' ? 'À qualifier' : routeStatus === 'customers-prospects' ? 'Prospects' : 'Clients'
            },
            {
              route: {
              name: `${routeStatus}-customerId`,
              params: { customerId: $route.params.customerId }},
              name: `${customer.firstname} ${customer.lastname}`
            }
          ]"
        />
      </div>
    </div>

    <!-- HEADER / TITRE -->
    <div class="new-header grid-x">
      <div class="cell shrink">
        <h1>{{ `${customer.firstname} ${customer.lastname}` }}</h1>
      </div>

      <div class="cell auto tag-container">
        <h5 class="tag" :class="tagColor">{{ getCustomerStatusName(customer.status) }}</h5>
      </div>

      <div class="cell shrink">
        <app-button
          theme="primary"
          size="large"
          icon="add"
          @click="showModale"
        >
          Envoyer un message
        </app-button>
      </div>
    </div>

    <!-- TABS -->
    <tab-nav :tabs="tabs" v-if="$route.params.customerId" />

    <!-- BODY / FORMULAIRE -->
    <div class="body">
      <app-spinner v-if="loadingData"/>

      <ul v-else-if="!loadingData && messages.length" class="message-list">
        <li v-for="(message, index) in messages" :key="`message-${index}`">
          <p>{{ message.content }}</p>
          <time>{{ message.createdAt | date }}</time>
        </li>
      </ul>

      <p class="empty-message" v-else>Aucun message envoyé</p>
    </div>

    <!-- MODALE D'ÉDITION D'UN MESSAGE -->
    <app-modal :show="displayModale" @update:show="hideModale" title="Envoyer un message">
      <form class="message-form" @submit.prevent="sendMessage">
        <app-textarea label="Texte" placeholder="Votre message..." v-model="newMessage" :maxLength="1600" />
        <p class="text-length">{{ newMessage ? newMessage.length : 0 }}/1600 caractères</p>
        <app-button class="submit-button" size="small" @click="sendMessage" :disabled="disabledFormSubmit">
          <template v-if="loadingSendMessage">Envoi en cours...</template>
          <template v-else>Envoyer</template>
        </app-button>
      </form>
    </app-modal>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import customerStatuses from '@/services/data/customerStatuses.json';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';
import customerApi from '@/services/api/customer';
import constomerContactLogApi from '@/services/api/customerContactLog';

export default {
  name: 'customer-contact',

  components: {
    TabNav,
    Breadcrumb,
  },

  metaInfo() {
    return {
      title: this.customer.lastname ? `${this.customer.lastname} –  Contact` : 'Contact',
    };
  },

  data() {
    return {
      customer: {
        agencyId: null,
        gender: null,
        lastname: null,
        firstname: null,
        secondaryContactGender: null,
        secondaryContactLastname: null,
        secondaryContactFirstname: null,
        type: 'COMPANY',
        companyName: null,
        companySiret: null,
        address: {
          addressLine1: null,
          postalCode: null,
          city: null,
        },
        status: 'UNQUALIFIED',
        firstContact: 'UNKNOWN',
      },
      agency: null,
      members: [],
      messages: [],
      newMessage: null,
      loadingData: false,
      loadingSendMessage: false,
      displayModale: false,
    };
  },

  computed: {
    // Initalisation du statut de la route
    routeStatus() {
      // On déduit les liens, à partir de la route actuelle
      const routeStatus = this.$route.name.split('-')[1];

      if (routeStatus === 'unqualified' || routeStatus === 'prospects') {
        return `customers-${routeStatus}`;
      }

      return 'projects-customers';
    },

    // Récupération de la couleur des tags
    tagColor() {
      return {
        'tag-primary': this.customer.status === 'CLIENT',
        'tag-neutral': this.customer.status === 'UNQUALIFIED',
        'tag-secondary': this.customer.status !== 'CLIENT' && this.customer.status !== 'UNQUALIFIED',
      };
    },

    // Initialisation des tabs
    tabs() {
      // Initialisation des tabs
      const tabs = [
        {
          route: {
            name: `${this.routeStatus}-customerId`,
            params: { customerId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-draft-projects`,
            params: { customerId: null },
          },
          label: 'Avant-projets',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-contact`,
            params: { customerId: null },
          },
          label: 'Prise de contact',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-agenda`,
            params: { customerId: null },
          },
          label: 'Agenda',
        },
      ];

      // Pour les clients, on ajoute les projets
      if (this.routeStatus === 'projects-customers') {
        tabs.push(
          {
            route: {
              name: `${this.routeStatus}-customerId-projects`,
              params: { customerId: null },
            },
            label: 'Projets',
          },
        );
      }

      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { customerId: this.$route.params.customerId },
        },
        label: el.label,
      }));
    },

    // Indique si le message peut-être envoyé ou non
    disabledFormSubmit() {
      return !this.newMessage || !this.newMessage.length || this.loadingSendMessage;
    },
  },

  async mounted() {
    // Récuparétion des données
    this.getData();

    // On set la valeur du status par défaut à 'Client'
    if (this.routeStatus === 'projects-customers') {
      this.customer.status = 'CLIENT';
    }
  },

  methods: {
    // Récuparétion des données
    async getData() {
      this.loadingData = true;

      // On récupère le customer et les messages dans la même promesse
      await Promise.all([
        this.getCustomer(),
        this.getMessages(),
      ]);

      // On récupère l'agence et les membres uniquement une fois la promesse résolue,
      // car on a besoin du customer pour récupérer l'ID de l'agence
      await this.getAgency();
      await this.getMembers();

      this.loadingData = false;
      this.presetNewMessage();
    },

    // Récupératuion du customer
    async getCustomer() {
      try {
        if (this.$route.params.customerId) {
          this.customer = await customerApi.getById(this.$route.params.customerId);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du contact',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération de l'agence
    async getAgency() {
      try {
        this.agency = await agencyApi.getById(this.customer.agencyId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: "Il y a eu un problème lors de la récupération de l'agence",
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des membres
    async getMembers() {
      try {
        this.members = await memberApi.getByAgency(this.customer.agencyId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des membres',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des messages
    async getMessages() {
      try {
        this.messages = await constomerContactLogApi.getAll(this.$route.params.customerId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des messages',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Préremplie le nouveau message par défaut
    async sendMessage() {
      this.loadingSendMessage = true;

      try {
        // On créer le nouveau message
        await constomerContactLogApi.create({
          customerId: this.customer.customerId,
          content: this.newMessage,
        });

        // On récupère tous les messages
        await this.getMessages();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: "Il y a eu un problème lors de l'envoi du message",
          cancelText: 'Ok',
          hasCancel: true,
        });
      } finally {
        this.loadingSendMessage = false;
        this.presetNewMessage();
        this.hideModale();
      }
    },

    // Récupération du statut du customer
    getCustomerStatusName(status) {
      const customer = customerStatuses.find((el) => el.name === status);
      return customer ? customer.label : null;
    },

    // Envoie d'un message
    presetNewMessage() {
      // Formatage du numéro de téléphone avec des espaces
      const phoneNumber = this.agency.secondaryPhone.replace(/\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*/, '$1 $2 $3 $4 $5');
      const gendersMap = {
        MALE: 'M.',
        FEMALE: 'Mme',
      };
      const projectManager = this.members.find((el) => el.jobTitle === 'projectManager');
      // Création du message par défaut
      this.newMessage = `Bonjour${gendersMap[this.customer.gender] ? ` ${gendersMap[this.customer.gender]}` : ''}${gendersMap[this.customer.gender] && this.customer.lastname ? ` ${this.customer.lastname}` : ''},\nVous avez visité notre site internet et je serais ravie d'évoquer votre projet de construction avec vous !\nN'hésitez pas à me contacter au ${phoneNumber} ${this.agency.email ? `ou par mail ${this.agency.email}` : ''}.\n\n${projectManager ? projectManager.firstname : ''}\nVotre agence Alysia\n${this.agency.address.city ? this.agency.address.city : ''}\n\nPS: Ne pas répondre sur ce numéro`;
    },

    // Affiche la modale
    showModale() {
      this.displayModale = true;
    },

    // Cache la modale
    hideModale() {
      this.displayModale = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.customer-contact

  .body
    @include screen

  .new-header
    @include header

  .row
    @include row

  .breadcrumbs
    @include breadcrumbs

  .spinner-container
    @include spinner-container

  .tag-container
    display: flex
    align-items: center
    margin-left: 16px

    > h5
      height: fit-content

  .tag-primary
    @include tag-primary

  .tag-secondary
    @include tag-secondary

  .form-section
    margin-bottom: 10px

  .message-list
    padding: 0
    background-color: $white
    box-shadow: 0 2px 4px 0 $shadow

    li
      padding: 24px

      &:not(:last-child)
        border-bottom: 1px solid rgba($gray-light, .4)

      p
        @include paragraph
        line-height: 20px
        white-space: pre-wrap

      time
        @include date
        margin: 20px 0 0 0
        display: block

  .empty-message
    padding: 24px
    text-align: center

  .message-form

    .app-textarea
      height: 160px

    .text-length
      @include label
      margin-top: 4px
      text-align: right
      font-weight: 400
      color: $info

    .submit-button
      margin: 30px auto 0 auto

</style>
