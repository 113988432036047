var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"version-variables hedifys-21"},[_c('form',{ref:"version-variables",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('div',{staticClass:"breadcrumbs grid-x row"},[_c('div',{staticClass:"cell auto"},[_c('breadcrumb',{attrs:{"items":[
            { route: { name: 'versions'}, name: 'Versions'},
            { route: { name: 'versions-versionId', params: { versionId: _vm.$route.params.versionId }}, name: _vm.version.reference }
          ]}})],1)]),_c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[(!_vm.isLoading)?_c('div',[_c('h1',[_vm._v(_vm._s(_vm.version.reference))])]):_vm._e()]),_c('div',{staticClass:"cell shrink save"},[_c('app-button',{attrs:{"theme":"primary","size":"large","type":"submit"}},[_vm._v("Sauvegarder")])],1)]),_c('tab-nav',{attrs:{"tabs":_vm.tabs}}),(!_vm.isLoading)?_c('div',{staticClass:"body"},[_c('h2',[_vm._v("Options")]),_c('p',[_vm._v("Certaines options du configurateur ont besoin de spécifications plus précises sur la version.")]),_c('div',{staticClass:"card"},[_c('h3',[_vm._v("Spécifications")]),_c('div',{staticClass:"grid-x row grid-margin-x"},[_c('div',{staticClass:"cell auto"},[_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.mergedVariables,"loading":_vm.isLoading,"small":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
          var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}},{key:"option",fn:function(ref){
          var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.options.length ? _vm.formatOptions(data.options) : 'Pas d’options liées'))])]}},{key:"value",fn:function(ref){
          var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"Valeur...","step":data.unit === 'EURO' || data.unit === 'SQUARE_METER' ? 0.01 : 1,"min":0,"max":data.unit === 'PERCENT' ? 100 : null,"unit":_vm.formatUnit(data.unit)},model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", _vm._n($$v))},expression:"data.value"}})]}}],null,false,941276097)},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2)],1)])])]):_c('div',{staticClass:"body"},[_c('div',{staticClass:"card spinner-container"},[_c('app-spinner')],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }