<template>
  <div class="lost-password hedifys-21 grid-x">
    <div class="form-container">
      <div>
        <div class="row">
          <h1>Mot de passe perdu</h1>
        </div>
        <div class="row">
          <h3>Cette procédure vous enverra un email afin de réinitialiser votre mot de passe.</h3>
        </div>
        <div class="row" v-if="isFormInvalid">
          <app-error title="Erreur" :messages="['Cet utilisateur n’existe pas']" @click="isFormInvalid = false" />
        </div>
        <form @submit.prevent="sendlostPasswordEmail">
          <div class="row">
            <app-input type="email" v-model="email" label="Email" placeholder="Email..." required />
          </div>
          <div class="row">
            <app-button theme="primary" type="submit">Valider</app-button>
          </div>
        </form>
        <router-link :to="{ name: 'login' }">Se connecter à votre compte Hedifys</router-link>
      </div>
      <div class="copyright">
        <p>© {{ $dayjs().year() }} - STARLYD</p>
      </div>
    </div>
    <div class="logo-container">
      <div>
        <hedifys />
        <h1>HEDIFYS</h1>
      </div>
    </div>
  </div>
</template>

<script>
import hedifys from '@/assets/logos/hedifys.svg?inline';
import member from '@/services/api/member';

export default {
  name: 'member-lost-password',
  components: {
    hedifys,
  },
  metaInfo: {
    title: 'Mot de passe perdu',
  },
  data() {
    return {
      email: null,
      passord: null,
      isFormInvalid: false,
    };
  },
  methods: {
    async sendlostPasswordEmail() {
      try {
        await member.lostPassword(this.email);
        this.$notification.show({ text: 'Un email vous a été envoyé afin de réinitialiser votre mot de passe.' });

        this.$router.push({ name: 'login' });
      } catch (e) {
        this.isFormInvalid = true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.lost-password
  height: 100%
  min-height: 750px
  flex-wrap: wrap-reverse
  h3
    color: $label
  .row
    @include row
  .form-container
    @include centered-container
    position: relative
    flex-basis: 40rem
    flex-grow: 1
    min-width: 700px
    > div
      width: 90%
      max-width: 420px
      margin: 0 auto
    .copyright
      position: absolute
      bottom: 24px
      text-align: center
      p
        color: $info
    button
      width: 100%
    a
      @include hyperlink
      text-align: center
      width: 100%
      display: block
  .logo-container
    @include centered-container
    background-image: linear-gradient($gradient-light, $gradient-dark)
    flex-basis: 0
    flex-grow: 999
    position: relative
    svg
      width: 135px
    h1
      color: $white
      font-weight: 300
      text-align: center
      margin-top: 16px
    .version
      position: absolute
      bottom: 24px
      > span
        color: $white
      .bubble
        background-color: $white
        border-radius: 15px
        margin-left: 8px
        color: $title
        padding: 5px 10px
</style>
