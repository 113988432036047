<template>
  <div class="styles hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Styles</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToStyle">Ajouter un style</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES STYLES -->
    <div class="body">
      <p class="description">Le style contient une liste d’options et bases pour un secteur géographique.</p>
      <app-table :headers="tableHeaders" :data="styles" :loading="isLoading" @line-cliked="goToStyle" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import styleApi from '@/services/api/style';

export default {
  name: 'styles',
  metaInfo: {
    title: 'Liste des styles',
  },
  data() {
    return {
      styles: null,
      tableHeaders: [
        { label: 'Nom du style', key: 'name', size: 4 },
      ],
      isLoading: true,
    };
  },
  mounted() {
    this.getStyles();
  },
  methods: {
    goToStyle(style) {
      if (style && style.styleId) {
        this.$router.push({ name: 'styles-styleId', params: { styleId: style.styleId } });
      } else {
        this.$router.push({ name: 'styles-new' });
      }
    },
    async getStyles() {
      this.isLoading = true;
      try {
        this.styles = await styleApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des styles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.styles
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .description
    @include description
</style>
