<template>
  <div class="regulation hedifys-21">
    <form ref="regulation" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="regulation && regulation.name"
            :items="[
              { route: { name: 'styles-regulations'}, name: 'Règles'},
              { route: { name: 'styles-regulations-regulationId', params: { regulationId: $route.params.regulationId }}, name: regulation.name}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <div>
            <h1 v-if="$route.params.regulationId">{{ !isLoading ? regulation.name : ' '}}</h1>
            <h1 v-else>Ajouter une règle géographique</h1>
          </div>
        </div>
        <div class="cell shrink" v-if="$route.params.regulationId">
          <app-button theme="warning" size="large" icon="remove" @click="openDeleteModal" :disabled="isLoading">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button v-if="$route.params.regulationId" theme="primary" size="large" type="submit" :disabled="isLoading">Sauvegarder</app-button>
          <app-button v-else theme="primary" size="large" icon="add" type="submit">Ajouter</app-button>
        </div>
      </div>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <h3>Informations générales</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="regulation.name" label="Nom de la règle" placeholder="Nom de la règle..." required />
            </div>
            <div class="cell auto"></div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-select v-model="regulation.themeId" label="Thème de la règle" :options="themeOptions" @input="themeChange" required />
            </div>
            <div class="cell auto"></div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <p class="radio-paragraph">Désactivation possible au lotissement ?</p>
              <div class="cell auto grid-x grid-margin-x">
                <div class="cell medium-2">
                  <app-radio
                    name="canBeDisabled"
                    :value="true"
                    v-model="regulation.canBeDisabled"
                  >
                    Oui
                  </app-radio>
                </div>
                <div class="cell medium-2">
                  <app-radio
                    name="canBeDisabled"
                    :value="false"
                    v-model="regulation.canBeDisabled"
                  >
                    Non
                  </app-radio>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <p class="radio-paragraph">Visible sur le site publique ?</p>
              <div class="cell auto grid-x grid-margin-x">
                <div class="cell medium-2">
                  <app-radio
                    name="isPublic"
                    :value="true"
                    v-model="regulation.isPublic"
                  >
                    Oui
                  </app-radio>
                </div>
                <div class="cell medium-2">
                  <app-radio
                    name="isPublic"
                    :value="false"
                    v-model="regulation.isPublic"
                  >
                    Non
                  </app-radio>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-select v-model="regulation.type" label="Type de la règle" :options="typeOptions" required />
            </div>
            <div class="cell auto"></div>
          </div>
        </div>
        <div class="row">
          <PostalCode
            :isLoading="isImportLoading"
            :postalCodes="regulationPostalCodes"
            :borders="borders"
            :showInvalidFileMessage="showInvalidFileMessage"
            :showInvalidPostalCodeMessage="showInvalidPostalCodeMessage"
            @getPostalCodes="searchPostalCodes"
            @importPostalCodes="importPostalCodes"
            @addPostalCode="addPostalCode"
            @deletePostalCode="deletePostalCode"
            @hideInvalidPostalCodeMessage="showInvalidPostalCodeMessage = false"
            @hideInvalidFileMessage="showInvalidFileMessage = false"
            :invalidPostalCodes="invalidPostalCodes"
          />
        </div>
        <div class="card">
          <h3>Options concernées</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <p>Ces options seront incluses dans le pack pour le configurateur.</p>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell shrink">
              <app-search size="small" v-model="search" placeholder="Rechercher" />
            </div>
            <!-- FILTER MODAL -->
            <div class="cell shrink">
            </div>
          </div>
          <div class="options grid-x row grid-margin-x">
            <div class="cell auto">
              <app-table :headers="tableHeaders" :data="selectedOptions" :loading="isTableLoading" small>
                <template slot="loading">
                  <app-spinner />
                </template>
                <template slot="empty-table">
                  <p>Aucune données de disponible</p>
                </template>
                <template slot="isIncluded" slot-scope="{ data }">
                  <app-checkbox
                    v-model="regulationOptions"
                    :value="data.optionId"
                  />
                </template>
                <template slot="name" slot-scope="{ data }">
                  <strong>{{ data.name }}</strong>
                </template>
              </app-table>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import * as Papa from 'papaparse';

import regulationTypes from '@/services/data/regulationTypes.json';
import regulationApi from '@/services/api/regulation';
import postalCodeApi from '@/services/api/postalCode';
import optionApi from '@/services/api/option';
import themeApi from '@/services/api/theme';
import utils from '@/services/utils/utils';
import PostalCode from '@/components/general/PostalCode.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

export default {
  name: 'regulation',
  components: {
    PostalCode,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.regulation.name ? `${this.regulation.name} – Règle` : 'Règle',
    };
  },
  data() {
    return {
      regulation: {
        name: null,
        themeId: null,
        canBeDisabled: true,
        isPublic: false,
        type: null,
      },
      isLoading: null,
      isTableLoading: null,
      regulationPostalCodes: [],
      filteredRegulationPostalCodes: [],
      borders: {
        type: 'FeatureCollection',
        name: 'borders',
        features: [],
      },
      tableHeaders: [
        { label: 'Inclus', key: 'isIncluded', size: 1 },
        { label: 'Option', key: 'name', size: 4 },
      ],
      themes: [],
      themeOptions: [],
      regulationTypes,
      typeOptions: [],
      options: [],
      selectedOptions: [],
      regulationOptions: null,
      showInvalidFileMessage: false,
      showInvalidPostalCodeMessage: false,
      search: null,
      isImportLoading: false,
      invalidPostalCodes: null,
    };
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getData();
        this.isLoading = false;
      }
    },
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.filterOptions, 500);
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async themeChange() {
      await this.getOptions();
      this.filterOptions();
      this.regulationOptions = null;
    },
    async getData() {
      await this.getRegulation();
      await this.getThemes();
      this.themeOptions = utils.formatOptions(
        this.themes,
        (option) => option.themeId,
        (option) => option.name,
        'choose',
      );
      this.typeOptions = utils.formatOptions(
        this.regulationTypes,
        (option) => option.name,
        (option) => option.label,
        'choose',
      );
      await this.getPostalCodes();
      this.filteredRegulationPostalCodes = this.regulationPostalCodes;
      this.updateBorders();
      await this.getOptions();
      this.filterOptions();
    },
    async getOptions() {
      this.isTableLoading = true;
      try {
        this.options = await optionApi.getAll(null);
        if (this.$route.params.regulationId) {
          let regulationOpt = await optionApi.getByRegulation(this.$route.params.regulationId);

          if (this.regulation.themeId && regulationOpt) {
            regulationOpt = regulationOpt.filter((option) => option.theme.themeId === this.regulation.themeId);
          }
          this.regulationOptions = regulationOpt.map((o) => o.optionId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des options',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isTableLoading = false;
    },
    async filterOptions() {
      let filteredOptions = [];
      if (this.searchOptions && this.searchOptions.length) {
        filteredOptions = this.options.filter((o) => o.name && o.name.toLowerCase().includes(this.searchOptions.toLowerCase()));
      } else {
        filteredOptions = this.options;
      }
      if (this.regulation.themeId) {
        filteredOptions = filteredOptions && filteredOptions.length ? filteredOptions.filter((option) => option.theme.themeId === this.regulation.themeId) : [];
      }

      this.selectedOptions = filteredOptions;
    },
    async getRegulation() {
      try {
        if (this.$route.params.regulationId) {
          this.regulation = await regulationApi.getById(this.$route.params.regulationId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des règles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getThemes() {
      try {
        this.themes = await themeApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des thèmes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getPostalCodes(search) {
      try {
        if (this.$route.params.regulationId) {
          this.regulationPostalCodes = await postalCodeApi.getByRegulation(this.$route.params.regulationId, search);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des codes postaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    searchPostalCodes(search) {
      this.filteredRegulationPostalCodes = this.regulationPostalCodes.filter((postalCode) => postalCode.postalCodeInseeNumber.includes(search) || postalCode.city.includes(search) || !search);
      this.updateBorders();
    },
    updateBorders() {
      // Récupération des frontières pour les inclure dans l'objet 'borders' si il y a
      if (this.filteredRegulationPostalCodes.length > 0) {
        this.borders.features = this.filteredRegulationPostalCodes.map((cp) => cp.borders);
      } else {
        this.borders.features = [];
      }
    },
    importPostalCodes(file, search) {
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file[0]);

        reader.onload = (event) => {
          const csv = event.target.result;
          Papa.parse(csv, {
            complete: async (results) => {
              this.isImportLoading = true;
              const newPostalCodes = results.data;
              newPostalCodes.splice(0, 1);

              this.invalidPostalCodes = [];
              const parsedPostalCodes = newPostalCodes.map((npc) => (npc && npc.length ? npc[0] : null));

              const postalCodePromises = parsedPostalCodes.map((ppc) => this.addPostalCode(ppc, search, true));
              await Promise.all(postalCodePromises);

              if (this.invalidPostalCodes && this.invalidPostalCodes.length) {
                this.showInvalidFileMessage = true;
              }
              this.updateBorders();
              this.isImportLoading = false;
            },
          });
        };
        reader.onerror = () => {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de l\'envoi du fichier',
            cancelText: 'Ok',
            hasCancel: true,
          });
        };
      }
    },
    async addPostalCode(pc, search, isImported) {
      if (!isImported) {
        this.invalidPostalCodes = [];
      }
      try {
        await this.matchPostalCodes(pc, search);
      } catch (er) {
        this.invalidPostalCodes.push(pc);
      }

      if (!isImported && this.invalidPostalCodes && this.invalidPostalCodes.length) {
        this.showInvalidPostalCodeMessage = true;
      }

      this.updateBorders();
    },
    async matchPostalCodes(selectedPostalCode, search) {
      if (selectedPostalCode && selectedPostalCode.length) {
        const inseeCodeFormat = `${selectedPostalCode}`;
        const newPostalCodes = await postalCodeApi.getAll(inseeCodeFormat);

        if (newPostalCodes && newPostalCodes.length) {
          newPostalCodes.forEach((pc) => {
            if (!this.regulationPostalCodes.find((spc) => spc.postalCodeInseeNumber === pc.postalCodeInseeNumber)) {
              this.regulationPostalCodes.push(pc);
            }
          });
        } else {
          this.invalidPostalCodes.push(selectedPostalCode);
        }
      }
      this.searchPostalCodes(search);
    },
    deletePostalCode(postalCodeInseeNumber, search) {
      const postalCodeIndex = this.regulationPostalCodes.findIndex((spc) => spc.postalCodeInseeNumber === postalCodeInseeNumber);
      this.regulationPostalCodes.splice(postalCodeIndex, 1);
      this.searchPostalCodes(search);
    },
    async save() {
      this.isLoading = true;
      try {
        const parsedPostalCodes = this.regulationPostalCodes.map((spc) => spc.postalCodeInseeNumber);
        const regulationObj = {
          name: this.regulation.name,
          themeId: this.regulation.themeId,
          canBeDisabled: this.regulation.canBeDisabled,
          isPublic: this.regulation.isPublic,
          type: this.regulation.type,
          postalCodesInseeNumbers: parsedPostalCodes,
        };

        if (this.$route.params.regulationId) {
          await regulationApi.update(this.regulation.regulationId, regulationObj);

          await optionApi.updateRegulation(this.regulation.regulationId, {
            options: this.regulationOptions,
          });
        } else {
          const regulation = await regulationApi.create(regulationObj);

          await optionApi.updateRegulation(regulation.regulationId, {
            options: this.regulationOptions,
          });

          this.$router.push({ name: 'styles-regulations-regulationId', params: { regulationId: regulation.regulationId } });
        }

        await this.getPostalCodes();
        this.filteredRegulationPostalCodes = this.regulationPostalCodes;
        this.updateBorders();
        await this.getOptions();
        this.filterOptions();
        await this.getRegulation();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde de la règle géographique',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer la règle géographique',
        text: 'Êtes vous sûr de vouloir supprimer cette règle géographique ?',
        confirmText: 'Supprimer la règle géographique',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteRegulation();
        },
      });
    },
    async deleteRegulation() {
      if (this.$route.params.regulationId) {
        this.isLoading = true;
        try {
          await regulationApi.delete(this.$route.params.regulationId);
          this.$notification.show({ text: 'Cette règle géographique a été supprimé avec succès !' });

          this.$router.push({ name: 'styles-regulations' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression de la règle géographique',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.regulation
  .body
    @include screen
    .options
      min-height: 200px
      .data
        flex: auto
    .radio-paragraph
      @include paragraph-bold
      color: $label
      margin-bottom: 1rem
  form > .header
    @include header
  .breadcrumbs
    @include breadcrumbs
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
</style>
