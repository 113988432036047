<template>
  <div class="documents">
     <!-- BREADCRUMBS -->
     <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb v-if="category"
            :items="[
              { route: { name: 'documents-space'}, name: 'Espace Docs'},
              { route: { name: 'documents-space-documents', params: {documentCategoryId: category.documentCategoryId } }, name: category.name }
            ]"
          />
        </div>
     </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1 v-if="category">{{category.name}}</h1>
      </div>
      <div class="cell shrink" v-if="isAdmin">
        <app-button theme="primary" size="large" icon="add" @click="openAddDocumentModal">Ajouter un document</app-button>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabsAdmin" key="tabsAdmin" v-if="isAdmin"/>
    <tab-nav :tabs="tabsAgency" key="tabsAgency" v-else/>

    <!-- BODY / LISTE DES DOCUMENTS -->
    <div class="body">
      <div class="filters-container">
        <span>Filtrer par :</span>
        <app-select :value="null" v-model="filters.subCategoryId" :options="subCategoriesOptions" @input="updateCategoryQuery" />
        <app-search size="small" v-model="filters.search" placeholder="Rechercher un document" />
        <span>Trier par :</span>
        <app-select v-model="filters.orderBy" :options="[
                    { name: 'dateDesc', label: 'Du + récent au + ancien'},
                    { name: 'dateAsc', label: 'Du + ancien au + récent'},
                  ]" @input="updateOrderByQuery" />
      </div>
      <app-table :headers="tableHeaders" :data="documents.data" :loading="isLoading">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.filename }}</strong>
        </template>
        <template slot="creation-date" slot-scope="{ data }">
          <strong>{{ $dayjs(data.createdAt).format('DD/MM/YYYY') }}</strong>
        </template>
        <template slot="type" slot-scope="{ data }">
          <strong>{{ data.mimetype }}</strong>
        </template>
        <template slot="sub-category" slot-scope="{ data }">
          <strong>{{ data.documentSubCategory.name }}</strong>
        </template>
        <template slot="more" slot-scope="{ data }">
          <div class="buttons-container">
            <a :href="data.url" target="_blank">
              <link-to-file />
            </a>
            <more-options v-if="isAdmin" :id="data.documentId" @update="openUpdateDocumentModal" @delete="deleteDocument"/>
          </div>
        </template>
      </app-table>
      <!-- PAGINATION -->
      <app-pagination
        v-if="documents.metadata"
        :limit="limit"
        :offset="documents.metadata.offset"
        :count="documents.metadata.count"
      />
    </div>
     <!-- MODALE AJOUT DE DOCUMENT -->
    <app-modal class="modal" :show="isAddDocumentModalOpen" size="medium" title="Ajouter un document" @update:show="isAddDocumentModalOpen = false;fileRequiredMessage = false;">
      <form ref="document" @submit.prevent="createDocument">
        <div>
          <app-input-file accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg" @upload="addFile" @remove="removeFile"/>
          <span class="info-label">Fichier .pdf, .xls, .jpg, .png. 10Mo max</span>
        </div>
        <app-label v-if="fileRequiredMessage" class="error">Le fichier est obligatoire</app-label>
        <app-select label="Rubrique" v-model="categoryId" :options="categoriesOptions" disabled required/>
        <app-select label="Sous-rubrique" :value="null" v-model="subCategoryId" :options="subCategoriesOptions" :disabled="subCategoriesOptions.length <= 1" required/>
        <app-button class="submit-btn" theme="primary" size="large" icon="add" type="submit">Ajouter</app-button>
      </form>
    </app-modal>
    <!-- MODALE MODIFICATION DE DOCUMENT-->
    <app-modal class="modal" :show="isUpdateDocumentModalOpen" size="medium" title="Modifier un document" @update:show="isUpdateDocumentModalOpen = false">
      <form ref="document" @submit.prevent="updateDocument">
        <app-select label="Rubrique" v-model="categoryId" :options="categoriesOptions" required/>
        <app-select label="Sous-rubrique" :value="null" v-model="subCategoryId" :options="subCategoriesOptions" :disabled="subCategoriesOptions.length <= 1" required/>
        <app-button class="submit-btn" theme="primary" size="large" icon="add" type="submit">Modifier</app-button>
      </form>
    </app-modal>
  </div>
</template>

<script>
// Services
import utils from '@/services/utils/utils';
import auth from '@/services/auth';
import memberApi from '@/services/api/member';
import documentApi from '@/services/api/document';
import documentCategoryApi from '@/services/api/documentCategory';
import documentSubCategoryApi from '@/services/api/documentSubCategory';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

// Components
import TabNav from '@/components/layouts/TabNav.vue';
import linkToFile from '@/assets/img/link-to-file.svg?inline';
import MoreOptions from './MoreOptions.vue';

export default {
  name: 'documents-space-documents',
  components: {
    TabNav,
    Breadcrumb,
    linkToFile,
    MoreOptions,
  },
  data() {
    return {
      isAdmin: false,
      brandId: null,
      documents: {
        data: null,
        metadata: null,
      },
      documentToEdit: null,
      documentId: null,
      subCategoryId: null,
      categoryId: this.$route.params.documentCategoryId,
      file: null,
      filename: null,
      url: null,
      category: null,
      categories: [],
      subCategories: [],
      search: null,
      limit: 20,
      filters: {
        search: null,
        subCategoryId: null,
        orderBy: 'dateDesc',
      },
      tabsAdmin: [
        {
          route: {
            name: 'documents-space',
          },
          label: 'Accueil',
        },
        {
          route: {
            name: 'documents-space-documents',
            params: {
              documentCategoryId: this.$route.params.documentCategoryId,
            },
          },
          label: 'Liste documents',
        },
        {
          route: {
            name: 'documents-space-subcategories',
            params: {
              documentCategoryId: this.$route.params.documentCategoryId,
            },
          },
          label: 'Sous-rubriques',
        },
      ],
      tabsAgency: [
        {
          route: {
            name: 'documents-space',
          },
          label: 'Accueil',
        },
        {
          route: {
            name: 'documents-space-documents',
            params: {
              documentCategoryId: this.$route.params.documentCategoryId,
            },
          },
          label: 'Liste documents',
        },
      ],
      tableHeaders: [
        { label: 'Nom du document', key: 'name', size: 4 },
        { label: 'Date de l\'ajout', key: 'creation-date', size: 2 },
        { label: 'Type', key: 'type', size: 1 },
        { label: 'Sous-rubrique', key: 'sub-category', size: 4 },
        { label: '', key: 'more', size: 1 },
      ],
      isLoading: false,
      isAddDocumentModalOpen: false,
      isUpdateDocumentModalOpen: false,
      fileRequiredMessage: false,
    };
  },
  async created() {
    await this.getData();
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    categoriesOptions() {
      return utils.formatOptions(
        this.categories,
        (option) => option.documentCategoryId,
        (option) => option.name,
        'choose',
      );
    },
    subCategoriesOptions() {
      return utils.formatOptions(
        this.subCategories.filter((subCategory) => subCategory.documentCategoryId === this.categoryId),
        (option) => option.documentSubCategoryId,
        (option) => option.name,
        'choose',
      );
    },
  },
  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getDocuments();
        this.isLoading = false;
      }
    },
    'filters.search': function onSearchChange() {
      this.debouncedUpdateSearchQuery();
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.getMe();
      await this.getCategories();
      await this.getCategory();
      this.getSubCategories();
      this.brandId = await auth.getBrandId();
      this.getDocuments();
      this.isLoading = false;
    },

    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isAdmin = this.me.isBrandAdmin;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getDocuments() {
      try {
        this.documents = await documentApi.getAllByCategory(this.$route.params.documentCategoryId, this.limit, this.computeOffset, this.$route.query.subCategoryId, this.$route.query.search, this.$route.query.orderBy);
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des documents',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.filters.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.filters.search || undefined,
          },
        });
      }
    },
    updateCategoryQuery() {
      if (this.$route.query.subCategoryId !== this.filters.subCategoryId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            subCategoryId: this.filters.subCategoryId || undefined,
          },
        });
      }
    },
    updateOrderByQuery() {
      if (this.$route.query.orderBy !== this.filters.orderBy) {
        this.$router.push({
          query: {
            ...this.$route.query,
            orderBy: this.filters.orderBy || undefined,
          },
        });
      }
    },
    async getCategories() {
      try {
        this.categories = await documentCategoryApi.getAll();
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des rubriques',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCategory() {
      try {
        this.category = await documentCategoryApi.getOneById(this.$route.params.documentCategoryId);
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la rubrique',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getSubCategories() {
      try {
        this.subCategories = await documentSubCategoryApi.getAllSubCategories();
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des sous-rubriques',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async addFile(file) {
      if (this.fileRequiredMessage) {
        this.fileRequiredMessage = false;
      }
      this.file = file;
    },
    async removeFile() {
      this.file = null;
      this.filename = null;
      this.url = null;
    },
    async createDocument() {
      if (!this.file) {
        this.fileRequiredMessage = true;
        return;
      }
      this.isLoading = true;
      try {
        await documentApi.createDocument(this.brandId, this.file, this.subCategoryId);
        this.$notification.show({ text: 'Document créé avec succès !' });
      } catch (er) {
        if (er.message.includes('413')) {
          this.$message.show({
            title: 'Erreur',
            text: 'Le fichier saisi est trop volumineux, 10 Mo maximum',
            cancelText: 'Ok',
            hasCancel: true,
          });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la création du document',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
      this.isAddDocumentModalOpen = false;
      this.getDocuments();
      this.isLoading = false;
    },
    async openAddDocumentModal() {
      this.file = null;
      this.isAddDocumentModalOpen = true;
    },
    async openUpdateDocumentModal(documentId) {
      this.documentToEdit = this.documents.data.find((doc) => doc.documentId === documentId);
      this.isUpdateDocumentModalOpen = true;
    },
    async updateDocument() {
      this.isLoading = true;
      try {
        await documentApi.updateDocument(this.documentToEdit.documentId, this.subCategoryId);
        this.$notification.show({ text: 'Document modifié avec succès !' });
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la modification du document',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isUpdateDocumentModalOpen = false;
      this.getDocuments();
      this.isLoading = false;
    },
    async deleteDocument(documentId) {
      this.$message.show({
        title: 'Supprimer document',
        text: 'Êtes-vous sûr de vouloir supprimer ce document ?',
        confirmText: 'Continuer',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: async () => {
          this.isLoading = true;
          try {
            await documentApi.deleteDocument(this.brandId, documentId);
            this.$notification.show({ text: 'Document supprimé avec succès !' });
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de la suppression du document',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
          this.getDocuments();
          this.isLoading = false;
        },
      });
    },
  },
};
</script>

<style lang='sass'>
.documents
  .body
    @include screen
    padding-top: 1em
  >.header
    @include header
  .breadcrumbs
    @include breadcrumbs

.documents

  .filters-container
    display: flex
    gap: 12px
    align-items: center
    flex-grow: 1
    margin: 1rem 0

    >span
      font-weight: bold

    .app-select
      max-width: 200px

    .app-search
      max-width: 250px

  .app-table
    .data
      overflow: visible

    .buttons-container
      display: flex
      flex-flow: row nowrap
      justify-content: center
      align-items: center
      position: relative

  .modal
    form
      height: 300px
      display: flex
      flex-flow: column nowrap
      justify-content: space-evenly
      margin: 6rem auto
      padding: 0 50px

      .app-input
        margin: 20px 0
      .submit-btn
        width: 10rem
        margin-top: 1rem!important
        margin: 0 auto
      .info-label
        color: $label
      .error
        color: $red
</style>
