import axios from 'axios';
import auth from '@/services/auth';

const { VUE_APP_API_URL } = process.env;

const getAll = async (category, agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customer-events`, {
      params: {
        ...(agencyId && { agencyId }),
        ...(category && { category }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const create = async (customerEvent) => {
  try {
    // Le format de date passe mal sinon (pas de prise en compte du fuxeau horaire)
    const startedAt = (new Date(customerEvent.startedAt)).toISOString();
    const endedAt = (new Date(customerEvent.endedAt)).toISOString();
    const response = await axios.post(`${VUE_APP_API_URL}/customers/customer-events`, {
      ...customerEvent,
      startedAt,
      endedAt,
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const getByCustomer = async (customerId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/${customerId}/customer-events`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un évènement
 * @param {string} customerEventId: id de l'évènement à modifier
 * @param {object} customerEvent: évènement à mettre à jour
 */
const update = async (customerEventId, customerEvent) => {
  try {
    // Le format de date passe mal sinon (pas de prise en compte du fuxeau horaire)
    const startedAt = (new Date(customerEvent.startedAt)).toISOString();
    const endedAt = (new Date(customerEvent.endedAt)).toISOString();
    const response = await axios.put(`${VUE_APP_API_URL}/customer-events/${customerEventId}`, {
      ...customerEvent,
      startedAt,
      endedAt,
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mets à jour toutes les récurrences d'un évènement
 * @param {string} customerEventId: id de l'évènement à modifier
 * @param {object} customerEvent: évènement à mettre à jour
 */
const updateAllRecurrences = async (customerEventId, customerEvent) => {
  try {
    const startedAt = (new Date(customerEvent.startedAt)).toISOString();
    const endedAt = (new Date(customerEvent.endedAt)).toISOString();
    const response = await axios.put(`${VUE_APP_API_URL}/customer-events/${customerEventId}/recurrences`, {
      ...customerEvent,
      startedAt,
      endedAt,
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Annule un RDV
 * @param {string} customerEventId: id du RDV à annuler
 * @returns customerEvent objet
 */
const cancel = async (customerEventId) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/customer-events/${customerEventId}/cancel`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Supprime un RDV
 * @param {string} customerEventId: id du RDV à supprimer
 * @returns void
 */
const deleteOne = async (customerEventId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/customer-events/${customerEventId}`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Supprime toutes les récurrences d'un évènement
 * @param {string} customerEventId: id de du RDV à supprimer
 * @returns void
 */
const deleteAllRecurrences = async (customerEventId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/customer-events/${customerEventId}/recurrences`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

const getCalendarLink = async (agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/${agencyId}/customer-events/calendar-link`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

const downloadCsv = (filters) => {
  const { startDate, endDate, agencyId } = filters;
  try {
    const token = auth.getToken();
    window.open(`${VUE_APP_API_URL}/customer-events/csv?token=${token}${agencyId ? `&agencyId=${agencyId}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`, '_blank');
  } catch (er) {
    throw er;
  }
};

const customerEvents = {};
customerEvents.getAll = getAll;
customerEvents.create = create;
customerEvents.update = update;
customerEvents.updateAllRecurrences = updateAllRecurrences;
customerEvents.cancel = cancel;
customerEvents.getByCustomer = getByCustomer;
customerEvents.getCalendarLink = getCalendarLink;
customerEvents.downloadCsv = downloadCsv;
customerEvents.deleteOne = deleteOne;
customerEvents.deleteAllRecurrences = deleteAllRecurrences;

export default customerEvents;
