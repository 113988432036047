<template>
  <div class="project-steps-week hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header-new grid-x">
      <div class="cell auto">
        <div>
          <h1>Liste des tâches</h1>
        </div>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body">
      <section class="hedifys-chantier" id="project-steps-week">
        <vk-grid gutter="collapse" class="uk-child-width-expand header">
          <div class="pending uk-width-auto@m" :class="{ checked : pendingFilter }">
            <label for="filter-pending">En attente</label>
            <input
              type="checkbox"
              name="filter-pending"
              id="filter-pending"
              class="uk-checkbox"
              v-model="pendingFilter"
              @change="filterChanged($event, 'pending')" />
          </div>
          <div class="in-progress uk-width-auto@m" :class="{ checked : inProgressFilter }">
            <label for="filter-in-progress">En cours</label>
            <input
              type="checkbox"
              class="uk-checkbox"
              name="filter-in-progress"
              id="filter-in-progress"
              v-model="inProgressFilter"
              @change="filterChanged($event, 'in_progress')" />
          </div>
          <div class="todo uk-width-auto@m" :class="{ checked : todoFilter }">
            <label for="filter-todo">A faire</label>
            <input
              type="checkbox"
              name="filter-todo"
              id="filter-todo"
              class="uk-checkbox"
              v-model="todoFilter"
              @change="filterChanged($event, 'todo')" />
          </div>
          <div class="delay uk-width-auto@m" :class="{ checked : lateFilter }">
            <label for="filter-late">En retard</label>
            <input
              type="checkbox"
              name="filter-late"
              id="filter-late"
              class="uk-checkbox"
              v-model="lateFilter"
              @change="filterChanged($event, 'late')" />
          </div>
          <div class="done uk-width-auto@m" :class="{ checked : doneFilter }">
            <label for="filter-done">Finis</label>
            <input
              type="checkbox"
              id="filter-done"
              name="filter-done"
              class="uk-checkbox"
              v-model="doneFilter"
              @change="filterChanged($event, 'done')" />
          </div>
          <div class="to-confirm uk-width-auto@m" :class="{ checked : toConfirmFilter }">
            <label for="filter-to-confirm">À confirmer</label>
            <input
              type="checkbox"
              id="filter-to-confirm"
              name="filter-to-confirm"
              class="uk-checkbox"
              v-model="toConfirmFilter"
              @change="filterChanged($event, 'to_confirm')" />
          </div>
          <div class="uk-width-expand@m"></div>
        </vk-grid>
        <vk-grid gutter="collapse" class="uk-child-width-expand header weekly">
          <div class="uk-width-expand@m week week-name">
            <span>{{ `Semaine ${this.selectedWeek.number} - du ${$dayjs(selectedWeek.monday).format('DD MMMM YYYY')} au ${$dayjs(selectedWeek.sunday).format('DD MMMM YYYY')}` }}</span>
          </div>
          <div class="uk-width-auto@m actions">
            <vk-grid gutter="collapse">
              <div class="previous">
                <div class="icon-arrow_left" @click="previousWeek"></div>
              </div>
              <div class="datepicker">
                <datepicker
                  v-model="selectedWeek.monday"
                  format="dd MMMM yyyy"
                  :language="french"
                  :disabledDates="{ days: [2,3,4,5,6,0] }"
                  :monday-first="true"
                  @input="onDateChanged"
                />
              </div>
              <div class="next">
                <div class="icon-arrow_right" @click="nextWeek"></div>
              </div>
            </vk-grid>
          </div>
        </vk-grid>
        <div class="loader-container" v-if="isLoading">
          <spinner></spinner>
        </div>
        <div v-else-if="projects && projects.length > 0">
          <div v-for="(project, index) in projects" :key="`project-step-grid-${project.projectId ? project.projectId : index}`">
            <project-detail :project="project.project" />
            <vk-grid v-for="(projectStep) in project.projectSteps"
              :key="`project-step-${projectStep.projectStepId}`" gutter="collapse"
              class="project-steps uk-flex uk-flex-middle">
              <project-step
                :projectStep="projectStep"
                :projectSteps="project.projectSteps"
                listName="tasks"
                :companies="companies"
                :companyCategories="companyCategories"
                :projectId="projectStep.project.projectId"
                :key="`project-step-${projectStep.projectStepId}`"
                @update="getData"
                @openModal="openProjectStepModal"/>
            </vk-grid>
          </div>
        </div>
        <div class="nothing" v-else>
          <p>Aucun projet disponible cette semaine</p>
        </div>
        <project-step-modal
          :project="selectedProject"
          :projectStep="selectedProjectStep"
          :projectSteps="projectSteps"
          :companies="companies"
          :companyCategories="companyCategories"
          :modalType="modalType"
          :openModal="showProjectStepModal"
          :listName="'tasks'"
          :dropdownPosition="dropdownPosition"
          @close="showProjectStepModal = false"
          @changeType="changeType"
          @update="getData"
        />
        <pdf-creation-modal
          :showModal="showPdfModal"
          :projects="companyProjects"
          @close="showPdfModal = false"
          type="company"
          :company="company"
        />
      </section>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';

import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';
import projectStep from '@/services/api/projectStep';
import company from '@/services/api/company';
import companyCategory from '@/services/api/companyCategory';

import ProjectStep from '@/views/projectStep/ProjectStep.vue';
import PdfCreationModal from '@/views/pdf/PdfCreationModal.vue';
import ProjectStepModal from '@/views/projectStep/ProjectStepModal.vue';
import ProjectDetail from '@/views/project/ProjectDetail.vue';
import Spinner from '@/components/general/Spinner.vue';

export default {
  name: 'companies',
  components: {
    TabNav,
    ProjectStep,
    PdfCreationModal,
    ProjectStepModal,
    ProjectDetail,
    Datepicker,
    Spinner,
  },
  metaInfo: {
    title: 'Liste des tâches',
  },
  data() {
    return {
      // New data
      tabs: [
        {
          route: {
            name: 'projects-project-steps',
          },
          label: 'Tâches en-cours',
        },
        {
          route: {
            name: 'projects-project-steps-week',
          },
          label: 'Tâches / semaine',
        },
      ],

      // Old data
      steps: [],
      filteredSteps: [],
      conductors: [],
      selectedConductor: null,
      projectSteps: [],
      filteredProjectSteps: [],
      companies: [],
      company: null,
      companyCategories: [],
      projects: [],
      pendingFilter: true,
      inProgressFilter: true,
      todoFilter: true,
      lateFilter: true,
      doneFilter: true,
      toConfirmFilter: true,

      showPdfModal: false,

      selectedProjectStep: null,
      // Type de modal à ouvrir depuis une projetStep
      modalType: null,
      showProjectStepModal: false,
      dropdownPosition: null,
      selectedProject: null,
      companyProjects: null,

      french: fr,

      selectedWeek: {
        monday: null,
        sunday: null,
        number: null,
        year: null,
      },
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;

    this.selectedWeek = {
      monday: this.$dayjs().year(new Date().getFullYear()).isoWeek(this.$dayjs(new Date()).isoWeek()).startOf('isoWeek')
        .format(),
      sunday: this.$dayjs().year(new Date().getFullYear()).isoWeek(this.$dayjs(new Date()).isoWeek()).endOf('isoWeek')
        .format(),
      number: this.$dayjs(new Date()).isoWeek(),
      year: new Date().getFullYear(),
    };

    await this.getData();
  },
  methods: {
    async getData() {
      try {
        this.projectSteps = await projectStep.getByWeek(this.selectedWeek.year, this.selectedWeek.number);

        this.filterShowToConfirm();
        this.sortByProjects();
      } catch (error) {
        //
      }
      try {
        const allCompanies = (await company.getAll()).data;
        if (allCompanies && allCompanies.length > 0) {
          const alphaSorted = allCompanies.sort((a, b) => a.name.localeCompare(b.name));
          const onlyFavorite = alphaSorted.filter((c) => c.isFavorite);
          const noFavorite = alphaSorted.filter((c) => !c.isFavorite);

          this.companies = onlyFavorite.concat(noFavorite);
        } else {
          this.companies = [];
        }
      } catch (error) {
        //
      }
      try {
        const allCompanyCategories = await companyCategory.getAll();
        this.companyCategories = allCompanyCategories.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        //
      }

      this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      this.isLoading = false;
    },
    sortByProjects() {
      const projectIds = [];
      this.projects = [];

      this.filteredProjectSteps.forEach((pS) => {
        if (!projectIds.includes(pS.project.projectId)) {
          projectIds.push(pS.project.projectId);
        }
      });

      projectIds.forEach((projectId) => {
        const projectStepsByProjectId = this.filteredProjectSteps.filter((pS) => pS.project.projectId === projectId);
        this.projects.push({
          project: {
            customer: projectStepsByProjectId[0].customer,
            member: projectStepsByProjectId[0].member,
            ...projectStepsByProjectId[0].project,
          },
          projectSteps: projectStepsByProjectId,
        });
      });
      this.companyProjects = this.projects.sort((a, b) => a.project.reference.localeCompare(b.project.reference));
    },
    filterProjectSteps(pending, todo, inProgress, late, done, toConfirm) {
      this.filteredProjectSteps = this.projectSteps.filter((pS) => (
        (
          pending && (pS.startStatus === 'PENDING' && pS.closeStatus === 'PENDING')
        )
        || (
          todo && (pS.startStatus === 'TODO' || pS.closeStatus === 'TODO')
        )
        || (
          inProgress && (pS.statusToDisplay === 'IN_PROGRESS')
        )
        || (
          toConfirm && (pS.showToConfirmThumbnail)
        )
        || (
          late && (pS.statusToDisplay === 'LATE')
        )
        || (
          done && (pS.statusToDisplay === 'DONE')
        )
      ));
      this.sortByProjects();
    },
    filterChanged(event, filterName) {
      if (filterName === 'pending') {
        this.filterProjectSteps(event.target.checked, this.todoFilter, this.inProgressFilter, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      } else if (filterName === 'todo') {
        this.filterProjectSteps(this.pendingFilter, event.target.checked, this.inProgressFilter, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      } else if (filterName === 'in_progress') {
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, event.target.checked, this.lateFilter, this.doneFilter, this.toConfirmFilter);
      } else if (filterName === 'late') {
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, event.target.checked, this.doneFilter, this.toConfirmFilter);
      } else if (filterName === 'done') {
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, this.lateFilter, event.target.checked, this.toConfirmFilter);
      } else if (filterName === 'to_confirm') {
        this.filterProjectSteps(this.pendingFilter, this.todoFilter, this.inProgressFilter, this.lateFilter, this.doneFilter, event.target.checked);
      }
    },
    openProjectStepModal(selectedProjectStep, modalType, position, project) {
      this.selectedProjectStep = selectedProjectStep;
      this.modalType = modalType;
      this.showProjectStepModal = true;
      this.openModal = true;
      this.dropdownPosition = position;
      this.selectedProject = project;
    },
    changeType(type) {
      this.modalType = type;
      this.openModal = true;
    },
    async nextWeek() {
      const currentWeek = this.selectedWeek.number;
      const currentYear = this.selectedWeek.year;

      const weekStart = this.$dayjs().year(currentYear).isoWeek(currentWeek).startOf('isoWeek');
      const newWeek = weekStart.add(1, 'week');

      this.selectedWeek = {
        number: newWeek.isoWeek(),
        monday: newWeek.startOf('isoWeek').format(),
        sunday: newWeek.endOf('isoWeek').format(),
        year: newWeek.year(),
      };

      await this.getData();
    },
    async previousWeek() {
      const currentWeek = this.selectedWeek.number;
      const currentYear = this.selectedWeek.year;

      const weekStart = this.$dayjs().year(currentYear).isoWeek(currentWeek).startOf('isoWeek');
      const newWeek = weekStart.subtract(1, 'week');

      this.selectedWeek = {
        number: newWeek.isoWeek(),
        monday: newWeek.startOf('isoWeek').format(),
        sunday: newWeek.endOf('isoWeek').format(),
        year: newWeek.year(),
      };

      await this.getData();
    },
    async onDateChanged(value) {
      const newWeekStart = this.$dayjs(value);

      this.selectedWeek = {
        number: newWeekStart.isoWeek(),
        monday: newWeekStart.startOf('isoWeek').format(),
        sunday: newWeekStart.endOf('isoWeek').format(),
        year: newWeekStart.year(),
      };

      await this.getData();
    },
    filterShowToConfirm() {
      for (let i = 0; i < this.projectSteps.length; i += 1) {
        // On calcule la date correspondant au début de la tâche moins la période de préférence de l'entreprise
        const companyPeriodPreferenceWeekAgo = this.$dayjs(this.projectSteps[i].startedAt).subtract(this.projectSteps[i].company.periodPreference, 'week');

        // On vérifie si on se trouve entre le début de la tache et la date calculé précédemment
        const isBetween = this.$dayjs().isBetween(companyPeriodPreferenceWeekAgo, this.projectSteps[i].startedAt, null, '[]');

        // On vérifie si la date de début de la tâche a été dépassé
        const isAfter = this.$dayjs().isAfter(this.projectSteps[i].startedAt);

        // On réunit les valeurs calculés afin de déterminer si on affiche ou non le label "À confirmer"
        if ((isBetween || isAfter) && this.projectSteps[i].company.companyId && this.projectSteps[i].showToConfirm && !this.projectSteps[i].confirmed) {
          this.projectSteps[i].showToConfirmThumbnail = true;
        } else {
          this.projectSteps[i].showToConfirmThumbnail = false;
        }
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.project-steps-week
  .body
    @include screen
  .header-new
    @include header
    padding-bottom: 4px
</style>

<style lang="scss">
#project-steps-week {
    min-height: 700px;
    .loader-container {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .format-choice{
      transform: scale(0.9);
      background: white;
      color: #3D455A;
      &.active {
        background: $blue-light;
        color: white;
      }
    }
  .header {
    padding: 30px;
    .to-confirm.checked label {
      background-color: $toConfirm;
      color: white;
    }
    .done.checked label {
      background-color: $done;
      color: white;
    }
    .pending.checked label {
      background-color: $pending;
      color: white;
    }
    .in-progress.checked label {
      background-color: $in-progress;
      color: white;
    }
    .todo.checked label {
      background-color: $todo;
      color: white;
    }
    .delay.checked label {
      background-color: $late;
      color: white;
    }
    .done, .pending, .in-progress, .todo, .delay, .to-confirm {
      margin: 0 0 0 10px;
    }
    .in-progress, .todo, .delay, .done, .pending, .to-confirm {
      position: relative;
      label {
        position: relative;
        padding: 12px 20px 10px;
        border-radius: 30px;
        color: $white;
        font-weight: normal;
        font-size: 0.9rem;
        text-transform: uppercase;
        z-index: 1;
        cursor: pointer;
        background-color: $gray-very-light;
        color: #3D455A;
        transition: all ease-in-out 0.2s;
      }
      input {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
      }
    }
    .title {
      padding-top: 30px;
      font-weight: 500;
      font-size: 25px;
      color: $gray-dark;
    }
    button {
      color: #ffffff;
      border-radius: 30px;
      border: none;
      background-color: $blue-medium;
    }
    input {
      margin-right: 30px;
      padding-left: 20px;
    }
    input[type="text"] {
      margin-right: 0;
      border-radius: 20px;
    }
    input[type="checkbox"] {
      height: 25px;
      width: 25px;
      border-radius: 5px;
    }
    label{
      padding-right: 10px;
      margin: auto;
    }
    .filters {
      margin: 20px 0 10px 0;
      align-items: center;
      > div {
        margin: 0 0 0 10px;
        select {
          border-radius: 5px;
        }
      }
    }
  }
  .weekly {
      padding: 10px 30px 10px 30px;
      align-items: center;
      background-color: $gray-medium;
      .week-name {
        font-size: 20px;
        span {
          color: white;
        }
      }
      input[type="text"] {
        height: initial;
        padding: 5px 10px;
      }
      .actions {
        > div {
          align-items: center;
        }
        .vdp-datepicker {
            position: relative;
          .vdp-datepicker__calendar {
            width: 150%;
            left: -25%;
          }
        }
        .previous, .next {
          display: flex;
          align-items: center;
          div {
            cursor: pointer;
            position: relative;
            width: 30px;
            border-radius: 30px;
            background-color: white;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
              font-size: 25px;
              color: $gray-medium;
            }
          }
        }
        .next div {
          padding-left: 2px;
        }
        .previous div {
          padding-right: 2px;
        }
        .datepicker {
          margin: 0 10px;
        }
      }
    }
    .weekly-name {
      span {
        margin-right: 20px;
      }
    }
  .header-week {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px 0px 21px;
      span {
        padding-right: 10px;
      }
    }
  .nothing {
    padding: 30px;
    background-color: $white;
  }
}
</style>
