<template>
  <div :class="size" class="app-select">
    <div class="search">
      <app-search
        placeholder="Chercher un code postal"
        v-model="search"
        @input="searchChange"
        @blur="focusout"
        :label="`Communes ${required ? '*' : ''}`"
      />
    </div>
    <label>
      <span v-if="label" class="label" :class="{ mandatory: required }">{{ label }}</span>
      <div
        role="listbox"
        class="select grid-x"
        :class="{ disabled: disabled }"
        @click="showOptions"
        :tabindex="tabIndex"
        @focusout="focusout"
      >
        <slot class="cell shrink" v-if="selectedOptions && options && options.find(o => selectedOptions.includes(o.name))">
          <div
            v-for="(item, index) in selectedOptions" :key="item"
            class="selected-item"
            @mousedown="removeOption(index)">
            {{ options.find(o => o.name === item).label }}
            <remove class="remove-option" />
          </div>
        </slot>
        <slot class="cell shrink" v-else>Choisir</slot>
        <dropdown :class="{ disabled: disabled }" class="show-options" />
      </div>
    </label>
    <select :value="selectedOptions" :required="required && (!selectedOptions || selectedOptions && !selectedOptions.length)">
      <option v-for="option in options" :key="option.name" :value="option.name" :selected="selectedOptions && selectedOptions.includes(option.name)"/>
    </select>
    <transition name="app-select-list">
      <div class="options-container" v-show="isOpen && !disabledSelection" @mousedown="catchClick">
        <app-scrollable>
          <app-checkbox
            role="option"
            v-for="option in options"
            :value="option.name"
            v-model="selectedOptions"
            :key="option.name"
            @blur="focusout"
            @input="selectionChanged"
            ><p>{{ option.label }}</p>
          </app-checkbox>
        </app-scrollable>
      </div>
    </transition>
    <div v-if="disabledSelection">
      <p class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import dropdown from '@/assets/img/dropdown.svg?inline';
import remove from '@/assets/img/remove.svg?inline';

export default {
  name: 'app-multi-select',
  components: {
    dropdown,
    remove,
  },
  data() {
    return {
      isOpen: false,
      selectedOptions: null,
      waitingForClick: false,
      search: null,
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Array, Number],
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledSelection: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
      default: 'large',
    },
  },
  mounted() {
    this.selectedOptions = this.$props.value;
  },
  watch: {
    options: {
      handler() {
        const fullArray = this.options && this.options.length ? this.options : [];
        if (this.selectedOptions && this.selectedOptions.length) {
          this.selectedOptions = this.selectedOptions.filter((selectedOption) => fullArray.find((option) => option.name === selectedOption));
        }
      },
      depp: true,
    },
  },
  methods: {
    searchChange(value) {
      this.isOpen = true;
      this.$emit('search', value);
    },
    showOptions(event) {
      if (event && event.button === 0) {
        if (!this.disabled) {
          this.isOpen = !this.isOpen;
        }
      }
    },
    removeOption(index) {
      this.selectedOptions.splice(index, 1);
      this.$emit('input', this.selectedOptions);
    },
    catchClick() {
      this.waitingForClick = true;
    },
    selectionChanged() {
      this.$emit('input', this.selectedOptions);
    },
    focusout() {
      if (!this.waitingForClick) {
        this.isOpen = false;
      } else {
        this.waitingForClick = false;
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.app-select
  display: flex
  flex-direction: column
  position: relative
  &.small
    width: 50% !important
  &.large
    width: 100%
  label
    display: flex
    flex-direction: column
  .label
    @include heading-xs
    color: $label
    padding-bottom: 4px

    &.mandatory:after
      content: "*"
      transform: translateX(0.2rem) translateY(-0.15rem)
      color: $label
      display: inline-block
  .show-options
    cursor: pointer
    position: absolute
    bottom: 12px
    right: 16px
    width: 14px
    height: 14px
    top: 50%
    transform: translate(0, -50%)

  .hidden-input
    position: absolute
    width: 0
    height: 0
    padding: 0
    border: 0

  .select
    @include paragraph
    user-select: none
    cursor: pointer
    display: flex
    padding: 10px 16px 0px 16px
    background-color: $off-white
    border: 1px solid $line
    border-radius: 4px
    color: $body
    outline: none
    position: relative
    min-height: 28px

  .disabled
    background-color: $background
    color: $info
    pointer-events: none
    cursor: not-allowed
    .show-options
      cursor: not-allowed
  .focus
    outline: none
    border: 1px solid $primary
    box-shadow: 0 0 2px $primary
  .options-container
    position: absolute
    top: 100%
    left: 0
    margin-top: 4px
    padding: unset
    background-color: $off-white
    color: $label
    box-shadow: $drop-med
    width: 100%
    max-height: 148px
    border-radius: 4px
    z-index: 2
    display: flex
    flex-direction: column
    .app-checkbox
      // width: 100%
      display: block
      margin: 10px
    li
      cursor: pointer
      padding: 0.5rem 1rem
      &:hover
        background-color: rgba($primary, 0.1)
      span
        @include paragraph

  .selected
    background-color: rgba($primary, 0.1)

  select
    position: absolute
    width: 100%
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: -10

  .app-select-list-enter-active, .app-select-list-leave-active
    transition: transform .3s, opacity .3s, scale .3s ease-in-out

  .app-select-list-enter, .app-select-list-leave-to
    opacity: 0
  .selected-item
    @include tag
    @include tag-primary
    margin-left: 10px
    margin-bottom: 10px
    display: flex
    padding: 5px 12px
    .remove-option
      width: 10px
      margin-left: 5px
      path
        stroke: $primary
  .search
    margin-bottom: 10px
  .error-message
    color: $error
    font-size: 10px
</style>
