import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

const create = async (favorite) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/favorites`, favorite);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const favorites = {};
favorites.create = create;
/* delete est un mot clé il ne peut donc pas avoir la syntax classique */
favorites.delete = async (favorite) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/favorites`, { data: favorite });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

export default favorites;
