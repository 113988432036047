import AppEvent from './utils/AppEvent';
import AppMessage from './components/AppMessage.vue';
import AppModal from './components/AppModal.vue';
import AppCheckbox from './components/AppCheckbox.vue';
import AppRadio from './components/AppRadio.vue';
import AppSwitch from './components/AppSwitch.vue';
import AppButton from './components/AppButton.vue';
import AppCard from './components/AppCard.vue';
import AppNotifications from './components/AppNotifications.vue';
import AppSelect from './components/AppSelect.vue';
import AppInput from './components/AppInput.vue';
import AppSearch from './components/AppSearch.vue';
import AppDatepicker from './components/AppDatepicker.vue';
import AppTitle from './components/AppTitle.vue';
import AppSubtitle from './components/AppSubtitle.vue';
import AppLabel from './components/AppLabel.vue';
import AppSlider from './components/AppSlider.vue';
import AppProgress from './components/AppProgress.vue';
import AppTable from './components/AppTable.vue';
import AppTextarea from './components/AppTextarea.vue';
import AppScrollable from './components/AppScrollable.vue';
import AppToggle from './components/AppToggle.vue';
import AppAvatar from './components/AppAvatar.vue';
import AppUploadImage from './components/AppUploadImage.vue';
import AppInputFile from './components/AppInputFile.vue';
import AppError from './components/AppError.vue';
import AppCardLink from './components/AppCardLink.vue';
import AppDoubleSlider from './components/AppDoubleSlider.vue';
import AppRadioButton from './components/AppRadioButton.vue';
import AppPagination from './components/AppPagination.vue';
import AppSpinner from './components/AppSpinner.vue';
import AppSideModal from './components/AppSideModal.vue';
import AppAccordion from './components/AppAccordion.vue';
import AppCardOption from './components/AppCardOption.vue';
import AppMultiSelect from './components/AppMultiSelect.vue';
import AppLightbox from './components/AppLightbox.vue';
import AppCollapse from './components/AppCollapse.vue';
import AppInfo from './components/AppInfo.vue';
import AppCalendar from './components/AppCalendar.vue';
import AppTimepicker from './components/AppTimepicker.vue';
import AppModalDraggable from './components/AppModalDraggable.vue';

const basics = {

  install(Vue) {
    Vue.component('app-message', AppMessage);
    Vue.component('app-modal', AppModal);
    Vue.component('app-checkbox', AppCheckbox);
    Vue.component('app-radio', AppRadio);
    Vue.component('app-switch', AppSwitch);
    Vue.component('app-button', AppButton);
    Vue.component('app-card', AppCard);
    Vue.component('app-notifications', AppNotifications);
    Vue.component('app-select', AppSelect);
    Vue.component('app-input', AppInput);
    Vue.component('app-datepicker', AppDatepicker);
    Vue.component('app-title', AppTitle);
    Vue.component('app-subtitle', AppSubtitle);
    Vue.component('app-label', AppLabel);
    Vue.component('app-slider', AppSlider);
    Vue.component('app-progress', AppProgress);
    Vue.component('app-table', AppTable);
    Vue.component('app-search', AppSearch);
    Vue.component('app-textarea', AppTextarea);
    Vue.component('app-scrollable', AppScrollable);
    Vue.component('app-toggle', AppToggle);
    Vue.component('app-avatar', AppAvatar);
    Vue.component('app-upload-image', AppUploadImage);
    Vue.component('app-input-file', AppInputFile);
    Vue.component('app-error', AppError);
    Vue.component('app-card-link', AppCardLink);
    Vue.component('app-radio-button', AppRadioButton);
    Vue.component('app-double-slider', AppDoubleSlider);
    Vue.component('app-pagination', AppPagination);
    Vue.component('app-spinner', AppSpinner);
    Vue.component('app-side-modal', AppSideModal);
    Vue.component('app-accordion', AppAccordion);
    Vue.component('app-card-option', AppCardOption);
    Vue.component('app-multi-select', AppMultiSelect);
    Vue.component('app-lightbox', AppLightbox);
    Vue.component('app-collapse', AppCollapse);
    Vue.component('app-info', AppInfo);
    Vue.component('app-calendar', AppCalendar);
    Vue.component('app-timepicker', AppTimepicker);
    Vue.component('app-modal-draggable', AppModalDraggable);

    // eslint-disable-next-line
    Vue.prototype.$message = {
      show(params) {
        AppEvent.$emit('app-message-show', params);
      },
    };

    // eslint-disable-next-line
    Vue.prototype.$notification = {
      show(params) {
        AppEvent.$emit('app-notification-show', params);
      },
    };
  },
};

export default basics;
