<template>
  <div class="matching card" :class="{
        'selected': selectedMatching && selectedMatching.version && matching && matching.version && selectedMatching.version.versionId === matching.version.versionId && (
        ((!matching.land || !matching.land.landId) && (!selectedMatching.land || !selectedMatching.land.landId)) ||
        (matching.land && selectedMatching.land && matching.land.landId === selectedMatching.land.landId)),
        'no-land': matching && !matching.land && landChoice !== 'no-land',
        }"
        @click="goToConfiguration(matching)"
        >
        <gallery-carousel v-if="matching && matching.version && matching.versionMedias" :images="matching.versionMedias" :imagesType="imagesType"/>
        <div v-else class="image-not-found">
            <upload class="icon-upload" />
        </div>
        <h4 v-if="matching && matching.version" class="model">Version {{ matching.version.name }}</h4>
        <h3  v-if="matching && matching.version">Maison {{ matching.version.numberOfRooms }} chambre{{ matching.version.numberOfRooms > 1 ? 's' : '' }}</h3>
        <div class="card-footer">
        <div class="grid-x">
            <div class="cell auto">
            <p class="land" v-if="matching && matching.land && matching.land.allotment && matching.land.allotment.name && matching.land">Lotissement "{{ matching.land.allotment.name }}" n°{{ matching.land.lotNumber }} - {{ matching.land.surface }}m²</p>
            <h4 class="land" v-else-if="matching && matching.land && !matching.land.landId && matching.land.width">Mon terrain Larg. {{ matching.land.width }} m</h4>
            <h4 class="land" v-else-if="matching && matching.land && matching.land.landId">Terrain - {{ matching.land.surface }}m²</h4>
            <h4 class="land no-land" v-else-if="matching && landChoice !== 'no-land'">Aucun terrain compatible</h4>
            </div>
            <div class="price cell shrink" v-if="matching && matching.priceWithoutAdditionalFees && landChoice === 'no-land'">
            <h3>{{ matching.priceWithoutAdditionalFees || matching.priceWithoutAdditionalFees === 0 ? utils.formatCentToEuro(matching.priceWithoutAdditionalFees, true, true)  : '' }}</h3>
            </div>
            <div class="price cell shrink" v-else-if="matching && matching.price && landChoice !== 'no-land'">
            <h3>{{ matching.price || matching.price === 0 ? utils.formatCentToEuro(matching.price, true, true)  : '' }}</h3>
            </div>
            <div v-else class="spinner-container">
            <app-spinner small />
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import GalleryCarousel from '@/components/general/GalleryCarousel.vue';
import ls from 'local-storage';
import utils from '@/services/utils/utils';

export default {
  name: 'matching-card',
  components: {
    GalleryCarousel,
  },
  props: {
    matching: null,
    landChoice: null,
    imagesType: null,
  },
  data() {
    return {
      matchingFavorite: ls('MATCHING_FAVORITE') || [],
      utils,
      selectedMatching: null,
    };
  },
  methods: {
    goToConfiguration(matching) {
      let isClayZone = null;
      if (Array.isArray(this.$route.query.isClayZone)) {
        isClayZone = this.$route.query.isClayZone.length ? this.$route.query.isClayZone[0] : undefined;
      } else {
        isClayZone = this.$route.query.isClayZone;
      }

      // Définition du postalCode
      let postalCode;

      if (this.$route.query.postalCodeInseeNumber) {
        postalCode = this.$route.query.postalCodeInseeNumber;
      }

      if (matching.land && matching.land.address && matching.land.address.postalCodeInseeNumber) {
        postalCode = matching.land.address.postalCodeInseeNumber;
      }

      if (matching.land && matching.land.postalCodeInseeNumber) {
        postalCode = matching.land.postalCodeInseeNumber;
      }

      if (this.$route.name.includes('edit')) {
        this.selectedMatching = matching;
      } else {
        const queries = {
          // id terrain
          ...(matching.land && matching.land.landId && { landId: matching.land.landId }),
          // id version
          ...(matching.version && matching.version.versionId && { versionId: matching.version.versionId }),
          // les params pour le terrain custom
          ...(this.$route.query.landWidth && matching.land && { landWidth: this.$route.query.landWidth }),
          ...(this.$route.query.landSurface && matching.land && { landSurface: this.$route.query.landSurface }),
          ...(this.$route.query.landPrice && matching.land && { landPrice: this.$route.query.landPrice }),
          ...(this.$route.query.isMainDrainagePlanned && matching.land && { isMainDrainagePlanned: this.$route.query.isMainDrainagePlanned }),
          ...(this.$route.query.isServiced && matching.land && { isServiced: this.$route.query.isServiced }),
          ...(this.$route.query.isClayZone && matching.land && { isClayZone }),
          // id avant-projet
          ...(this.$route.query.projectDraftId && { projectDraftId: this.$route.query.projectDraftId }),
          // id client
          ...(this.$route.query.customerId && { customerId: this.$route.query.customerId }),
          // id code postal
          ...({ postalCodeInseeNumber: postalCode }),
          //
          ...(this.$route.query.emptyLand && { emptyLand: this.$route.query.emptyLand }),
        };
        try {
          this.$router.push({
            name: 'customers-projects-drafts-new-configuration',
            query: queries,
            params: {
              ...(this.$route.query.projectDraftId && { projectDraftId: this.$route.query.projectDraftId }),
              tab: 'version',
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }
      }
    },
    selectMatching(matching) {
      if (this.$route.name.includes('edit')) {
        this.selectedMatching = matching;
      }
    },
  },
};

</script>
<style>

</style>
