<template>
  <div @click="projectRead">
    <vk-grid gutter="collapse" class="project-detail uk-child-width-expand">
      <div class="column uk-width-auto@m customer">
        <router-link :to="{ name: 'projects-projectId-steps-gantt', params: { projectId : project.projectId } }">
          <div class="gantt-link" @click.stop="">
            <Chart />
          </div>
        </router-link>
        <strong>
          <template v-if="project.customer.type === 'PERSON'">
            {{ getCustomersNames(project.customer).toUpperCase() }}
          </template>
          <template v-else>
            {{ `${project.customer.companyName.toUpperCase()}` }}
          </template>
        </strong>
      </div>
      <div class="column uk-width-auto@m address">
        <strong>{{ project.address.postalCode }} {{ project.address.city }}</strong>
      </div>
      <div class="column uk-width-expand@m"></div>
      <div class="column uk-width-auto@m member">
        {{ project.reference }} {{ project.member.lastname }}
      </div>
      <div class="column uk-width-auto@m arrow">
        <span class="icon-arrow2_right"></span>
      </div>
    </vk-grid>
  </div>
</template>

<script>
import customer from '@/services/api/customer';

import Chart from '@/assets/img/chart.svg?inline';

export default {
  name: 'project-detail',
  props: ['project'],
  components: {
    Chart,
  },
  methods: {
    getCustomersNames(selectedCustomer) {
      return customer.getCustomersNames(selectedCustomer);
    },
    projectRead() {
      this.$router.push({ name: 'projects-projectId-steps', params: { projectId: this.project.projectId } });
    },
  },
};
</script>

<style lang="scss">
  .container.project-detail {
    display: contents;
  }
  .project-detail {
    border-bottom: 1px solid $gray-light;
    background: white !important;
    width: 100%;
    cursor: pointer;
    .column {
      padding: 1.5rem 2rem 1.4rem;
      box-sizing: border-box;
      color: #4C5367;
      font-size: $font-md;
    }
    .customer {
      padding-right: .8rem;
      padding-left: 4rem;
      display: flex;
      position: relative;
      align-items: center;
      strong {
        color: $blue-medium;
        font-size: $font-md;
      }
      .gantt-link {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top:0;
        background-color: $blue-medium;
        height: 100%;
        width: 3rem;
      }
    }
    .address {
      color: $gray-medium;
      display: flex;
      align-items: center;
      strong {
        font-size: $font-md;
      }
    }
    .gantt {
      a {
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
        background: $primary-dark;
        padding: 1rem;
        color: white;
        cursor: pointer;
        &:hover {
          background: $blue-dark;
        }
      }
    }
    .member, .arrow {
      color: $gray-regular;
      display: flex;
      align-items: center;
    }
  }
</style>
