<template>
  <button
    class="app-button"
    :class="[theme, size]"
    :type="type"
    @click="handleClick"
  >
    <span v-if="icon !== ''" class="icon">
      <img class="img" :class="{ 'img-small': size }" :src='"@/assets/img/" + icon + ".svg"' alt="icon" />
    </span>
    <h4 class="text">
      <slot></slot>
    </h4>
    <span v-if="hasArrow" class="icon arrow">
      <img class="img" :class="{ 'img-small': size }" src="@/assets/img/arrow-right.svg" alt="icon" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },
    size: {
      type: String,
      validator: (val) => ['round', 'round-small', 'small', 'large'].indexOf(val) !== -1,
      default: 'large',
    },
    theme: {
      type: String,
      default: 'primary',
      validator: (val) => ['primary', 'secondary', 'warning', 'error'].indexOf(val) !== -1,
    },
    icon: {
      type: String,
      default: '',
      validator: (val) => ['', 'add', 'remove', 'filter', 'import', 'download', 'edit', 'archive', 'copy', 'checked', 'save', 'print', 'send'].indexOf(val) !== -1,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>

<style lang='sass' scoped>
.app-button
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 16px 20px 17px
  border: 1px solid transparent
  color: $off-white
  border-radius: 4px
  height: 48px
  transition: all 0.3s ease-in-out
  user-select: none

  &:disabled
    .img
      transition: all 0.3s ease-in-out
      filter: invert(36%) sepia(24%) saturate(226%) hue-rotate(188deg) brightness(90%) contrast(81%)

  .icon
    display: flex
    padding-right: 8px
    .img
      transition: all 0.3s ease-in-out
      width: 14px
      height: 14px
    &.arrow
      padding-right: 0 !important
      padding-left: 8px

  &.round, &.round-small
    padding: 0
    border-radius: 50%
    box-shadow: $drop-min
    .icon
      padding-right: 0

  &.round
    width: 32px
    height: 32px
    .img-small
      width: 12px
      height: 12px

  &.round-small
    width: 20px
    height: 20px
    .img-small
      width: 10px
      height: 10px

  &.small
    padding: 13px 12px 13px 12px
    height: 40px
    span
      @include heading-xs

  // Button primary
  &.primary
    background-color: $primary
    &:hover
      background-color: $primary-light
    &:active
      background-color: $primary-dark
    &:focus
      outline: none
      box-shadow: 0 0 4px $primary
      border: 1px solid $primary

  // Button secondary
  &.secondary
    background-color: $secondary
    &:hover
      background-color: $secondary-light
    &:active
      background-color: $secondary-dark
    &:focus
      outline: none
      box-shadow: 0 0 4px $secondary
      border: 1px solid $secondary

  // Button error
  &.error
    background-color: $error
    &:hover
      background-color: $error-light
    &:active
      background-color: $error-dark
    &:focus
      outline: none
      box-shadow: 0 0 4px $error
      border: 1px solid $error

  // Button warning
  &.warning
    background-color: $warning
    &:hover
      background-color: $error-light
    &:active
      background-color: $error-dark
    &:focus
      outline: none
      box-shadow: 0 0 4px $warning
      border: 1px solid $warning

  // Disabled
  &.warning:disabled,
  &.primary:disabled,
  &.secondary:disabled
    background-color: $line
    color: $info
    pointer-events: none
    cursor: not-allowed
</style>
