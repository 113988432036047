<template>
  <div class="hedifys-chantier" id="project-pdf">
      <pdf-header v-if="project && agency && customer && $route.params.projectId" :project="project" :agency="agency" :brand="currentBrand" />
      <div class="loader-container" v-if="isLoading" v-show="$route.params.projectId">
        <spinner></spinner>
      </div>
      <div class="pdf-page-body" v-else>
        <div class="pdf-project-header">
          <vk-grid v-if="$route.params.projectId" gutter="collapse" class="uk-child-width-expand page-title">
            <div>
              <h1 class="main-title">Planning de chantier</h1>
            </div>
            <div>
              <h4 class="h-color-gray-dark date-title right-align">{{ $dayjs(new Date()).format('dddd D MMMM YYYY') }}</h4>
            </div>
          </vk-grid>
          <vk-grid gutter="collapse" class="uk-child-width-expand header" v-if="projectSteps && projectSteps.length > 0">
            <div class="uk-width-expand" v-if="project">
              <h4 class="grey-title">Chantier :</h4>
              <div v-if="customer.type === 'PERSON'">
                <p class="h-color-gray-dark project-title">
                  {{ `
                    ${customer.gender === 'MALE' ? 'M.' : 'Mme.'}
                    ${customer.lastname !== customer.secondaryContactLastname ? customer.lastname.toUpperCase() : ''}
                    ${customer.secondaryContactLastname ? customer.secondaryContactGender === 'MALE' ? 'et M.' : 'et Mme.' : ''}
                    ${customer.secondaryContactLastname && customer.secondaryContactLastname !== customer.lastname ? customer.secondaryContactLastname.toUpperCase() : ''}
                    ${customer.secondaryContactLastname && customer.secondaryContactLastname === customer.lastname ? customer.lastname.toUpperCase() : ''}
                  ` }}
                </p>
              </div>
              <div v-else>
                <p class="h-color-gray-dark project-title" >{{ customer.companyName }}</p>
                <p class="h-color-gray-dark uk-margin-remove" >{{ customer.siret }}</p>
              </div>
              <div v-if="project.address">
                <p class="h-color-gray-dark uk-margin-remove" >{{ project.address.addressLine1 }}</p>
                <p class="h-color-gray-dark uk-margin-remove" >{{ project.address.postalCode }} {{ project.address.city }}</p>
              </div>
              <p class="h-color-gray-dark uk-margin-remove" >{{ customer.email }}</p>
              <p class="h-color-gray-dark uk-margin-remove" >{{ customer.phone }}</p>
            </div>
            <div v-if="project" class="right-align">
              <h4 class="grey-title right-align">Référence :</h4>
              <p class="reference">
              {{ $route.params.companyId  ? `${project.agency.name} / `: '' }} N° {{ project.reference }}</p>
            </div>
          </vk-grid>
        </div>
          <project-pdf-planning
            v-if="projectSteps && projectSteps.length > 0 && ((companyProject && company && company.companyId) || !companyProject)"
            class="project-tasks"
            :project="project"
            :filter="activeFilter"
            :day-width="dayWidth"
            :type="companyProject ? 'company': 'project'"
            :raw-project-steps="projectSteps"
            :company="company"
            />
      </div>
      <div class="pdf-actions" v-if="!companyProject && $route.query.isHtml">
        <button type="button" class="format-choice active uk-button uk-button-default light" @click="goBack">Annuler</button>
        <button type="button" class="format-choice active uk-button uk-button-default"  @click="getPdfLink">Télécharger</button>
        <vk-button class="format-choice active uk-button uk-button-default" :disabled="currentCompanies && currentCompanies.length === 0" @click="showPdfModal = true">Envoyer</vk-button>
      </div>
      <pdf-send-modal
        v-if="!companyProject && $route.query.isHtml && currentBrand && project"
        :show="showPdfModal"
        :project="project"
        :companies="currentCompanies"
        :showAll="showAll"
        :project-steps="projectSteps"
        :brand="currentBrand"
        @close="showPdfModal = false"
        @update="getData">
      </pdf-send-modal>
    </div>
</template>

<script>
import project from '@/services/api/project';
import customer from '@/services/api/customer';
import agency from '@/services/api/agency';
import pdfHeader from '@/views/pdf/PdfHeader.vue';
import ProjectPdfPlanning from '@/views/pdf/ProjectPdfPlanning.vue';
import projectStep from '@/services/api/projectStep';
import auth from '@/services/auth';
import pdfSendModal from '@/views/pdf/PdfSendModal.vue';
import Spinner from '@/components/general/Spinner.vue';

export default {
  name: 'project-pdf',
  components: {
    ProjectPdfPlanning,
    pdfHeader,
    pdfSendModal,
    Spinner,
  },
  props: ['companyProject', 'company', 'brand'],
  data() {
    return {
      activeFilter: 'all',
      project: null,

      scheduleType: 'list',
      dayWidth: 15,

      projectSteps: [],

      agency: null,
      currentBrand: null,

      showPdfModal: false,

      currentCompanies: [],
      showAll: false,

      customer: null,

      isLoading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async getPdfLink() {
      try {
        const { endDate, startDate, companyType } = this.$route.query;
        let selectParams = '';

        if (this.company && this.companyProject) {
          selectParams = `&company=${this.company.companyId}`;
        } else if (this.$route.query.companies) {
          const arrayCompanies = Array.isArray(this.$route.query.companies) ? this.$route.query.companies : [this.$route.query.companies];
          if (arrayCompanies && arrayCompanies.length > 0) {
            selectParams += '&companies=';
            arrayCompanies.forEach((companyId, index) => {
              selectParams += `${companyId}`;
              if (index !== arrayCompanies.length - 1) {
                selectParams += ',';
              }
            });
          }
        } else {
          selectParams = '';
        }

        const baseServer = process.env.VUE_APP_API_URL;
        const route = `/pdf/chantiers/${this.project.projectId}`;

        const query = `?token=${auth.getToken()}${selectParams}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${companyType ? `&companyType=${companyType}` : ''}`;

        window.open(baseServer + route + query, '_blank');
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la génération du PDF',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getData() {
      this.isLoading = true;
      this.project = null;
      try {
        if (this.companyProject) {
          this.currentBrand = this.brand;
        } else {
          this.currentBrand = await auth.getBrand();
        }
      } catch (er) {
        //
      }

      if (this.$route.params.projectId) {
        try {
          this.project = await project.getById(this.$route.params.projectId);
        } catch (er) {
          //
        }
      } else {
        try {
          this.project = await project.getById(this.companyProject.projectId);
        } catch (er) {
          //
        }
      }
      try {
        this.agency = await agency.getById(this.project.agency.agencyId);
        this.customer = await customer.getById(this.project.customer.customerId);
      } catch (er) {
        //
      }

      if (this.$route.params.projectId) {
        try {
          this.projectSteps = await projectStep.getByProject(this.project.projectId);
        } catch (er) {
          //
        }

        if (this.companyProject) {
          this.projectSteps = this.projectSteps.filter((step) => (step.company && step.company.companyId === this.company.companyId) || step.type === 'START' || step.type === 'CLOSE');
        } else if (this.$route.query.companies) {
          this.projectSteps = this.projectSteps.filter((step) => (step.company && this.$route.query.companies.includes(step.company.companyId)) || step.type === 'START' || step.type === 'CLOSE');
        } else if (this.$route.query.companyType) {
          this.projectSteps = this.projectSteps.filter((step) => (step.companyCategory.companyType === this.$route.query.companyType) || step.type === 'START' || step.type === 'CLOSE');
        }

        // récupération des partenaires présents dans la sélection
        if (!this.$route.query.companies) {
          this.currentCompanies = this.projectSteps.filter((ps) => ps.company).map((ps) => ps.company.companyId);
          this.currentCompanies = Array.from(new Set(this.currentCompanies));
          this.currentCompanies = this.currentCompanies.filter((cc) => cc);
          this.showAll = true;
        } else {
          this.currentCompanies = this.$route.query.companies;
          this.showAll = false;
        }
      } else {
        this.projectSteps = this.companyProject.projectSteps;
      }

      this.projectSteps = this.projectSteps.filter((step) => step.status !== 'DONE');

      if (this.$route.query.startDate) {
        this.projectSteps = this.projectSteps.filter((step) => this.$dayjs(step.startedAt).isAfter(this.$dayjs.unix(this.$route.query.startDate), 'day')
          || this.$dayjs(step.startedAt).isSame(this.$dayjs.unix(this.$route.query.startDate), 'day') || (this.$dayjs(step.closedAt).isAfter(this.$dayjs.unix(this.$route.query.startDate), 'day') && this.$dayjs(step.startedAt).isBefore(this.$dayjs.unix(this.$route.query.startDate), 'day'))
          || step.type === 'START' || step.type === 'CLOSE' || step.startStatus === 'LATE' || step.closeStatus === 'LATE' || step.closeStatus === 'IN_PROGRESS');
      }
      if (this.$route.query.endDate) {
        this.projectSteps = this.projectSteps.filter((step) => this.$dayjs(step.closedAt).isBefore(this.$dayjs.unix(this.$route.query.endDate), 'day')
          || this.$dayjs(step.closedAt).isSame(this.$dayjs.unix(this.$route.query.endDate), 'day') || (this.$dayjs(step.startedAt).isBefore(this.$dayjs.unix(this.$route.query.endDate), 'day') && this.$dayjs(step.closedAt).isAfter(this.$dayjs.unix(this.$route.query.endDate), 'day'))
          || step.type === 'START' || step.type === 'CLOSE' || step.startStatus === 'LATE' || step.closeStatus === 'LATE' || step.closeStatus === 'IN_PROGRESS');
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss">
#project-pdf {
  max-width: 1100px;
  margin: auto auto 30px auto;
  .loader-container {
    display: flex;
    align-items: center;
    padding-top: 20%;
    justify-content: center;
  }
  .pdf-page-body {
    padding: 0px 20px 0px 20px;
  }
  .pdf-project-header {
    break-inside: avoid;
  }
  .right-align {
    text-align: right
  }
  .page-title {
    padding: 10px 0px 10px 0px;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-light;
  }
  .header {
    margin: 10px 0px 30px 0px;
    padding-top: 10px;
  }
  .tasks {
    break-inside: avoid-page;
  }
  .project-title, .date-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    margin: 0px 0px 5px 0px;
    color: $gray-dark!important;
  }
  .grey-title {
    color: $gray-regular!important;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  }
  .reference {
    display: inline-block;
    margin-top: 0px;
    border-radius: 30px;
    background-color: $gray-dark!important;
    color: $white!important;
    padding: 5px 15px 5px 15px;
    font-weight: 400;
  }
  .main-title {
    color: $blue-light!important;
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
  }
  button {
    color: $white;
    border-radius: 30px;
    border: none;
    background-color: $blue-medium;
    margin-left: 20px;
  }
  button:disabled {
    background-color: $gray-light!important;
    cursor: initial;
  }
  button.light {
    background: $white;
    color: $blue-dark;
  }
  .pdf-actions {
    position: fixed;
    bottom: 30px;
    right: 40px;
    .action {
      background-color: $blue-medium;
    }
  }
}
@page {
  size: A4 portait;
  margin: 0;
}
</style>
