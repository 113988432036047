<template>
  <div class="customer-projects hedifys-21">
    <form ref="customer-projects" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="$route.params.customerId && customer && customer.lastname"
            :items="[
              {
                route: {
                  name: routeStatus
                },
                name: routeStatus  === 'customers-unqualified' ? 'À qualifier' : routeStatus === 'customers-prospects' ? 'Prospects' : 'Clients'
              },
              { route: { name: `${routeStatus}-customerId`,
                  params: { customerId: $route.params.customerId }},
                name: `${customer.firstname} ${customer.lastname}`
              }
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="new-header grid-x">
        <div class="cell shrink">
          <h1>{{ `${customer.firstname} ${customer.lastname}` }}</h1>
        </div>
        <div class="tag-container">
          <h5 class="tag" :class="tagColor">{{ getCustomerStatusName(customer.status) }}</h5>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" v-if="$route.params.customerId" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading && customer">
        <section class="hedifys-chantier" id="customer-projects">
          <div class="wrapper">
            <vk-grid class="header" gutter="collapse">
              <div class="uk-width-1-2"><h2>Projet(s)</h2></div>
              <div class="new-project uk-width-1-2 uk-text-right">
                <button>
                  <router-link :to="{ name: 'projects-new', params: { customerId: $route.params.customerId } }">Nouveau projet</router-link>
                </button>
              </div>
            </vk-grid>
            <!-- Projets -->
            <vk-grid class="projects" v-if="projects && projects.length > 0" gutter="collapse">
              <div class="header uk-width-1-1">
                <vk-grid class="uk-width-1-1 uk-child-width-expand" gutter="collapse">
                  <h6 class="uk-width-2-5">Dossier Client</h6>
                  <h6>Lieu de construction</h6>
                  <h6>Construction</h6>
                  <h6 class="uk-width-small">Statut</h6>
                </vk-grid>
              </div>
              <div @click="projectRead(project.projectId)" class="project uk-width-1-1 uk-child-width-expand" v-for="(project, index) in projects" :key="`project-list-project-${index}`">
                <vk-grid class="uk-width-1-1 uk-child-width-expand" gutter="small">
                  <div class="customer uk-width-2-5">
                    <span class="reference">{{ project.reference }}</span>
                    <span class="name">
                      <template v-if="project.customer.type === 'PERSON'">
                        {{ `${project.customer.firstname}-${project.customer.lastname}` }}
                      </template>
                      <template v-else>
                        {{ `${project.customer.companyName}` }}
                      </template>
                    </span>
                    <br>
                    <span class="agency">{{ `${project.agency.name} - ` }}</span>
                    <span class="conductor">{{ project.member ? `${project.member.firstname}-${project.member.lastname}` : '' }}</span>
                  </div>
                  <div class="address">{{ project.address ? `${project.address.postalCode} ${project.address.city}` : '' }}</div>
                  <div class="buildFilter"><p>{{ `${project.buildFilterLabel ? project.buildFilterLabel : 'Non défini. En attente de la validation du permis de construire.'}` }}</p></div>
                  <div class="status uk-width-small">
                    <p :class="project.status ? project.status.toLowerCase() : null">{{ statuses[project.status] }}</p>
                  </div>
                </vk-grid>
              </div>
            </vk-grid>
            <template v-if="projects && projects.length <= 0">
              <hr class="uk-margin-remove">
              <div class="nothing">
                <p>Aucun projet</p>
              </div>
            </template>
          </div>
        </section>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

import customerApi from '@/services/api/customer';
import customerStatuses from '@/services/data/customerStatuses.json';

import projectApi from '@/services/api/project';
import statuses from '@/services/data/statuses.json';

export default {
  name: 'customer-projects',
  components: {
    TabNav,
    Breadcrumb,
  },
  data() {
    return {
      isLoading: false,
      customerStatuses,
      projects: [],
      statuses,
      customer: {
        agencyId: null,
        gender: null,
        lastname: null,
        firstname: null,
        secondaryContactGender: null,
        secondaryContactLastname: null,
        secondaryContactFirstname: null,
        type: 'COMPANY',
        companyName: null,
        companySiret: null,
        address: {
          addressLine1: null,
          postalCode: null,
          city: null,
        },
        status: 'UNQUALIFIED',
        firstContact: 'UNKNOWN',
      },
      genders: [
        { value: 'MALE', label: 'M.' },
        { value: 'FEMALE', label: 'Mme' },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getCustomer();
    await this.getProjects();
    this.isLoading = false;
  },
  computed: {
    routeStatus() {
      // on déduit les liens, à partir de la route actuelle
      const routeStatus = this.$route.name.split('-')[1];
      if (routeStatus === 'unqualified' || routeStatus === 'prospects') {
        return `customers-${routeStatus}`;
      }
      return 'projects-customers';
    },
    tagColor() {
      return {
        'tag-primary': this.customer.status === 'CLIENT',
        'tag-secondary': this.customer.status !== 'CLIENT' && this.customer.status !== 'UNQUALIFIED',
        'tag-neutral': this.customer.status === 'UNQUALIFIED',
      };
    },
    tabs() {
      // initialisation des tabs
      const tabs = [
        {
          route: {
            name: `${this.routeStatus}-customerId`,
            params: { customerId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-draft-projects`,
            params: { customerId: null },
          },
          label: 'Avant-projets',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-agenda`,
            params: { customerId: null },
          },
          label: 'Agenda',
        },
      ];
      // Pour les clients, on a ajoute les projets
      if (this.routeStatus === 'projects-customers') {
        tabs.push(
          {
            route: {
              name: `${this.routeStatus}-customerId-projects`,
              params: { customerId: null },
            },
            label: 'Projets',
          },
        );
      }
      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { customerId: this.$route.params.customerId },
        },
        label: el.label,
      }));
    },
  },
  methods: {
    async getCustomer() {
      try {
        if (this.$route.params.customerId) {
          this.customer = await customerApi.getById(this.$route.params.customerId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du contact',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getProjects() {
      try {
        this.projects = await projectApi.getByCustomer(this.$route.params.customerId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'L\'obtention des données des projets a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    projectRead(id) {
      this.$router.push({ name: 'projects-projectId', params: { projectId: id } });
    },
    getCustomerStatusName(status) {
      const customer = customerStatuses.find((el) => el.name === status);
      return customer ? customer.label : null;
    },
  },
};
</script>

<style lang='sass' scoped>
.customer-projects
  .body
    @include screen
  .new-header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  h3
    color: $primary
    margin-bottom: 10px
  .tag-container
    display: flex
    align-items: center
    margin-left: 16px
    > h5
      height: fit-content
  .tag-primary
    @include tag-primary
  .tag-secondary
    @include tag-secondary
  .form-section
    margin-bottom: 10px
</style>

<style lang="scss">
#customer-projects {
  .wrapper {
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);
    > .header {
      padding: 30px;
      background-color: #ffffff;
      h2 {
        color: $blue-medium;
      }
      .new-project {
        button {
          padding: 0;
          height: 35px;
          border: none;
          background-color: transparent;
          a {
            padding: 10px 25px;
            color: $gray-regular;
            text-transform: uppercase;
            border-radius: 30px;
            background-color: $gray-white;
          }
        }
      }
    }
    .projects {
      background-color: #ffffff;
      .header {
        position: relative;
        padding: 15px 0 15px 25px;
        color: $gray-light;
        border-width: 0;
        border-left-width: 8px;
        border-bottom-width: 1px;
        border-style: solid;
        border-color: $gray-light;
      }
      .project {
        position: relative;
        padding: 15px 0 15px 25px;
        border-style: solid;
        border-width: 0;
        border-left-width: 8px;
        border-left-color: $blue-medium;
        cursor: pointer;
        &:not(:first-child) {
          border-bottom-width: 1px;
          border-bottom-color: $gray-light;
        }
        > .uk-grid {
          align-items: center;
        }
        .customer {
          span.reference, span.name {
            font-weight: 500;
            text-transform: uppercase;
          }
          span.reference {
            color: $gray-dark;
          }
          span.name {
            color: $blue-medium;
          }
          span.agency {
            text-transform: uppercase;
          }
          span.agency, span.conductor {
            color: $gray-regular;
          }
        }
        .address {
          color: $gray-dark;
          font-weight: 500;
        }
        .buildFilter {
          color: $gray-regular;
        }
        .status {
          p {
            display: inline-block;
            padding: 5px 10px;
            width: 100px;
            border-width: 3px;
            border-style: solid;
            text-transform: uppercase;
            text-align: center;
            font-weight: 500;
            &.pending {
              color: $gray-regular;
              border-color: $gray-regular;
            }
            &.todo {
              color: $orange;
              border-color: $orange;
            }
            &.in_progress {
              color: $blue-medium;
              border-color: $blue-medium;
            }
            &.done {
              color: $green;
              border-color: $green;
            }
          }
        }
      }
    }
    .nothing {
      padding: 30px;
      background-color: #ffffff;
    }
  }
}
</style>
