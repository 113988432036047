<template>
  <div class="app-toggle" v-if='toggleType === "icon"'>
    <div v-for="icon in icons" :key="icon">
      <input type="radio" :name="name" :id='icon' :value='icon' v-model='picked' @change="$emit('input', icon)">
      <label :for="icon">
        <span class="blueprint-floor" v-if='icon === "blueprint2"' :class="picked === icon ? 'selected' : ''">+1</span>
        <list v-if='icon === "list"' :class="picked === icon ? 'blue' : 'grey'" />
        <locate v-if='icon === "locate"' :class="picked === icon ? 'blue' : 'grey'" />
        <blueprint v-if='icon === "blueprint"' :class="picked === icon ? 'blue' : 'grey'" />
        <house v-if='icon === "house"' :class="picked === icon ? 'blue' : 'grey'" />
        <blueprint2 v-if='icon === "blueprint2"' :class="picked === icon ? 'blue' : 'grey'" />
        <location v-if='icon === "location"' :class="picked === icon ? 'blue' : 'grey'" />
        <search v-if='icon === "search"' :class="picked === icon ? 'blue' : 'grey'" />
      </label>
    </div>
  </div>
  <div class="app-toggle" v-else-if='toggleType === "text"'>
    <div v-for="text in texts" :key="text">
      <input type="radio" :name="name" :id='text' :value='text' v-model='picked' @change="$emit('input', text)">
      <label class="toggle-text" :for="text">
        <p :class="picked === text ? 'blue' : 'grey'">{{text}}</p>
      </label>
    </div>
  </div>
</template>

<script>
import locate from '@/assets/img/locate.svg?inline';
import list from '@/assets/img/list.svg?inline';
import blueprint from '@/assets/img/blueprint.svg?inline';
import house from '@/assets/img/house.svg?inline';
import blueprint2 from '@/assets/img/blueprint2.svg?inline';
import location from '@/assets/img/location.svg?inline';
import search from '@/assets/img/search.svg?inline';

export default {
  name: 'app-toggle',

  components: {
    locate,
    list,
    blueprint,
    house,
    blueprint2,
    location,
    search,
  },

  props: {
    name: {
      type: String,
    },

    icons: {
      type: Array,
      validator: (val) => val.every((v) => ['list', 'locate', 'blueprint', 'blueprint2', 'house', 'search', 'location', 'favorite', 'all'].includes(v)),
    },

    selectedIcon: {
      type: String,
    },

    toggleType: {
      type: String,
      default: 'icon',
      validator: (v) => ['text', 'icon'].indexOf(v) !== -1,
    },

    texts: {
      type: Array,
    },
  },

  data() {
    return {
      picked: this.selectedIcon || this.icons[0],
    };
  },
  watch: {
    selectedIcon() {
      this.picked = this.selectedIcon || this.icons[0];
    },
  },
};
</script>

<style lang='sass' scoped>
.grey
  stroke: $line
  rect, path
    stroke: $line
  mask
    // fill: $line
.blue
  stroke: $primary
  rect, path
    stroke: $primary
  mask
    // fill: $primary

.app-toggle
  display: flex
  flex-direction: row

  > div
    &:first-child label
      border-radius: 4px 0 0 4px

    &:last-child label
      border-radius: 0 4px 4px 0

  input[type='radio']
    display: none

    &:checked ~ label
      border-color: $primary
      box-shadow: 0 0 2px $primary

    &:checked ~ .toggle-text > p
      color: $primary

  label
    cursor: pointer
    user-select: none
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    background-color: $off-white
    border: 1px solid $line
    position: relative

  .toggle-text
    width: 80px
    height: 38px

  svg
    width: 20px
    height: 20px

  .blueprint-floor
    position: absolute
    font-size: 10px
    color: $line
    top: 4px
    right: 2px
    display: block
    line-height: 8px
    background-color: white
    border: $line 1px solid
    border-radius: 2px
    padding: 2px 1px
    font-weight: 500
    &.selected
      color: $primary
      border: $primary 1px solid
</style>
