<template>
  <div class="dropdown-actions">
    <button class="dropdown-button" @click="toggle">
      <h4 class="text">{{ title }}</h4>
      <span v-if="hasArrow" class="icon arrow">
        <img class="img" src="@/assets/img/arrow-down.svg" alt="icon" />
      </span>
    </button>
    <ul
      v-if="isOpen && actions.length > 0"
      class="dropdown-content">
      <li v-for="(action, index) in actions" :key="`action-${index}`">
        <a @click="onClick(action)">
          {{ action.title ? action.title : '' }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'dropdown',
  props: {
    title: {
      type: String,
    },
    actions: {
      type: Array,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('click', this.close);
    this.isOpen = false;
  },
  methods: {
    onClick(item) {
      this.isOpen = false;
      item.callback();
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.$emit('dropdownClosed');
      }
    },
  },
};
</script>

<style lang="sass">
.dropdown-actions
  width: 100%
  height: 100%
  .dropdown-button
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    background-color: #3E4558
    color: white
    border: none
    border-radius: 4px
    height: 40px
    padding: 0 16px
    .text
      padding-right: 12px
    .icon
      height: 100%
      border-left: 1px solid #2b3141
      padding-left: 14px
      .img
        position: relative
        top: 25%
        width: 12px
        height: 12px
  .dropdown-content
    border: 1px solid $line
    border-radius: 4px
    z-index: 5
    position: absolute
    margin: 10px 0
    white-space: nowrap
    background: white
    padding: 0
    li
      padding: 12px 16px
      display: flex
      flex-direction: row-reverse
      &:hover
        background-color: $background
      a
        display: inline-block
        width: 100%
        font-size: 12px
        color: $body
        text-align: left
</style>
