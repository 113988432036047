var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"versions hedifys-21"},[_c('div',{staticClass:"header grid-x"},[_vm._m(0),_c('div',{staticClass:"cell shrink"},[(_vm.isBrandAdmin)?_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add"},on:{"click":_vm.goToVersion}},[_vm._v("Ajouter une version")]):_vm._e()],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"search grid-x"},[_c('div',{staticClass:"cell shrink search"},[_c('app-search',{attrs:{"size":"small","placeholder":"Rechercher une version"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"cell shrink button"},[_c('filter-modal',{attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return !!i; }).length},on:{"change":_vm.filterVersions,"reset":_vm.resetFilers,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-radio-button',{attrs:{"label":"Filtrer par prix","input":_vm.filters.order,"options":[
                  { name: 'price', label: 'Prix croissant' },
                  { name: '-price', label: 'Prix décroissant' }
                ]},model:{value:(_vm.filters.order),callback:function ($$v) {_vm.$set(_vm.filters, "order", $$v)},expression:"filters.order"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-select',{attrs:{"label":"Gamme","options":_vm.rangeOptions,"disabled":_vm.rangeOptions.length === 0},on:{"input":_vm.resetModelOptions},model:{value:(_vm.filters.rangeId),callback:function ($$v) {_vm.$set(_vm.filters, "rangeId", $$v)},expression:"filters.rangeId"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-select',{attrs:{"label":"Modèle","options":_vm.modelOptions,"disabled":_vm.modelOptions.length === 0},model:{value:(_vm.filters.modelId),callback:function ($$v) {_vm.$set(_vm.filters, "modelId", $$v)},expression:"filters.modelId"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-select',{attrs:{"label":"Style","options":_vm.styleOptions,"disabled":_vm.styleOptions.length === 0},model:{value:(_vm.filters.styleId),callback:function ($$v) {_vm.$set(_vm.filters, "styleId", $$v)},expression:"filters.styleId"}})],1)])])],1)]),_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.versions.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToVersion},scopedSlots:_vm._u([{key:"versionMedia",fn:function(ref){
                var data = ref.data;
return [(data.versionMedia)?_c('img',{staticClass:"image",attrs:{"src":data.versionMedia,"alt":"Visuel de la version"}}):_c('div',{staticClass:"image not-found"},[_c('upload',{staticClass:"icon-upload"})],1)]}},{key:"name",fn:function(ref){
                var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}},{key:"reference",fn:function(ref){
                var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.reference))])]}},{key:"range",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.range.name))])]}},{key:"surface",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.surface)+" m²")])]}},{key:"price",fn:function(ref){
                var data = ref.data;
return [(data.price || data.price === 0)?_c('strong',{staticClass:"price"},[_vm._v(_vm._s(_vm.utils.formatCentToEuro(data.price, true)))]):_c('app-spinner',{attrs:{"small":""}})]}},{key:"surfaceHabitable",fn:function(ref){
                var data = ref.data;
return [(data.surfaces && data.surfaces.length && data.price)?_c('p',[_vm._v(" "+_vm._s(data.surfaces && data.surfaces.length && data.price ? _vm.getPriceBySurface(data) : '0 €')+" /m² ")]):_c('p',[_vm._v("0 €/m²")])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2),(_vm.versions.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.versions.metadata.offset,"count":_vm.versions.metadata.count}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Versions")])])}]

export { render, staticRenderFns }