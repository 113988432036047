import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère une gamme
 * @param {string} rangeId: id de la gamme à récupérer
 */
const getById = async (rangeId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/ranges/${rangeId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des gammes
 * @param {string} search: paramètre de recherche, sur le nom de la gamme
 */
const getAll = async (search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/ranges/`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'une gamme
 * @param {range} range: objet de la gamme à créer
 */
const create = async (range) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/ranges/`, range);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'une gamme
 * @param {range} range: nouvel objet de la gamme
 * @param {string} rangeId: id de la gamme à récupérer
 */
const update = async (rangeId, range) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/ranges/${rangeId}`, range);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression de la gamme
 * @param {string} rangeId: id de la gamme à récupérer
 */
const deleteOne = async (rangeId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/ranges/${rangeId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const range = {};
range.getAll = getAll;
range.getById = getById;
range.create = create;
range.update = update;
range.delete = deleteOne;

export default range;
