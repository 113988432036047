<template>
  <div class="diffusion-logs">
    <form ref="diffusion-logs" @submit.prevent="save">

      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Configuration diffusion</h1>
        </div>
        <div class="cell shrink">
        </div>
      </div>

      <!-- TABS -->
      <tab-nav :tabs="tabs"/>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="grid-x grid-margin-x filter">
          <div class="cell medium-3">
            <app-datepicker
              v-model="selectedDate"
              @input="getData"
              :language="french"
              :monday-first="true"
              format="dd MMMM yyyy"
              :clear-button="false">
            </app-datepicker>
          </div>
          <div class="cell auto"></div>
        </div>
        <app-table class="agencies" :headers="tableHeaders" :data="diffusionLogs.data" :loading="isLoading">
          <template slot="empty-table">
            <p>Aucune données de disponible</p>
          </template>
          <template slot="reference" slot-scope="{ data }">
            <strong>{{ data.advertisement.reference }}</strong>
          </template>
          <template slot="agency" slot-scope="{ data }">
            <p>{{ data.agency.code }}</p>
          </template>
          <template slot="type" slot-scope="{ data }">
            <p v-if="data.advertisement.isGenerated && data.version && data.version.versionId">T+M auto</p>
            <p v-else-if="data.advertisement.isGenerated && (!data.version || !data.version.versionId)">T auto</p>
            <p v-else-if="!data.advertisement.isGenerated">T+M man</p>
          </template>
          <template slot="land" slot-scope="{ data }">
            <p v-if="data.land && data.land.landId">
              {{ data.land.lotNumber ? `Lot ${data.land.lotNumber}, ` : '' }}
              {{ data.land.surface ? `${data.land.surface} m², ` : ''  }}
              {{ data.land.city ? data.land.city : '' }}
            </p>
          </template>
          <template slot="version" slot-scope="{ data }">
            <p>{{ data.version.reference }}</p>
          </template>
          <template slot="diffuser" slot-scope="{ data }">
            <p>{{ data.publishers && data.publishers.length ? data.publishers.map(p => publishers[p]).join(', ') : '-' }}</p>
          </template>
          <template slot="action" slot-scope="{ data }">
            <p>{{ advertisementActions[data.action] }}</p>
          </template>
          <template slot="publicationDate" slot-scope="{ data }">
            <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY') }}</p>
          </template>
        </app-table>
        <app-pagination
          v-if="diffusionLogs.metadata"
          :limit="limit"
          :offset="diffusionLogs.metadata.offset"
          :count="diffusionLogs.metadata.count"
        />
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { fr } from 'vuejs-datepicker/dist/locale';
import advertisementLogApi from '@/services/api/advertisementLog';
import TabNav from '@/components/layouts/TabNav.vue';
import publishers from '@/services/enums/publishers.enum';
import advertisementActions from '@/services/enums/advertisementActions.enum';

export default {
  name: 'diffusion-logs',
  components: {
    TabNav,
  },
  metaInfo: {
    title: 'Configuration diffusion',
  },
  data() {
    return {
      french: fr,
      tabs: [
        {
          route: {
            name: 'diffusions-quotas',
            params: {},
          },
          label: 'Gestion',
        },
        {
          route: {
            name: 'diffusions-statistics',
            params: {},
          },
          label: 'Statistiques',
        },
        {
          route: {
            name: 'diffusions-templates',
            params: {},
          },
          label: 'Templates',
        },
        {
          route: {
            name: 'diffusions-ubiflow',
            params: {},
          },
          label: 'Ubiflow',
        },
        {
          route: {
            name: 'diffusions-advertisement-packs',
            params: {},
          },
          label: 'Packs',
        },
        {
          route: {
            name: 'diffusions-logs',
            params: {},
          },
          label: 'Logs',
        },
      ],
      tableHeaders: [
        { label: 'Référence', key: 'reference', size: 2 },
        { label: 'Agence', key: 'agency', size: 1 },
        { label: 'Type', key: 'type', size: 1 },
        { label: 'Terrain', key: 'land', size: 'auto' },
        { label: 'Version', key: 'version', size: 2 },
        { label: 'Diffuseur', key: 'diffuser', size: 2 },
        { label: 'Action', key: 'action', size: 1 },
        { label: 'Date de l\'action', key: 'publicationDate', size: 1 },
      ],
      isLoading: null,
      diffusionLogs: {
        data: [],
        metadata: {},
      },
      limit: 10,
      publishers,
      advertisementActions,
      selectedDate: null,
    };
  },
  async mounted() {
    this.selectedDate = new Date();
    await this.getData();
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getDiffusionLogs(this.computeOffset);
        this.isLoading = false;
      }
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getDiffusionLogs();
      this.isLoading = false;
    },
    async getDiffusionLogs() {
      try {
        this.diffusionLogs = await advertisementLogApi.getAll(
          this.limit,
          this.computeOffset,
          this.selectedDate,
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des logs des annonces',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.diffusion-logs
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .card
    @include card
</style>
