import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/build-filters`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getById = async (buildFilterId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/build-filters/${buildFilterId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const create = async (buildFilter) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/build-filters`, buildFilter);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const duplicate = async (buildFilterId, agencyId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/build-filters/${buildFilterId}/duplicate`, { agencyId });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const edit = async (buildFilter) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/build-filters/${buildFilter.buildFilterId}`, buildFilter);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const deleteOne = async (buildFilterId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/build-filters/${buildFilterId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const buildFilters = {};
buildFilters.getAll = getAll;
buildFilters.getById = getById;
buildFilters.create = create;
buildFilters.edit = edit;
buildFilters.delete = deleteOne;
buildFilters.duplicate = duplicate;

export default buildFilters;
