<template>
  <div class="app-search-city" :class="size">
    <label>
      <span v-if="label" class="label" :class="{ mandatory: required }">{{ label }}</span>
      <locate class="icon"/>
      <input
        ref="search"
        type="text"
        v-model="search"
        :required="required"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="updateSearch"
        autocomplete="no"
      />
      <cancel
        v-if="search && search.length"
        @click.prevent="clearSearch"
        class="cancel"
      />
    </label>
    <transition name="app-search-city-list">
      <ul v-show="isOpen && postalCodeOptions.length > 0">
        <app-scrollable>
          <li
            role="option"
            v-for="option in postalCodeOptions"
            :key="option.name"
            @mousedown="selectOption($event, option)"
          >
            <span>{{ option.label }}</span>
          </li>
        </app-scrollable>
      </ul>
    </transition>
  </div>
</template>

<script>
import locate from '@/assets/img/locate.svg?inline';
import cancel from '@/assets/img/cancel.svg?inline';
import postalCodeApi from '@/services/api/postalCode';
import utils from '@/services/utils/utils';

export default {
  name: 'app-search-city',

  components: {
    locate,
    cancel,
  },

  props: {
    placeholder: {
      type: String,
      default: 'Rechercher...',
    },

    label: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    selectedPostalCode: {
      type: String,
    },

    required: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
      default: 'large',
    },
  },

  data() {
    return {
      isOpen: false,
      search: null,
      postalCodes: [],
      postalCodeOptions: [],
    };
  },

  watch: {
    search(value) {
      if (value === '') {
        this.clearSearch();
      }
    },
    selectedPostalCode(value) {
      this.search = value;
    },
  },

  created() {
    this.debouncedUpdateSearch = utils.debounce(this.getPostalCodes, 500);
  },

  mounted() {
    if (this.selectedPostalCode) {
      this.search = this.selectedPostalCode;
    }
  },

  computed: {
    input() {
      return this.value;
    },
  },

  methods: {
    async getPostalCodes() {
      try {
        // On vérifie si le champ est remplie, si non, on vide les anciens résultats
        if (this.search && this.search.length >= 3) {
          this.postalCodes = await postalCodeApi.getAll(this.search);
          this.postalCodeOptions = utils.formatOptions(
            this.postalCodes,
            (option) => option.postalCodeInseeNumber,
            (option) => `${option.city} - ${option.postalCodeInseeNumber.split('-')[0]}`,
          );
        } else if (!this.search || this.search.length === 0) {
          this.postalCodes = [];
          this.postalCodeOptions = [];
        }

        // On ouvre la liste des propositions si il y a des codes postaux de trouvés
        this.isOpen = this.postalCodeOptions.length > 0;
      } catch (error) {
        throw error;
      }
    },

    updateSearch() {
      this.debouncedUpdateSearch();
    },

    // Ouvre ou ferme la liste des options
    showOptions() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },

    // Vide le champ
    clearSearch() {
      this.selected = null;
      this.search = null;
      this.isOpen = false;
      this.$emit('clear');
    },

    // Sélectionne le code postal choisi
    selectOption(event, option) {
      if (event.button === 0) {
        this.selected = option;
        this.search = option.label;
        this.isOpen = false;
        this.$emit('select', this.selected);
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.app-search-city
  display: flex
  flex-direction: column
  position: relative
  &.small
    width: 50% !important
    min-width: 240px !important
  &.large
    width: 100%
  label
    display: flex
    flex-direction: column
    position: relative
    width: 100%
  .label
    @include heading-xs
    color: $label
    padding-bottom: 4px
    &.mandatory:after
      content: "*"
      display: inline-block
      transform: translateX(0.3rem) translateY(-0.15rem)
      color: $label
  .icon
    stroke: $info
    position: absolute
    bottom: 12px
    left: 18px
    width: 14px
    height: 14px
  .cancel
    cursor: pointer
    position: absolute
    bottom: 14px
    right: 18px
    width: 10px
    height: 10px
  input
    @include paragraph
    display: flex
    padding: 10px 16px 10px 42px
    background-color: $off-white
    border: 1px solid $line
    border-radius: 4px
    color: $body
    margin: 0
    &:focus
      outline: none
      border: 1px solid $primary
      box-shadow: 0 0 2px $primary
    &:disabled
      background-color: $background
      color: $info
      pointer-events: none
      cursor: not-allowed
    &::placeholder
      color: $info
  ul
    position: absolute
    top: 100%
    left: 0
    margin-top: 4px
    padding: unset
    display: flex
    flex-direction: column
    background-color: $off-white
    color: $label
    box-shadow: $drop-med
    width: 100%
    max-height: 148px
    border-radius: 4px
    z-index: 999
  li
    cursor: pointer
    padding: 0.5rem 1rem
    &:hover
      background-color: rgba($primary, 0.1)
    span
      @include paragraph

  .app-search-city-list-enter-active, .app-search-city-list-leave-active
    transition: transform .3s, opacity .3s, scale .3s ease-in-out

  .app-search-city-list-enter, .app-search-city-list-leave-to
    opacity: 0
</style>
