import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

const create = async (contactId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/favorite-contacts/${contactId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const customers = {};
customers.delete = async (contactId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/favorite-contacts/${contactId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

customers.create = create;

export default customers;
