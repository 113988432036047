<template>
  <div class="agency-postal-codes hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="agency && agency.name"
          :items="[
            { route: { name: 'agencies'}, name: 'Agences'},
            { route: { name: 'agencies-agencyId', params: { agencyId: $route.params.agencyId }}, name: agency.name}
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>{{ agency && agency.name ? agency.name : '' }}</h1>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <div class="body" v-if="agency">
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <PostalCode
            :postalCodes="postalCodes"
            :isLoading="isLoading"
            :borders="borders"
            :showInvalidFileMessage="showInvalidFileMessage"
            :showInvalidPostalCodeMessage="showInvalidPostalCodeMessage"
            @getPostalCodes="getPostalCodes"
            @importPostalCodes="importPostalCodes"
            @addPostalCode="addPostalCode"
            @deletePostalCode="deletePostalCode"
            @hideInvalidPostalCodeMessage="showInvalidPostalCodeMessage = false"
            @hideInvalidFileMessage="showInvalidFileMessage = false"
            :invalidPostalCodes="invalidPostalCodes"
            :readonly="!this.isBrandAdmin"
            largeMap
          />
        </div>
      </div>
    </div>
    <div class="body" v-else>
      <div class="card spinner-container">
        <app-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import member from '@/services/api/member';
import agency from '@/services/api/agency';
import postalCode from '@/services/api/postalCode';
import PostalCode from '@/components/general/PostalCode.vue';

export default {
  name: 'agency-postal-codes',
  components: {
    TabNav,
    Breadcrumb,
    PostalCode,
  },
  metaInfo() {
    return {
      title: this.agency ? `${this.agency.name} – Agence` : 'Agence',
    };
  },
  data() {
    return {
      tabs: [],
      agency: null,
      isLoading: null,
      postalCodes: [],
      borders: {
        type: 'FeatureCollection',
        name: 'borders',
        features: [],
      },
      showInvalidFileMessage: false,
      showInvalidPostalCodeMessage: false,
      invalidPostalCodes: null,
      isBrandAdmin: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    const me = await member.getMe();
    this.isBrandAdmin = me.isBrandAdmin;
    this.tabs = [
      {
        route: {
          name: 'agencies-agencyId',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Informations',
      },
      {
        route: {
          name: 'agencies-agencyId-postal-codes',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Secteurs',
      },
      {
        route: {
          name: 'agencies-agencyId-medias',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Médias',
      },
      {
        route: {
          name: 'agencies-agencyId-members',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Membres',
      },
    ];
    // Masque l'onglet média aux admins d'agence
    if (!this.isBrandAdmin) {
      const index = this.tabs.map((e) => e.label).indexOf('Médias');
      this.tabs.splice(index, 1);
    }
    this.getAgency();
    this.getPostalCodes();
    this.isLoading = false;
  },
  methods: {
    async getAgency() {
      try {
        if (this.$route.params.agencyId) {
          this.agency = await agency.getById(this.$route.params.agencyId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des données des borders
    async getPostalCodes(search) {
      try {
        if (this.$route.params.agencyId) {
          this.postalCodes = await postalCode.getByAgency(this.$route.params.agencyId, search);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des codes postaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      // On vide le tableau des frontières des borders

      // Récupération des frontières pour les inclure dans l'objet 'borders' si il y a
      if (this.postalCodes.length > 0) {
        this.borders.features = this.postalCodes.map((cp) => cp.borders);
      } else {
        this.borders.features = [];
      }
    },

    // Importation de la liste des codes postaux
    async importPostalCodes(file, search) {
      this.isLoading = true;
      if (file) {
        try {
          if (this.$route.params.agencyId) {
            await postalCode.importFileByAgency(this.$route.params.agencyId, file[0]);
          }
        } catch (error) {
          const invalidPostalCodes = error.message.split(':');
          invalidPostalCodes.splice(0, 1);
          this.invalidPostalCodes = invalidPostalCodes;
          this.showInvalidFileMessage = true;
        }
      }
      await this.getPostalCodes(search);
      this.isLoading = false;
    },

    // Ajout d'un code postal
    async addPostalCode(selectedPostalCode, search) {
      this.isLoading = true;
      this.invalidPostalCodes = [];
      try {
        if (this.$route.params.agencyId) {
          await postalCode.addOneByAgency(this.$route.params.agencyId, selectedPostalCode);
        }
      } catch (error) {
        const invalidPostalCode = error.message.split(':')[1];
        this.invalidPostalCodes = [invalidPostalCode];

        this.showInvalidPostalCodeMessage = true;
      }
      await this.getPostalCodes(search);
      this.isLoading = false;
    },

    // Supprime un code postal de la liste
    async deletePostalCode(postalCodeInseeNumber, search) {
      this.isLoading = true;
      try {
        if (this.$route.params.agencyId) {
          await postalCode.deleteOneByAgency(this.$route.params.agencyId, postalCodeInseeNumber);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression du code postal',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      await this.getPostalCodes(search);
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.agency-postal-codes
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
</style>
