<template>
  <div class="diffusion-statistics">
    <form ref="diffusion-statistics">

      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Configuration diffusion</h1>
        </div>
      </div>

      <!-- TABS -->
      <tab-nav :tabs="tabs"/>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="grid-x grid-margin-x filter">
          <div class="cell medium-3">
            <app-datepicker
              v-model="selectedDate"
              @input="dateChanged"
              :language="french"
              :monday-first="true"
              format="dd MMMM yyyy"
              :clear-button="false">
            </app-datepicker>
          </div>
          <div class="cell auto"></div>
        </div>
        <div class="table grid-x">
          <div class="cell auto">
            <app-table class="diffusion-statistics-table" :headers="tableHeaders" :data="statistics" :loading="isLoading">
              <template slot="empty-table">
                <p>Aucune données de disponible</p>
              </template>
              <template slot="agency" slot-scope="{ data }">
                <strong>{{ data.name }}</strong>
              </template>
              <template slot="citiesCount" slot-scope="{ data }">
                <p>{{ data.statistics.citiesCount }}</p>
              </template>
              <template slot="manualCount" slot-scope="{ data }">
                <p>{{ data.statistics.manualCount }}</p>
              </template>
              <template slot="landCount" slot-scope="{ data }">
                <p>{{ data.statistics.landCount }}</p>
              </template>
              <template slot="landVersionCount" slot-scope="{ data }">
                <p>{{ data.statistics.landVersionCount }}</p>
              </template>
              <template slot="totalAdvertisements" slot-scope="{ data }">
                <p>{{ data.statistics.totalAdvertisements }}</p>
              </template>
            </app-table>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import { fr } from 'vuejs-datepicker/dist/locale';
import advertisementApi from '@/services/api/advertisement';
import agencyApi from '@/services/api/agency';
import TabNav from '@/components/layouts/TabNav.vue';

export default {
  name: 'diffusion-statistics',
  components: {
    TabNav,
  },
  metaInfo: {
    title: 'Configuration diffusion',
  },
  data() {
    return {
      french: fr,
      tabs: [
        {
          route: {
            name: 'diffusions-quotas',
            params: {},
          },
          label: 'Gestion',
        },
        {
          route: {
            name: 'diffusions-statistics',
            params: {},
          },
          label: 'Statistiques',
        },
        {
          route: {
            name: 'diffusions-templates',
            params: {},
          },
          label: 'Templates',
        },
        {
          route: {
            name: 'diffusions-ubiflow',
            params: {},
          },
          label: 'Ubiflow',
        },
        {
          route: {
            name: 'diffusions-advertisement-packs',
            params: {},
          },
          label: 'Packs',
        },
        {
          route: {
            name: 'diffusions-logs',
            params: {},
          },
          label: 'Logs',
        },
      ],
      tableHeaders: [
        { label: 'Agence', key: 'agency', size: 2 },
        { label: 'Nombre communes retenues', key: 'citiesCount', size: 2 },
        { label: 'PA Terrain + Maison manuelles envoyées', key: 'manualCount', size: 2 },
        { label: 'PA Terrain auto envoyées', key: 'landCount', size: 2 },
        { label: 'PA Terrain + maison auto envoyées', key: 'landVersionCount', size: 2 },
        { label: 'Total PA envoyées', key: 'totalAdvertisements', size: 2 },
      ],
      statistics: [],
      agencies: [],
      dateOptions: [],
      date: null,
      isLoading: null,
      selectedDate: null,
    };
  },
  async mounted() {
    this.date = this.$route.query.date || null;
    await this.getData();
  },
  watch: {
    date() {
      this.$router.push({
        query: {
          ...this.$route.query,
          type: this.date || undefined,
        },
      });

      this.getStatistics();
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getAgencies();
      this.selectedDate = new Date();
      await this.getStatistics();

      this.dateOptions = [
        { name: '1/10/21', label: 'Lundi 1 octobre 2021' },
        { name: '2/10/21', label: 'Mardi 2 octobre 2021' },
        { name: '3/10/21', label: 'Mercredi 3 octobre 2021' },
        { name: '4/10/21', label: 'Jeudi 4 octobre 2021' },
        { name: '5/10/21', label: 'Vendredi 5 octobre 2021' },
        { name: '6/10/21', label: 'Samedi 6 octobre 2021' },
        { name: '7/10/21', label: 'Dimanche 7 octobre 2021' },
      ];
      this.isLoading = false;
    },
    async dateChanged() {
      this.isLoading = true;
      await this.getStatistics();
      this.isLoading = false;
    },
    async getAgencies() {
      try {
        this.agencies = await agencyApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getStatistics() {
      try {
        if (this.agencies && this.agencies.length && this.selectedDate) {
          const agenciesPromises = this.agencies.map(async (agency) => {
            const agencyStatistics = await advertisementApi.getStatistics(agency.agencyId, this.$dayjs(this.selectedDate).format('YYYY-MM-DD'));
            return {
              ...agency,
              statistics: agencyStatistics,
            };
          });
          this.statistics = await Promise.all(agenciesPromises);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des statistiques',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.diffusion-statistics
  &-table
    height: inherit
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .card
    @include card
  .filter
    padding-bottom: 16px
</style>
