import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des lotissement
 * @param {string} limit: limit d'affichage par page
 * @param {string} offset: offset de la pagination
 * @param {string} search: paramètre de recherche, sur le nom du modèle
 * @param {boolean} noLimit: bool, pour savoir si on veut utiliser la pagination ou non
 */
const getAll = async (limit, offset, search, companyId, agencyId, noLimit) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/allotments/`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(companyId && { companyId }),
        ...(agencyId && { agencyId }),
        ...(noLimit && { noLimit }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère un lotissement avec son id
 * @param {string} allotmentId id du lotissement
 */
const getById = async (allotmentId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/allotments/${allotmentId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'un nouveau lotissement
 * @param {object} allotment Lotissement à créer
 */
const create = async (allotment) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/allotments`, allotment);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Upload du pdf du lotissement
 * @param {string} allotmentId: id de l'agence
 * @param {File} file: pdf à upload
 */
const upload = async (allotmentId, file) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/allotments/${allotmentId}/pdf`, file, {
      headers: {
        'Content-Type': 'application/pdf',
      },
    });
    return response;
  } catch (er) {
    throw er;
  }
};

/**
 * Supprime un lotissement
 * @param {string} allotmentId id du lotissement à supprimer
 */
const deleteOne = async (allotmentId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/allotments/${allotmentId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un lotissement
 * @param {string} allotmentId id du lotissement
 * @param {object} allotment lotissement à mettre à jour
 */
const update = async (allotmentId, allotment) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/allotments/${allotmentId}`, allotment);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const allotment = {};
allotment.getAll = getAll;
allotment.create = create;
allotment.getById = getById;
allotment.delete = deleteOne;
allotment.update = update;
allotment.upload = upload;
export default allotment;
