import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des agences
 * @param {string} search: paramètre de recherche, sur le nom de l'agence
 */
const getAll = async (limit, offset, isPublishable, agencyId, isGenerated) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/advertisements`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(isPublishable && { isPublishable }),
        ...(agencyId && { agencyId }),
        ...(isGenerated && { isGenerated }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une annonce
 * @param {string} advertisementId: id de l'annonce
 */
const getById = async (advertisementId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/advertisements/${advertisementId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'une annonce
 * @param {advertisement} advertisementId: objet de l'annocne
 */
const create = async (advertisement) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/advertisements/`, advertisement);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'une annonce
 * @param {advertisement} advertisement: nouvel objet de l'annonce
 * @param {string} advertisementId: id de la gamme à récupérer
 */
const update = async (advertisementId, advertisement) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/advertisements/${advertisementId}`, advertisement);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression d'une annonce
 * @param {string} advertisementId: id de l'annonce
 */
const deleteOne = async (advertisementId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/advertisements/${advertisementId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération des statistiques d'une journée
 * @param {string} agencyId: id de l'agence
 */
const getStatistics = async (agencyId, date) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/${agencyId}/advertisement-statistics/${date}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const advertisement = {};
advertisement.getAll = getAll;
advertisement.getById = getById;
advertisement.create = create;
advertisement.update = update;
advertisement.deleteOne = deleteOne;
advertisement.getStatistics = getStatistics;

export default advertisement;
