import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère un coefficient
 * @param {string} agencyCoefficientId: id du coefficient à récupérer
 */
const getById = async (agencyCoefficientId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agency-coefficients/${agencyCoefficientId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des coefficients
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agency-coefficients/`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'un coefficient
 * @param {agencyCoefficient} agencyCoefficient: objet du coefficient à créer
 */
const create = async (agencyCoefficient) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/agency-coefficients/`, agencyCoefficient);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un coefficient
 * @param {agencyCoefficient} agencyCoefficient: nouvel objet du coefficient
 * @param {string} agencyCoefficientIdId: id du coefficient
 */
const update = async (agencyCoefficientId, agencyCoefficient) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/agency-coefficients/${agencyCoefficientId}`, agencyCoefficient);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression du coefficient
 * @param {string} agencyCoefficientId: id du coefficient à récupérer
 */
const deleteOne = async (agencyCoefficientId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/agency-coefficients/${agencyCoefficientId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const agencyCoefficient = {};
agencyCoefficient.getAll = getAll;
agencyCoefficient.getById = getById;
agencyCoefficient.create = create;
agencyCoefficient.update = update;
agencyCoefficient.delete = deleteOne;

export default agencyCoefficient;
