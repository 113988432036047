<template>
  <div class="advertisement">
    <form ref="advertisement" @submit.prevent="save">
      <!-- BREADCRUMBS -->
        <div class="breadcrumbs grid-x row">
          <div class="cell auto">
            <breadcrumb
              v-if="advertisement && advertisement.reference"
              :items="[
                { route: { name: 'diffusions-advertisements'}, name: 'Annonces'},
                { route: { name: 'diffusions-advertisements-advertisementId', params: { advertisementId: $route.params.advertisementId }}, name: advertisement.reference }
              ]"
            />
            <breadcrumb
              v-else
              :items="[
                { route: { name: 'diffusions-advertisements'}, name: 'Annonces'}
              ]"
            />
          </div>
        </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x align-middle">
        <div class="cell" :class="addingVersions || addingLands ? 'stretch' : 'auto'">
          <h1>{{ title }}</h1>
        </div>
        <template v-if="!addingVersions && !addingLands">
          <div class="cell shrink" v-if="advertisement.advertisementId">
            <app-button theme="warning" size="large" icon="remove" @click="openDeleteModal">Archiver</app-button>
          </div>
          <div class="cell shrink" v-if="!advertisement.advertisementId">
            <app-button theme="primary" size="large" type="submit" :disabled="!this.advertisement.land || !this.advertisement.version">Enregistrer</app-button>
          </div>
        </template>
        <template v-else>
          <div class="cell small-12 back-button">
            <a @click="addingVersions = false; addingLands = false;"><returnArrow />Revenir à l'annonce</a>
          </div>
        </template>
      </div>
      <div class="spinner-container" v-if="loading">
        <app-spinner />
      </div>
      <AddingVersionsBody v-else-if="addingVersions" class="body" :versionsAdded="versionsId" @update:addingVersions="addingVersions = false" @add:version="addVersion" />
      <AddingLandsBody v-else-if="addingLands" class="body" :landsAdded="landsId" @update:addingLands="addingLands = false" @add:land="addLand" />

      <div class="body" v-else>
        <div class="sub-section" v-if="advertisement && advertisement.advertisementId">
          <h3>Reference</h3>
          <div class="search grid-x">
            <div class="cell shrink">
              <app-input v-model="advertisement.reference" required placeholder="Nom du reference..." disabled />
            </div>
          </div>
        </div>
        <div class="grid-x">
          <div class="cell auto">
            <app-label>Annonce publiable</app-label>
            <div class="grid-x radio-container">
              <div class="cell shrink">
                <app-radio v-model="advertisement.isPublishable" :value="true">Oui</app-radio>
              </div>
                <div class="cell auto">
                  <app-radio v-model="advertisement.isPublishable" :value="false">Non</app-radio>
                </div>
              </div>
          </div>
          <div class="cell auto"></div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto" v-if="advertisement && advertisement.land">
            <app-label>Terrain</app-label>
            <div class="card">
              <h3>Terrain {{ advertisement.land.surface }} m²</h3>
              <p>{{ advertisement.land.reference }}</p>
              <p>{{ advertisement.land.address && advertisement.land.address.postalCodeInseeNumber ? `${advertisement.land.address.postalCodeInseeNumber.split('-')[0]} - ` : '' }} {{ advertisement.land.address ? advertisement.land.address.city : '' }}</p>
              <p>{{ advertisement.land.price ? utils.formatCentToEuro(advertisement.land.price, true, true) : '' }}</p>
              <div class="cta-container" v-if="!advertisement.advertisementId">
                <app-button theme="primary" size="small" @click="addingLands = true">Modifier</app-button>
                <app-button theme="warning" size="small" @click="removeLand">Supprimer</app-button>
              </div>
            </div>
          </div>
          <div class="cell auto" v-else-if="!isLoading">
            <app-label>Terrain</app-label>
            <div class="card empty">
              <h3>Sélectionnez un terrain</h3>
               <app-button theme="primary" size="small" icon="add" @click="addingLands = true">Ajouter un terrain</app-button>
            </div>
          </div>
          <div class="cell auto" v-if="advertisement && advertisement.version">
            <app-label>Version</app-label>
            <div class="card grid-x">
              <div class="cell shrink">
                <img v-if="advertisement.version.versionMediaMain" :src="advertisement.version.versionMediaMain" />
                <img v-else-if="advertisement.version.versionMedia" :src="advertisement.version.versionMedia" />
              </div>
              <div class="cell auto">
                <h3>{{ advertisement.version.name }}</h3>
                <p>Maison {{ advertisement.version.numberOfRooms }} chambres de {{ advertisement.version.surface }} m²</p>
                <p>{{ advertisement.version.price ? utils.formatCentToEuro(advertisement.version.price, true, true) : '' }}</p>
                <div class="cta-container" v-if="!advertisement.advertisementId">
                  <app-button theme="primary" size="small" @click="addingVersions = true">Modifier</app-button>
                  <app-button theme="warning" size="small" @click="removeVersion">Supprimer</app-button>
                </div>
              </div>
            </div>
          </div>
          <div class="cell auto" v-else-if="!isLoading">
            <app-label>Version</app-label>
            <div class="card empty">
              <h3>Sélectionnez une version</h3>
                <app-button :disabled="!this.advertisement.land" theme="primary" size="small" icon="add" @click="addingVersions = true">Ajouter une version</app-button>
            </div>
          </div>
          <div class="cell small-12">
            <div class="card">
              <h3>Descriptif</h3>
              <form>
                <p>Type de descriptif visible sur l'annonce</p>
                <div class="cta-container radio-container">
                  <app-radio v-model="isUsingCustomDescription" :value="false">Automatique</app-radio>
                  <app-radio v-model="isUsingCustomDescription" :value="true">Manuel</app-radio>
                </div>
                <div class="description-input-container">
                  <app-textarea readonly resize="vertical" label="Description automatique" :value="advertisement.version ? automaticDescriptionLandVersion : automaticDescriptionLand"/>
                  <app-textarea resize="vertical" :disabled="!isUsingCustomDescription" label="Description manuelle" v-model="advertisement.customDescription" />
                </div>
                <p>Liste des variables disponibles : {descriptif_commercial_terrain}, {descriptif_commercial_lotissement}, {telephone-1_OX}<template v-if="advertisement.version">, {description_version}, {nombre_chambres}</template> </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import advertisementApi from '@/services/api/advertisement';
import versionApi from '@/services/api/version';
import landApi from '@/services/api/land';
import authApi from '@/services/auth';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import returnArrow from '@/assets/img/return-arrow.svg?inline';
import utils from '@/services/utils/utils';

import AddingVersionsBody from './AddingVersionsBody.vue';
import AddingLandsBody from './AddingLandsBody.vue';
import AppTextarea from '../../basics/components/AppTextarea.vue';

export default {
  name: 'advertisement',
  components: {
    Breadcrumb,
    returnArrow,
    AddingVersionsBody,
    AddingLandsBody,
    AppTextarea,
  },
  metaInfo() {
    return {
      title: this.advertisement.reference ? `${this.advertisement.reference} – Annonce` : 'Annonce',
    };
  },
  data() {
    return {
      loading: false,
      advertisement: {
        reference: '',
        version: null,
        land: null,
        isPublishable: false,
        customDescription: null,
      },
      isUsingCustomDescription: false,
      land: null,
      landId: null,
      utils,
      addingVersions: false,
      addingLands: false,
      isLoading: true,
      templates: {
        landDescription: null,
        landVersionDescription: null,
      },
    };
  },
  computed: {
    versionsId() {
      return this.advertisement.version ? [this.advertisement.version.versionId] : [];
    },
    landsId() {
      return this.advertisement.land ? [this.advertisement.land.landId] : [];
    },
    title() {
      if (this.addingVersions) {
        return `${this.advertisement.reference} - Ajout de la version`;
      }
      if (this.addingLands) {
        return `${this.advertisement.reference} - Ajout du terrain`;
      }
      if (!this.advertisement.advertisementId) {
        return 'Nouvelle annonce';
      }
      return this.advertisement.reference;
    },
    automaticDescriptionLand() {
      let description = this.templates.landDescription;
      if (this.advertisement.land) {
        description = description.replace(/{descriptif_commercial_terrain}/g, this.advertisement.land.commercialDescription || '');
        if (this.advertisement.land.allotment) {
          description = description.replace(/{descriptif_commercial_lotissement}/g, this.advertisement.land.allotment.commercialDescription || '');
        }
      }
      return description;
    },
    automaticDescriptionLandVersion() {
      let description = this.templates.landVersionDescription;
      if (this.advertisement.land) {
        description = description.replace(/{descriptif_commercial_terrain}/g, this.advertisement.land.commercialDescription || '');
        if (this.advertisement.land.allotment) {
          description = description.replace(/{descriptif_commercial_lotissement}/g, this.advertisement.land.allotment.commercialDescription || '');
        }
      }
      if (this.advertisement.version) {
        description = description.replace(/{nombre_chambres}/g, this.advertisement.version.numberOfRooms || 0);
        description = description.replace(/{description_version}/g, this.advertisement.version.description || '');
      }
      return description;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await Promise.all([
        this.getAdvertisement(),
        this.getTemplates(),
      ]);
      await Promise.all([
        this.getLand(),
        this.getVersion(),
      ]);
      this.isLoading = false;
    },
    async getAdvertisement() {
      try {
        if (this.$route.params.advertisementId) {
          this.advertisement = await advertisementApi.getById(this.$route.params.advertisementId);
          this.isUsingCustomDescription = !!this.advertisement.customDescription;
        }
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des annonces',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLand() {
      try {
        if (this.advertisement && this.advertisement.land && this.advertisement.land.landId) {
          this.advertisement.land = await landApi.getOne(this.advertisement.land.landId);
        }
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des terrains',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getVersion() {
      try {
        if (this.advertisement && this.advertisement.version && this.advertisement.version.versionId) {
          this.advertisement.version = await versionApi.getById(this.advertisement.version.versionId);
        }
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des versions',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getTemplates() {
      try {
        const brand = await authApi.getBrand();
        this.templates.landDescription = brand.templateLandDescription;
        this.templates.landVersionDescription = brand.templateLandVersionDescription;
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du descriptif automatique',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      if (this.advertisement.advertisementId) {
        await this.saveUpdates();
      } else {
        await this.createAdvertisement();
      }
    },
    async saveUpdates() {
      this.loading = true;
      try {
        if (
          this.advertisement.version
          && this.advertisement.version.versionId
          && this.advertisement.land
          && this.advertisement.land.landId
        ) {
          await advertisementApi.update(this.$route.params.advertisementId, {
            versionId: this.advertisement.version.versionId,
            landId: this.advertisement.land.landId,
            isPublishable: this.advertisement.isPublishable,
            customDescription: this.isUsingCustomDescription ? this.advertisement.customDescription : null,
          });
          await this.getData();
          this.$notification.show({ text: 'Annonce mis à jour avec succès' });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'La version, et le terrain sont obligatoires',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour de l\'annonce',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.loading = false;
    },
    async createAdvertisement() {
      this.loading = true;
      try {
        if (
          this.advertisement.version
          && this.advertisement.version.versionId
          && this.advertisement.land
          && this.advertisement.land.landId
        ) {
          const response = await advertisementApi.create({
            versionId: this.advertisement.version.versionId,
            landId: this.advertisement.land.landId,
            isPublishable: this.advertisement.isPublishable,
            customDescription: this.isUsingCustomDescription ? this.advertisement.customDescription : null,
          });
          await this.$router.push({ name: 'diffusions-advertisements-advertisementId', params: { advertisementId: response.advertisementId } });

          await this.getData();
          this.$notification.show({ text: 'Annonce créé avec succès' });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'La version, et le terrain sont obligatoires',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la création du annonce',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.loading = false;
    },

    // Suppression de l'annonce
    async deleteAdvertisement() {
      if (this.$route.params.advertisementId) {
        this.loading = true;

        try {
          await advertisementApi.deleteOne(this.$route.params.advertisementId);

          this.$notification.show({ text: 'Cette annonce a été archivé avec succès !' });
          this.$router.push({ name: 'diffusions-advertisements' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de l\'archivage de cette annonce',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }

        this.loading = false;
      }
    },
    goToVersion(selectedVersion) {
      this.$router.push({ name: 'versions-versionId-summary', params: { versionId: selectedVersion.versionId } });
    },
    goToLand(selectedLand) {
      this.$router.push({ name: 'lands-landId', params: { landId: selectedLand.landId } });
    },
    removeVersion() {
      this.advertisement.version = null;
    },
    removeLand() {
      this.advertisement.land = null;
    },
    async addVersion(version) {
      this.advertisement.version = version;
      this.isLoading = true;
      await this.getVersion();
      this.isLoading = false;
      this.addingVersions = false;
    },
    async addLand(land) {
      this.advertisement.land = land;
      this.isLoading = true;
      await this.getLand();
      this.isLoading = false;
      this.addingLands = false;
      this.$router.push({
        query: {
          landId: land.landId,
        },
      });
    },
    // Demande de confirmation pour la suppression de la version
    openDeleteModal() {
      this.$message.show({
        title: 'Archiver cette annonce',
        text: 'Êtes vous sûr de vouloir archiver cette annonce?',
        confirmText: 'Archiver l\'annnonce',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteAdvertisement();
        },
      });
    },
  },
};
</script>

<style lang='sass' scoped>
.advertisement
  .breadcrumbs
    @include breadcrumbs
  .header
    @include header
    .app-button
      margin-left: 16px
  .body
    @include screen
    .sub-section
      margin-bottom: 2rem
    h3
      margin-bottom: 0.5rem
    .app-table
      margin-top: 0.5rem
  .card
    @include card
    padding: 16px 24px
    h3
      margin-left: 0
    img
      width: 200px
      margin-left: -16px
    strong
      color: $primary
      cursor: pointer
      display: block
      margin-top: 10px
    .cta-container
      display: flex
      margin-top: 16px
      gap: 16px
      flex-wrap: wrap
    .description-input-container
      display: flex
      justify-content: space-between
      gap: 16px
      align-items: stretch
      .app-textarea
        min-height: 40rem
        resize: vertical
        .textarea
          resize: vertical

  .radio-container
    padding-bottom: 16px
    .cell:first-child
      padding-right: 20px
  label
    margin-bottom: 16px
  .price
    color: $primary
  img
    border-radius: 4px
  .image
    object-fit: cover
    width: 130px
    height: 90px
    border: 1px solid $line
    border-radius: 8px
  .not-found
    @include centered-container
    svg
      width: 50px
  .back-button
    svg
      width: 13px
      margin-right: 10px
      path
        stroke: currentcolor
</style>
