import axios from 'axios';
import dayjs from 'dayjs';

const { VUE_APP_API_URL } = process.env;

const getByBuildFilter = async (buildFilter) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/build-filters/${buildFilter}/build-steps`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const create = async (buildStep) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/build-steps`, buildStep);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const edit = async (buildStep) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/build-steps/${buildStep.buildStepId}`, buildStep);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const add = async (buildStep, buildSteps) => {
  /* Décale toutes les tâches non-commencées, à partir d'une date */
  const filteredSteps = buildSteps.filter((step) => step.rank >= buildStep.rank);

  const allPromises = [];

  if (filteredSteps && filteredSteps.length > 0) {
    filteredSteps.forEach((step) => {
      const newbuildStep = step;
      newbuildStep.rank = step.rank + 1;

      /* Envoyer la mise à jour au serveur */
      allPromises.push(edit(newbuildStep));
    });
  }
  await Promise.all(allPromises);

  try {
    const response = await axios.post(`${VUE_APP_API_URL}/build-steps`, buildStep);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const delayFollowingBuildSteps = (startingStep, buildSteps, numberOfWeeks) => {
  /* Décale toutes les tâches non-commencées, à partir d'une date */
  const filteredSteps = buildSteps.filter((step) => step.rank > startingStep.rank);

  const allPromises = [];

  if (filteredSteps && filteredSteps.length > 0) {
    filteredSteps.forEach((step) => {
      const newBuildStep = {
        buildStepId: step.buildStepId,
        buildFilterId: step.buildFilterId,
        rank: step.rank,
        label: step.label,
        type: step.type,
        startedAt: dayjs(step.startedAt).add(numberOfWeeks, 'week').format(),
        closedAt: dayjs(step.closedAt).add(numberOfWeeks, 'week').format(),
      };

      /* Envoyer la mise à jour au serveur */
      allPromises.push(edit(newBuildStep));
    });
  }
  return Promise.all(allPromises);
};

const deleteOne = async (buildStepId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/build-steps/${buildStepId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const buildSteps = {};
buildSteps.getByBuildFilter = getByBuildFilter;
buildSteps.create = create;
buildSteps.add = add;
buildSteps.edit = edit;
buildSteps.delayFollowingBuildSteps = delayFollowingBuildSteps;
buildSteps.delete = deleteOne;

export default buildSteps;
