<template>
  <div class="projects-progress hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Liste des chantiers</h1>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- BODY / LISTE DES CHANTIERS -->
    <div class="body">
      <div class="search grid-x">
        <!-- RECHERCHE -->
        <div class="cell small-3">
          <app-search
            v-model="search"
            placeholder="Recherche par référence, client"
            size="auto"
          />
        </div>
        <!-- FILTER MODAL -->
        <div class="cell shrink button">
          <filter-modal @change="filterProjects" @reset="resetFilers" @close="closeFilterModal" :numberOfInputs="Object.values(filters).filter(i => !!i).length">
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-radio-button
                  label="Trier par :"
                  v-model="filters.order"
                  :input="filters.order"
                  :options="[
                    { name: 'startedStepDate', label: 'Date démarrage' },
                    { name: 'alphabetic', label: 'Nom client' },
                    { name: 'reference', label: 'N° dossier'},
                    { name: 'delay', label: 'Retard'},
                  ]"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Agence"
                  v-model="filters.agencyId"
                  :options="agencyOptions"
                  :disabled="agencyOptions.length === 0"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Conducteur"
                  v-model="filters.conductorId"
                  :options="conductorOptions"
                  :disabled="conductorOptions.length === 0"
                />
              </div>
            </div>
          </filter-modal>
        </div>
        <div class="cell auto"></div>
      </div>
      <!-- LISTE DES CHANTIERS -->
      <app-table :headers="tableHeaders" :data="projects.data" :loading="isLoading" @line-cliked="goToProject" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="customerFile" slot-scope="{ data }">
          <p>
            <strong>{{ data.reference }}</strong> {{ data.customer.lastname }} <br />
            <small>{{ data.agency.name }} - {{ data.member.firstname }} {{ data.member.lastname }}</small>
          </p>
        </template>
        <template slot="buildingSite" slot-scope="{ data }">
          <p>
            <strong>{{ data.address.postalCode }} {{ data.address.city }}</strong> <br />
            <small>{{ data.buildFilterLabel }}</small>
          </p>
        </template>
        <template slot="contractSignatureDate" slot-scope="{ data }">
          <p class="open-close-date-table" v-if="data.contractSignatureDate">
            <strong>S{{ $dayjs(data.contractSignatureDate).isoWeek() }}</strong>
            <small>{{ $dayjs(data.contractSignatureDate).format('DD/MM/YYYY') }}</small>
          </p>
        </template>
        <template slot="duration" slot-scope="{ data }">
          <p>{{ data.duration }} J</p>
          <progress class="progress-bar" max="100" :value="getTimeSpent(data.startedStepDate) / data.duration * 100"></progress>
        </template>
        <template slot="objective" slot-scope="{ data }">
          <p class="objective">
            <span>{{ data.initialDuration }} J</span>
          </p>
        </template>
        <template slot="delay" slot-scope="{ data }">
          <p>{{ data.delay }} J</p>
        </template>
        <template slot="ending" slot-scope="{ data }">
          <p class="open-close-date-table">
            <strong>S{{ $dayjs(data.startedStepDate).isoWeek() }}</strong>
            <small>{{ $dayjs(data.closedStepDate).format('DD/MM/YYYY') }}</small>
          </p>
        </template>
      </app-table>
      <app-pagination
        v-if="projects.metadata"
        :limit="limit"
        :offset="projects.metadata.offset"
        :count="projects.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import FilterModal from '@/components/general/FilterModal.vue';
import TabNav from '@/components/layouts/TabNav.vue';

import project from '@/services/api/project';
import agency from '@/services/api/agency';
import member from '@/services/api/member';

import utils from '@/services/utils/utils';

export default {
  name: 'projects-progress',
  components: {
    TabNav,
    FilterModal,
  },
  metaInfo: {
    title: 'Liste des chantiers',
  },
  data() {
    return {
      // New data
      tabs: [
        {
          route: {
            name: 'projects',
          },
          label: 'Tout',
        },
        {
          route: {
            name: 'projects-pending',
          },
          label: 'En attente',
        },
        {
          route: {
            name: 'projects-todo',
          },
          label: 'Prévision',
        },
        {
          route: {
            name: 'projects-in-progress',
          },
          label: 'En-cours',
        },
        {
          route: {
            name: 'projects-done',
          },
          label: 'Livrés',
        },
      ],
      tableHeaders: [
        { label: 'Dossier Clients', key: 'customerFile', size: 3 },
        { label: 'Lieu de construction', key: 'buildingSite', size: 3 },
        { label: 'Signature Contrat', key: 'contractSignatureDate', size: 1 },
        { label: 'Durée', key: 'duration', size: 2 },
        { label: 'Objectif', key: 'objective', size: 1 },
        { label: 'Retard', key: 'delay', size: 1 },
        { label: 'Fin', key: 'ending', size: 1 },
      ],
      projects: {
        data: null,
        metadata: null,
      },
      limit: 25,
      isLoading: true,
      search: null,
      conductors: [],
      conductorOptions: [],
      agencies: [],
      agencyOptions: [],
      filters: {
        order: null,
        agencyId: null,
        conductorId: null,
      },
      utils,
      me: null,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  async mounted() {
    this.search = this.$route.query.search || null;
    this.filters.order = this.$route.query.order || null;
    this.filters.agencyId = this.$route.query.agencyId || null;
    this.filters.conductorId = this.$route.query.modelId || null;

    await this.getData();
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    $route() {
      if (!this.isLoading) {
        this.getData();
      }
    },
    filters: {
      handler(value) {
        this.filters.agencyId = value.agencyId;
        this.filters.conductorId = value.conductorId;
        this.filters.order = value.order;
      },
      deep: true,
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getMe();
      await this.getAgencies();
      await this.getConductors();

      this.agencyOptions = utils.formatOptions(
        this.agencies,
        (option) => option.agencyId,
        (option) => option.name,
        'choose',
      );
      this.conductorOptions = utils.formatOptions(
        this.conductors,
        (option) => option.memberId,
        (option) => `${option.firstname} ${option.lastname}`,
        'choose',
      );

      await this.getProjects();
      this.isLoading = false;
    },
    async getAgencies() {
      try {
        this.agencies = await agency.getAll();
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMe() {
      try {
        this.me = await member.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getConductors() {
      try {
        const allConductors = await member.getAll();

        if (allConductors && allConductors.length > 0) {
          this.conductors = allConductors.sort((a, b) => a.lastname.localeCompare(b.lastname));
        } else {
          this.conductors = [];
        }
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des conducteurs',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getProjects() {
      try {
        this.projects = await project.getProjectsInProgress(
          this.$route.query.order || 'startedStepDate',
          this.limit,
          this.computeOffset,
          this.$route.query.search,
          this.$route.query.conductorId,
          this.$route.query.agencyId,
        );
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des chantiers en cours',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    filterProjects() {
      this.$router.push({
        query: {
          ...this.$route.query,
          order: this.filters.order || undefined,
          agencyId: this.filters.agencyId || undefined,
          conductorId: this.filters.conductorId || undefined,
          page: 1,
        },
      });
      this.isLoading = true;
      this.getProjects();
      this.isLoading = false;
    },
    closeFilterModal() {
      this.filters.order = this.$route.query.order || null;
      this.filters.agencyId = this.$route.query.agencyId || null;
      this.filters.conductorId = this.$route.query.conductorId || null;
    },
    async resetFilers() {
      this.filters = {
        order: null,
        agencyId: null,
        conductorId: null,
      };
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          order: this.filters.order || undefined,
          agencyId: this.filters.agencyId || undefined,
          conductorId: this.filters.conductorId || undefined,
        },
      });
      this.isLoading = true;
      this.getProjects();
      this.isLoading = false;
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
            page: 1,
          },
        });
      }
    },
    goToProject(selectedProject) {
      this.$router.push({
        name: 'projects-projectId',
        params: {
          projectId: selectedProject.projectId,
        },
      });
    },
    getDayjsObject(date) {
      return this.$dayjs(date).utc().startOf('day');
    },
    getTimeSpent(startedStepDate) {
      let timespent = 0;
      timespent = this.getDayjsObject(new Date()).utc().diff(this.getDayjsObject(startedStepDate), 'day') + 1;
      return timespent;
    },
  },
};
</script>

<style lang='sass' scoped>
.projects-progress
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
    .button
      margin-left: 1rem
  .objective
    display: flex
    flex-direction: column
    span
      margin-bottom: 0.5rem
  .open-close-date-table
    display: flex
    flex-direction: column
  small
    color: $info
  progress
    height: 4px
    border-radius: 0
  progress[value]::-webkit-progress-bar
    background-color: $line
    border-radius: $global-border-radius
  progress[value]::-webkit-progress-value
    background-color: $primary
    border-radius: $global-border-radius
</style>
