<template>
  <section class="hedifys-chantier build-steps">
    <div class="build-steps-header">
      <h1>Plan de construction</h1>
      <app-button theme="secondary" icon="remove" @click="close">Revenir aux gabarits</app-button>
    </div>
    <div class="loading" v-if="isLoading">
      <app-spinner />
    </div>
    <div v-else>
      <section v-if="buildSteps.length > 0" class="gantt">
        <perfect-scrollbar @scroll="handleScrollX">
          <div class="gantt-container" :style="{ width: `${(days) * dayWidth}px` }">
            <div class="gantt-header" :style="{ width: `${(days) * dayWidth}px`, transform: `translateX(${stickyOffsetLeft})`, willChange: 'transform'}">
              <div>
                <span class="week" :style="{ width: `${($dayjs(startDate).endOf('isoWeek').diff($dayjs(startDate),'day') + 1) * dayWidth}px` }">
                  S{{ $dayjs(startDate).startOf('isoWeek').isoWeek() }}
                </span>
                <span class="week" v-for="w in weeks" :key="`week${w}`" :style="{ width: `${dayWidth * 7}px` }">
                  S{{ $dayjs(startDate).add(w, 'week').isoWeek() }}
                </span>
                <span class="week" :style="{ width: `${($dayjs(endDate).diff($dayjs(endDate).startOf('isoWeek'),'day') + 1)* dayWidth}px` }">
                  S{{ $dayjs(endDate).startOf('isoWeek').isoWeek() }}
                </span>
              </div>
              <div>
                <span class="day" v-for="d in days" :key="`day${d}`" :style="{ width: `${dayWidth}px` }" :class="[{ weekend: [6,0].includes($dayjs(startDate).add(d - 1, 'day').day())}, { today: $dayjs().startOf('day').diff($dayjs(startDate).add(d - 1, 'day'), 'day') === 0 }]">
                  {{ $dayjs(startDate).add(d - 1, 'day').format('DD') }}
                </span>
              </div>
            </div>
            <div
              v-if="me"
              class="tasks" :style="{
              background: `repeating-linear-gradient(
                90deg,
                white,
                white ${dayWidth - 1}px,
                #aaaaaa ${dayWidth}px
              )`}">
              <Build-step-gantt
                v-for="(buildStep, index) in buildSteps"
                :key="`build-step-gantt-${buildStep.buildStepId}`"
                v-model="buildSteps[index]"
                :start-date="startDate"
                :end-date="endDate"
                :day-width="dayWidth"
                :build-steps="buildSteps"
                :companies="companies"
                :companyCategories="companyCategories"
                :buildFilter="buildFilter"
                :disabled="me.isAgencyAdmin && !buildFilter.agencyId && !me.isBrandAdmin"
                @update="fetchBuildSteps"
              >
              </Build-step-gantt>
            </div>
          </div>
        </perfect-scrollbar>
      </section>

      <section class="empty" v-else>
        <button @click="createBuildStep">Commencer mon planning de construction</button>
      </section>

    </div>
  </section>
</template>

<script>
import buildStep from '@/services/api/buildStep';
import buildFilter from '@/services/api/buildFilter';
import companyCategory from '@/services/api/companyCategory';
import company from '@/services/api/company';
import member from '@/services/api/member';

import BuildStepGantt from '@/views/buildFilter/BuildStepGantt.vue';

export default {
  name: 'build-steps',
  components: {
    BuildStepGantt,
  },
  data() {
    return {
      errorForbidden: false,

      buildSteps: [],
      companyCategories: [],
      companies: [],
      buildFilters: [],

      buildFilter: null,

      isLoading: true,

      me: null,

      // Gantt
      dayWidth: 40,
      showPdfModal: false,
      isSticky: false,
      stickyOffsetLeft: 0,
      days: null,
      weeks: null,
      months: null,
      startDate: '2010-01-04T00:00:00.000Z',
      endDate: null,
    };
  },
  watch: {
    $route: 'fetchData',
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.fetchBuildFilter();
      await this.fetchCompanyCategories();
      await this.fetchCompanies();
      await this.fetchBuildSteps();
      await this.getMe();
      this.isLoading = false;
    },
    async fetchCompanyCategories() {
      const allCompanyCategories = await companyCategory.getAll();
      this.companyCategories = allCompanyCategories.sort((a, b) => a.name.localeCompare(b.name));
    },
    async fetchCompanies() {
      const allCompanies = (await company.getAll()).data;
      if (allCompanies && allCompanies.length > 0) {
        const alphaSorted = allCompanies.sort((a, b) => a.name.localeCompare(b.name));
        const onlyFavorite = alphaSorted.filter((c) => c.isFavorite);
        const noFavorite = alphaSorted.filter((c) => !c.isFavorite);

        this.companies = onlyFavorite.concat(noFavorite);
      } else {
        this.companies = [];
      }
    },
    async fetchBuildFilter() {
      this.buildFilter = await buildFilter.getById(this.$route.params.buildFilterId);
    },
    async getMe() {
      try {
        this.me = await member.getMe();
      } catch (error) {
        throw error;
      }
    },
    async fetchBuildSteps() {
      try {
        this.buildSteps = await buildStep.getByBuildFilter(this.$route.params.buildFilterId);
      } catch (er) {
        //
      }
      this.updateBoundaries();
    },
    updateBoundaries() {
      if (this.buildSteps && this.buildSteps.length > 0) {
        this.buildSteps.forEach((task) => {
          if (this.endDate < task.closedAt || !this.endDate) {
            this.endDate = task.closedAt;
          }
        });

        this.startDate = this.$dayjs(this.startDate).startOf('day');
        this.endDate = this.$dayjs(this.startDate).endOf('year').startOf('day');

        this.days = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'day') + 1;
        this.weeks = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'week');
        this.months = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'month') + 1;
        if (this.$dayjs(this.endDate).startOf('isoWeek').isoWeek() === this.$dayjs(this.startDate).add(this.weeks, 'week').isoWeek()) {
          this.weeks -= 1;
        }
      }
    },
    async createBuildStep() {
      await buildStep.create({
        buildFilterId: this.$route.params.buildFilterId,
        companyCategoryId: null,
        label: 'Nouvelle tâche',
        rank: 1,
        type: 'DEFAULT',
        startedAt: this.$dayjs(this.startDate).add(14, 'day'),
        closedAt: this.$dayjs(this.startDate).add(19, 'day'),
      });

      this.fetchData();
    },
    close() {
      this.$router.push({ name: 'projects-build-filters' });
    },
    handleScrollX(ev) {
      this.stickyOffsetLeft = `-${ev.target.scrollLeft}px`;
    },
  },
};
</script>

<style lang="scss">
.build-steps {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 990;
  overflow-y: scroll;

  .empty {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      padding: 0.5rem 1rem;
      border: 0;
      font-size: 1rem;
    }
  }

  .build-steps-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 991;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 16px 56px;
    box-shadow: 0 2px 4px 0 $shadow;
  }

  .filters {
    margin: -0.4rem 1rem 0;
    width: 300px;
    font-size: 0.8rem;
  }
  button {
    background-color: $blue-light;
    padding: 4px 15px;
    color: white;
    border-radius: 4px;
  }

  .schedulegantt {
    position: relative;
    width: 100%;
    padding-bottom: 20px;
    overflow: scroll hidden;
    background-attachment: local;
    .tasks {
      display: block;
    }
  }

  .time {
    position: relative;
    border-bottom: 1px solid $gray-light;
    z-index: 200;
    .today {
      position: absolute;
      left: 300px;
      display: block;
      height: 100vh;
      width: 2px;
      background: orange;
      opacity: 0.3;
      z-index: 1;
    }
    .unit {
      display: inline-block;
      padding: 1rem 0;
      text-align: center;
      line-height: 1.1;
      color: #AAAAAA;
      small {
        font-size: 0.7rem;
      }
    }
  }

  .build-step {
    position: relative;
    min-height: 50px;
  }

  .gantt {
    width: 100%;
    .gantt-header {
      position: fixed;
      top: 80px;
      left: 0;
      z-index: 990;
      background-color: white;
      transition: transform ease-out 0.05s;
    }
    .ps {
      &::-webkit-scrollbar { // Cache la scrollbar mais garde la fontionnalité de scroll par glissement utile sur mobile
        display: none;
      }
      overflow-x: scroll !important;
      .ps__rail-x {
        position: fixed;
        top: 98vh;
        left: 0 !important;
        bottom: 0;
        height: 17px;
        opacity: 1;
        background-color: #E0E4ED;
        z-index: 1;
        .ps__thumb-x {
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #51ADF8;
        }
      }
    }
  }

  .gantt-container {
    padding-top: 142px;
    text-align: left;
    color: $gray-medium;
    background: white;

    .month, .week, .day {
      display: inline-block;
      padding: 0.5rem 0 0.3rem;
      text-align: center;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: bold;
      border-top: 1px solid $gray-light;
      border-right: 1px solid $gray-light;
      box-sizing: border-box;
    }
    .day {
      font-weight: normal;
      border-bottom: 1px solid $gray-light;
      background: white;
      &.weekend {
        background: $gray-white;
      }
      &.today {
        border: 2px solid $orange;
      }
    }
    .tasks {
      padding-top: 5px;
      min-height: 500px;
      box-shadow: inset 0 2px 4px rgba(0,0,0,0.4);
      padding-bottom: 250px;
    }
  }

  .loading {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>
