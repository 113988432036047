<template>
  <div class="uk-flex uk-flex-middle">
    <vk-modal
      class="pdf-modal"
      :show="true" center
      :style="{display: showModal ? 'block': 'none'}">
      <vk-modal-close @click.stop="" @click="closeModal"></vk-modal-close>
      <vk-modal-title @click.stop="" v-if="project || company">
        PLANNING {{ type === 'project' ? `${project.reference} - ${project.customer.lastname}` : '' }}
                {{ type === 'company' ? `${company.name}` : '' }}
        </vk-modal-title>
      <div v-if="project || company" @click.stop="">
        <div class="content">
          <vk-grid gutter="collapse" class="container uk-child-width-expand">
            <div class="column uk-width-2-5@m">
              <div v-if="type === 'project' && companies && companies.length > 0">
                <label>Types de tâches</label>
                <select class="uk-select" v-model="companyType">
                  <option :value="null">Tous les types</option>
                  <option value="SUBCONTRACTOR">{{ companyTypeList }}</option>
                </select>
              </div>
              <div v-if="type === 'company' && projects && projects.length > 0">
                <label>Projets:</label>
                <select class="uk-select" v-model="pdfProjectId" >
                  <option :value="null">Tous les projets</option>
                  <option :value="'memberProjects'">Tous mes projets</option>
                  <option :value="project.project ? project.project.projectId : project.projectId"
                    v-for="(project, index) in projects"
                    :key="project.project ? `${project.project.reference}-${index}` : `${project.reference}-${index}`">
                    {{ project.project ? project.project.reference : project.reference }}
                  </option>
                </select>
              </div>
            </div>
            <div class="column uk-width-1-5@m"></div>
          </vk-grid>
        </div>
        <div class="content">
          <strong class="title">Période (optionnelle)</strong>
        </div>
        <div class="content no-top-padding">
          <vk-grid gutter="collapse" class="container uk-child-width-expand">
            <div class="column uk-width-expand@m">
              <label>Début</label>
              <datepicker
                v-model="pdfStartDate"
                format="dd MMMM yyyy"
                :language="french"
                :disabledDates="{ days: [6,0] }" />
            </div>
            <div class="column uk-width-1-5@m"></div>
            <div class="column uk-width-expand@m">
              <label>Fin</label>
              <datepicker
                v-model="pdfEndDate"
                format="dd MMMM yyyy"
                :language="french"
                :disabledDates="{ days: [6,0] }" />
            </div>
          </vk-grid>
        </div>
        <div class="content" v-if="type === 'project' && companyType !== 'SUBCONTRACTOR' && companies && companies.length > 0">
          <strong class="title">Sélection des partenaires</strong>
        </div>
        <div class="content no-top-padding" v-if="type === 'project' && companyType !== 'SUBCONTRACTOR' && companies && companies.length > 0">
          <input
            class="uk-width-auto uk-checkbox uk-margin-small-right"
            type="checkbox"
            :value="'all'"
            :checked="companies && selectedCompanies && companies.length === selectedCompanies.length"
            @change="onSelectedCompaniesSelectAll"
          />
          <span>Tout sélectionner</span>
        </div>
        <div v-if="type === 'project' && companyType !== 'SUBCONTRACTOR'" class="company-selection-container">
          <div v-for="company in companies" :key="company.companyId" class="company-selection">
             <input
              class="uk-width-auto uk-checkbox uk-margin-small-right"
              type="checkbox"
              :value="company.companyId"
              :checked="selectedCompanies.includes(company.companyId)"
              @change="onSelectedCompaniesChanged"
            />
            <p>{{ company.name }}</p>
          </div>
        </div>
        <div @click.stop="">
          <vk-grid gutter="collapse" class="container uk-child-width-expand">
            <div class="column uk-width-expand@m"></div>
            <div class="column uk-width-auto@m">
              <vk-button @click="closeModal" class="secondary button">Annuler</vk-button>
            </div>
            <div class="column uk-width-auto@m">
              <vk-button @click="getHtmlLink" class="primary button">Générer le pdf</vk-button>
            </div>
          </vk-grid>
        </div>
      </div>
    </vk-modal>
  </div>
</template>

<script>
import { fr } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import types from '@/services/data/types.json';
import pdfApi from '@/services/api/pdf';

export default {
  name: 'pdf-creation-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      pdfCompanyId: null,
      pdfEndDate: null,
      pdfStartDate: null,
      pdfProjectId: null,
      onlyMember: null,
      french: fr,

      companyTypeList: types.companyCategoryType.SUBCONTRACTOR,
      companyType: null,

      selectedCompanies: [],
    };
  },
  props: ['showModal', 'companies', 'company', 'type', 'project', 'projects'],
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    this.closeModal();
  },
  methods: {
    onSelectedCompaniesChanged(event) {
      const index = this.selectedCompanies.indexOf(event.target.value);
      if (index === -1) {
        this.selectedCompanies.push(event.target.value);
      } else {
        this.selectedCompanies.splice(index, 1);
      }
    },
    onSelectedCompaniesSelectAll() {
      if (this.companies && this.selectedCompanies.length !== this.companies.length) {
        this.selectedCompanies = this.companies.map((c) => c.companyId);
      } else {
        this.selectedCompanies = [];
      }
    },
    closeModal(event) {
      if (this.showModal && event && !this.$el.contains(event.target)) {
        this.$emit('close');
      }
    },
    getData() {
      if (this.showModal) {
        this.pdfProjectId = this.project ? this.project.projectId : null;
        this.pdfCompanyId = this.company ? this.company.companyId : null;

        this.pdfStartDate = null;
        this.pdfEndDate = null;
      }
    },
    async getHtmlLink() {
      let path = null;

      if (this.type === 'company') {
        // generation du lien de la page avec le contenu du pdf
        const onlyMember = this.pdfProjectId === 'memberProjects' ? 'onlyMember=true' : null;
        const query = `${'?isHtml=true&'}${this.pdfProjectId && this.pdfProjectId !== 'memberProjects' ? `project=${this.pdfProjectId}&` : ''}${this.pdfStartDate ? `startDate=${this.$dayjs(this.pdfStartDate).unix()}&` : ''}${this.pdfEndDate
          ? `endDate=${this.$dayjs(this.pdfEndDate).unix()}&` : ''}${onlyMember ? 'onlyMember=true' : ''}`;

        path = `/pdf/partenaires/${this.pdfCompanyId}${query}`;
        await pdfApi.generateReports('fromCompanyProjectStepsPage', [this.pdfCompanyId], null, null, null, path);
      } else if (this.type === 'project') {
        let companiesQuery = '';
        // Si on veut tous les partenaires on ne filtre pas
        if (this.companies) {
          this.selectedCompanies.forEach((c) => {
            companiesQuery += `companies=${c}&`;
          });
        }
        // generation du lien de la page avec le contenu du pdf
        const query = `${'?isHtml=true&'}${companiesQuery}${this.pdfStartDate ? `startDate=${this.$dayjs(this.pdfStartDate).unix()}&` : ''}${this.pdfEndDate
          ? `endDate=${this.$dayjs(this.pdfEndDate).unix()}&` : ''}${this.companyType ? `companyType=${this.companyType}` : ''}`;

        path = `/pdf/chantiers/${this.project.projectId}${query}`;
      }
      setTimeout(() => {
        this.$router.push({ path });
      }, 200);
    },
  },
  watch: {
    showModal: 'getData',
    companyType(newValue) {
      /* Si le type sous-traitants est sélectionné alors on reset la valeur du filtre partenaire */
      if (newValue === 'SUBCONTRACTOR') {
        this.selectedCompanies = [];
        this.pdfCompanyId = null;
      }
    },
  },
};
</script>

<style lang="scss">
.pdf-modal {
  opacity: 1;
  .uk-modal-dialog {
    opacity: 1;
  }
  .no-top-padding {
    padding-top: 0px!important;
  }
  .link {
    cursor: pointer;
  }
  .company-selection-container {
    margin: 10px 20px;
    max-height: 200px;
    overflow-y: auto;
  }
  .company-selection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    border: 1px solid $gray-light;
    border-top: 0 solid;
  }
    input[type="checkbox"] {
      height: 20px !important;
      border-radius: $global-border-radius;
      width: 20px !important;
      background-size: auto!important;
    }
  .company-selection:first-child {
    border-top: 1px solid $gray-light;
    border-radius: $global-border-radius $global-border-radius 0 0;
  }
  .company-selection:last-child {
    border-bottom-right-radius: $global-border-radius;
    border-bottom-left-radius: $global-border-radius;
  }
 }
</style>
