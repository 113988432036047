<template>
  <div class="projects-done">
    <!-- HEADER / TITRE -->
    <div class="new-header grid-x">
      <div class="cell auto">
        <div>
          <h1>Liste des chantiers</h1>
        </div>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body" v-if="!isLoading">
      <section class="hedifys-chantier" id="projects-done" v-if="me">
        <!-- Filtres et Input de recherche -->
        <div class="header uk-width-1-1">
          <vk-grid class="filters uk-width-1-1" gutter="collapse">
            <span v-if="me.isBrandAdmin || me.isAgencyAdmin">FILTRER:</span>
            <div class="uk-width-1-5" v-if="(agencies && agencies.length > 0) && me.isBrandAdmin">
              <select class="uk-select" v-model="selectedAgency">
                <option :value="null">Toutes les agences</option>
                <option :value="agency.agencyId" v-for="(agency, index) in agencies" :key="`projects-done-agency-${index}`">{{ agency.name }}</option>
              </select>
            </div>
            <div class="uk-width-1-5" v-if="(conductors && conductors.length > 0) && (me.isBrandAdmin || me.isAgencyAdmin)">
              <select class="uk-select" v-model="selectedConductor">
                <option :value="null">Tous les conducteurs</option>
                <option :value="conductor.memberId" v-for="(conductor, index) in conductors" :key="`projects-done-conductor-${index}`">
                  {{ conductor.lastname }} {{ conductor.firstname }}
                </option>
              </select>
            </div>
            <div class="uk-width-expand"></div>
            <div class="uk-width-1-5 uk-text-right">
              <input placeholder="Rechercher" class="uk-input uk-width-auto@m" type="text" v-model="searchText" />
            </div>
          </vk-grid>
        </div>

        <!-- Projets -->
        <vk-grid class="projects" v-if="projects && projects.length > 0" gutter="collapse">
          <div class="header uk-width-1-1">
            <vk-grid class="uk-width-1-1 uk-child-width-expand" gutter="small">
              <div class="uk-width-1-4"><h6>Dossier Clients</h6></div>
              <div class="uk-width-1-5"><h6>Lieu de construction</h6></div>
              <div><h6>Ouverture</h6></div>
              <div class="uk-width-1-6"><h6>Durée</h6></div>
              <div><h6>Objectif</h6></div>
              <div><h6>Retard</h6></div>
              <div><h6>Fin</h6></div>
            </vk-grid>
          </div>
          <div @click="projectRead(project.projectId)" class="project uk-width-1-1" v-for="(project, index) in projects" :key="`projects-project-${index}`">
            <vk-grid class="uk-width-1-1 uk-child-width-expand uk-height-1-1" gutter="small">
              <div class="customer uk-width-1-4 uk-height-1-1">
                <span class="reference">{{ `${project.reference} ` }}</span>
                <span class="name">
                  <template v-if="project.customer.type === 'PERSON'">
                    {{ getCustomersNames(project.customer) }}
                  </template>
                  <template v-else>
                    {{ `${project.customer.companyName}` }}
                  </template>
                </span>
                <br>
                <span class="agency">{{ `${project.agency.name} - ` }}</span>
                <span class="conductor">{{ `${project.member.firstname}-${project.member.lastname}` }}</span>
              </div>
              <div class="address uk-width-1-5">
                <span>{{ `${project.address.postalCode} ${project.address.city}` }}</span>
                <br>
                <span class="buildfilter">{{ project.buildFilterLabel }}</span>
              </div>
              <div class="ouverture">
                <span>S{{ $dayjs(project.startedStepDate).isoWeek() }}</span>
                <span>{{ $dayjs(project.startedStepDate).format('DD.MM.YYYY') }}</span>
              </div>
              <div class="duration uk-width-1-6">{{ project.duration ? project.duration : '0' }}J</div>
              <div class="initial">{{ project.initialDuration ? project.initialDuration : '0' }}J</div>
              <div class="delay" :class="[project.delay <= 0 ? 'negative': '']">
                <p> {{ `${project.delay && project.delay > 0 ? '+' : ''}${project.status !== 'TODO' ? project.delay : '' }` }}</p>
              </div>
              <div class="end">
                <span>S{{ $dayjs(project.closedStepDate).isoWeek() }}</span>
                <span>{{ $dayjs(project.closedStepDate).format('DD.MM.YYYY') }}</span>
              </div>
            </vk-grid>
          </div>
        </vk-grid>

        <div class="nothing" v-else>
          <p>Aucun projet livré</p>
        </div>
        <div class="note">Les chantiers livrés sont conservés 18 mois avant leur suppression définitive.</div>
        <app-pagination
              v-if="projects"
              :limit="limit"
              :offset="computeOffset"
              :count="count"
              />
      </section>
    </div>
    <div class="body" v-else>
      <div class="spinner-container">
        <app-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import project from '@/services/api/project';
import agency from '@/services/api/agency';
import member from '@/services/api/member';
import customer from '@/services/api/customer';
import utils from '@/services/utils/utils';

export default {
  name: 'projects-done',
  components: {
    TabNav,
  },
  data() {
    return {
      // New data
      tabs: [
        {
          route: {
            name: 'projects',
          },
          label: 'Tout',
        },
        {
          route: {
            name: 'projects-pending',
          },
          label: 'En attente',
        },
        {
          route: {
            name: 'projects-todo',
          },
          label: 'Prévision',
        },
        {
          route: {
            name: 'projects-in-progress',
          },
          label: 'En-cours',
        },
        {
          route: {
            name: 'projects-done',
          },
          label: 'Livrés',
        },
      ],
      isLoading: true,
      count: null,
      limit: 25,
      me: null,
      projects: null,
      agencies: [],
      selectedAgency: null,
      conductors: [],
      selectedConductor: null,
      searchText: null,
    };
  },
  watch: {
    $route: {
      handler: 'getProjects',
      deep: true,
    },
    selectedAgency() {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, agencyId: this.selectedAgency, page: 1 } });
    },
    selectedConductor() {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, conductorId: this.selectedConductor, page: 1 } });
    },
    searchText() {
      this.debouncedUpdateSearchQuery();
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getProjects();
    await this.getAgencies();
    await this.getConductors();
    await this.getMe();
    this.isLoading = false;
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 750);
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return 0;
    },
  },
  methods: {
    getCustomersNames(selectedCustomer) {
      return customer.getCustomersNames(selectedCustomer);
    },
    async getProjects() {
      this.isLoading = true;
      const queries = {
        ...this.$route.query,
        limit: this.limit,
        offset: this.computeOffset,
      };
      try {
        const { data, metadata } = await project.getProjectsDone(queries);
        this.projects = data;
        this.count = metadata.count;
      } catch (error) {
        this.$displayNotification('L\'obtention des données des projets a échouée.');
      } finally {
        this.isLoading = false;
      }
    },
    async getAgencies() {
      try {
        const allAgencies = await agency.getAll();

        if (allAgencies && allAgencies.length > 0) {
          this.agencies = allAgencies.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.agencies = [];
        }
      } catch (error) {
        this.$displayNotification('L\'obtention des données des agences a échouée.');
      }
    },
    async getConductors() {
      try {
        const allConductors = await member.getAll();

        if (allConductors && allConductors.length > 0) {
          this.conductors = allConductors.sort((a, b) => a.lastname.localeCompare(b.lastname));
        } else {
          this.conductors = [];
        }
      } catch (error) {
        this.$displayNotification('L\'obtention des données des conducteurs a échouée.');
      }
    },
    async getMe() {
      try {
        this.me = await member.getMe();
      } catch (error) {
        //
      }
    },
    projectRead(id) {
      this.$router.push({ name: 'projects-projectId', params: { projectId: id } });
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.searchText) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.searchText || undefined,
          },
        });
      }
    },
  },
};
</script>

<!-- New style -->
<style lang='sass' scoped>
.projects-done
  .body
    @include screen
  .new-header
    @include header
    padding-bottom: 4px
  .spinner-container
    @include spinner-container
</style>

<style lang="scss">
#projects-done {
  > .header {
    position: relative;
    background-color: $gray-very-light;
    button {
      color: #ffffff;
      border-radius: 30px;
      border: none;
      background-color: $blue-medium;
    }
    .search {
      input {
        margin: 0 20px 0 0;
        border-radius: 30px;
        padding-left: 20px;
        width: auto;
      }
    }
    .filters {
      margin: 0 0 20px 0;
      align-items: center;
      > div {
        margin: 0 0 0 10px;
        select {
          border-radius: 5px;
        }
      }
    }
  }
  .note {
    font-size: 12px;
    margin-top: 4px;
    color: $black;
  }
  .projects {
    background-color: #ffffff;
    .header {
      position: relative;
      color: $gray-light;
      border-width: 0;
      border-left-width: 8px;
      border-bottom-width: 1px;
      border-style: solid;
      border-color: $gray-light;
      padding-left: 15px;
      > div {
        align-items: center;
      }
      > div > div {
        padding: 15px 20px;
      }
    }
    .project {
      position: relative;
      border-style: solid;
      border-width: 0;
      border-left-width: 8px;
      border-left-color: $blue-medium;
      cursor: pointer;
      padding-left: 15px;
      > div > div {
        padding: 15px 20px;
        height: 100%;
      }
      > div > div:not(.customer):not(.address):not(.ouverture):not(.end) {
        display: flex;
        align-items: center;
      }
      &:not(:first-child) {
        border-bottom-width: 1px;
        border-bottom-color: $gray-light;
      }
      > .uk-grid {
        align-items: center;
      }
      .customer {
        span.reference, span.name {
          font-weight: 500;
          text-transform: uppercase;
        }
        span.reference {
          color: $gray-dark;
        }
        span.name {
          color: $blue-medium;
        }
        span.agency {
          text-transform: uppercase;
        }
        span.agency, span.conductor {
          color: $gray-regular;
        }
      }
      .address {
        color: $gray-dark;
        font-weight: 500;
        .buildfilter {
          font-weight: 400;
          color: $gray-regular;
        }
      }
      .duration {
        color: #ffffff;
        background-color: $done;
      }
      .delay {
        font-weight: 500;
        color: $orange;
        &.negative {
          color: $green;
        }
      }
      .duration {
        justify-content: center;
      }
      .ouverture, .end {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $gray-regular;
      }
      .ouverture > span:first-child, .end > span:first-child {
        font-weight: 500;
        color: $gray-medium;
      }
      .ouverture, .duration, .initial, .delay, .end {
        border-color: $gray-light;
        border-width: 1px;
        border-style: solid;
        border-top: none;
        border-bottom: none;
        text-align: center;
        justify-content: center;
      }
      .duration, .initial, .delay, .end {
        border-right: 1px solid $gray-light;
        border-left: none;
      }
    }
  }
  .nothing {
    padding: 30px;
    background-color: #ffffff;
  }
}
</style>
