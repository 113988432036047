<template>
  <div class="ranges hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Gammes</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="isCreateModalOpen = true">Ajouter une gamme</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES GAMMES -->
    <div class="body">
      <app-table :headers="tableHeaders" :data="ranges" :loading="isLoading" @line-cliked="openUpdateModal" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY')  }}</p>
        </template>
      </app-table>
      <app-modal class="create-modal" :show="isCreateModalOpen" size="medium" title="Nouvelle gamme" @update:show="closeCreateModal">
        <form ref="range" @submit.prevent="createRange">
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-input v-model="range.name" label="Nom de la gamme" placeholder="Nom de la gamme" required />
            </div>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="cell auto"></div>
            <div class="cell shrink">
              <app-button theme="primary" size="large" icon="add" type="submit">Créer la gamme</app-button>
            </div>
            <div class="cell auto"></div>
          </div>
        </form>
      </app-modal>
      <app-modal class="update-modal" :show="isUpdateModalOpen" size="medium" title="Éditer une gamme" @update:show="closeUpdateModal">
        <form ref="range" @submit.prevent="updateRange">
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-input v-model="range.name" label="Nom de la gamme" placeholder="Nom de la gamme" required />
            </div>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="cell auto"></div>
            <div class="cell shrink">
              <app-button theme="secondary" size="large" icon="archive" type="button" @click="deleteRange">Archiver</app-button>
            </div>
            <div class="cell shrink">
              <app-button theme="primary" size="large" icon="edit" type="submit">Éditer la gamme</app-button>
            </div>
            <div class="cell auto"></div>
          </div>
        </form>
      </app-modal>
    </div>
  </div>
</template>

<script>
import range from '@/services/api/range';

export default {
  name: 'ranges',
  metaInfo: {
    title: 'Gammes',
  },
  data() {
    return {
      isLoading: true,
      ranges: null,
      selectedRange: null,
      range: {
        name: null,
      },
      isCreateModalOpen: null,
      isUpdateModalOpen: null,
      tableHeaders: [
        { label: 'Nom', key: 'name', size: 11 },
        { label: 'Créée le ', key: 'createdAt', size: 1 },
      ],
    };
  },
  mounted() {
    this.getRanges();
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getRanges();
      }
    },
  },
  methods: {
    async getRanges() {
      this.isLoading = true;
      try {
        this.ranges = await range.getAll(this.$route.query.search);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des gammes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async createRange() {
      try {
        await range.create(this.range);
        this.$notification.show({ text: 'Cette gamme a été ajoutée avec succès !' });

        await this.getRanges();
        this.closeCreateModal();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'ajout la gamme',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async updateRange() {
      try {
        await range.update(this.selectedRange, this.range);
        this.$notification.show({ text: 'Cette gamme a été mise à jour avec succès !' });

        await this.getRanges();
        this.selectedRange = null;
        this.closeUpdateModal();
        this.range.name = null;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'ajout la gamme',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async deleteRange() {
      this.$message.show({
        title: 'Archiver la gamme',
        text: 'Attention, si vous confirmez, vous ne pourrez plus accéder à cette gamme',
        confirmText: 'Archiver la gamme',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: async () => {
          this.isLoading = true;
          try {
            await range.delete(this.selectedRange);
            this.$notification.show({ text: 'Cette gamme a été archivée avec succès !' });

            await this.getRanges();
            this.selectedRange = null;
            this.isUpdateModalOpen = false;
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de l\'archivage de la gamme',
              cancelText: 'Ok',
              hasCancel: true,
            });
            this.isLoading = false;
          }
        },
      });
    },
    openUpdateModal(selectedRange) {
      this.range.name = selectedRange.name;
      this.selectedRange = selectedRange.rangeId;
      this.isUpdateModalOpen = true;
    },
    closeCreateModal() {
      if (this.range && this.range.name) this.range.name = null;
      this.isCreateModalOpen = false;
    },
    closeUpdateModal() {
      if (this.range && this.range.name) this.range.name = null;
      this.isUpdateModalOpen = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.ranges
  .body
    @include screen
  .header
    @include header
  .row
    @include row
</style>
