<template>
  <div class="app-datepicker">
    <label v-if="label" class="label" :class="{ mandatory: required, disabled: disabled }">{{ label }}</label>
    <datepicker
      class="datepicker"
      :class="size"
      :format="format"
      :value="input"
      @selected="onSelect"
      :clear-button="clearButton && !disabled"
      placeholder="Choisir une date..."
      :language="french"
      monday-first
      :required="required"
      :disabled-dates="disabledDates"
      :disabled="disabled"
      :minimum-view="minView"
    />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';

export default {
  components: {
    Datepicker,
  },
  props: {
    value: [String, Date],
    clearButton: {
      default: true,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: Object,
      required: false,
    },
    minView: {
      type: String,
      validator: (val) => ['day', 'month', 'year'].indexOf(val) !== -1,
      default: 'day',
    },
    size: {
      type: String,
      validator: (val) => ['small', 'medium', 'large'].indexOf(val) !== -1,
      default: 'medium',
    },
    format: {
      type: String,
      default: 'dd/MM/yyyy',
    },
  },
  data() {
    return {
      french: fr,
    };
  },
  mounted() {
    const input = this.$el.querySelector('input');
    // permet de valider l'input par soumission d'un formulaire
    input.removeAttribute('readonly');
    // empeche l'utilisateur de taper ou coller la date
    input.addEventListener('keydown', this.preventDefault);
  },
  computed: {
    input() {
      return this.value;
    },
  },
  methods: {
    onSelect(val) {
      this.$emit('input', val);
    },
    preventDefault(evt) {
      evt.preventDefault();
    },
  },
};
</script>

<style lang="sass">
.app-datepicker
  .label
    @include heading-xs
    color: $label
    padding-bottom: 4px
    &.mandatory:after
      content: "*"
      display: inline-block
      transform: translateX(0.3rem) translateY(-0.15rem)
      color: $label
    &.disabled
      color: $info
  .datepicker
    position: relative
    display: inline-block
    width: 100%
    &.small
      max-width: 200px
    &.medium
      max-width: 400px
    &.large
      max-width: 600px
    input
      width: 100%
      padding: 0.75rem 1rem 0.6rem
      background: white
      font-size: 12px
      font-weight: 400
      color: $body
      background-color: $off-white
      border-radius: $global-border-radius
      border: 1px solid $line
      transition: all 0.2s ease-in-out
      &:hover
        border-color: darken($light-color, 10%)
      &:focus
        outline: 0
        border-color: $primary
        box-shadow: 0 0 2px $primary
      &:disabled
        background-color: $background
        color: $label
        pointer-events: none
        cursor: not-allowed
        &::placeholder
          color: $label
      &::placeholder
        color: $info

    .vdp-datepicker__calendar
      border-radius: $global-border-radius
      header
        padding-top: 8px
      .cell
        border-radius: $global-border-radius
    .vdp-datepicker__calendar .cell.selected
      background: $primary
      color: white
    .vdp-datepicker__calendar .cell.selected:hover
      background: $primary!important
    .vdp-datepicker__calendar .cell:hover
      border-color: $primary!important
    .vdp-datepicker__clear-button, .vdp-datepicker__calendar-button
      position: absolute
      top: 50%
      right: 10px
      margin-top: -10px
</style>
