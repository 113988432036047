<template>
  <div class="project-step-gantt" ref="projectStepGantt">
    <span class="task" :class="[value.startStatus, value.closeStatus]"
      :style="{
        width: `${ boxWidth }px`,
        transform: `translate3d(${x}px,0, 0)`
      }">
      {{ $dayjs(value.closedAt).diff($dayjs(value.startedAt), 'day') + 1 }}J
      <div
        class="dropdown-project-step"
        v-if="value && value.type !== 'START' &&  value.type !== 'CLOSE'">
        <span class="label active" v-if="!disabled" @click="clickDropdown">
          ☰ {{ value.label }}
          <span v-if="(value.type === 'DEFAULT' || value.type === 'EVENT') && value.comment" class="comment" :class="{public: value.isPublicComment}">
            <img src="/edit.svg" alt="">
          </span>
        </span>
        <span class="label" v-else>
          {{ value.label }}
          <span v-if="(value.type === 'DEFAULT' || value.type === 'EVENT') && value.comment" class="comment" :class="{public: value.isPublicComment}">
            <img src="/edit.svg" alt="">
          </span>
        </span>
      </div>
      <span v-else class="label ">{{ value.label }}</span>
      <span class="company" :class="{ unconfirmed: !value.confirmed }">{{!value.company.isCompanyRegistered && value.company.name ? 'X-' : ''}}{{ value.company.name }}</span>
      <span class="company event" v-if="value.type === 'EVENT'">>>> Évènement</span>

      <span class="edit-start"
        v-if="!disabled" draggable="true"
        @dragstart="dragStartEdit"
        @drag="dragMoveEditStart"
        @dragend="dragEndEdit">
      </span>

      <span class="edit-move"
        v-if="!disabled"
        draggable="true"
        @dragstart="dragStartMove"
        @drag="dragMove"
        @dragend="dragEndMove">
      </span>
      <span class="edit-move disabled" v-else></span>

      <span class="edit-end"
        v-if="!disabled" draggable="true"
        @dragstart="dragStartEdit"
        @drag="dragMoveEditEnd"
        @dragend="dragEndEdit">
      </span>
    </span>
    <div v-if="showDropDown" class="gantt-actions"
      :style="{
        transform: `translate3d(${x + boxWidth}px,-15px, 0)`
      }">
      <DropdownActions
        :actions="dropdownContent"
        :initialValue="true"
        @dropdownClosed="showDropDown = false" />
      </div>
  </div>
</template>

<script>
import DropdownActions from '@/components/general/DropdownActions.vue';
import projectStep from '@/services/api/projectStep';
import project from '@/services/api/project';

// Hack pour utiliser le drag event sans shadow image
const pixel = new Image();
pixel.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==';

export default {
  components: {
    DropdownActions,
  },
  name: 'ProjectStepGantt',
  props: ['value', 'startDate', 'dayWidth', 'disabled', 'projectId', 'projectSteps', 'stepFocusedId'],
  data() {
    return {
      boxWidth: 0,

      x: 0, // position courante de la tâche
      x0: 0, // position initiale de la tâche
      clientX0: 0, // position initiale du curseur
      duration0: 0,
      showDropDown: false,
      dropdownContent: [],
      moving: false,

      currentValue: null,
    };
  },
  async created() {
    this.init();
  },
  mounted() {
    // Si la valeur de projectStepId est égale à la valeur de l'ID passé en props, alors on envoie au parent les informations de position du projectStep en question
    if (this.value.projectStepId === this.stepFocusedId) {
      this.$emit('getFocusedStepPosition', { positionX: this.clientX0, positionY: this.$refs.projectStepGantt.offsetTop, width: this.boxWidth });
    }
  },
  watch: {
    x: 'updateDate',
    boxWidth: 'updateDate',
    value: 'init',
    startDate: 'init',
  },
  methods: {
    setDropdown() {
      this.dropdownContent = [
        {
          title: 'Insérer une nouvelle tâche au-dessus',
          callback: this.addBefore,
        },
        {
          title: 'Insérer une nouvelle tâche en-dessous',
          callback: this.addAfter,
        },
        {
          title: 'Éditer la tâche',
          callback: () => this.openModal(null, 'edit'),
        },
        {
          title: 'Supprimer la tâche',
          callback: () => this.openModal(null, 'delete'),
        },
        {
          title: 'Décaler les tâches suivantes',
          callback: () => this.openModal(null, 'delay'),
        },
      ];

      if (this.value && this.value.company.companyId) {
        this.dropdownContent.push({
          title: 'Fiche partenaire',
          callback: this.showCompany,
        });
      }
    },
    init() {
      if (!this.moving) {
        this.currentValue = this.value;

        this.currentValue.startedAt = `${this.$dayjs(this.value.startedAt).format('YYYY-MM-DD')}T08:00:00.000Z`;
        this.currentValue.closedAt = `${this.$dayjs(this.value.closedAt).format('YYYY-MM-DD')}T18:00:00.000Z`;

        this.x = (this.$dayjs(this.value.startedAt).diff(this.$dayjs(this.startDate), 'day')) * this.dayWidth;
        this.duration0 = this.$dayjs(this.value.closedAt).diff(this.$dayjs(this.value.startedAt), 'day') + 1;
        this.boxWidth = this.duration0 * this.dayWidth;

        this.clientX0 = this.x;
        this.x0 = this.x;

        this.setDropdown();
      }
    },
    updateDate() {
      this.currentValue.startedAt = this.$dayjs(this.startDate).add((this.x / this.dayWidth), 'day').format('YYYY-MM-DD');
      this.currentValue.startedAt = `${this.value.startedAt}T08:00:00.000Z`;
      this.currentValue.closedAt = this.$dayjs(this.startDate).add(((this.x + this.boxWidth) / this.dayWidth) - 1, 'day').format('YYYY-MM-DD');
      this.currentValue.closedAt = `${this.value.closedAt}T18:00:00.000Z`;
    },

    // Move
    dragStartMove(e) {
      e.dataTransfer.setDragImage(pixel, 0, 0);
      if (!this.moving) {
        this.clientX0 = e.clientX;
        this.moving = true;
      }
    },
    dragMove(e) {
      if (e.clientX > 0) {
        this.x = (this.x0 + e.clientX) - this.clientX0;
      }
      if (e.clientX === 0) {
        this.moving = false; // Eviter le bug de glissement déclenché par la fonction init()
      }
    },
    dragEndMove() {
      this.x = Math.round(this.x / this.dayWidth) * this.dayWidth;
      this.x0 = this.x;
      this.duration0 = this.$dayjs(this.value.closedAt).diff(this.$dayjs(this.value.startedAt), 'day');
      this.moving = false;
      this.saveData();
    },

    // Edit
    dragStartEdit(e) {
      e.dataTransfer.setDragImage(pixel, 0, 0);
      this.clientX0 = e.clientX;
      this.duration0 = this.$dayjs(this.value.closedAt).diff(this.$dayjs(this.value.startedAt), 'day') + 1;
    },
    dragEndEdit() {
      this.x = Math.round(this.x / this.dayWidth) * this.dayWidth;
      this.boxWidth = Math.round(this.boxWidth / this.dayWidth) * this.dayWidth;
      this.x0 = this.x;
      this.saveData();
    },

    // Edit start
    dragMoveEditStart(e) {
      if (e.clientX > 0) {
        this.x = Math.min((this.x0 + e.clientX) - this.clientX0, this.x0 - (this.dayWidth * (1 - this.duration0)));
        const step = (this.x0 - this.x) / this.dayWidth;
        this.boxWidth = (this.duration0 * this.dayWidth) + (step * this.dayWidth);
      }
    },

    // Edit end
    dragMoveEditEnd(e) {
      if (e.clientX > 0) {
        const deltaX = e.clientX - this.clientX0;
        const step = (this.x0 - this.x) / this.dayWidth;
        this.boxWidth = Math.max((this.duration0 * this.dayWidth) + (step * this.dayWidth) + deltaX, this.dayWidth);
      }
    },
    // Data
    async saveData() {
      await projectStep.edit({
        projectStepId: this.value.projectStepId,
        projectId: this.value.project.projectId,
        companyId: this.selectedCompany,
        companyCategoryId: this.selectedCompanyCategory,
        label: this.value.label,
        rank: this.value.rank,
        startedAt: this.value.startedAt,
        closedAt: this.value.closedAt,
        status: this.value.status,
        type: this.value.type,
        confirmed: false,
      });
      this.$emit('updateBoundaries');
    },
    clickDropdown() {
      this.showDropDown = !this.showDropDown;
    },
    async openModal(event, type) {
      if (this.projectId) {
        this.project = await project.getById(this.projectId);
      }

      let selectedType = type;
      if (type === 'edit' && this.value.type === 'START') {
        selectedType = 'start';
      }
      /* A ajouter avec la modale de fermeture */
      if (type === 'edit' && this.value.type === 'CLOSE') {
        selectedType = 'close';
      }

      this.$emit('openModal', this.value, selectedType, null, this.project);
    },
    addBefore() {
      this.add();
    },
    addAfter() {
      this.add(this.value.rank + 1);
    },
    showCompany() {
      this.$router.push({ name: 'company-companyId-read', params: { companyId: this.value.company.companyId } });
    },
    async add(rank) {
      /* Faire les mises à jour des tâches */
      await projectStep.add(
        {
          projectId: this.value.project.projectId,
          label: 'Tâche vide',
          rank: !rank ? this.value.rank : rank,
          startedAt: this.value.closedAt,
          closedAt: this.$dayjs(this.value.closedAt).add(3, 'days'),
        },
        this.projectSteps,
      );
      this.showDropDown = false;
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss">
.project-step-gantt {
  position: relative;
  .dropdown-link {
    display: none;
  }
  .gantt {
    width: 100%;
    overflow-x: scroll;
  }

  .task {
    position: relative;
    display: block;
    margin: 10px 0;
    padding: 10px 0;
    height: 20px;
    background: #42495C;
    z-index: 0;
    color: white;
    text-align: center;
    font-weight: bold;

    &.TODO {
      background-color: $green!important;
    }
    &.LATE {
      background-color: $orange!important;
    }
    &.DONE {
      background-color: $gray-light;
    }
    &.disabled {
      background-color: $gray-regular;
    }
    .drag-zone {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: -50px;
      background: transparent;
    }

    &:hover {
      .edit-start, .edit-end {
        background: rgba(0,0,0,0.3);
      }
    }
    .dropdown-project-step .active {
      cursor: pointer;
    }
  }
    .label {
      position: absolute;
      left: 100%;
      top: -2px;
      margin-left: 15px;
      width: max-content;
      color: $gray-medium;
      font-weight: bold;
      text-align: left;
    }

      .comment {
        vertical-align: middle;
        position: relative;
        display: inline-flex;
        margin-left: 8px;
        justify-content: center;
        align-items: center;
        height: 15px;
        width: 15px;
        &.public::after {
          background-color: $blue-medium;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          height: inherit;
          width: inherit;
          border-radius: 100%;
          background-color: $gray-light;
          z-index: 0;
        }
        img {
          position: relative;
          height: 9px;
          z-index: 5;
          filter: brightness(3);
        }
      }

    .company {
      position: absolute;
      left: 100%;
      top: 20px;
      margin-left: 15px;
      width: 300px;
      color: $blue-medium;
      font-weight: bold;
      font-size: 0.8rem;
      text-align: left;
      text-transform: uppercase;
      &.unconfirmed {
        color: $orange;
      }
    }

    .event {
      color: #edc900;
    }

    .edit-start, .edit-end {
      position: absolute;
      top: 0;
      height: 40px;
      width: 5px;
      background: transparent;
      cursor: col-resize;
      border-radius: 5px;
      transition: background ease-in-out 0.2s;
    }
    .edit-start {
      left: 0;
    }
    .edit-end {
      right: 0
    }

  .edit-move {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 5px;
    right: 5px;

    &:hover, &.active {
      cursor: move;
      .start, .end {
        opacity: 1;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }

  .gantt-actions {
    position: absolute;
    z-index: 1;
  }
}
</style>
