<template>
  <div class='advertisements hedifys-21'>
    <!-- HEADER / TITRE -->
    <div class='header grid-x'>
      <div class='cell auto'>
        <h1>Annonces manuelles</h1>
      </div>
      <div class="cell shrink">
        <app-button
          advertisement="primary"
          size="large"
          icon="add"
          @click="goToAdvertisement"
        >
          Ajouter une annonce
        </app-button>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="body">
      <!-- CONTENT -->
      <div class="search grid-x">
        <!-- FILTER MODAL -->
        <div class="cell shrink">
          <filter-modal
            @change="filterAdvertisements"
            @reset="resetFilers"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-radio-button
                  label="Trier par"
                  name="isPublishable"
                  :value="$route.query.isPublishable"
                  v-model="filters.isPublishable"
                  :options="[
                    { name: 'true', label: 'Publiables' },
                    { name: 'false', label: 'Non publiables' }
                  ]"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Agence"
                  v-model="filters.agencyId"
                  :options="agencyOptions"
                  :disabled="agencyOptions.length === 0"
                />
              </div>
            </div>
          </filter-modal>
        </div>
      </div>

      <!-- TABLE -->
      <app-table
        :headers="tableHeader"
        :data="advertisements.data"
        :loading="isLoading"
        @line-cliked="goToAdvertisement"
        clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="reference" slot-scope="{ data }">
          <strong>{{ data.reference }}</strong>
        </template>
        <template slot="agency" slot-scope="{ data }">
          <p>{{ data.agency.name }}</p>
        </template>
        <template slot="land" slot-scope="{ data }">
          <p>Lot {{ data.land.lotNumber }}, {{ data.land.address.addressLine1 }}, {{ data.land.city }}</p>
        </template>
        <template slot="version" slot-scope="{ data }">
          <p>{{ data.version.name }}</p>
        </template>
        <template slot="isPublishable" slot-scope="{ data }">
          <p>{{ data.isPublishable ? "Oui" : "Non" }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY') }}</p>
        </template>
        <template slot="updatedAt" slot-scope="{ data }">
          <p>{{ $dayjs(data.updatedAt).format('DD/MM/YYYY') }}</p>
        </template>
        <template slot="empty-table">
          <p>Aucune donnée de disponible</p>
        </template>
      </app-table>
      <app-pagination
        v-if="advertisements.metadata"
        :limit="limit"
        :offset="advertisements.metadata.offset"
        :count="advertisements.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import advertisementApi from '@/services/api/advertisement';
import agencyApi from '@/services/api/agency';
import utils from '@/services/utils/utils';
import FilterModal from '@/components/general/FilterModal.vue';

export default {
  name: 'advertisements',

  components: {
    FilterModal,
  },

  metaInfo: {
    title: 'Annonces manuelles',
  },

  data() {
    return {
      // Table
      tableHeader: [
        { label: 'Référence', key: 'reference', size: 3 },
        { label: 'Agence', key: 'agency', size: 2 },
        { label: 'Terrain', key: 'land', size: 2 },
        { label: 'Version', key: 'version', size: 2 },
        { label: 'Publiable', key: 'isPublishable', size: 1 },
        { label: 'Créée le', key: 'createdAt', size: 1 },
        { label: 'Mise à jour le', key: 'updatedAt', size: 1 },
      ],
      isLoading: false,
      limit: 5,

      // Advertisements
      advertisements: [],

      // Agencies
      agencies: [],
      agencyOptions: [],

      // Filters
      filters: {
        isPublishable: null,
        agencyId: null,
      },
    };
  },

  mounted() {
    this.filters.isPublishable = this.$route.query.isPublishable || null;
    this.filters.agencyId = this.$route.query.agencyId || null;
    this.getData();
  },

  watch: {
    filters: {
      handler(value) {
        this.filters.isPublishable = value.isPublishable;
        this.filters.agencyId = value.agencyId;
      },
      deep: true,
    },
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getAdvertisements(this.computeOffset);
        this.isLoading = false;
      }
    },
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },

  methods: {
    async getData() {
      this.isLoading = true;
      await this.getAdvertisements();
      await this.getAgencies();
      this.agencyOptions = utils.formatOptions(
        this.agencies,
        (option) => option.agencyId,
        (option) => option.name,
        'choose',
      );
      this.isLoading = false;
    },

    // Récupération des annonces
    async getAdvertisements() {
      try {
        this.advertisements = await advertisementApi.getAll(
          this.limit,
          this.computeOffset,
          this.$route.query.isPublishable,
          this.$route.query.agencyId,
          'false',
        );
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des annonces',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des agences
    async getAgencies() {
      try {
        this.agencies = await agencyApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    filterAdvertisements() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      this.$router.push({
        query: {
          ...this.$route.query,
          isPublishable: this.filters.isPublishable || undefined,
          agencyId: this.filters.agencyId || undefined,
        },
      });

      // On récupère les annonces avec les nouvelles query
      this.getAdvertisements();
    },

    async resetFilers() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        isPublishable: null,
        agencyId: null,
      };

      // On supprime les query categoryId et lotId de l'URl
      this.$router.replace({
        query: {
          ...this.$route.query,
          isPublishable: undefined,
          agencyId: undefined,
        },
      });

      this.getAdvertisements();
    },

    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.isPublishable = this.$route.query.isPublishable || null;
      this.filters.agencyId = this.$route.query.agencyId || null;
    },
    goToAdvertisement(selectedAdvertisement) {
      if (selectedAdvertisement && selectedAdvertisement.advertisementId) {
        this.$router.push({ name: 'diffusions-advertisements-advertisementId', params: { advertisementId: selectedAdvertisement.advertisementId } });
      } else {
        this.$router.push({ name: 'diffusions-advertisements-new' });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.advertisements
  .body
    @include screen

  .header
    @include header

  .row
    @include row

  .search
    padding-bottom: 1rem
    display: flex
    justify-content: flex-end
    button
      margin-left: 1rem

  .filter-btn
    margin-left: 1rem

  .edit-advertisement
    .edit-advertisement-input-container
      margin-bottom: 1rem
      &:first-of-type, &:last-of-type
        margin-top: 1rem

    .edit-advertisement-input-submit-container
      margin-top: 3rem

    .edit-advertisement-label
      font-weight: 500
      margin-bottom: .5rem
      &:first-of-type
        margin-top: .5rem
</style>
