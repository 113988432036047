<template>
  <div class="advertisement-packs">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Packs de diffusion</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToAdvertisementPack">Créer un package</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES PACKS DE DIFFUSION -->
    <div class="body">
      <app-table
        :headers="tableHeaders"
        :data="advertisementPacks"
        :loading="loading"
        @line-cliked="goToAdvertisementPack"
        clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="versionsCount" slot-scope="{ data }">
          <p>{{ data.versionsCount || 0 }}</p>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import advertisementPackApi from '@/services/api/advertisementPack';

export default {
  name: 'advertisement-packs',
  metaInfo: {
    title: 'Packs de diffusion',
  },
  data() {
    return {
      loading: false,
      advertisementPacks: [],
      tableHeaders: [
        { label: 'Nom', key: 'name', size: 2 },
        { label: 'Versions', key: 'versionsCount', size: 'auto' },
      ],
    };
  },
  mounted() {
    this.getAdvertisementPacks();
  },
  methods: {
    async getAdvertisementPacks() {
      this.loading = true;
      try {
        const response = await advertisementPackApi.getAll();
        this.advertisementPacks = response;
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des packs de diffusion',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.loading = false;
    },
    goToAdvertisementPack(selectedAdvertisementPack) {
      if (selectedAdvertisementPack && selectedAdvertisementPack.advertisementPackId) {
        this.$router.push({ name: 'diffusions-advertisement-diffusion-packs-advertisementPackId', params: { advertisementPackId: selectedAdvertisementPack.advertisementPackId } });
      } else {
        this.$router.push({ name: 'diffusions-advertisements-diffusion-packs-new' });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.advertisement-packs
  .header
    @include header
  .body
    @include screen
  .row
    @include row
</style>
