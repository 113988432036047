<template>
  <div class="app-search" :class="size">
    <label>
      <span v-if="label" class="label">{{ label }}</span>
      <search class="search" />
      <input
        ref="search"
        type="text"
        :value="value"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="showOptions"
        @blur="blur"
        @input="$emit('input', $event.target.value)"
      />
      <cancel v-if="value && value.length" @click.prevent="clearSearch" class="cancel" />
    </label>
    <ul v-show="isOpen && options.length > 0">
      <app-scrollable>
        <li v-for="(option, key) in options" :key="key" @mousedown="selectOption($event, option)">
          <span>{{ option }}</span>
        </li>
      </app-scrollable>
    </ul>
  </div>
</template>

<script>
import search from '@/assets/img/search.svg?inline';
import cancel from '@/assets/img/cancel.svg?inline';

export default {
  name: 'app-search',
  components: {
    search,
    cancel,
  },
  data() {
    return {
      isOpen: false,
      selected: null,
    };
  },
  props: {
    value: String,
    options: {
      type: Array,
      default: () => [],
    },
    autocomplete: {
      default: 'off',
    },
    placeholder: {
      type: String,
      default: 'Text...',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'small', 'auto'].indexOf(val) !== -1,
      default: 'large',
    },
  },
  methods: {
    showOptions() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },
    blur() {
      this.showOptions();
      this.$emit('blur');
    },
    clearSearch() {
      this.selected = null;
      this.$emit('input', '');
      this.$refs.search.focus();
    },
    selectOption(event, option) {
      if (event.button === 0) {
        this.selected = option;
        this.$emit('input', this.selected);
      }
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
};
</script>

<style lang='sass' scoped>
.app-search
  display: flex
  flex-direction: column
  position: relative
  &.small
    width: 50% !important
    min-width: 240px !important
  &.large
    width: 100%
    max-width: 480px
  &.auto
    width: 100%
  label
    display: flex
    flex-direction: column
    position: relative
    width: 100%
  .label
    @include heading-xs
    color: $label
    padding-bottom: 4px
  .search
    position: absolute
    bottom: 12px
    left: 18px
    width: 14px
    height: 14px
  .cancel
    cursor: pointer
    position: absolute
    bottom: 14px
    right: 18px
    width: 10px
    height: 10px
  input
    @include paragraph
    display: flex
    padding: 10px 16px 10px 42px
    background-color: $off-white
    border: 1px solid $line
    border-radius: 4px
    color: $body
    margin: 0
    &:focus
      outline: none
      border: 1px solid $primary
      box-shadow: 0 0 2px $primary
    &:disabled
      background-color: $background
      color: $info
      pointer-events: none
      cursor: not-allowed
    &::placeholder
      color: $info
  ul
    position: absolute
    top: 100%
    left: 0
    margin-top: 4px
    padding: unset
    display: flex
    flex-direction: column
    background-color: $off-white
    color: $label
    box-shadow: $drop-med
    width: 100%
    max-height: 148px
    border-radius: 4px
    z-index: 999
  li
    cursor: pointer
    padding: 0.5rem 1rem
    &:hover
      background-color: rgba($primary, 0.1)
    span
      @include paragraph
</style>
