<template>
  <div class="agency-medias hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="agency && agency.name"
          :items="[
            { route: { name: 'agencies'}, name: 'Agences'},
            { route: { name: 'agencies-agencyId', params: { agencyId: $route.params.agencyId }}, name: agency.name}
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>{{ agency && agency.name ? agency.name : '' }}</h1>
      </div>
      <div class="cell shrink"></div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <div class="body">
      <!-- Affichage des medias triés par type -->
      <div v-for="mediaType in Object.values(medias)" :key="mediaType.type">
        <div class="row">
          <h2>{{ mediaType.label }}</h2>
          <p>Les photos seront affichées sur le site internet, page agence</p>
        </div>
        <div v-if="!mediaType.loading" class="grid-x row">
          <div class="cell shrink image-container" v-for="(media, index) in mediaType.data" :key="index">
            <app-upload-image
              v-model="media.url"
              @upload="(e) => upload(e, mediaType.type, media)"
              @remove="remove(media)"
            />
          </div>
          <div class="cell shrink image-container">
            <app-upload-image @upload="(e) => upload(e, mediaType.type)" />
          </div>
          <div class="cell auto"></div>
        </div>
        <div v-else>
          <div class="card spinner-container row">
            <app-spinner />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import agency from '@/services/api/agency';
import agencyMedia from '@/services/api/agencyMedia';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

export default {
  name: 'agency-medias',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.agency ? `${this.agency.name} – Agence` : 'Agence',
    };
  },
  data() {
    return {
      tabs: [
        {
          route: {
            name: 'agencies-agencyId',
            params: { agencyId: this.$route.params.agencyId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'agencies-agencyId-postal-codes',
            params: { agencyId: this.$route.params.agencyId },
          },
          label: 'Secteurs',
        },
        {
          route: {
            name: 'agencies-agencyId-medias',
            params: { agencyId: this.$route.params.agencyId },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'agencies-agencyId-members',
            params: { agencyId: this.$route.params.agencyId },
          },
          label: 'Membres',
        },
      ],
      agency: null,

      img: null,
      filename: null,
      agencyMediaId: null,

      medias: {
        OUTSIDE: {
          label: 'Vues extérieures',
          type: 'OUTSIDE',
          data: null,
          loading: false,
        },
        INSIDE: {
          label: 'Vues intérieures',
          type: 'INSIDE',
          data: null,
          loading: false,
        },
        MAP: {
          label: 'Plans',
          type: 'MAP',
          data: null,
          loading: false,
        },
      },
    };
  },
  async mounted() {
    Object.keys(this.medias).forEach((type) => { this.medias[type].loading = true; });
    await this.getAgency();
    await this.getMedias();
    Object.keys(this.medias).forEach((type) => { this.medias[type].loading = false; });
  },
  methods: {
    async getMedias() {
      try {
        if (this.$route.params.agencyId) {
          const medias = await agencyMedia.getAll(this.$route.params.agencyId);
          if (medias && medias.length) {
            Object.keys(this.medias).forEach((type) => { this.medias[type].data = medias.filter((m) => m.type === type); });
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des médias',
          confirmText: 'Ok',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgency() {
      try {
        if (this.$route.params.agencyId) {
          this.agency = await agency.getById(this.$route.params.agencyId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de l\' agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async upload(event, type, media) {
      this.medias[type].loading = true;

      try {
        const file = new FormData();
        file.append('file', event);
        file.append('type', type);

        if (media && media.agencyMediaId) {
          file.append('agencyMediaId', media.agencyMediaId);
        }

        const newMedia = await agencyMedia.upload(this.agency.agencyId, file);

        if (!this.medias[type].data) {
          this.medias[type].data = [];
        }
        this.medias[type].data.push(newMedia);

        this.getMedias();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'envoi du média',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.medias[type].loading = false;
    },
    async remove(media) {
      this.medias[media.type].loading = true;
      try {
        await agencyMedia.delete(this.agency.agencyId, media.agencyMediaId);

        const findIndex = this.medias[media.type].data.findIndex((m) => m.agencyMediaId === media.agencyMediaId);
        this.medias[media.type].data.splice(findIndex, 1);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression du média',
          cancelText: 'Ok',
          hasCancel: false,
        });
      }
      this.medias[media.type].loading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.agency-medias
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
    height: 54px
  .image-container
    margin-right: 24px
    margin-bottom: 15px
    max-width: 180px
    max-height: 110px
</style>
