import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère les logs
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/logs/`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère les pushes
 */
const getAllPushes = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/pushes/`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Push de prix
 * @param {push} push: les données du push
 */
const pushLotSurveyPrices = async (push) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/pushes/`, push);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const log = {};
log.getAll = getAll;
log.getAllPushes = getAllPushes;
log.pushLotSurveyPrices = pushLotSurveyPrices;

export default log;
