<template>
  <div id="pdf-header">
    <vk-grid gutter="collapse" class="uk-child-width-expand colored-header" v-if="agency || brand">
      <div>
        <img class="logo-home" :src="`${agency && agency.logo ? agency.logo : brand.logo}`" alt width="220">
      </div>
      <div class="right-col">
        <div class="agency-container" v-if="agency">
          <h2 class="title">{{ agency.name }}</h2>
          <p>
            {{ agency.phone }}
            <br>
            {{ agency.website }}
          </p>
        </div>
        <div class="agency-container" v-else>
          <h4 class="subtitle">{{this.project ? 'Dossier suivi par :' : 'Edité par:'}}</h4>
          <h4 class="title">{{ person.firstname }} {{ person.lastname }}</h4>
          <p class="h-color-gray-dark">{{ person.email }}<br>
          {{ person.phone }}</p>
        </div>
      </div>
    </vk-grid>
  </div>
</template>

<script>

export default {
  name: 'pdf-header',
  props: ['agency', 'project', 'member', 'brand'],
  data() {
    return {
      person: null,
    };
  },
  created() {
    /* Affiche les infos d'un membre, soit depuis un projet, soit avec directement le membre en param */
    this.person = this.project && this.project.member ? this.project.member : this.member;
  },
};
</script>
<style lang="scss">
#pdf-header {
  max-width: 1100px;
  margin: auto;
  .colored-header {
    background-color: $gray-deep!important;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    .right-col {
      padding: 10px 0px 10px 0px;
      display: flex;
      .agency-container {
        margin: auto;
        margin-right: 0;
        p, h2 {
          color: white!important;
          margin: 0;
        }
      }
    }
  }

  .right-col {
    text-align: right
  }
  .title {
    font-weight: 600;
    margin: 0px;
    color: $gray-dark;
  }
  p {
    margin-top: 5px;
  }
  .subtitle {
    color: $gray-regular!important;
    text-align: right;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  }
  .member {
    padding-right: 20px;
    padding-top: 20px;
  }
  .logo-home {
    margin: 20px 0 20px 0;
  }
}
</style>
