<template>
  <div :class="size" class="app-select">
    <label>
      <span v-if="label" class="label" :class="{ mandatory: required, disabled: disabled }">{{ label }}</span>
      <div
        role="listbox"
        class="select"
        :class="[{ disabled: disabled, focus: isOpen }, `value-${value}`]"
        @click="showOptions"
        :tabindex="tabIndex"
        @focusout="isOpen = false"
      >
        <slot v-if="options.find(o => o.name === value)">{{ options.find(o => o.name === value).label }}</slot>
        <slot v-else>Choisir</slot>
        <dropdown :class="{ disabled: disabled }" class="show-options" />
      </div>
    </label>
    <select :value="value" :required="required">
      <option v-for="option in options" :key="option.name" :value="option.name" :selected="value && option.name === value"/>
    </select>
    <transition name="app-select-list">
      <ul v-show="isOpen">
        <app-scrollable>
          <li role="option" v-for="option in options" :key="option.name" @mousedown="selectedOption($event, option)" :class="[{ 'selected': value === option.name }, `value-${option.name}`]" >
            <span>{{ option.label }}</span>
          </li>
        </app-scrollable>
      </ul>
    </transition>
  </div>
</template>

<script>
import dropdown from '@/assets/img/dropdown.svg?inline';

export default {
  name: 'app-select',
  components: {
    dropdown,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Array, Number, Boolean],
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
      default: 'large',
    },
  },
  mounted() {
    if (this.$props.options.find((o) => o.name === this.$props.value)) {
      this.selected = this.$props.value;
    }
  },
  methods: {
    showOptions(event) {
      if (event.button === 0) {
        if (!this.disabled) {
          this.isOpen = !this.isOpen;
        }
      }
    },
    selectedOption(event, option) {
      if (event.button === 0 && this.value !== option.name) {
        this.$emit('input', option.name);
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.app-select
  display: flex
  flex-direction: column
  position: relative
  &.small
    width: 50% !important
  &.large
    width: 100%
  label
    display: flex
    flex-direction: column
  .label
    @include heading-xs
    color: $label
    padding-bottom: 4px

    &.mandatory:after
      content: "*"
      transform: translateX(0.2rem) translateY(-0.15rem)
      color: $label
      display: inline-block
    &.disabled
      color: $info
  .show-options
    cursor: pointer
    position: absolute
    bottom: 12px
    right: 16px
    width: 14px
    height: 14px

  .hidden-input
    position: absolute
    width: 0
    height: 0
    padding: 0
    border: 0

  .select
    @include paragraph
    user-select: none
    cursor: pointer
    display: flex
    padding: 10px 16px
    background-color: $off-white
    border: 1px solid $line
    border-radius: 4px
    color: $body
    outline: none

    &.disabled
      background-color: $background
      color: $label
      pointer-events: none
      cursor: not-allowed
      .show-options
        cursor: not-allowed
  .focus
    outline: none
    border: 1px solid $primary
    box-shadow: 0 0 2px $primary
  ul
    position: absolute
    top: 100%
    left: 0
    margin-top: 4px
    padding: unset
    background-color: $off-white
    color: $label
    box-shadow: $drop-med
    width: 100%
    max-height: 148px
    border-radius: 4px
    z-index: 20
    display: flex
    flex-direction: column
    li
      cursor: pointer
      padding: 0.5rem 1rem
      &:hover
        background-color: rgba($primary, 0.1)
      span
        @include paragraph

  .selected
    background-color: rgba($primary, 0.1)

  select
    position: absolute
    width: 100%
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: -10

  .app-select-list-enter-active, .app-select-list-leave-active
    transition: transform .3s, opacity .3s, scale .3s ease-in-out

  .app-select-list-enter, .app-select-list-leave-to
    opacity: 0
</style>
