<template>
  <div class='lots hedifys-21'>
    <!-- HEADER / TITRE -->
    <div class='header grid-x'>
      <div class='cell auto'>
        <h1>Liste des lots</h1>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="body">
      <div class="search grid-x">
        <!-- FILTER MODAL -->
          <filter-modal
            @change="filterLots"
            @reset="resetFilers"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Catégorie"
                  v-model="filters.categoryId"
                  :options="categoryOptions"
                />
              </div>
            </div>
          </filter-modal>
      </div>

      <!-- TABLE -->
      <app-table :headers="tableHeader" :data="lots" :loading="isLoading" class="table" :clickable="true" @line-cliked="openEditionModal">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="image" slot-scope="{ data }">
          <img v-if="data.image" :src="data.image" alt="illustration" class="lot-illustration" />
          <div v-else class="lot-illustration not-found">
            <upload class="icon-upload" />
          </div>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="category" slot-scope="{ data }">
          <p>{{ getCategoryName(data.categoryId) }}</p>
        </template>
        <template slot="description" slot-scope="{ data }">
          <p class="description">{{ data.description }}</p>
        </template>
        <template slot="lotsSurveysNames" slot-scope="{ data }">
          <p class="text-uppercase">{{ data.lotsSurveysNames }}</p>
        </template>
        <template slot="empty-table">
          <p>Aucune donnée de disponible</p>
        </template>
      </app-table>

      <!-- Modale d'edition -->
      <app-modal v-if="editedLot" :show="isEditionModalOpen" @update:show="closeEditionModal()" size="medium" title="Modifier le lot" class="edition-modal">
        <div class="grid-container">
          <div class="image">
            <img v-if="editedLot.image" :src="editedLot.image" alt="illustration" class="lot-illustration" />
            <div v-else class="lot-illustration not-found">
              <upload class="icon-upload" />
            </div>
          </div>
          <div class="flex-container">
            <h3>{{ this.editedLot.name }}</h3>
            <p>{{ getCategoryName(this.editedLot.categoryId) }}</p>
            <p class="text-uppercase">{{ this.editedLot.lotsSurveysNames }}</p>
          </div>
        </div>
        <div class="description">
          <p>Description</p>
          <app-textarea v-model="editedLot.description" placeholder="Description..."/>
        </div>
        <app-button icon="edit" @click="updateLot(editedLot.lotId)">Enregistrer les modifications</app-button>
      </app-modal>
    </div>
  </div>
</template>

<script>
import lotApi from '@/services/api/lot';
import categoryApi from '@/services/api/category';
import utils from '@/services/utils/utils';
import FilterModal from '@/components/general/FilterModal.vue';

import upload from '@/assets/img/upload.svg?inline';

export default {
  name: 'lots',
  components: {
    FilterModal,
    upload,
  },
  metaInfo: {
    title: 'Liste des lots',
  },
  data() {
    return {
      // Table
      tableHeader: [
        { label: 'Visuel', key: 'image', size: 1 },
        { label: 'Thème', key: 'name', size: 2 },
        { label: 'Catégorie', key: 'category', size: 2 },
        { label: 'Description', key: 'description', size: 4 },
        { label: 'Lots métrés', key: 'lotsSurveysNames', size: 3 },
      ],
      isLoading: false,

      // Lots
      lots: [],
      editedLot: null,

      // Categories
      categories: [],

      // Search
      search: null,

      // Filters
      filters: {
        categoryId: null,
      },

      // Options
      categoryOptions: [],

      // Edition Modal
      isEditionModalOpen: false,
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },

  mounted() {
    this.search = this.$route.query.search;
    this.filters.categoryId = this.$route.query.categoryId || null;
    this.getData();
  },

  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },

    filters: {
      handler(value) {
        this.filters.categoryId = value.categoryId;
      },
      deep: true,
    },
  },

  methods: {
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });

        if (!this.isLoading) {
          this.getLots();
        }
      }
    },

    // Récupération des données les un après les autres
    async getData() {
      await this.getLots();
      await this.getCategories();
      this.categoryOptions = utils.formatOptions(
        this.categories,
        (option) => option.categoryId,
        (option) => option.name,
        'choose',
      );
    },

    // Récupération des lots
    async getLots() {
      this.isLoading = true;

      try {
        this.lots = await lotApi.getAll(this.$route.query.categoryId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.isLoading = false;
    },

    // Récupération des catégories
    async getCategories() {
      try {
        this.categories = await categoryApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async filterLots() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      this.$router.push({
        query: {
          ...this.$route.query,
          categoryId: this.filters.categoryId || undefined,
        },
      });

      // On récupère les thèmes avec les nouvelles query
      this.getLots();
    },

    async resetFilers() {
      // On reset les valeurs de l'objet filters
      this.filters = { categoryId: null };

      // On supprime les query categoryId et lotId de l'URl
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          categoryId: undefined,
        },
      });

      this.getLots();
    },

    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.categoryId = this.$route.query.categoryId || null;
      this.filters.lotId = this.$route.query.lotId || null;
    },

    // Récupère le nom de la catégorie
    getCategoryName(categoryId) {
      const category = this.categories.find((cat) => cat.categoryId === categoryId);

      if (category && category.name) {
        return category.name;
      }

      return null;
    },

    // Ouvre la modale
    openEditionModal(event) {
      this.editedLot = JSON.parse(JSON.stringify(this.lots)).find((lot) => lot.lotId === event.lotId);
      this.isEditionModalOpen = true;
    },

    // Ferme la modale
    closeEditionModal() {
      this.isEditionModalOpen = false;
      this.editedLot = null;
    },

    // Met à jour la description du lot et actualise la page
    async updateLot(lotId) {
      // Met à jour la description du lot
      try {
        await lotApi.updateLot(lotId, this.editedLot.description);
        // Ferme la modale
        this.closeEditionModal();
        this.$notification.show({ text: 'Lot mit à jour avec succès' });
        // Recharge la page
        await this.getLots();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour du lot.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.lots
  .body
    @include screen

  .header
    @include header

  .row
    @include row

  .search
    padding-bottom: 1rem
    justify-content: flex-end
    button
      margin-left: 1rem

  .table
    cursor: pointer

  .lot-illustration
    border: 1px solid $line
    border-radius: 8px
  .not-found
    padding: 15px
  .text-uppercase
    text-transform: uppercase
  .description
    white-space: pre-wrap

  .edition-modal
    .image
      width: 86px
      height: same-as-width

    .grid-container
      padding: 0
      display: grid
      grid-template-columns: 100px 1fr
      grid-gap: 30px
      margin: 40px 0

    .flex-container
      display: flex
      flex-direction: column

    p
      color: $secondary-light

    .description
      margin: 40px 0

      .app-textarea
        height: 150px

        textarea
          height: 150px

    .app-button
      margin: auto
</style>
