const companyCategoryTypeLabel = Object.freeze({
  SUPPLIER: 'Fournisseur',
  SUBCONTRACTOR: 'Sous-traitant',
  PARTNER: 'Partenaire',
  INSTITUTION: 'Institution',
  ESTATE: 'Biens',
  DEVELOPER: 'Développeur',
});

export default companyCategoryTypeLabel;
