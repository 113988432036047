<!-- WORK IN PROGRESS -->
<template>
  <div class="project-header">
    <vk-grid gutter="collapse" class="container uk-child-width-expand" v-if="project">
      <div class="column uk-width-auto@m" :class="project.status.toLowerCase()">
        <p class="label">Statut</p>
        <div class="status col" :class="project.status.toLowerCase()">
          <p >{{ statuses[project.status] }}</p>
        </div>
      </div>
      <div class="column uk-width-auto@m time-point" v-if="project.contractSignatureDate">
        <p class="label">Contrat</p>
        <div class="col">
          <div class="contract-start">
            <div class="week-date">
              <p class="uk-margin-remove">
                <strong class="week">S{{ $dayjs(project.contractSignatureDate).isoWeek() }}</strong>
              </p>
              <p class="uk-margin-remove timeline-date">
                {{ formatDate($dayjs(project.contractSignatureDate)) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column uk-width-auto@m" v-if="project.contractSignatureDate">
        <p class="label"></p>
        <div class="col">
          <div class="contract-start-duration">
            <p>{{ $dayjs(project.startedStepDate).diff($dayjs(project.contractSignatureDate), 'days') }} J</p>
          </div>
        </div>
      </div>
      <div class="column uk-width-auto@m time-point" :class="{ 'label-two-lines': project.status === 'TODO' }">
        <p class="label">{{ project.status === 'TODO' ? 'Prévision OC' : 'Début' }}</p>
        <div class="col">
          <div v-if="project.startedStepDate" class="start-date" >
            <div class="week-date">
              <p class="uk-margin-remove">
                <strong class="week">S{{ $dayjs(project.startedStepDate).isoWeek() }}</strong>
              </p>
              <p class="uk-margin-remove timeline-date">
                {{ formatDate($dayjs(project.startedStepDate)) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column uk-width-auto@m delay-container" v-if="project.status === 'TODO' ">
        <p class="label">Retard</p>
        <div class="col">
          <div class="delay">
            <p> {{ `${project.delay && project.delay > 0 ? '+' : ''}${project.delay > 0 || project.status !== 'TODO' ? project.delay : '' }` }}</p>
          </div>
        </div>
      </div>
      <div class="column uk-width-expand@m duration-container">
        <p class="label">Durée</p>
        <div class="duration col"
          :style="{ background: `linear-gradient(
            to right, #C9CFDD,
            #C9CFDD ${getTimeSpent() / project.duration * 100}%,
            #42495C ${getTimeSpent() / project.duration * 100}%,
            #42495C)`
          }">
          <p>{{ project.duration }} J</p>
        </div>
      </div>
      <div class="column uk-width-auto@m" v-if="project.status !== 'TODO' ">
        <p class="label">Objectif</p>
        <div class="col">
          <div class="initial-duration">
            <p>{{ project.initialDuration ? project.initialDuration : 0}} J</p>
          </div>
        </div>
      </div>
      <div class="column uk-width-auto@m" v-if="project.status !== 'TODO' ">
        <p class="label">Retard</p>
        <div class="col">
          <div class="delay" :class="[project.delay <= 0 ? 'negative': '']">
            <p> {{ `${project.delay && project.delay > 0 ? '+' : ''}${project.status !== 'TODO' ? project.delay : '' }` }}</p>
          </div>
        </div>
      </div>
      <div class="column uk-width-auto@m time-point label-two-lines">
        <p class="label">Fin prev.</p>
        <div class="col">
          <div v-if="project.closedStepDate" class="close-date">
            <div class="week-date">
              <p class="uk-margin-remove">
                <strong class="week">S{{ $dayjs(project.closedStepDate).isoWeek() }}</strong>
              </p>
              <p class="uk-margin-remove">
                {{ formatDate($dayjs(project.closedStepDate)) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column uk-width-auto@m" v-if="project.contractSignatureDate">
        <p class="label"></p>
        <div class="col">
          <div class="contract-end-duration">
            <p>{{ $dayjs(project.contractSignatureDate).add(15, 'M').diff($dayjs(project.closedStepDate), 'days') }} J</p>
          </div>
        </div>
      </div>
      <div class="column uk-width-auto@m time-point label-two-lines" v-if="project.contractSignatureDate">
        <p class="label red">Fin contrat</p>
        <div class="col">
          <div v-if="project.closedStepDate" class="contract-end-date">
            <div class="week-date">
              <p class="uk-margin-remove">
                <strong class="week">S{{ $dayjs(project.closedStepDate).isoWeek() }}</strong>
              </p>
              <p class="uk-margin-remove">
                {{ formatDate($dayjs(project.contractSignatureDate).add(15, 'M')) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </vk-grid>
  </div>
</template>

<script>

import types from '@/services/data/types.json';
import statuses from '@/services/data/statuses.json';

export default {
  name: 'project-header',
  props: ['project'],
  data() {
    return {
      statuses,
      types,
    };
  },
  created() {
  },
  methods: {
    getDayjsObject(date) {
      return this.$dayjs(date).utc().startOf('day');
    },
    getTimeSpent() {
      let timespent = 0;
      if (this.project.status === 'TODO') {
        timespent = this.getDayjsObject(new Date()).utc().diff(this.getDayjsObject(this.project.startedStepDate), 'day') + 1;
      }
      if (this.project.status === 'IN_PROGRESS') {
        timespent = this.getDayjsObject(new Date()).utc().diff(this.getDayjsObject(this.project.startedStepDate), 'day') + 1;
      }
      if (this.project.status === 'DONE') {
        timespent = this.project.duration;
      }
      return timespent;
    },
    formatDate(date) {
      return `${date.format('DD')} ${date.format('MMM').slice(0, 3).toUpperCase()} ${date.format('YY')}`;
    },
  },
};
</script>

<style lang="scss">
  .project-header {
    padding: 60px 0 70px 0;
    .label {
      margin-bottom: 5px;
      @include heading-xs;
      color: $label;
    }

    .week {
      display: block;
      text-align: center;
    }

    .container {
      .time-point {
        position: relative;
        margin-top: 19px;
        height: 100%;
        .label {
          position: absolute;
          top: -19px;
          left: -1em;
          padding: 0;
          text-align: center;
          &.red {
            color: $error;
          }
        }
        &.label-two-lines {
          .label {
            top: -30px;
          }
        }
      }
      .col {
        height: 60px;
        //overflow: hidden;
      }
      .contract-start, .start-date, .close-date, .contract-end-date {
        height: 100%;
        //padding: 0 20px 0 20px;
        background-color: $secondary-light;
        position: relative;
        width: 5px;
        .week-date {
          width: 60px;
          display: block;
          position: absolute;
          top: 100%;
        }
      }

      .contract-end-date {
        background-color: $error;
        .week-date {
          p {
            color: $error;
          }
        }
      }

      .initial-duration, .delay {
        background-color: $white;
        border-width: 0;
        border-right-width: 2px;
        border-style: solid;
        border-color: $gray-light;
      }

      .delay {
        border: none;
      }

      .contract-start-duration, .contract-end-duration {
        background-color: $white;
        padding: 0 20px 0 20px;
        margin-top: 19px;
        height: 100%;
      }

      .contract-end-duration {
        padding: 0 40px;
      }

      .contract-start, .start-date, .contract-start-duration, .contract-end-duration, .initial-duration, .delay, .close-date, .contract-end-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .status {
        height: 54px;
        border-width: 3px;
        border-style: solid;
        display: flex;
        background-color: $white;
        &.pending {
          border-color: $gray-regular;
          p {
            color: $gray-regular;
          }
        }
        &.todo {
          border-color: $orange;
          p {
            color: $orange;
          }
        }
        &.in_progress {
          border-color: $blue-medium;
          p {
            color: $blue-medium;
          }
        }
        &.done {
          border-color: $green;
          p {
            color: $green;
          }
        }
        p {
          margin: auto;
          display: inline-block;
          padding: 5px 10px;
          width: 100px;
          text-transform: uppercase;
          text-align: center;
          font-weight: 500;
        }
      }
      .duration-container, .delay-container {
        .label {
          text-align: center;
          padding: auto;
        }
      }
      .duration {
        display: flex;
        p {
          text-align: center;
          color: $white;
          margin: auto;
          @include heading-md;
        }
      }
      .delay {
        padding: 0 20px;
        font-weight: 500;
        color: $orange;
        &.negative {
          color: $green;
        }
      }
      .delay, .initial-duration, .contract-start-duration {
        display: flex;
        height: 100%;
        min-width: 50px;
        p {
          padding: 10px 20px 10px 20px;
          margin: auto;
        }
      }
    }
  }
</style>
