<template>
  <div class="company-categories">
    <!-- HEADER / TITRE -->
    <div class="new-header grid-x">
      <div class="cell auto">
        <h1>Liste des métiers</h1>
      </div>
    </div>

    <!-- BODY -->
    <div class="body">
      <section class="hedifys-chantier company-categories">
        <div>
          <vk-grid class="data" v-if="companyCategories.length > 0" gutter="collapse">
            <div class="categories uk-width-1-3@m">
              <ul>
                <li v-for="(type, index) in Object.keys(types.companyCategoryType)" :key="type" :class="{ active: index === indexCurrentCompanyCategory }" @click="selectCategory(index)">
                  {{ types.companyCategoryType[type] }}
                </li>
              </ul>
            </div>

            <div class="jobs uk-width-2-3@m">
              <vk-grid class="header" v-if="companyCategories.length > 0" gutter="collapse">
                <div class="uk-width-2-3@m"><h4 class="uk-text-left">Métiers</h4></div>
                <div class="uk-width-1-3@m uk-text-right"><button @click="modalType = 'add'; openModal()">Ajouter</button></div>
              </vk-grid>
              <div class="content">
                <vk-grid gutter="collapse" class="uk-child-width-expand job" v-for="category in currentType" :key="category.companyCategoryId">
                  <div class="uk-text-left uk-width-2-3@m"><span class="name">{{ category.label }}</span></div>
                  <div class="uk-text-right"><button @click="modalType = 'edit'; selectedCategory = category; openModal()">Éditer</button></div>
                  <div class="uk-text-right button-right"><button @click="deleteCategory(category)">Supprimer</button></div>
                </vk-grid>
                <div v-if="currentType && currentType.length === 0" class="uk-text-left uk-padding-small">
                  <small>Pas de métiers renseignés pour cette catégorie.</small>
                </div>
              </div>
            </div>
          </vk-grid>

          <vk-modal :show.sync="modalCategory">
            <vk-modal-title>{{ modalType === 'add' ? 'Nouveau métier' : 'Edition' }}</vk-modal-title>
            <form @submit.prevent="modalType === 'add' ? saveCategory() : editCategory()">
              <div class="content">
                <label>Label</label>
                <input class="uk-input" type="text" v-model="companyCategory.label">
              </div>
              <div class="uk-text-right">
                <vk-button class="secondary button" @click="closeModal">Annuler</vk-button>
                <vk-button class="primary button" htmlType="submit">Enregistrer</vk-button>
              </div>
            </form>
          </vk-modal>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import types from '@/services/data/types.json';
import companyCategory from '@/services/api/companyCategory';
import slugify from 'slugify';
import auth from '@/services/auth';

export default {
  name: 'company-categories',

  metaInfo: {
    title: 'Liste des métiers',
  },

  data() {
    return {
      types,
      companyCategories: [],
      indexCurrentCompanyCategory: 0,
      modalCategory: false,
      modalType: null,
      companyCategory: {
        name: '',
        label: '',
        type: null,
      },
      selectedCategory: {},
    };
  },

  mounted() {
    this.getCompanyCategories();
  },

  computed: {
    currentType: {
      get() {
        return this.companyCategories.filter((category) => category.type === Object.keys(this.types.companyCategoryType)[this.indexCurrentCompanyCategory]);
      },
    },
  },

  methods: {
    // Récupération des catégories
    async getCompanyCategories() {
      try {
        this.companyCategories = await companyCategory.getAll(this.$route.params.profilId);
      } catch (error) {
        throw error;
      }
    },

    // Sélection de la catégorie
    selectCategory(index) {
      this.indexCurrentCompanyCategory = index;
    },

    // Ouverture de la modale
    openModal() {
      this.modalCategory = true;

      if (this.modalType === 'add') {
        this.companyCategory = {
          name: '',
          label: '',
          type: Object.keys(this.types.companyCategoryType)[this.indexCurrentCompanyCategory],
        };
      } else {
        this.companyCategory = {
          name: this.selectedCategory.name,
          label: this.selectedCategory.label,
          type: Object.keys(this.types.companyCategoryType)[this.indexCurrentCompanyCategory],
        };
      }
    },

    // Fermeture de la modale
    closeModal() {
      this.modalCategory = false;
    },

    // Sauvegarde
    async saveCategory() {
      try {
        this.companyCategory.name = slugify(this.companyCategory.label, {
          lower: true,
          strict: true,
        });

        const brand = await auth.getBrand();
        const newCompanyCategory = await companyCategory.create({ brandId: brand.brandId, ...this.companyCategory });
        this.companyCategories.push(newCompanyCategory);
        this.$notification.show({ text: 'Ajout du nouveau métier réussi.' });

        // Fermeture modal si réussi
        this.closeModal();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'ajout du métier.',
          confirmText: 'Ok',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Suppression
    async deleteCategory(category) {
      try {
        await companyCategory.delete(category.companyCategoryId);
        await this.getCompanyCategories();
        this.$notification.show({ text: 'Suppression du métier réussie.' });
      } catch (error) {
        this.$notification.show({ text: 'La suppression du métier a échouée.' });
        if (error.message && error.message.includes('409')) {
          this.$message.show({
            title: 'Erreur',
            text: 'Ce métier est encore utilisé.',
            confirmText: 'Ok',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },

    // Édition
    async editCategory() {
      try {
        this.companyCategory.name = slugify(this.companyCategory.label, {
          lower: true,
          strict: true,
        });

        await companyCategory.edit({ ...this.selectedCategory, label: this.companyCategory.label, name: this.companyCategory.name });
        await this.getCompanyCategories();
        this.$notification.show({ text: 'Modification du métier réussie.' });

        // Fermeture modal si réussi
        this.closeModal();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour du métier.',
          confirmText: 'Ok',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.company-categories
  .new-header
    @include header
  .body
    @include screen
</style>

<style lang="scss">
.company-categories {
  button {
    border: none;
    padding: 10px 25px;
    color: $gray-regular;
    text-transform: uppercase;
    border-radius: 30px;
    background-color: $gray-white;
  }
  .data {
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .1);
    background-color: $off-white;
    .categories {
      border-right: 1px solid $gray-regular;
      ul {
        padding: 0;
        text-align: left;
        li {
          padding: 20px 30px;
          text-transform: uppercase;
          cursor: pointer;
          ~ li {
            border-top: 1px solid $gray-regular;
          }
          &.active {
            color: $blue-medium;
          }
        }
      }
    }
    .jobs {
      .header {
        padding: 20px 30px;
        border-bottom: 1px solid $gray-regular;
      }
      .button-right {
        margin-left: 10px;
      }
      .content {
        &.job:not(:last-child) {
          border-bottom: 1px solid $gray-regular;
        }
        .job {
          padding: 20px 30px;
          align-items: center;
          ~ .job {
            border-top: 1px solid $gray-regular;
          }
        }
      }
    }
  }
}
</style>
