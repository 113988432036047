<template>
  <div v-if="currentProjectStep" class="project-step container uk-flex uk-flex-middle" :class="currentProjectStep.type === 'START' ? 'start' : currentProjectStep.type === 'EVENT' ? 'event' : currentProjectStep.type === 'CLOSE' ? 'close' : 'default'">
    <div class="project-step uk-flex uk-flex-middle" :class="isProjectStarted && !isProjectClosed ? 'project-started' : isProjectStarted && isProjectClosed ? 'project-closed' : 'project-pending'">
      <!-- DEFINITION DE LA TACHE AVEC LE NOM DU SOUS-TRAITANT -->
      <div class="project-step-definition h-cursor-pointer" @click="openNewModal(null, 'edit')">
        <p>
          {{ currentProjectStep.label }}
        </p>
        <div v-if="currentProjectStep.comment" class="comment" :class="{public: currentProjectStep.isPublicComment}">
          <img src="/edit.svg" alt="">
        </div>
        <router-link v-if="currentProjectStep.company && currentProjectStep.company.companyId"
          :to="{ name: 'companies-companyId-projects', params: { companyId: currentProjectStep.company.companyId} }"
          class="company-name"
          :class="{ unconfirmed: !currentProjectStep.confirmed }">
          {{ projectStep.confirmed && projectStep.confirmedAt ? `${projectStep.company.isCompanyRegistered ? projectStep.company.name : 'X-' + projectStep.company.name  } - DEMANDE DU ${$dayjs(projectStep.confirmedAt).format('DD/MM/YYYY')}` : `${projectStep.company.isCompanyRegistered ? projectStep.company.name : 'X-' + projectStep.company.name}` }}
        </router-link>
        <small class="h-color-gray-regular" v-else-if="currentProjectStep.companyCategory && currentProjectStep.companyCategory.companyCategoryId">{{ currentProjectStep.companyCategory.label }} : Aucun partenaire</small>
        <small class="h-color-gray-regular event" v-else-if="currentProjectStep.type === 'EVENT'">>>> ÉVÈNEMENT</small>
        <small class="h-color-gray-regular event start" v-else-if="currentProjectStep.type === 'START'">>>> DÉMARRAGE</small>
        <small class="h-color-gray-regular event close" v-else-if="currentProjectStep.type === 'CLOSE'">>>> FIN</small>
        <small class="h-color-gray-regular" v-else>---</small>
      </div>

      <!-- DETAIL DE LA TACHE / PLANNING -->
      <vk-grid class="project-step-detail uk-width-expand uk-flex-right" gutter="collapse" matched>
        <div class="uk-text-center uk-flex uk-flex-middle">
          <!-- ETAT DE LA TACHE -->
          <div class="tag-list">
            <div v-if="labels[currentProjectStep.startStatus]" class="tag-container">
              <span :class="['tag', stateColor(currentProjectStep.startStatus, 'start', false)]">{{ labels[currentProjectStep.startStatus] }}</span>
            </div>
            <div v-if="labels[currentProjectStep.closeStatus]" class="tag-container">
              <span :class="['tag', stateColor(currentProjectStep.closeStatus, 'close', false)]">{{ labels[currentProjectStep.closeStatus] }}</span>
            </div>
            <div v-if="currentProjectStep.statusToDisplay === 'LATE'" class="tag-container">
              <span class="tag tag-late">Retard</span>
            </div>
            <div v-if="currentProjectStep.showToConfirmThumbnail" class="tag-container">
              <span class="tag tag-to-confirm">{{ labels.TO_CONFIRM }}</span>
            </div>
          </div>

          <!--  DATE DE DEBUT DE LA TACHE-->
          <div class="start-date-step uk-width-auto box-icon h-cursor-pointer" @click="openNewModal(null, 'edit')">
            <div>
              <p class="uk-margin-remove h-font-size-17">
                <strong class="week">S{{ $dayjs(currentProjectStep.startedAt).isoWeek() }}</strong>
              </p>
              <p class="uk-margin-remove h-font-size-12">
                {{ $dayjs(currentProjectStep.startedAt).format('DD.MM.YY') }}
              </p>
            </div>
          </div>

          <!-- BOUTON POUR LANCER UNE TACHE -->
          <button
            @click="start()"
            class="start-step uk-width-auto"
            :class="[stateColor(currentProjectStep.startStatus, 'start', true)]"
            :disabled="(currentProjectStep.status === 'IN_PROGRESS' || currentProjectStep.status === 'DONE')">
            <span :class="currentProjectStep.status === 'TODO' ? 'icon-planning_go' : 'icon-planning_stop'"></span>
          </button>

          <!-- JOURS PASSES SUR LA TACHE -->
          <!-- TODO API calcul diff jour ajd - startedAt -->
          <div class="project-step-duration uk-width-auto uk-margin-remove h-font-size-20 h-cursor-pointer"
            @click="openNewModal(null, 'edit')"
            :class="[stateColor(currentProjectStep.closeStatus, 'close', true)]"
          >
            {{ getStepDuration() }}J
          </div>

          <!-- BOUTON POUR ARRETER UNE TACHE -->
          <button
            @click="close()"
            class="stop-step uk-width-auto"
            :class="[stateColor(currentProjectStep.closeStatus, 'close', true)]"
            :disabled="currentProjectStep.status === 'DONE' || currentProjectStep.status === 'TODO'">
            <span class="icon-planning_stop"></span>
          </button>

          <!--  DATE DE FIN DE LA TACHE-->
          <div class="end-date-step uk-width-auto box-icon h-cursor-pointer" @click="openNewModal(null, 'edit')">
            <div>
              <p class="uk-margin-remove h-font-size-17">
                <strong class="week">S{{ $dayjs(currentProjectStep.closedAt).isoWeek() }}</strong>
              </p>
              <p class="uk-margin-remove h-font-size-12">
                {{ $dayjs(currentProjectStep.closedAt).format('DD.MM.YY') }}
              </p>
            </div>
          </div>
          <div class="uk-width-auto no-padding">
            <a
              v-if="projectStep.type !== 'START' &&  projectStep.type !== 'CLOSE'"
              @click.capture="clickDropdown"
              class="burger-container">
              <div class="container">
                <span>☰</span>
              </div>
              <div
                v-if="showDropDown"
                class="project-step-actions"
                :class="[listName === 'tasks'?  'tasks' : '']">
                <DropdownActions
                  :actions="dropdownContent"
                  :initialValue="true"
                  @dropdownClosed="showDropDown = false" />
              </div>
            </a>
          </div>
        </div>
      </vk-grid>
    </div>
  </div>
</template>

<script>
import { fr } from 'vuejs-datepicker/dist/locale';
import projectStep from '@/services/api/projectStep';
import project from '@/services/api/project';
import DropdownActions from '@/components/general/DropdownActions.vue';

export default {
  name: 'project-step',
  components: {
    DropdownActions,
  },
  props: ['projectStep', 'projectSteps', 'listName', 'companies', 'companyCategories', 'projectId', 'isProjectStarted', 'isProjectClosed'],
  data() {
    return {
      french: fr,
      labels: {
        INACTIVE: null,
        DONE: null,
        TODO: 'à faire',
        CRITICAL: 'urgent',
        TO_CONFIRM: 'à confirmer',
      },
      selectedCompanyCategory: null,
      selectedCompany: null,
      currentProjectStep: null,
      project: null,
      showDropDown: false,
      dropdownContent: [],
    };
  },
  watch: {
    projectStep: 'loadData',
  },
  mounted() {
    this.loadData();
    this.setDropdown();
  },
  methods: {
    setDropdown() {
      if (this.listName === 'project') {
        this.dropdownContent = [
          {
            title: 'Insérer une nouvelle tâche au-dessus',
            callback: this.addBefore,
          },
          {
            title: 'Insérer une nouvelle tâche en-dessous',
            callback: this.addAfter,
          },
          {
            title: 'Éditer la tâche',
            callback: () => this.openModal(null, 'edit'),
          },
          {
            title: 'Supprimer la tâche',
            callback: () => this.openModal(null, 'delete'),
          },
          {
            title: 'Décaler les tâches suivantes',
            callback: () => this.openModal(null, 'delay'),
          },
        ];
      } else {
        this.dropdownContent = [
          {
            title: 'Éditer la tâche',
            callback: () => this.openModal(null, 'edit'),
          },
          {
            title: 'Supprimer la tâche',
            callback: () => this.openModal(null, 'delete'),
          },
        ];
      }

      if (this.currentProjectStep && this.currentProjectStep.company.companyId) {
        this.dropdownContent.push({
          title: 'Fiche partenaire',
          callback: this.showCompany,
        });
      }
    },
    async openModal(event, type) {
      if (this.projectId) {
        this.project = await project.getById(this.projectId);
      }

      let selectedType = type;
      if (type === 'edit' && this.projectStep.type === 'START') {
        selectedType = 'start';
      }
      /* A ajouter avec la modale de fermeture */
      if (type === 'edit' && this.projectStep.type === 'CLOSE') {
        selectedType = 'close';
      }

      this.$emit('openModal', this.projectStep, selectedType, null, this.project);
    },
    addBefore() {
      this.add();
    },
    addAfter() {
      this.add(this.projectStep.rank + 1);
    },
    async add(rank) {
      /* Faire les mises à jour des tâches */
      await projectStep.add(
        {
          projectId: this.projectStep.project.projectId,
          label: 'Tâche vide',
          rank: !rank ? this.projectStep.rank : rank,
          startedAt: this.projectStep.closedAt,
          closedAt: this.$dayjs(this.projectStep.closedAt).add(3, 'days'),
        },
        this.projectSteps,
      );
      this.showDropDown = false;
      this.$emit('update');
    },
    clickDropdown() {
      this.showDropDown = !this.showDropDown;
    },
    loadData() {
      this.currentProjectStep = { ...this.projectStep };

      this.selectedCompanyCategory = this.projectStep.companyCategory.companyCategoryId;

      this.selectedCompany = this.currentProjectStep.company.companyId;

      this.setDropdown();
    },
    showCompany() {
      this.$router.push({ name: 'company-companyId-read', params: { companyId: this.currentProjectStep.company.companyId } });
    },
    async openNewModal(event, type) {
      if (this.projectId) {
        this.project = await project.getById(this.projectId);
      }

      let selectedType = type;
      if (type === 'edit' && this.currentProjectStep.type === 'START') {
        selectedType = 'start';
      }
      if (type === 'edit' && this.currentProjectStep.type === 'CLOSE') {
        selectedType = 'close';
      }

      if (event && event.path) {
        const domBox = event.path.find((p) => p
          && p.classList
          && p.classList.length > 0
          && p.classList.contains('box-icon'));
        this.$emit('openModal', this.currentProjectStep, selectedType, {
          y: `${(domBox.offsetTop + domBox.offsetHeight) - 2}px`,
          x: `${domBox.offsetLeft - 220}px`,
        }, this.project);
      } else {
        this.$emit('openModal', this.currentProjectStep, selectedType, null, this.project);
      }
    },
    handleDropdownOpen(ev) {
      this.openNewModal(ev, 'dropdown');
    },
    getDayjsObject(date) {
      return this.$dayjs(date).utc().startOf('day');
    },
    getStepDuration() {
      return this.getDayjsObject(this.currentProjectStep.closedAt).diff(this.getDayjsObject(this.currentProjectStep.startedAt), 'day') + 1;
    },
    getTimeSpent() {
      let timespent = 0;
      if (this.currentProjectStep.startedAt && this.currentProjectStep.status === 'IN_PROGRESS') {
        timespent = this.getDayjsObject(new Date()).utc().diff(this.getDayjsObject(this.currentProjectStep.startedAt), 'day') + 1;
      }
      if (this.currentProjectStep.closedAt && this.currentProjectStep.status === 'DONE') {
        timespent = this.getStepDuration();
      }

      return timespent;
    },
    stateColor(status, type, isButton) {
      const prefix = isButton ? 'bg' : 'tag';
      if (status === 'TODO') {
        return `${prefix}-todo`;
      } if ((status === 'IN_PROGRESS' && type === 'start') || status === 'DONE') {
        return `${prefix}-done`;
      } if (status === 'LATE') {
        return `${prefix}-late`;
      }
      return `${prefix}-pending`;
    },
    async saveData() {
      await projectStep.edit({
        projectStepId: this.currentProjectStep.projectStepId,
        projectId: this.currentProjectStep.project.projectId,
        companyId: this.selectedCompany,
        companyCategoryId: this.selectedCompanyCategory,
        label: this.currentProjectStep.label,
        rank: this.currentProjectStep.rank,
        startedAt: this.currentProjectStep.startedAt,
        closedAt: this.currentProjectStep.closedAt,
        status: this.currentProjectStep.status,
        type: this.currentProjectStep.type,
        confirmed: this.currentProjectStep.confirmed,
      });
      this.$emit('update');
    },
    changeStartStatus() {
      if (this.currentProjectStep.status === 'TODO') {
        this.currentProjectStep.status = 'IN_PROGRESS';
      } else {
        this.currentProjectStep.status = 'TODO';
      }
      if (this.currentProjectStep.status === 'IN_PROGRESS') {
        this.currentProjectStep.startStatus = 'IN_PROGRESS';
      }
    },
    changeCloseStatus() {
      if (this.currentProjectStep.status === 'IN_PROGRESS') {
        this.currentProjectStep.confirmed = true;
        this.currentProjectStep.status = 'DONE';
      } else {
        this.currentProjectStep.status = 'IN_PROGRESS';
      }
      if (this.currentProjectStep.status === 'DONE') {
        this.currentProjectStep.closeStatus = 'DONE';
      }
    },
    async start() {
      if (this.currentProjectStep.type === 'START') {
        this.openNewModal(null, 'start');
      } else if (this.currentProjectStep.type === 'CLOSE') {
        this.openNewModal(null, 'close');
      } else if (this.currentProjectStep.type === 'EVENT') {
        this.changeStartStatus();
        this.changeCloseStatus();
        await this.saveData();
      } else {
        this.changeStartStatus();
        await this.saveData();
      }
    },
    async close() {
      this.changeCloseStatus();
      await this.saveData();
    },
  },
};
</script>

<style lang="scss">
.container.project-step {
  display: contents;
  position: relative;
}

.project-step {
  border-bottom: 1px solid $gray-light;
  background: white !important;
}

.project-step {
  width: 100%;
  .event {
    color: #edc900;
    &.start,
    &.close {
      color: $event;
    }
  }
  .company-name {
    color: $blue-medium;
    font-size: 12px !important;
    font-weight: 500;
  }
  .burger-container span {
    color: $gray-dark !important;
  }
  .dropdown-link {
    display: none;
  }
  .project-step-actions {
    position: absolute;
    z-index: 1;
    right: 55px;
    margin-top: 22px;
  }
  .bg-done {
    cursor: auto !important;
    .icon-planning_stop {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 11px;
        width: 3px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  .icon-planning_stop,
  .icon-planning_go {
    color: white;
  }
  &.close .project-pending,
  &.start .project-closed,
  &.start .project-started,
  &.close .project-closed {
    > div,
    button {
      pointer-events: none;
      cursor: default;
    }
  }
  .project-step-definition {
    width: 300px;
    padding-left: 35px;
    p {
      display: inline-block;
      margin: 0;
    }
    a,
    small {
      display: block;
    }
    div.comment {
      vertical-align: middle;
      position: relative;
      display: inline-flex;
      margin-left: 8px;
      justify-content: center;
      align-items: center;
      height: 15px;
      width: 15px;
      &.public::after {
        background-color: $blue-medium;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: inherit;
        width: inherit;
        border-radius: 100%;
        background-color: $gray-light;
        z-index: 0;
      }
      img {
        position: relative;
        height: 9px;
        z-index: 5;
        filter: brightness(3);
      }
    }
  }

  .project-step-detail > div {
    > div,
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 6px;
      min-width: 65px;
      min-height: 70px;
      border: 0;
      border-left: 1px solid $gray-light;
    }
    button {
      background-color: $gray-white;
      color: white;
    }
    button[disabled] {
      cursor: auto;
    }
    .project-step-state {
      margin: 0 5px;
      padding: 10px 20px;
      min-height: 0;
      border-radius: 30px;
      text-transform: uppercase;
      @include paragraph;
      color: white;
    }
    .tag-list {
      width: fit-content;
      border: none;
    }
    .tag-container {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-left: 16px;
      }
      > span {
        text-transform: capitalize;
        height: fit-content;
      }
    }
    .project-step-duration {
      width: 200px;
      color: $white;
    }
    .week {
      display: inline-block;
      min-width: 60px;
    }
    .box-icon {
      span,
      p {
        color: $gray-medium;
      }
      span {
        padding-right: 0px !important;
        align-self: start;
        font-size: 15px;
        color: $blue-light;
        opacity: 0.5;
      }
    }
  }
  .no-padding {
    padding: 0 !important;
  }

  .unconfirmed {
    color: $orange;
  }
}

.vdp-datepicker {
  max-width: 450px;
}

.delete-modal {
  .content {
    padding: 0px 20px;
  }
  button {
    border-radius: 30px;
  }
  button.primary {
    background-color: $blue-light;
  }
  button.primary:hover {
    background-color: $blue-medium;
  }
}

.tag-pending {
  @include tag-secondary;
}

.tag-todo {
  @include tag-success;
}

.tag-late {
  @include tag-warning;
}

.tag-done {
  @include tag-neutral;
}

.tag-to-confirm {
  @include tag-danger;
}

.bg-done {
  background-color: $info !important;
}

.bg-pending {
  background-color: $secondary-dark !important;
}

.bg-late {
  background-color: $warning !important;
}

@media (max-width: 1300px) {
  .project-step .project-step-definition {
    width: 200px;
  }
  .project-step .project-step-detail > div .project-step-duration {
    width: 80px;
  }
}

@media (max-width: 1050px) {
  .project-steps-modal .uk-modal-dialog {
    transform: scale(0.8);
  }
}
</style>
