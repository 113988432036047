<template>
  <div class="models hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Modèles</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="showModal = true">Ajouter un modèle</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES MODÈLES -->
    <div class="body">
      <div class="search grid-x">
        <div class="cell shrink">
          <app-search size="small" v-model="search" placeholder="Rechercher un modèle" />
        </div>
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterRanges"
            @reset="resetRanges"
            :numberOfInputs="Object.values(filter).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select label="Gamme" v-model="filter.rangeId" :options="ranges" />
              </div>
            </div>
          </filter-modal>
        </div>
        <!-- <div class="cell auto"></div> -->
      </div>
      <app-table :headers="tableHeaders" :data="models.data" :loading="isLoading" @line-cliked="openEditModal" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="image" slot-scope="{ data }">
          <img v-if="data.image" :src="data.image" class="image" />
          <div v-else class="image not-found">
            <upload />
          </div>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="range" slot-scope="{ data }">
          <p>{{ data.range.name }}</p>
        </template>
        <template slot="description" slot-scope="{ data }">
          <p>{{ data.description }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY')  }}</p>
        </template>
      </app-table>
      <app-pagination
        v-if="models.metadata"
        :limit="limit"
        :offset="models.metadata.offset"
        :count="models.metadata.count"
      />
    </div>
    <app-modal :show="showModal" size="medium" :title="selectedModel !== null ? 'Modifier le modèle' : 'Ajouter un modèle'" @update:show="showModal = false">
      <form ref="model" @submit.prevent="submitModal">
        <div class="grid-x grid-margin-x">
          <div class="cell small-6">
            <app-upload-image :value="imgUrl" @upload="uploadImg" @remove="removeImg" />
          </div>
          <div class="cell small-6">
            <div class="grid-x grid-margin-y">
              <div class="cell">
                <app-input v-model="model.name" label="Nom du modèle" placeholder="Nom du modèle..." required />
              </div>
            </div>
            <div class="grid-x grid-margin-y">
              <div class="cell">
                <app-select label="Gamme" v-model="model.rangeId" :options="ranges" required />
              </div>
            </div>
            <div class="grid-x grid-margin-y">
              <div class="cell">
                <app-textarea v-model="model.description" label="Description" rows="4" />
              </div>
            </div>
          </div>
        </div>
        <div class="grid-x form-btn row" v-if="selectedModel === null">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" icon="add" type="submit">Créer le modèle</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
        <div class="grid-x grid-margin-x form-btn" v-else>
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-button theme="secondary" size="large" icon="archive" type="button" @click="deleteModel">Archiver</app-button>
          </div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" icon="edit" type="submit">Sauvegarder les modifications</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
      </form>
    </app-modal>
  </div>
</template>

<script>
import model from '@/services/api/model';
import range from '@/services/api/range';
import utils from '@/services/utils/utils';
import FilterModal from '@/components/general/FilterModal.vue';

import upload from '@/assets/img/upload.svg?inline';

export default {
  name: 'models',
  components: {
    FilterModal,
    upload,
  },
  metaInfo: {
    title: 'Modèles',
  },
  data() {
    return {
      models: {},
      ranges: null,
      img: null,
      oldImg: null,
      imgUrl: null,
      limit: 5,
      filter: {
        rangeId: null,
      },
      model: {
        name: null,
        rangeId: null,
        description: null,
      },
      tableHeaders: [
        { label: 'Visuel', key: 'image', size: 2 },
        { label: 'Modèle', key: 'name', size: 2 },
        { label: 'Gamme', key: 'range', size: 2 },
        { label: 'Description', key: 'description', size: 5 },
        { label: 'Créée le', key: 'createdAt', size: 1 },
      ],
      isLoading: true,
      search: null,
      selectedModel: null,
      showModal: false,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  mounted() {
    this.search = this.$route.query.search;
    this.filter.ranges = this.$route.query.rangeId || null;
    this.getModels(this.computeOffset);
    this.getRanges();
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getModels(this.computeOffset);
        this.getRanges();
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
    showModal() {
      if (!this.showModal) {
        this.model = {
          name: null,
          description: null,
          rangeId: null,
        };
        this.selectedModel = null;
        this.imgUrl = null;
        this.img = null;
      }
    },
  },
  methods: {
    async filterRanges() {
      this.isLoading = true;
      try {
        this.getRanges();
        this.$router.replace({
          query: {
            ...this.$router.query,
            rangeId: this.filter.rangeId || undefined,
          },
        });
      } catch (error) {
        throw error;
      }
      this.isLoading = false;
    },
    async resetRanges() {
      this.filter = {};
      this.getRanges();
    },
    uploadImg(event) {
      this.img = event;
      this.imgUrl = URL.createObjectURL(event);
    },
    removeImg() {
      this.img = null;
      this.imgUrl = null;
    },
    openEditModal(selectedModel) {
      this.model.name = selectedModel.name;
      this.model.description = selectedModel.description;
      this.model.rangeId = selectedModel.range.rangeId;
      this.imgUrl = selectedModel.image;
      this.oldImg = selectedModel.image;
      this.selectedModel = selectedModel.modelId;
      this.showModal = true;
    },
    submitModal() {
      if (this.selectedModel === null) {
        this.createModel();
      } else {
        this.editModel();
      }
    },
    async deleteModel() {
      this.$message.show({
        title: 'Archiver le modèle',
        text: 'Attention, si vous confirmez, vous ne pourrez plus accéder à ce modèle',
        confirmText: 'Archiver le modèle',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: async () => {
          this.isLoading = true;
          try {
            await model.delete(this.selectedModel);
            this.$notification.show({ text: 'Ce modèle a été archivée avec succès !' });
            await this.getModels();
            this.selectedModel = null;
            this.model = {
              name: null,
              description: null,
              rangeId: null,
            };
            this.imgUrl = null;
            this.showModal = false;
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de l\'archivage du modèle',
              cancelText: 'Ok',
              hasCancel: true,
            });
            this.isLoading = false;
          }
        },
      });
    },
    async editModel() {
      try {
        await model.update(this.selectedModel, this.model);

        if (this.oldImg !== this.imgUrl) {
          try {
            const file = new FormData();
            file.append('file', this.img);
            await model.uploadImg(file, this.selectedModel);
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de l\'upload de l\'image',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
        }

        await this.getModels();
        this.selectedModel = null;
        this.model = {
          name: null,
          description: null,
          rangeId: null,
        };
        this.imgUrl = null;
        this.oldImg = null;
        this.$notification.show({ text: 'Ce modèle a été mis à jour avec succès !' });
        this.showModal = false;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour du modèle',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async createModel() {
      try {
        const res = await model.create(this.model);
        const { modelId } = res.data;

        if (this.img) {
          try {
            const file = new FormData();
            file.append('file', this.img);
            await model.uploadImg(file, modelId);
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de l\'upload de l\'image',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
        }

        this.$notification.show({ text: 'Ce modèle a été ajoutée avec succès !' });

        await this.getModels();
        this.showModal = false;
        this.model.name = null;
        this.model.rangeId = null;
        this.model.description = null;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'ajout du modèle',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getModels(offset) {
      this.isLoading = true;
      try {
        this.models = await model.getAll(this.limit, offset, this.search, this.filter.rangeId ? this.filter.rangeId : null);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des modèles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async getRanges() {
      this.isLoading = true;
      try {
        const res = await range.getAll();
        this.ranges = utils.formatOptions(res, (o) => o.rangeId, (o) => o.name, 'choose');
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des gammes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.models
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
  .filter-container
    margin-left: auto
  .filter-btn
    margin-left: 1rem
  .form-btn
    margin-top: 40px
  .image
    object-fit: cover
    width: 130px
    height: 90px
  .not-found
    @include centered-container
    svg
      width: 70px
</style>
