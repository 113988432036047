import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des quotas par membre
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/quotas/`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'un quota
 * @param {quota} quota: objet du quota à créer
 */
const create = async (quota) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/quotas/`, quota);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un quota
 * @param {quota} quota: nouvel objet du quota
 * @param {string} quotaId: id du quota à récupérer
 */
const update = async (quotaId, quota) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/quotas/${quotaId}`, quota);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const quota = {};
quota.getAll = getAll;
quota.create = create;
quota.update = update;

export default quota;
