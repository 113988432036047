<template>
  <div class="regulations hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Règles géographiques</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToRegulation">Ajouter une règle géographique</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES RÉGLES -->
    <div class="body">
      <p class="description">La règle à partir d’un secteur géographique permet de contrôler localement certaines options ou bases.</p>
      <app-table :headers="tableHeaders" :data="regulations" :loading="isLoading" @line-cliked="goToRegulation" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="type" slot-scope="{ data }">
          <p>{{ regulationTypes.find(t => t.name === data.type).label }}</p>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import regulationApi from '@/services/api/regulation';
import regulationTypes from '@/services/data/regulationTypes.json';

export default {
  name: 'regulations',
  components: {
  },
  metaInfo: {
    title: 'Règles géographiques',
  },
  data() {
    return {
      regulations: null,
      regulationTypes,
      tableHeaders: [
        { label: 'Règles', key: 'name', size: 4 },
        { label: 'Type', key: 'type', size: 4 },
      ],
      isLoading: true,
    };
  },
  mounted() {
    this.getRegulations();
  },
  methods: {
    goToRegulation(regulation) {
      if (regulation && regulation.regulationId) {
        this.$router.push({ name: 'styles-regulations-regulationId', params: { regulationId: regulation.regulationId } });
      } else {
        this.$router.push({ name: 'styles-regulations-new' });
      }
    },
    async getRegulations() {
      this.isLoading = true;
      try {
        this.regulations = await regulationApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des régulations',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.regulations
  .body
    @include screen
  .text
    margin-bottom: 1rem
  .header
    @include header
  .row
    @include row
  .description
    @include description
</style>
