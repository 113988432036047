<template>
  <modal name="modal-draggable" class="modal-draggable" height="auto" draggable @closed="$emit('hide')" @opened="$emit('show')">
    <div class="modal-wrapper">
      <div class="close-modal">
        <close @click="$emit('hide')" />
      </div>
      <div class="title">
        <h2>{{ title }}</h2>
      </div>
      <slot></slot>
    </div>
  </modal>
</template>

<script>
import close from '@/assets/img/close.svg?inline';

export default {
  name: 'app-modal-draggable',
  components: {
    close,
  },
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style lang='sass'>
.modal-draggable
  z-index: 10 !important
  width: 0px !important
  height: 0px !important
  .vm--overlay
    width: 0px
    height: 0px
  .vm--modal
    position: fixed
    overflow: scroll
    .modal-wrapper
      padding: 20px
      .title
        display: flex
        justify-content: center
        margin-bottom: 1.5rem

      .close-modal
        position: absolute
        top: 24px
        right: 24px
        svg
          cursor: pointer
          width: 14px
          height: 14px
          stroke: $title
</style>
