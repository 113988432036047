<template>
  <div class="version hedifys-21">
    <form ref="version" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="version && version.reference && version.versionId"
            :items="[
              { route: { name: 'versions'}, name: 'Versions'},
              { route: { name: 'versions-versionId', params: { versionId: $route.params.versionId }}, name: version.reference }
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x" :class="{ 'includes-tabs': $route.params.versionId }">
        <div class="cell auto">
          <div v-if="!isLoading">
            <h1 v-if="$route.params.versionId">{{ version.reference }}</h1>
            <h1 v-else>Ajouter une version</h1>
          </div>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">{{ $route.params.versionId ? 'Sauvegarder' : 'Ajouter' }}</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav v-if="$route.params.versionId" :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <h3>Informations générales</h3>
          <div class="grid-x container grid-margin-x">
            <div class="cell auto">
              <div class="row">
                <app-input v-model="version.name" label="Nom de la version" placeholder="Nom..." required />
              </div>
              <div class="row">
                <app-input v-model="version.reference" label="Référence de la version" placeholder="Référence..." required />
              </div>
            </div>
            <div class="cell auto textarea">
              <app-textarea v-model="version.description" label="Description de la version" placeholder="Description de la version..." />
            </div>
          </div>
        </div>
        <div class="card">
          <h3>Détails version</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-select label="Gamme" :options="rangeOptions" v-model="version.range.rangeId" required/>
            </div>
            <div class="cell auto roof">
              <app-label required>Type de toit</app-label>
              <div class="grid-x grid-margin-x">
                <div class="cell shrink" v-for="type in roofType" :key="type.name">
                  <app-radio name="roof" v-model="version.roofType" :value="type.name">{{ type.label }}</app-radio>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-select label="Modèle" :options="modelOptions" v-model="version.model.modelId" :disabled="!version.range.rangeId" required/>
            </div>
            <div class="cell auto">
              <app-select label="Degré du toit" :options="roofDegree" v-model="version.roofDegree" required/>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-select label="Style" :options="styleOptions" v-model="version.styleId" required/>
            </div>
            <div class="cell auto">
              <app-select label="Orientation (façade avant)" :options="[{ name: null, label: 'Choisir' }].concat(orientations)" v-model="version.orientation" />
            </div>
          </div>
        </div>
        <div class="card">
          <h3>Publication</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <p class="publication">La version n’est publiable que si toutes les informations requises sont complétées.</p>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell shrink">
              <app-radio name="active" v-model="version.status" :value="VersionStatus.DRAFT">Brouillon</app-radio>
            </div>
            <div class="cell shrink">
              <app-radio name="active" v-model="version.status" :value="VersionStatus.PRIVATE">Publiée en privé</app-radio>
            </div>
            <div class="cell shrink">
              <app-radio name="active" v-model="version.status" :value="VersionStatus.PUBLIC">Publiée en public</app-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import versionApi from '@/services/api/version';
import modelApi from '@/services/api/model';
import rangeApi from '@/services/api/range';
import styleApi from '@/services/api/style';
import memberApi from '@/services/api/member';

import orientations from '@/services/data/orientation.json';
import roofType from '@/services/data/roofType.json';
import roofDegree from '@/services/data/roofDegree.json';
import utils from '@/services/utils/utils';
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import VersionStatus from '@/services/enums/versionStatus.enum';

export default {
  name: 'version',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.version.reference ? `${this.version.reference} – Version` : 'Version',
    };
  },
  data() {
    return {
      VersionStatus,
      version: {
        versionId: null,
        description: null,
        name: null,
        reference: null,
        model: {
          modelId: null,
          name: null,
        },
        range: {
          rangeId: null,
          name: null,
        },
        styleId: null,
        roofType: 'TILES',
        status: VersionStatus.DRAFT,
      },
      roofDegree,
      roofType,
      orientations,
      ranges: [],
      rangeOptions: [],
      styles: [],
      styleOptions: [],
      models: [],
      modelOptions: [],
      me: null,
      isBrandAdmin: null,
      isLoading: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getData();
      }
    },
    'version.range.rangeId': {
      async handler(value, oldValue) {
        if (oldValue !== null && value !== oldValue) {
          this.version.model = { modelId: null, name: null };
        }
        await this.getModels(this.version.range.rangeId);
        this.modelOptions = utils.formatOptions(
          this.models,
          (o) => o.modelId,
          (o) => o.name,
        );
      },
    },
  },
  computed: {
    tabs() {
      const tabs = [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: null },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: null },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: null },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: null },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: null },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: null },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: null },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: null },
          },
          label: 'Prix',
        },
      ];
      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { versionId: this.$route.params.versionId },
        },
        label: el.label,
      }));
    },
  },
  methods: {
    async getData() {
      await this.getVersion();
      await this.getRanges();
      this.rangeOptions = utils.formatOptions(
        this.ranges,
        (o) => o.rangeId,
        (o) => o.name,
      );
      await this.getStyles();
      this.styleOptions = utils.formatOptions(
        this.styles,
        (o) => o.styleId,
        (o) => o.name,
      );
    },
    async getVersion() {
      try {
        if (this.$route.params.versionId) {
          this.version = await versionApi.getById(this.$route.params.versionId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la version',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getRanges() {
      try {
        this.ranges = await rangeApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des gammes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getModels(rangeId) {
      try {
        const models = await modelApi.getAll(null, null, null, rangeId, true);
        this.models = models.data;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des modèles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getStyles() {
      try {
        this.styles = await styleApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des styles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        const versionObject = {
          modelId: this.version.model.modelId,
          styleId: this.version.styleId,
          name: this.version.name,
          reference: this.version.reference,
          description: this.version.description,
          roofType: this.version.roofType,
          roofDegree: this.version.roofDegree,
          orientation: this.version.orientation,
          status: this.version.status,
        };
        if (this.$route.params.versionId) {
          await versionApi.update(this.$route.params.versionId, {
            ...versionObject,
            isFirstExtensionActive: this.version.isFirstExtensionActive,
            isSecondExtensionActive: this.version.isSecondExtensionActive,
          });
          this.$notification.show({ text: 'Version mise à jour avec succès !' });
          this.getData();
        } else {
          const version = await versionApi.create(versionObject);
          this.$notification.show({ text: 'Version ajoutée avec succès !' });
          this.$router.push({ name: 'versions-versionId', params: { versionId: version.versionId } });
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde de la version.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.version
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .container
    margin: 0
  .textarea
    margin-bottom: 24px
    label
      height: 100%
  .roof
    display: flex
    flex-direction: column
    justify-content: space-evenly
  .breadcrumbs
    @include breadcrumbs
  .publication
    @include details
    color: $label
  .card
    @include card
  .save
    margin-left: 16px
</style>
