var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-contact hedifys-21"},[_c('div',{staticClass:"breadcrumbs grid-x row"},[_c('div',{staticClass:"cell auto"},[(_vm.$route.params.customerId && _vm.customer && _vm.customer.lastname)?_c('breadcrumb',{attrs:{"items":[
          {
            route: { name: _vm.routeStatus },
            name: _vm.routeStatus  === 'customers-unqualified' ? 'À qualifier' : _vm.routeStatus === 'customers-prospects' ? 'Prospects' : 'Clients'
          },
          {
            route: {
            name: (_vm.routeStatus + "-customerId"),
            params: { customerId: _vm.$route.params.customerId }},
            name: ((_vm.customer.firstname) + " " + (_vm.customer.lastname))
          }
        ]}}):_vm._e()],1)]),_c('div',{staticClass:"new-header grid-x"},[_c('div',{staticClass:"cell shrink"},[_c('h1',[_vm._v(_vm._s(((_vm.customer.firstname) + " " + (_vm.customer.lastname))))])]),_c('div',{staticClass:"cell auto tag-container"},[_c('h5',{staticClass:"tag",class:_vm.tagColor},[_vm._v(_vm._s(_vm.getCustomerStatusName(_vm.customer.status)))])]),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add"},on:{"click":_vm.showModale}},[_vm._v(" Envoyer un message ")])],1)]),(_vm.$route.params.customerId)?_c('tab-nav',{attrs:{"tabs":_vm.tabs}}):_vm._e(),_c('div',{staticClass:"body"},[(_vm.loadingData)?_c('app-spinner'):(!_vm.loadingData && _vm.messages.length)?_c('ul',{staticClass:"message-list"},_vm._l((_vm.messages),function(message,index){return _c('li',{key:("message-" + index)},[_c('p',[_vm._v(_vm._s(message.content))]),_c('time',[_vm._v(_vm._s(_vm._f("date")(message.createdAt)))])])}),0):_c('p',{staticClass:"empty-message"},[_vm._v("Aucun message envoyé")])],1),_c('app-modal',{attrs:{"show":_vm.displayModale,"title":"Envoyer un message"},on:{"update:show":_vm.hideModale}},[_c('form',{staticClass:"message-form",on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage($event)}}},[_c('app-textarea',{attrs:{"label":"Texte","placeholder":"Votre message...","maxLength":1600},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}}),_c('p',{staticClass:"text-length"},[_vm._v(_vm._s(_vm.newMessage ? _vm.newMessage.length : 0)+"/1600 caractères")]),_c('app-button',{staticClass:"submit-button",attrs:{"size":"small","disabled":_vm.disabledFormSubmit},on:{"click":_vm.sendMessage}},[(_vm.loadingSendMessage)?[_vm._v("Envoi en cours...")]:[_vm._v("Envoyer")]],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }