import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des avant-projets
 * @param {number} limit: nombre d'éléments à récupérer
 * @param {number} offset: offset de la liste
 * @param {string} agencyId: id de l'agence
 * @param {string} status: status à récupérer
 */
const getAll = async (limit, offset, agencyId, status) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/project-drafts`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(agencyId && { agencyId }),
        ...(status && { status }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des avant-projets d'un client
 * @param {number} limit: nombre d'éléments à récupérer
 * @param {number} offset: offset de la liste
 * @param {string} agencyId: id de l'agence
 * @param {string} status: status à récupérer
 */
const getByCustomer = async (limit, offset, customerId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/${customerId}/project-drafts`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération d'un avant-projet
 * @param {string} projectDraftId: id de l'avant projet à récupérer
 */
const getById = async (projectDraftId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/project-drafts/${projectDraftId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération d'un lien du PDF de l'avant-projet
 * @param {string} projectDraftId: id de l'avant projet à récupérer
 */
const getPdfUrl = async (projectDraftId, query) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/project-drafts/${projectDraftId}/pdf?${query}`, { responseType: 'arraybuffer' });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'un avant-projet
 * @param {object} data: données d'un avant-projet
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/project-drafts`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Duplication d'un avant-projet
 * @param {string} projectDraftId: id de l'avant projet à dupliquer
 */
const duplicate = async (projectDraftId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/project-drafts/${projectDraftId}/duplicate`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mise à jour d'un avant-projet
 * @param {string} projectDraftId: id de l'avant projet
 * @param {object} data: données de l'avant projet
 */
const update = async (projectDraftId, data) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/project-drafts/${projectDraftId}`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Suppression d'un terrain
 * @param {string} projectDraftId: id de l'avant projet à supprimer
 */
const deleteOne = async (projectDraftId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/project-drafts/${projectDraftId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const projectDraft = {};
projectDraft.getAll = getAll;
projectDraft.getById = getById;
projectDraft.create = create;
projectDraft.duplicate = duplicate;
projectDraft.update = update;
projectDraft.delete = deleteOne;
projectDraft.getByCustomer = getByCustomer;
projectDraft.getPdfUrl = getPdfUrl;

export default projectDraft;
