<template>
  <div class="company-lands hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="company && company.companyId && company.name"
          :items="[
            { route: { name: 'companies'}, name: 'Partenaires'},
            { route: { name: 'companies-companyId', params: { companyId: $route.params.companyId }}, name: company.name}
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <div>
          <h1>{{ !isLoading && $route.params.companyId && company ? company.name : ' ' }}</h1>
        </div>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav v-if="$route.params.companyId" :tabs="tabs" />
    <!-- BODY / TABLE -->
    <div class="body">
      <div class="search grid-x">
        <!-- SEARCH BAR -->
        <div class="cell auto">
          <app-search
            v-model="search"
            placeholder="Rechercher une référence, un lotissement"
            size="auto"
          />
        </div>

        <!-- FILTER MODAL -->
        <div class="cell shrink">
          <filter-modal
            @change="filterLands"
            @reset="resetFilters"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-radio-button
                  label="Trier par"
                  name="orderBy"
                  :value="$route.query.orderBy"
                  v-model="filters.orderBy"
                  :options="[
                    { name: 'price', label: 'Prix croissant' },
                    { name: 'width', label: 'Façade croissante' }
                  ]"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-search-city
                  label="Commune"
                  placeholder="Nantes - 44000"
                  @select="setPreSelectedPostalCode"
                  @clear="resetSelectedPostalCode"
                  :selectedPostalCode="selectedPostalCode"
                />
              </div>
            </div>
          </filter-modal>
        </div>

        <div class="cell auto"/>
      </div>

      <!-- TABLE -->
      <app-table
        :headers="tableHeader"
        :data="lands.data"
        :loading="isLoading"
        @line-cliked="goToLand"
        clickable
      >
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="city" slot-scope="{ data }">
          <strong>
            {{ data.address.postalCodeInseeNumber.split('-')[0] }} - {{ data.address.city }}
          </strong>
        </template>
        <template slot="allotment" slot-scope="{ data }">
          <p>{{ data.allotment.name }}</p>
        </template>
        <template slot="reference" slot-scope="{ data }">
          <p>{{ data.reference }}</p>
        </template>
        <template slot="isAvailableForAds" slot-scope="{ data }">
          <p>{{ booleanEnum[data.isAvailableForAds] }}</p>
        </template>
        <template slot="status" slot-scope="{ data }">
          <h5 class='tag' :class="[`tag-${status[data.status].tag}`]">
            <success v-if="status[data.status].tag === 'success'"/>
            <minus v-if="status[data.status].tag === 'warning'"/>
            <price v-if="status[data.status].tag === 'neutral'"/>
              {{ status[data.status].label }}
          </h5>
        </template>
        <template slot="lotNumber" slot-scope="{ data }">
          <p>{{ data.lotNumber }}</p>
        </template>
        <template slot="depth" slot-scope="{ data }">
          <p>{{ data.width }} m</p>
        </template>
        <template slot="surface" slot-scope="{ data }">
          <strong>{{ data.surface }} m²</strong>
        </template>
        <template slot="price" slot-scope="{ data }">
          <strong class="price">{{ utils.formatCentToEuro(data.price, true) }} </strong>
        </template>
        <template slot="empty-table">
          <p>Aucune donnée de disponible</p>
        </template>
      </app-table>

      <!-- PAGINATION -->
      <app-pagination
        v-if="lands.metadata"
        :limit="limit"
        :offset="lands.metadata.offset"
        :count="lands.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import companyApi from '@/services/api/company';
import landApi from '@/services/api/land';
import utils from '@/services/utils/utils';

import companyCategory from '@/services/api/companyCategory';

import postalCodeApi from '@/services/api/postalCode';
import booleanEnum from '@/services/enums/booleanEnum.enum';
import status from '@/services/data/status.json';
import FilterModal from '@/components/general/FilterModal.vue';
import AppSearchCity from '@/basics/components/AppSearchCity.vue';

import success from '@/assets/img/success.svg?inline';
import minus from '@/assets/img/minus.svg?inline';
import price from '@/assets/img/price.svg?inline';

export default {
  name: 'company-lands',
  components: {
    TabNav,
    Breadcrumb,
    FilterModal,
    AppSearchCity,
    success,
    minus,
    price,
  },
  metaInfo() {
    return {
      title: this.company ? `${this.company.name} –  Partenaire` : 'Partenaire',
    };
  },
  data() {
    return {
      // New data
      isLoading: false,
      company: null,
      search: null,

      tableHeader: [
        { label: 'Ville', key: 'city', size: 'auto' },
        { label: 'Lotissement', key: 'allotment', size: 'auto' },
        { label: 'Référence', key: 'reference', size: 1 },
        { label: 'Diffusion', key: 'isAvailableForAds', size: 1 },
        { label: 'Statut', key: 'status', size: 'auto' },
        { label: 'Lot', key: 'lotNumber', size: 1 },
        { label: 'Façade', key: 'depth', size: 1 },
        { label: 'Surface', key: 'surface', size: 1 },
        { label: 'Prix', key: 'price', size: 1 },
      ],
      lands: {
        data: null,
        metadata: null,
      },

      preSelectedPostalCode: null,
      selectedPostalCode: null,
      showCreateLandModale: false,
      filters: {
        orderBy: null,
        postalCodeInseeNumber: null,
      },
      limit: 6,
      booleanEnum,
      status,
      utils,
      selectedView: null,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
    this.selectedPage = parseInt(this.$route.query.page, 10);
  },
  async mounted() {
    this.isLoading = true;
    try {
      // récupération des métiers
      const companyCategories = await companyCategory.getAll();

      if (companyCategories && companyCategories.length > 0) {
        this.companyCategories = companyCategories.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.companyCategories = [];
      }
    } catch (er) {
      this.$message.show({
        title: 'Erreur',
        text: 'Il y a eu un problème lors du chargement des métiers',
        cancelText: 'Ok',
        hasCancel: true,
      });
    }
    this.search = this.$route.query.search;
    this.selectedView = this.$route.query.view || undefined;
    this.filters.orderBy = this.$route.query.orderBy || null;
    this.filters.postalCodeInseeNumber = this.$route.query.postalCodeInseeNumber || null;
    await this.getCompany();
    await this.getLands(this.computeOffset);

    if (this.$route.query.postalCodeInseeNumber) {
      this.getPostalCode(this.$route.query.postalCodeInseeNumber);
    }

    this.isLoading = false;
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    tabs() {
      let tabs = [
        {
          route: {
            name: 'companies-companyId',
            params: { companyId: null },
          },
          label: 'Informations',
        },
      ];
      if (this.company && this.company.companyCategories && this.company.companyCategories.length && this.companyCategories && this.companyCategories.length) {
        const isSupplierOrContractor = this.companyCategories.filter(
          (category) => (category.type === 'SUPPLIER' || category.type === 'SUBCONTRACTOR')
            && this.company.companyCategories.includes(category.companyCategoryId),
        ).length > 0;
        if (isSupplierOrContractor) {
          tabs = tabs.concat([
            {
              route: {
                name: 'companies-companyId-projects',
                params: { companyId: null },
              },
              label: 'Chantiers',
            },
            {
              route: {
                name: 'companies-companyId-schedule',
                params: { companyId: null },
              },
              label: 'Disponibilités',
            },
          ]);
        }

        const isDeveloper = this.companyCategories.filter(
          (category) => category.type === 'DEVELOPER'
            && this.company.companyCategories.includes(category.companyCategoryId),
        ).length > 0;
        if (isDeveloper) {
          tabs = tabs.concat([
            {
              route: {
                name: 'companies-companyId-allotments',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Lotissements',
            },
            {
              route: {
                name: 'companies-companyId-lands',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Terrains',
            },
          ]);
        }
      }

      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { companyId: this.$route.params.companyId },
        },
        label: el.label,
      }));
    },
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getLands(this.computeOffset);
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
    filters: {
      handler(value) {
        this.filters.orderBy = value.orderBy;
        this.filters.postalCodeInseeNumber = value.postalCodeInseeNumber;
      },
      deep: true,
    },
    selectedView() {
      this.updateRoute();
    },
  },
  methods: {
    goToLand(land) {
      this.$router.push({ name: 'lands-landId', params: { landId: land.landId } });
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    async getLands() {
      try {
        this.lands = await landApi.getAll(
          this.limit,
          this.computeOffset,
          this.$route.query.search,
          this.$route.query.orderBy,
          null,
          this.$route.params.companyId,
          this.$route.query.postalCodeInseeNumber,
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des terrains',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Récupération du code postal sélectionné
    async getPostalCode(postalCodeInseeNumber) {
      try {
        const postalCode = await postalCodeApi.getOne(postalCodeInseeNumber);
        this.selectedPostalCode = `${postalCode.city} - ${postalCode.postalCodeInseeNumber.split('-')[0]}`;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du code postal',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCompany() {
      if (this.$route.params.companyId) {
        try {
          this.company = await companyApi.getById(this.$route.params.companyId);
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors du chargement du partenaire',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    // Récupère le code postal sélectionné
    setPreSelectedPostalCode(value) {
      this.preSelectedPostalCode = value.label;
      this.filters.postalCodeInseeNumber = value.name;
    },

    filterLands() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      const postalCodeInseeNumber = this.$route.query.postalCodeInseeNumber ? this.$route.query.postalCodeInseeNumber : null;
      const orderBy = this.$route.query.orderBy ? this.$route.query.orderBy : null;

      if (postalCodeInseeNumber !== this.filters.postalCodeInseeNumber || orderBy !== this.filters.orderBy) {
        this.$router.push({
          query: {
            ...this.$route.query,
            orderBy: this.filters.orderBy || undefined,
            postalCodeInseeNumber: this.filters.postalCodeInseeNumber || undefined,
            page: 1,
          },
        });
      }

      if (this.preSelectedPostalCode) {
        this.selectedPostalCode = this.preSelectedPostalCode;
      }

      if (!this.$route.query.postalCodeInseeNumber) {
        this.selectedPostalCode = null;
      }

      // On récupère les terrains avec les nouvelles query
      this.getLands();
    },

    // Extrait les coordonnées des terrains et de leur id pour la carte
    getLandsDataForMap() {
      this.landsCoordinates = this.lands.data.map((land) => ({
        landId: land.landId,
        coordinates: land.coordinates,
      }));
    },

    resetFilters() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        orderBy: null,
        postalCodeInseeNumber: null,
      };

      // On supprime les query categoryId et lotId de l'URl
      this.$router.replace({
        query: {
          ...this.$route.query,
          orderBy: undefined,
          postalCodeInseeNumber: undefined,
        },
      });

      // On réinitialise les valeurs du label de la commune sélectionné
      this.preSelectedPostalCode = null;
      this.selectedPostalCode = null;

      this.getLands();
    },

    // Reset les valeurs du code postal sélectionné si le champ de recherche est vide
    resetSelectedPostalCode() {
      this.filters.postalCodeInseeNumber = null;
    },

    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.orderBy = this.$route.query.orderBy || null;
      this.filters.postalCodeInseeNumber = this.$route.query.postalCodeInseeNumber || null;

      if (!this.$route.query.postalCodeInseeNumber) {
        this.selectedPostalCode = null;
      }
    },

    // On met à jour la query de la vue sélectionné
    updateRoute() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          view: this.selectedView,
        },
      });
    },
  },
};
</script>

<!-- New style -->
<style lang='sass' scoped>
.company-lands
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .save
    margin-left: 16px
  .breadcrumbs
    @include breadcrumbs
  h3
    color: $primary
    margin-bottom: 10px
  .row
    @include row
  .price
    @include price
  .search
    padding-bottom: 1rem
    button
      margin-left: 1rem

  .filter-btn
    margin-left: 1rem
</style>
