<template>
  <div class="brand hedifys-21">
    <form ref="brand" @submit.prevent="save">
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Votre marque</h1>
        </div>
        <div class="cell shrink">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <h3>Informations générales</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto"></div>
            <div class="cell shrink avatar">
              <app-avatar v-model="brandLogoUrl" @upload="uploadImg" @remove="removeImg" />
              <h4>Logo de la marque</h4>
            </div>
            <div class="cell auto"></div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="brand.name" type="text" placeholder="Nom..." label="Nom de la marque" required />
            </div>
            <div class="cell auto">
              <app-input v-model="brand.address.addressLine1" type="text" placeholder="Adresse..." label="Adresse de la marque" required />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="brand.website" type="text" placeholder="URL..." label="Lien du site" required />
            </div>
            <div class="cell auto">
              <app-input v-model="brand.address.addressLine2" type="text" placeholder="Adresse complémentaire..." label="Adresse complémentaire" />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="brand.phone" type="text" placeholder="Téléphone..." label="Numéro de téléphone" required />
            </div>
            <div class="cell auto">
              <div class="grid-x row">
                <div class="cell auto">
                  <app-input v-model="brand.address.postalCode" type="text" placeholder="Code postal..." label="Code postal" size="small" required />
                </div>
                <div class="cell auto">
                  <app-input v-model="brand.address.city" type="text" placeholder="Ville..." label="Ville" size="small" required />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import auth from '@/services/auth';
import brandApi from '@/services/api/brand';

export default {
  name: 'brand',
  components: {
    TabNav,
  },
  metaInfo: {
    title: 'Votre marque',
  },
  data() {
    return {
      isLoading: null,
      brand: {
        name: null,
        address: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          postalCode: null,
        },
        phone: null,
        logo: null,
        website: null,
      },
      brandLogoUrl: null,
      brandLogo: null,
      tabs: [
        {
          route: {
            name: 'brand',
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'brand-members',
          },
          label: 'Membres',
        },
        {
          route: {
            name: 'brand-parameters',
          },
          label: 'Paramètres de marque',
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getBrand();
    this.brandLogoUrl = this.brand.logo;
    this.isLoading = false;
  },
  methods: {
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        await brandApi.update(this.brand.brandId, {
          name: this.brand.name,
          website: this.brand.website,
          address: this.brand.address,
          phone: this.brand.phone,
          defaultSeparationDistance: this.brand.defaultSeparationDistance,
          habitableExtensionPrice: this.brand.habitableExtensionPrice,
          nonHabitableExtensionPrice: this.brand.nonHabitableExtensionPrice,
          carportExtensionPrice: this.brand.carportExtensionPrice,
          margin: this.brand.margin,
        });
        try {
          await brandApi.uploadLogo(this.brand.brandId, this.brandLogo);
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la mise à jour du logo de la marque',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.$notification.show({ text: 'La marque a été mise à jour avec succès !' });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde des modifications de la marque',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async uploadImg(event) {
      const file = new FormData();
      file.append('file', event);
      this.brandLogo = file;
      this.brandLogoUrl = URL.createObjectURL(event);
    },
    removeImg() {
      this.brandLogo = null;
      this.brandLogoUrl = null;
    },
  },
};
</script>

<style lang='sass' scoped>
.brand
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
  .input
    margin-right: 16px
  .avatar
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 16px
    > div
      margin-bottom: 16px
</style>
