<template>
  <section class="app-card-link">
    <router-link v-if="primaryRoute.route" :to="primaryRoute.route"  target="_blank"></router-link>
    <div class="body">
      <h3>{{ primaryRoute.label }}</h3>
      <p>{{ description }}</p>
      <router-link v-if="secondaryRoute" :to="secondaryRoute.route" target="_blank">{{ secondaryRoute.label }}</router-link>
    </div>
    <div class="img">
      <chevron />
    </div>
  </section>
</template>

<script>
import chevron from '@/assets/img/chevron.svg?inline';

export default {
  name: 'app-card-link',
  components: {
    chevron,
  },
  props: {
    primaryRoute: {
      // Format : { route: { name: '', params:{} }, label: 'home' }
      type: Object,
      default: () => {},
    },
    description: {
      type: String,
    },
    secondaryRoute: {
      // Format : { route: { name: '', params:{} }, label: 'home' }
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang='sass' scoped>
.app-card-link
  background-color: $background
  border: 1px solid $line
  border-radius: 4px
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%
  position: relative

  > a
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0

  .img
    display: flex
    align-items: center
    padding-right: 20px

    svg
      width: 6px
      height: 12px

  .body
    display: flex
    flex-direction: column
    padding: 16px

    h3
      color: $primary

    p
      @include details
      color: $label

    a
      z-index: 1
      margin-top: 16px
      @include hyperlink
</style>
