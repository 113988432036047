import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Mise à jour d'une marque
 * @param {object} brand: nouvel objet de la marque
 * @param {string} brandId: id de la marque à récupérer
 */
const update = async (brandId, brand) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/brands/${brandId}`, brand);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * @param {string} brandId: id de la marque
 * @param {FormData} files: photos à upload
 * @returns files: incluant les nouvelles photos ou leur suppression
 */
const uploadPhotos = async (brandId, files) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/brands/${brandId}/template-photo`, files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * @param {string} brandId: id de la marque
 * @param {File} file: logo à upload, optionnel
 * @returns données du membre, incluant le nouveau logo ou sa suppression
 */
const uploadLogo = async (brandId, file) => {
  try {
    let response = null;
    if (file === null) {
      response = await axios.post(`${VUE_APP_API_URL}/brands/${brandId}/logo`);
    } else {
      response = await axios.post(`${VUE_APP_API_URL}/brands/${brandId}/logo`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    return response.data;
  } catch (er) {
    throw er;
  }
};

// Edito
const getEdito = async (brandId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/brand/edito/${brandId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const uploadEdito = async (brandId, file) => {
  try {
    let response = null;
    if (file === null) {
      response = await axios.post(`${VUE_APP_API_URL}/brand/edito/${brandId}`);
    } else {
      response = await axios.post(`${VUE_APP_API_URL}/brand/edito/${brandId}`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const brand = {};
brand.update = update;
brand.uploadPhotos = uploadPhotos;
brand.uploadLogo = uploadLogo;
brand.getEdito = getEdito;
brand.uploadEdito = uploadEdito;

export default brand;
