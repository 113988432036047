import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère un modèle
 * @param {string} modelId: id du modèle à récupérer
 */
const getById = async (modelId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/models/${modelId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des modèles
 * @param {number} limit: nombre d'éléments à récupérer
 * @param {number} offset: offset de la liste
 * @param {string} search: paramètre de recherche, sur le nom du modèle
 * @param {string} rangeId: id de la gamme
 * @param {boolean} noLimit: bool, pour savoir si on veut utiliser la pagination ou non
 * @param {string} packId: id du pack
 */
const getAll = async (limit, offset, search, rangeId, nolimit, packId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/models`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(rangeId && { rangeId }),
        ...(nolimit && { nolimit }),
        ...(packId && { packId }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'un modèle
 * @param {model} model: objet du modèle à créer
 */
const create = async (model) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/models/`, model);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un modèle
 * @param {model} model: nouvel objet du modèle
 * @param {string} modelId: id du modèle à récupérer
 */
const update = async (modelId, model) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/models/${modelId}`, model);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression du modèle
 * @param {string} modelId: id du modèle à récupérer
 */
const deleteOne = async (modelId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/models/${modelId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Upload de l'image du modèle
 * @param {string} modelId: id du modèle
 */
const uploadImg = async (file, modelId) => {
  try {
    let response = null;
    if (file === null) {
      response = await axios.post(`${VUE_APP_API_URL}/models/${modelId}/image`);
    } else {
      response = await axios.post(`${VUE_APP_API_URL}/models/${modelId}/image`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const model = {};
model.getAll = getAll;
model.getById = getById;
model.create = create;
model.update = update;
model.delete = deleteOne;
model.uploadImg = uploadImg;

export default model;
