import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère un coefficient
 * @param {string} brandCoefficientId: id du coefficient à récupérer
 */
const getById = async (brandCoefficientId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/brand-coefficients/${brandCoefficientId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des coefficients
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/brand-coefficients/`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'un coefficient
 * @param {brandCoefficient} brandCoefficient: objet du coefficient à créer
 */
const create = async (brandCoefficient) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/brand-coefficients/`, brandCoefficient);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un coefficient
 * @param {brandCoefficient} brandCoefficient: nouvel objet du coefficient
 * @param {string} brandCoefficientIdId: id du coefficient
 */
const update = async (brandCoefficientId, brandCoefficient) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/brand-coefficients/${brandCoefficientId}`, brandCoefficient);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression du coefficient
 * @param {string} brandCoefficientId: id du coefficient à récupérer
 */
const deleteOne = async (brandCoefficientId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/brand-coefficients/${brandCoefficientId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const brandCoefficient = {};
brandCoefficient.getAll = getAll;
brandCoefficient.getById = getById;
brandCoefficient.create = create;
brandCoefficient.update = update;
brandCoefficient.delete = deleteOne;

export default brandCoefficient;
