<template>
<div class="app-input-file" :class="size">
  <div class="input">
    <div>
      <input type="file" ref="file" :accept="accept" @change="fileUpload" :required="required">
      <span class="text">
        <span v-if="file">{{ file.name }}</span>
        <span v-else>
          <a class="link" v-if="filename && url" href="#" @click.prevent="getFile" target="_blank">{{ filename }}</a>
          <span v-else>Votre fichier</span>
        </span>
        <remove v-if="file || (filename && url)" class="remove" @click="removeFile" />
      </span>
    </div>
    <app-button theme="primary" size="small" @click="$refs.file.click()">Importer</app-button>
  </div>
  <span v-if="text" class="text">{{ text }}</span>
</div>
</template>

<script>
import remove from '@/assets/img/remove.svg?inline';

export default {
  name: 'app-input-file',
  components: {
    remove,
  },
  data() {
    return {
      file: null,
    };
  },
  props: {
    text: {
      type: String,
    },
    accept: {
      type: String,
    },
    filename: {
      type: String,
    },
    url: {
      type: String,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
      default: 'large',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fileUpload(event) {
      const file = event.target.files[0];
      this.file = file;
      this.$emit('upload', this.file);
    },
    removeFile() {
      this.file = null;
      this.$emit('remove');
    },
    getFile() {
      window.open(this.url, '_blank');
    },
  },
};
</script>

<style lang="sass" scoped>
.small
  .input
    width: 50% !important
    min-width: 240px !important

.large
  .input
    width: 100% !important

.app-input-file
  display: inline-flex
  flex-direction: column
  position: relative
  width: 100%

  .input
    display: flex
    width: 100%
    align-items: center
    justify-content: space-between
    background-color: $off-white
    user-select: none

    > div
      width: 100%
      height: 38px
      display: flex
      justify-content: space-between
      align-items: center
      border: 1px solid $line
      border-radius: 4px 0 0 4px

    .remove
      cursor: pointer
      width: 14px
      height: 14px

      path
        stroke: $info

    .text
      margin: 0 16px
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center

      > span
        @include paragraph
        color: $info

      .link
        @include hyperlink

    button
      border-radius: 0 4px 4px 0
      width: 70px !important

  input[type="file"]
    display: none
    position: absolute
    top: 0
    left: 0

  .text
    @include details
    margin-top: 8px
    color: $info
</style>
