import { render, staticRenderFns } from "./VersionConstraints.vue?vue&type=template&id=4ccff1b8&scoped=true&"
import script from "./VersionConstraints.vue?vue&type=script&lang=js&"
export * from "./VersionConstraints.vue?vue&type=script&lang=js&"
import style0 from "./VersionConstraints.vue?vue&type=style&index=0&id=4ccff1b8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ccff1b8",
  null
  
)

export default component.exports