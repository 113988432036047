<template>
  <div class="matching-map">
    <div class="map-container">
      <div class="map-loader">
        <app-spinner v-if="mapIsLoading"/>
        <p>Chargement de la carte...</p>
      </div>
      <div :class="{ 'map-hidden': mapIsLoading }" id="map" />
    </div>
  </div>
</template>

<script>
import utils from '@/services/utils/utils';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';

export default {
  name: 'matching-map',

  props: {
    postalCodes: {
      type: Array,
    },
    selectedPostalCode: {
      type: Object,
    },
    height: {
      type: Number,
    },
  },

  data() {
    return {
      map: null,
      mapIsLoading: true,
      initialMapSettings: {
        center: [2.7195924, 46.9259861],
        zoom: 6,
      },

      mapFeatures: [],

      isFirstLoad: true,
      markers: [],
    };
  },

  watch: {
    postalCodes: {
      handler() {
        this.setMapFeatures();
      },
      deep: true,
    },
  },

  mounted() {
    this.setMap();
  },

  methods: {
    // Setup de la carte
    setMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: process.env.VUE_APP_MAPBOX_STYLE_URL,
        center: this.initialMapSettings.center,
        zoom: this.initialMapSettings.zoom,
        // dragPan: false,
        // scrollZoom: false,
        doubleClickZoom: false,
        maxZoom: 12,
        minZoom: 8,
      });

      this.loadLands();
    },

    // Chargement de la carte
    loadLands() {
      this.map.on('load', () => {
        this.mapIsLoading = false;

        this.map.addControl(new mapboxgl.NavigationControl());

        // On appelle la création des layers si les terrains existent
        const mapSource = this.map.getSource('postalCodes');
        if (!mapSource) {
          // Ajout des sources
          this.map.addSource('postalCodes', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: this.mapFeatures,
            },
          });
        }

        this.setMapFeatures();

        this.map.on('dragend', () => {
          const center = this.map.getCenter();
          this.$emit('mapdrag', { latitude: center.lat, longitude: center.lng });
        });
      });
    },

    // Création des layers pour chaque terrain
    setLayers() {
      const mapSource = this.map.getSource('postalCodes');

      if (!mapSource) {
        // Ajout des sources
        this.map.addSource('postalCodes', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: this.mapFeatures,
          },
        });
      } else {
        mapSource.setData({
          type: 'FeatureCollection',
          features: this.mapFeatures,
        });
      }

      // On centre la carte sur un terrain
      if (this.mapFeatures.length === 1 && this.isFirstLoad) {
        this.map.flyTo({
          center: [
            this.mapFeatures[0].geometry.coordinates[0],
            this.mapFeatures[0].geometry.coordinates[1],
          ],
          zoom: 9,
        });
      }

      // On centre la carte sur l'ensemble des terrains
      if (this.mapFeatures.length > 1) {
        const coordinates = [];

        this.mapFeatures.forEach((ft) => {
          coordinates.push(ft.geometry.coordinates);
        });

        const line = turf.lineString(coordinates);
        const bbox = turf.bbox(line);
        if (this.isFirstLoad) {
          this.map.fitBounds(bbox, { padding: 40, duration: 300 });
        }
      }
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
      }
    },

    // Création de l'objet feature pour les sources de point
    setMapFeatures() {
      if (this.postalCodes && this.postalCodes.length) {
        this.mapFeatures = [];

        if (this.markers && this.markers.length) {
          this.markers.map((marker) => {
            if (marker) {
              marker.remove();
            }
            return null;
          });
          this.markers = [];
        }

        this.postalCodes.forEach((postalCode) => {
          if (
            postalCode
            && postalCode.borders
            && postalCode.borders.geometry
            && postalCode.borders.geometry.coordinates
            && postalCode.borders.geometry.coordinates[0]
            && postalCode.borders.geometry.coordinates[0][0]
          ) {
            let coordinates = null;
            let secondCoordinates = null;
            let pointCount = null;
            let index = null;

            if (postalCode.borders.geometry.type === 'MultiPolygon' && postalCode.borders.geometry.coordinates[0][0][0]) {
              [[[coordinates]]] = postalCode.borders.geometry.coordinates;

              pointCount = postalCode.borders.geometry.coordinates[0][0].length;
              index = Math.round(pointCount / 2);
              secondCoordinates = postalCode.borders.geometry.coordinates[0][0][index];
            } else {
              [[coordinates]] = postalCode.borders.geometry.coordinates;
              pointCount = postalCode.borders.geometry.coordinates[0].length;
              index = Math.round(pointCount / 2);
              secondCoordinates = postalCode.borders.geometry.coordinates[0][index];
            }

            // Récupération du centre du polygone, avec le premier point, et celui à mi chemin
            const center = [
              (secondCoordinates[0] + coordinates[0]) / 2,
              (secondCoordinates[1] + coordinates[1]) / 2,
            ];

            this.mapFeatures.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [center[0], center[1]],
              },
            });

            const el = document.createElement('div');
            el.id = 'marker';

            const popupClass = this.selectedPostalCode && this.selectedPostalCode.postalCodeInseeNumber === postalCode.postalCodeInseeNumber ? 'selected' : 'not-selected';

            // On créer des popups non fermable pour afficher les infos sur les villes
            // On ajoute la popup a un marker avec la bonne position
            const marker = new mapboxgl
              .Marker(el)
              .setLngLat(center);

            const popup = new mapboxgl
              .Popup({ closeOnClick: false, closeButton: false, className: popupClass })
              .setHTML(`
                <h4>${postalCode.city}</h4>
                <p>${postalCode.landsCount} terrain${postalCode.landsCount > 1 ? 's' : ''} à partir de ${utils.formatCentToEuro(postalCode.minimumPrice, true)}</p>
            `);

            marker
              .setPopup(popup)
              .addTo(this.map)
              .togglePopup();

            if (!this.markers) {
              this.markers = [];
            }

            this.markers.push(marker);

            // on ajoute un évènement sur le click du marker
            popup.getElement().addEventListener('click', () => {
              this.cityClick(postalCode);
            });
          }
        });
        this.setLayers();
      }
    },
    cityClick(postalCode) {
      // On propage l'évènement du click sur un marker
      this.$emit('input', postalCode);
    },
  },
};
</script>

<style lang="sass">
.matching-map
  background-color: $off-white
  box-shadow: $drop-min
  border-radius: $global-border-radius
  height: calc(100vh - 280px)
  .map-container
    position: relative
    display: flex
    align-items: center
    height: 100%
  .map-loader
    margin: auto
    p
      padding-top: 20px
      color: $info
  .map-hidden
    visibility: hidden

  #map
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    overflow: hidden
    z-index: 0
    canvas
      width: 100% !important
      border-radius: $global-border-radius
    .mapboxgl-canvas
      cursor: default
    .selected
      .mapboxgl-popup-content
        background-color: $primary
      p, h4
        color: white
    .selected.mapboxgl-popup-anchor-right .mapboxgl-popup-tip
        border-left-color: $primary
    .selected.mapboxgl-popup-anchor-left .mapboxgl-popup-tip
        border-right-color: $primary
    .selected.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip
        border-top-color: $primary
    .selected.mapboxgl-popup-anchor-top .mapboxgl-popup-tip
        border-bottom-color: $primary
    .selected, .not-selected
      cursor: pointer
</style>
