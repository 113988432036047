<template>
  <div class="sign-up hedifys-21 grid-x">
    <div class="form-container">
      <div>
        <div class="row">
          <h1>Bienvenue sur Hedifys</h1>
        </div>
        <div class="row">
          <h3>Nous avons besoin de quelques informations complémentaires pour créer votre compte.</h3>
        </div>
        <form @submit.prevent="createMember">
          <div class="row">
            <app-select label="Genre" v-model="member.gender" :options="genders" required />
          </div>
          <div class="row">
            <app-input type="text" label="Nom" placeholder="Nom..." v-model="member.lastname" required />
          </div>
          <div class="row">
            <app-input type="text" label="Prénom" placeholder="Prénom..." v-model="member.firstname" required />
          </div>
          <div class="row">
            <app-input type="text" label="Téléphone" placeholder="Téléphone..." v-model="member.phone" required />
          </div>
          <div class="row">
            <app-select label="Poste" v-model="member.jobTitle" :options="jobs" required />
          </div>
          <div class="row">
            <app-input type="password" label="Mot de passe" placeholder="Mot de passe..." v-model="member.password" required />
          </div>
          <div class="row">
            <app-button theme="primary" type="submit">Confirmer mon inscription</app-button>
          </div>
        </form>
      </div>
    </div>
    <div class="logo-container">
      <div >
        <hedifys />
        <h1>HEDIFYS</h1>
      </div>
      <div class="version">
        <span>VERSION</span>
        <span class="bubble">BETA</span>
      </div>
    </div>
  </div>
</template>

<script>
import hedifys from '@/assets/logos/hedifys.svg?inline';
import genders from '@/services/data/gender.json';
import member from '@/services/api/member';
import agencyJobs from '@/services/data/agencyJobs.json';
import brandJobs from '@/services/data/brandJobs.json';

export default {
  name: 'sign-up',
  components: {
    hedifys,
  },
  metaInfo: {
    title: 'Créer son compte',
  },
  data() {
    return {
      member: {},
      genders,
      jobs: [...agencyJobs, ...brandJobs],
    };
  },
  methods: {
    async createMember() {
      try {
        await member.registerNewMember(this.$route.query.token, this.member);
        this.$displayNotification('Inscription réussie. Vous pouvez vous connecter dès maintenant.');

        // Redirection vers la page d'accueil
        this.$router.replace({ name: 'login' });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l’inscription, vérifiez que tous les champs sont bien valides',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.sign-up
  height: 100%
  min-height: 750px
  flex-wrap: wrap-reverse
  h3
    color: $label
  .row
    @include row
  .form-container
    @include centered-container
    position: relative
    flex-basis: 40rem
    flex-grow: 1
    min-width: 700px
    > div
      width: 90%
      max-width: 420px
      margin: 0 auto
    .copyright
      position: absolute
      bottom: 24px
      text-align: center
      p
        color: $info
    button
      width: 100%
    a
      @include hyperlink
      text-align: center
      width: 100%
      display: block
  .logo-container
    @include centered-container
    background-image: linear-gradient($gradient-light, $gradient-dark)
    flex-basis: 0
    flex-grow: 999
    position: relative
    svg
      width: 135px
    h1
      color: $white
      font-weight: 300
      text-align: center
      margin-top: 16px
    .version
      position: absolute
      bottom: 24px
      > span
        color: $white
      .bubble
        background-color: $white
        border-radius: 15px
        margin-left: 8px
        color: $title
        padding: 5px 10px
</style>
