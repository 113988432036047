<template>
  <div class="document-space">
    <!-- HEADER / TITRE -->
    <div class="header grid-x grid-margin-x">
      <div class="cell auto">
        <h1>Espace Docs</h1>
      </div>
      <div class="cell shrink"  v-if="isAdmin">
        <app-button theme="primary" size="large" icon="add" @click="openAddDocumentModal">Ajouter un document</app-button>
      </div>
      <div class="cell shrink" v-if="isAdmin">
        <app-button theme="primary" size="large" icon="add" @click="openAddSubCategoryModal">Ajouter une sous-rubrique</app-button>
      </div>
    </div>
    <!-- BODY -->
    <div class="body">
      <!-- LISTE DERNIERS DOCUMENTS -->
      <h3>Derniers documents</h3>
      <app-table :headers="tableHeaders" :data="lastDocuments" :loading="isLoading">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.documentFilename }}</strong>
        </template>
        <template slot="creation-date" slot-scope="{ data }">
          <strong>{{ $dayjs(data.documentCreatedAt).format('DD/MM/YYYY') }}</strong>
        </template>
        <template slot="type" slot-scope="{ data }">
          <strong>{{ data.documentMimetype }}</strong>
        </template>
        <template slot="category" slot-scope="{ data }">
          <strong>{{ data.documentCategoryName }}</strong>
        </template>
        <template slot="sub-category" slot-scope="{ data }">
          <strong>{{ data.documentSubCategoryName }}</strong>
        </template>
        <template slot="more" slot-scope="{ data }">
          <div class="buttons-container">
            <a :href="data.documentUrl" target="_blank">
              <link-to-file />
            </a>
            <more-options v-if="isAdmin" :id="data.documentId" @update="openUpdateDocumentModal" @delete="deleteDocument"/>
          </div>
        </template>
      </app-table>
      <!-- LISTE RUBRIQUES -->
      <section class="doc-categories-section">
        <h3>Rubriques</h3>
        <div class="doc-categories-container">
          <div v-for="category in categories" :key="category.documentCategoryId">
            <router-link class="cell shrink nav-item" :to="{ name: 'documents-space-documents', params: {documentCategoryId: category.documentCategoryId} }">
              <div class="title">{{category.name}}</div>
            </router-link>
          </div>
        </div>
      </section>
    </div>
    <!-- MODALE AJOUT DE DOCUMENT -->
    <app-modal class="modal" :show="isAddDocumentModalOpen" size="medium" title="Ajouter un document" @update:show="isAddDocumentModalOpen = false;fileRequiredMessage = false;">
      <form ref="document" @submit.prevent="createDocument">
        <div>
          <app-input-file accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg" @upload="addFile" @remove="removeFile"/>
          <span class="info-label">Fichier .pdf, .xls, .jpg, .png. 10Mo max</span>
        </div>
        <app-label v-if="fileRequiredMessage" class="error">Le fichier est obligatoire</app-label>
        <app-select label="Rubrique" :value="null" v-model="categoryId" :options="categoriesOptions" required/>
        <app-select label="Sous-rubrique" :value="null" v-model="subCategoryId" :options="subCategoriesOptions" :disabled="subCategoriesOptions.length <= 1" required/>
        <app-button class="submit-btn" theme="primary" size="large" icon="add" type="submit">Ajouter</app-button>
      </form>
    </app-modal>
    <!-- MODALE MODIFICATION DE DOCUMENT -->
    <app-modal class="modal" :show="isUpdateDocumentModalOpen" size="medium" title="Modifier un document" @update:show="isUpdateDocumentModalOpen = false">
      <form ref="document" @submit.prevent="updateDocument">
        <app-select label="Rubrique" v-model="categoryId" :options="categoriesOptions" required/>
        <app-select label="Sous-rubrique" :value="null" v-model="subCategoryId" :options="subCategoriesOptions" :disabled="subCategoriesOptions.length <= 1" required/>
        <app-button class="submit-btn" theme="primary" size="large" icon="add" type="submit">Modifier</app-button>
      </form>
    </app-modal>
    <!-- MODALE AJOUT DE SOUS-RUBRIQUE -->
    <app-modal class="modal" :show="isAddSubCategoryModalOpen" size="small" title="Ajouter une sous-rubrique"
      @update:show="isAddSubCategoryModalOpen = false">
      <form ref="subCategory" @submit.prevent="createSubCategory">
        <app-select label="Rubrique" :value="null" v-model="categoryId" :options="categoriesOptions" required/>
        <app-input v-model="subCategoryName" label="Sous-rubrique" placeholder="Nom de la sous-rubrique..." pattern="^(?!\s*$).+" required />
        <app-button class="submit-btn" theme="primary" size="large" type="submit">Ajouter</app-button>
      </form>
    </app-modal>
  </div>
</template>

<script>
// Services
import documentCategoryApi from '@/services/api/documentCategory';
import documentSubCategoryApi from '@/services/api/documentSubCategory';
import documentApi from '@/services/api/document';
import memberApi from '@/services/api/member';
import auth from '@/services/auth';
import utils from '@/services/utils/utils';

// Components
import linkToFile from '@/assets/img/link-to-file.svg?inline';
import MoreOptions from './MoreOptions.vue';

export default {
  name: 'documents-space',
  components: {
    MoreOptions,
    linkToFile,
  },
  data() {
    return {
      isAdmin: false,
      subCategoryName: null,
      categories: [],
      subCategories: [],
      tableHeaders: [
        { label: 'Nom du document', key: 'name', size: 3 },
        { label: 'Date de l\'ajout', key: 'creation-date', size: 2 },
        { label: 'Type', key: 'type', size: 1 },
        { label: 'Rubrique', key: 'category', size: 3 },
        { label: 'Sous-rubrique', key: 'sub-category', size: 2 },
        { label: '', key: 'more', size: 1 },
      ],
      // limit: 10,
      isLoading: false,
      isAddDocumentModalOpen: false,
      isUpdateDocumentModalOpen: false,
      isAddSubCategoryModalOpen: false,
      brandId: null,
      file: null,
      lastDocuments: null,
      categoryId: null,
      subCategoryId: null,
      documentToEdit: null,
      fileRequiredMessage: false,
    };
  },
  async created() {
    await this.getData();
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    categoriesOptions() {
      return utils.formatOptions(
        this.categories,
        (option) => option.documentCategoryId,
        (option) => option.name,
        'choose',
      );
    },
    subCategoriesOptions() {
      return utils.formatOptions(
        this.subCategories.filter((subCategory) => subCategory.documentCategoryId === this.categoryId),
        (option) => option.documentSubCategoryId,
        (option) => option.name,
        'choose',
      );
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.getMe();
      this.getCategories();
      this.getSubCategories();
      this.getLastDocuments();
      this.isLoading = false;
    },
    async getCategories() {
      try {
        this.categories = await documentCategoryApi.getAll();
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des rubriques',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMe() {
      try {
        const { isBrandAdmin } = await memberApi.getMe();
        this.isAdmin = isBrandAdmin;
        this.brandId = await auth.getBrandId();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getSubCategories() {
      try {
        this.subCategories = await documentSubCategoryApi.getAllSubCategories();
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des sous-rubriques',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLastDocuments() {
      try {
        this.lastDocuments = await documentApi.getLastDocuments();
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des documents',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async openUpdateDocumentModal(documentId) {
      this.documentToEdit = this.lastDocuments.find((doc) => doc.documentId === documentId);
      this.categoryId = this.documentToEdit.documentCategoryId;
      this.subCategoryId = this.documentToEdit.documentSubCategoryId;
      this.isUpdateDocumentModalOpen = true;
    },
    async addFile(file) {
      if (this.fileRequiredMessage) {
        this.fileRequiredMessage = false;
      }
      this.file = file;
    },
    async removeFile() {
      this.file = null;
      this.filename = null;
      this.url = null;
    },
    async createSubCategory() {
      this.isLoading = true;
      try {
        await documentSubCategoryApi.createSubCategory({ name: this.subCategoryName.trim(), documentCategoryId: this.categoryId });
        this.$notification.show({ text: 'Sous-rubrique créée avec succès !' });
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la création de la sous-rubrique',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isAddSubCategoryModalOpen = false;
      this.getSubCategories();
      this.isLoading = false;
    },

    async openAddDocumentModal() {
      this.file = null;
      this.categoryId = null;
      this.isAddDocumentModalOpen = true;
    },
    async openAddSubCategoryModal() {
      this.categoryId = null;
      this.subCategoryName = null;
      this.isAddSubCategoryModalOpen = true;
    },
    async updateDocument() {
      this.isLoading = true;
      try {
        await documentApi.updateDocument(this.documentToEdit.documentId, this.subCategoryId);
        this.$notification.show({ text: 'Document modifié avec succès !' });
      } catch {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la modification du document',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isUpdateDocumentModalOpen = false;
      this.getLastDocuments();
      this.isLoading = false;
    },
    async deleteDocument(documentId) {
      this.$message.show({
        title: 'Supprimer document',
        text: 'Êtes-vous sûr de vouloir supprimer ce document ?',
        confirmText: 'Continuer',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: async () => {
          this.isLoading = true;
          try {
            await documentApi.deleteDocument(this.brandId, documentId);
            this.$notification.show({ text: 'Document supprimé avec succès !' });
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de la suppression du document',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
          this.getLastDocuments();
          this.isLoading = false;
        },
      });
    },
    async createDocument() {
      if (!this.file) {
        this.fileRequiredMessage = true;
        return;
      }
      this.isLoading = true;
      try {
        await documentApi.createDocument(this.brandId, this.file, this.subCategoryId);
        this.$notification.show({ text: 'Document créé avec succès !' });
      } catch (er) {
        if (er.message.includes('413')) {
          this.$message.show({
            title: 'Erreur',
            text: 'Le fichier saisi est trop volumineux,  10 Mo maximum',
            cancelText: 'Ok',
            hasCancel: true,
          });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la création du document',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
      this.isAddDocumentModalOpen = false;
      this.getLastDocuments();
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass'>
.document-space
  .body
    @include screen
  >.header
    @include header

.document-space
  .app-table
    .data
      overflow: visible

    .buttons-container
      display: flex
      flex-flow: row nowrap
      justify-content: center
      align-items: center
      position: relative

  .modal
    form
      height: 300px
      display: flex
      flex-flow: column nowrap
      justify-content: space-evenly
      margin: 6rem auto
      padding: 0 50px

      .app-input
        margin: 20px 0
      .submit-btn
        width: 10rem
        margin-top: 1rem!important
        margin: 0 auto
      .info-label
        color: $label
      .error
        color: $red

  .doc-categories-section
    margin-top: 2rem

  .doc-categories-container
    width: 100%
    display: flex
    flex-flow: row wrap
    justify-content: center
    align-items: center

    .nav-item
      background-color: $primary
      padding: 16px 15px
      margin: 12px
      border-radius: $global-border-radius
      transition: background-color 0.2s
      width: 130px
      height: 50px
      cursor: pointer
      @include centered-container
      &:hover
        background-color: darken($primary, 2%)
      .title
        color: white
        @include heading-md
        text-align: center

</style>
