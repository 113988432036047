<template>
  <div class="build-filters hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Liste des gabarits</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="openCreateModal">Créer un gabarit</app-button>
      </div>
    </div>
    <!-- BODY / FORMULAIRE -->
    <div class="body" v-if="!isLoading">
      <section class="hedifys-chantier" id="build-filters">
        <vk-grid class="new-planning" gutter="collapse">
          <div class="uk-width-1-3@m">
            <label>Filtrer les gabarits</label>
            <select v-if="filterBrandsAgencies" class="uk-select" v-model="selectedBrandAgency">
              <option :value="null" v-if="me.isBrandAdmin">Tout</option>
              <optgroup label="Marques" v-if="filterBrandsAgencies && filterBrandsAgencies.filter(f => f.type === 'brand') && filterBrandsAgencies.filter(f => f.type === 'brand').length > 0">
                <template v-for="(element, index) in filterBrandsAgencies">
                  <option v-if="element.type === 'brand'"
                    :value="{id: element.id, name: element.name, type: element.type}"
                    :key="`build-filters-brand-option-${index}`">{{ element.name }}
                  </option>
                </template>
              </optgroup>
              <optgroup label="Agences" v-if="filterBrandsAgencies && filterBrandsAgencies.filter(f => f.type === 'agency') && filterBrandsAgencies.filter(f => f.type === 'agency').length > 0">
                <template v-for="(element, index) in filterBrandsAgencies">
                  <option v-if="element.type === 'agency'"
                    :value="{id: element.id, name: element.name, type: element.type}"
                    :key="`build-filters-agency-option-${index}`">{{ element.name }}
                  </option>
                </template>
              </optgroup>
            </select>
          </div>
          <div class="warning-container">
            <div class="warning-logo-block">
              <warning class="logo"/>
              <p class="warning-text bold">Attention :</p>
            </div>
            <p class="warning-text">Un gabarit indiquant une durée de 0J risque d’entrainer des bugs. Pour être conforme, vous devez systématiquement y rajouter une tâche “Ouverture de chantier” et “Livraison de chantier”.</p>
          </div>
        </vk-grid>

        <vk-grid class="data" gutter="collapse">
          <div class="types uk-width-1-1@m">
            <vk-grid class="header" gutter="collapse">
              <div class="uk-text-left uk-width-expand@m">
                Titre
              </div>
              <div class="uk-text-left uk-width-1-5@m">
                Durée
              </div>
              <div class="uk-width-1-5@m">

              </div>
            </vk-grid>
            <div class="content">
              <vk-grid gutter="collapse" class="uk-child-width-expand type" v-for="buildFilterRow in filteredBuildFilters" :key="buildFilterRow.buildFilterId">
                <div class="uk-text-left uk-width-expand@m">
                  <span class="uk-text-bold">{{ buildFilterRow.label }} {{ buildFilterRow.code ? `(${buildFilterRow.code})` : '' }}</span>
                </div>
                <div class="uk-text-left uk-width-1-5@m">
                  {{ `${buildFilterRow.duration}J` }}
                </div>
                <div class="uk-width-1-5@m">
                  <button class="button" @click="openDuplicateModal(buildFilterRow)">
                    <img src="/duplicate.svg" alt="">
                  </button>
                  <button class="button"
                    v-if="me.isBrandAdmin || (me.isAgencyAdmin && buildFilterRow.agencyId)"
                    @click="() => openDeleteModal(buildFilterRow)">
                    <img src="/delete.svg" alt="">
                  </button>
                  <router-link class="button setting-button" :to="{
                    name: 'projects-build-filters-buildFilterId',
                    params: {
                      buildFilterId: buildFilterRow.buildFilterId,
                    } }">
                    <img src="/view-planning.svg" alt="">
                  </router-link>
                  <button class="button" v-if="me.isBrandAdmin || (me.isAgencyAdmin && buildFilterRow.agencyId)" @click="openEditModal(buildFilterRow)">
                    <img src="/edit.svg" alt="">
                  </button>
                </div>
              </vk-grid>
            </div>
          </div>
        </vk-grid>

        <vk-modal class="project-modal" :show.sync="showDeleteModal">
          <vk-modal-title>Supprimer le gabarit</vk-modal-title>
          <vk-grid gutter="medium">
            <div class="uk-width-1-1">
              Êtes-vous sûr de vouloir supprimer ce gabarit ?
            </div>
            <div class="uk-width-1-2">
              <button @click="deleteFilter">Supprimer</button>
            </div>
            <div class="uk-width-1-2">
              <button @click="closeDeleteModal">Annuler</button>
            </div>
          </vk-grid>
        </vk-modal>

        <vk-modal :show.sync="modalFilter" class="build-filter-modal">
          <vk-modal-close @click="modalFilter = false"></vk-modal-close>
          <vk-modal-title>{{modalMode === 'create' ? 'Nouveau plan de tâche' : modalMode !== 'edit' ? 'Dupliquer' : 'Modifier' }}</vk-modal-title>
          <form @submit.prevent="validateForm" class="content">
            <vk-grid gutter="collapse">
              <div class="uk-width-1-2@m">
                <div class="content">
                  <label>Niveau *</label>
                  <select class="uk-select" v-model="buildFilterLabel.level" :disabled="modalMode === 'duplicate'">
                    <option :value="null">Non renseigné</option>
                    <option
                      :value="level"
                      v-for="level in labelData.levels"
                      :key="level">{{ level }}
                    </option>
                  </select>
                </div>
                <div class="content">
                  <label>Toiture *</label>
                  <select class="uk-select" v-model="buildFilterLabel.roof" :disabled="modalMode === 'duplicate'">
                    <option :value="null">Non renseigné</option>
                    <option
                      :value="roof"
                      v-for="roof in labelData.roofings"
                      :key="roof">{{ roof }}
                    </option>
                  </select>
                </div>
                <div class="content">
                  <label>Chauffage *</label>
                  <select class="uk-select" v-model="buildFilterLabel.heating" :disabled="modalMode === 'duplicate'">
                    <option :value="null">Non renseigné</option>
                    <option
                      :value="heating"
                      v-for="heating in labelData.heatings"
                      :key="heating">{{ heating }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="uk-width-1-2@m">
                <div class="content">
                  <label>Editeur *</label>
                  <select class="uk-select" v-model="buildFilterLabel.editor" @change="editorChanged">
                    <option
                      :value="editor.agencyId"
                      v-for="editor in labelData.editors.filter(e => e.agencyId)"
                      :key="editor.agencyId">{{ editor.name }}
                    </option>
                    <option
                      :value="editor.brandId"
                      v-for="editor in labelData.editors.filter(e => e.brandId && !e.agencyId)"
                      :key="editor.brandId">{{ editor.name }}
                    </option>
                  </select>
                </div>
                <div class="content">
                  <label>Suffixe (optionnel)</label>
                  <input type="text" v-model="buildFilterLabel.suffix" :disabled="modalMode === 'duplicate'">
                </div>
              </div>
            </vk-grid>
            <div class="uk-text-right">
              <vk-button class="secondary button" htmlType="button" @click="closeModal">Annuler</vk-button>
              <vk-button v-if="modalMode !== 'duplicate'" class="primary button" htmlType="submit">{{modalMode === 'edit' ? 'Modifier' : 'Ajouter' }}</vk-button>
              <vk-button v-else class="primary button" htmlType="submit">Dupliquer</vk-button>
            </div>
          </form>
        </vk-modal>
      </section>
    </div>
    <div class="body" v-else>
      <div class="card spinner-container">
        <app-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import ls from 'local-storage';
import buildFilter from '@/services/api/buildFilter';
import member from '@/services/api/member';
import auth from '@/services/auth';
import agency from '@/services/api/agency';
import warning from '@/assets/img/warning.svg?inline';

export default {
  name: 'build-filters',
  components: { warning },
  metaInfo: {
    title: 'Liste des gabarits',
  },
  data() {
    return {
      buildFilters: [],
      filteredBuildFilters: [],
      filterBrandsAgencies: [],
      modalFilter: false,
      me: null,
      buildFilter: {
        label: '',
        code: null,
        agencyId: null,
      },
      buildFilterLabel: {
        level: null,
        roof: null,
        heating: null,
        editor: null,
        suffix: null,
        code: null,
      },
      labelData: {
        levels: ['Plain-pied', 'Étage'],
        roofings: ['Tuiles', 'Ardoise', 'Toits plats', 'Bacs acier', 'Mixtes'],
        heatings: ['PAC Air/Eau + PC', 'PAC Air/Eau', 'GAZ + PC', 'GAZ', 'PAC Air/Air', 'Poêle pellets'],
        editors: [],
      },
      agencies: [],
      brand: null,
      modalMode: null,
      selectedBrandAgency: null,
      isLoading: true,
      showDeleteModal: false,
      filterToDelete: null,
    };
  },
  async mounted() {
    await this.getMe();
    await this.getBuildFilters();
    this.filteredBuildFilters = this.buildFilters;
    await this.getAgencies();
    this.getFilterAgencies();

    this.brand = await auth.getBrand();

    if (this.me.isBrandAdmin) {
      this.labelData.editors = [this.brand];
    }
    this.labelData.editors = this.labelData.editors.concat(this.agencies);
    this.buildFilterLabel.editor = this.getEditorId();

    const filterValue = ls('FILTER_BUILDFILTERS');

    if (filterValue) {
      if (this.filterBrandsAgencies.find((fba) => fba.id === filterValue.id)) {
        this.selectedBrandAgency = filterValue;
      }
    } else {
      // On définit par défaut le filtre sur la 1ère agence de l'utilisateur trouvé, si non on le définit sur tous
      const filterAgency = this.filterBrandsAgencies.find((brandAgency) => brandAgency.type === 'agency');
      this.selectedBrandAgency = filterAgency || null;
    }

    this.isLoading = false;
  },
  watch: {
    selectedBrandAgency() {
      this.filterBuildFiltersByAgency();
    },
  },
  methods: {
    getEditorId() {
      return this.buildFilter.agencyId ? this.buildFilter.agencyId : this.buildFilter.brandId;
    },
    getEditorName() {
      if (this.buildFilter.agencyId) {
        const editor = this.agencies.find((a) => a.agencyId === this.buildFilter.agencyId);
        return editor.name;
      }
      return this.brand.name;
    },
    editorChanged(value) {
      if (this.brand && this.brand.brandId === value.target.value) {
        this.buildFilter.brandId = this.brand.brandId;
        this.buildFilter.agencyId = null;
      } else {
        this.buildFilter.agencyId = value.target.value;
      }
    },
    isBuildFilterInit() {
      const buildFilterLabel = this.buildFilter.label.split('_');
      return this.labelData.levels.includes(buildFilterLabel[0])
        && this.labelData.roofings.includes(buildFilterLabel[1])
        && this.labelData.heatings.includes(buildFilterLabel[2]);
    },
    async getMe() {
      try {
        this.me = await member.getMe();
      } catch (error) {
        throw error;
      }
    },
    async getBuildFilters() {
      try {
        this.buildFilters = await buildFilter.getAll();
        this.buildFilters = this.buildFilters.sort((a, b) => a.label.localeCompare(b.label));
      } catch (error) {
        this.$displayNotification('L\'obtention des données des plans de maison a échouée.');
      }
    },
    async getAgencies() {
      try {
        const allAgencies = await agency.getAll();
        this.agencies = allAgencies.filter((a) => this.me.rules.find((rule) => ((a.agencyId === rule.agencyId && rule.type === 'ADMIN') || rule.brandId)));
      } catch (error) {
        this.$displayNotification('L\'obtention des données des agences a échouée.');
      }
    },
    getFilterAgencies() {
      this.filterBrandsAgencies = [];
      this.buildFilters.forEach((currentBuildFilter) => {
        if (!this.filterBrandsAgencies.find((bf) => (currentBuildFilter.brandId === bf.id)) && !currentBuildFilter.agencyId) {
          this.filterBrandsAgencies.push({
            id: currentBuildFilter.brandId,
            name: currentBuildFilter.brandName,
            type: 'brand',
          });
        }

        if (!this.filterBrandsAgencies.find((bf) => currentBuildFilter.agencyId === bf.id) && currentBuildFilter.agencyId) {
          this.filterBrandsAgencies.push({
            id: currentBuildFilter.agencyId,
            name: currentBuildFilter.agencyName,
            type: 'agency',
          });
        }
      });
    },
    openDeleteModal(filter) {
      this.filterToDelete = filter;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.filterToDelete = null;
      this.showDeleteModal = false;
    },
    openDuplicateModal(filter) {
      this.modalMode = 'duplicate';
      this.openModal(filter);
    },
    openEditModal(filter) {
      this.modalMode = 'edit';
      this.openModal(filter);
    },
    openModal(filter) {
      this.buildFilter = {
        buildFilterId: filter.buildFilterId,
        brandId: filter.brandId,
        agencyId: filter.agencyId,
        label: filter.label,
        code: null,
      };
      const buildFilterLabel = filter.label.split('_');

      if (this.isBuildFilterInit()) {
        const buildFilterCopy = buildFilterLabel.splice(0, 4);
        this.buildFilterLabel = {
          level: buildFilterCopy[0],
          roof: buildFilterCopy[1],
          heating: buildFilterCopy[2],
          suffix: `${buildFilterLabel.join('_')}${filter.code ? `_${filter.code}` : ''}`,
          code: null,
        };
      } else {
        this.buildFilterLabel = {
          level: null,
          roof: null,
          heating: null,
          suffix: `${buildFilterLabel.join('_')}${filter.code ? `_${filter.code}` : ''}`,
          code: null,
        };
      }
      if (this.modalMode === 'duplicate') {
        if (this.agencies && this.agencies.length > 0 && this.agencies.find((a) => a.agencyId === this.buildFilter.agencyId)) {
          this.buildFilterLabel.editor = this.buildFilter.agencyId;
        } else if (this.agencies && this.agencies.length > 0 && !this.me.isBrandAdmin) {
          this.buildFilter.agencyId = this.agencies[0].agencyId;
          this.buildFilterLabel.editor = this.buildFilter.agencyId;
        } else {
          this.buildFilterLabel.editor = this.labelData.editors[0].brandId;
        }
      } else {
        this.buildFilterLabel.editor = this.buildFilter.agencyId ? this.buildFilter.agencyId : this.buildFilter.brandId;
      }

      this.modalFilter = true;
    },
    openCreateModal() {
      this.modalMode = 'create';
      this.buildFilterLabel = {
        level: null,
        roof: null,
        heating: null,
        editor: null,
        suffix: null,
        code: null,
      };
      if (this.agencies && this.agencies.length > 0 && !this.me.isBrandAdmin) {
        this.buildFilter.agencyId = this.agencies[0].agencyId;
        this.buildFilterLabel.editor = this.buildFilter.agencyId;
      } else {
        this.buildFilterLabel.editor = this.labelData.editors[0].brandId;
      }
      this.modalFilter = true;
    },
    closeModal() {
      this.modalFilter = false;
    },
    validateForm() {
      if (this.modalMode === 'duplicate') {
        this.duplicate(this.buildFilter);
      } else {
        this.saveFilter();
      }
    },
    async saveFilter() {
      // edition du build filter
      const label = `${this.buildFilterLabel.level}_${this.buildFilterLabel.roof}_${this.buildFilterLabel.heating}_${this.getEditorName()}${this.buildFilterLabel.suffix ? `_${this.buildFilterLabel.suffix}` : ''}`;
      this.buildFilter.label = label;

      if (this.modalMode === 'edit') {
        try {
          if (!this.buildFilterLabel.level || !this.buildFilterLabel.roof || !this.buildFilterLabel.heating || !this.buildFilterLabel.editor) throw new Error('Des champs obligatoires n\'ont pas été renseignés');
          await buildFilter.edit({
            buildFilterId: this.buildFilter.buildFilterId,
            brandId: this.buildFilter.brandId,
            agencyId: this.buildFilter.agencyId,
            label: this.buildFilter.label,
            code: this.buildFilter.code,
          });
          this.$displayNotification('L\'edition du plan de tâche a réussi.');
        } catch (error) {
          this.$displayNotification('L\'edition du plan de tâche a échoué.');
          this.$displayNotification(error.message);
        }

        await this.resetFilter();
      } else {
        // creation d'un nouveau build filter
        try {
          if (!this.buildFilterLabel.level || !this.buildFilterLabel.roof || !this.buildFilterLabel.heating || !this.buildFilterLabel.editor) throw new Error('Des champs obligatoires n\'ont pas été renseignés');
          delete this.buildFilter.buildFilterId;
          await buildFilter.create({ brandId: this.brand.brandId, ...this.buildFilter });
          this.$displayNotification('Ajout du nouveau plan de tâche réussi.');
        } catch (error) {
          this.$displayNotification('L\'ajout du nouveau plan de tâche a échoué.');
          this.$displayNotification(error.message);
        }

        await this.resetFilter();
      }
    },
    async deleteFilter() {
      try {
        const filter = this.filterToDelete;
        await buildFilter.delete(filter.buildFilterId);
        this.$displayNotification('Suppression du plan de tâche réussie.');
      } catch (error) {
        this.$displayNotification('La suppression du plan de tâche a échouée.');
        this.$displayNotification(error.message);
      }

      await this.resetFilter();
      this.showDeleteModal = false;
    },
    async duplicate(filter) {
      try {
        const label = `${this.buildFilterLabel.level}_${this.buildFilterLabel.roof}_${this.buildFilterLabel.heating}_${this.getEditorName()}${this.buildFilterLabel.suffix ? `_${this.buildFilterLabel.suffix}` : ''}`;
        this.buildFilter.label = label;

        await buildFilter.duplicate(filter.buildFilterId, filter.agencyId);
        this.$displayNotification('Duplication du plan de tâche réussie.');
      } catch (error) {
        this.$displayNotification('Duplication du plan de tâche a échouée.');
        this.$displayNotification(error.message);
      }

      await this.resetFilter();
    },
    async resetFilter() {
      try {
        await this.getBuildFilters();
      } catch (er) {
        this.$displayNotification('La récupération des plans de tâche a échoué');
      }
      this.filteredBuildFilters = this.buildFilters;

      this.getFilterAgencies();
      this.filterBuildFiltersByAgency();

      if (!this.filterBrandsAgencies.find((f) => this.selectedBrandAgency && f.id === this.selectedBrandAgency.id)) {
        this.selectedBrandAgency = null;
      }

      this.modalFilter = false;
    },
    filterBuildFiltersByAgency() {
      ls.set('FILTER_BUILDFILTERS', this.selectedBrandAgency);

      if (!this.selectedBrandAgency) this.filteredBuildFilters = this.buildFilters;
      else if (this.selectedBrandAgency.type === 'brand') this.filteredBuildFilters = this.buildFilters.filter((element) => (element.brandId && !element.agencyId) && element.brandId === this.selectedBrandAgency.id);
      else if (this.selectedBrandAgency.type === 'agency') this.filteredBuildFilters = this.buildFilters.filter((element) => element.brandId && element.agencyId === this.selectedBrandAgency.id);
    },
  },
};
</script>

<style lang='sass' scoped>
.build-filters
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .warning-container
    width: 100%
    margin-top: 1rem
    padding: 0.5rem 1rem 0.5rem 1rem
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-content: flex-start
    background-color: #F82D5E1A
    font-size: 0.9rem
    border-left: 4.5px solid #F45A5A
    border-top-right-radius: 5px
    border-bottom-right-radius: 5px
    p
      all: unset
      color: #F45A5A
    .bold
      font-weight: bold

    .warning-logo-block
      display: flex
      flex-flow: row nowrap
      align-items: center
      justify-content: flex-start
      margin-bottom: 0.5rem
      .logo
        font-size: 1.7rem
</style>

<style lang="scss">
#build-filters {
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-weight: 500;
    font-size: 25px;
    color: $gray-dark;
  }
  button, .setting-button {
    margin: 0 0 0 5px !important;
    border: none;
    img {
      height: 20px;
    }
  }
  .new-planning {
    margin-bottom: 30px;
    label {
      margin-bottom: 10px;
      color: $gray-regular;
      ~ select {
        margin-top: 10px;
      }
    }
    button {
      padding: 10px 25px;
      text-transform: uppercase;
      font-size: 0.9rem;
      border-radius: 30px;
      color: white;
      background-color: $blue-medium;
    }
  }
  .data {
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);
    background-color: #ffffff;
    .title {
      border-right: 1px solid $gray-regular;
      ul {
        padding: 0;
        text-align: left;
        li {
          padding: 20px 30px;
          text-transform: uppercase;
          ~ li {
            border-top: 1px solid $gray-regular;
          }
          &.active {
            color: $blue-medium;
          }
        }
      }
    }
    .types {
      .header {
        padding: 20px 30px;
        color: $gray-regular;
        border-bottom: 1px solid $gray-regular;
      }
      .content {
        &.type:not(:last-child) {
          border-bottom: 1px solid $gray-regular;
        }
        .type {
          padding: 20px 30px;
          align-items: center;
          ~ .type {
            border-top: 1px solid $gray-regular;
          }
        }
      }
      .button {
        margin-bottom: 10px;
        display: block;
        text-align: center;
        float: right;
      }
    }
  }
}
.build-filter-modal {
  input.label {
    margin-bottom: 20px;
  }
}
</style>
