var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(!_vm.$route.meta.print)?_c('div',{staticClass:"grid-x"},[(!_vm.$route.meta.hideSideNav)?_c('side-nav'):_vm._e(),_c('div',{staticClass:"page-container cell auto",class:{
        'print': _vm.$route.meta.print,
        'no-scroll':
          _vm.$route.name === 'customers-projects-drafts-new' ||
          _vm.$route.name === 'customers-projects-drafts-edit' ||
          _vm.$route.name === 'customers-projects-drafts-projectDraftId-edit' ||
          _vm.$route.name === 'customers-projects-drafts-new-configuration' ||
          _vm.$route.name === 'customers-projects-drafts-projectDraftId-configuration'
      }},[(!_vm.$route.meta.hideTopbar)?_c('account-nav'):_vm._e(),_c('div',{staticClass:"page",class:{'with-nav': !_vm.$route.meta.hideTopbar}},[_c('router-view')],1)],1)],1):_c('div',{staticClass:"print"},[_c('router-view')],1),_c('app-message'),_c('app-notifications'),_c('vk-notification',{attrs:{"messages":_vm.$root.notificationMessages,"timeout":3500},on:{"update:messages":function($event){return _vm.$set(_vm.$root, "notificationMessages", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }