<template>
  <div class="allotments hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Lotissements</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToAllotment">Ajouter un lotissement</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES LOTISSEMENTS -->
    <div class="body">
      <div class="grid-x">
        <div class="cell shrink search">
          <app-search size="small" v-model="search" placeholder="Rechercher un lotissement" />
        </div>
        <div class="cell small-2" v-if="isBrandAdmin">
          <app-select :value="null" v-model="agencyId" :options="agencies" @input="agencyChange" />
        </div>
        <div class="cell auto"></div>
      </div>
      <app-table :headers="tableHeaders" :data="allotments.data" :loading="isLoading" @line-cliked="goToAllotment" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="company" slot-scope="{ data }" >
          <p :class="{'no-registered-company-color' : !data.isCompanyRegistered}">{{ data.company ? data.company.name : null }}</p>
        </template>
        <template slot="landsCount" slot-scope="{ data }">
          <p>{{ `${data.activeLandsCount} / ${data.landsCount}`}}</p>
        </template>
        <template slot="postalCode" slot-scope="{ data }">
          <p>{{ data.address && data.address.postalCodeInseeNumber ? data.address.postalCodeInseeNumber.split('-')[0] : null }}</p>
        </template>
        <template slot="city" slot-scope="{ data }">
          <p>{{ data.address.city }}</p>
        </template>
        <template slot="agency" slot-scope="{ data }">
          <p>{{ data.agency && data.agency.agencyId ? data.agency.name : '-' }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY') }}</p>
        </template>
      </app-table>
      <app-pagination
        v-if="allotments.metadata"
        :limit="limit"
        :offset="allotments.metadata.offset"
        :count="allotments.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import allotmentApi from '@/services/api/allotment';
import utils from '@/services/utils/utils';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';

export default {
  name: 'allotments',
  metaInfo: {
    title: 'Lotissements',
  },
  data() {
    return {
      allotments: {},
      limit: 10,
      tableHeaders: [
        { label: 'Nom du lotissement', key: 'name', size: 2 },
        { label: 'Lotisseur', key: 'company', size: 'auto' },
        { label: 'Lots actifs', key: 'landsCount', size: 1 },
        { label: 'Code postal', key: 'postalCode', size: 2 },
        { label: 'Ville', key: 'city', size: 2 },
        { label: 'Agence', key: 'agency', size: 2 },
        { label: 'Créée le', key: 'createdAt', size: 1 },
      ],
      me: null,
      isBrandAdmin: null,
      agencyId: null,
      agencies: [],
      isLoading: true,
      search: null,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  async mounted() {
    this.isLoading = true;
    await this.getMe();
    this.isBrandAdmin = this.me.isBrandAdmin;
    this.search = this.$route.query.search;
    this.agencyId = this.$route.query.agencyId;

    await this.getAllotments(this.computeOffset);

    if (this.isBrandAdmin) {
      await this.getAgencies();
    }

    this.isLoading = false;
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getAllotments(this.computeOffset);
        this.isLoading = false;
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
  },
  methods: {
    goToAllotment(allotment) {
      if (allotment && allotment.allotmentId) {
        this.$router.push({ name: 'lands-allotments-allotmentId', params: { allotmentId: allotment.allotmentId } });
      } else {
        this.$router.push({ name: 'lands-allotments-new' });
      }
    },
    async getAllotments(offset) {
      try {
        this.allotments = await allotmentApi.getAll(this.limit, offset, this.search, null, this.agencyId);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lotissement',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll(this.search);
        const parsedAgencies = utils.formatOptions(
          agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agencies = parsedAgencies;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    agencyChange() {
      if (this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            agencyId: this.agencyId || undefined,
          },
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.allotments
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
    margin-right: 1rem
  .filter-btn
    margin-left: 1rem
  .form-btn
    margin-top: 40px
  .image
    object-fit: cover
    width: 130px
    height: 90px
  .no-registered-company-color
    color: red
</style>
