import axios from 'axios';
import dayjs from 'dayjs';

const { VUE_APP_API_URL } = process.env;

const getByProject = async (projectId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/projects/${projectId}/project-steps`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const edit = async (projectStep) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/project-steps/${projectStep.projectStepId}`, projectStep);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const add = async (projectStep, projectSteps) => {
  /* Décale toutes les tâches non-commencées, à partir d'une date */
  const filteredSteps = projectSteps.filter((step) => step.rank >= projectStep.rank);

  const allPromises = [];

  if (filteredSteps && filteredSteps.length > 0) {
    filteredSteps.forEach((step) => {
      const newProjectStep = {};
      newProjectStep.rank = step.rank + 1;
      newProjectStep.projectId = projectStep.projectId;
      newProjectStep.projectStepId = step.projectStepId;

      /* Envoyer la mise à jour au serveur */
      allPromises.push(edit(newProjectStep));
    });
  }
  await Promise.all(allPromises);

  try {
    const response = await axios.post(`${VUE_APP_API_URL}/project-steps`, projectStep);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const remove = async (projectStep) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/project-steps/${projectStep.projectStepId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const deleteAllByProjectId = async (projectId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/projects/${projectId}/project-steps`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getActive = async (companyCategoryId = null, type = null) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/project-steps`,
      { params: { ...(companyCategoryId && { companyCategoryId }), ...(type && { type }) } });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getByCompany = async (companyId, companyCategoryId = null, type = null, search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/companies/${companyId}/project-steps`,
      {
        params: {
          ...(companyCategoryId && { companyCategoryId }),
          ...(type && { type }),
          ...(search && { search }),
        },
      });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const delayFollowingProjectSteps = (startingStep, projectSteps, numberOfWeeks) => {
  /* Décale toutes les tâches non-commencées, à partir d'une date */
  const filteredSteps = projectSteps.filter((step) => step.rank > startingStep.rank && step.status === 'TODO');

  const allPromises = [];

  if (filteredSteps && filteredSteps.length > 0) {
    filteredSteps.forEach((step) => {
      const newProjectStep = {};
      newProjectStep.projectStepId = step.projectStepId;
      newProjectStep.startedAt = dayjs(step.startedAt).add(numberOfWeeks, 'week').format();
      newProjectStep.closedAt = dayjs(step.closedAt).add(numberOfWeeks, 'week').format();
      newProjectStep.confirmed = false;

      /* Envoyer la mise à jour au serveur */
      allPromises.push(edit(newProjectStep));
    });
  }
  return Promise.all(allPromises);
};

const getSchedule = async (companyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/companies/${companyId}/schedule`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getByWeek = async (year, week) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/years/${year}/weeks/${week}/project-steps`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const projectSteps = {};
projectSteps.getByProject = getByProject;
projectSteps.getByCompany = getByCompany;
projectSteps.edit = edit;
projectSteps.add = add;
projectSteps.getActive = getActive;
projectSteps.remove = remove;
projectSteps.delayFollowingProjectSteps = delayFollowingProjectSteps;
projectSteps.deleteAllByProjectId = deleteAllByProjectId;
projectSteps.getSchedule = getSchedule;
projectSteps.getByWeek = getByWeek;

export default projectSteps;
