<template>
  <div class="categories hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Liste des catégories</h1>
      </div>
    </div>
    <!-- BODY / LISTE DES CATÉGORIES -->
    <div class="body">
      <app-table :headers="tableHeaders" :data="categories" :loading="isLoading">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="icon" slot-scope="{ data }">
          <img v-if="data.icon" class="icon" :src="data.icon" />
          <div v-else class="icon not-found">
            <upload class="icon-upload" />
          </div>
        </template>
        <template slot="name" slot-scope="{ data }">
          <p class='bold'>{{ data.name }}</p>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import category from '@/services/api/category';
import upload from '@/assets/img/upload.svg?inline';

export default {
  name: 'categories',
  components: {
    upload,
  },
  metaInfo: {
    title: 'Liste des catégories',
  },
  data() {
    return {
      categories: null,
      tableHeaders: [
        { label: 'Visuel', key: 'icon', size: 1 },
        { label: 'Catégorie', key: 'name', size: 'auto' },
      ],
      isLoading: true,
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      this.isLoading = true;
      try {
        this.categories = await category.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.categories
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .bold
    @include paragraph-bold
  .icon
    width: 24px
    height: 24px
</style>
