<template>
  <div class="hedifys-21 app-message">
    <transition name="message">
    <div class="message-wrapper" v-if="visible">
      <div class="title">
        <h2>{{ title }}</h2>
      </div>
      <div class="grid-x grid-margin-x row">
        <div class="cell auto">
          <p class="text">{{ text }}</p>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell auto"></div>
        <div class="cell shrink grid-x">
          <div class="cell shrink">
            <app-button v-if="hasCancel" class="cancel" theme="secondary" size="large" @click="hide">
              {{ cancelText }}
            </app-button>
          </div>
          <div class="cell shrink">
            <app-button v-if="hasConfirm" @click="confirm" theme="warning" icon="remove" size="large">
              {{ confirmText }}
            </app-button>
          </div>
        </div>
        <div class="cell auto"/>
      </div>
    </div>
    </transition>
    <transition name="screen">
      <div class="message-screen" v-if="visible" @click="hide()"/>
    </transition>
  </div>
</template>

<script>
import AppEvent from '../utils/AppEvent';

export default {
  data() {
    return {
      visible: false,
      title: '',
      text: '',
      confirmText: 'Annuler',
      cancelText: 'Annuler',
      hasCancel: true,
      hasConfirm: false,

      onConfirm: {},
    };
  },
  methods: {
    hide() {
      this.visible = false;
      // document.documentElement.style.overflow = 'scroll';
    },
    confirm() {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm();
        this.hide();
      } else {
        this.hide();
      }
    },
    show(params) {
      this.visible = true;
      // document.documentElement.style.overflow = 'hidden';

      this.title = params.title;
      this.text = params.text;

      if (params.onConfirm) this.onConfirm = params.onConfirm;
      if (params.confirmText) this.confirmText = params.confirmText;
      if (params.cancelText) this.cancelText = params.cancelText;
      this.hasCancel = !!params.hasCancel;
      this.hasConfirm = !!params.hasConfirm;
    },
  },
  beforeMount() {
    AppEvent.$on('app-message-show', (params) => {
      this.show(params);
    });
  },
  beforeDestroy() {
    AppEvent.$off('app-message-show', (params) => {
      this.show(params);
    });
    // document.documentElement.style.overflow = 'scroll';
  },
};
</script>

<style lang="sass">
.app-message
  .row
    @include row
  .message-wrapper
    position: fixed
    top: 50%
    left: 50%
    width: 440px
    padding: 40px
    border-radius: $global-border-radius
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
    background: white
    z-index: 1000
    transform: translate(-50%, -50%)
    overflow: auto

  .message-screen
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba(0,0,0,0.4)
    z-index: 800

  .message-buttons
    display: flex
    margin-top: 2rem
    > .app-button
      flex-grow: 1

  .text
    text-align: center

  .title
      display: flex
      justify-content: center
      margin-bottom: 1.5rem

  .cancel
     margin-right: 16px

  .screen-enter-active, .screen-leave-active
    transition: opacity .9s

  .screen-enter, .screen-leave-to
    opacity: 0

  .message-enter-active, .message-leave-active
    transition: transform .5s, opacity .3s

  .message-enter, .message-leave-to
    opacity: 0
    transform: translateY(-130px) translateX(-50%)

</style>
