<template>
  <div class="config-app-search-city" :class="[size, color]">
    <span v-if="label" class="label" :class="{ mandatory: required }">{{ label }}</span>
    <form @submit.prevent="submitCity">
      <label>
        <div class="search-input grid-x">
          <position class="search" />
          <div class="cell large-auto medium-12 small-12">
            <input
                ref="search"
                type="text"
                v-model="search"
                :required="required"
                :placeholder="placeholder"
                :disabled="disabled"
                @input="updateSearch"
                autocomplete="no"
              />
            <cancel
              v-if="search && search.length"
              @click.prevent="clearSearch"
              class="cancel"
            />
            <transition name="app-search-city-list">
              <ul v-show="isOpen">
                <app-scrollable v-show="postalCodeOptions.length > 0">
                  <li
                    role="option"
                    v-for="option in postalCodeOptions"
                    :key="option.name"
                    @mousedown="selectOption($event, option)"
                    :class="{ 'clickable' : postalCodes.find((postalCode) => postalCode.postalCodeInseeNumber === option.name && postalCode.hasAgencies) }"
                  >
                    <span>{{ option.label }}</span>
                  </li>
                </app-scrollable>
                <app-scrollable v-show="!postalCodeOptions || !postalCodeOptions.length">
                  <li>
                    <span>Nous ne sommes pas présent dans cette commune</span>
                  </li>
                </app-scrollable>
              </ul>
            </transition>
          </div>
          <div class="cell large-shrink medium-12 small-12">
            <app-button type="submit" hasArrow></app-button>
          </div>
        </div>
      </label>
    </form>
  </div>
</template>

<script>
import position from '@/assets/img/position.svg?inline';
import cancel from '@/assets/img/cancel.svg?inline';
import postalCodeApi from '@/services/api/postalCode';
import utils from '@/services/utils/utils';

export default {
  name: 'app-search-city',
  components: {
    position,
    cancel,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Rechercher...',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedPostalCode: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
      default: 'large',
    },
    color: {
      type: String,
      validator: (val) => ['default', 'dark'].indexOf(val) !== -1,
      default: 'default',
    },
  },

  data() {
    return {
      isOpen: false,
      search: null,
      postalCodes: [],
      postalCodeOptions: [],
    };
  },

  watch: {
    search(value) {
      if (value === '') {
        this.clearSearch();
      }
    },
    selectedPostalCode(value) {
      this.search = value;
    },
  },

  created() {
    this.debouncedUpdateSearch = utils.debounce(this.getPostalCodes, 500);
  },

  mounted() {
    if (this.selectedPostalCode) {
      this.search = this.selectedPostalCode;
    }
  },

  computed: {
    input() {
      return this.value;
    },
  },

  methods: {
    async getPostalCodes() {
      try {
        // On vérifie si le champ est remplie, si non, on vide les anciens résultats
        if (this.search && this.search.length >= 3) {
          this.postalCodes = await postalCodeApi.getAllPublic(this.search);
          this.postalCodeOptions = utils.formatOptions(
            this.postalCodes,
            (option) => option.postalCodeInseeNumber,
            (option) => `${option.city} (${option.postalCodeInseeNumber.split('-')[0]})${!option.hasAgencies ? ' - Cette commune n\'est pas dans notre zone de construction' : ''}`,
          );
        } else if (!this.search || this.search.length === 0) {
          this.postalCodes = [];
          this.postalCodeOptions = [];
        }

        // On ouvre la liste des propositions si il y a des codes postaux de trouvés
        this.isOpen = this.search && this.search.length >= 3;
      } catch (error) {
        throw error;
      }
    },

    updateSearch() {
      this.debouncedUpdateSearch();
    },

    // Ouvre ou ferme la liste des options
    showOptions() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },

    // Vide le champ
    clearSearch() {
      this.selected = null;
      this.search = null;
      this.isOpen = false;
      this.$emit('clear');
    },

    // Sélectionne le code postal choisi
    selectOption(event, option) {
      const match = this.postalCodes.find((postalCode) => postalCode.postalCodeInseeNumber === option.name && postalCode.hasAgencies);
      if (match && event.button === 0) {
        this.selected = option;
        this.search = option.label;
        this.isOpen = false;
        this.$emit('select', this.selected);
      }
    },
    submitCity() {
      this.$emit('submit-city', this.input);
    },
  },
};
</script>

<style lang='sass' scoped>
.config-app-search-city
  display: flex
  flex-direction: column
  position: relative
  &.small
    width: 50% !important
    min-width: 240px !important
  &.medium
    width: 100%
    max-width: 480px
  &.large
    width: 100%
    .search
      transform: translateY(-50%) scale(1)
    .search-input
      input
        width: 100%
        padding: 16px 24px 16px 48px
        font-size: $font-sm

      button
        height: 52px
        // width: 80px
        width: 100%
  &.dark
    label .label
      color: $white !important
    input
      background-color: $subtitle
      border-color: $subtitle
      color: $white
    ul
      background-color: $subtitle
      li
        span
          color: $white
        &:hover
          background-color: $body-color
  .cancel
    cursor: pointer
    position: absolute
    bottom: 20px
    right: 90px
    width: 10px
    height: 10px
  label
    display: flex
    flex-direction: column
    position: relative
    width: 100%
  .label
    @include label
    color: $label
    padding-bottom: 4px
    &.mandatory:after
      content: "*"
      display: inline-block
      transform: translateX(0.3rem) translateY(-0.15rem)
      color: $label
  .search
    position: absolute
    left: 10px
    top: 50%
    transform: translateY(-50%) scale(0.75)
  .search-input
    display: flex
    align-items: center
    flex-wrap: wrap
    input
      @include paragraph
      box-sizing: border-box
      display: flex
      padding: 10px 16px 10px 42px
      background-color: $off-white
      border: 1px solid $line
      border-radius: 4px 0 0 4px
      border-right: none
      color: $body
      &:focus
        outline: none
      &:disabled
        background-color: $background
        color: $info
        pointer-events: none
        cursor: not-allowed
      &::placeholder
        color: $info
    .app-button
      height: 40px
      border-radius: 0 4px 4px 0
      margin-left: 0
      span
        padding-left: 0 !important
  ul
    position: absolute
    top: 100%
    left: 0
    margin-top: 4px
    padding: unset
    display: flex
    flex-direction: column
    background-color: $off-white
    color: $label
    box-shadow: $drop-med
    width: 100%
    max-height: 148px
    border-radius: 4px
    z-index: 999
    overflow: hidden
  li
    padding: 0.5rem 1rem
    overflow: hidden
    span
      @include paragraph
      font-weight: 300

  li.clickable
    cursor: pointer
    &:hover
      background-color: $loading
  li:not(.clickable)
    background-color: $loading

  .app-search-city-list-enter-active, .app-search-city-list-leave-active
    transition: transform .3s, opacity .3s, scale .3s ease-in-out

  .app-search-city-list-enter, .app-search-city-list-leave-to
    opacity: 0

  svg.search
    path
      fill: $primary

  // DESKTOP
  @media (min-width: 768px)
    .search-input input
      flex-basis: 65%
  // MOBILE
  @media (max-width: 768px)
    .search-input
      input, button
        flex-basis: 100%
        border-radius: 4px!important
      input
        margin-bottom: 16px
      svg.search
        top: calc(25% - 5px)
        path
          fill: $primary
      svg.cancel
        top: calc(25% - 8px)
        right: 20px
    ul
      top: 52px
</style>
