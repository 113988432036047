var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lots hedifys-21"},[_vm._m(0),_c('div',{staticClass:"body"},[_c('div',{staticClass:"search grid-x"},[_c('filter-modal',{staticClass:"filter-btn",attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return i !== null; }).length},on:{"change":_vm.filterLots,"reset":_vm.resetFilers,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-select',{attrs:{"label":"Catégorie","options":_vm.categoryOptions},model:{value:(_vm.filters.categoryId),callback:function ($$v) {_vm.$set(_vm.filters, "categoryId", $$v)},expression:"filters.categoryId"}})],1)])])],1),_c('app-table',{staticClass:"table",attrs:{"headers":_vm.tableHeader,"data":_vm.lots,"loading":_vm.isLoading,"clickable":true},on:{"line-cliked":_vm.openEditionModal},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var data = ref.data;
return [(data.image)?_c('img',{staticClass:"lot-illustration",attrs:{"src":data.image,"alt":"illustration"}}):_c('div',{staticClass:"lot-illustration not-found"},[_c('upload',{staticClass:"icon-upload"})],1)]}},{key:"name",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}},{key:"category",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.getCategoryName(data.categoryId)))])]}},{key:"description",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"description"},[_vm._v(_vm._s(data.description))])]}},{key:"lotsSurveysNames",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"text-uppercase"},[_vm._v(_vm._s(data.lotsSurveysNames))])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune donnée de disponible")])])],2),(_vm.editedLot)?_c('app-modal',{staticClass:"edition-modal",attrs:{"show":_vm.isEditionModalOpen,"size":"medium","title":"Modifier le lot"},on:{"update:show":function($event){return _vm.closeEditionModal()}}},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"image"},[(_vm.editedLot.image)?_c('img',{staticClass:"lot-illustration",attrs:{"src":_vm.editedLot.image,"alt":"illustration"}}):_c('div',{staticClass:"lot-illustration not-found"},[_c('upload',{staticClass:"icon-upload"})],1)]),_c('div',{staticClass:"flex-container"},[_c('h3',[_vm._v(_vm._s(this.editedLot.name))]),_c('p',[_vm._v(_vm._s(_vm.getCategoryName(this.editedLot.categoryId)))]),_c('p',{staticClass:"text-uppercase"},[_vm._v(_vm._s(this.editedLot.lotsSurveysNames))])])]),_c('div',{staticClass:"description"},[_c('p',[_vm._v("Description")]),_c('app-textarea',{attrs:{"placeholder":"Description..."},model:{value:(_vm.editedLot.description),callback:function ($$v) {_vm.$set(_vm.editedLot, "description", $$v)},expression:"editedLot.description"}})],1),_c('app-button',{attrs:{"icon":"edit"},on:{"click":function($event){return _vm.updateLot(_vm.editedLot.lotId)}}},[_vm._v("Enregistrer les modifications")])],1):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Liste des lots")])])])}]

export { render, staticRenderFns }