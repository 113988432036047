<template>
  <div class="design-system hedifys-21">
    <div class="header">
      <h1>Design système</h1>
    </div>
    <div class="body">
      <!-- BUTTONS ------------  -->
      <h2>Buttons</h2>
      <div class="grid-x row">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell shrink"><app-button theme="primary">Ajouter une agence</app-button></div>
          <div class="cell shrink"><app-button theme="primary" icon="add">Ajouter une agence</app-button></div>
          <div class="cell shrink"><app-button theme="primary" icon="add" size="round"></app-button></div>
        </div>
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell shrink"><app-button theme="primary" size="small">Ajouter une agence</app-button></div>
          <div class="cell shrink"><app-button theme="primary" size="small" icon="add">Ajouter une agence</app-button></div>
        </div>
      </div>
      <div class="grid-x row">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell shrink"><app-button theme="secondary">Filtrer</app-button></div>
          <div class="cell shrink"><app-button theme="secondary" icon="filter">Filtrer</app-button></div>
          <div class="cell shrink"><app-button theme="secondary" icon="filter" size="round"></app-button></div>
        </div>
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell shrink"><app-button theme="secondary" size="small">Filtrer</app-button></div>
          <div class="cell shrink"><app-button theme="secondary" size="small" icon="filter">Filtrer</app-button></div>
        </div>
      </div>
      <div class="grid-x row">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell shrink"><app-button theme="warning">Supprimer</app-button></div>
          <div class="cell shrink"><app-button theme="warning" icon="remove">Supprimer</app-button></div>
          <div class="cell shrink"><app-button theme="warning" icon="remove" size="round"></app-button></div>
        </div>
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell shrink"><app-button theme="warning" size="small">Supprimer</app-button></div>
          <div class="cell shrink"><app-button theme="warning" size="small" icon="remove">Supprimer</app-button></div>
        </div>
      </div>
      <!-- INPUTS ------------ -->
      <h2>Input</h2>
      <div class="grid-x row">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-input label="Label" /></div>
          <div class="cell auto"><app-input label="Label" size="small" /></div>
        </div>
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-input disabled /></div>
          <div class="cell auto"><app-input disabled size="small" /></div>
        </div>
      </div>
        <div class="grid-x row">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-input label="Label" unit="m2" /></div>
          <div class="cell auto"><app-input label="Label" unit="m2" size="small" /></div>
        </div>
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-input disabled unit="m2" /></div>
          <div class="cell auto"><app-input disabled unit="m2" size="small" /></div>
        </div>
      </div>
      <div class="grid-x row">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-input label="Label" hasSucceed="success" msg="Success message" /></div>
          <div class="cell auto"><app-input label="Label" hasSucceed="success" msg="Success message" size="small" /></div>
        </div>
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-input label="Label" hasSucceed="error" msg="Error message" /></div>
          <div class="cell auto"><app-input label="Label" hasSucceed="error" msg="Error message" size="small" /></div>
        </div>
      </div>
      <!-- SEARCH ------------  -->
      <h2>Input Search</h2>
      <div class="grid-x row">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-search label="Label" v-model="search" :options="['item 1', 'item 2', 'item 3', 'item 4', 'item 5']" /></div>
          <div class="cell auto"><app-search label="Label" v-model="searchSmall" size="small" /></div>
        </div>
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-search v-model="searchDisabled" disabled /></div>
          <div class="cell auto"><app-search v-model="searchDisabledSmall" disabled size="small" /></div>
        </div>
      </div>
      <!-- SELECT ------------  -->
      <h2>Select</h2>
      <div class="grid-x row">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto">
            <app-select label="Label" v-model="selectLarge" :options="[
              { name: '1', label: 'item 1' },
              { name: '2', label: 'item 2' },
              { name: '3', label: 'item 3' },
              { name: '4', label: 'item 4' },
              { name: '5', label: 'item 5' },
            ]" />
          </div>
          <div class="cell auto">
            <app-select label="Label" v-model="selectSmall" :options="[
              { name: '1', label: 'item 1' },
              { name: '2', label: 'item 2' },
              { name: '3', label: 'item 3' },
              { name: '4', label: 'item 4' },
              { name: '5', label: 'item 5' },
            ]" size="small" />
          </div>
        </div>
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell auto"><app-select disabled /></div>
          <div class="cell auto"><app-select disabled size="small" /></div>
        </div>
      </div>
      <!-- TEXTAREA ------------  -->
      <h2>Textarea</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-textarea label="Label" />
        </div>
        <div class="cell auto"></div>
      </div>
      <!-- TOGGLE ------------  -->
      <h2>Toggle</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-toggle name='toggle' :icons='["list", "locate"]' />
        </div>
      </div>
      <!-- TABLES ------------  -->
      <h2>Tableau</h2>
      <h3>Tableau medium :</h3>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-table :headers="tableHeadersMedium" :data="tableDataMedium">
            <template slot="favorite" slot-scope="{ data }">
              <favorite
                @click="toggleFavorite(data.id)"
                :class="{'favorite-active': data.favorite, 'favorite-inactive': !data.favorite}"
              />
            </template>
            <template slot="name" slot-scope="{ data }">
              <strong>{{data.name}}</strong>
            </template>
            <template slot="state" slot-scope="{ data }">
              <h5 class="tag" :class="'tag-' + data.state">
                <success v-if="data.state === 'success'"/>
                <minus v-if="data.state === 'warning' || data.state === 'error'"/>
                <price v-if="data.state === 'neutral'"/>
                {{data.state}}
              </h5>
            </template>
            <template slot="mail" slot-scope="{ data }">
              <app-input :value="data.mail" />
            </template>
          </app-table>
        </div>
      </div>

      <h3>Tableau large :</h3>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-table :headers="tableHeadersLarge" :data="tableDataLarge">
            <template slot="photo" slot-scope="{}">
              <media class="media"/>
            </template>
            <template slot="name" slot-scope="{ data }">
              <strong>{{data.name}}</strong>
            </template>
          </app-table>
        </div>
      </div>

      <h3>Tableau large avec pagination : ( ⚠️Nécessite de se loguer pour que la liste affiche des données)</h3>
      <filter-modal @change="filterLands" @reset="resetLands" :numberOfInputs="Object.values(filter).filter(i => !!i).length">
        <div class="grid-x grid-margin-x row">
          <div class="cell">
            <app-input v-model="filter.searchLand" label="Rechercher" placeholder="Rechercher..." type="text" />
          </div>
        </div>
      </filter-modal>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto grid-y">
          <app-table :headers="tableLandsHeader" :data="lands.data" :loading="isLoading">
            <template slot="loading">
              <app-spinner />
            </template>
            <template slot="empty-table">
              <p>Aucune données de disponible</p>
            </template>
            <template slot="address" slot-scope="{ data }">
              <p>{{data.address.addressLine1}}</p>
            </template>
            <template slot="city" slot-scope="{ data }">
              <p>{{data.address.city}}</p>
            </template>
            <template slot="surface" slot-scope="{ data }">
              <p>{{data.surface}}m2</p>
            </template>
            <template slot="status" slot-scope="{ data }">
              <h5 class="tag tag-error">
                {{data.status}}
              </h5>
            </template>
            <template slot="price" slot-scope="{ data }">
              <p>{{data.price}}€</p>
            </template>
          </app-table>

          <app-pagination
            v-if="lands.metadata"
            :limit="limit"
            :offset="lands.metadata.offset"
            :count="lands.metadata.count"
          />
        </div>
      </div>
      <h3>Tableau large en chargement :</h3>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-table :headers="tableLandsHeader" :data="lands.data" loading>
            <template slot="loading">
              <app-spinner />
            </template>
          </app-table>
        </div>
      </div>

      <h3>Tableau large avec aucune donnée :</h3>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-table :headers="tableLandsHeader">
            <template slot="empty-table">
              <p>Aucune donnée de disponible</p>
            </template>
          </app-table>
        </div>
      </div>

      <h3>Tableaux min :</h3>
      <div class="card">
        <div class="grid-x row grid-margin-x scrollable-content">
          <div class="cell auto">
            <app-table :headers="tableHeadersSmall" :data="tableDataSmall" small>
              <template slot="action" slot-scope="{ data }">
                <app-button @click="deleteRow(data.id)" theme='warning' icon='remove' size='round-small'/>
              </template>
              <template slot="empty-table">
                <p>Aucune donnée de disponible</p>
              </template>
            </app-table>
          </div>
          <div class="cell auto">
            <app-table :headers="tableHeadersSmall" :data="tableDataSmall" small loading>
              <template slot="loading">
                <app-spinner />
              </template>
            </app-table>
          </div>
        </div>
      </div>

      <div class="grid-x row grid-margin-x card">
        <div class="cell auto  scrollable-content">
          <app-table :headers="tableHeadersSmall" small>
            <template slot="empty-table">
              <p>Aucune donnée de disponible</p>
            </template>
          </app-table>
        </div>
        <div class="cell auto"></div>
      </div>

      <!-- ERROR ------------  -->
      <h2>Error message</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-error
            title="Error message"
            :messages="['Input error at age field, must be a number.', 'Input error at name field, must not be empty.']"
          />
        </div>
      </div>
      <!-- CARD LINK ------------  -->
      <h2>Card Link</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-card-link
            :primaryRoute="{ route: { name: 'design-system' }, label: 'Lotissement Loire 25' }"
            :secondaryRoute="{ route: { name: 'design-system' }, label: 'Lamotte Immobilier' }"
            description="Rue des olivettes - 44000 NANTES"
          />
        </div>
      </div>

      <!-- MODAL ------------  -->
      <h2>Modal</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-button theme="primary" @click="isMediumModalOpen = true">Ouvrir la modale</app-button>
          <app-modal :show="isMediumModalOpen" @update:show="closeMediumModal" size="medium" title="Titre modale">
            <div class="grid-x grid-margin-x row">
              <div class="cell"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell auto"><app-input label="Label" /></div>
              <div class="cell auto"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell auto"><app-input label="Label" /></div>
              <div class="cell auto"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-button theme="secondary" @click="closeMediumModal">Envoyer le formulaire</app-button>
              </div>
            </div>
          </app-modal>
        </div>
        <div class="cell auto">
          <app-button theme="primary" @click="isSmallModalOpen = true">Ouvrir la modale</app-button>
          <app-modal :show="isSmallModalOpen" @update:show="closeSmallModal" size="small" title="Titre modale">
            <div class="grid-x grid-margin-x row">
              <div class="cell"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell auto"><app-input label="Label" /></div>
              <div class="cell auto"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell auto"><app-input label="Label" /></div>
              <div class="cell auto"><app-input label="Label" /></div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-button theme="secondary" @click="closeSmallModal">Envoyer le formulaire</app-button>
              </div>
            </div>
          </app-modal>
        </div>
      </div>

      <!-- TABNAV ------------  -->
      <h2>TabNav</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <tab-nav
            :tabs="[
              { route: { name: 'design-system' }, label: 'Design sytème'},
              { route: { name: 'home' }, label: 'Accueil'},
              { route: { name: 'agencies' }, label: 'Agences'},
            ]"
          />
        </div>
      </div>
      <!-- BREADCRUMBS ------------  -->
      <h2>Breadcrumb</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <breadcrumb
            :items="[
              { route: { name: 'agencies'}, name: 'Agences'},
              { route: { name: 'agencies-agencyId', params: { agencyId: '3442cd9d-eb58-45f5-86e0-cf2bbdd416fd' }}, name: 'Agence Sud 44'}
            ]"
          />
        </div>
      </div>
      <!-- FILE UPLOADS ------------  -->
      <h2>Avatar</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-avatar v-model="avatar" @upload="uploadAvatar" @remove="removeAvatar" />
        </div>
      </div>
      <h2>Upload image</h2>
      <div class="grid-x row grid-margin-x">
        <div class="upload-img cell small-2">
          <app-upload-image v-model="media.url" @upload="uploadImg" @remove="removeImg" />
        </div>
      </div>
      <h2>Input file</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-input-file accept="image/png, image/jpeg" text="Fichier .png, .jpeg 500ko max" />
        </div>
        <div class="cell auto">
          <app-input-file accept="image/png, image/jpeg" text="Fichier .png, .jpeg 500ko max" size="small" />
        </div>
      </div>

      <!-- CHECKBOX ------------  -->
      <h2>Checkbox</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-checkbox>Checkbox</app-checkbox>
        </div>
        <div class="cell auto">
          <app-checkbox disabled>Checkbox</app-checkbox>
        </div>
      </div>

      <!-- RADIO ------------  -->
      <h2>Radio</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto grid-x grid-margin-x">
          <div class="cell shrink">
            <app-radio name="radio" value="ONE" v-model="radio" checked>One</app-radio>
          </div>
          <div class="cell shrink">
            <app-radio name="radio" value="TWO" v-model="radio">TWO</app-radio>
          </div>
          <div class="cell shrink">
            <app-radio name="radio" value="THREE" v-model="radio">THREE</app-radio>
          </div>
        </div>
        <div class="cell auto">
          <app-radio disabled>Radio</app-radio>
        </div>
      </div>

      <!-- SWITCH ------------  -->
      <h2>Switch</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-switch>Switch</app-switch>
        </div>
        <div class="cell auto">
          <app-switch disabled>Switch</app-switch>
        </div>
      </div>

      <!-- RADIO BUTTON ------------  -->
      <h2>Radio Button</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-radio-button
            label="Filtrer par prix"
            v-model="orderBy"
            :options="[
              { name: 'ASC', label: 'Prix Ascendant' },
              { name: 'DESC', label: 'Prix descendant' }
            ]"
          />
        </div>
      </div>

      <!-- SLIDER ------------  -->
      <h2>Slider</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <app-slider label="Max :" />
        </div>
        <div class="cell auto">
          <app-double-slider label="Range :" :min="0" :max="100" />
        </div>
      </div>

      <!-- POSTAL CODE ------------  -->
      <h2>Code postaux</h2>
      <div class="grid-x row grid-margin-x">
        <div class="cell auto">
          <PostalCode
            :postalCodes="postalCodes"
            :isLoading="isPostalCodesLoading"
            :borders="borders"
            :showInvalidFileMessage="showInvalidFileMessage"
            :showInvalidPostalCodeMessage="showInvalidPostalCodeMessage"
            @getPostalCodes="getPostalCodes"
            @importPostalCodes="importPostalCodes"
            @addPostalCode="addPostalCode"
            @deletePostalCode="deletePostalCode"
            @hideInvalidPostalCodeMessage="showInvalidPostalCodeMessage = false"
            @hideInvalidFileMessage="showInvalidFileMessage = false"
          />
        </div>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import member from '@/services/api/member';
import agencyMedia from '@/services/api/agencyMedia';
import postalCode from '@/services/api/postalCode';
import land from '@/services/api/land';

import favorite from '@/assets/img/favorite.svg?inline';
import success from '@/assets/img/success.svg?inline';
import minus from '@/assets/img/minus.svg?inline';
import price from '@/assets/img/price.svg?inline';
import media from '@/assets/img/image.svg?inline';
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import PostalCode from '@/components/general/PostalCode.vue';
import FilterModal from '@/components/general/FilterModal.vue';

export default {
  name: 'design-system',

  components: {
    favorite,
    success,
    minus,
    price,
    media,
    TabNav,
    PostalCode,
    Breadcrumb,
    FilterModal,
  },

  data() {
    return {
      // propriétés des v-model
      search: null,
      searchSmall: null,
      searchDisabled: null,
      searchDisabledSmall: null,
      selectLarge: null,
      selectSmall: null,
      orderBy: null,

      // Propriétés des uploads de fichiers
      avatar: null,
      agencyId: '3442cd9d-eb58-45f5-86e0-cf2bbdd416fd',
      media: {},

      // Propriétés des modales
      isMediumModalOpen: false,
      isSmallModalOpen: false,

      // propriété du filtre
      filter: {
        searchLand: this.$route.query.search,
      },

      // Propriétés des tableaux
      tableHeadersMedium: [
        { label: 'Favori', key: 'favorite', size: 1 },
        { label: 'Nom', key: 'name', size: 4 },
        { label: 'État', key: 'state', size: 2 },
        { label: 'Mail', key: 'mail', size: 2 },
        { label: 'Date', key: 'date', size: 2 },
        { label: 'Prix', key: 'price', size: 1 },
      ],
      tableDataMedium: [
        {
          id: 1,
          favorite: false,
          name: 'Maisons Alysia 19 Ouest Coast, Ancenis de saint jean de mont de la berinière',
          state: 'success',
          mail: 'hedifys@mail.com',
          date: '11/06/2020',
          price: '12 000 €',
        },
        {
          id: 2,
          favorite: false,
          name: 'Maisons Alysia 17 California',
          state: 'warning',
          mail: 'contact@mail.com',
          date: '11/03/2020',
          price: '22 000 €',
        },
        {
          id: 3,
          favorite: true,
          name: 'Maisons Alysia 18 Est Side',
          state: 'error',
          mail: 'hedifys@mail.com',
          date: '10/02/2020',
          price: '10 000 €',
        },
        {
          id: 4,
          favorite: true,
          name: 'Maisons Alysia 17 California',
          state: 'warning',
          mail: 'contact@mail.com',
          date: '11/03/2020',
          price: '22 000 €',
        },
        {
          id: 5,
          favorite: true,
          name: 'Maisons Alysia 18 Est Side',
          state: 'neutral',
          mail: 'hedifys@mail.com',
          date: '10/02/2020',
          price: '10 000 €',
        },
      ],
      tableHeadersLarge: [
        { label: 'Photo', key: 'photo', size: 2 },
        { label: 'Nom', key: 'name', size: 4 },
        { label: 'Adresse', key: 'address', size: 2 },
        { label: 'Ville', key: 'city', size: 1 },
        { label: 'Date', key: 'date', size: 2 },
        { label: 'Prix', key: 'price', size: 1 },
      ],
      tableDataLarge: [
        {
          photo: 'Photo',
          name: 'Maisons Alysia 19 Ouest Coast, Ancenis de saint jean de mont de la berinière',
          address: '42 Rue du Tertre',
          city: 'Nantes',
          date: '11/06/2020',
          price: '12 000 €',
        },
        {
          photo: 'Photo',
          name: 'Maisons Alysia 17 California',
          address: '42 Rue du Tertre',
          city: 'Nantes',
          date: '11/03/2020',
          price: '22 000 €',
        },
        {
          photo: 'Photo',
          name: 'Maisons Alysia 18 Est Side',
          address: '42 Rue du Tertre',
          city: 'Nantes',
          date: '10/02/2020',
          price: '10 000 €',
        },
      ],
      tableHeadersSmall: [
        { label: 'Code postal', key: 'postalCode', size: 3 },
        { label: 'Commune', key: 'town', size: 8 },
        { label: '', key: 'action', size: 1 },
      ],
      tableDataSmall: [
        {
          id: 1,
          postalCode: 44300,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 2,
          postalCode: 44100,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 3,
          postalCode: 44200,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 4,
          postalCode: 44100,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 5,
          postalCode: 44300,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 6,
          postalCode: 44300,
          town: 'Ancenis de saint jean de mont de la berinière, un nom très long',
          action: '',
        },
        {
          id: 7,
          postalCode: 44200,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 8,
          postalCode: 44100,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 9,
          postalCode: 44000,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 10,
          postalCode: 42300,
          town: 'Ancenis',
          action: '',
        },
        {
          id: 11,
          postalCode: 44100,
          town: 'Ancenis',
          action: '',
        },
      ],
      limit: 5,
      isLoading: false,
      tableLandsHeader: [
        { label: 'Adresse', key: 'address', size: 5 },
        { label: 'Ville', key: 'city', size: 2 },
        { label: 'Surface', key: 'surface', size: 2 },
        { label: 'Statut', key: 'status', size: 2 },
        { label: 'Prix', key: 'price', size: 1 },
      ],
      lands: [],

      postalCodes: [],
      isPostalCodesLoading: false,
      borders: {
        type: 'FeatureCollection',
        name: 'borders',
        features: [],
      },
      showInvalidFileMessage: false,
      showInvalidPostalCodeMessage: false,

      radio: 'ONE',
    };
  },
  watch: {
    // Déclenche la recherche de produits à chaque changement de query
    $route() {
      this.getLands(this.computeOffset);
    },
  },
  computed: {
    // Calcule de l'offset
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  mounted() {
    this.getLands(this.computeOffset);
    this.getMedia();
    this.getPostalCodes();
  },
  methods: {
    async getMedia() {
      try {
        const medias = await agencyMedia.getAll(this.agencyId);
        if (medias && medias.length) {
          [this.media] = medias;
        }
      } catch (er) {
        throw er;
      }
    },
    async uploadImg(event) {
      try {
        const file = new FormData();
        file.append('file', event);
        file.append('type', 'OUTSIDE');
        this.media = await agencyMedia.upload(this.agencyId, file);
      } catch (er) {
        throw er;
      }
    },
    async removeImg() {
      try {
        await agencyMedia.delete(this.agencyId, this.media.agencyMediaId);
        this.media = {};
      } catch (er) {
        throw er;
      }
    },
    async uploadAvatar(event) {
      try {
        const memberId = await member.getMe();
        const file = new FormData();
        file.append('file', event);
        const res = await member.uploadPhoto(memberId.memberId, file);
        this.avatar = res.data.photo;
      } catch (er) {
        throw er;
      }
    },
    async removeAvatar() {
      try {
        const memberId = await member.getMe();
        const res = await member.uploadPhoto(memberId.memberId);
        this.avatar = res.data.photo;
      } catch (er) {
        throw er;
      }
    },
    async getLands(offset) {
      this.isLoading = true;
      try {
        this.lands = await land.getAll(this.limit, offset, this.filter.searchLand);
      } catch (error) {
        throw error;
      }
      this.isLoading = false;
    },

    async filterLands() {
      this.isLoading = true;
      try {
        this.lands = await land.getAll(this.limit, this.computeOffset, this.filter.searchLand);
        this.$router.replace({
          query: {
            ...this.$router.query,
            search: this.filter.searchLand,
          },
        });
      } catch (error) {
        throw error;
      }
      this.isLoading = false;
    },

    async resetLands() {
      this.filter = {};
      this.getLands();
    },

    toggleFavorite(id) {
      this.tableDataMedium[id - 1].favorite = !this.tableDataMedium[id - 1].favorite;
    },

    deleteRow(id) {
      const removeIndex = this.tableDataSmall.map((item) => item.id === id);
      this.tableDataSmall.splice(removeIndex, 1);
    },
    closeMediumModal() {
      if (this.isMediumModalOpen) {
        this.isMediumModalOpen = !this.isMediumModalOpen;
      }
    },
    closeSmallModal() {
      if (this.isSmallModalOpen) {
        this.isSmallModalOpen = !this.isSmallModalOpen;
      }
    },

    // Récupération des données des borders
    async getPostalCodes(search) {
      this.isPostalCodesLoading = true;
      try {
        this.postalCodes = await postalCode.getByAgency(this.agencyId, search);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des codes postaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      // On vide le tableau des frontières des borders
      this.borders.features = [];

      // Récupération des frontières pour les inclure dans l'objet 'borders' si il y a
      if (this.postalCodes.length > 0) {
        this.borders.features = this.postalCodes.map((cp) => cp.borders);
      }
      this.isPostalCodesLoading = false;
    },

    // Importation de la liste des codes postaux
    async importPostalCodes(file) {
      if (file) {
        try {
          await postalCode.importFileByAgency(this.agencyId, file[0]);
          this.getPostalCodes();
        } catch (error) {
          this.showInvalidFileMessage = true;
        }
      }
    },

    // Ajout d'un code postal
    async addPostalCode(selectedPostalCode) {
      try {
        await postalCode.addOneByAgency(this.agencyId, selectedPostalCode);
        this.getPostalCodes();
      } catch (error) {
        this.showInvalidPostalCodeMessage = true;
      }
    },

    // Supprime un code postal de la liste
    async deletePostalCode(postalCodeInseeNumber) {
      try {
        await postalCode.deleteOneByAgency(this.agencyId, postalCodeInseeNumber);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression du code postal',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.getPostalCodes();
    },
  },
};
</script>

<style lang='sass' scoped>
.design-system
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .media
    @include media
  .card
    @include card
  .favorite-active
    @include favorite-active
  .favorite-inactive
    @include favorite-inactive
  h2, h3
    margin: 2rem 0 1rem 0
  .scrollable-content
    height: 300px
</style>
