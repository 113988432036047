<template>
  <div class="option hedifys-21">
    <form ref="option" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="$route.params.optionId"
            :items="[
              { route: { name: 'categories-options'}, name: 'Options'},
              { route: { name: 'categories-options-optionId', params: { optionId: $route.params.optionId }}, name: option.name}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <div v-if="!isLoading">
            <h1 v-if="$route.params.optionId">{{ option.name }}</h1>
            <h1 v-else>Ajouter une option</h1>
          </div>
        </div>
        <div class="cell shrink" v-if="$route.params.optionId">
          <app-button theme="warning" size="large" @click="openDeleteModal">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">{{ $route.params.optionId ? 'Sauvegarder' : 'Ajouter' }}</app-button>
        </div>
      </div>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="grid-x row grid-margin-x">
          <div class="img">
            <app-upload-image :value="option.image" @upload="uploadImg" @remove="removeImg" />
          </div>
          <div class="card cell auto">
            <div class="grid-x row grid-margin-x">
              <div class="cell auto">
                <app-input v-model="option.name" label="Nom de l'option" placeholder="Nom de l'option'..." required />
              </div>
              <div class="cell auto">
                <app-input v-model="option.reference" label="Référence de l'option" placeholder="Référence de l'option..." />
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell auto">
                <app-select v-model="option.theme.themeId" label="Thème de l'option" :options="themeOptions" required />
              </div>
              <div class="cell auto">
                <app-radio-button
                  label="Type :"
                  name="option"
                  v-model="option.type"
                  :input="option.type"
                  :options="typeOptions"
                  required
                />
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell auto">
                <app-textarea v-model="option.formula" label="Mode / Formule de calcul" placeholder="1.2 * $lot_metre_menuiseries_ext_pvc_fourniture" />
                <div class="variables">
                  <info class="info" />
                  <p @click="openVariableModal = true">Voir les variables</p>
                </div>
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell auto">
                <app-textarea v-model="option.description" label="Description" placeholder="Description de l’option" />
              </div>
            </div>
          </div>
        </div>
        <!-- VARIABLES MODAL -->
        <div class="cell shrink">
          <app-side-modal :isOpen="openVariableModal" @close="openVariableModal = false" title="Liste des variables">
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <h3 class="heading">Variables</h3>
                <ul class="list">
                  <li v-for="variable in variables" :key="variable.variableId">{{ variable.key }}</li>
                </ul>
                <h3 class="heading">Lots métrés</h3>
                <ul class="list">
                  <li v-for="lotSurvey in lotsSurveys" :key="lotSurvey.lotSurveyId">{{ lotSurvey.key }}</li>
                </ul>
              </div>
            </div>
          </app-side-modal>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import optionTypes from '@/services/data/optionTypes.json';
import optionApi from '@/services/api/option';
import themeApi from '@/services/api/theme';
import lotSurveyApi from '@/services/api/lotSurvey';
import variableApi from '@/services/api/variable';
import utils from '@/services/utils/utils';
import info from '@/assets/img/info.svg?inline';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

export default {
  name: 'update-option',
  components: {
    info,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.option.name ? `${this.option.name} – Option` : 'Option',
    };
  },
  data() {
    return {
      option: {
        name: null,
        theme: {
          themeId: null,
        },
        image: null,
        type: null,
        description: null,
        reference: null,
        useInPublicPrice: null,
        variables: null,
        formula: null,
      },
      img: null,
      oldImg: null,
      typeOptions: [],
      isLoading: null,
      themes: [],
      themeOptions: [],
      variables: [],
      lotsSurveys: [],
      openVariableModal: false,
    };
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getData();
      }
    },
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getVariables();
      await this.getThemes();
      this.themeOptions = utils.formatOptions(
        this.themes,
        (option) => option.themeId,
        (option) => option.name,
      );
      this.typeOptions = utils.formatOptions(
        optionTypes,
        (option) => option.name,
        (option) => option.label,
      );
      await this.getLotsSurveys();
      await this.getOptions();
      this.isLoading = false;
    },
    async getOptions() {
      try {
        if (this.$route.params.optionId) {
          this.option = await optionApi.getById(this.$route.params.optionId);
          this.oldImg = this.option.image;
        } else {
          this.option.type = optionTypes[0].name;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des règles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getThemes() {
      try {
        this.themes = await themeApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des thèmes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getVariables() {
      try {
        const variables = await variableApi.getAll();
        this.variables = variables && variables.length ? variables.sort((a, b) => a.key.localeCompare(b.key)) : [];
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des variables',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLotsSurveys() {
      try {
        const lotsSurveys = await lotSurveyApi.getAll();
        this.lotsSurveys = lotsSurveys && lotsSurveys.length ? lotsSurveys.sort((a, b) => a.key.localeCompare(b.key)) : [];
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    uploadImg(event) {
      this.img = event;
      this.option.image = URL.createObjectURL(event);
    },
    removeImg() {
      this.img = null;
      this.option.image = null;
    },
    async save() {
      this.isLoading = true;
      try {
        if (!this.option.type) {
          throw new Error();
        }
        const optionObj = {
          name: this.option.name,
          themeId: this.option.theme.themeId,
          type: this.option.type,
          image: null,
          description: this.option.description,
          reference: this.option.reference,
          useInPublicPrice: true,
          variables: this.option.variables,
          formula: this.option.formula,
        };
        let newOption = null;

        if (this.$route.params.optionId) {
          await optionApi.update(this.option.optionId, optionObj);
          this.uploadImgOption(this.option.optionId);
          this.$notification.show({ text: 'L\'option a bien été mise à jour.' });
        } else {
          newOption = await optionApi.create(optionObj);
          this.uploadImgOption(newOption.optionId);
          this.$notification.show({ text: 'L\'option a bien été crée.' });
          this.$router.push({ name: 'categories-options-optionId', params: { optionId: newOption.optionId } });
        }
      } catch (er) {
        let errorMessage = 'Il y a eu un problème lors de la sauvegarde de l\'option. Assurez-vous que tous les champs ont été correctement remplis.';
        if (er.message.includes('406')) {
          errorMessage = 'Il y a eu un problème lors de la sauvegarde de l\'option. Vérifiez que les variables ont bien les bons noms';
        }

        this.$message.show({
          title: 'Erreur',
          text: errorMessage,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async uploadImgOption(optionId) {
      if (this.oldImg !== this.option.image || this.img !== null) {
        try {
          const file = new FormData();
          file.append('file', this.img);
          await optionApi.uploadImg(file, optionId);
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de l\'upload de l\'image',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer l\'option',
        text: 'Êtes vous sûr de vouloir supprimer cette option ?',
        confirmText: 'Supprimer l\'option',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteOption();
        },
      });
    },
    async deleteOption() {
      if (this.$route.params.optionId) {
        this.isLoading = true;
        try {
          await optionApi.delete(this.$route.params.optionId);
          this.$notification.show({ text: 'Cette option a été supprimé avec succès !' });
          this.$router.push({ name: 'categories-options' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression de l\'option',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.option
  .body
    @include screen
    .img
      width: 225px
      height: 225px
    .heading
      margin: 1.5rem 0
    .variables
      display: flex
      flex-direction: row
      align-items: center
      margin-top: 8px
      p
        margin-left: 8px
        @include hyperlink
    .info
      width: 16px
      height: 16px
      circle
        stroke: $primary
      path
        fill: $primary
    .list
      padding: 0
      li
        @include paragraph
        white-space: nowrap
        list-style: disc inside

        + li
          margin-top: 1rem
  form > .header
    @include header
  .breadcrumbs
    @include breadcrumbs
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
</style>
