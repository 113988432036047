import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des régulations
 * @param {string} postalCodeInseeNumber: code postal de la règle, paramètre de recherche
 */
const getAll = async (postalCodeInseeNumber) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/regulations`, {
      params: { ...(postalCodeInseeNumber && { postalCodeInseeNumber }) },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une règle
 * @param {string} regulationId: id de la gamme à récupérer
 */
const getById = async (regulationId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/regulations/${regulationId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Créer une règle
 * @param {object} data: données de la règle à créer
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/regulations/`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Met à jour une règle
 * @param {string} regulationId: id de la règle à mettre à jour
 * @param {object} data: données de la règle
 */
const update = async (regulationId, data) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/regulations/${regulationId}`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Suppression d'une règle
 * @param {string} regulationId: id d'une règle à récupérer
 */
const deleteOne = async (regulationId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/regulations/${regulationId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const regulation = {};
regulation.getAll = getAll;
regulation.create = create;
regulation.delete = deleteOne;
regulation.update = update;
regulation.getById = getById;

export default regulation;
