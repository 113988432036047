var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hedifys-chantier build-steps"},[_c('div',{staticClass:"build-steps-header"},[_c('h1',[_vm._v("Planning du chantier "+_vm._s(_vm.project && _vm.project.reference ? ((_vm.project.reference) + " " + _vm.customerName) : ''))]),_c('app-button',{attrs:{"theme":"secondary","icon":"remove"},on:{"click":_vm.close}},[_vm._v("Revenir aux tâches du chantier")])],1),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('app-spinner')],1):_c('div',{staticClass:"gantt gantt-section"},[_c('perfect-scrollbar',{ref:"scroll",on:{"scroll":_vm.handleScrollX}},[_c('div',{staticClass:"gantt-container",style:({ width: ((_vm.days * _vm.dayWidth) + "px"), paddingTop: _vm.isSticky ? '96px' : 0 })},[_c('div',{staticClass:"gantt-header",style:({ width: (((_vm.days) * _vm.dayWidth) + "px"), transform: ("translateX(-" + _vm.stickyOffsetLeft + "px)"), willChange: 'transform'})},[_c('div',_vm._l((_vm.months),function(m){return _c('span',{key:("month" + m),staticClass:"month",style:({
                width: ((_vm.$dayjs(_vm.startDate)
                  .add(m - 1, 'month')
                  .daysInMonth() * _vm.dayWidth) + "px")
              })},[_vm._v(" "+_vm._s(_vm.$dayjs(_vm.startDate) .add(m - 1, "month") .format("MMMM YYYY"))+" ")])}),0),_c('div',[_c('span',{staticClass:"week",style:({
                width: (((_vm.$dayjs(_vm.startDate)
                  .endOf('isoWeek')
                  .diff(_vm.$dayjs(_vm.startDate), 'day') +
                  1) *
                  _vm.dayWidth) + "px")
              })},[_vm._v(" S"+_vm._s(_vm.$dayjs(_vm.startDate) .startOf("isoWeek") .isoWeek())+" ")]),_vm._l((_vm.weeks),function(w){return _c('span',{key:("week" + w),staticClass:"week",style:({ width: ((_vm.dayWidth * 7) + "px") })},[_vm._v(" S"+_vm._s(_vm.$dayjs(_vm.startDate) .add(w, "week") .isoWeek())+" ")])}),_c('span',{staticClass:"week",style:({
                width: (((_vm.$dayjs(_vm.endDate).diff(_vm.$dayjs(_vm.endDate).startOf('isoWeek'), 'day') + 1) *
                  _vm.dayWidth) + "px")
              })},[_vm._v(" S"+_vm._s(_vm.$dayjs(_vm.endDate) .startOf("isoWeek") .isoWeek())+" ")])],2),_c('div',_vm._l((_vm.days),function(d){return _c('span',{key:("day" + d),staticClass:"day",class:[
                {
                  weekend: [6, 0].includes(
                    _vm.$dayjs(_vm.startDate)
                      .add(d - 1, 'day')
                      .day()
                  )
                },
                {
                  today:
                    _vm.$dayjs()
                      .startOf('day')
                      .diff(_vm.$dayjs(_vm.startDate).add(d - 1, 'day'), 'day') === 0
                }
              ],style:({ width: (_vm.dayWidth + "px") })},[_vm._v(" "+_vm._s(_vm.$dayjs(_vm.startDate) .add(d - 1, "day") .format("DD"))+" ")])}),0)]),_c('div',{staticClass:"tasks-project",style:({
            background: ("repeating-linear-gradient(\n                90deg,\n                white,\n                white " + (_vm.dayWidth - 1) + "px,\n                #aaaaaa " + _vm.dayWidth + "px\n              )")
          })},_vm._l((_vm.projectSteps),function(projectStep,index){return _c('project-step-gantt',{key:("project-step-gantt-" + (projectStep.projectStepId)),attrs:{"start-date":_vm.startDate,"day-width":_vm.dayWidth,"companies":_vm.companies,"companyCategories":_vm.companyCategories,"projectId":projectStep.project.projectId,"project-steps":_vm.projectSteps,"disabled":projectStep.status === 'DONE',"step-focused-id":_vm.stepFocusedId},on:{"openModal":_vm.openProjectStepModal,"update":_vm.getData,"getFocusedStepPosition":_vm.getFocusedStepPosition,"updateBoundaries":_vm.updateBoundaries},model:{value:(_vm.projectSteps[index]),callback:function ($$v) {_vm.$set(_vm.projectSteps, index, $$v)},expression:"projectSteps[index]"}})}),1)])]),_c('project-step-modal',{attrs:{"project":_vm.project,"projectStep":_vm.selectedProjectStep,"projectSteps":_vm.projectSteps,"companies":_vm.companies,"companyCategories":_vm.companyCategories,"modalType":_vm.modalType,"openModal":_vm.showProjectStepModal,"listName":'project',"dropdownPosition":_vm.dropdownPosition},on:{"close":function($event){_vm.showProjectStepModal = false},"changeType":_vm.changeType,"update":_vm.getData}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }