<template>
  <div class="coefficients hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Les marges</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" @click="openCoefficientModal()">Ajouter une marge</app-button>
      </div>
    </div>
    <!-- BODY / FORMULAIRE -->
    <div class="body" v-if="!isLoading">
      <div class="agency-list">
        <app-accordion v-for="agency in agencies" :key="agency.agencyId">
          <template slot="header">
            <h3>{{ agency.name }}</h3>
          </template>
          <template slot="content">
            <div
              class="grid-x grid-margin-x accordion-row"
              v-if="agencyCoefficients.find((coefficient) => coefficient.agencyId === agency.agencyId && coefficient.lotSurvey && coefficient.lotSurvey.lotSurveyId)">
              <div class="cell small-2 title">
                <h4>Lots métrés</h4>
              </div>
              <div class="cell auto">
                <div
                  class="grid-x grid-margin-x coefficient"
                  @click="openCoefficientModal(agencyCoefficient)"
                  v-for="agencyCoefficient in agencyCoefficients.filter((coefficient) => coefficient.agencyId === agency.agencyId && coefficient.lotSurvey && coefficient.lotSurvey.lotSurveyId)"
                  :key="agencyCoefficient.agencyCoefficientId">
                  <div class="cell small-5">
                    <p>{{ agencyCoefficient.lotSurvey.name }}</p>
                  </div>
                  <div class="cell auto">
                    <p>{{ agencyCoefficient.value }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="grid-x grid-margin-x accordion-row"
              v-if="agencyCoefficients.find((coefficient) => coefficient.agencyId === agency.agencyId && coefficient.version && coefficient.version.versionId)">
              <div class="cell small-2 title">
                <h4>Versions</h4>
              </div>
              <div class="cell auto">
                <div
                  class="grid-x grid-margin-x coefficient"
                  @click="openCoefficientModal(agencyCoefficient)"
                  v-for="agencyCoefficient in agencyCoefficients.filter((coefficient) => coefficient.agencyId === agency.agencyId && coefficient.version && coefficient.version.versionId)" :key="agencyCoefficient.agencyCoefficientId">
                  <div class="cell small-5">
                    <p>{{ agencyCoefficient.version.name }}</p>
                  </div>
                  <div class="cell auto">
                    <p>{{ agencyCoefficient.value }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="grid-x grid-margin-x accordion-row"
              v-if="brandCoefficients.find((coefficient) => coefficient.agenciesIds.includes(agency.agencyId))">
              <div class="cell small-2 title">
                <h4>Code postaux</h4>
              </div>
              <div class="cell auto">
                <div
                  class="grid-x grid-margin-x coefficient"
                  v-for="brandCoefficient in brandCoefficients.filter((coefficient) => coefficient.agenciesIds.includes(agency.agencyId))" :key="brandCoefficient.agencyCoefficientId"
                  @click="openCoefficientModal(brandCoefficient, agency)">
                  <div class="cell small-5">
                    <p>
                      {{
                        brandCoefficient.postalCodesInseeNumbers && brandCoefficient.postalCodesInseeNumbers.length ?
                          brandCoefficient.postalCodesInseeNumbers.map(pc => pc.substring(0, 5)).join(', ') : null
                      }}
                    </p>
                  </div>
                  <div class="cell auto">
                    <p>{{ brandCoefficient.value }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="grid-x grid-margin-x accordion-row"
              v-if="
                !brandCoefficients.find((coefficient) => coefficient.agenciesIds.includes(agency.agencyId))
                &&
                !agencyCoefficients.find((coefficient) => coefficient.agencyId === agency.agencyId && coefficient.lotSurvey && coefficient.lotSurvey.lotSurveyId)
                &&
                !agencyCoefficients.find((coefficient) => coefficient.agencyId === agency.agencyId && coefficient.version && coefficient.version.versionId)
              ">
              <div class="cell auto">
                <p>Pas de coefficients pour cette agence</p>
              </div>
            </div>
          </template>
        </app-accordion>
      </div>
    </div>
    <div class="body" v-else>
      <div class="card spinner-container">
        <app-spinner />
      </div>
    </div>
    <!-- MODAL D'AJOUT -->
    <app-modal
      class="create-coefficient"
      :show="isCreateModalOpen"
      size="medium"
      :title="!coefficient.agencyCoefficientId && !coefficient.brandCoefficientId ? 'Ajouter un coefficient' : 'Modifier le coefficient'"
      @update:show="isCreateModalOpen = false">
      <form ref="agency" @submit.prevent="createCoefficient">
        <div class="grid-x grid-margin-x row">
          <div class="cell">
            <app-select
              label="Type de coefficient"
              v-model="type"
              :options="coefficientTypes"
              required
            />
          </div>
        </div>
        <div
          class="grid-x grid-margin-x row" >
          <div class="cell">
            <app-select
              label="Agence"
              v-model="coefficient.agencyId"
              :options="agenciesOptions"
              @input="agencyChange"
              required
            />
          </div>
        </div>
        <div
          v-if="type === 'lot-survey-coefficient'"
          class="grid-x grid-margin-x row">
          <div class="cell">
            <app-select
              label="Lot métré"
              v-model="coefficient.lotSurveyId"
              :options="lotsSurveys"
              required
            />
          </div>
        </div>
        <div
          v-if="type === 'version-coefficient'"
          class="grid-x grid-margin-x row">
          <div class="cell">
            <app-select
              label="Modèle"
              v-model="selectedModelId"
              :options="models"
              @input="modelChange"
              required
            />
          </div>
        </div>
        <div
          v-if="type === 'version-coefficient'"
          class="grid-x grid-margin-x row">
          <div class="cell">
            <app-select
              label="Version"
              v-model="coefficient.versionId"
              :options="versions"
              :disabled="selectedModelId == null"
            />
          </div>
        </div>
        <div
          v-if="type === 'cities-coefficient' && coefficient && coefficient.agencyId"
          class="grid-x grid-margin-x row">
          <div class="cell">
            <app-multi-select
              v-model="coefficient.postalCodesInseeNumbers"
              :options="postalCodes"
              required
              @input="postalCodeChange"
              @search="searchChange"
            />
          </div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell">
            <app-input
              v-model.number="coefficient.value"
              placeholder="Coefficient..."
              label="Coefficient"
              type="number"
              :step="0.001"
              required />
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto"></div>
          <div class="cell shrink" v-if="coefficient.agencyCoefficientId || coefficient.brandCoefficientId">
            <app-button theme="warning" size="large" icon="remove" @click="openDeleteModal">Supprimer</app-button>
          </div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" icon="add" type="submit">
              {{ !coefficient.agencyCoefficientId && !coefficient.brandCoefficientId ? 'Créer le coefficient' : 'Modifier le coefficient' }}</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
      </form>
    </app-modal>
  </div>
</template>

<script>
import agencyApi from '@/services/api/agency';
import agencyCoefficientApi from '@/services/api/agencyCoefficient';
import brandCoefficientApi from '@/services/api/brandCoefficient';
import postalCodeApi from '@/services/api/postalCode';
import lotSurveyApi from '@/services/api/lotSurvey';
import modelApi from '@/services/api/model';
import versionApi from '@/services/api/version';
import coefficientTypes from '@/services/data/coefficientTypes.json';
import utils from '@/services/utils/utils';

export default {
  name: 'coefficients',
  metaInfo: {
    title: 'Marges',
  },
  data() {
    return {
      coefficientTypes,
      agencies: null,
      agencyCoefficients: null,
      brandCoefficients: null,
      isLoading: true,
      isCreateModalOpen: false,
      type: null,
      coefficient: {
        agencyId: null,
        lotSurveyId: null,
        versionId: null,
        value: null,
        postalCodesInseeNumbers: null,
      },
      models: null,
      selectedModelId: null,
      versions: [
        {
          label: 'Toutes les versions',
          name: null,
        },
      ],
      lotsSurveys: null,
      agenciesOptions: null,
      answer3bis: null,
      postalCodes: null,
      fullPostalCodeSelection: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getAgencies();
    await this.getAgencyCoefficients();
    await this.getBrandCoefficients();
    await this.getModels();
    await this.getLotsSurveys();
    this.isLoading = false;
  },
  methods: {
    async agencyChange() {
      this.fullPostalCodeSelection = null;
      this.coefficient.postalCodesInseeNumbers = null;
      await this.searchChange();
    },
    async modelChange() {
      if (this.selectedModelId != null) {
        try {
          const versions = await versionApi.getVersionsModel(this.selectedModelId);

          const parsedVersions = utils.formatOptions(
            versions.data,
            (o) => o.versionId,
            (o) => o.name,
            'choose',
          );
          parsedVersions[0].label = 'Toutes les versions';
          this.versions = parsedVersions;
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la récupération des versions',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      } else {
        this.versions = [
          {
            label: 'Toutes les versions',
            name: null,
          },
        ];
      }
      this.coefficient.versionId = null;
    },
    async postalCodeChange() {
      if (this.coefficient && this.coefficient.postalCodesInseeNumbers && this.coefficient.postalCodesInseeNumbers.length) {
        const promiseFullPostalCodes = this.coefficient.postalCodesInseeNumbers.map((postalCodeInseeNumber) => postalCodeApi.getOne(postalCodeInseeNumber));
        this.fullPostalCodeSelection = await Promise.all(promiseFullPostalCodes);
      }
    },
    async searchChange(search) {
      try {
        let postalCodes = null;
        if (this.coefficient && this.coefficient.agencyId) {
          postalCodes = await postalCodeApi.getByAgency(this.coefficient.agencyId, search);
        } else {
          postalCodes = await postalCodeApi.getAll(search);
        }

        let parsedlPostalCodes = utils.formatOptions(
          postalCodes,
          (o) => o.postalCodeInseeNumber,
          (o) => o.city,
        );

        if (this.fullPostalCodeSelection && this.fullPostalCodeSelection.length) {
          const parsedFullPostalCodes = utils.formatOptions(
            this.fullPostalCodeSelection,
            (o) => o.postalCodeInseeNumber,
            (o) => o.city,
          );

          parsedlPostalCodes = parsedlPostalCodes.filter((postalCode) => !parsedFullPostalCodes.find((pc) => pc.name === postalCode.name));
          this.postalCodes = parsedFullPostalCodes.concat(parsedlPostalCodes);
        } else {
          this.postalCodes = parsedlPostalCodes;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la recherche de codes postaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencyCoefficients() {
      try {
        this.agencyCoefficients = await agencyCoefficientApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des coefficients d\'agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getBrandCoefficients() {
      try {
        this.brandCoefficients = await brandCoefficientApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des coefficients de marque',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLotsSurveys() {
      try {
        const lotsSurveys = await lotSurveyApi.getAll();
        const parsedlLotsSurveys = utils.formatOptions(
          lotsSurveys,
          (o) => o.lotSurveyId,
          (o) => `${o.name} (${o.category.name})`,
          'choose',
        );
        parsedlLotsSurveys[0].label = 'Choisir un lot métré';

        this.lotsSurveys = parsedlLotsSurveys;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getModels() {
      try {
        const models = await modelApi.getAll(null, null, null, null, true);
        const parsedModels = utils.formatOptions(
          models.data,
          (o) => o.modelId,
          (o) => o.name,
          'choose',
        );
        parsedModels[0].label = 'Choisir un modèle';

        this.models = parsedModels;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des modèles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencies() {
      try {
        this.agencies = await agencyApi.getAll();

        const parsedAgencies = utils.formatOptions(
          this.agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agenciesOptions = parsedAgencies;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async openCoefficientModal(coefficient, agency) {
      if (coefficient) {
        this.coefficient = { ...coefficient };
        if (coefficient.brandId) {
          this.$set(this.coefficient, 'agencyId', agency.agencyId);
          this.type = 'cities-coefficient';
        } else if (coefficient.lotSurvey && coefficient.lotSurvey.lotSurveyId) {
          this.$set(this.coefficient, 'lotSurveyId', coefficient.lotSurvey.lotSurveyId);
          this.type = 'lot-survey-coefficient';
        } else if (coefficient.version && coefficient.version.versionId) {
          const version = await versionApi.getById(coefficient.version.versionId);
          this.selectedModelId = version.model.modelId;
          await this.modelChange();
          this.$set(this.coefficient, 'versionId', coefficient.version.versionId);
          this.type = 'version-coefficient';
        }
        this.searchChange();
        this.postalCodeChange();
      } else {
        this.coefficient = {
          agencyId: null,
          lotSurveyId: null,
          versionId: null,
          value: null,
        };
        this.type = null;
      }
      this.isCreateModalOpen = true;
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer le coefficient',
        text: 'Êtes vous sûr de vouloir supprimer le coefficient ?',
        confirmText: 'Supprimer le coefficient',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteCoefficient();
        },
      });
    },
    async deleteCoefficient() {
      this.isLoading = true;
      try {
        if (this.coefficient && this.coefficient.brandCoefficientId) {
          await brandCoefficientApi.delete(this.coefficient.brandCoefficientId);
        } else {
          await agencyCoefficientApi.delete(this.coefficient.agencyCoefficientId);
        }
        this.$notification.show({ text: 'Ce coefficient a été supprimé avec succès !' });
        this.isCreateModalOpen = false;

        await this.getAgencyCoefficients();
        await this.getBrandCoefficients();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression du coefficient',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async createCoefficient() {
      this.isLoading = true;
      try {
        if (this.type === 'cities-coefficient') {
          if (!this.coefficient.brandCoefficientId) {
            // add brand coefficient
            const parsedCoefficient = {
              postalCodesInseeNumbers: this.coefficient.postalCodesInseeNumbers,
              value: this.coefficient.value,
            };
            await brandCoefficientApi.create(parsedCoefficient);
            this.$notification.show({ text: 'Ce coefficient a été ajouté avec succès !' });
          } else {
            // update brand coefficient
            const parsedCoefficient = {
              brandCoefficientId: this.coefficient.brandCoefficientId,
              postalCodesInseeNumbers: this.coefficient.postalCodesInseeNumbers,
              value: this.coefficient.value,
            };
            await brandCoefficientApi.update(this.coefficient.brandCoefficientId, parsedCoefficient);
            this.$notification.show({ text: 'Ce coefficient a été modifié avec succès !' });
          }
        } else if (!this.coefficient.agencyCoefficientId) {
          // add brand coefficient
          if (this.coefficient.lotSurveyId) {
            // lotSurveyId
            const parsedCoefficient = {
              versionId: null,
              lotSurveyId: this.coefficient.lotSurveyId,
              agencyId: this.coefficient.agencyId,
              value: this.coefficient.value,
            };

            await agencyCoefficientApi.create(parsedCoefficient);
            this.$notification.show({ text: 'Ce coefficient a été ajouté avec succès !' });
          } else {
            // versionId
            let versionIdList = [];
            if (this.coefficient.versionId != null) {
              versionIdList.push(this.coefficient.versionId);
            } else {
              versionIdList = this.versions.map((version) => version.name)
                .filter((versionId) => versionId != null);
            }

            const fulfilledId = [];
            const failedId = [];

            try {
              await Promise.all(versionIdList.map((versionId) => {
                fulfilledId.push(versionId);

                return agencyCoefficientApi.create({
                  versionId,
                  lotSurveyId: null,
                  agencyId: this.coefficient.agencyId,
                  value: this.coefficient.value,
                }).catch(() => failedId.push(versionId));
              }));

              if (failedId.length > 0) throw Error('Une erreur est survenue lors de l\'ajout des coefficients');

              this.$notification.show({ text: 'Les coefficients ont étés ajoutés avec succès !' });
            } catch (err) {
              const failedPromises = this.versions.filter((version) => version.name != null)
                .filter((version) => failedId.includes(version.name))
                .map((version) => version.label);

              const fulfilledPromises = this.versions.filter((version) => fulfilledId.filter((id) => !failedId.includes(id)).includes(version.name))
                .map((version) => version.label);

              this.$message.show({
                title: 'Erreur',
                text: `Il y a eu un problème lors de l'ajout des coefficients :
                ${failedPromises.join(', ')}
                ${fulfilledPromises.length > 0 ? 'Coefficients ajoutés pour les versions :' : ''}
                ${fulfilledPromises.join(', ')}`,
                cancelText: 'Ok',
                hasCancel: true,
              });
            }
          }
        } else {
          // update brand coefficient
          const parsedCoefficient = {
            agencyCoefficientId: this.coefficient.agencyCoefficientId,
            versionId: this.coefficient.versionId,
            lotSurveyId: this.coefficient.lotSurveyId,
            agencyId: this.coefficient.agencyId,
            value: this.coefficient.value,
          };
          await agencyCoefficientApi.update(this.coefficient.agencyCoefficientId, parsedCoefficient);
          this.$notification.show({ text: 'Ce coefficient a été modifié avec succès !' });
        }
        this.isCreateModalOpen = false;

        await this.getAgencyCoefficients();
        await this.getBrandCoefficients();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la modification du coefficient',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.coefficients
  .body
    @include screen
    .accordion-row
      margin: 0
      padding: 15px 0
      border-bottom: 1px solid $line
      h4
        color: $primary
      .coefficient
        padding: 15px 0
        cursor: pointer
        &:hover
          background-color: rgba($background, 0.5)
  .header
    @include header
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
  .search
    margin-bottom: 10px
  .title
    padding-top: 15px
</style>
