import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère tous les lots
 * @param {string?} categoryId: catégorie à rechercher
 */
const getAll = async (categoryId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/lots`, {
      params: {
        ...(categoryId && { categoryId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère tous les lots
 * @param {string?} categoryId: catégorie à rechercher
 * @param {string} brandId: id de la marque
 */
const getAllPublic = async (categoryId, brandId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${brandId}/lots`, {
      params: {
        ...(categoryId && { categoryId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifie un lot
 * @param {string} lotId: id du lot
 * @param {string} description: nouvelle description
 */
const updateLot = async (lotId, description) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/lots/${lotId}`, { description });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const lot = {};
lot.getAll = getAll;
lot.getAllPublic = getAllPublic;
lot.updateLot = updateLot;

export default lot;
