var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"diffusion-statistics"},[_c('form',{ref:"diffusion-statistics"},[_vm._m(0),_c('tab-nav',{attrs:{"tabs":_vm.tabs}}),(!_vm.isLoading)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"grid-x grid-margin-x filter"},[_c('div',{staticClass:"cell medium-3"},[_c('app-datepicker',{attrs:{"language":_vm.french,"monday-first":true,"format":"dd MMMM yyyy","clear-button":false},on:{"input":_vm.dateChanged},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('div',{staticClass:"cell auto"})]),_c('div',{staticClass:"table grid-x"},[_c('div',{staticClass:"cell auto"},[_c('app-table',{staticClass:"diffusion-statistics-table",attrs:{"headers":_vm.tableHeaders,"data":_vm.statistics,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"agency",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}},{key:"citiesCount",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.statistics.citiesCount))])]}},{key:"manualCount",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.statistics.manualCount))])]}},{key:"landCount",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.statistics.landCount))])]}},{key:"landVersionCount",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.statistics.landVersionCount))])]}},{key:"totalAdvertisements",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.statistics.totalAdvertisements))])]}}],null,false,1639463569)},[_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2)],1)])]):_c('div',{staticClass:"body"},[_c('div',{staticClass:"card spinner-container"},[_c('app-spinner')],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Configuration diffusion")])])])}]

export { render, staticRenderFns }