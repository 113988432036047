import axios from 'axios';
import customer from './customer';

const { VUE_APP_API_URL } = process.env;

const getAll = async (queries) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/projects`, {
      params: {
        agencyId: queries.agencyId || null,
        conductorId: queries.conductorId || null,
        search: queries.search || null,
        limit: queries.limit || null,
        offset: queries.offset || null,
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const getById = async (projectId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/projects/${projectId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getProjectsPending = async (queries) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/projects-pending`, {
      params: {
        agencyId: queries.agencyId || null,
        conductorId: queries.conductorId || null,
        search: queries.search || null,
        limit: queries.limit || null,
        offset: queries.offset || null,
        order: queries.order || null,
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const getProjectsTodo = async (queries) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/projects-todo`, {
      params: {
        agencyId: queries.agencyId || null,
        conductorId: queries.conductorId || null,
        search: queries.search || null,
        order: queries.order || null,
        limit: queries.limit || null,
        offset: queries.offset || null,
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des chantiers en cours
 * @param {string} order: paramètre de trie (alphabetique, date, n° de dossier)
 * @param {string} limit: nombre d'éléments à récupérer
 * @param {string} offset: nombre d'éléments à sauter avant de faire la recherche
 * @param {string} search: paramètre de recherche, sur le nom du chantier
 * @param {string} conductorId: paramètre de recherche, sur l'id du conducteur
 * @param {string} agencyId: paramètre de recherche, sur l'id de l'agence
 */
const getProjectsInProgress = async (order, limit, offset, search, conductorId, agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/projects-in-progress`, {
      params: {
        ...(order && { order }),
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(conductorId && conductorId !== 'ALL' && { conductorId }),
        ...(agencyId && agencyId !== 'ALL' && { agencyId }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const getProjectsDone = async (queries) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/projects-done`, {
      params: {
        agencyId: queries.agencyId || null,
        conductorId: queries.conductorId || null,
        search: queries.search || null,
        limit: queries.limit || null,
        offset: queries.offset || null,
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const create = async (project) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/projects`, project);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const update = async (project) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/projects/${project.projectId}`, project);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getByCompany = async (companyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/companies/${companyId}/projects`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getByCustomer = async (customerId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/${customerId}/projects`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const generate = async (projectId, startDate) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/projects/${projectId}/generate`, { startDate });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const deleteOne = async (projectId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/projects/${projectId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const start = async (startDate, projectId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/projects/${projectId}/start`, { startDate });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const close = async (startDate, projectId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/projects/${projectId}/close`, { startDate });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getProjectsSummary = async (agencyId, startDate, endDate) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/${agencyId}/projects/summary`, {
      params: {
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getProjectName = (project) => `${project.reference} - ${project.customer.type === 'PERSON' ? customer.getCustomersNames(project.customer) : project.customer.companyName}`;

const addImageToNote = async (projectId, file) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/projects/${projectId}/note-image`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const removeImageFromNote = async (projectId, removedImagesArray) => {
  const data = { removedImagesArray };
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/projects/${projectId}/note-image`, { data });
    return response.data;
  } catch (er) {
    throw er;
  }
};
const projects = {};
projects.getAll = getAll;
projects.start = start;
projects.close = close;
projects.generate = generate;
projects.getById = getById;
projects.create = create;
projects.update = update;
projects.getByCompany = getByCompany;
projects.delete = deleteOne;
projects.getProjectsPending = getProjectsPending;
projects.getProjectsTodo = getProjectsTodo;
projects.getProjectsInProgress = getProjectsInProgress;
projects.getProjectsDone = getProjectsDone;
projects.getByCustomer = getByCustomer;
projects.getProjectsSummary = getProjectsSummary;
projects.getProjectName = getProjectName;
projects.addImageToNote = addImageToNote;
projects.removeImageFromNote = removeImageFromNote;

export default projects;
