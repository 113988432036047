<template>
  <div class="version-variables hedifys-21">
    <form ref="version-variables" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            :items="[
              { route: { name: 'versions'}, name: 'Versions'},
              { route: { name: 'versions-versionId', params: { versionId: $route.params.versionId }}, name: version.reference }
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <div v-if="!isLoading">
            <h1>{{ version.reference }}</h1>
          </div>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <h2>Options</h2>
        <p>Certaines options du configurateur ont besoin de spécifications plus précises sur la version.</p>
        <div class="card">
          <h3>Spécifications</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-table :headers="tableHeaders" :data="mergedVariables" :loading="isLoading" small>
                <template slot="loading">
                  <app-spinner />
                </template>
                <template slot="empty-table">
                  <p>Aucune données de disponible</p>
                </template>
                <template slot="name" slot-scope="{ data }">
                  <strong>{{ data.name }}</strong>
                </template>
                <template slot="option" slot-scope="{ data }">
                  <p>{{ data.options.length ? formatOptions(data.options) : 'Pas d’options liées' }}</p>
                </template>
                <template slot="value" slot-scope="{ data }">
                  <app-input
                    type="number"
                    placeholder="Valeur..."
                    v-model.number="data.value"
                    :step="data.unit === 'EURO' || data.unit === 'SQUARE_METER' ? 0.01 : 1"
                    :min="0"
                    :max="data.unit === 'PERCENT' ? 100 : null"
                    :unit="formatUnit(data.unit)"
                  />
                </template>
              </app-table>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import versionApi from '@/services/api/version';
import variableApi from '@/services/api/variable';
import units from '@/services/data/units.json';
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import utils from '@/services/utils/utils';

export default {
  name: 'version-variables',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.version.reference ? `${this.version.reference} – Version` : 'Version',
    };
  },
  data() {
    return {
      version: {
        reference: null,
      },
      variables: [],
      variablesSpecifications: [],
      mergedVariables: [],
      tableHeaders: [
        { label: 'Paramètres', key: 'name', size: 4 },
        { label: 'Option(s) liée(s)', key: 'option', size: 5 },
        { label: 'Valeur', key: 'value', size: 3 },
      ],
      utils,
      tabs: [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Prix',
        },
      ],
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      await this.getVersion();
      await this.getVariables();
      await this.getVariablesSpecifications();
      this.mergedVariables = this.variables.map((el) => {
        const variable = this.variablesSpecifications.find((item) => el.variableId === item.variable.variableId);
        return {
          ...el,
          value: variable ? variable.value : 0,
        };
      });
    },
    async getVersion() {
      try {
        this.version = await versionApi.getById(this.$route.params.versionId);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des versions',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getVariables() {
      try {
        this.variables = await variableApi.getAll(null, null, false);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des variables',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getVariablesSpecifications() {
      try {
        const variablesSpecifications = await versionApi.getVariablesSpecifications(this.$route.params.versionId);

        // On converti les variables qui sont de type "EURO" en euros
        this.variablesSpecifications = variablesSpecifications.map((specification) => ({
          ...specification,
          value: specification.variable.unit === 'EURO' ? utils.formatCentToEuro(specification.value) : specification.value,
        }));
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des spécifications de variables',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        // On met des 0 si on a des valeurs vides
        let variablesSpecificationsObject = this.mergedVariables.map((el) => ({
          variableId: el.variableId,
          value: el.value && el.value >= 0 ? el.value : 0,
        }));

        // on converti si on a des valeurs en euros
        variablesSpecificationsObject = this.mergedVariables.map((el) => ({
          variableId: el.variableId,
          value: el.unit === 'EURO' ? utils.formatEuroToCent(el.value) : el.value,
        }));

        await versionApi.updateVariablesSpecifications(
          { variableSpecification: variablesSpecificationsObject },
          this.$route.params.versionId,
        );
        this.$notification.show({ text: 'Version mise à jour avec succès !' });
        await this.getData();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvergarde des spécifications de variables.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    formatUnit(unit) {
      return units.find((u) => u.name === unit).label;
    },
    formatOptions(options) {
      return options.map((o) => o.name).join(', ');
    },
  },
};
</script>

<style lang='sass'>
.version-variables
  .body
    @include screen
    h2
      margin-bottom: 8px
    > p
      margin-bottom: 40px
    .range + .range
      margin-left: 2rem
  form > .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  .app-table .data.auto
    height: initial
    flex: initial
</style>
