var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"diffusion-logs"},[_c('form',{ref:"diffusion-logs",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_vm._m(0),_c('tab-nav',{attrs:{"tabs":_vm.tabs}}),(!_vm.isLoading)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"grid-x grid-margin-x filter"},[_c('div',{staticClass:"cell medium-3"},[_c('app-datepicker',{attrs:{"language":_vm.french,"monday-first":true,"format":"dd MMMM yyyy","clear-button":false},on:{"input":_vm.getData},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('div',{staticClass:"cell auto"})]),_c('app-table',{staticClass:"agencies",attrs:{"headers":_vm.tableHeaders,"data":_vm.diffusionLogs.data,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"reference",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.advertisement.reference))])]}},{key:"agency",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.agency.code))])]}},{key:"type",fn:function(ref){
var data = ref.data;
return [(data.advertisement.isGenerated && data.version && data.version.versionId)?_c('p',[_vm._v("T+M auto")]):(data.advertisement.isGenerated && (!data.version || !data.version.versionId))?_c('p',[_vm._v("T auto")]):(!data.advertisement.isGenerated)?_c('p',[_vm._v("T+M man")]):_vm._e()]}},{key:"land",fn:function(ref){
var data = ref.data;
return [(data.land && data.land.landId)?_c('p',[_vm._v(" "+_vm._s(data.land.lotNumber ? ("Lot " + (data.land.lotNumber) + ", ") : '')+" "+_vm._s(data.land.surface ? ((data.land.surface) + " m², ") : '')+" "+_vm._s(data.land.city ? data.land.city : '')+" ")]):_vm._e()]}},{key:"version",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.version.reference))])]}},{key:"diffuser",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.publishers && data.publishers.length ? data.publishers.map(function (p) { return _vm.publishers[p]; }).join(', ') : '-'))])]}},{key:"action",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.advertisementActions[data.action]))])]}},{key:"publicationDate",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs(data.createdAt).format('DD/MM/YYYY')))])]}}],null,false,444885244)},[_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2),(_vm.diffusionLogs.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.diffusionLogs.metadata.offset,"count":_vm.diffusionLogs.metadata.count}}):_vm._e()],1):_c('div',{staticClass:"body"},[_c('div',{staticClass:"card spinner-container"},[_c('app-spinner')],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Configuration diffusion")])]),_c('div',{staticClass:"cell shrink"})])}]

export { render, staticRenderFns }