<template>
  <div class="agency hedifys-21">
    <form ref="agency" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="agency && agency.name"
            :items="[
              { route: { name: 'agencies'}, name: 'Agences'},
              { route: { name: 'agencies-agencyId', params: { agencyId: $route.params.agencyId }}, name: agency.name}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>{{ agency && agency.name ? agency.name : '' }}</h1>
        </div>
        <div class="cell shrink" v-if="isBrandAdmin">
          <app-button theme="warning" size="large" @click="openDeleteModal">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading && agency">
        <div class="card">
          <h3>Informations générales</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="agency.name" label="Nom de l'agence" placeholder="Nom de l'agence..." required />
            </div>
            <div class="cell auto">
              <app-input v-model="agency.code" label="Code agence" placeholder="Code agence..." size="small" required />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto"></div>
            <div class="cell auto grid-y publication-container">
              <app-label>Publication</app-label>
              <span class="description">L'agence n'est publiable que si toutes les informations requises sont complètes</span>
              <div class="grid-x radio-container">
                <div class="cell auto">
                  <app-radio v-model="agency.isActive" :value="false">Brouillon</app-radio>
                </div>
                <div class="cell auto">
                  <app-radio v-model="agency.isActive" :value="true">Publiée</app-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="grid-x">
            <div class="cell auto">
              <h3>Adresse</h3>
            </div>
            <div class="cell auto">
              <h3>Contact</h3>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="agency.address.addressLine1" label="Adresse" placeholder="Adresse ligne 1..."  required />
            </div>
            <div class="cell auto">
              <app-input v-model="agency.email" label="Email"  placeholder="Email..." required />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="agency.address.addressLine2"  placeholder="Adresse ligne 2..." label="Adresse line 2 (complément)" />
            </div>
            <div class="cell auto">
              <app-input v-model="agency.phone" label="Téléphone 1" placeholder="Téléphone 1..." required />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="agency.address.city" label="Commune"  placeholder="Commune..." required />
            </div>
            <div class="cell auto">
              <app-input v-model="agency.secondaryPhone" label="Téléphone 2" placeholder="Téléphone..." />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell medium-6">
              <app-input v-model="agency.address.postalCode" label="Code postal"  placeholder="Code postal..." required />
            </div>
          </div>
        </div>
        <div class="card">
          <h3>Administratif</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="agency.rcs" label="RCS" placeholder="RCS..." />
            </div>
            <div class="cell auto">
              <app-input v-model="agency.ape" label="APE" placeholder="APE..." />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="agency.siren" label="SIREN" placeholder="SIREN..." />
            </div>
            <div class="cell auto">
              <app-input v-model="agency.vatId" label="TVA intra" placeholder="TVA intra..." />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="agency.siret" label="SIRET" placeholder="SIRET..." />
            </div>
            <div class="cell auto">
              <app-input v-model="agency.status" label="Forme juridique" placeholder="Forme juridique..." />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="agency.corporateName" label="Raison sociale" placeholder="Raison sociale..." />
            </div>
            <div class="cell auto">
              <app-input v-model="agency.scorimmoId" label="Identifiant Scorimmo" placeholder="XXX..."/>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input
                v-model="agency.immodvisorKey"
                label="Clé Immodvisor"
                placeholder="Clé Immodvisor..."
                msg="Format attendu: <em>{data-im-id}:{Clé Widget}</em> (Tous deux à récupérer dans le code de la page avis). Example: <em>31074:1072-EFI2-HU05O-TPZH6-C4E9O0</em>"
              />
            </div>
            <div class="cell auto"></div>
          </div>
        </div>
        <div class="card">
          <div class="grid-x">
            <div class="cell auto">
              <h3>Description de l'agence</h3>
            </div>
            <div class="cell auto">
              <h3>Informations complémentaires</h3>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-textarea v-model="agency.description" label="Décrivez l'agence en quelques mots pour le site web: " placeholder="Description..." />
            </div>
            <div class="cell auto">
              <app-textarea v-model="agency.openingHours" label="Ces informations apparaîtront sur la page web de l'agence: " placeholder="Informations complémentaires..." />
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

export default {
  name: 'agency',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.agency ? `${this.agency.name} – Agence` : 'Agence',
    };
  },
  data() {
    return {
      tabs: [],
      agency: null,
      me: null,
      isBrandAdmin: false,
      isLoading: null,
    };
  },
  async  mounted() {
    this.isLoading = true;
    await this.getMe();
    this.tabs = [
      {
        route: {
          name: 'agencies-agencyId',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Informations',
      },
      {
        route: {
          name: 'agencies-agencyId-postal-codes',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Secteurs',
      },
      {
        route: {
          name: 'agencies-agencyId-medias',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Médias',
      },
      {
        route: {
          name: 'agencies-agencyId-members',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Membres',
      },
    ];
    // Masque l'onglet média aux admins d'agence
    if (!this.isBrandAdmin) {
      const index = this.tabs.map((e) => e.label).indexOf('Médias');
      this.tabs.splice(index, 1);
    }
    await this.getAgency();
    this.isLoading = false;
  },
  methods: {
    async getAgency() {
      try {
        if (this.$route.params.agencyId) {
          this.agency = await agencyApi.getById(this.$route.params.agencyId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de l\'agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.$route.params.agencyId) {
          const formatedAgency = { ...this.agency };
          delete formatedAgency.agencyId;
          delete formatedAgency.createdAt;
          delete formatedAgency.updatedAt;
          delete formatedAgency.deletedAt;

          this.agency = await agencyApi.update(this.$route.params.agencyId, formatedAgency);
          this.$notification.show({ text: 'Cette agence a été modifiée avec succès !' });
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde de l\'agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer l\'agence',
        text: 'Êtes vous sûr de vouloir supprimer cette agence ? \nLes comptes membres ne seront pas supprimés.',
        confirmText: 'Supprimer l\'agence',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteAgency();
        },
      });
    },
    async deleteAgency() {
      if (this.$route.params.agencyId) {
        this.isLoading = true;
        try {
          await agencyApi.delete(this.$route.params.agencyId);
          this.$notification.show({ text: 'Cette agence a été supprimée avec succès !' });

          this.$router.push({ name: 'agencies' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression de l\'agence',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.agency
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  .radio-container
    width: 50%
  .publication-container
    label
      padding-bottom: 0
    .description
      @include details
      color: $info
      padding-bottom: 8px
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
</style>
