<template>
  <div class="advertisement-pack">
    <form ref="advertisementPack" @submit.prevent="save">
      <!-- BREADCRUMBS -->
        <div class="breadcrumbs grid-x row">
          <div class="cell auto">
            <breadcrumb
              v-if="advertisementPack && advertisementPack.name"
              :items="[
                { route: { name: 'diffusions-advertisement-diffusion-packs'}, name: 'Packs de diffusion'},
                { route: { name: 'diffusions-advertisement-diffusion-packs-advertisementPackId', params: { advertisementPackId: $route.params.advertisementPackId }}, name: advertisementPack.name}
              ]"
            />
          </div>
        </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x align-middle">
        <div class="cell" :class="addingVersions ? 'stretch' : 'auto'">
          <h1>{{ title }}</h1>
        </div>
        <template v-if="!addingVersions">
          <div class="cell shrink" v-if="!isNewPack">
            <app-button theme="warning" size="large" icon="remove" @click="openDeleteModal">Archiver</app-button>
          </div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" type="submit">Enregistrer</app-button>
          </div>
        </template>
        <template v-else>
          <div class="cell small-12 back-button">
            <a @click="addingVersions = false"><returnArrow />Revenir au pack</a>
          </div>
        </template>
      </div>
      <div class="spinner-container" v-if="loading">
        <app-spinner />
      </div>
      <AddingVersionsBody v-else-if="addingVersions" class="body" :versionsAdded="versionsId" @update:addingVersions="addingVersions = false" @add:version="addVersion"/>
      <div class="body" v-else>
        <div class="sub-section">
          <h3>Nom</h3>
          <div class="search grid-x">
            <div class="cell shrink">
              <app-input v-model="advertisementPack.name" required placeholder="Nom du pack..."/>
            </div>
          </div>
        </div>
        <div class="sub-section">
          <div class="grid-x align-middle">
            <div class="cell auto">
              <h3>Versions</h3>
            </div>
            <div class="cell shrink">
              <app-button size="small" icon="add" @click="addingVersions = true">Ajouter des versions</app-button>
            </div>
          </div>
          <app-table :headers="tableHeaders" :data="advertisementPack.versions" clickable @line-cliked="goToVersion">
            <template slot="empty-table">
              <p>Aucune données de disponible</p>
            </template>
            <template slot="versionMedia" slot-scope="{ data }">
              <img class="image" v-if="data.versionMediaMain" :src="data.versionMediaMain" alt="Visuel de la version" />
              <img class="image" v-else-if="data.versionMedia" :src="data.versionMedia" alt="Visuel de la version" />
              <div v-else class="image not-found">
                <upload class="icon-upload" />
              </div>
            </template>
            <template slot="name" slot-scope="{ data }">
              <strong>{{ data.name }}</strong>
            </template>
            <template slot="model" slot-scope="{ data }">
              <strong>{{ data.model.name }}</strong>
            </template>
            <template slot="range" slot-scope="{ data }">
              <p>{{ data.range.name }}</p>
            </template>
            <template slot="surface" slot-scope="{ data }">
              <p>{{ data.surface }} m²</p>
            </template>
            <template slot="price" slot-scope="{ data }">
              <strong class="price">{{ utils.formatCentToEuro(data.price, true) }}</strong>
            </template>
            <template slot="action" slot-scope="{ data }">
              <app-button size="small" icon="remove" theme="warning" @click.stop="removeVersion(data)">Retirer</app-button>
            </template>
          </app-table>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import advertisementPackApi from '@/services/api/advertisementPack';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import returnArrow from '@/assets/img/return-arrow.svg?inline';
import upload from '@/assets/img/upload.svg?inline';
import utils from '@/services/utils/utils';

import AddingVersionsBody from './AddingVersionsBody.vue';

export default {
  name: 'advertisement-pack',
  components: {
    Breadcrumb,
    returnArrow,
    upload,
    AddingVersionsBody,
  },
  data() {
    return {
      loading: false,
      advertisementPack: {
        name: '',
        versions: [],
      },
      tableHeaders: [
        { label: 'Visuel', key: 'versionMedia', size: 2 },
        { label: 'Version', key: 'name', size: 3 },
        { label: 'Modèle', key: 'model', size: 2 },
        { label: 'Gamme', key: 'range', size: 2 },
        { label: 'Surface', key: 'surface', size: 1 },
        { label: 'Prix', key: 'price', size: 1 },
        { label: '', key: 'action', size: 1 },
      ],
      utils,
      addingVersions: false,
    };
  },
  computed: {
    isNewPack() {
      return !this.$route.params.advertisementPackId;
    },
    versionsId() {
      return this.advertisementPack.versions.map((v) => v.versionId);
    },
    title() {
      const title = this.isNewPack ? 'Pack de diffusion' : this.advertisementPack.name;
      if (this.addingVersions) {
        return `${title} - Ajout des versions`;
      }
      if (this.isNewPack) {
        return `${title} - Nouveau`;
      }
      return title;
    },
  },
  mounted() {
    this.getAdvertisementPack();
  },
  methods: {
    async getAdvertisementPack() {
      this.loading = true;
      try {
        if (!this.isNewPack) {
          const response = await advertisementPackApi.getById(this.$route.params.advertisementPackId);
          this.advertisementPack = response;
        }
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des packs de diffusion',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.loading = false;
    },
    async save() {
      if (this.isNewPack) {
        await this.createAdvertisementPack();
      } else {
        await this.saveUpdates();
      }
    },
    async saveUpdates() {
      this.loading = true;
      try {
        const response = await advertisementPackApi.update(this.$route.params.advertisementPackId, {
          name: this.advertisementPack.name,
          versions: this.versionsId,
        });
        this.advertisementPack = response;
        this.$notification.show({ text: 'Pack de diffusion mis à jour avec succès' });
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour du pack de diffusion',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.loading = false;
    },
    async createAdvertisementPack() {
      this.loading = true;
      try {
        const response = await advertisementPackApi.create({
          name: this.advertisementPack.name,
          versions: this.versionsId,
        });
        this.$notification.show({ text: 'Pack de diffusion créé avec succès' });
        this.$router.push({ name: 'diffusions-advertisement-diffusion-packs-advertisementPackId', params: { advertisementPackId: response.advertisementPackId } });
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la création du pack de diffusion',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.loading = false;
    },

    // Suppression du pack de diffusion
    async deleteAdvertisementPack() {
      if (this.$route.params.advertisementPackId) {
        this.loading = true;

        try {
          await advertisementPackApi.deleteOne(this.$route.params.advertisementPackId);

          this.$notification.show({ text: 'Ce pack a été archivé avec succès !' });
          this.$router.push({ name: 'diffusions-advertisement-diffusion-packs' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de l\'archivage de ce pack',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }

        this.loading = false;
      }
    },
    goToVersion(selectedVersion) {
      this.$router.push({ name: 'versions-versionId-summary', params: { versionId: selectedVersion.versionId } });
    },
    removeVersion(version) {
      const versionIndex = this.advertisementPack.versions.findIndex((v) => v.versionId === version.versionId);
      this.advertisementPack.versions.splice(versionIndex, 1);
    },
    addVersion(version) {
      this.advertisementPack.versions.push(version);
    },
    // Demande de confirmation pour la suppression de la version
    openDeleteModal() {
      this.$message.show({
        title: 'Archiver le pack de diffusion',
        text: 'Êtes vous sûr de vouloir archiver ce pack?',
        confirmText: 'Archiver le pack',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteAdvertisementPack();
        },
      });
    },
  },
};
</script>

<style lang='sass' scoped>
.advertisement-pack
  .breadcrumbs
    @include breadcrumbs
  .header
    @include header
    .app-button
      margin-left: 16px
  .body
    @include screen
    .sub-section
      margin-bottom: 2rem
    h3
      margin-bottom: 0.5rem
    .app-table
      margin-top: 0.5rem
  .price
    color: $primary
  img
    border-radius: 4px
  .image
    object-fit: cover
    width: 130px
    height: 90px
    border: 1px solid $line
    border-radius: 8px
  .not-found
    @include centered-container
    svg
      width: 50px
  .back-button
    svg
      width: 13px
      margin-right: 10px
      path
        stroke: currentcolor
</style>
