var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-allotments hedifys-21"},[_c('div',{staticClass:"breadcrumbs grid-x row"},[_c('div',{staticClass:"cell auto"},[(_vm.company && _vm.company.companyId && _vm.company.name)?_c('breadcrumb',{attrs:{"items":[
          { route: { name: 'companies'}, name: 'Partenaires'},
          { route: { name: 'companies-companyId', params: { companyId: _vm.$route.params.companyId }}, name: _vm.company.name}
        ]}}):_vm._e()],1)]),_c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('div',[_c('h1',[_vm._v(_vm._s(!_vm.isLoading && _vm.$route.params.companyId && _vm.company ? _vm.company.name : ' '))])])])]),(_vm.$route.params.companyId)?_c('tab-nav',{attrs:{"tabs":_vm.tabs}}):_vm._e(),_c('div',{staticClass:"body"},[_c('div',{staticClass:"search grid-x"},[_c('div',{staticClass:"cell shrink"},[_c('app-search',{attrs:{"size":"small","placeholder":"Rechercher un lotissement"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"cell auto"})]),_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.allotments ? _vm.allotments.data : [],"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToAllotment},scopedSlots:_vm._u([{key:"name",fn:function(ref){
        var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}},{key:"landsCount",fn:function(ref){
        var data = ref.data;
return [_c('p',[_vm._v(_vm._s(((data.activeLandsCount) + " / " + (data.landsCount))))])]}},{key:"postalCode",fn:function(ref){
        var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.address && data.address.postalCodeInseeNumber ? data.address.postalCodeInseeNumber.split('-')[0] : null))])]}},{key:"city",fn:function(ref){
        var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.address.city))])]}},{key:"agency",fn:function(ref){
        var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.agency && data.agency.agencyId ? data.agency.name : '-'))])]}},{key:"createdAt",fn:function(ref){
        var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs(data.createdAt).format('DD/MM/YYYY')))])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2),(_vm.allotments && _vm.allotments.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.allotments.metadata.offset,"count":_vm.allotments.metadata.count}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }