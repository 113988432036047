<template>
  <div class="version-medias hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="version && version.name"
          :items="[
            { route: { name: 'versions'}, name: 'Versions'},
            { route: { name: 'versions-versionId', params: { agencyId: $route.params.agencyId }}, name: version ? version.reference : '' }
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>{{ version ? version.reference : '' }}</h1>
      </div>
      <div class="cell shrink"></div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- FILE UPLOADS -->
    <div class="body">
      <div v-for="mediaType in versionMediaTypes" :key="mediaType.name">
        <div class="row">
          <h2>{{ mediaType.label }}</h2>
          <p>{{ mediaType.description }}</p>
        </div>
        <div v-if="isLoading && (typeLoading === mediaType.name || typeLoading === 'all')">
          <div class="card spinner-container row">
            <app-spinner />
          </div>
        </div>
        <div v-else>
          <div v-if="mediaType.name !== 'VIDEO'" class="grid-x row">
            <div
              class="cell shrink image-container"
              v-for="media in medias.filter(m => m.type === mediaType.name)"
              :key="media.versionMediaId">
              <app-upload-image
                v-model="media.url"
                @upload="(e) => upload(e, mediaType.name, media)"
                @remove="remove(media)"
              />
            </div>
            <div class="cell shrink image-container">
              <app-upload-image @upload="(e) => upload(e, mediaType.name)" />
            </div>
            <div class="cell auto"></div>
          </div>
          <div v-else class="grid-x row">
            <div
              class="cell shrink video-container"
              v-for="media in medias.filter(m => m.type === 'VIDEO')"
              :key="media.versionMediaId">
              <iframe
                width="250"
                height="180"
                :src="media.url"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
              <a @click="remove(media)" class="link">Supprimer</a>
            </div>
            <div class="cell shrink video-container">
              <app-upload-image type="video" @upload="isVideoModalOpen = true" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MEMBER CREATION MODAL --- -->
    <app-modal class="create-member" :show="isVideoModalOpen" size="small" title="Ajouter une vidéo" @update:show="isVideoModalOpen = false">
      <form ref="agency" @submit.prevent="addVideo">
        <div class="grid-x grid-margin-x row">
          <div class="cell">
            <p>Entrer un url de vidéo Youtube. Le lien devrait avoir le format: https://www.youtube.com/embed/abcdefghij</p>
          </div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell"><app-input v-model="videoUrl" label="Url de la vidéo" placeholder="Url de la vidéo..." type="text" required /></div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" icon="add" type="submit">Ajouter</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
      </form>
    </app-modal>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import version from '@/services/api/version';
import versionMedia from '@/services/api/versionMedia';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import versionMediaTypes from '@/services/data/versionMediaTypes.json';

export default {
  name: 'version-medias',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.version ? `${this.version.reference} – Version` : 'Version',
    };
  },
  data() {
    return {
      versionMediaTypes,
      tabs: [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Prix',
        },
      ],
      version: null,

      img: null,
      filename: null,
      versionMediaId: null,
      videoUrl: null,

      isLoading: true,
      isVideoModalOpen: false,
      typeLoading: 'all',
      medias: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getVersion();
    await this.getMedias();
    this.isLoading = false;
    this.typeLoading = null;
  },
  methods: {
    async getMedias() {
      try {
        if (this.$route.params.versionId) {
          this.medias = await versionMedia.getAll(this.$route.params.versionId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des médias',
          confirmText: 'Ok',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getVersion() {
      try {
        if (this.$route.params.versionId) {
          this.version = await version.getById(this.$route.params.versionId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la version',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async addVideo() {
      this.isLoading = true;
      this.typeLoading = 'VIDEO';

      if (this.medias.filter((m) => m.type === 'VIDEO').length) {
        const video = this.medias.filter((m) => m.type === 'VIDEO')[0];

        await this.remove(video);
      }

      try {
        const file = new FormData();
        file.append('type', 'VIDEO');
        file.append('url', this.videoUrl);
        await versionMedia.upload(this.version.versionId, file);

        this.medias = await versionMedia.getAll(this.$route.params.versionId);
        this.isVideoModalOpen = false;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'envoi du média',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.typeLoading = null;
      this.isLoading = false;
    },
    async upload(event, type, media) {
      this.isLoading = true;
      this.typeLoading = type;

      try {
        const file = new FormData();
        file.append('file', event);
        file.append('type', type);

        if (media && media.versionMediaId) {
          file.append('versionMediaId', media.versionMediaId);
        }

        await versionMedia.upload(this.version.versionId, file);

        this.medias = await versionMedia.getAll(this.$route.params.versionId);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'envoi du média',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.typeLoading = null;
      this.isLoading = false;
    },
    async remove(media) {
      this.isLoading = true;
      this.typeLoading = media.type;
      try {
        await versionMedia.delete(this.version.versionId, media.versionMediaId);
        const findIndex = this.medias.findIndex((m) => m.versionMediaId === media.versionMediaId);
        this.medias.splice(findIndex, 1);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression du média',
          cancelText: 'Ok',
          hasCancel: false,
        });
      }
      this.typeLoading = null;
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.version-medias
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
    height: 54px
  .image-container
    margin-right: 24px
    max-width: 180px
    max-height: 110px
  .video-container
    position: relative
    margin-right: 24px
    width: 250px
    height: 180px

    .link
      position: absolute
      right: 0
      bottom: -20px
      text-align: right
      text-decoration: underline
</style>
