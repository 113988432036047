import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des partenaires
 * @param {number} limit: nombre d'éléments à récupérer
 * @param {number} offset: offset de la liste
 * @param {string} search: paramètre de recherche
 * @param {string} postalCode: code postal du partenaire
 * @param {string} type: type de partenaire
 * @param {string} category: catégorie du partenaire
 * @param {string} view: Sélectionner seulement les partenaires favoris ou tous
 * @param {boolean} noLimit: bool, pour savoir si on veut utiliser la pagination ou non
 */
const getAll = async (type, limit, offset, search, postalCode, category, view, noLimit) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/companies`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(postalCode && { postalCode }),
        ...(type && { type }),
        ...(category && { category }),
        ...(view && { view }),
        ...((noLimit || noLimit === false) && { noLimit }),
      },
    });

    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des partenaires ayant au moins un chantier
 * @param {string} category: catégorie du partenaire
 */
const getWithActiveProjectSteps = async (category, view) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/companies/with-active-project-steps`, {
      params: {
        ...(category && { category }),
        ...(view && { view }),
      },
    });

    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupération d'un partenaire
 * @param {string} companyId: id du partenaire à récupérer
 */
const getById = async (companyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/companies/${companyId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'un partenaire
 * @param {object} company: données du partenaire
 */
const create = async (company) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/companies`, company);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un partenaire
 * @param {object} company: données du partenaire (contenant l'id de celui celui)
 */
const edit = async (company) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/companies/${company.companyId}`, company);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression d'un partenaire
 * @param {string} companyId: id du partenaire à supprimer
 */
const deleteOne = async (companyId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/companies/${companyId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const companies = {};
companies.getAll = getAll;
companies.getById = getById;
companies.create = create;
companies.edit = edit;
companies.delete = deleteOne;
companies.getWithActiveProjectSteps = getWithActiveProjectSteps;

export default companies;
