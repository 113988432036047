<template>
  <div class="project hedifys-21">
    <form ref="project" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="project"
            :items="[
              { route: { name: 'projects'}, name: 'Chantiers'},
              { route: { name: 'projects-projectId', params: { projectId: $route.params.projectId }}, name: `${project ? project.reference : '' } ${customerName}`}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x" :class="{ 'includes-tabs': $route.params.projectId }">
        <div class="cell auto">
          <h1>{{`${project ? project.reference : ''} ${customerName}` }}</h1>
        </div>
        <div class="cell shrink" v-if="isBrandAdmin && !isLoading">
          <app-button @click="openDeleteModal" theme="warning" size="large" class="generate">Supprimer</app-button>
        </div>
        <div class="cell shrink save" v-if="!isLoading">
          <app-button v-if="project" theme="primary" size="large" type="submit">Enregistrer</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav v-if="project" :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading && project">
      <h1 class="title">Notes de chantier</h1>
      <section class="editor_container" >
          <VueEditor class="editor" id="editor" v-model="editorContent" useCustomImageHandler @image-added="handleImageAdded" @image-removed="handleImageRemoved" />
      </section>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import { VueEditor } from 'vue2-editor';
import projectApi from '@/services/api/project';
import memberApi from '@/services/api/member';
import customerApi from '@/services/api/customer';

export default {
  name: 'projects-projectId-notes',
  components: {
    TabNav,
    Breadcrumb,
    VueEditor,
  },
  metaInfo() {
    return {
      title: `${this.project.reference} ${this.customerName} – Chantier`,
    };
  },
  data() {
    return {
      tabs: [
        {
          route: {
            name: 'projects-projectId',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'projects-projectId-steps',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Tâches',
        },
        {
          route: {
            name: 'projects-projectId-notes',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Notes',
        },
      ],
      isLoading: null,
      project: null,
      editorContent: null,
      me: null,
      isBrandAdmin: null,
      removedImagesArray: [],
      addedImagesArray: [],
      isSaved: false,
    };
  },
  watch: {
    $route: 'getData',
  },
  async mounted() {
    await this.getData();
  },
  async beforeDestroy() {
    if (this.addedImagesArray && this.addedImagesArray.length > 0 && !this.isSaved) {
      await this.removeImages(this.addedImagesArray);
    }
  },
  computed: {
    customerName() {
      if (this.project && this.customer) {
        return this.customer.type === 'COMPANY' ? this.customer.companyName : customerApi.getCustomersNames(this.customer);
      }
      return '';
    },
  },
  methods: {
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const { data: imageUrl } = await projectApi.addImageToNote(this.project.projectId, formData);
        Editor.insertEmbed(cursorLocation, 'image', imageUrl);
        resetUploader();
        const newAddedImagesArray = this.addedImagesArray;
        newAddedImagesArray.push(imageUrl);
        this.addedImagesArray = newAddedImagesArray;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: error.response.data.message ? error.response.data.message : 'Une erreur est survenue lors de l\'ajout de l\'image',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    handleImageRemoved(imageUrl) {
      const newRemovedImagesArray = this.removedImagesArray;
      newRemovedImagesArray.push(imageUrl);
      this.removedImagesArray = newRemovedImagesArray;
    },
    async removeImages(array) {
      try {
        await projectApi.removeImageFromNote(this.project.projectId, array);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression de l\'image',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer le projet',
        text: 'Êtes vous sûr de vouloir supprimer ce projet ?',
        confirmText: 'Supprimer le projet',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteProject();
        },
      });
    },
    async deleteProject() {
      try {
        await projectApi.delete(this.$route.params.projectId);
        this.$notification.show({ text: 'Ce projet a été supprimée avec succès !' });

        this.$router.push({ name: 'projects' });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression du projet',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getData() {
      this.isLoading = true;
      await this.getMe();
      await this.getProject();
      this.isLoading = false;
    },
    async getProject() {
      try {
        const {
          projectId,
          customer,
          member: { memberId },
          reference,
          model,
          note,
        } = await projectApi.getById(this.$route.params.projectId);
        this.editorContent = note;
        this.customer = customer;
        this.project = {
          projectId,
          customerId: customer.customerId,
          memberId,
          reference,
          model,
          note,
        };
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'L\'obtention des données du projet a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      this.project.note = this.editorContent;

      try {
        if (this.removedImagesArray && this.removedImagesArray.length > 0) {
          await this.removeImages(this.removedImagesArray);
        }
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Un problème est survenu lors de la suppression des images',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      try {
        await projectApi.update(this.project);
        this.$notification.show({ text: 'Le projet a été modifié avec succès !' });
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Un problème est survenu lors de l\'enregistrement du projet',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isSaved = true;
      this.isLoading = false;
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.project
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  h3
    color: $primary
    margin: 0 16px 32px 0px
  h1
    font-weight: 500
    font-size: 25px
    color: $gray-dark
  .save
    margin-left: 16px
  .editor
    background-color: #fff
    em
     color: #000 !important
  .title
    margin-bottom: 2rem
    font-weight: 500
    font-size: 25px
    color: $gray-dark
</style>
