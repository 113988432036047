<template>
  <div class="project-draft-matchings">
    <!-- BO - ONLY -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="projectDraft && projectDraft.name"
          :items="[
            { route: { name: 'customers-projects-drafts'}, name: 'Avant-projets'},
            { route: { name: 'customers-projects-drafts-projectDraftId', params: { projectDraftId: $route.params.projectDraftId }}, name: projectDraft.name }
          ]"
        />
        <breadcrumb
          v-else
          :items="[
            { route: { name: 'customers-projects-drafts'}, name: 'Avant-projets'},
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell shrink header-title">
        <h1>{{ projectDraft && projectDraft.name ? projectDraft.name : 'Nouvel avant-projet' }}</h1>
      </div>
      <div class="grid-x cell shrink">
        <div class="cell auto customer" @click="isCustomerModalOpen = true">
          <app-card-link
            :primary-route="{
              label: customer && customer.firstname ? getCustomersNames(customer) : 'Sélectionnez un client',
            }"
            :description="customer.email"
          />
        </div>
      </div>
    </div>
    <!-- COMMON PUBLIC -->
    <div class="grid-x filter-header-container">
      <!-- FILTRE COMMUNE  -->
      <div class="cell large-3 medium-12 small-12 cities">
        <div class="filter-header grid-x"
          @click="() => openFilter('postalCode')"
          :class="{ 'is-open': isPostalCodeOpen }">
          <div class="cell small-2 icon header-column">
            <location class="location stroke" />
          </div>
          <div class="cell auto title-container header-column">
            <h4 class="filter-name">Commune</h4>
            <div v-if="postalCode && postalCode.postalCodeId">
              <p class="filter-value">{{ postalCode.city }}</p>
            </div>
            <div v-else>
              <p class="filter-value">Pas de commune</p>
            </div>
          </div>
          <div class="cell shrink icon header-column">
            <arrow class="arrow stroke" />
          </div>
        </div>
        <div class="dropdown-filter city" v-if="isPostalCodeOpen">
          <h3>Quelle commune recherchez-vous ?</h3>
          <div class="grid-x">
            <div class="cell auto">
              <config-app-search-city
                placeholder="Nantes - 44000"
                @select="setSelectedPostalCode"
                @clear="resetSelectedPostalCode"
                @submit-city="() => applyPostalCode(true)"
                :selectedPostalCode="selectedPostalCode"
                :showArrow="false"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <!-- FILTRES TERRAIN -->
      <div class="cell large-3 medium-12 small-12 lands">
        <div class="filter-header grid-x"
          @click="() => openFilter('land')"
          :class="{ 'is-open': isLandOpen }">
          <div class="cell small-2 icon header-column">
            <layer class="layer stroke" />
          </div>
          <div class="cell auto title-container header-column">
            <h4 class="filter-name">Terrain</h4>
            <div v-if="land && land.landId && $route.query.landId">
              <p class="filter-value">{{ land.allotment.allotmentId && land.lotNumber ? `Lot n°${land.lotNumber} - ` : '' }}{{ land.surface }}m² - {{ land.price ? utils.formatCentToEuro(land.price, true, true)  : '' }}</p>
            </div>
            <div v-else-if="landChoice === 'land' && (!availableLands || !availableLands.length)">
              <p class="filter-value">Aucun terrain disponible</p>
            </div>
            <div v-else-if="landChoice === 'no-land'">
              <p class="filter-value">Pas de terrains</p>
            </div>
            <div v-else-if="landChoice === 'custom-land'">
              <p class="filter-value" v-if="$route.query.landWidth && $route.query.landPrice">Mon terrain Larg. {{ $route.query.landWidth }} m</p>
              <p class="filter-value" v-else>Définir mon terrain</p>
            </div>
            <div v-else>
              <p class="filter-value">{{ availableLandsMsg }}</p>
            </div>
          </div>
          <div class="cell shrink icon header-column">
            <arrow class="arrow stroke" />
          </div>
        </div>
        <div class="dropdown-filter land" v-if="isLandOpen">
          <!-- CHOIX DU MODE DE TERRAIN  -->
          <form ref="land" @submit.prevent="applyLandChoice">
            <div class="grid-x">
              <div
                class="cell large-auto medium-12 small-12 land-choice grid-x"
                :class="{ 'is-selected': landChoice === 'land' }"
                @click="() => landChoiceChange('land')">
                <div class="cell shrink">
                  <search-land class="land-icon" />
                </div>
                <div class="cell auto">
                  <p class="cta">Choisir mon terrain</p>
                  <p class="land-count" v-if="allLands && allLands.length">
                    {{ allLands.length }} terrain{{ allLands.length > 1 ? 's' : '' }} disponible{{ allLands.length > 1 ? 's' : '' }}
                  </p>
                </div>
              </div>
              <div
                class="cell large-auto medium-12 small-12 land-choice grid-x"
                :class="{ 'is-selected': landChoice === 'custom-land' }"
                @click="() => landChoiceChange('custom-land')">
                <div class="cell shrink">
                  <my-land class="land-icon" />
                </div>
                <div class="cell auto">
                  <p class="cta">J'ai mon terrain</p>
                </div>
              </div>
              <div
                class="cell large-auto medium-12 small-12 land-choice grid-x"
                :class="{ 'is-selected': landChoice === 'no-land' }"
                @click="() => landChoiceChange('no-land')">
                <div class="cell shrink">
                  <later-land class="land-icon" />
                </div>
                <div class="cell auto">
                  <p class="cta">A définir plus tard</p>
                </div>
              </div>
            </div>
          </form>
          <!-- CHOIX DES TERRAINS, POUR LA COMMUNE  -->
          <div class="land-detail" v-if="landChoice === 'land' && availableLands && availableLands.length">
            <div
              class="allotment-container"
              v-for="(landsByAllotment, index) in availableLands"
              :key="landsByAllotment.allotment && landsByAllotment.allotment.allotmentId ? landsByAllotment.allotment.allotmentId : index">
              <div class="allotment-header grid-x">
                <div class="cell auto">
                  <h5>
                    <slot v-if="postalCode && postalCode.city">{{ postalCode.city }} - </slot>
                    {{ landsByAllotment && landsByAllotment.allotment && landsByAllotment.allotment.name ? landsByAllotment.allotment.name : 'Terrains sans lotissements' }}
                  </h5>
                  <div class="company" v-if="landsByAllotment.company && landsByAllotment.company.companyId">
                    <p>Lotissement proposé par notre partenaire</p>
                    <router-link :to="{ name: 'companies-companyId', params: { companyId: landsByAllotment.company.companyId }}" target='_blank'>{{ landsByAllotment.company.name }}</router-link>
                  </div>
                </div>
                <div
                  v-if="landsByAllotment && landsByAllotment.allotment && landsByAllotment.allotment.presentationPdf && landsByAllotment.allotment.presentationPdf.includes('https://')"
                  class="cell shrink download"
                  @click="downloadPdf(landsByAllotment.allotment)">
                  <document />
                  <p>Voir la fiche<br>lotissement</p>
                </div>
              </div>
              <div class="allotment-lands" v-if="landsByAllotment && landsByAllotment.lands.length">
                <div v-for="(land, index) in landsByAllotment.lands" :key="land && land.landId ? land.landId : index" class="grid-x">
                  <div class="cell auto land-name">
                    <p>{{ land.lotNumber ? `Lot n°${land.lotNumber} - ` : '' }}{{ land.surface ? `${land.surface} m²` : '' }}</p>
                  </div>
                  <div class="cell shrink">
                    <h3>{{ land.price || land.price === 0 ? utils.formatCentToEuro(land.price, true, true)  : '' }}</h3>
                  </div>
                  <div class="cell shrink land-radio">
                    <app-radio v-if="land" v-model="landId" :value="land.landId" @input="landChange(land)"></app-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="land-detail land" v-else-if="landChoice === 'land' && (!availableLands || !availableLands.length)">
            <h3>Aucun terrain disponible pour cette commune</h3>
            <p>Nous n’avons actuellement aucun terrain sur cette commune. Vous pouvez voir les terrains disponibles sur les communes alentours directement sur la carte ci-dessous.</p>
            <app-button v-if="nearPostalCodes && nearPostalCodes.length > 1" size="large" theme="primary" @click="nextPostalCode">Voir les terrains les plus proches</app-button>
          </div>
          <!-- FORMULAIRE DE TERRAIN CUSTOM  -->
          <div class="land-detail custom-land" v-if="landChoice === 'custom-land'">
            <h3>Personnaliser votre recherche de maison avec votre terrain</h3>
            <p>
              Pour afficher les modèles de maisons compatibles avec votre terrain, renseignez les informations ci-dessous.
              Sinon, cliquez sur le bouton « A définir plus tard ».
            </p>
            <form ref="custom-change" @submit.prevent="applyCustomLand">
              <div class="grid-x grid-margin-x row">
                <div class="cell large-auto medium-12 small-12">
                  <app-input v-model.number="landWidth" :min="0" :step="0.01" label="Largeur du terrain" placeholder="Largeur..." type="number" unit="m" required />
                </div>
                <div class="cell large-auto medium-12 small-12">
                  <app-input v-model.number="landPrice" :min="1" :step="1" label="Prix du terrain" placeholder="Prix..." type="number" unit="€" required />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell large-auto medium-12 small-12">
                  <app-input v-model.number="landSurface" :step="0.01" label="Superficie du terrain" placeholder="Superficie..." type="number" unit="m²" />
                </div>
                <div class="cell large-auto medium-12 small-12">
                  <p><span class="label">Nature du terrain</span></p>
                  <app-checkbox
                    value="true"
                    v-model="isClayZone"
                  >
                    <span class="checkbox-value">Zone argileuse</span>
                  </app-checkbox>
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell large-auto medium-12 small-12">
                  <app-radio-button
                    label="Assainissement"
                    name="isMainDrainagePlanned"
                    :value="isMainDrainagePlanned"
                    v-model="isMainDrainagePlanned"
                    :options="[
                      { name: 'true', label: 'Tout à l\'égout' },
                      { name: 'false', label: 'Autonome' }
                    ]"
                  />
                </div>
                <div class="cell large-auto medium-12 small-12">
                  <app-radio-button
                    label="Aménagement"
                    name="isServiced"
                    :value="isServiced"
                    v-model="isServiced"
                    :options="[
                      { name: 'true', label: 'Viabilisé' },
                      { name: 'false', label: 'Non viabilisé' }
                    ]"
                  />
                </div>
              </div>
              <app-button size="large" type="submit">Appliquer pour mon terrain</app-button>
            </form>
          </div>
          <div class="land-detail no-land" v-if="landChoice === 'no-land'">
            <h3>Découvrez tous nos modèles de maisons</h3>
            <p>Accédez immédiatement à l’intégralité de notre catalogue sans terrain. Par la suite, vous pourrez toujours compléter votre projet.</p>
            <app-button size="large" theme="primary" @click="isLandOpen = false">Voir les modèles</app-button>
          </div>
        </div>
      </div>
      <!-- FILTRE DES VERSION  -->
      <div class="cell large-auto medium-12 small-12 filters">
        <div
          class="filter-header grid-x desktop"
          @click="() => openFilter('version')"
          :class="{ 'is-open': isVersionOpen }">
          <div class="cell small-2 icon header-column">
            <filters class="filter stroke" />
          </div>
          <div class="cell shrink header-column">
            <p class="filter-value">Filtrer</p>
          </div>
          <div class="cell auto bubbles-container grid-x" v-if="models && models.length && ranges && ranges.length && floors && floors.length">
            <div class="bubble cell shrink" v-if="modelId" @click.stop="() => removeFilter('modelId')">
              {{ models.find((model) => model.name === modelId) ? models.find((model) => model.name === modelId).label : '' }}
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-for="range in selectedRanges" :key="range" @click.stop="() => removeFilter('selectedRanges', range)">
              {{ ranges.find((r) => r.name === range) ? ranges.find((r) => r.name === range).label : '' }}
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="roomCount" @click.stop="() => removeFilter('roomCount')">
              {{ roomCount }} chambre{{ roomCount > 1 ? 's' : '' }}
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="bathroomCount" @click.stop="() => removeFilter('bathroomCount')">
              {{ bathroomCount }} salle{{ bathroomCount > 1 ? 's' : '' }} de bain
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="surfaceRange && surfaceRange.start" @click.stop="() => removeFilter('surfaceRange')">
              {{ surfaceRange.start }}m² - {{ surfaceRange.end }}m²
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="floorId" @click.stop="() => removeFilter('floorId')">
                {{ floors.find((floor) => floor.name === floorId) ? floors.find((floor) => floor.name === floorId).label : null }}
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="priceRange && (priceRange.start && priceRange.start !== 0 || priceRange.end && priceRange.end !== 600000)" @click.stop="() => removeFilter('priceRange')">
              {{ priceRange.start }}€ - {{ priceRange.end }}€
              <remove class="remove-option" />
            </div>
            <div class="bubble cell shrink" v-if="garage" @click.stop="() => removeFilter('garage')">
              {{ garage === 'withGarage' ? 'Avec garage' : 'Sans garage' }}
              <remove class="remove-option" />
            </div>
          </div>
          <div v-else class="cell auto">
          </div>
          <div class="cell shrink icon">
            <arrow class="arrow stroke" />
          </div>
        </div>
        <div class="dropdown-filter-version" v-if="isVersionOpen">
          <div class="filter-body">
            <div class="grid-x row grid-margin-x">
              <div class="cell large-auto medium-12 small-12">
                <app-radio-button
                  label="Trier par"
                  name="orderBy"
                  :value="orderBy"
                  v-model="orderBy"
                  :options="[
                    { name: 'price', label: 'Prix croissant' },
                    { name: '-price', label: 'Prix décroissant' },
                  ]"
                />
              </div>
              <div class="cell large-auto medium-12 small-12">
                <app-double-slider
                  label="Budget"
                  :min="0"
                  :max="600000"
                  unit="€"
                  v-model="priceRange"
                  :value="priceRange"
                  :step="1000"
                />
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell large-auto medium-12 small-12" v-if="ranges">
                <app-label>Gammes :</app-label>
                  <div class="range" v-for="range in ranges" :key="range.rangeId">
                    <app-checkbox
                      v-if="range"
                      v-model="selectedRanges"
                      :value="range.name"
                    >{{ range.label }}</app-checkbox>
                  </div>
              </div>
              <div class="cell large-auto medium-12 small-12">
                <app-select
                  label="Modèles"
                  v-model="modelId"
                  :options="models"
                />
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell large-auto medium-12 small-12">
                <app-radio-button
                  label="Niveau"
                  name="floor"
                  :value="floorId"
                  v-model="floorId"
                  :options="floors"
                />
              </div>
              <div class="cell large-auto medium-12 small-12">
                <app-double-slider
                  label="Surfaces habitables"
                  :min="0"
                  :max="300"
                  unit="m²"
                  v-model="surfaceRange"
                  :value="surfaceRange"
                />
                <app-radio-button
                  class="filter-garage"
                  label="Garage"
                  name="garage"
                  :value="garage"
                  v-model="garage"
                  :options="[
                    { name: 'withoutGarage', label: 'Sans garage' },
                    { name: 'withGarage', label: 'Avec garage' },
                  ]"
                />
              </div>
            </div>
            <div class="grid-x row grid-margin-x">
              <div class="cell large-auto medium-12 small-12">
                <app-radio-button
                  label="Chambres"
                  name="roomCount"
                  :value="roomCount"
                  v-model="roomCount"
                  :options="[
                    { name: '1', label: '1' },
                    { name: '2', label: '2' },
                    { name: '3', label: '3' },
                    { name: '4', label: '4' },
                    { name: '5', label: '5' },
                  ]"
                />
              </div>
              <div class="cell large-auto medium-12 small-12">
                <app-radio-button
                  label="Salles de bains"
                  name="bathroomCount"
                  :value="bathroomCount"
                  v-model="bathroomCount"
                  :options="[
                    { name: '1', label: '1' },
                    { name: '2', label: '2' },
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="filter-bottom grid-x">
            <div class="cell shrink">
              <app-button theme="primary" @click="() => applyVersionFilters(true)">Afficher versions</app-button>
            </div>
            <div class="cell auto"></div>
            <div class="cell shrink init" @click="resetVersionFilter">
              <p>Réinitialiser</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BODY -->
    <div class="list-container">
      <div v-if="isPostalCodeOpen || isLandOpen || isVersionOpen" class="cover" @click="closeFilter"></div>
      <div class="grid-x">
        <div class="cell large-6 medium-12 small-12 map desktop" :class="{'is-open': isPostalCodeOpen }" v-if="landChoice === 'land'">
          <!-- CARTE DES TERRAINS -->
          <matching-map
            v-if="showMap"
            :postalCodes="nearPostalCodes"
            :selectedPostalCode="postalCode"
            :height="500"
            @input="mapClicked"
            @mapdrag="mapDragged"
          />
        </div>
        <div class="cell large-auto medium-12 small-12 list" :class="{'is-open': !isPostalCodeOpen && !isLandOpen && !isVersionOpen }" v-if="!isLoading && filteredMatchings && filteredMatchings.length">
          <div v-if="viewType === 'version'" class="page-body" ref="pageBodyVersion">
            <div class="up">
              <div class="link" @click="goBackToModels">
                <return-arrow />
                <span>Retour aux modèles</span>
              </div>
              <app-toggle
                name='toggle'
                :icons='["blueprint", "house"]'
                v-model="imagesType"
                :selectedIcon="$route.query.imagesType || 'house'"
                @input="updateImages"
              />
            </div>
            <!-- LISTE DES MATCHINGS -->
            <div class="grid-x">
              <div class="cell large-auto medium-12 small-12">
                <h2>Nos {{ filteredMatchings && filteredMatchings.length ? `${filteredMatchings.length} versions ${modelId && models.find((m) => m.name === modelId) ? ` du modèle ${models.find((m) => m.name === modelId).label}` : ''} à ${postalCode ? postalCode.city : ''}` : ' ' }}</h2>
              </div>
            </div>
            <div class="grid-x grid-margin-x">
              <div
                class="cell small-12 medium-6"
                :class="{ 'large-6': landChoice === 'land', 'large-3': landChoice !== 'land' }"
                v-intersection="handleCardIntersection"
                :data-version="matching.version.versionId"
                v-for="(matching, index) in filteredMatchings"
                :key="matching && matching.version ? matching.version.versionId : index"
                @click="() => goToConfiguration(matching)"
                >
                <matching-card :matching="matching" :landChoice="landChoice" :imagesType="imagesType"/>
              </div>
            </div>
          </div>

          <div v-else-if="versionsByModels && versionsByModels.length" class="page-body">
            <!-- LISTE DES MODELES -->
            <div class="grid-x">
              <div class="cell auto">
                <h2>Nos {{ versionsByModels && versionsByModels.length ? `${versionsByModels.length} modèles de maisons pour construire à ${postalCode ? postalCode.city : ''}` : ' ' }}</h2>
              </div>
            </div>
            <div class="grid-x grid-margin-x">
              <div
                class="cell matching card model-card small-12 medium-6"
                :class="{
                  'large-6': landChoice === 'land',
                  'large-3': landChoice !== 'land',
                  'no-land': model && !filteredMatchings.find((matching) => matching && matching.version && matching.version.model.modelId === model.modelId && matching.land) && landChoice !== 'no-land'
                }"
                v-intersection="handleCardIntersection" :data-version="model.displayedVersion"
                @click="() => selectModel(model)"
                v-for="(model, index) in versionsByModels" :key="model && model.modelId ? model.modelId : index">
                <img v-if="model && model.image" :src="model.image" />
                <div v-else class="image-not-found">
                  <upload class="icon-upload" />
                </div>
                <div class="grid-x">
                  <div class="cell auto">
                    <h4 v-if="model && model.name" class="model">Modèle</h4>
                    <h5 v-if="model.name">{{ model.name.includes('| INVEST') ?  model.name.replace('| INVEST', '') : model.name }} <small v-if="model.name.includes('| INVEST') ">INVEST</small></h5>
                    <p class="version-count" v-if="model.versionCount">{{ model.versionCount }} version{{ model.versionCount > 1 ? 's' : '' }}</p>
                  </div>
                  <div class="cell auto">
                    <div class="price-container">
                      <h4 class="model">À partir de</h4>
                      <div class="price" v-if="model && landChoice === 'no-land' && (model.priceWithoutAdditionalFees || model.priceWithoutAdditionalFees === 0)">
                        <h3>{{ model.priceWithoutAdditionalFees || model.priceWithoutAdditionalFees === 0 ? utils.formatCentToEuro(model.priceWithoutAdditionalFees, true, true)  : '' }}</h3>
                      </div>
                      <div class="price" v-else-if="model && landChoice !== 'no-mand' && (model.price || model.price === 0)">
                        <h3>{{ model.price || model.price === 0 ? utils.formatCentToEuro(model.price, true, true)  : '' }}</h3>
                      </div>
                      <div v-else class="spinner-container">
                        <app-spinner small />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cell auto main-loading" v-else-if="isLoading">
          <div class="spinner-container">
            <app-spinner />
          </div>
        </div>
        <div v-else class="empty-list">
          <p>Aucun modèle de maison pour les filtres demandées</p>
        </div>
      </div>
    </div>
    <!-- BO - ONLY -->
    <SelectCustomerModal
      :show="isCustomerModalOpen"
      title="Choisir un prospect"
      @update:show="isCustomerModalOpen = false"
      v-model="customer"
      :isParentLoading="isLoading"/>
    <!-- COMMON PUBLIC -->
    <div class="edit-configuration grid-x" v-if="$route.name.includes('edit')" :class="{ 'hide-config': isPostalCodeOpen || isLandOpen || isVersionOpen }">
      <div class="cell shrink grid-x">
        <div class="cell shrink">
          <house />
        </div>
        <div class="cell shrink status">
          <p>Projet<br>en-cours</p>
        </div>
      </div>
      <div class="cell auto configuration desktop" v-if="selectedMatching">
        <p class="version-info" v-if="selectedMatching.version">Maison {{ selectedMatching.version.name }} {{ selectedMatching.version.numberOfRooms }} Chambre{{ selectedMatching.version.numberOfRooms > 1 ? 's' : '' }} à {{ postalCode.city }}</p>
        <p class="land-info" v-if="selectedMatching.land && selectedMatching.land.allotment && selectedMatching.land.allotment.allotmentId">{{ `Lotissement ${selectedMatching.land.allotment.name}${selectedMatching.land.lotNumber ? ` - Lot n°${selectedMatching.land.lotNumber}` : ''} - ${selectedMatching.land.surface} m²` }}</p>
        <p class="land-info" v-else-if="selectedMatching.land && selectedMatching.land.surface">{{ `Terrain ${selectedMatching.land.surface} m²` }}</p>
      </div>
      <div class="auto" v-else-if="isPreviousMatchingInit">
        <div>Nous n'avons pas pu reprendre la même version, veuillez faire une nouvelle sélection</div>
      </div>
      <div v-else class="edit-loader">
        <app-spinner small />
      </div>
      <div class="cell large-shrink medium-6 small-6" v-if="selectedMatching">
        <p class="surface">{{ selectedMatching.totalSurfaceHabitable ? `${Math.round(selectedMatching.totalSurfaceHabitable)} m²` : '' }}</p>
        <p class="price" v-if="landChoice === 'no-land' && selectedMatching.priceWithoutAdditionalFees">{{ utils.formatCentToEuro(selectedMatching.priceWithoutAdditionalFees, true, true) }}</p>
        <p class="price" v-else-if="landChoice !== 'no-land' && selectedMatching.price">{{ utils.formatCentToEuro(selectedMatching.price, true, true) }}</p>
        <p class="price" v-else><app-spinner small /></p>
      </div>
      <div class="cell shrink">
        <app-button theme="secondary" size="small" @click="cancelConfigEdit">Annuler</app-button>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="small" @click="applyConfigEdit">Continuer</app-button>
      </div>
    </div>
  </div>
</template>

<script>
// BO ONLY
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import ConfigAppSearchCity from '@/views/projectDraft/ConfigAppSearchCity.vue';
import customerApi from '@/services/api/customer';
import auth from '@/services/auth';

// COMMON PUBLIC
import configurationApi from '@/services/api/configuration';
import postalCodeApi from '@/services/api/postalCode';
import projectDraftApi from '@/services/api/projectDraft';
import landApi from '@/services/api/land';
import roomApi from '@/services/api/room';
import floorApi from '@/services/api/floor';
import rangeApi from '@/services/api/range';
import modelApi from '@/services/api/model';
import utils from '@/services/utils/utils';

import MatchingMap from '@/views/projectDraft/MatchingMap.vue';
import SelectCustomerModal from '@/components/general/SelectCustomerModal.vue';

import upload from '@/assets/img/upload.svg?inline';
import arrow from '@/assets/img/nav/arrow.svg?inline';
import location from '@/assets/img/location.svg?inline';
import layer from '@/assets/img/nav/layer.svg?inline';
import filters from '@/assets/img/filter.svg?inline';
import laterLand from '@/assets/img/later-land.svg?inline';
import myLand from '@/assets/img/my-land.svg?inline';
import searchLand from '@/assets/img/search-land.svg?inline';
import document from '@/assets/img/document.svg?inline';
import remove from '@/assets/img/remove.svg?inline';
import returnArrow from '@/assets/img/return-arrow.svg?inline';
import house from '@/assets/img/house.svg?inline';

import matchingCard from './MatchingCard.vue';

export default {
  name: 'project-draft-matchings',
  components: {
    // BO ONLY
    Breadcrumb,
    ConfigAppSearchCity,
    SelectCustomerModal,

    // COMMON PUBLIC
    upload,
    location,
    arrow,
    filters,
    layer,
    document,
    'later-land': laterLand,
    'my-land': myLand,
    'search-land': searchLand,
    'return-arrow': returnArrow,
    remove,
    MatchingMap,
    house,
    matchingCard,
  },
  metaInfo() {
    return {
      title: 'Nouvel avant-projet',
    };
  },
  data() {
    return {
      // BO ONLY
      customer: {
        customerId: null,
      },
      isCustomerModalOpen: false,
      brand: null,

      // COMMON PUBLIC
      isLoading: null,

      // config params
      postalCode: {
        postalCodeId: null,
        postalCodeInseeNumber: null,
      },
      projectDraft: null,
      land: null,
      landId: null,
      // custom land
      landWidth: null,
      landSurface: null,
      landPrice: null,
      isMainDrainagePlanned: null,
      isServiced: null,
      isClayZone: null,

      // Infos pour les filtres
      matchings: null,
      allLands: null,
      availableLands: null,
      allotments: null,
      utils,
      // la sélection peut être 'land', 'no-land', ou 'custom-land'
      landChoice: 'land',

      // listes pour les filtres de version
      rooms: null,
      garage: null,
      models: null,
      ranges: null,
      floors: null,

      // filtres pour la version, front
      filteredMatchings: null,
      orderBy: null,
      priceRange: {
        start: 0,
        end: 600000,
      },
      surfaceRange: {
        start: 0,
        end: 300,
      },
      floorId: null,
      roomCount: null,
      bathroomCount: null,
      selectedRanges: [],
      modelId: null,

      // inputs de filtres
      selectedPostalCode: null,
      selectedPostalCodeInseeNumber: null,

      // la vue peut être 'version' ou 'model'
      viewType: 'model',
      versionsByModels: null,

      // Conditions pour l'interface
      isPostalCodeOpen: false,
      isVersionOpen: false,
      isLandOpen: false,

      nearPostalCodes: null,

      // Le nouveau matching sélectionné
      selectedMatching: null,
      // La configuration précédente pour le retour en arrière
      previousConfiguration: null,
      previousCustomerLand: null,
      previousPostalCodeInseeNumber: null,
      previousCustomerId: null,

      isPreviousMatchingInit: false,
      isMapLoading: false,
      showMap: true,

      allModels: null,

      imagesType: 'house',

      // prix à la demande
      pricedVersions: {},
      toPriceVersions: {},
    };
  },
  async created() {
    await this.getData();
  },
  watch: {
    // Déclenche la recherche de produits à chaque changement de query
    async $route() {
      if (!this.$route.query.modelId && this.modelId) {
        this.modelId = null;
      }

      this.applyVersionFilters(true);
    },
    customer() {
      if (this.customer) {
        this.selectCustomer();
      }
    },
  },
  computed: {
    availableLandsMsg() {
      let msg = 'Tous les terrains';
      if (this.allLands && this.allLands.length) {
        if (this.allLands.length === 1) {
          msg = 'Voir le terrain disponible';
        } else {
          msg = `Voir les ${this.allLands.length} terrains disponibles`;
        }
      }
      return msg;
    },
  },
  methods: {
    async handleCardIntersection(event) {
      if (!this.toPriceVersions[event.target.dataset.version]) {
        this.toPriceVersions[event.target.dataset.version] = true;
      }
      this.setPrices();
    },
    initPricingSystems() {
      this.pricedVersions = {};
      this.toPriceVersions = {};
    },
    removePrivateModels() {
      if (this.versionsByModels && this.versionsByModels.length) {
        const versionsModelIds = this.versionsByModels.map((version) => version.modelId);
        this.models = this.models.filter((model) => versionsModelIds.includes(model.name));
      }
    },
    // ONLY BO
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async selectCustomer() {
      this.isLoading = true;

      if (this.customer && this.$route.query.customerId !== this.customer.customerId) {
        try {
          await this.$router.push({
            query: {
              ...this.$route.query,
              customerId: this.customer.customerId,
              postalCodeInseeNumber: this.customer && this.customer.address ? (this.customer.address.postalCodeInseeNumber || this.customer.address.postalCode) : undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }

        await this.getPostalCode();
        if (this.$route.query.postalCodeInseeNumber) {
          this.initPricingSystems();

          // il faut au moins le code postal du customer pour aller chercher des matchings
          await this.getMatchings();
          await this.resetVersionFilter();
        }
      }
      this.isCustomerModalOpen = false;
      this.isLoading = false;
    },
    async getCustomer() {
      try {
        if (this.$route.query && (this.$route.query.customerId || this.$route.query.projectDraftId)) {
          const customerId = this.projetDraft && this.projetDraft.customer ? this.projectDraft.customer.customerId : this.$route.query.customerId;
          this.customer = await customerApi.getById(customerId);
          const customerPostalCode = this.customer && this.customer.address ? (this.customer.address.postalCodeInseeNumber || this.customer.address.postalCode) : undefined;
          const newPostalCode = !this.$route.query.postalCodeInseeNumber ? customerPostalCode : this.$route.query.postalCodeInseeNumber;

          if (this.$route.query.customerId !== this.customer.customerId || newPostalCode !== this.$route.query.postalCodeInseeNumber) {
            try {
              await this.$router.push({
                query: {
                  ...this.$route.query,
                  customerId: this.customer.customerId,
                  postalCodeInseeNumber: !this.$route.query.postalCodeInseeNumber ? customerPostalCode : this.$route.query.postalCodeInseeNumber,
                },
              });
            } catch (er) {
              if (er.name !== 'NavigationDuplicated') {
                throw er;
              }
            }
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du customer',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Récupération du nom du client
    getCustomersNames(selectedCustomer) {
      return customerApi.getCustomersNames(selectedCustomer);
    },
    async mapDragged(coordinates) {
      if (!this.isMapLoading) {
        try {
          if (coordinates && coordinates.latitude) {
            this.isMapLoading = true;
            this.nearPostalCodes = await postalCodeApi.getByPostalCode(
              this.brand.brandId,
              null,
              30,
              coordinates.latitude,
              coordinates.longitude,
            );
            this.isMapLoading = false;
          }
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la récupération des codes postaux proches',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    async landChoiceChange(choice) {
      this.landChoice = choice;
      if (choice !== 'custom-land') {
        this.isLoading = true;
        try {
          await this.$router.push({
            query: {
              ...this.$route.query,
              landChoice: choice,
              landWidth: undefined,
              landSurface: undefined,
              landPrice: undefined,
              isMainDrainagePlanned: undefined,
              isServiced: undefined,
              isClayZone: undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }

        this.landWidth = null;
        this.landSurface = null;
        this.landPrice = null;
        this.isMainDrainagePlanned = null;
        this.isServiced = null;
        this.isClayZone = null;

        if (this.landChoice === 'no-land') {
          this.landId = null;
          this.land = null;
        }
        try {
          if (this.landChoice === 'no-land') {
            await this.$router.push({
              query: {
                ...this.$route.query,
                emptyLand: true,
                landId: undefined,
              },
            });
            this.landId = null;
            this.land = null;
          } else if (this.$route.query.emptyLand !== this.emptyLand || this.landChoice === 'land') {
            await this.$router.push({
              query: {
                ...this.$route.query,
                emptyLand: undefined,
              },
            });
          }
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }
        this.initPricingSystems();
        await this.getMatchings();
        await this.resetVersionFilter();
        this.isLoading = false;
      } else {
        this.isMainDrainagePlanned = 'true';
        this.isServiced = 'true';
      }
    },
    async getData() {
      this.isLoading = true;
      // On récupère la marque
      await this.getBrand();

      // On récupère les données pour les filtres versions
      await this.getRanges();
      await this.getModels();
      await this.getRooms();
      await this.getFloors();

      this.landPrice = this.$route.query.landPrice ? Math.round(utils.formatCentToEuro(this.$route.query.landPrice)) : null;
      this.landSurface = this.$route.query.landSurface;
      this.landWidth = this.$route.query.landWidth;
      this.isMainDrainagePlanned = typeof this.$route.query.isMainDrainagePlanned === 'boolean' ? this.$route.query.isMainDrainagePlanned.toString() : this.$route.query.isMainDrainagePlanned;
      this.isServiced = typeof this.$route.query.isServiced === 'boolean' ? this.$route.query.isServiced.toString() : this.$route.query.isServiced;

      this.isClayZone = (typeof this.$route.query.isClayZone === 'boolean' && this.$route.query.isClayZone) || this.$route.query.isClayZone === 'true' ? ['true'] : null;
      this.emptyLand = typeof this.$route.query.emptyLand === 'boolean' ? this.$route.query.emptyLand.toString() : this.$route.query.emptyLand;

      // await this.getCustomers();
      if (this.$route.query && !this.$route.query.projectDraftId && !this.$route.query.customerId) {
        this.isCustomerModalOpen = true;
      } else if (this.$route.query && this.$route.query.projectDraftId) {
        await this.getProjectDraft();
        // Ajouter de la logique pour gérer l'ouverture d'une configuration existante
      } else if (this.$route.query && this.$route.query.customerId) {
        await this.getCustomer();

        if (this.$route.query.postalCodeInseeNumber) {
          // il faut au moins le code postal du customer pour aller chercher des matchings
          await this.getMatchings();
        }
      }
      await this.getPostalCode();

      // quand on a toutes les données, on peut set le filtre des terrains
      let landChoice = '';
      if (this.$route.query.landChoice) {
        landChoice = this.$route.query.landChoice;
      } else if (this.$route.query.emptyLand) {
        landChoice = 'no-land';
      } else if (this.$route.query.landWidth) {
        landChoice = 'custom-land';
      } else {
        landChoice = 'land';
      }
      this.landChoice = landChoice;

      this.initPricingSystems();

      // On set les filtres des versions (les data à partir des queries)
      this.isLoading = false;

      this.setVersionFilters();

      if (this.$route.query.modelId) {
        this.applyVersionFilters(false);
      }
    },
    async getProjectDraft() {
      try {
        if (this.$route.params.projectDraftId) {
          this.projectDraft = await projectDraftApi.getById(this.$route.params.projectDraftId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de l\'avant-projet',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    updateImages() {
      if (this.imagesType !== this.$route.query.imagesType) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            imagesType: this.imagesType,
          },
        });
      }
    },
    async getPostalCode() {
      try {
        let fullPostalCode = null;
        // on récupère le code postal avec le CP en query, ou directement un PC+inseeNumber
        if (this.$route.query.postalCodeInseeNumber && this.$route.query.postalCodeInseeNumber.length === 5) {
          const matchingPostalCodes = await postalCodeApi.getAll(this.$route.query.postalCodeInseeNumber);
          fullPostalCode = matchingPostalCodes && matchingPostalCodes.length ? matchingPostalCodes[0] : null;
          // on met à jour le postalCode complet dans la query
          try {
            await this.$router.push({
              query: {
                ...this.$route.query,
                postalCodeInseeNumber: fullPostalCode.postalCodeInseeNumber,
              },
            });
          } catch (er) {
            if (er.name !== 'NavigationDuplicated') {
              throw er;
            }
          }
        } else if (this.$route.query.postalCodeInseeNumber && this.$route.query.postalCodeInseeNumber.length === 11) {
          const matchingPostalCode = await postalCodeApi.getOne(this.$route.query.postalCodeInseeNumber);
          fullPostalCode = matchingPostalCode;
        }

        this.postalCode = fullPostalCode;
        // Si on a une code postal, on l'entre dans l'input de changement de code postal
        if (this.postalCode) {
          this.selectedPostalCode = `${this.postalCode.city} - ${this.postalCode.postalCodeInseeNumber.split('-')[0]}`;
          this.selectedPostalCodeInseeNumber = this.postalCode.postalCodeInseeNumber;

          await this.getNearPostalCodes();
        } else {
          this.selectedPostalCode = null;
          this.selectedPostalCodeInseeNumber = null;
        }
        await this.updateLands();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des codes postaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getNearPostalCodes() {
      try {
        if (this.postalCode) {
          this.nearPostalCodes = await postalCodeApi.getByPostalCode(this.brand.brandId, this.postalCode.postalCodeInseeNumber, 40);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des codes postaux proches',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async nextPostalCode() {
      const nextPostalCode = this.nearPostalCodes.find((postalCode) => postalCode.postalCodeInseeNumber !== this.postalCode.postalCodeInseeNumber);

      if (nextPostalCode && nextPostalCode.postalCodeInseeNumber) {
        this.selectedPostalCode = nextPostalCode.city;
        this.selectedPostalCodeInseeNumber = nextPostalCode.postalCodeInseeNumber;

        await this.applyPostalCode(true, true);
      }
    },
    async updateLands() {
      // On met à jour la liste des terrains, en fonction du code postal + insee number
      if (this.postalCode) {
        this.allLands = await landApi.getPublic(this.brand.brandId, this.selectedPostalCodeInseeNumber);

        this.availableLands = this.allLands.reduce((a, pcLand) => {
          let matchIndex = null;
          if (a && a.length) {
            matchIndex = a.findIndex((land) => (land.allotment && pcLand.allotment && land.allotment.allotmentId === pcLand.allotment.allotmentId) || !pcLand.allotment.allotmentId);

            if ((matchIndex || matchIndex === 0) && matchIndex !== -1) {
              if (!a[matchIndex].lands) {
                // eslint-disable-next-line no-param-reassign
                a[matchIndex].lands = [];
              }
              a[matchIndex].lands.push(pcLand);
              return a;
            }
          }
          a.push({
            allotment: pcLand && pcLand.allotment && pcLand.allotment.allotmentId ? pcLand.allotment : null,
            company: pcLand && pcLand.company && pcLand.company.companyId ? pcLand.company : null,
            lands: [pcLand],
          });
          return a;
        }, []);

        // On ouvre le dropdown des terrains si on a 0 terrain
        if (this.availableLands.length === 0) {
          this.isLandOpen = true;
        }
      } else {
        this.availableLands = null;
      }

      if (this.$route.query && this.$route.query.landId && this.$route.query.landId.length) {
        if (this.allLands && this.allLands.length) {
          this.landId = this.$route.query.landId;
          this.land = this.allLands.find((selectedLand) => selectedLand.landId === this.$route.query.landId);
        } else {
          // Si on ne trouve pas de terrain, on reset le champs
          this.land = null;
          this.landId = null;
        }
      }
    },
    resetSelectedPostalCode() {
      this.selectedPostalCode = null;
      this.selectedPostalCodeInseeNumber = null;
    },
    setSelectedPostalCode(value) {
      if (value) {
        this.selectedPostalCode = value.label;
        this.selectedPostalCodeInseeNumber = value.name;
      }
    },
    async mapClicked(postalCode) {
      this.selectedPostalCode = postalCode.city;
      this.selectedPostalCodeInseeNumber = postalCode.postalCodeInseeNumber;

      await this.applyPostalCode(false);
    },
    async applyPostalCode(resetMap, closeLandFilterAfter = false) {
      this.isLoading = true;

      if (resetMap) {
        this.showMap = false;
      }

      this.isLandOpen = closeLandFilterAfter ? false : this.isLandOpen;

      if (this.selectedPostalCodeInseeNumber !== this.$route.query.postalCodeInseeNumber) {
        try {
          this.landId = null;
          this.land = null;
          await this.$router.push({
            query: {
              ...this.$route.query,
              postalCodeInseeNumber: this.selectedPostalCodeInseeNumber ? this.selectedPostalCodeInseeNumber : undefined,
              landId: undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }
        await this.getPostalCode();

        if (this.$route.query.postalCodeInseeNumber) {
          this.initPricingSystems();
          // il faut au moins le code postal du customer pour aller chercher des matchings
          await this.getMatchings();
          if (!this.matchings || !this.matchings.length) {
            this.isLandOpen = true;
            await this.landChoiceChange('land');
          } else {
            await this.resetVersionFilter();
          }
        }
      }
      this.isPostalCodeOpen = false;

      if (resetMap) {
        this.showMap = true;
      }

      this.isLoading = false;
    },
    async getMatchings() {
      try {
        const isClayZone = Array.isArray(this.$route.query.isClayZone) && this.$route.query.isClayZone.length ? this.$route.query.isClayZone[0] : this.$route.query.isClayZone;

        if (this.$route.query.postalCodeInseeNumber) {
          const matchings = await configurationApi.getAll(
            this.brand.brandId,
            this.$route.query.postalCodeInseeNumber,
            this.$route.query.emptyLand,
            this.$route.query.landWidth,
            this.$route.query.landSurface,
            this.$route.query.landPrice,
            this.$route.query.isMainDrainagePlanned,
            this.$route.query.isServiced,
            isClayZone,
            this.$route.query.landId,
          );
          const sortedMatchings = matchings.map((matching) => ({
            ...matching,
            versionMedias: matching.version.versionMedias && matching.version.versionMedias.length ? matching.version.versionMedias.sort((a, b) => a.createdAt.localeCompare(b.createdAt)) : [],
          }));

          this.matchings = sortedMatchings;

          this.filteredMatchings = sortedMatchings;
          this.getVersionByModels();

          // Si on fait une modification, alors on ouvre la modale des terrains
          if (this.$route.name.includes('edit')) {
            if (this.matchings && this.matchings.length) {
              this.selectedMatching = {
                ...this.matchings.find((matching) => matching.version.versionId === this.$route.query.versionId
                && (
                  (matching.land && matching.land.landId === this.$route.query.landId) || (!matching.land || !matching.land.alndId))),
              };

              if (this.selectedMatching && this.selectedMatching.land && this.selectedMatching.land.landId) {
                const selectedLand = await landApi.getOne(this.selectedMatching.land.landId);
                this.selectedMatching.land = selectedLand || this.selectedMatching.land;
              }

              // On stocké séparément la config de départ pour le retour
              if (!this.previousConfiguration) {
                if (this.$route.query.openLandChoice === 'true' || this.$route.query.openLandChoice === true) {
                  this.isLandOpen = true;
                }
                this.previousConfiguration = this.selectedMatching;
              }
              if (!this.previousCustomerLand) {
                this.previousCustomerLand = {
                  width: this.$route.query.landWidth,
                  surface: this.$route.query.landSurface,
                  price: this.$route.query.landPrice,
                  isMainDrainagePlanned: this.$route.query.isMainDrainagePlanned,
                  isServiced: this.$route.query.isServiced,
                  isClayZone: this.$route.query.isClayZone,
                  postalCodeInseeNumber: this.$route.query.postalCodeInseeNumber,
                };
              }
              if (!this.previousPostalCodeInseeNumber) {
                this.previousPostalCodeInseeNumber = this.selectedPostalCodeInseeNumber;
              }
              if (!this.previousCustomerId) {
                this.previousCustomerId = this.$route.query.customerId;
              }
              this.isPreviousMatchingInit = true;
            }
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des matchings terrains / maisons',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    getVersionByModels() {
      if (this.filteredMatchings && this.filteredMatchings) {
        const modelsNotEmpty = this.allModels.filter((model) => {
          if (model.name) {
            return this.filteredMatchings.find((matching) => matching.version.model.modelId === model.modelId);
          }
          return false;
        });

        if (modelsNotEmpty && modelsNotEmpty.length) {
          this.versionsByModels = modelsNotEmpty.map((model) => {
            const matchingsByModelLand = this.filteredMatchings.filter((matching) => matching && matching.version.model.modelId === model.modelId && ((matching.land && matching.land.landId) || (matching.customerLand && matching.customerLand.width)));

            let sortedMatchingsLand = [];
            if (matchingsByModelLand && matchingsByModelLand.length) {
              sortedMatchingsLand = matchingsByModelLand.sort((a, b) => (a && b ? ((a.version.width ? a.version.width : 0) * (a.version.depth ? a.version.depth : 0) - (b.version.width ? b.version.width : 0) * (b.version.depth ? b.version.depth : 0)) : 0));
            }

            const matchingsByModelNoLand = this.filteredMatchings.filter((matching) => matching && matching.version.model.modelId === model.modelId && ((!matching.land || !matching.land.landId) && (!matching.customerLand || !matching.customerLand.width)));

            let sortedMatchingsNoLand = [];
            if (matchingsByModelNoLand && matchingsByModelNoLand.length) {
              sortedMatchingsNoLand = matchingsByModelNoLand.sort((a, b) => (a && b ? ((a.version.width ? a.version.width : 0) * (a.version.depth ? a.version.depth : 0) - (b.version.width ? b.version.width : 0) * (b.version.depth ? b.version.depth : 0)) : 0));
            }

            const allMatchings = sortedMatchingsLand.concat(sortedMatchingsNoLand);

            return {
              modelId: model.modelId,
              name: model.name,
              image: model.image,
              displayedVersion: allMatchings[0].version.versionId,
              versionCount: allMatchings && allMatchings.length ? allMatchings.length : 0,
            };
          });
          this.removePrivateModels();
        }
      }
    },
    async setPrices() {
      if (this.matchings && this.matchings.length) {
        let matchingCopy = this.matchings.slice();

        matchingCopy.sort((a, b) => this.allModels.findIndex((model) => model.modelId === a.version.model.modelId) - this.allModels.findIndex((model) => model.modelId === b.version.model.modelId));

        // On tri les matchings par pricePrimary croissant ou l'inverse afin d'aller chercher le vrai prix des versions demandées en priorité
        matchingCopy = this.$route.query.orderBy === '-price' ? matchingCopy.sort((a, b) => b.version.pricePrimary - a.version.pricePrimary) : matchingCopy.sort((a, b) => a.version.pricePrimary - b.version.pricePrimary);

        // On filtre par les versions qui on besoin d un prix
        matchingCopy = matchingCopy.filter((matchingElement) => this.toPriceVersions[matchingElement.version.versionId] && !this.pricedVersions[matchingElement.version.versionId]);

        const chunks = [];
        const chunkSize = 12;

        for (let i = 0; i < matchingCopy.length; i += chunkSize) {
          const chunk = matchingCopy.slice(i, i + chunkSize);
          chunks.push(chunk);
        }
        /* eslint-disable no-await-in-loop */
        for (let i = 0; i < chunks.length; i += 1) {
          const chunkPromises = chunks[i].map(async (matching) => {
            const { price, priceWithoutAdditionalFees } = await this.getPrice(matching.version.versionId, matching.land ? matching.land.landId : null);
            const matchIndexMatchings = this.matchings.findIndex((matchingFilter) => matchingFilter && matching && matchingFilter.version.versionId === matching.version.versionId);
            if (matchIndexMatchings !== -1) {
              this.$set(this.matchings, matchIndexMatchings, {
                ...matching,
                price: price || null,
                priceWithoutAdditionalFees: priceWithoutAdditionalFees || null,
              });
            }
            const matchIndex = this.filteredMatchings.findIndex((matchingFilter) => matchingFilter && matching && matchingFilter.version.versionId === matching.version.versionId);
            if (matchIndex === 0 || matchIndex) {
              this.$set(this.filteredMatchings, matchIndex, {
                ...matching,
                price: price || null,
                priceWithoutAdditionalFees: priceWithoutAdditionalFees || null,
              });
            }
            // on met à jour le modèle
            if (this.versionsByModels && this.versionsByModels.length) {
              const modelIndex = this.versionsByModels.findIndex((model) => model && matching && model.displayedVersion === matching.version.versionId);
              if (modelIndex !== -1 && this.versionsByModels[modelIndex]) {
                this.versionsByModels[modelIndex].price = price || null;
                this.versionsByModels[modelIndex].priceWithoutAdditionalFees = priceWithoutAdditionalFees || null;
              }
            }
            // on met à jour la config sélectionnée
            if (this.$route.name.includes('edit') && this.selectedMatching && this.selectedMatching.version && matching && matching.version && matching.version.versionId === this.selectedMatching.version.versionId) {
              this.selectedMatching.price = price || null;
              this.selectedMatching.priceWithoutAdditionalFees = priceWithoutAdditionalFees || null;
            }

            // On met à jour l objet des prix à jour
            this.pricedVersions[matching.version?.versionId] = true;

            return { price, priceWithoutAdditionalFees };
          });
          await Promise.all(chunkPromises);
        }
      }
      return true;
    },
    async getPrice(versionId, landId) {
      let price = null;
      let priceWithoutAdditionalFees = null;
      try {
        ({ price, priceWithoutAdditionalFees } = await configurationApi.getPrice(
          this.brand.brandId,
          this.$route.query.postalCodeInseeNumber,
          this.$route.query.emptyLand,
          this.$route.query.landWidth,
          this.$route.query.landSurface,
          this.$route.query.landPrice,
          (this.$route.query.isMainDrainagePlanned === 'true' || this.$route.query.isMainDrainagePlanned === true),
          (this.$route.query.isServiced === 'true' || this.$route.query.isServiced === true),
          (this.$route.query.isClayZone === 'true' || this.$route.query.isClayZone === true),
          versionId,
          landId,
        ));
      } catch (er) {
        // On n'affiche pas le message d'erreur si on a un problème avec le prix
        // On l'enlève de la liste
        if (this.matchings && this.matchings.length) {
          const index = this.matchings.findIndex((matching) => matching && matching.version.versionId === versionId);
          this.matchings.splice(index, 1);
        }
        if (this.filteredMatchings && this.filteredMatchings.length) {
          const index = this.filteredMatchings.findIndex((matching) => matching && matching.version.versionId === versionId);
          this.filteredMatchings.splice(index, 1);
        }
      }
      return { price, priceWithoutAdditionalFees };
    },
    async getRanges() {
      try {
        const ranges = await rangeApi.getAll();
        this.ranges = utils.formatOptions(
          ranges,
          (option) => option.rangeId,
          (option) => option.name,
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des gammes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getModels() {
      try {
        const models = await modelApi.getAll(null, null, null, null, true);
        this.allModels = models && models.data ? models.data : null;
        this.models = utils.formatOptions(
          models.data,
          (option) => option.modelId,
          (option) => option.name,
          'choose',
        );
        this.models = this.models.sort((a, b) => a.label.localeCompare(b.label));
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des modèles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getRooms() {
      try {
        this.rooms = await roomApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des pièces',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getFloors() {
      try {
        const floors = await floorApi.getAll();

        // On remplace "rez-de-chaussée", par "plein pied", pour faire varier les conditionsdu filtre
        if (floors && floors.length) {
          const index = floors.findIndex((floor) => floor.floorId === 'd0e1fedc-c9a8-4f59-8f30-66d897b0a951');
          if (index !== -1) {
            floors[index] = {
              ...floors[index],
              name: 'Plain-pied',
            };
          }
          this.floors = utils.formatOptions(
            floors,
            (option) => option.floorId,
            (option) => option.name,
          );
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des niveaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    openFilter(filterName) {
      if (filterName === 'postalCode') {
        this.isPostalCodeOpen = !this.isPostalCodeOpen;
        this.isLandOpen = false;
        this.isVersionOpen = false;
      } else if (filterName === 'land') {
        this.isPostalCodeOpen = false;
        this.isLandOpen = !this.isLandOpen;
        this.isVersionOpen = false;
      } else if (filterName === 'version') {
        this.isPostalCodeOpen = false;
        this.isLandOpen = false;
        this.isVersionOpen = !this.isVersionOpen;

        if (!this.isVersionOpen) {
          this.setVersionFilters();
        }
      }
    },
    closeFilter() {
      this.isPostalCodeOpen = false;
      this.isLandOpen = false;
      this.isVersionOpen = false;

      if (!this.isVersionOpen) {
        this.setVersionFilters();
      }
    },
    downloadPdf(allotment) {
      // télécharger le pdf du lotissement
      window.open(allotment.presentationPdf, '_blank');
    },
    async landChange(land) {
      this.isLoading = true;
      if (land && this.$route.query.landId !== land.landId) {
        try {
          await this.$router.push({
            query: {
              ...this.$route.query,
              landId: land && land.landId ? land.landId : undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }
        this.land = this.allLands.find((selectedLand) => selectedLand.landId === land.landId);

        if (this.$route.query.postalCodeInseeNumber) {
          this.initPricingSystems();
          await this.getMatchings();
          await this.resetVersionFilter();
        }
      }
      this.isLoading = false;

      this.isLandOpen = false;
    },
    async applyCustomLand() {
      // Si un des champs a changé
      if (
        (this.landWidth !== this.$route.query.landWidth)
        || (this.landSurface !== this.$route.query.landSurface)
        || (this.landPrice !== this.$route.query.landPrice)
        || (this.isMainDrainagePlanned !== this.$route.query.isMainDrainagePlanned)
        || (this.isServiced !== this.$route.query.isServiced)
        || (this.isClayZone !== this.$route.query.isClayZone)
      ) {
        try {
          this.land = null;
          await this.$router.push({
            query: {
              ...this.$route.query,
              landChoice: 'custom-land',
              landId: undefined,
              landWidth: this.landWidth,
              landSurface: this.landSurface,
              emptyLand: undefined,
              landPrice: (this.landPrice || this.landPrice === 0) ? utils.formatEuroToCent(this.landPrice) : undefined,
              isMainDrainagePlanned: this.isMainDrainagePlanned ? this.isMainDrainagePlanned : undefined,
              isServiced: this.isServiced ? this.isServiced : undefined,
              isClayZone: this.isClayZone ? this.isClayZone : undefined,
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }

        this.isLoading = true;
        this.initPricingSystems();
        await this.getMatchings();
        await this.resetVersionFilter();

        this.isLandOpen = false;
        this.isLoading = false;
      }
    },
    setVersionFilters() {
      if (!this.surfaceRange) {
        this.surfaceRange = {
          start: 0,
          end: 300,
        };
      }
      if (!this.priceRange) {
        this.priceRange = {
          start: 0,
          end: 600000,
        };
      }

      // set modelId filter from querie
      this.modelId = this.$route.query.modelId;
    },
    async applyVersionFilters(applyLoading) {
      let filtersEmpty = true;

      // application des filtres sur la liste des matchings, et dans la route
      if (applyLoading) {
        this.isLoading = true;
      }

      let matchings = this.matchings && this.matchings.length ? this.matchings.slice() : [];

      // trier par prix ou non
      if (this.orderBy === 'price') {
        filtersEmpty = false;
        matchings = matchings.sort((a, b) => a.price - b.price);
      } else if (this.orderBy === '-price') {
        matchings = matchings.sort((a, b) => b.price - a.price);
      }

      if (this.priceRange && (this.priceRange.start !== 0 || this.priceRange.end !== 600000)) {
        filtersEmpty = false;
        matchings = matchings.filter((matching) => matching.price > utils.formatEuroToCent(this.priceRange.start, false) && matching.price < utils.formatEuroToCent(this.priceRange.end, false));
      }

      if (this.surfaceRange && (this.surfaceRange.start !== 0 || this.surfaceRange.end !== 300)) {
        filtersEmpty = false;
        matchings = matchings.filter((matching) => matching.totalSurfaceHabitable > this.surfaceRange.start && matching.totalSurfaceHabitable < this.surfaceRange.end);
      }

      if (this.floorId && this.floorId.length) {
        filtersEmpty = false;
        matchings = matchings.filter(
          (matching) => {
            if (matching.version && matching.version.surfaces && matching.version.surfaces.length) {
              // Si c'est le rez-de-chaussée, ça soit être le seul niveau (sauf si c'est des combles aménageables)
              if (this.floorId === 'd0e1fedc-c9a8-4f59-8f30-66d897b0a951') {
                const surfaces = matching.version.surfaces.filter((surface) => surface.floorId !== this.floorId && surface.floorId !== 'c166aea2-c111-4ed6-97bf-0ec3697c9cf2');
                return !surfaces || surfaces.length === 0;
              }
              const surfaces = matching.version.surfaces.filter((surface) => surface.floorId === this.floorId);
              return surfaces && surfaces.length;
            }
            return false;
          },
        );
      }

      if (this.roomCount && this.roomCount.length) {
        filtersEmpty = false;
        matchings = matchings.filter(
          (matching) => {
            if (matching.version && matching.version.surfaces && matching.version.surfaces.length) {
              const surfaces = matching.version.surfaces.filter((surface) => surface.room.name.includes('Chambre') || surface.room.name.includes('Suite parentale'));
              return surfaces && surfaces.length === parseInt(this.roomCount, 10);
            }
            return false;
          },
        );
      }

      if (this.bathroomCount && this.bathroomCount.length) {
        filtersEmpty = false;
        matchings = matchings.filter(
          (matching) => {
            if (matching.version && matching.version.surfaces && matching.version.surfaces.length) {
              const surfaces = matching.version.surfaces.filter((surface) => surface.room.name.includes('Salle de bain') || surface.room.name.includes('Salle d’eau'));
              return surfaces && surfaces.length === parseInt(this.bathroomCount, 10);
            }
            return false;
          },
        );
      }

      if (this.garage) {
        filtersEmpty = false;
        const withGarage = (surface) => surface.room.roomId === '0e75df63-e0d4-4983-b7c4-b253f9104903' || surface.room.roomId === '3f7a7eae-7343-499d-994a-8871b8508b4f' || surface.room.roomId === '0682b316-6b8f-4646-909b-6d51aa86ed80';
        const withoutGarage = (surface) => surface.room.roomId !== '0e75df63-e0d4-4983-b7c4-b253f9104903' && surface.room.roomId !== '3f7a7eae-7343-499d-994a-8871b8508b4f' && surface.room.roomId !== '0682b316-6b8f-4646-909b-6d51aa86ed80';
        if (this.garage === 'withGarage') {
          matchings = matchings.filter(
            (matching) => matching.version.surfaces.some(withGarage),
          );
        } else {
          matchings = matchings.filter(
            (matching) => matching.version.surfaces.every(withoutGarage),
          );
        }
      }

      if (this.selectedRanges && this.selectedRanges.length) {
        filtersEmpty = false;
        matchings = matchings.filter(
          (matching) => {
            if (matching.version && matching.version.range && matching.version.range.rangeId) {
              return this.selectedRanges.includes(matching.version.range.rangeId);
            }
            return false;
          },
        );
      }

      if (this.modelId && this.modelId.length) {
        filtersEmpty = false;
        matchings = matchings.filter(
          (matching) => {
            if (matching && matching.version && matching.version.model && matching.version.model.modelId) {
              return matching.version.model.modelId === this.modelId;
            }
            return false;
          },
        );
      }

      this.filteredMatchings = matchings.slice();

      if (filtersEmpty) {
        this.viewType = 'model';
      } else {
        this.viewType = 'version';
      }

      if (applyLoading) {
        this.isLoading = false;
      }

      this.isVersionOpen = false;
    },
    async resetVersionFilter() {
      this.isLoading = true;
      this.filteredMatchings = this.matchings;

      this.orderBy = null;
      this.priceRange = {
        start: 0,
        end: 600000,
      };
      this.surfaceRange = {
        start: 0,
        end: 300,
      };
      this.floorId = null;
      this.roomCount = null;
      this.bathroomCount = null;
      this.garage = null;

      this.selectedRanges = null;
      this.modelId = null;

      try {
        await this.$router.replace({
          query: {
            ...this.$route.query,
            modelId: undefined,
          },
        });
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }

      this.isVersionOpen = false;
      await this.applyVersionFilters();

      this.viewType = 'model';

      this.isLoading = false;
    },
    async goBackToModels() {
      this.isLoading = true;
      this.filteredMatchings = this.matchings;

      this.modelId = null;

      this.$router.replace({
        query: {
          ...this.$route.query,
          modelId: undefined,
        },
      });

      this.isVersionOpen = false;
      this.initPricingSystems();
      await this.applyVersionFilters();
      await this.getVersionByModels();

      this.viewType = 'model';

      this.isLoading = false;
    },
    removeFilter(key, value) {
      if (key === 'modelId') {
        this.$router.push({
          query: {
            ...this.$route.query,
            modelId: undefined,
          },
        });
      }
      if (key === 'surfaceRange') {
        this.surfaceRange = {
          start: 0,
          end: 300,
        };
      } else if (key === 'priceRange') {
        this.priceRange = {
          start: 0,
          end: 600000,
        };
      } else if (key === 'selectedRanges') {
        const index = this.selectedRanges.findIndex((range) => range === value);
        this.selectedRanges.splice(index, 1);
      } else {
        this[key] = null;
      }
      this.initPricingSystems();
      this.applyVersionFilters(true);
      this.getVersionByModels();
    },
    async selectModel(model) {
      this.viewType = 'version';
      this.modelId = model.modelId;

      try {
        await this.$router.push(
          {
            params: {
              ...this.$route.params,
            },
            query: {
              ...this.$route.query,
              modelId: model.modelId,
            },
          },
        );
        if (this.$refs.pageBodyVersion) {
          this.$refs.pageBodyVersion.scrollTo(0, 0);
        }
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }

      this.applyVersionFilters(true);
    },
    goToConfiguration(matching) {
      let isClayZone = null;
      if (Array.isArray(this.$route.query.isClayZone)) {
        isClayZone = this.$route.query.isClayZone.length ? this.$route.query.isClayZone[0] : undefined;
      } else {
        isClayZone = this.$route.query.isClayZone;
      }

      // Définition du postalCode
      let postalCode;

      if (this.$route.query.postalCodeInseeNumber) {
        postalCode = this.$route.query.postalCodeInseeNumber;
      }

      if (matching.land && matching.land.address && matching.land.address.postalCodeInseeNumber) {
        postalCode = matching.land.address.postalCodeInseeNumber;
      }

      if (matching.land && matching.land.postalCodeInseeNumber) {
        postalCode = matching.land.postalCodeInseeNumber;
      }

      if (this.$route.name.includes('edit')) {
        this.selectedMatching = matching;
      } else {
        const queries = {
          // id terrain
          ...(matching.land && matching.land.landId && { landId: matching.land.landId }),
          // id version
          ...(matching.version && matching.version.versionId && { versionId: matching.version.versionId }),
          // les params pour le terrain custom
          ...(this.$route.query.landWidth && matching.land && { landWidth: this.$route.query.landWidth }),
          ...(this.$route.query.landSurface && matching.land && { landSurface: this.$route.query.landSurface }),
          ...(this.$route.query.landPrice && matching.land && { landPrice: this.$route.query.landPrice }),
          ...(this.$route.query.isMainDrainagePlanned && matching.land && { isMainDrainagePlanned: this.$route.query.isMainDrainagePlanned }),
          ...(this.$route.query.isServiced && matching.land && { isServiced: this.$route.query.isServiced }),
          ...(this.$route.query.isClayZone && matching.land && { isClayZone }),
          // id avant-projet
          ...(this.$route.query.projectDraftId && { projectDraftId: this.$route.query.projectDraftId }),
          // id client
          ...(this.$route.query.customerId && { customerId: this.$route.query.customerId }),
          // id code postal
          ...({ postalCodeInseeNumber: postalCode }),
          //
          ...(this.$route.query.emptyLand && { emptyLand: this.$route.query.emptyLand }),
        };
        try {
          this.$router.push({
            name: 'customers-projects-drafts-new-configuration',
            query: queries,
            params: {
              ...(this.$route.query.projectDraftId && { projectDraftId: this.$route.query.projectDraftId }),
              tab: 'version',
            },
          });
        } catch (er) {
          if (er.name !== 'NavigationDuplicated') {
            throw er;
          }
        }
      }
    },
    cancelConfigEdit() {
      try {
        if (this.$route.params.projectDraftId) {
          this.$router.push({
            name: 'customers-projects-drafts-projectDraftId-configuration',
            params: {
              projectDraftId: this.$route.params.projectDraftId,
            },
          });
        } else if (this.previousConfiguration) {
          this.$router.push({
            name: 'customers-projects-drafts-new-configuration',
            query: {
              versionId: this.previousConfiguration.version.versionId,
              landId: this.previousConfiguration.land ? this.previousConfiguration.land.landId : undefined,
              landWidth: this.previousCustomerLand.landWidth,
              landSurface: this.previousCustomerLand.landSurface,
              landPrice: this.previousCustomerLand.landPrice,
              isMainDrainagePlanned: this.previousCustomerLand.isMainDrainagePlanned,
              isServiced: this.previousCustomerLand.isServiced,
              isClayZone: this.previousCustomerLand.isClayZone,
              postalCodeInseeNumber: this.previousPostalCodeInseeNumber,
              customerId: this.previousCustomerId,
            },
          });
        } else {
          this.$router.push({
            name: 'customers-projects-drafts',
          });
        }
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }
    },
    applyConfigEdit() {
      try {
        let isClayZone = null;
        if (Array.isArray(this.$route.query.isClayZone)) {
          isClayZone = this.$route.query.isClayZone.length ? this.$route.query.isClayZone[0] : undefined;
        } else {
          isClayZone = this.$route.query.isClayZone;
        }

        if (this.$route.params.projectDraftId) {
          this.$router.push({
            name: 'customers-projects-drafts-projectDraftId-configuration',
            params: {
              projectDraftId: this.$route.params.projectDraftId,
            },
            query: {
              versionId: this.selectedMatching.version.versionId,
              landId: this.selectedMatching.land && this.selectedMatching.land.landId ? this.selectedMatching.land.landId : undefined,
              landWidth: this.landWidth,
              landSurface: this.landSurface,
              landPrice: this.landPrice || this.landPrice === 0 ? utils.formatEuroToCent(this.landPrice) : undefined,
              isMainDrainagePlanned: this.isMainDrainagePlanned,
              isServiced: this.isServiced,
              isClayZone,
              postalCodeInseeNumber: this.selectedPostalCodeInseeNumber,
              customerId: this.customer ? this.customer.customerId : undefined,
            },
          });
        } else if (this.selectedMatching) {
          this.$router.push({
            name: 'customers-projects-drafts-new-configuration',
            query: {
              versionId: this.selectedMatching.version.versionId,
              landId: this.selectedMatching.land ? this.selectedMatching.land.landId : undefined,
              landWidth: this.landWidth,
              landSurface: this.landSurface,
              landPrice: this.landPrice || this.landPrice === 0 ? utils.formatEuroToCent(this.landPrice) : undefined,
              isMainDrainagePlanned: this.isMainDrainagePlanned,
              isServiced: this.isServiced,
              isClayZone,
              postalCodeInseeNumber: this.selectedPostalCodeInseeNumber,
              customerId: this.$route.query.customerId,
            },
          });
        } else {
          this.$router.push({
            name: 'customers-projects-drafts',
          });
        }
      } catch (er) {
        if (er.name !== 'NavigationDuplicated') {
          throw er;
        }
      }
    },
  },
};
</script>

<style lang='sass'>
.project-draft-matchings
  margin-top: 0px
  .list-container
    position: relative
    height: calc(100vh - 280px)
    .cover
      background: black
      position: absolute
      opacity: 0.2
      height: 100%
      width: 100%
      top: 0
      left: 0
      z-index: 1
    .list
      z-index: 0
      min-height: 100vh
    .page-body
      @include screen
      overflow-y: auto
      height: calc(100vh - 360px)
      padding-top: 24px
      .up
        display: flex
        flex-direction: row
        justify-content: space-between
  .header
    @include header
    padding-bottom: 30px
    .header-title
      display: flex
      align-items: center
    h1
      margin-right: 20px
  .row
    @include row
  .card
    @include card
    border-radius: 8px
    &.no-land
      filter: grayscale(5%)
      opacity: 0.45
  .breadcrumbs
    @include breadcrumbs
  .customer
    cursor: pointer
  h2
    @include subtitle
    font-weight: bold
    margin-bottom: 16px
  .matching
    padding: 0
    overflow: hidden
    cursor: pointer
    position: relative
    &.blueprint
      img
        display: block
        margin: 10px auto 0
        width: calc( 100% - 20px )
    &.selected
      border: solid $primary 2px
      margin-left: -2px
      margin-right: -2px
    .spinner-container
      margin: 16px
    .version-count
      @include infos
      margin-left: 16px
      margin-bottom: 16px
      color: $primary
    .card-footer
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 15px
      .grid-x
        width: 100%
    h4
      margin-top: 15px
      margin-bottom: 5px
      &.land
        margin: 0 0 0 16px
      &.no-land
        color: $error-light
        font-weight: 900
    h5
      margin: 0 0 0 16px
      line-height: 1.4
      color: black
      small
        display: inline-block
        padding-left: 3px
        border-left: 2px solid #2A2A2A
        font-size: 60%
        line-height: 1
        vertical-align: 2px
    img
      min-height: 150px
      width: 100%
      object-fit: cover
    h3
      @include body-bold
      margin-bottom: 0
      color: black
    .price
      background-color: $warning
      margin: 0px 10px 0 0
      padding: 2px
      width: fit-content
      border-radius: 16px
      float: right
      height: fit-content
      h3
        color: white
        font-weight: 500
        margin: 0px 5px
  .model
    @include label
    margin: 8px 16px 0 16px
    color: $secondary
    font-weight: 500
  .image-not-found
    display: flex
    align-items: center
    justify-content: center
    height: 181px
    width: 100%
    border-bottom: 1px solid $line
    .icon-upload
      width: 100px
  .land
    margin: 0 16px
    color: $secondary
    @include infos
  .filter-header-container
    top: 70px
    background-color: $white
    border-top: 1px solid $line
    border-bottom: 1px solid $line
    > .cell
      height: fit-content
      position: relative
      &:not(:last-child)
        border-right: 1px solid $line
    .filter-header
      cursor: pointer
      height: 50px
      padding: 16px
      position: relative
      overflow: hidden
      .header-column
        height: inherit
        display: flex
        flex-direction: column
        justify-content: center
      .main
        padding-left: 10px
        padding-right: 10px
        display: flex
        flex-direction: column
        justify-content: center
    .location, .layer, .filter
      width: 24px
    .arrow
      width: 12px
      transition: transform 0.3s
    .location, .layer, .filter
      path
        fill: $primary
    .arrow, .filter
      path, circle
        stroke: $primary
    .icon
      @include centered-container
    .filter-name
      @include infos
      color: $primary
      margin: 0
    .filter-value
      @include body
      color: $subtitle
      text-overflow: ellipsis
      overflow: hidden
      max-height: 27px
      white-space: nowrap
  .dropdown-filter,
  .dropdown-filter-version
    position: absolute
    width: calc(200% - 47px)
    background-color: $medium-bg
    margin-top: 1px
    padding: 24px
    border-bottom-right-radius: 8px
    border-bottom-left-radius: 8px
    z-index: 2
    max-height: calc(100vh - 280px)
    overflow: auto
    h3
      @include body
      margin-bottom: 16px
    &.land
      left: calc(-100% - 16px)
    &.city
      overflow: initial
    button
      margin-left: 16px
  .is-open
    .arrow
      transform: rotate(90deg)
  .dropdown-filter-version
    width: 100%
    padding: 0
    .filter-body
      padding: 24px
    .filter-bottom
      z-index: 1
      position: sticky
      bottom: 0
      background-color: white
      padding: 24px
      .cell
        @include centered-container
      .init
        cursor: pointer
        text-decoration: underline
  // Le filtre des terrains
  .land-choice
    box-shadow: $global-box-shadow
    margin: 8px
    padding: 11px
    border-radius: 8px
    text-align: center
    background-color: $white
    cursor: pointer
    display: flex
    align-items: center
    border: solid 1px $white
    &:first-child
      margin-left: 0
    &:last-child
      margin-right: 0
    &.is-selected
      border: solid 1px $primary
    .land-icon
      width: 30px
    .cell.auto
      padding: 0 5px
    .land-count
      @include label
      color: $body-color
  .land-detail
    margin-top: 10px
    .label
      @include label
      color: $body-color
    .app-checkbox span
      font-weight: 400
    &.custom-land
      > h3
        font-weight: 500
        @include body-bold
        color: black
        text-align: center
      > p
        @include small-body
        color: $subtitle
        margin-bottom: 25px
        text-align: center
      .row
        margin-bottom: 10px
      form > .app-button
        margin: 0 auto
    &.no-land,
    &.land
      @include centered-container
      text-align: center
      flex-direction: column
      h3
        font-weight: 500
        @include body-bold
        color: black
      p
        @include small-body
        color: $subtitle
        margin-bottom: 16px
  // Choix des terrains
  .allotment-container
    background-color: white
    box-shadow: $global-box-shadow
    border-radius: 8px
    margin: 16px 0
    .allotment-header
      padding: 16px
    .allotment-lands > .grid-x
      padding: 16px
      border-top: solid 1px $line
    .company
      margin-top: 8px
      p
        @include infos
        color: $body-color
      p.company-name
        color: $primary
        text-decoration: underline
        @include link
        cursor: inherit
    .download
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      cursor: pointer
      svg
        width: 24px
      p
        @include label
        color: $primary
        line-height: 14px
        margin-top: 5px
    h3
      color: $title
      font-weight: 500
      margin-bottom: 0
    .land-name p
      @include body
      color: $subtitle
    .land-radio
      align-items: center
      justify-content: center
      display: flex
      margin-left: 10px
  .filter-header-container .filter-header .bubbles-container
    height: inherit
    overflow: hidden
    display: flex
    flex-wrap: nowrap
    align-items: center
    padding: 0 16px
    .bubble
      @include tag
      @include tag-primary
      margin-left: 5px
      display: inline-block
      padding: 3px 10px
      height: fit-content
      font-size: 14px
      &:first-letter
        text-transform: uppercase
      .remove-option
        width: 10px
        margin-left: 5px
        path
          stroke: $primary
    .arrow-container
      padding-left: 16px
  .link
    display: flex
    cursor: pointer
    height: 24px
    width: fit-content
    margin-bottom: 8px
    border: 1px solid $blue-button
    border-radius: 16px
    padding: 0 8px
    outline: 2px solid transparent
    transition: background-color 0.3s ease-out
    color: $blue-button
    user-select: none
    background-color: transparent
    &:hover
      background-color: $blue-button
      transition: all 0.3s ease-in background-color
      span
        color: $white
        transition: all 0.3s ease-in background-color
      svg path
        stroke: $white
        transition: all 0.3s ease-in background-color
    &:active
      // outline: 2px solid rgba($blue-button, 0.5)
    span
      display: inline-block
      font-size: $font-3xs
      color: $blue-button
      font-weight: 500
      margin-left: 4px
      line-height: 24px
    svg
      width: 13px
      vertical-align: middle
      path
        stroke: $blue-button
  .model-card
    cursor: pointer
    .price-container
      h4
        text-align: right
      .price
        margin-top: 0px
  .main-loading .spinner-container, .empty-list p
    margin: 30px
  .edit-configuration
    position: sticky
    bottom: 0
    z-index: 2
    background-color: $colored-bg
    color: white
    padding: 10px 20px
    p
      color: white
    .cell
      align-items: center
    svg
      width: 20px
      path, rect
        stroke: $primary
    .status
      margin: 0 20px
      p
        @include label
        color: $primary
    .configuration
      display: flex
      flex-direction: column
      text-align: left
      align-items: flex-start
      justify-content: center
    .version-info
      @include cta
      color: white
    .land-info
      @include infos
      color: $white
    .surface
      @include infos
      color: $white
      padding-right: 40px
      text-align: right
    .price
      @include body-bold
      color: $white
      padding-right: 40px
      text-align: right
    .app-button.secondary
      margin-right: 8px
    .edit-loader
      display: flex
      align-items: center
  .cta
    @include cta
    color: $subtitle
  .checkbox-value
    @include small-body
    color: $subtitle
  .city-toggle
    margin-bottom: 16px
</style>
