<template>
  <div class="filter-modal">
    <!-- BUTTON --- -->
    <div class="button">
      <app-button
        @click="isModalOpen = true"
        theme="primary"
        icon="filter"
        size="small"
      >
        Filtrer {{ numberOfInputs > 0 ? ` (${numberOfInputs})` : '' }}
      </app-button>
    </div>

    <!-- MODAL --- -->
    <transition name="modal">
      <div class="modal-wrapper" v-if="isModalOpen">
        <form ref="filter-form" @submit.prevent="submit">
          <div class="header grid-x auto shrink">
            <div class="cell shrink close-modal">
              <close @click="close" />
            </div>
            <div class="title cell auto">
              <h3>Filtres {{ numberOfInputs > 0 ? ` (${numberOfInputs})` : '' }}</h3>
            </div>
            <div class="cell shrink">
              <h6 @click="reset">Réinitialiser</h6>
            </div>
          </div>
          <div class="form-content cell auto">
            <slot></slot>
          </div>
          <div class="footer">
            <app-button
              theme="primary"
              size="large"
              icon="add"
              type="submit"
            >
              Appliquer les filtres {{ numberOfInputs > 0 ? ` (${numberOfInputs})` : '' }}
            </app-button>
          </div>
        </form>
      </div>
    </transition>
    <transition name="screen">
      <div class="modal-screen" v-if="isModalOpen" @click="close"/>
    </transition>
  </div>
</template>

<script>
import close from '@/assets/img/close.svg?inline';

export default {
  name: 'filter-modal',
  components: {
    close,
  },
  props: {
    tabs: {
      // Format : [{ route: { name: '', params:{} }, label: 'home' }, ...]
      type: Array,
      default: () => [],
    },
    numberOfInputs: Number,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    submit() {
      this.$emit('change');
      this.isModalOpen = false;
    },
    reset() {
      this.$emit('reset');
      this.isModalOpen = false;
    },
    close() {
      this.$emit('close');
      this.isModalOpen = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.filter-modal
  .modal-wrapper
    position: fixed
    top: 0
    right: 0
    bottom: 0
    width: 330px
    background: $off-white
    box-shadow: $drop-max
    z-index: 10
    transition: all .4s ease-in-out
    overflow-y: scroll
    .row
      @include row
    .header
      padding: 24px 16px
      @include centered-container
      box-shadow: none
      h6
        @include hyperlink
        text-decoration: none
        cursor: pointer
      .title
        margin: 0 8px
    form
      height: fit-content
      display: flex
      flex-direction: column
      justify-content: space-between
      overflow: visible
    .form-content
      flex: 1
      overflow-y: visible
    .footer
      @include centered-container
      padding: 16px
      button
        width: 100%
    .close-modal
      svg
        cursor: pointer
        width: 14px
        height: 14px
        stroke: $title

    /* width */
    ::-webkit-scrollbar
      width: 7px
    /* Track */
    ::-webkit-scrollbar-track
      background: transparent
    /* Handle */
    ::-webkit-scrollbar-thumb
      background: $line
      border-radius: 10px
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover
      background: $line

  // écran grisé sous la modale
  .modal-screen
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba($body, 0.7)
    z-index: 5

  // animations pour apparition, et la disparition de la modale
  .screen-enter-active
    transition: opacity .9s

  .screen-leave-active
    transition: opacity .3s

  .screen-enter, .screen-leave-to
    opacity: 0

  .modal-enter-active
    transition: all 0s

  .modal-enter-active, .modal-leave-active
    right: -250px
</style>
