<template>
  <div class="companies-reports hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Rapports partenaires</h1>
      </div>
      <div class="cell shrink btn">
        <app-button theme="primary" size="large" icon="add" @click="handleClickOnGenerateReportsModal">Générer les rapports</app-button>
      </div>
      <div class="cell shrink btn">
        <app-button class="send-btn" size="large" icon="send" @click="handleClickOnSendReportsModal">Envoyer les rapports</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES RAPPORTS PARTENAIRES -->
    <div class="body">
        <div class="btn-filter-container">
          <p>SÉLECTION :</p>
          <app-button @click="handleSwitchSelection">{{isSwitchSelectionAllSelected ? 'Tout désélectionner' : 'Tout sélectionner'}}</app-button>
          <app-button @click="handleLastGeneratedSelection">Derniers générés</app-button>
          <div class='filter-container'>
            <span>FILTRER: </span>
            <div class="cell">
              <app-select :value="null" v-model="companyCategoryFilter" :options="filteredCompanyCategories" />
            </div>
          </div>
        </div>
      <app-table :headers="tableHeaders" :data="paginatedCompanies" :loading="isLoading">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="select" slot-scope="{ data }">
          <app-checkbox :value="data.companyId" v-model="selectedCompanies"/>
        </template>
        <template slot="company" slot-scope="{ data }">
          <p class="company-name" @click="pushToCompanyProjects(data)">{{ data.name }}</p>
        </template>
        <template slot="city" slot-scope="{ data }">
          <p>{{ displayCity(data.address.postalCode, data.address.city) }}</p>
        </template>
        <template slot="categories" slot-scope="{ data }">
            <span>
            {{ getFullCompanyCategories(data.companyCategories).map(cc => cc ? cc.label : cc).join(', ') }}
            </span>
          </template>
        <template slot="periodPreference" slot-scope="{ data }">
          <p>{{ data.periodPreference }} {{ data.periodPreference > 1 ? 'semaines' : 'semaine'}}</p>
        </template>
        <template slot="sendDate" slot-scope="{ data }">
          <p v-if="data.sendDate">{{ $dayjs(data.sendDate).format('DD/MM/YYYY' )}}</p>
          <div v-else class="no-send-label">
            <WarnSvg/>
            <p>Non envoyé</p>
          </div>
        </template>
        <template slot="generationDate" slot-scope="{ data }">
          <p>{{ data.generationDate ? $dayjs(data.generationDate).format('DD/MM/YYYY') : 'Jamais' }}</p>
        </template>
        <template slot="pdfLink" slot-scope="{ data }">
          <router-link v-if="data.reportPdf" :to=data.reportPdf target="_blank">
            <div>
              <pdf-icon />
            </div>
          </router-link>
        </template>
      </app-table>
      <app-pagination
        :limit="limit"
        :offset="computedOffset"
        :count="companies.length"
      />

      <app-modal class="generate-modal" :show="showPdfGenerationModal" size="medium" title="Générer un rapport" @update:show="showPdfGenerationModal = false">
        <form @submit.prevent="generateReports">
            <div class="radio-container">
                <div class="app-input">
                  <app-radio v-model="isPeriodPreference" :value="true">Préférences artisans</app-radio>
                </div>
                <div class="app-input">
                  <app-radio v-model="isPeriodPreference" :value="false">Définir une période</app-radio>
                </div>
            </div>
            <div class="datepicker-container" v-if="!isPeriodPreference">
              <div>
                <app-datepicker
                label="Date de début"
                v-model="startDate"
                />
              </div>
              <div>
                <app-datepicker
                label="Date de fin"
                v-model="endDate"
                />
              </div>
          </div>
          <app-button theme="primary" size="large" type="submit">Générer mon rapport</app-button>
        </form>
      </app-modal>

      <app-modal class="send-modal" :show="showPdfSendModal" size="small" title="Confirmation d'envoi" @update:show="showPdfSendModal = false">
        <h3>Êtes vous sûr de vous ?</h3>
        <br/>
        <p v-if="selectedCompanies.length === 1">Vous êtes sur le point d'envoyer le rapport à l'artisan sélectionné.</p>
        <p v-else>Vous êtes sur le point d'envoyer les rapports aux <span class="bold">{{selectedCompanies.length}}</span> artisans sélectionnés..</p>
        <br/>
        <div class="buttons-container">
          <app-button theme="error" @click="showPdfSendModal = false" size="large">Je ne suis plus sûr...</app-button>
          <app-button class="send-btn" size="large" @click="sendPdf">Oui, j'envoie ces rapports</app-button>
        </div>
      </app-modal>

    </div>
  </div>
</template>

<script>
import auth from '@/services/auth';
import companyApi from '@/services/api/company';
import companyCategoryApi from '@/services/api/companyCategory';
import memberApi from '@/services/api/member';
import pdfApi from '@/services/api/pdf';
import { fr } from 'vuejs-datepicker/dist/locale';
import pdfIcon from '@/assets/img/pdf-icon.svg?inline';
import utils from '@/services/utils/utils';
import types from '@/services/data/types.json';
import WarnSvg from '@/assets/img/warn.svg?inline';

export default {
  name: 'projects-companies-reports',
  metaInfo: {
    title: 'Rapports partenaires',
  },
  components: {
    pdfIcon,
    WarnSvg,
  },
  data() {
    return {
      me: null,
      tableHeaders: [
        { label: 'Select', key: 'select', size: 1 },
        { label: 'Partenaire', key: 'company', size: 2 },
        { label: 'Commune', key: 'city', size: 2 },
        { label: 'Métiers', key: 'categories', size: 2 },
        { label: 'Préférence d\'envoi', key: 'periodPreference', size: 1 },
        { label: 'Date d\'envoi', key: 'sendDate', size: 2 },
        { label: 'Date de création', key: 'generationDate', size: 1 },
        { label: 'Lien pdf', key: 'pdfLink', size: 1 },
      ],
      isLoading: true,
      companies: [],
      selectedCompanies: [],
      showPdfGenerationModal: false,
      showPdfSendModal: false,
      isPeriodPreference: true,
      companyCategories: [],
      french: fr,
      startDate: null,
      endDate: null,
      companyCategoryFilter: null,
      isSwitchSelectionAllSelected: false,
      filteredCompanyCategories: [],
      types,
      limit: 10,
    };
  },
  async created() {
    this.typesParsed = Object.entries(types.companyCategoryType);
    await this.getMe();
    await this.getCompanies();
    await this.getCompanyCategories();
  },
  computed: {
    computedOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    paginatedCompanies() {
      const startIndex = this.computedOffset;
      const endIndex = startIndex + this.limit;
      return this.companies.slice(startIndex, endIndex);
    },
    formatSendMessage() {
      if (this.selectedCompanies.length === 1) {
        return 'Vous êtes sur le point d\'envoyer le rapport à l\'artisan sélectionné.';
      }
      return `Vous êtes sur le point d'envoyer les rapports aux <span class="bold">${this.selectedCompanies.length}</span> artisans sélectionnés.`;
    },
  },
  watch: {
    isPeriodPreference() {
      if (this.isPeriodPreference) {
        this.startDate = null;
        this.endDate = null;
      }
    },
    companyCategoryFilter() {
      if (this.companyCategoryFilter === null) {
        this.$router.push({
          name: 'projects-companies-reports',
        });
      } else {
        this.$router.push({
          name: 'projects-companies-reports',
          query: {
            category: this.companyCategoryFilter,
          },
        });
      }
    },
    $route() {
      this.getCompanies();
    },
  },
  methods: {
    displayCity(postalCode, city) {
      return `${postalCode} ${city?.charAt(0).toUpperCase() + city?.slice(1).toLowerCase()}`;
    },
    async getCompanies() {
      if (this.me) {
        const view = this.me.isBrandAdmin ? 'ALL' : 'AGENCY_REGISTERED';
        try {
          this.isLoading = true;
          this.companies = await companyApi.getWithActiveProjectSteps(this.$route.query.category, view);
          this.isLoading = false;
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors du chargement des partenaires',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    handleSwitchSelection() {
      if (this.selectedCompanies.length === this.companies.length) {
        this.isSwitchSelectionAllSelected = false;
        this.selectedCompanies = [];
      } else {
        this.isSwitchSelectionAllSelected = true;
        this.selectedCompanies = this.companies.map((company) => company.companyId);
      }
    },
    handleLastGeneratedSelection() {
      const getClosestDate = (companies) => {
        const today = new Date();
        return companies.reduce((closest, company) => {
          const currentDate = new Date(company.generationDate);
          const currentDiff = Math.abs(currentDate - today);
          const closestDiff = Math.abs(new Date(closest) - today);
          return currentDiff < closestDiff ? currentDate : closest;
        }, new Date(companies[0].generationDate));
      };

      const filterCompaniesByDate = (companies, targetDate) => {
        const targetDateString = targetDate.toISOString().split('T')[0];
        return companies.filter((company) => {
          const companyDateString = new Date(company.generationDate).toISOString().split('T')[0];
          return companyDateString === targetDateString;
        });
      };

      const closestDate = getClosestDate(this.companies);
      const lastGeneratedCompanies = filterCompaniesByDate(this.companies, closestDate);

      this.selectedCompanies = lastGeneratedCompanies.map((company) => company.companyId);
    },
    handleClickOnGenerateReportsModal() {
      if (this.selectedCompanies.length === 0) {
        this.$message.show({
          title: 'Attention',
          text: 'Vous devez sélectionner au moins un partenaire',
          cancelText: 'Ok',
          hasCancel: true,
        });
      } else {
        this.showPdfGenerationModal = true;
      }
    },
    handleClickOnSendReportsModal() {
      if (this.selectedCompanies.length === 0) {
        this.$message.show({
          title: 'Attention',
          text: 'Vous devez sélectionner au moins un partenaire',
          cancelText: 'Ok',
          hasCancel: true,
        });
      } else {
        this.showPdfSendModal = true;
      }
    },
    getFullCompanyCategories(selectedCompanyCategories) {
      return selectedCompanyCategories.map((selectedCompanyCategory) => this.companyCategories.find((cc) => cc.companyCategoryId === selectedCompanyCategory));
    },
    pushToCompanyProjects(selectedCompany) {
      this.$router.push({
        name: 'companies-companyId-projects',
        params: {
          companyId: selectedCompany.companyId,
        },
      });
    },
    async getCompanyCategories() {
      try {
        this.isLoading = true;
        let companyCategories = (await companyCategoryApi.getAll()).filter(((cat) => cat.type === 'SUBCONTRACTOR' || cat.type === 'SUPPLIER' || cat.type === 'PARTNER')).sort((a, b) => a.name.localeCompare(b.name));

        companyCategories = companyCategories.map((category) => {
          const typeName = this.typesParsed.find((type) => type[0] === category.type);
          return { ...category, typeName: typeName[1] };
        });
        this.companyCategories = companyCategories.sort((a, b) => a.name.localeCompare(b.name));

        this.filteredCompanyCategories = utils.formatOptions(
          this.companyCategories,
          (o) => o.companyCategoryId,
          (o) => `${o.label} (${o.typeName})`,
          { text: 'Métiers' },
        );
        this.isLoading = false;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du chargement des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async sendPdf() {
      this.isLoading = true;
      this.showPdfSendModal = false;

      try {
        await pdfApi.sendReports(
          this.selectedCompanies,
          auth.getToken(),
        );
        this.$displayNotification('Mails envoyés avec succès');
        await this.getCompanies();
        this.isLoading = false;
      } catch (er) {
        this.$displayNotification('Echec de l\'envoi des mails');
        this.isLoading = false;
      }
    },
    async getMe() {
      try {
        this.isLoading = true;
        this.me = await memberApi.getMe();
        this.isLoading = false;
      } catch (error) {
        throw error;
      }
    },
    async generateReports() {
      try {
        this.isLoading = true;
        this.showPdfGenerationModal = false;
        await pdfApi.generateReports('fromReportsPage', this.selectedCompanies, this.isPeriodPreference, this.startDate, this.endDate, null);
        this.selectedCompanies = [];
        await this.getCompanies();
        this.isLoading = false;
        this.$displayNotification('Rapports générés avec succès');
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la génération des rapports',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.companies-reports
  .body
    @include screen
    .no-send-label
      display: flex
      justify-content: center
      align-items: center
      width: 110px
      padding: 6px 8px
      border-radius: 24px
      border: 2px solid
      border-color: #FDB03E
      background: rgba(254, 123, 69, 0.10)
      p
        margin-left: 4px
        color: #FDB03E
    .btn-filter-container
      display: flex
      align-items: center
      gap: 1rem
    .filter-container
      display: flex
      align-items: center
      margin-left: 1rem
      span
        margin-right: 0.5rem
    .send-modal
      display: flex
      flex-flow: column nowrap
      justify-content: space-evenly
      align-items: center
      .buttons-container
        display: flex
        flex-flow: row nowrap
        justify-content: space-between
        margin-top: 1rem
        .app-button
          width: 200px

    .generate-modal
      form
        height: 8rem
        width: 25rem
        display: flex
        flex-flow: column nowrap
        margin: 2rem auto 8rem auto
        padding: 0 50px

        .radio-container
          display: flex
          flex-flow: column nowrap
          justify-content: space-evenly
          .app-radio
            margin: 1rem 0
        .datepicker-container
          display: flex
          flex-flow: row nowrap
          gap: 20px
          justify-content: space-evenly
          margin: 10px 0
          .app-datepicker
            .vdp-datepicker
              z-index: 10
              .vdp-datepicker__calendar
                z-index: 10
        .app-button
          margin: 1rem auto
  .header
    @include header
  .row
    @include row
  .btn
    margin-left: 1rem
  .send-btn
    background-color: #37B87A !important
  .company-name
    color: $primary
    font-weight: bold
    cursor: pointer
  .bold
    font-weight: bold

</style>
