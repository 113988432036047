<template>
  <div class="account-nav hedifys-21" :class="{'home': $route.name === 'home' }">
    <div class="container">
      <div class="account">
        <h4 class="brand">{{ brand && brand.name ? brand.name : ' ' }}</h4>
        <h5 class="user">{{ me ? `Bonjour, ${me.firstname} ${me.lastname}` : ' ' }}</h5>
      </div>
      <div class="img">
        <img v-if="!img" src="@/assets/logos/alysia_square.jpg" alt="Image de profil">
        <img v-else :src="img" alt="Image de profil">
      </div>
      <div class="icon">
        <button
          @click="isOpen = !isOpen"
          :tabindex="1000"
          @focusout="isOpen = false"
          >
          <dropdown />
        </button>
      </div>
      <ul v-show="isOpen">
        <li class="option" v-if="!isImpersonated">
          <div @mousedown="goToAccount">
          <h5>Mon compte</h5>
          </div>
        </li>
        <li class="option">
          <button @mousedown="logout">
            <h5>Se deconnecter</h5>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth';
import member from '@/services/api/member';
import dropdown from '@/assets/img/dropdown.svg?inline';
import ls from 'local-storage';
import jwtDecode from 'jwt-decode';

export default {
  name: 'account-nav',
  components: {
    dropdown,
  },
  data() {
    return {
      isOpen: false,
      me: null,
      brand: null,
      isImpersonated: false,
    };
  },
  props: {
    img: {
      type: String,
    },
  },
  async created() {
    this.getMe();
  },
  methods: {
    async getMe() {
      try {
        this.me = await member.getMe();
        this.brand = await auth.getBrand();
        const jwt = ls.get('TOKEN');
        const decodedToken = jwtDecode(jwt);
        this.isImpersonated = decodedToken.isImpersonated;
      } catch (error) {
        throw error;
      }
    },
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (error) {
        throw error;
      }
    },
    async logout(event) {
      if (event.button === 0) {
        try {
          return auth.logout();
        } catch (err) {
          throw err;
        }
      }
      return null;
    },
    async goToAccount(event) {
      if (event.button === 0) {
        if (this.$route.name !== 'account') {
          this.$router.push({ name: 'account' });
        }
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.account-nav
  width: 100%
  top: 0
  padding-top: 10px
  position: sticky
  background: white
  height: 60px
  z-index: 3
  right: 0
  &.home
    background: transparent
    padding-top: 0
    .account
      h4, h5
        color: white
    .icon
      path
        fill: white
    .container
      top: 24px
  .container
    display: flex
    align-items: center
    justify-content: flex-end
    position: absolute
    right: 48px
  > div + div
    margin-left: 16px

.account
  display: flex
  flex-direction: column
  text-align: right
  justify-content: center
.brand
  color: $title
.user
  color: $label
.img
  margin: 0 16px
  > img
    width: 48px
    height: 48px
    border-radius: 50%

.icon
  display: flex
  align-items: center
  button
    display: flex
    background-color: transparent
    border: none
    padding: 0
  svg
    width: 16px
    height: 12px

ul
  position: absolute
  top: 100%
  right: 0
  width: 100%
  margin-top: 10px
  display: flex
  flex-direction: column
  padding: unset
  background-color: $off-white
  box-shadow: $drop-max
  border-radius: 4px
  z-index: 2

.option
  cursor: pointer
  + .option
    border-top: 1px solid $line

  button,
  a
    display: flex
    background-color: transparent
    border: none
    margin: 0
    padding: 0
    width: 100%
    &:hover
      background-color: rgba($info, 0.1)
  h5
    color: $secondary-light
    padding: 12px 0 12px 32px
</style>
