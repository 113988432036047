import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des packs de diffusion
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/advertisement-packs/`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getById = async (advertisementPackId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/advertisement-packs/${advertisementPackId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const update = async (advertisementPackId, advertisementPack) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/advertisement-packs/${advertisementPackId}`, advertisementPack);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const create = async (advertisementPack) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/advertisement-packs`, advertisementPack);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const deleteOne = async (advertisementPackId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/advertisement-packs/${advertisementPackId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const api = {};
api.getAll = getAll;
api.getById = getById;
api.update = update;
api.create = create;
api.deleteOne = deleteOne;

export default api;
