<template>
  <div class="login hedifys-21 grid-x">
    <div class="form-container">
      <div>
        <div class="row">
          <h1>Se connecter</h1>
        </div>
        <form @submit.prevent="login">
          <div class="row">
            <app-error v-if="isFormInvalid" title="Erreur" :messages="[errorMessage]" @click="isFormInvalid = false" />
          </div>
          <div class="row">
            <app-input type="email" v-model="email" label="Identifiant / Email" placeholder="Email..." :hasSucceed="isFormInvalid ? 'error' : null" required />
          </div>
          <div class="row">
            <app-input type="password" v-model="password" label="Mot de passe" placeholder="Mot de passe..." :hasSucceed="isFormInvalid ? 'error' : null" required />
          </div>
          <div class="row">
            <app-button :disabled="loading" theme="primary" type="submit">Se connecter</app-button>
          </div>
        </form>
        <router-link :to="{ name: 'lost-password' }">Mot de passe oublié ?</router-link>
      </div>
      <div class="copyright">
        <p>© {{ $dayjs().year() }} - STARLYD</p>
      </div>
    </div>
    <div class="logo-container">
      <div>
        <hedifys />
        <h1>HEDIFYS</h1>
      </div>
    </div>
  </div>
</template>

<script>
import hedifys from '@/assets/logos/hedifys.svg?inline';
import authApi from '@/services/auth';
import member from '@/services/api/member';

export default {
  name: 'member-login',
  components: {
    hedifys,
  },
  metaInfo: {
    title: 'Se connecter',
  },
  data() {
    return {
      email: null,
      password: null,
      isFormInvalid: false,
      errorMessage: 'Identifiants incorrects',
      loading: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        await authApi.login(this.email, this.password);
        const isTwoFactorAuthentified = await authApi.isTwoFactorAuthentified();

        this.isFormInvalid = false;

        if (!isTwoFactorAuthentified && process.env.VUE_APP_ENV_PREVENT_SENDING_MAIL === 'production') {
          await authApi.sendTwoFactorCode();
          this.$router.push({ name: 'two-factor-authentication' });
        } else {
          const me = await member.getMe();

          if (me.brandId) {
            this.$router.push({ name: 'home' });
          } else {
            this.$displayNotification('Votre compte Hedifys n’a accès à aucune marque.');
            await authApi.logout();
          }
        }
      } catch (e) {
        this.errorMessage = e.response.data.message === 'Related brand not found' ? 'Votre compte Hedifys n\'a accès à aucune marque.' : 'Identifiants incorrects';
        this.isFormInvalid = true;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.login
  height: 100%
  min-height: 750px
  flex-wrap: wrap-reverse
  .row
    @include row
  .form-container
    @include centered-container
    position: relative
    flex-basis: 40rem
    flex-grow: 1
    min-width: 700px
    > div
      width: 90%
      max-width: 420px
      margin: 0 auto
    .copyright
      position: absolute
      bottom: 24px
      text-align: center
      p
        color: $info
    button
      width: 100%
    a
      @include hyperlink
      text-align: center
      width: 100%
      display: block
  .logo-container
    @include centered-container
    background-image: linear-gradient($gradient-light, $gradient-dark)
    flex-basis: 0
    flex-grow: 999
    position: relative
    svg
      width: 135px
    h1
      color: $white
      font-weight: 300
      text-align: center
      margin-top: 16px
    .version
      position: absolute
      bottom: 24px
      > span
        color: $white
      .bubble
        background-color: $white
        border-radius: 15px
        margin-left: 8px
        color: $title
        padding: 5px 10px
</style>
