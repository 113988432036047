<template>
  <div class="brand-members hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Membres</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" type="submit" icon="add" @click="isInviteModalOpen = true">Ajouter un membre</app-button>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body" v-if="!isLoading">
      <app-table :headers="tableHeaders" :data="members" :loading="isLoading">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="photo" slot-scope="{ data }">
          <img v-if="data.photo" class="photo" alt="Photo de profil" :src="data.photo" />
          <div v-else class="avatar">
            <avatar />
          </div>
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="lastname" slot-scope="{ data }">
          <strong>{{ data.lastname }}</strong>
        </template>
        <template slot="firstname" slot-scope="{ data }">
          <p>{{ data.firstname }}</p>
        </template>
        <template slot="email" slot-scope="{ data }">
          <p>{{ data.email }}</p>
        </template>
        <template slot="phone" slot-scope="{ data }">
          <p>{{ data.phone }}</p>
        </template>
        <template slot="secondaryPhone" slot-scope="{ data }">
          <p v-if="data.secondaryPhone">{{ data.secondaryPhone }}</p>
          <p v-else>Non-renseigné</p>
        </template>
        <template slot="jobTitle" slot-scope="{ data }">
          <p>{{ formatJob(data.jobTitle) }}</p>
        </template>
        <template slot="rules" slot-scope="{ data }">
          <app-button v-if="data.memberId !== me.memberId" theme="warning" size="small" icon="remove" type="button" @click="removeMember(data)">Enlever les droits</app-button>
        </template>
      </app-table>
    </div>
    <div class="body" v-else>
      <div class="card spinner-container">
          <app-spinner />
      </div>
    </div>
    <!-- MODAL -->
    <app-modal :show="isInviteModalOpen" size="small" title="Inviter un membre" @update:show="isInviteModalOpen = false">
      <form ref="agency" @submit.prevent="inviteMember">
        <div class="grid-x grid-margin-x row">
          <div class="cell">
            <app-input v-model="email" label="Email du membre" placeholder="Email..." required />
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" icon="add" type="submit">Inviter le membre</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
      </form>
    </app-modal>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import auth from '@/services/auth';
import brandJobs from '@/services/data/brandJobs.json';
import agencyJobs from '@/services/data/agencyJobs.json';
import memberApi from '@/services/api/member';
import avatar from '@/assets/img/avatar.svg?inline';

export default {
  name: 'brand-members',
  components: {
    TabNav,
    avatar,
  },
  metaInfo: {
    title: 'Votre marque',
  },
  data() {
    return {
      isLoading: null,
      me: null,
      tableHeaders: [
        { label: '', key: 'photo', size: 1 },
        { label: 'Nom', key: 'lastname', size: 1 },
        { label: 'Prénom', key: 'firstname', size: 1 },
        { label: 'Email', key: 'email', size: 3 },
        { label: 'Téléphone', key: 'phone', size: 1 },
        { label: '', key: 'secondaryPhone', size: 1 },
        { label: 'Poste', key: 'jobTitle', size: 2 },
        { label: '', key: 'rules', size: 2 },
      ],
      isInviteModalOpen: false,
      email: null,
      members: [],
      brand: null,
      tabs: [
        {
          route: {
            name: 'brand',
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'brand-members',
          },
          label: 'Membres',
        },
        {
          route: {
            name: 'brand-parameters',
          },
          label: 'Paramètres de marque',
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getMembersByBrand();
    this.me = await memberApi.getMe();
    this.isLoading = false;
  },
  methods: {
    async getMembersByBrand() {
      try {
        this.brand = await auth.getBrand();
        this.members = await memberApi.getByBrand(this.brand.brandId);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des membres de la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async inviteMember() {
      this.isLoading = true;
      try {
        await memberApi.invite(this.email, this.brand.brandId, null);
        this.$notification.show({ text: 'Ce membre a été invité ! \n Il sera ajouté à la liste, une fois son inscription faite' });
        this.isInviteModalOpen = false;
        this.email = null;
        await this.getMembersByBrand();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'invitation du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async removeMember(member) {
      this.$message.show({
        title: 'Retirer ses accès au membre',
        text: 'Attention, si vous confirmez, le membre perdra ses accès.',
        confirmText: 'Retirer l’accès',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: async () => {
          this.isLoading = true;
          try {
            await memberApi.deleteRule(member.rule.ruleId);
            this.$notification.show({ text: 'Les accès du membre ont été mis à jour avec succès !' });
            await this.getMembersByBrand();
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de l\'édition des accès du membre.',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
          this.isLoading = false;
        },
      });
    },
    formatJob(job) {
      const j = brandJobs.concat(agencyJobs).find((el) => el.name === job);
      return j !== undefined ? j.label : null;
    },
  },
};
</script>

<style lang='sass' scoped>
.brand-members
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .spinner-container
    @include spinner-container
  .text
    @include details
  .photo
    border-radius: 50%
    width: 2.5rem
    height: 2.5rem
    object-fit: cover
  .avatar
    border-radius: 50%
    width: 2.5rem
    height: 2.5rem
    background-color: $line
    display: flex
    justify-content: center
    align-items: flex-end

    > svg
      width: 30px
      height: 30px
      fill: $off-white
  .card
    @include card
</style>
