import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère tous les documents associés à une rubrique
 */
const getAllByCategory = async (categoryId, limit, offset, documentSubCategoryId, search, orderBy) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/document-categories/${categoryId}/documents/`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(documentSubCategoryId && { documentSubCategoryId }),
        ...(search && { search }),
        ...(orderBy && { orderBy }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const getAllBySubCategory = async (documentSubCategoryId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/document-sub-categories/${documentSubCategoryId}/documents/`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère les derniers documents
 */
const getLastDocuments = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/documents/last`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Crée un document
 */
const createDocument = async (brandId, file, documentSubCategoryId) => {
  const form = new FormData();
  form.append('file', file);
  form.append('documentSubCategoryId', documentSubCategoryId);

  try {
    const response = await axios.post(
      `${VUE_APP_API_URL}/brands/${brandId}/documents`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Modifie un document
 */
const updateDocument = async (documentId, documentSubCategoryId) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/documents/${documentId}`, { documentSubCategoryId });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Supprime un document
 */
const deleteDocument = async (brandId, documentId) => {
  let response = null;
  try {
    response = await axios.delete(`${VUE_APP_API_URL}/brands/${brandId}/documents/${documentId}`);
  } catch (er) {
    throw er;
  }
  return response.data;
};

const document = {};
document.getAllByCategory = getAllByCategory;
document.createDocument = createDocument;
document.updateDocument = updateDocument;
document.deleteDocument = deleteDocument;
document.getLastDocuments = getLastDocuments;
document.getAllBySubCategory = getAllBySubCategory;

export default document;
