<template>
  <h2 class="app-subtitle"><slot></slot></h2>
</template>

<script>
export default {
};
</script>

<style lang="sass">
.app-subtitle
  position: relative
  padding: 0.5rem 0
  text-transform: uppercase
  font-weight: bold
  font-size: 2rem
  text-align: left
  &:after
    content: ""
    position: absolute
    top: 100%
    left: 0
    width: 50px
    height: 4px
    background: $main-color
</style>
