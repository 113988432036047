<template>
  <nav class="tab-nav">
    <ul>
      <li v-for="tab in tabs" :key="tab.route.name">
        <router-link
          :to="tab.route"
          :class="{'router-link-exact-active': tab.route.name === $route.name}"
        >
          <span>{{ tab.label }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'tab-nav',
  props: {
    tabs: {
      // Format : [{ route: { name: '', params:{} }, label: 'home' }, ...]
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang='sass' scoped>
.tab-nav
  display: flex
  background-color: $off-white
  position: sticky
  top: 65px
  box-shadow: 0 2px 4px 0 $shadow
  z-index: 2

  ul
    display: flex
    flex-direction: row
    align-items: center
    padding: unset
    margin-left: 56px

  li + li
    margin-left: 48px

  span
    padding: 16px 0
    color: $secondary-light
    display: block
    @include heading-sm

  a
    display: block
    border-bottom: 2px transparent solid

  .router-link-exact-active
    border-bottom: 2px $primary solid
    span
      color: $primary
      @include heading-sm-bold
</style>
