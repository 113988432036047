<template>
  <div class="version-summary hedifys-21">
    <form ref="version-constraints" @submit.prevent="save">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="version && version.name"
          :items="[
            { route: { name: 'versions'}, name: 'Versions' },
            { route: { name: 'versions-versionId', params: { versionId: $route.params.versionId }}, name: version.reference }
          ]"
        />
      </div>
    </div>

    <!-- HEADER / TITRE -->

    <div class="header grid-x">
      <div class="cell auto">
        <h1>{{ version && version.reference ? version.reference : '' }}</h1>
      </div>
      <div class="cell shrink">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
      </div>
    </div>

    <!-- TABS -->
    <tab-nav :tabs="tabs"/>

    <!-- BODY / FORMULAIRE -->
    <div class="body" v-if="!isLoading && version">
      <div class="row">
        <h2>Contraintes</h2>
        <p>Seules les contraintes "Sélection Version" sont présentes dans la liste.</p>
      </div>
      <div class="card">
          <div class="grid-x row grid-margin-x">
            <div class="cell">
              <app-search size="auto" v-model="search" placeholder="Rechercher" />
            </div>
          </div>
          <div class="constraints grid-x row grid-margin-x">
            <div class="cell auto">
              <app-table :headers="tableHeaders" :data="filteredConstraints" :loading="isLoading" small>
                <template slot="loading">
                  <app-spinner />
                </template>
                <template slot="empty-table">
                  <p>Aucune données de disponible</p>
                </template>
                <template slot="isIncluded" slot-scope="{ data }">
                  <app-checkbox
                    v-model="currentSelectedConstraintsIds"
                    :value="data.constraintId"
                  />
                </template>
                <template slot="name" slot-scope="{ data }">
                  <strong>{{ data.name }}</strong>
                </template>
              </app-table>
            </div>
          </div>
        </div>
    </div>

    <div class="body" v-else>
      <div class="card spinner-container">
        <app-spinner />
      </div>
    </div>
  </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import utils from '@/services/utils/utils';
import versionApi from '@/services/api/version';
import constraintsApi from '../../services/api/constraint';

export default {
  name: 'version-constraints',

  components: {
    TabNav,
    Breadcrumb,
  },

  metaInfo() {
    return {
      title: this.version ? `${this.version.reference} – Version` : 'Version',
    };
  },

  data() {
    return {
      isLoading: false,
      version: null,
      utils,
      constraints: [],
      filteredConstraints: [],
      previousSelectedConstraints: [],
      currentSelectedConstraintsIds: [],
      tableHeaders: [
        { label: '', key: 'isIncluded', size: 1 },
        { label: 'Contraintes', key: 'name', size: 10 },
      ],
      search: null,
    };
  },
  computed: {
    constraintsAdded() {
      return this.selectedConstraints.filter((sc) => !this.previousSelectedConstraints.map((psc) => psc.constraintId).includes(sc.constraintId));
    },
    selectedConstraints() {
      return this.constraints.filter((c) => this.currentSelectedConstraintsIds.includes(c.constraintId));
    },
    constraintsRemoved() {
      return this.previousSelectedConstraints.filter((c) => !this.currentSelectedConstraintsIds.includes(c.constraintId));
    },
    tabs() {
      const tabs = [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: null },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: null },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: null },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: null },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: null },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: null },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: null },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: null },
          },
          label: 'Prix',
        },
      ];

      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { versionId: this.$route.params.versionId },
        },
        label: el.label,
      }));
    },
  },
  watch: {
    $route() {
      this.getData();
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
  },
  mounted() {
    this.getData();
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.filterConstraints, 500);
  },

  methods: {
    // Récupération des données
    async getData() {
      this.isLoading = true;
      await this.getVersion();
      await this.getConstraintsWithAllowedVersionsCondition();
      await this.getSelectedConstraints();

      this.isLoading = false;
    },

    // Récupération de la version
    async getVersion() {
      try {
        if (this.$route.params.versionId) {
          this.version = await versionApi.getById(this.$route.params.versionId);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la version',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getSelectedConstraints() {
      try {
        this.previousSelectedConstraints = await constraintsApi.getWithAllowedVersionsConditionByVersion(this.version.versionId);
        this.currentSelectedConstraintsIds = this.previousSelectedConstraints.map((c) => c.constraintId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des contraintes appliquées',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getConstraintsWithAllowedVersionsCondition() {
      try {
        this.constraints = await constraintsApi.getAllWithAllowedVersionsCondition();
        this.filteredConstraints = this.constraints;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des contraintes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    filterConstraints() {
      if (this.search && this.search.length) {
        this.filteredConstraints = this.constraints.filter((o) => o.name && o.name.toLowerCase().includes(this.search.toLowerCase()));
      } else this.filteredConstraints = this.constraints;
    },

    async save() {
      try {
        if (this.constraintsAdded && this.constraintsAdded.length > 0) {
          this.constraintsAdded.forEach(async (constraint) => {
            const {
              constraintId,
              type,
              name,
              conditions,
              allowedVersions,
            } = constraint;
            allowedVersions.push(this.version.versionId);
            await constraintsApi.update(constraintId, {
              type,
              name,
              conditions,
              allowedVersions,
            });
          });
        }
        if (this.constraintsRemoved && this.constraintsRemoved.length > 0) {
          this.constraintsRemoved.forEach(async (constraint) => {
            const {
              constraintId,
              type,
              name,
              conditions,
              allowedVersions,
            } = constraint;
            const newAllowedVersions = allowedVersions.filter((av) => av !== this.version.versionId);
            await constraintsApi.update(constraintId, {
              type,
              name,
              conditions,
              allowedVersions: newAllowedVersions,
            });
          });
        }
        this.$notification.show({ text: 'Les contraintes ont bien été mises à jour !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.version-summary
  .body
    @include screen
    .constraints
      min-height: 345px
      .data
        flex: auto
  .header
    @include header
    padding-bottom: 4px
  .breadcrumbs
    @include breadcrumbs
  .row
    @include row
    h2
      margin-bottom: 10px
  .spinner-container
    @include spinner-container
  .card
    @include card
</style>
