import axios from 'axios';
import ls from 'local-storage';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère les données de son compte
 */
const getMe = async (params) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/members/me`, { params });

    let memberWithRules = null;
    // On des booleans pour facilité l'affichage qui dépend des droits
    if (response && response.data && response.data.data) {
      memberWithRules = response.data.data;
      memberWithRules.isBrandAdmin = response.data.data
        .rules
        .some((rule) => (rule.brandId) && (rule.type === 'ADMIN' || rule.type === 'SUPER_ADMIN'));

      memberWithRules.isAgencyAdmin = response.data.data
        .rules
        .some((rule) => (rule.agencyId) && (rule.type === 'ADMIN' || rule.type === 'SUPER_ADMIN'));
    }
    // Sauvegarde du brandId du membre dans le localStorage
    ls('BRAND', response.data.data.brandId);

    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère les données de tous les membres
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/members`);
    return response.data.data;
  } catch (er) {
    return er;
  }
};

/**
 * Met à jour les données de son compte
 * @param {objet} member informations de son compte à mettre à jour
 */
const updateMe = async (member) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/members/me`, member);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const updatePasswordProfil = async (memberId, oldPassword, newPassword) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/members/${memberId}/password`, { oldPassword, newPassword });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const registerNewMember = async (token, newMember) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/register`, { token, ...newMember });
    return response.data;
  } catch (er) {
    throw er;
  }
};

// Hedifys 21

/**
 * Récupère la liste des agences
 * @param {string} email: email du membre a qui a demandé un changement de mot de passe
 */
const lostPassword = async (email) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/lost-password`, { email });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une agence
 * @param {string} token: token obtenu dans le lien du mail
 * @param {string} password: nouveau mot de passe
 */
const updatePassword = async (token, password) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/auth/lost-password/new-password?token=${token}`, { password });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * @param {string} memberId: id du membre
 * @returns membre mis à jour
 */
const update = async (memberId, member) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/members/${memberId}`, member);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression d'une règle
 * @param {string} ruleId: id de la règle à supprimer
 * @returns void
 */
const deleteRule = async (ruleId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/rules/${ruleId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * La route va enoyer une mail, avec un lien au futur membre pour qu'il puisse s'inscrire
 * @param {string} brandId: id de la marque
 * @param {string} agencyId: id de l'agence
 * @param {string} type: type de droit pour le membre
 * @param {string} email: email du futur membre
 * @returns void
 */
const invite = async (email, brandId, agencyId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/members/invitation`, {
      email, type: 'ADMIN', brandId, agencyId,
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * @param {string} agencyId: id de l'agence
 * @returns liste de membres par agence
 */
const getByAgency = async (agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/${agencyId}/members`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère les membres d'une agence publique
 * @param {uuid} agencyId : id de l'agence concernée
 */
const getPublicByAgency = async (agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/agencies/${agencyId}/members`);
    return response.data.data;
  } catch (er) {
    return er;
  }
};

/**
 * @param {string} brandId: id de la marque
 * @returns liste de membres par marque
 */
const getByBrand = async (brandId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/brands/${brandId}/members`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * @param {string} memberId: id du membre
 * @param {File} file: image à upload, optionnel
 * @returns données du membre, incluant la nouvelle image ou sa suppression
 */
const uploadPhoto = async (memberId, file) => {
  try {
    let response = null;
    if (file === null) {
      response = await axios.post(`${VUE_APP_API_URL}/members/${memberId}/photo`);
    } else {
      response = await axios.post(`${VUE_APP_API_URL}/members/${memberId}/photo`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    return response.data;
  } catch (er) {
    throw er;
  }
};

const member = {};
member.getMe = getMe;
member.getAll = getAll;
member.updateMe = updateMe;
member.update = update;
member.invite = invite;
member.registerNewMember = registerNewMember;
member.lostPassword = lostPassword;
member.updatePassword = updatePassword;
member.deleteRule = deleteRule;
member.getByAgency = getByAgency;
member.updatePasswordProfil = updatePasswordProfil;
member.getByBrand = getByBrand;
member.uploadPhoto = uploadPhoto;
member.getPublicByAgency = getPublicByAgency;

export default member;
