import axios from 'axios';
import roomTypes from '@/services/data/roomTypes.json';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère toutes les pièces
 * @param {string} type: le type de la pièce
 */
const getAll = async (type) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/rooms/`, {
      params: { ...(type && type !== roomTypes[0].name && { type }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const room = {};
room.getAll = getAll;

export default room;
