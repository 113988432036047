<template>
  <div class="customers">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1 class="title">Liste des clients {{ customers && customers.metadata && customers.metadata.count ? `(${customers.metadata.count})` : '' }}</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToCustomer">Ajouter un client</app-button>
      </div>
    </div>

    <!-- BODY -->
    <div class="body">
      <div class="search grid-x">
        <!-- RECHERCHE -->
        <div class="cell shrink search">
          <app-search size="small" v-model="search" placeholder="Rechercher un client" />
        </div>

        <!-- FILTRE PAR AGENCE -->
        <div class="cell medium-2 search" v-if="isBrandAdmin">
          <app-select
            :value="null"
            v-model="agencyId"
            :options="agencies"
            :disabled="agencies.length === 0"
            @input="agencyChange"
          />
        </div>

        <div class="cell shrink search" v-if="isBrandAdmin">
          <app-button theme="secondary" size="small" @click="initSearch">Réinitialiser</app-button>
        </div>

        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterCustomers"
            @reset="resetFilters"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-label class="label-period">Période</app-label>
                <app-label>Du</app-label>
                <app-datepicker
                :value="filters.startDate"
                @input="handleFilterDateInput('startDate', $event)"
                :language="datePickerLanguage"
                monday-first :required="!!(filters.endDate)"/>
                <app-label class="label-datepicker">Au</app-label>
                <app-datepicker
                :value="filters.endDate"
                  @input="handleFilterDateInput('endDate', $event)"
                  :language="datePickerLanguage"
                  monday-first :required="!!(filters.startDate)"/>
              </div>
            </div>
          </filter-modal>
        </div>
      </div>

      <app-table
        :headers="tableHeaders"
        :data="customers.data"
        :loading="isLoading"
        @line-cliked="goToCustomer"
        clickable
      >
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="reference" slot-scope="{ data }">
          <strong>{{ data.reference || '--' }}</strong>
        </template>
        <template slot="name" slot-scope="{ data }">
          <p>{{ data.firstname }} {{ data.lastname }}</p>
        </template>
        <template slot="phone" slot-scope="{ data }">
          <p>{{ data.phone }}</p>
        </template>
        <template slot="city" slot-scope="{ data }">
          <p>{{ data.address ? data.address.city : '' }}</p>
        </template>
        <template slot="agency" slot-scope="{ data }">
          <p>{{ data.agency ? data.agency.name : '' }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs.utc(data.createdAt).format('DD/MM/YYYY') }}</p>
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
      </app-table>

      <app-pagination
        v-if="customers && customers.metadata"
        :limit="limit"
        :offset="customers.metadata.offset"
        :count="customers.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import customerApi from '@/services/api/customer';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';

import FilterModal from '@/components/general/FilterModal.vue';

import utils from '@/services/utils/utils';

export default {
  name: 'customers',
  components: {
    FilterModal,
  },
  metaInfo: {
    title: 'Liste des clients',
  },
  data() {
    return {
      customers: [],
      limit: 10,
      agencies: [],
      search: null,
      isLoading: true,
      tableHeaders: [
        { label: 'Référence', key: 'reference', size: 1 },
        { label: 'Nom', key: 'name', size: 2 },
        { label: 'Mail', key: 'email', size: 3 },
        { label: 'Téléphone', key: 'phone', size: 1 },
        { label: 'Commune', key: 'city', size: 2 },
        { label: 'Agence', key: 'agency', size: 2 },
        { label: 'Crée le', key: 'createdAt', size: 1 },
      ],
      filters: {
        startDate: null,
        endDate: null,
      },
      datePickerLanguage: 'fr',
      me: null,
      isBrandAdmin: null,
      agencyId: null,
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  async mounted() {
    this.isLoading = true;
    this.search = this.$route.query.search || null;
    this.filters.startDate = this.$route.query.startDate || null;
    this.filters.endDate = this.$route.query.endDate || null;

    await this.getCustomers(this.computeOffset);
    await this.getMe();
    this.isBrandAdmin = this.me.isBrandAdmin;

    if (this.isBrandAdmin) {
      await this.getAgencies();
    }
    this.isLoading = false;
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }

      return null;
    },
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getCustomers(this.computeOffset);
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },

    filters: {
      handler(value) {
        this.filters.startDate = value.startDate;
        this.filters.endDate = value.endDate;
      },
      deep: true,
    },
  },

  methods: {
    initSearch() {
      this.agencyId = undefined;
      this.search = undefined;

      if (this.$route.query.search !== this.search || this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: undefined,
            agencyId: undefined,
            page: 1,
          },
        });
      }
    },

    // Récupération du nom du client
    getCustomersNames(selectedCustomer) {
      return customerApi.getCustomersNames(selectedCustomer);
    },

    // Redirection vers le client
    goToCustomer(customer) {
      if (customer && customer.customerId) {
        this.$router.push({
          name: 'projects-customers-customerId',
          params: { customerId: customer.customerId },
        });
      } else {
        this.$router.push({ name: 'projects-customers-new' });
      }
    },

    // Récupération des clients
    async getCustomers() {
      try {
        this.customers = await customerApi.getAll(
          this.limit,
          this.computeOffset,
          this.$route.query.search,
          ['CLIENT'],
          this.$route.query.agencyId,
          null,
          this.filters.startDate,
          this.filters.endDate,
        );
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des clients',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    handleFilterDateInput(filterName, date) {
      this.filters[filterName] = date ? this.$dayjs(date).toISOString() : undefined;
    },
    // Récupération du compte
    async getMe() {
      try {
        this.me = await memberApi.getMe();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupère les agences
    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll();
        const parsedAgencies = utils.formatOptions(
          agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agencies = parsedAgencies;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de l\'agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Met à jour la recherche
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
            page: 1,
          },
        });
      }
    },

    // Filtrage des clients
    filterCustomers() {
      this.$router.push({
        query: {
          ...this.$route.query,
          startDate: this.filters.startDate || undefined,
          endDate: this.filters.endDate || undefined,
        },
      });
    },

    // Reset les filtres
    async resetFilters() {
      this.filters = {
        startDate: null,
        endDate: null,
      };

      this.$router.replace({
        query: {
          ...this.$route.query,
          startDate: undefined,
          endDate: undefined,
        },
      });
    },

    // Ferme la modale des filtres
    closeFilterModal() {
      this.filters.startDate = this.$route.query.startDate || null;
      this.filters.endDate = this.$route.query.endDate || null;
    },
    agencyChange() {
      if (this.$route.query.agencyId !== this.agencyId) {
        this.$router.push({
          query: {
            ...this.$route.query,
            agencyId: this.agencyId || undefined,
          },
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.customers
  .header
    @include header
  .row
    @include row
  .body
    @include screen
  .filter-container
    margin-left: auto
  .search
    padding-bottom: 1rem
    margin-right: 1rem
    .button
      margin-left: 1rem
  .label-period
    margin-bottom: 1rem
  .label-datepicker
    margin-top: 0.5rem
</style>
