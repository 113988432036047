<template>
  <div class='variables hedifys-21'>
    <!-- HEADER / TITRE -->
    <div class='header grid-x'>
      <div class='cell auto'>
        <h1>Paramètres de coût</h1>
      </div>
      <div class="cell shrink">
        <app-button
          variable="primary"
          size="large"
          icon="add"
          @click="showCreateVariableModale = true"
        >
          Ajouter un paramètre
        </app-button>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="body">

      <p class="description">Les paramètres de coût sont personnalisables à la version et utilisables dans la formule d’une base, d’une option, d’un frais ou d’un lot.</p>

      <!-- CONTENT -->
      <div class="search grid-x">
        <div class="cell shrink">
          <app-search size="small" v-model="search" placeholder="Rechercher par nom" />
        </div>

        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterVariables"
            @reset="resetFilers"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn">
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Unité"
                  v-model="filters.unit"
                  :options="[{ name: null, label: 'Choisir' }, ...unitOptions]"
                />
              </div>
              <div class="cell auto grid-y grid-margin-y">
                <div class="cell small-1"/>
                <div class="cell small-1">
                  <app-label>Éditable</app-label>
                </div>
                <div class="cell small-2">
                  <app-radio
                    name="isReadonly"
                    :value=null
                    v-model="filters.isReadonly"
                  >
                    Tous
                  </app-radio>
                </div>
                <div class="cell small-2">
                  <app-radio
                    name="isReadonly"
                    :value=false
                    v-model="filters.isReadonly"
                  >
                    Éditable
                  </app-radio>
                </div>
                <div class="cell small-2">
                  <app-radio
                    name="isReadonly"
                    :value=true
                    v-model="filters.isReadonly"
                  >
                    Non éditable
                  </app-radio>
                </div>
              </div>
            </div>
          </filter-modal>
        </div>
      </div>

      <!-- TABLE -->
      <app-table
        :headers="tableHeader"
        :data="variables"
        :loading="isLoading"
        @line-cliked="getSelectedVariable"
        clickable
        :isClickableIf="(value) => !value.isReadonly"
      >
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="unit" slot-scope="{ data }">
          <h5 class="tag tag-neutral">{{ Unit[data.unit] }}</h5>
        </template>
        <template slot="isReadonly" slot-scope="{ data }">
          <p>{{ IsReadonly[!data.isReadonly] }}</p>
        </template>
        <template slot="key" slot-scope="{ data }">
          <p>{{ data.key }}</p>
        </template>
        <template slot="empty-table">
          <p>Aucune donnée de disponible</p>
        </template>
      </app-table>

      <!-- EDIT MODAL -->
      <AppModal
        class="edit-variable"
        :show="showCreateVariableModale"
        size="medium"
        :title="variable.variableId ? 'Modifier le paramètre de coût' : 'Ajouter un paramètre de coût'"
        @update:show="closeEditVariableModale"
      >
        <form ref="variable" @submit.prevent="editVariable">
          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-input
                class="edit-variable-input-container"
                label="Nom"
                placeholder="Nom"
                :value="variable.name"
                v-model="formatVariableKey"
                required
              />
            </div>
            <div class="cell medium-6">
              <app-input
                class="edit-variable-input-container"
                label="Variable technique générée"
                :value="variable.key"
                disabled
              />
            </div>
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-select
                class="edit-variable-input-container"
                label="Unité"
                v-model="variable.unit"
                :options="unitOptions"
                required
              />
            </div>
          </div>

          <div class="edit-variable-input-submit-container grid-x grid-margin-x">
            <div class="cell auto"/>
            <div v-if="variable.variableId" class='cell shrink'>
              <app-button
                theme="warning"
                size="large"
                icon="remove"
                @click="deleteVariable(variable.variableId)"
              >
                Supprimer
              </app-button>
            </div>
            <div class='cell shrink'>
              <app-button
                variable="primary"
                size="large"
                icon="edit"
                type="submit"
              >
                Enregistrer les modifications
              </app-button>
            </div>
            <div class="cell auto"/>
          </div>
        </form>
      </AppModal>
    </div>
  </div>
</template>

<script>
import variableApi from '@/services/api/variable';
import utils from '@/services/utils/utils';
import Unit from '@/services/enums/unit.enum';
import unitOptions from '@/services/data/units.json';
import IsReadonly from '@/services/enums/isReadonly.enum';
import behaviors from '@/services/data/behaviors.json';
import FilterModal from '@/components/general/FilterModal.vue';
import AppModal from '@/basics/components/AppModal.vue';

export default {
  name: 'variables',
  components: {
    FilterModal,
    AppModal,
  },

  metaInfo: {
    title: 'Paramètres de coût',
  },

  data() {
    return {
      // Table
      tableHeader: [
        { label: 'Name', key: 'name', size: 8 },
        { label: 'Unité', key: 'unit', size: 1 },
        { label: 'Éditable', key: 'isReadonly', size: 1 },
        { label: 'Variable technique', key: 'key', size: 2 },
      ],
      isLoading: false,

      // variables
      variables: [],
      variable: {
        isReadonly: false,
        name: '',
        unit: '',
        key: '$',
      },

      // Lots
      lots: [],

      // Categories
      categories: [],

      // Behaviors
      behaviors,

      // Modale
      showCreateVariableModale: false,

      // Search
      search: null,

      // Filters
      filters: {
        unit: null,
        isReadonly: null,
      },

      // Enums
      Unit,
      IsReadonly,

      // Options
      unitOptions,
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },

  mounted() {
    this.search = this.$route.query.search;
    this.filters.unit = this.$route.query.unit || null;
    this.filters.isReadonly = utils.parseBoolean(this.$route.query.isReadonly);
    this.getVariables();
  },

  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },

    filters: {
      handler(value) {
        this.filters.unit = value.unit;
        this.filters.isReadonly = value.isReadonly;
      },
      deep: true,
    },
  },

  computed: {
    // Formate la valeur de la variable technique
    formatVariableKey: {
      get() {
        return this.variable.name;
      },

      set(value) {
        this.variable.name = value;
        this.variable.key = utils.formatVariableKey(value);
      },
    },
  },

  methods: {
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });

        if (!this.isLoading) {
          this.getVariables();
        }
      }
    },

    // Récupération des thèmes
    async getVariables() {
      this.isLoading = true;

      try {
        this.variables = await variableApi.getAll(
          this.$route.query.search,
          this.$route.query.unit,
          this.$route.query.isReadonly,
        );
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des paramètres de coût',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.isLoading = false;
    },

    // Récupère la variable sélectionné
    getSelectedVariable(selectedVariable) {
      // On vérifie si la variable sélectionné est éditable
      if (!selectedVariable.isReadonly) {
        this.variableId = selectedVariable.variableId;

        const {
          createdAt,
          updatedAt,
          deletedAt,
          ...data
        } = selectedVariable;
        this.variable = data;
        this.variable.key = utils.formatVariableKey(this.variable.name);

        this.showCreateVariableModale = true;
      }
    },

    // Édition d'une variable
    async editVariable() {
      if (this.variable.variableId) {
        try {
          const { variableId, options, ...data } = this.variable;
          await variableApi.update(this.variable.variableId, data);
          this.$notification.show({ text: 'Le paramètre de coût a bien été mis à jour.' });
        } catch (error) {
          let erroMessage = 'Il y a eu un problème lors de la mise à jour du paramètre de coût';
          if (error && error.message.includes('406')) {
            erroMessage = 'Vous ne pouvez pas modifier ce paramètre de coût car il est utilisé dans une option';
          }
          this.$message.show({
            title: 'Erreur',
            text: erroMessage,
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      } else {
        try {
          const { ...data } = this.variable;
          await variableApi.create(data);
          this.$notification.show({ text: 'Le paramètre de coût a bien été crée.' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la mise à jour du paramètre de coût',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }

      this.closeEditVariableModale();
      this.getVariables();
    },

    // Suppression d'une variable
    async deleteVariable(variableId) {
      this.$message.show({
        title: 'Supprimer le paramètre de coût',
        text: 'Souhaitez vous supprimer ce paramètre de coût ?',
        confirmText: 'Supprimer le paramètre de coût',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: async () => {
          try {
            await variableApi.delete(variableId);
            this.$notification.show({ text: 'Le paramètre de coût a bien été supprimé.' });
            this.closeEditVariableModale();
            this.getVariables();
          } catch (error) {
            let erroMessage = 'Il y a eu un problème lors de la suppression du paramètre de coût';
            if (error && error.message.includes('406')) {
              erroMessage = 'Vous ne pouvez pas supprimer ce paramètre de coût car il est utilisé dans une option';
            }
            this.$message.show({
              title: 'Erreur',
              text: erroMessage,
              cancelText: 'Ok',
              hasCancel: true,
            });
            this.closeEditVariableModale();
          }
        },
      });
    },

    async filterVariables() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      this.$router.push({
        query: {
          ...this.$route.query,
          unit: this.filters.unit || undefined,
          isReadonly: this.filters.isReadonly === null ? undefined : this.filters.isReadonly,
        },
      });

      // On récupère les variables avec les nouvelles query
      this.getVariables();
    },

    async resetFilers() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        unit: null,
        isReadonly: null,
      };

      // On supprime les query categoryId et lotId de l'URl
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          unit: undefined,
          isReadonly: undefined,
        },
      });

      this.getVariables();
    },

    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.unit = this.$route.query.unit || null;
      this.filters.isReadonly = this.$route.query.isReadonly || null;
    },

    // Fermeture de la modale d'édition
    closeEditVariableModale() {
      this.showCreateVariableModale = false;
      this.resetVariable();
    },

    // Réinitialisation de l'objet variable
    resetVariable() {
      this.variable = {
        isReadonly: false,
        name: '',
        unit: '',
        key: '',
      };

      delete this.variable.variableId;
    },
  },
};
</script>

<style lang='sass' scoped>
.variables
  .body
    @include screen

  .header
    @include header

  .row
    @include row

  .description
    @include description

  .search
    padding-bottom: 1rem
    button
      margin-left: 1rem

  .filter-container
    margin-left: auto

  .edit-variable
    .edit-variable-input-container
      margin-bottom: 1rem
      &:first-of-type, &:last-of-type
        margin-top: 1rem

    .edit-variable-input-submit-container
      margin-top: 3rem

    .edit-variable-label
      font-weight: 500
      margin-bottom: .5rem
      &:first-of-type
        margin-top: .5rem
</style>
