/* eslint-disable vue/no-mutating-props */
<template>
  <div class="build-step-gantt">
    <span class="task" :class="[value.startStatus, value.closeStatus]"
      :style="{
        width: `${ boxWidth }px`,
        transform: `translate3d(${x}px,0, 0)`
      }">
      {{ $dayjs(value.closedAt).diff($dayjs(value.startedAt), 'day') + 1 }}J
      <div class="dropdown-build-step">
        <span class="label active" v-if="!disabled" @click="showDropDown = !showDropDown">☰ {{ value.label }}</span>
        <span class="label active" v-if="disabled"> {{ value.label }} </span>
      </div>

      <span class="company" v-if="companyCategory">
        {{ companyCategory.label }} {{ getCompanyLabel() }}
      </span>
      <span class="company event" v-if="value.type === 'EVENT'">>>> Évènement</span>

      <span class="edit-start"
        v-if="!disabled" draggable="true"
        @dragstart="dragStartEdit"
        @drag="dragMoveEditStart"
        @dragend="dragEndEdit">
      </span>

      <span class="edit-move"
        v-if="!disabled" draggable="true"
        @dragstart="dragStartMove"
        @drag="dragMove"
        @dragend="dragEndMove">
      </span>

      <span class="edit-end"
        v-if="!disabled" draggable="true"
        @dragstart="dragStartEdit"
        @drag="dragMoveEditEnd"
        @dragend="dragEndEdit">
      </span>
    </span>
    <div v-if="showDropDown" class="gantt-actions"
      :style="{
        transform: `translate3d(${x + boxWidth}px,-15px, 0)`
      }">
        <DropdownActions
          :actions="dropdownContent"
          :initialValue="true"
          @dropdownClosed="showDropDown = false" />
    </div>
    <section>
      <vk-modal class="modal-edit"
        :show="showEditModal">
        <vk-modal-close @click="showEditModal = false; showDropDown = false"></vk-modal-close>
        <vk-modal-title>
          Édition de la tâche
        </vk-modal-title>
        <div class="modal-body">
          <vk-grid gutter="collapse" class="uk-child-width-expand">
            <div class="column uk-width-1-2@m">
              <div v-if="currentValue.type !== 'START' && currentValue.type !== 'CLOSE'" class="content partners">
                <label>Type :</label>
                <select class="uk-select uk-input" v-model="currentValue.type" @change="handleEvent($event)">
                  <option
                    v-for="(label, type) in buildTypes"
                    :key="type" :value="type">
                    {{ label }}
                  </option>
                </select>
              </div>
              <div class="content build-step-name" v-if="value.type === 'DEFAULT' || value.type === 'EVENT'">
                <label>Nom de la tâche :</label>
                <input class="uk-input"  type="text" v-model="currentValue.label" placeholder="Nom">
              </div>
              <div class="content partners" v-if="value.type === 'DEFAULT'">
                <label>Catégorie :</label>
                <select class="uk-select uk-input" v-if="companyCategories" v-model="currentValue.companyCategoryId" @change="setFilteredCompanies">
                  <option :value="null">Sans catégorie</option>
                  <option
                    v-for="category in companyCategories"
                    :key="category.companyCategoryId" :value="category.companyCategoryId">
                    {{ category.label }} ({{ types.companyCategoryType[category.type] }})
                  </option>
                </select>
              </div>
            </div>
            <div class="column uk-width-1-2@m">
              <div class="content date build-step-dates">
                <label class="title">Début</label>
                <datepicker v-model="currentValue.startedAt"
                  :language="french"
                  format="dd MMMM yyyy">
                </datepicker>
              </div>
              <div class="content date build-step-dates" v-if="value.type !== 'EVENT'">
                <label class="title">Fin</label>
                <datepicker v-model="currentValue.closedAt"
                  :language="french"
                  format="dd MMMM yyyy">
                </datepicker>
              </div>
              <div class="content partners" v-if="value.type === 'DEFAULT'">
                <label>Partenaire :</label>
                <select class="uk-select uk-input" v-if="companyCategories" v-model="currentValue.companyId">
                  <option :value="null">Sans partenaire</option>
                  <option
                    v-for="company in filteredCompanies"
                    :key="company.companyId"
                    :value="company.companyId"
                  >
                    {{ company.name }}
                  </option>
                </select>
              </div>
            </div>
          </vk-grid>
          <vk-button
            @click="saveData()"
            type="primary"
            class="primary button">Enregistrer</vk-button>
        </div>
      </vk-modal>
    </section>
    <section>
      <vk-modal class="modal-delay"
        :show="showDelayModal">
        <vk-modal-close @click="showDelayModal = false; showDropDown = false"></vk-modal-close>
        <vk-modal-title>
          Décaler les tâches suivantes
        </vk-modal-title>
        <div class="modal-body">
          <div class="container uk-width-1-2@m">
            <div class="content">
              <label>Nombre de semaines *</label>
              <input class="uk-input" type="number" v-model="delayInWeeks" @keypress="delayInWeeks = parseInt(delayInWeeks)"/>
            </div>
          </div>
          <div class="content">
            <label>* Cette tâche n'est pas incluse, seules les tâches suivantes seront décalées.</label>
          </div>
          <div>
            <vk-grid gutter="collapse" class="container uk-child-width-expand">
              <div class="content">
                <div class="column uk-width-expand@m"></div>
                <div class="column uk-width-auto@m">
                  <vk-button @click="delayFollowongBuildSteps" class="primary button">Décaler</vk-button>
                </div>
              </div>
            </vk-grid>
          </div>
        </div>
      </vk-modal>
    </section>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';
import DropdownActions from '@/components/general/DropdownActions.vue';

import buildStep from '@/services/api/buildStep';
import project from '@/services/api/project';

import types from '@/services/data/types.json';

// Hack pour utiliser le drag event sans shadow image
const pixel = new Image();
pixel.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==';

export default {
  components: {
    Datepicker,
    DropdownActions,
  },
  name: 'build-step-gantt',
  props: ['value', 'startDate', 'endDate', 'dayWidth', 'disabled', 'companyCategories', 'companies', 'buildSteps', 'buildFilter'],
  data() {
    return {
      french: fr,
      boxWidth: 0,

      x: 0,
      x0: 0,
      clientX0: 0,
      duration0: 0,

      companyCategory: null,
      company: null,
      filteredCompanies: [],
      delayInWeeks: 1,

      showDropDown: false,
      showEditModal: false,
      showDelayModal: false,

      buildTypes: {
        START: 'Ouverture de chantier',
        CLOSE: 'Livraison de chantier',
        DEFAULT: 'Tâche classique',
        EVENT: 'Événement',
      },
      types,
      currentValue: null,
    };
  },
  watch: {
    x: 'updateDate',
    boxWidth: 'updateDate',
    value: 'init',
    currentValue: 'updateDropDown',
  },
  mounted() {
    this.init();
  },
  async created() {
    this.dropdownContent = [
      {
        title: 'Insérer une nouvelle tâche au-dessus',
        callback: () => {
          this.addData(this.value.rank);
        },
      },
      {
        title: 'Insérer une nouvelle tâche en-dessous',
        callback: () => {
          this.addData(this.value.rank + 1);
        },
      },
      {
        title: 'Éditer la tâche',
        callback: () => {
          this.showEditModal = true;
        },
      },
      {
        title: 'Supprimer la tâche',
        callback: () => {
          this.deleteData();
        },
      },
      {
        title: 'Décaler les tâches suivantes',
        callback: () => {
          this.showDelayModal = true;
        },
      },
    ];
    this.init();
  },
  methods: {
    init() {
      this.currentValue = this.value;

      this.currentValue.startedAt = `${this.$dayjs(this.currentValue.startedAt).format('YYYY-MM-DD')}T08:00:00.000Z`;
      this.currentValue.closedAt = `${this.$dayjs(this.currentValue.closedAt).format('YYYY-MM-DD')}T18:00:00.000Z`;

      this.x = (this.$dayjs(this.currentValue.startedAt).diff(this.$dayjs(this.startDate), 'day')) * this.dayWidth;
      this.duration0 = this.$dayjs(this.currentValue.closedAt).diff(this.$dayjs(this.currentValue.startedAt), 'day') + 1;
      this.boxWidth = this.duration0 * this.dayWidth;
      this.clientX0 = this.x;
      this.x0 = this.x;

      this.companyCategory = this.companyCategories.find((cat) => cat.companyCategoryId === this.currentValue.companyCategoryId);
      this.company = this.companies.find((cat) => cat.companyId === this.currentValue.companyId);

      this.setFilteredCompanies();
    },
    updateDate() {
      this.currentValue.startedAt = this.$dayjs(this.startDate).add((this.x / this.dayWidth), 'day').format('YYYY-MM-DD');
      this.currentValue.startedAt = `${this.currentValue.startedAt}T08:00:00.000Z`;
      this.currentValue.closedAt = this.$dayjs(this.startDate).add(((this.x + this.boxWidth) / this.dayWidth) - 1, 'day').format('YYYY-MM-DD');
      this.currentValue.closedAt = `${this.currentValue.closedAt}T18:00:00.000Z`;
    },
    updateDropDown() {
      if (this.currentValue.type === 'START' || this.currentValue.type === 'CLOSE') {
        this.dropdownContent = [
          {
            title: 'Insérer une nouvelle tâche au-dessus',
            callback: () => {
              this.addData(this.value.rank);
            },
          },
          {
            title: 'Insérer une nouvelle tâche en-dessous',
            callback: () => {
              this.addData(this.value.rank + 1);
            },
          },
          {
            title: 'Éditer la tâche',
            callback: () => {
              this.showEditModal = true;
            },
          },
          {
            title: 'Décaler les tâches suivantes',
            callback: () => {
              this.showDelayModal = true;
            },
          },
        ];
      }
    },
    setFilteredCompanies() {
      /* Filtre les companies à partir de la valeur de companyCategoryId */
      this.filteredCompanies = this.companies.filter((c) => c.companyCategories.find((cat) => cat === this.currentValue.companyCategoryId && c.isAgencyRegistered));

      if (this.currentValue.companyId) {
        const matchingCompany = this.filteredCompanies.find((company) => company.companyId === this.currentValue.companyId);
        this.currentValue.companyId = matchingCompany ? this.currentValue.companyId : null;
      }
    },

    getCompanyLabel() {
      if (!this.company) return null;
      const isRegistered = this.company.registeredAgency && this.company.registeredAgency.agencyId === this.buildFilter?.agencyId;
      return isRegistered ? `- ${this.company.name}` : `- X-${this.company.name}`;
    },

    // Move
    dragStartMove(e) {
      e.dataTransfer.setDragImage(pixel, 0, 0);
      this.clientX0 = e.clientX;
    },
    dragMove(e) {
      if (e.clientX > 0) {
        this.x = (this.x0 + e.clientX) - this.clientX0;
      }
    },
    dragEndMove() {
      this.x = Math.round(this.x / this.dayWidth) * this.dayWidth;
      this.x0 = this.x;
      this.duration0 = this.$dayjs(this.currentValue.closedAt).diff(this.$dayjs(this.currentValue.startedAt), 'day');
      this.saveData();
    },

    // Edit
    dragStartEdit(e) {
      e.dataTransfer.setDragImage(pixel, 0, 0);
      this.clientX0 = e.clientX;
      this.duration0 = this.$dayjs(this.currentValue.closedAt).diff(this.$dayjs(this.currentValue.startedAt), 'day') + 1;
    },
    dragEndEdit() {
      this.x = Math.round(this.x / this.dayWidth) * this.dayWidth;
      this.boxWidth = Math.round(this.boxWidth / this.dayWidth) * this.dayWidth;
      this.x0 = this.x;
      this.saveData();
    },

    // Edit start
    dragMoveEditStart(e) {
      if (e.clientX > 0) {
        this.x = Math.min((this.x0 + e.clientX) - this.clientX0, this.x0 - (this.dayWidth * (1 - this.duration0)));
        const step = (this.x0 - this.x) / this.dayWidth;
        this.boxWidth = (this.duration0 * this.dayWidth) + (step * this.dayWidth);
      }
    },

    // Edit end
    dragMoveEditEnd(e) {
      if (e.clientX > 0) {
        const deltaX = e.clientX - this.clientX0;
        const step = (this.x0 - this.x) / this.dayWidth;
        this.boxWidth = Math.max((this.duration0 * this.dayWidth) + (step * this.dayWidth) + deltaX, this.dayWidth);
      }
    },
    // Data
    async saveData() {
      if (this.currentValue.type === 'START') {
        this.currentValue.label = 'Ouverture de chantier';
      } else if (this.value.type === 'CLOSE') {
        this.currentValue.label = 'Livraison de chantier';
      } else if (this.value.type === 'EVENT') {
        this.currentValue.closedAt = this.currentValue.startedAt;
      }

      if (this.$dayjs(this.currentValue.startedAt).isBefore(this.startDate)) {
        this.currentValue.startedAt = this.startDate.toDate();
      }

      if (this.$dayjs(this.currentValue.closedAt).isAfter(this.endDate)) {
        this.currentValue.closedAt = this.endDate.toDate();
      }

      if (this.$dayjs(this.currentValue.startedAt).isAfter(this.endDate)) {
        this.currentValue.startedAt = this.endDate.toDate();
      }

      await buildStep.edit({
        buildStepId: this.currentValue.buildStepId,
        buildFilterId: this.currentValue.buildFilterId,
        companyCategoryId: this.currentValue.type === 'EVENT' ? null : this.currentValue.companyCategoryId,
        companyId: this.currentValue.type === 'EVENT' ? null : this.currentValue.companyId,
        label: this.currentValue.label,
        rank: this.currentValue.rank,
        type: this.currentValue.type,
        startedAt: this.currentValue.startedAt,
        closedAt: this.currentValue.closedAt,
      });

      this.$emit('update');

      this.showEditModal = false;
      this.showDropDown = false;
    },
    async addData(rank) {
      await buildStep.add({
        buildFilterId: this.currentValue.buildFilterId,
        companyCategoryId: null,
        companyId: null,
        label: 'Nouvelle tâche',
        rank,
        type: 'DEFAULT',
        startedAt: this.currentValue.closedAt,
        closedAt: this.$dayjs(this.currentValue.closedAt).add(3, 'days'),
      }, this.buildSteps);

      this.showDropDown = false;
      this.$emit('update');
    },
    async deleteData() {
      await buildStep.delete(this.currentValue.buildStepId);
      this.showDropDown = false;
      this.$emit('update');
    },
    async delayFollowongBuildSteps() {
      await buildStep.delayFollowingBuildSteps(this.currentValue, this.buildSteps, this.delayInWeeks);
      this.showDropDown = false;
      this.showDelayModal = false;
      this.$emit('update');
    },
    clickDropdown() {
      this.showDropDown = !this.showDropDown;
    },
    async openModal(event, type) {
      if (this.projectId) {
        this.project = await project.getById(this.projectId);
      }

      let selectedType = type;
      if (type === 'edit' && this.currentValue.type === 'START') {
        selectedType = 'start';
      }
      /* A ajouter avec la modal de fermeture */
      if (type === 'edit' && this.currentValue.type === 'CLOSE') {
        selectedType = 'close';
      }

      this.$emit('openModal', this.currentValue, selectedType, null, this.project);
    },
    addBefore() {
      this.add();
    },
    addAfter() {
      this.add(this.currentValue.rank + 1);
    },
    showCompany() {
      this.$router.push({ name: 'company-companyId-read', params: { companyId: this.currentValue.company.companyId } });
    },
    async add(rank) {
      /* Faire les mises à jour des tâches */
      await buildStep.add(
        {
          projectId: this.currentValue.project.projectId,
          label: 'Tâche vide',
          rank: !rank ? this.currentValue.rank : rank,
        },
        this.buildSteps,
      );
      this.showDropDown = false;
      this.$emit('update');
    },
    handleEvent(event) {
      if (event.target.value === 'EVENT') {
        this.company = null;
        this.companyCategory = null;
      } else {
        this.company = this.companies.find((cat) => cat.companyId === this.currentValue.companyId);
        this.companyCategory = this.companyCategories.find((cat) => cat.companyCategoryId === this.currentValue.companyCategoryId);
      }
    },
  },
};
</script>

<style lang="scss">
.build-step-gantt {
  position: relative;
  .dropdown-link {
     display: none;
  }
  .gantt {
    width: 100%;
    overflow-x: scroll;
  }

  .task {
    position: relative;
    display: block;
    margin: 10px 0;
    padding: 10px 0;
    height: 20px;
    background: #42495C;
    z-index: 0;
    color: white;
    text-align: center;
    font-weight: bold;

    &.TODO {
      background-color: $green!important;
    }
    &.LATE {
      background-color: $orange!important;
    }
    &.DONE {
      background-color: $gray-light;
    }
    &.disabled {
      background-color: $gray-regular;
    }
    .drag-zone {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: -50px;
      background: transparent;
    }

    &:hover {
      .edit-start, .edit-end {
        background: rgba(0,0,0,0.3);
      }
    }
    .dropdown-build-step .active {
      cursor: pointer;
    }
  }
    .label {
      position: absolute;
      left: 100%;
      top: -2px;
      margin-left: 15px;
      width: 300px;
      color: $gray-medium;
      font-weight: bold;
      text-align: left;
    }

    .company {
      position: absolute;
      left: 100%;
      top: 20px;
      margin-left: 15px;
      width: 300px;
      color: $blue-medium;
      font-weight: bold;
      font-size: 0.8rem;
      text-align: left;
      text-transform: uppercase;
      &.unconfirmed {
        color: $orange;
      }
    }

    .event {
      color: #edc900;
    }

    .edit-start, .edit-end {
      position: absolute;
      top: 0;
      height: 40px;
      width: 5px;
      background: transparent;
      cursor: col-resize;
      border-radius: 5px;
      transition: background ease-in-out 0.2s;
    }
    .edit-start {
      left: 0;
    }
    .edit-end {
      right: 0
    }

  .edit-move {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 5px;
    right: 5px;

    &:hover, &.active {
      cursor: move;
      .start, .end {
        opacity: 1;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }

  .gantt-actions {
    position: absolute;
    z-index: 1;
    background: $gray-very-light;
    z-index: 500;
    ul {
      padding: 0;
      li {
        & ~ li {
          border-top: 1px solid #aaa;
          cursor: pointer;
        }
      }
    }
  }
}

.modal-delay, .modal-edit {
  .uk-modal-title {
    margin-bottom: 0;
    background-color: $white;
    small {
      display: block;
    }
  }
  .modal-body {
    margin-top: 10px;
  }
  .button {
    margin: 20px;
    margin-bottom: 20px!important;
  }
  button.primary {
    color: #ffffff;
    border: none;
    border-radius: 30px;
    background-color: $blue-light;
  }
  .content {
    background: #f3f4fa;
    padding: 15px 2rem !important;
    &.no-bottom {
      margin-bottom: 0;
      padding-bottom: 0!important;
    }
    strong.title {
      padding-bottom: 20px;
      display: block;
    }
    .duration {
      border: 1px solid $gray-light;
      border-radius: 5px;
      padding: 10px;
      text-align: left;
      align-self: baseline;
      font-weight: 600;
    }
    &.date {
      .title {
        text-align: left;
        width: 100%;
      }
      .container {
        width: 100%;
        &.top-input {
          margin-bottom: 20px;
        }
      }
      .vdp-datepicker__calendar header span {
        padding: 0.8rem 1rem;
      }
    }
  }
}
</style>
