var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"opportunities hedifys-21"},[_vm._m(0),_c('div',{staticClass:"body"},[_c('p',{staticClass:"description"},[_vm._v("Liste des contacts créés il y a plus de 30 jours ayant fait des avant-projets dans les moins de 30 jours.")]),_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.hotCustomers.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToCustomerDetailsPage},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(_vm.nameFormat(data.firstname, data.lastname)))])]}},{key:"creationDate",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs(data.createdAt).format('DD/MM/YYYY')))])]}},{key:"status",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(_vm.statusFormat(data.status)))])]}},{key:"newProjects",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.newProjectDraft))])]}},{key:"lastProjectDraftCreatedAt",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(_vm.$dayjs(data.lastProjectDraftCreatedAt).format('DD/MM/YYYY')))])]}},{key:"totalProjects",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.totalProjectDraft))])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2),(_vm.hotCustomers.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.hotCustomers.metadata.offset,"count":_vm.hotCustomers.metadata.count}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Opportunités")])])])}]

export { render, staticRenderFns }