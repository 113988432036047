<template>
  <div class="land hedifys-21">
    <form ref="land" @submit.prevent="editLand">

      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="$route.params.landId && land && land.landId"
            :items="[
              { route: { name: 'lands'}, name: 'Terrains'},
              { route: { name: 'lands-landId', params: { landId: $route.params.landId }}, name: getPageTitle}
            ]"
          />
          <breadcrumb
            v-else
            :items="[
              { route: { name: 'lands'}, name: 'Terrains'},
            ]"
          />
        </div>
      </div>

      <!-- HEADER / TITRE -->
      <div class='header grid-x'>
        <div class='cell auto'>
          <h1>{{ getPageTitle }}</h1>
        </div>
        <div v-if="$route.params.landId" class="cell shrink button">
          <app-button
            theme="warning"
            size="large"
            @click="duplicateLand"
          >
            Dupliquer
          </app-button>
        </div>
        <div v-if="$route.params.landId" class="cell shrink button">
          <app-button
            theme="error"
            size="large"
            icon="remove"
            @click="openDeleteModal"
          >
            Supprimer
          </app-button>
        </div>
        <div class="cell shrink button">
          <app-button
            theme="primary"
            size="large"
            type="submit"
          >
            Sauvegarder
          </app-button>
        </div>
      </div>

      <!-- CONTENT -->
      <div class="body" v-if="!isLoading">
        <div class="grid-x grid-margin-x map-container">
          <!-- MAP -->
          <div class="cell medium-6">
            <land-map
              :lands="[land.coordinates]"
              :height="450"
            />
          </div>

          <!-- LOTISSEMENT -->
          <div class="cell medium-6">
            <div class="card card-allotment">
              <div class="grid-x grid-margin-x row">
                <div class="cell auto">
                  <app-radio-button
                    label="Type :"
                    inline
                    v-model="landType"
                    :value="$route.query.orderBy"
                    :options="[
                      { name: 'allotment', label: 'Lotissement' },
                      { name: 'notAllotment', label: 'Terrain diffus' }
                    ]"
                    @input="setLandType"
                    required
                  />
                </div>
              </div>

              <div class="grid-x grid-margin-x row" v-if="landType === 'allotment'">
                <div class="cell small-8">
                  <app-select
                    label="Lotissement"
                    :options="allotmentOptions"
                    v-model="land.allotment.allotmentId"
                    @input="selectAllotment"
                    :required="landType === 'allotment'"
                    :disabled="landType === 'notAllotment'"
                  />
                </div>
                <div class="cell small-4 align-self-bottom">
                  <app-input
                    label="Numéro de lot"
                    placeholder="Numéro"
                    v-model="land.lotNumber"
                    type="text"
                    :required="landType === 'allotment'"
                    :disabled="landType === 'notAllotment'"
                  />
                </div>
              </div>

              <div class="grid-x grid-margin-x row" v-else>
                <div class="cell small-12">
                  <app-select
                    label="Partenaire"
                    :options="companyOptions"
                    v-model="land.company.companyId"
                  />
                </div>
              </div>

              <div v-if="land.allotment.allotmentId && selectedAllotment && landType === 'allotment'" class="grid-x grid-margin-x row">
                <div class="cell small-12">
                  <app-card-link
                    :primary-route="{
                      route: {
                        name: 'lands-allotments-allotmentId',
                        params: { allotmentId: land.allotment.allotmentId }
                      },
                      label: selectedAllotment.name,
                    }"
                    :secondary-route="selectedAllotment.company && selectedAllotment.company.companyId ? {
                      route: {
                        name: 'companies-companyId',
                        params: { companyId: selectedAllotment.company.companyId }
                      },
                      label: selectedAllotment.company.name,
                    } : null"
                    :description="`${selectedAllotment.address.addressLine1 ? selectedAllotment.address.addressLine1 + ', ' : ''} ${selectedAllotment.address.postalCodeInseeNumber.split('-')[0]} ${selectedAllotment.address.city}`"
                  />
                </div>
              </div>

              <div class="ref-image-row">
                <div v-if="$route.params.landId">
                  <app-input
                    label="Référence du terrain"
                    placeholder="Référence"
                    v-model="land.reference"
                    disabled
                  />
                </div>
                <div class="image-input-section">
                  <p class="section-title">Image</p>
                  <app-upload-image :value="imageUrl" @upload="uploadImage" @remove="removeImage" style="min-width: 120px!important;min-height: 40px!important"/>
                  <app-label>Fichier .jpg, .png 5Mo max</app-label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ADRESSE -->
        <div class="grid-x">
          <div class="cell medium-12">
            <div class="card">
              <h3>Adresse</h3>
              <div class="grid-x grid-margin-x row">
                <div class="cell small-6">
                  <app-input
                    label="Adresse"
                    placeholder="Adresse ligne 1"
                    v-model="land.address.addressLine1"
                  />
                </div>
                <div class="cell small-6">
                  <app-search-city
                    label="Code postal"
                    placeholder="Nantes - 44000"
                    @select="setSelectedPostalCode"
                    @clear="resetSelectedPostalCode"
                    :selectedPostalCode="selectedPostalCode"
                    required
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell small-6">
                  <app-input
                    label="Adresse ligne 2 (complément)"
                    placeholder="Adresse ligne 2"
                    v-model="land.address.addressLine2"
                  />
                </div>
                <div class="cell small-6">
                  <app-input
                    label="Référence cadastrale"
                    placeholder="Référence cadastrale"
                    v-model="land.registerReference"
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell small-6">
                  <div class="grid-x grid-margin-x">
                    <div class="cell small-6">
                      <app-input
                        label="Latitude"
                        placeholder="Ex : 47.2382007"
                        v-model.number="land.coordinates.lat"
                        type="number"
                        :step="0.000001"
                        :min="-90"
                        :max="90"
                      />
                    </div>
                    <div class="cell small-6">
                      <app-input
                        label="Longitude"
                        placeholder="Ex : -1.6300958"
                        v-model.number="land.coordinates.long"
                        type="number"
                        :step="0.000001"
                        :min="-180"
                        :max="180"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-x grid-margin-x">
          <div class="cell medium-8">
            <!-- CARACTÉRISTIQUES -->
            <div class="card">
              <h3>Caractéristiques</h3>
              <div class="grid-x grid-margin-x row">
                <div class="cell small-6">
                  <app-input
                    label="Surface (m²)"
                    placeholder="Surface en mètre²"
                    v-model.number="land.surface"
                    type="number"
                    :step="0.01"
                  />
                </div>
                <div class="cell small-6">
                  <app-input
                    label="Largeur de façade (m)"
                    placeholder="Largeur en mètre"
                    v-model.number="land.width"
                    type="number"
                    :step="0.01"
                    required
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell small-6">
                  <app-input
                    label="Profondeur (m)"
                    placeholder="Profondeur en mètre"
                    v-model.number="land.depth"
                    type="number"
                    :step="0.01"
                  />
                </div>
                <div class="cell small-6">
                  <app-input
                    label="Distance de mitoyenneté (m)"
                    placeholder="Distance en mètre"
                    v-model.number="land.separationDistance"
                    type="number"
                    :step="0.01"
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell small-6 align-self-bottom">
                  <app-input
                    label="Prix"
                    placeholder="Prix"
                    v-model.number="land.price"
                    type="number"
                    :step="0.01"
                    required
                  />
                </div>
                <div class="cell small-6">
                  <app-select
                    label="Orientation"
                    placeholder="Orientation"
                    :options="orientation"
                    v-model="land.orientation"
                    required
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell small-6 align-self-bottom">
                  <app-input
                    label="Frais d'adaptation au terrain"
                    placeholder="Prix"
                    v-model.number="land.priceLandAdaptation"
                    type="number"
                    :step="0.01"
                    required
                  />
                </div>
                <div class="cell small-6">
                  <app-input
                    label="Frais règlementation locale"
                    placeholder="Prix"
                    v-model.number="land.priceLocalRegulation"
                    type="number"
                    :step="0.01"
                    required
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto">
                  <app-textarea
                    label="Notes"
                    placeholder="Vos notes..."
                    v-model="land.description"
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto">
                  <app-textarea
                    label="Descriptif commercial"
                    placeholder="Description utilisée dans le template des petites annonces."
                    v-model="land.commercialDescription"
                  />
                </div>
              </div>
            </div>

            <!-- STYLES -->
            <div class="card card-with-description">
              <h3>Styles</h3>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto checkbox-container">
                  <span class="description">
                    Les styles suivants s’appliquent sur ce lotissement, vous pouvez cocher / décocher pour activer ou désactiver les styles sur ce terrain.
                  </span>

                  <div class="checkbox-container checkbox-grid-style">
                    <div v-for="style in styles" :key="style.styleId">
                      <app-checkbox
                        :value="style.styleId"
                        v-model="land.excludedStyles"
                        reverse
                      >
                        {{ style.name }}
                      </app-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- RÈGLES -->
            <div class="card card-with-description">
              <h3>Règles</h3>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto checkbox-container">
                  <span class="description">
                    Les règles suivantes s’appliquent sur ce lotissement, vous pouvez cocher / décocher pour activer ou désactiver les règles sur ce terrain.
                  </span>

                  <div class="checkbox-container checkbox-grid-regulation">
                    <div v-for="regulation in regulations" :key="regulation.regulationId">
                      <app-checkbox
                        :value="regulation.regulationId"
                        v-model="land.excludedRegulations"
                        reverse
                      >
                        {{ regulation.name }}
                      </app-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- OPTIONS -->
          <div class="cell medium-4">
            <div class="card">
              <div class="grid-x grid-margin-x row">
                <div class="cell auto radio-container">
                  <h4>Assainissement</h4>
                  <div>
                    <app-radio
                      v-model="land.isMainDrainagePlanned"
                      :value="true"
                    >
                      Tout à l’égout
                    </app-radio>
                  </div>
                  <div>
                    <app-radio
                      v-model="land.isMainDrainagePlanned"
                      :value="false"
                    >
                      Assainissement autonome
                    </app-radio>
                  </div>
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto radio-container">
                  <h4>Viabilisé</h4>
                  <div>
                    <app-radio
                      v-model="land.isServiced"
                      :value="true"
                    >
                      Oui
                    </app-radio>
                  </div>
                  <div>
                    <app-radio
                      v-model="land.isServiced"
                      :value="false"
                    >
                      Non
                    </app-radio>
                  </div>
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto radio-container">
                  <h4>Zone argileuse</h4>
                  <div>
                    <app-radio
                      v-model="land.isClayZone"
                      :value="true"
                    >
                      Oui
                    </app-radio>
                  </div>
                  <div>
                    <app-radio
                      v-model="land.isClayZone"
                      :value="false"
                    >
                      Non
                    </app-radio>
                  </div>
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto radio-container">
                  <h4>Diffusion (publication en annonce)</h4>
                  <div>
                    <app-radio
                      v-model="land.isAvailableForAds"
                      :value="true"
                    >
                      Oui
                    </app-radio>
                  </div>
                  <div>
                    <app-radio
                      v-model="land.isAvailableForAds"
                      :value="false"
                    >
                      Non
                    </app-radio>
                  </div>
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto radio-container">
                  <h4>Statut du terrain</h4>
                  <div>
                    <app-radio
                      v-model="land.status"
                      value="ACTIVE"
                    >
                      {{ status.ACTIVE }}
                    </app-radio>
                  </div>
                  <div>
                    <app-radio
                      v-model="land.status"
                      value="SOLD"
                    >
                      {{ status.SOLD }}
                    </app-radio>
                  </div>
                  <div>
                    <app-radio
                      v-model="land.status"
                      value="UNAVAILABLE"
                    >
                      {{ status.UNAVAILABLE }}
                    </app-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- LOADER -->
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LandMap from '@/components/general/LandMap.vue';
import AppSearchCity from '@/basics/components/AppSearchCity.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

import landApi from '@/services/api/land';
import allotmentApi from '@/services/api/allotment';
import styleApi from '@/services/api/style';
import regulationApi from '@/services/api/regulation';
import postalCodeApi from '@/services/api/postalCode';
import companyApi from '@/services/api/company';
import memberApi from '@/services/api/member';

import orientation from '@/services/data/orientation.json';
import utils from '@/services/utils/utils';
import status from '@/services/enums/status.enum';
import auth from '@/services/auth';

export default {
  name: 'land',

  components: {
    AppSearchCity,
    Breadcrumb,
    LandMap,
  },

  metaInfo() {
    return {
      title: this.land ? this.getPageTitle : 'Terrain',
    };
  },

  data() {
    return {
      isLoading: true,

      // Terrain
      land: {
        address: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          postalCodeInseeNumber: null,
        },
        allotment: {
          allotmentId: null,
          name: null,
        },
        company: {
          companyId: null,
          name: null,
        },
        coordinates: {
          lat: null,
          long: null,
        },
        allotmentId: null,
        companyId: null,
        depth: null,
        description: null,
        commercialDescription: null,
        excludedRegulations: [],
        excludedStyles: [],
        isAvailableForAds: false,
        isClayZone: false,
        isMainDrainagePlanned: true,
        isServiced: true,
        lotNumber: null,
        orientation: null,
        price: null,
        priceLandAdaptation: null,
        priceLocalRegulation: null,
        reference: null,
        registerReference: null,
        separationDistance: null,
        status: 'UNAVAILABLE',
        surface: null,
        width: null,
      },
      landType: 'allotment',
      autocomplete: false,

      // Lotissement
      allotments: [],
      allotmentOptions: [],
      companies: [],
      companyOptions: [],
      selectedAllotment: {
        name: null,
        agency: {
          agencyId: null,
          name: null,
        },
        priceLandAdaptation: null,
        priceLocalRegulation: null,
      },

      // Code postal
      selectedPostalCode: null,

      // Styles
      styles: [],

      // Règles
      regulations: [],

      // Enums
      orientation,
      status,
      brand: null,

      imageUrl: null,
      imageFile: null,
      oldImage: null,
    };
  },

  mounted() {
    this.getMe();
    this.getData();
  },

  computed: {
    getPageTitle() {
      if (this.$route.params.landId) {
        // Si le terrain fait partie d'un lotissement
        if (this.landType === 'allotment') {
          return `Terrain lotissement
            ${this.land.lotNumber ? `- Lot ${this.land.lotNumber}` : ''}
            ${this.land.surface ? `- Surface ${this.land.surface}` : ''}
            ${this.land.address.city ? `- ${this.land.address.city}` : ''}
          `;
        }

        // Si le terrain ne fait pas partie d'un lotissement
        return `Terrain diffus
          ${this.land.surface ? `- Surface ${this.land.surface}` : ''}
          ${this.land.address.city ? `- ${this.land.address.city}` : ''}
        `;
      }
      return 'Nouveau terrain';
    },
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getData();
      }
    },
    async selectedPostalCode() {
      await this.getStyles();
      await this.getRegulations();
    },
    'selectedAllotment.allotmentId': {
      handler() {
        if (this.autocomplete || !this.$route.params.landId) {
          this.land.address = this.selectedAllotment.address;
          this.selectedPostalCode = `${this.selectedAllotment.address.city} - ${this.selectedAllotment.address.postalCodeInseeNumber.split('-')[0]}`;
          this.land.priceLandAdaptation = this.selectedAllotment.priceLandAdaptation;
          this.land.priceLocalRegulation = this.selectedAllotment.priceLocalRegulation;
        } else {
          this.autocomplete = true;
          this.land.priceLandAdaptation = this.land.priceLandAdaptation || this.selectedAllotment.priceLandAdaptation;
          this.land.priceLocalRegulation = this.land.priceLocalRegulation || this.selectedAllotment.priceLocalRegulation;
        }
      },
    },
  },
  methods: {
    async getMe() {
      try {
        this.member = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getData() {
      this.isLoading = true;
      await this.getBrand();

      // On récupère les données du terrain si il s'agit d'un terrain déjà existant
      if (this.$route.params.landId) {
        await this.getLand(this.$route.params.landId);
      }
      await this.getAllotments();
      await this.getStyles();
      await this.getRegulations();
      await this.getCompanies();

      // On sélectionne automatiquement le lotissement si il s'agit d'un terrain déjà existant
      if (this.$route.params.landId && this.land.allotment.allotmentId) {
        this.selectAllotment(this.land.allotment.allotmentId);
      }
      this.isLoading = false;
    },

    async getCompanies() {
      try {
        const type = 'DEVELOPER';
        const companies = await companyApi.getAll(type);
        if (this.member.isBrandAdmin) {
          this.companies = companies.data;
        }
        if (this.member.isAgencyAdmin) {
          this.companies = companies.data.filter((c) => c.isAgencyRegistered);
        }
        this.companyOptions = utils.formatOptions(
          this.companies,
          (option) => option.companyId,
          (option) => option.name,
          'choose',
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lotisseurs',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Demande de confirmation pour la suppression d'un terrain
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer le terrain',
        text: 'Êtes vous sûr de vouloir supprimer ce terrain ?',
        confirmText: 'Supprimer le terrain',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteLand();
        },
      });
    },

    // Sauvegarde du terrain
    async editLand() {
      // On reformat l'objet du terrain à envoyer pour qu'il convienne aux routes d'édition
      const editedLand = JSON.parse(JSON.stringify(this.land));
      editedLand.postalCodeInseeNumber = this.land.address.postalCodeInseeNumber;
      delete editedLand.address.postalCodeInseeNumber;
      delete editedLand.company;
      delete editedLand.allotment;

      if (this.landType === 'notAllotment') {
        editedLand.companyId = this.land.company.companyId;
        editedLand.allotmentId = null;
      } else {
        editedLand.companyId = null;
      }

      if (this.$route.params.landId) {
        try {
          await landApi.update(this.$route.params.landId, {
            ...editedLand,
            price: utils.formatEuroToCent(editedLand.price),
            priceLandAdaptation: utils.formatEuroToCent(editedLand.priceLandAdaptation),
            priceLocalRegulation: utils.formatEuroToCent(editedLand.priceLocalRegulation),
          });

          if (this.oldImage !== this.imageUrl) {
            try {
              const file = new FormData();
              file.append('file', this.imageFile);
              await landApi.uploadImage(file, this.$route.params.landId);
            } catch (er) {
              this.$message.show({
                title: 'Erreur',
                text: 'Il y a eu un problème lors de l\'upload de l\'image',
                cancelText: 'Ok',
                hasCancel: true,
              });
            }
          }

          this.$notification.show({ text: 'Ce terrain a été mis à jour avec succès !' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la mise à jour du terrain',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      } else {
        try {
          this.land = await landApi.create({
            ...editedLand,
            price: utils.formatEuroToCent(editedLand.price),
            priceLandAdaptation: utils.formatEuroToCent(editedLand.priceLandAdaptation),
            priceLocalRegulation: utils.formatEuroToCent(editedLand.priceLocalRegulation),
          });

          if (this.imageFile) {
            try {
              const file = new FormData();
              file.append('file', this.imageFile);
              await landApi.uploadImage(file, this.land.landId);
            } catch (er) {
              this.$message.show({
                title: 'Erreur',
                text: 'Il y a eu un problème lors de l\'upload de l\'image',
                cancelText: 'Ok',
                hasCancel: true,
              });
            }
          }

          this.$notification.show({ text: 'Ce terrain a été crée avec succès !' });
          this.$router.push({
            name: 'lands-landId', params: { landId: this.land.landId },
          });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la création du terrain',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },

    async duplicateLand() {
      try {
        // On reformat l'objet du terrain à envoyer pour qu'il convienne aux routes d'édition
        const editedLand = JSON.parse(JSON.stringify(this.land));
        editedLand.postalCodeInseeNumber = this.land.address.postalCodeInseeNumber;
        delete editedLand.address.postalCodeInseeNumber;
        delete editedLand.company;
        delete editedLand.allotment;
        delete editedLand.landId;
        delete editedLand.reference;
        delete editedLand.createdAt;
        delete editedLand.updatedAt;

        if (this.landType === 'notAllotment') {
          editedLand.allotmentId = null;
        }

        const duplicatedLand = await landApi.create({
          ...editedLand,
          price: utils.formatEuroToCent(editedLand.price),
          priceLandAdaptation: utils.formatEuroToCent(editedLand.priceLandAdaptation),
          priceLocalRegulation: utils.formatEuroToCent(editedLand.priceLocalRegulation),
        });

        if (this.imageFile) {
          try {
            const file = new FormData();
            file.append('file', this.imageFile);
            await landApi.uploadImage(file, this.duplicatedLand.landId);
          } catch (er) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de l\'upload de l\'image',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
        }

        this.$notification.show({ text: 'Ce terrain a été dupliqué avec succès !' });
        this.$router.push({
          name: 'lands-landId', params: { landId: duplicatedLand.landId },
        });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la duplication du terrain',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Suppression du terrain
    async deleteLand() {
      if (this.$route.params.landId) {
        try {
          await landApi.delete(this.$route.params.landId);

          this.$notification.show({ text: 'Ce terrain a été supprimé avec succès !' });
          this.$router.push({ name: 'lands' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression du terrain',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    // Récupération de la marque
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Récupération du terrain
    async getLand(landId) {
      try {
        const land = await landApi.getOne(landId);
        if (land) {
          land.price = utils.formatCentToEuro(land.price);
          land.priceLandAdaptation = utils.formatCentToEuro(land.priceLandAdaptation);
          land.priceLocalRegulation = utils.formatCentToEuro(land.priceLocalRegulation);
          land.separationDistance = land.separationDistance || land.separationDistance === 0
            ? land.separationDistance : this.brand.defaultSeparationDistance;

          this.imageUrl = land.landImage;
          this.oldImage = land.landImage;
        }

        this.land = land;

        // On set le type de terrain sur terrain diffus si aucun id pour le lotissement est trouvé
        if (!this.land.allotment.allotmentId) {
          this.landType = 'notAllotment';
        }

        // On transforme les valeurs de excludedStyles et excludedRegulations en tableau si ils ne le sont pas
        if (!this.land.excludedStyles) this.land.excludedStyles = [];
        if (!this.land.excludedRegulations) this.land.excludedRegulations = [];

        // On récupère le code postal
        this.getPostalCode(this.land.address.postalCodeInseeNumber);

        // Formatage du type pour certaines valeurs
        const valueToFormat = ['depth', 'width', 'surface', 'separationDistance'];

        valueToFormat.forEach((element) => {
          if (typeof this.land[element] === 'string') {
            this.land[element] = parseFloat(this.land[element], 10);
          }
        });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du terrain',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des lotissements
    async getAllotments() {
      try {
        const allotments = await allotmentApi.getAll(null, null, null, null, null, true);
        this.allotments = allotments.data;

        // On format les options pour la liste des lotissements
        this.allotmentOptions = utils.formatOptions(
          this.allotments,
          (option) => option.allotmentId,
          (option) => option.name,
        );
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lotissements',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des styles
    async getStyles() {
      try {
        if (this.land.address.postalCodeInseeNumber) {
          this.styles = await styleApi.getAll(this.land.address.postalCodeInseeNumber);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des styles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des règles
    async getRegulations() {
      try {
        if (this.land.address.postalCodeInseeNumber) {
          this.regulations = await regulationApi.getAll(this.land.address.postalCodeInseeNumber);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des règles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération du code postal sélectionné
    async getPostalCode(postalCodeInseeNumber) {
      try {
        const postalCode = await postalCodeApi.getOne(postalCodeInseeNumber);
        this.selectedPostalCode = `${postalCode.city} - ${postalCode.postalCodeInseeNumber.split('-')[0]}`;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du code postal',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération du lotissement sélectionné
    selectAllotment(value) {
      const allotment = this.allotments.find((allot) => allot.allotmentId === value);
      this.land.allotment.name = allotment.name;
      this.selectedAllotment = allotment;
      this.selectedAllotment.priceLandAdaptation = utils.formatCentToEuro(this.selectedAllotment.priceLandAdaptation);
      this.selectedAllotment.priceLocalRegulation = utils.formatCentToEuro(this.selectedAllotment.priceLocalRegulation);
      this.land.allotmentId = allotment.allotmentId;
    },

    // Récupère le code postal sélectionné
    setSelectedPostalCode(value) {
      this.selectedPostalCode = value.label;
      this.land.address.postalCodeInseeNumber = value.name;
    },

    // Reset les valeurs du code postal sélectionné si le champ de recherche est vide
    resetSelectedPostalCode() {
      this.selectedPostalCode = null;
      this.land.address.postalCodeInseeNumber = null;
    },

    // On récupère la valeur du type du terrain
    setLandType(value) {
      if (this.land.allotment.allotmentId && value === 'notAllotment') {
        this.land.allotment.allotmentId = null;
        this.land.lotNumber = null;
      }
    },
    uploadImage(event) {
      this.imageFile = event;
      this.imageUrl = URL.createObjectURL(event);
    },
    removeImage() {
      this.imageFile = null;
      this.imageUrl = null;
    },
  },
};
</script>

<style lang='sass' scoped>
.land
  .body
    @include screen
    .ref-image-row
      display: flex
      flex-flow: row nowrap
      justify-content: flex-start
      gap: 2rem
      padding: 0 15px 0 15px
      .image-input-section
        .section-title
          font-size: 10px
          font-weight: 500
          color: $info
          margin-bottom: 3px
          line-height: 15px
        .app-upload-image
          margin-bottom: 6px
          max-width: 140px
          max-height: 80px
        .app-label
          color: $info

  .breadcrumbs
    @include breadcrumbs

  .header
    @include header
    h1
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

  .button
    margin-left: 1rem

  .row
    @include row

  .card
    @include card

  .card-allotment
    height: calc(100% - 40px - 16px)

  .map-container
    margin-bottom: 24px
    > div
      height: 450px

  h3
    color: $primary
    margin: 0 16px 32px 16px

  .card-with-description
    h3
      margin: 0 16px 5px 16px

  .radio-container
    h4
      color: $secondary-light
    > div
      margin-top: 5px

  .checkbox-container
    > div
      margin-top: 10px

  .checkbox-grid-style
    display: grid
    grid-template-columns: repeat(3, 1fr)

  .checkbox-grid-regulation
    display: grid
    grid-template-columns: repeat(2, 1fr)

  .description
    @include details
    color: $label
</style>
