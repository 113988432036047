var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-progress hedifys-21"},[_vm._m(0),_c('tab-nav',{attrs:{"tabs":_vm.tabs}}),_c('div',{staticClass:"body"},[_c('div',{staticClass:"search grid-x"},[_c('div',{staticClass:"cell small-3"},[_c('app-search',{attrs:{"placeholder":"Recherche par référence, client","size":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"cell shrink button"},[_c('filter-modal',{attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return !!i; }).length},on:{"change":_vm.filterProjects,"reset":_vm.resetFilers,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-radio-button',{attrs:{"label":"Trier par :","input":_vm.filters.order,"options":[
                  { name: 'startedStepDate', label: 'Date démarrage' },
                  { name: 'alphabetic', label: 'Nom client' },
                  { name: 'reference', label: 'N° dossier'},
                  { name: 'delay', label: 'Retard'} ]},model:{value:(_vm.filters.order),callback:function ($$v) {_vm.$set(_vm.filters, "order", $$v)},expression:"filters.order"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-select',{attrs:{"label":"Agence","options":_vm.agencyOptions,"disabled":_vm.agencyOptions.length === 0},model:{value:(_vm.filters.agencyId),callback:function ($$v) {_vm.$set(_vm.filters, "agencyId", $$v)},expression:"filters.agencyId"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-select',{attrs:{"label":"Conducteur","options":_vm.conductorOptions,"disabled":_vm.conductorOptions.length === 0},model:{value:(_vm.filters.conductorId),callback:function ($$v) {_vm.$set(_vm.filters, "conductorId", $$v)},expression:"filters.conductorId"}})],1)])])],1),_c('div',{staticClass:"cell auto"})]),_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.projects.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToProject},scopedSlots:_vm._u([{key:"customerFile",fn:function(ref){
                var data = ref.data;
return [_c('p',[_c('strong',[_vm._v(_vm._s(data.reference))]),_vm._v(" "+_vm._s(data.customer.lastname)+" "),_c('br'),_c('small',[_vm._v(_vm._s(data.agency.name)+" - "+_vm._s(data.member.firstname)+" "+_vm._s(data.member.lastname))])])]}},{key:"buildingSite",fn:function(ref){
                var data = ref.data;
return [_c('p',[_c('strong',[_vm._v(_vm._s(data.address.postalCode)+" "+_vm._s(data.address.city))]),_vm._v(" "),_c('br'),_c('small',[_vm._v(_vm._s(data.buildFilterLabel))])])]}},{key:"contractSignatureDate",fn:function(ref){
                var data = ref.data;
return [(data.contractSignatureDate)?_c('p',{staticClass:"open-close-date-table"},[_c('strong',[_vm._v("S"+_vm._s(_vm.$dayjs(data.contractSignatureDate).isoWeek()))]),_c('small',[_vm._v(_vm._s(_vm.$dayjs(data.contractSignatureDate).format('DD/MM/YYYY')))])]):_vm._e()]}},{key:"duration",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.duration)+" J")]),_c('progress',{staticClass:"progress-bar",attrs:{"max":"100"},domProps:{"value":_vm.getTimeSpent(data.startedStepDate) / data.duration * 100}})]}},{key:"objective",fn:function(ref){
                var data = ref.data;
return [_c('p',{staticClass:"objective"},[_c('span',[_vm._v(_vm._s(data.initialDuration)+" J")])])]}},{key:"delay",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.delay)+" J")])]}},{key:"ending",fn:function(ref){
                var data = ref.data;
return [_c('p',{staticClass:"open-close-date-table"},[_c('strong',[_vm._v("S"+_vm._s(_vm.$dayjs(data.startedStepDate).isoWeek()))]),_c('small',[_vm._v(_vm._s(_vm.$dayjs(data.closedStepDate).format('DD/MM/YYYY')))])])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2),(_vm.projects.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.projects.metadata.offset,"count":_vm.projects.metadata.count}}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Liste des chantiers")])])])}]

export { render, staticRenderFns }