import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère toutes les surfaces
 * @param {string} versionId: version à rechercher
 * @param {string} floorId: niveau de la version à rechercher
 */
const getByVersion = async (versionId, floorId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions/${versionId}/surfaces`, {
      params: {
        ...(floorId && { floorId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mettre à jour les surfaces d'une version
 * @param {string} versionId: version à mettre à jour
 * @param {object} surfaces: données des surfaces de la version
 */
const updateByVersion = async (versionId, surfaces) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/versions/${versionId}/surfaces`, surfaces);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mettre à jour les extensions d'une version
 * @param {string} versionId: version à mettre à jour
 * @param {object} extensions: données des extensions de la version
 */
const updateExtensionsByVersion = async (versionId, extensions) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/versions/${versionId}/extensions`, extensions);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const surface = {};
surface.getByVersion = getByVersion;
surface.updateByVersion = updateByVersion;
surface.updateExtensionsByVersion = updateExtensionsByVersion;

export default surface;
