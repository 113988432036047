<template>
  <div class="member-update-password hedifys-21">
    <form ref="memberUpdatePassword" @submit.prevent="save">
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Votre compte</h1>
        </div>
        <div class="cell shrink">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <h3>Mot de passe</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input type="password" v-model="oldPassword" placeholder="Mot de passe actuel..." label="Mot de passe actuel" required />
            </div>
            <div class="cell auto"></div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input type="password" v-model="newPassword" placeholder="Nouveau mot de passe..." label="Nouveau mot de passe" required />
            </div>
            <div class="cell auto">
              <app-input type="password" v-model="confirmationPassword" :hasSucceed="confirmationSucceed" :msg="confirmationMsg" placeholder="Confirmation..." label="Confirmation du nouveau mot de passe" required />
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import memberApi from '@/services/api/member';

export default {
  name: 'member-account',
  components: {
    TabNav,
  },
  metaInfo: {
    title: 'Votre compte',
  },
  data() {
    return {
      isLoading: false,
      member: {},
      tabs: [
        {
          route: {
            name: 'account',
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'account-password',
          },
          label: 'Mot de passe',
        },
      ],
      jobOptions: [],
      oldPassword: null,
      newPassword: null,
      confirmationPassword: null,
      confirmationSucceed: null,
      confirmationMsg: null,
      avatar: null,
      avatarUrl: null,
    };
  },
  watch: {
    newPassword() {
      this.newPasswordMatch();
    },
    confirmationPassword() {
      this.newPasswordMatch();
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getMember();
    this.isLoading = false;
  },
  methods: {
    newPasswordMatch() {
      if (this.confirmationPassword && this.confirmationPassword.length && this.newPassword && this.newPassword.length) {
        if (this.confirmationPassword !== this.newPassword) {
          this.confirmationSucceed = 'error';
          this.confirmationMsg = 'Les mots de passe ne correspondent pas !';
        } else {
          this.confirmationMsg = 'Les mots de passe correspondent !';
          this.confirmationSucceed = 'success';
        }
      } else {
        this.confirmationMsg = null;
        this.confirmationSucceed = null;
      }
    },
    async getMember() {
      try {
        this.member = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du compte',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.confirmationSucceed === 'success') {
          await memberApi.updatePasswordProfil(this.member.memberId, this.oldPassword, this.newPassword);
          this.$notification.show({ text: 'Votre mot de passe a bien été mis à jour !' });
        } else {
          throw new Error();
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde des modifications du compte. Vérifiez que le nouveau mot de passe et la confirmation du mot de passe correspondent.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.member-update-password
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
  .input
    margin-right: 16px
  .avatar
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 16px
    > div
      margin-bottom: 16px
</style>
