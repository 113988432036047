import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère une sous rubrique via son id
 */
const getOneSubCategoryById = async (subCategoryId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/document-sub-categories/${subCategoryId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère toutes les sous-rubriques
 */
const getAllSubCategories = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/document-sub-categories`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère toutes les sous-rubriques d'une rubrique
 */
const getSubCategoriesByCategory = async (categoryId, limit, offset) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/document-category/${categoryId}/document-sub-categories`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};
/**
 * Crée une sous-rubrique
 */
const createSubCategory = async (subCategory) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/document-sub-categories`, subCategory);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Modifie une sous-rubrique
 */
const updateSubCategory = async (subCategoryId, body) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/document-sub-categories/${subCategoryId}`, body);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Supprime une sous-rubrique
 */
const deleteSubCategory = async (subCategoryId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/document-sub-categories/${subCategoryId}`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

const documentSubCategories = {};
documentSubCategories.createSubCategory = createSubCategory;
documentSubCategories.getAllSubCategories = getAllSubCategories;
documentSubCategories.getSubCategoriesByCategory = getSubCategoriesByCategory;
documentSubCategories.updateSubCategory = updateSubCategory;
documentSubCategories.deleteSubCategory = deleteSubCategory;
documentSubCategories.getOneSubCategoryById = getOneSubCategoryById;
export default documentSubCategories;
