/* eslint-disable */

export default {
    inserted(el, binding) {
      const options = {
        root: null, // Utilise le viewport du navigateur comme zone de référence
        rootMargin: '0px',
        threshold: 0.1, // L'élément doit être au moins 10% visible pour déclencher l'intersection
      };

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            binding.value(entry); // Appelle la fonction passée en valeur de la directive
          }
        });
      };

      // Déclare observer à un niveau plus haut pour l'utiliser dans inserted et unbind
      el._observer = new IntersectionObserver(callback, options);

      el._observer.observe(el);
    },
    unbind(el) {
      if (el._observer) {
        el._observer.unobserve(el);
        el._observer.disconnect(); // Nettoyage de l'observer
        delete el._observer; // Supprimer la référence
      }
    },
  };
