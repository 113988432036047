<template>
  <div class="brand-parameters hedifys-21">
    <form ref="brand" @submit.prevent="save">
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Paramètre de marque</h1>
        </div>
        <div class="cell shrink">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div>
          <div class="section">
          <p class="section-title grid-x row grid-margin-x">Informations générales</p>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input unit="m" :step="0.01" v-model.number="brand.defaultSeparationDistance" type="number" placeholder="Distance mitoyenne par défault..." label="Distance mitoyenne par défault" />
            </div>
            <div class="cell auto">
              <app-input unit="€/m2" v-model.number="brand.habitableExtensionPrice" type="number" placeholder="Prix d’agrandissement habitable..." label="Prix d’agrandissement habitable" />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input unit="€/m2" v-model.number="brand.nonHabitableExtensionPrice" type="number" placeholder="Prix d’agrandissement non habitable..." label="Prix d’agrandissement non habitable" />
            </div>
            <div class="cell auto">
              <app-input unit="€/m2" v-model.number="brand.carportExtensionPrice" type="number" placeholder="Prix d’agrandissement carport..." label="Prix d’agrandissement carport" />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input unit="€" v-model.number="brand.margin" type="number" placeholder="Marge marque par défaut sur les versions..." label="Marge marque par défaut sur les versions" />
            </div>
            <div class="cell auto"></div>
          </div>
        </div>
        </div>
        <div class="section">
          <p class="section-title">Zone édito</p>
          <div class="edito">
            <div class="upload">
              <app-upload-image :value="editoImgUrl" @upload="uploadImg" @remove="removeImg" style="max-width: 100px;"/>
            </div>
            <div class="content">
              <app-input v-model="brand.announcementTitle" type="text" placeholder="Titre" label="Titre de l'édito" />
              <app-textarea v-model="brand.announcementDescription" type="text" placeholder="Descripton" label="Contenu de l'édito" class="textarea"/>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import auth from '@/services/auth';
import brandApi from '@/services/api/brand';
import utils from '@/services/utils/utils';

export default {
  name: 'brand',
  components: {
    TabNav,
  },
  metaInfo: {
    title: 'Votre marque',
  },
  data() {
    return {
      isLoading: null,
      brand: {
        defaultSeparationDistance: null,
        habitableExtensionPrice: null,
        nonHabitableExtensionPrice: null,
        carportExtensionPrice: null,
        margin: null,
        announcementTitle: null,
        announcementDescription: null,
        announcementImage: null,
      },
      tabs: [
        {
          route: {
            name: 'brand',
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'brand-members',
          },
          label: 'Membres',
        },
        {
          route: {
            name: 'brand-parameters',
          },
          label: 'Paramètres de marque',
        },
      ],
      editoImgUrl: null,
      editoImg: null,
      deleteImg: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getBrand();
    this.editoImgUrl = this.brand.announcementImage;
    this.isLoading = false;
  },
  methods: {
    async getBrand() {
      try {
        const brand = await auth.getBrand();

        if (brand) {
          brand.habitableExtensionPrice = utils.formatCentToEuro(brand.habitableExtensionPrice);
          brand.nonHabitableExtensionPrice = utils.formatCentToEuro(brand.nonHabitableExtensionPrice);
          brand.carportExtensionPrice = utils.formatCentToEuro(brand.carportExtensionPrice);
          brand.margin = utils.formatCentToEuro(brand.margin);
        }
        this.brand = brand;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    uploadImg(event) {
      const file = new FormData();
      file.append('file', event);
      this.editoImg = file;
      this.editoImgUrl = URL.createObjectURL(event);
      this.deleteImg = false;
    },
    removeImg() {
      this.editoImgUrl = null;
      this.editoImg = null;
      this.deleteImg = true;
    },
    async save() {
      this.isLoading = true;
      try {
        await brandApi.update(this.brand.brandId, {
          name: this.brand.name,
          website: this.brand.website,
          address: this.brand.address,
          phone: this.brand.phone,
          defaultSeparationDistance: this.brand.defaultSeparationDistance,
          habitableExtensionPrice: utils.formatEuroToCent(this.brand.habitableExtensionPrice),
          nonHabitableExtensionPrice: utils.formatEuroToCent(this.brand.nonHabitableExtensionPrice),
          carportExtensionPrice: utils.formatEuroToCent(this.brand.carportExtensionPrice),
          margin: utils.formatEuroToCent(this.brand.margin),
          announcementTitle: this.brand.announcementTitle,
          announcementDescription: this.brand.announcementDescription,
        });
        if (this.deleteImg || this.editoImg) await brandApi.uploadEdito(this.brand.brandId, this.editoImg);
        this.$notification.show({ text: 'La marque a été mise à jour avec succès !' });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde des modifications de la marque',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.brand-parameters
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
  .section
    box-shadow: $drop-med
    background-color: $off-white
    padding: 40px 8px 40px 8px
    margin-bottom: 24px
    border-radius: $global-border-radius
    display: flex
    flex-direction: column
    .section-title
      font-size: 16px
      font-weight: 700
      color: #368DF7
      margin-left: 15px
    .edito
      width: 100%
      height: fit-content
      display: flex
      flex-direction: row
      align-items: center
      gap: 30pxs
      .upload
        margin: 10px
      .content
        display: flex
        flex-direction: column
        gap: 10px
        width: 100%
  .input
    margin-right: 16px
  .avatar
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 16px
    > div
      margin-bottom: 16px
</style>
