<template>
  <div class="app-error">
    <div class="app-error-content">
      <div class="title">
        <div>
          <error />
        </div>
        <h4>{{ title }}</h4>
      </div>
      <ul>
        <li v-for="msg in messages" :key="msg">
          <p>{{ msg }}</p>
        </li>
      </ul>
    </div>
    <div class="app-error-button">
      <cancel @click="onClick"/>
    </div>
  </div>
</template>

<script>
import error from '@/assets/img/error.svg?inline';
import cancel from '@/assets/img/cancel.svg?inline';

export default {
  name: 'app-error',
  components: {
    error,
    cancel,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    messages: {
      type: Array,
      default: () => [],
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style lang='sass' scoped>
.app-error
  position: relative
  display: flex
  justify-content: space-between
  background-color: rgba($error, .1)
  color: $error
  padding: 12px 16px
  border-radius: 0 4px 4px 0

  &::before
    content: ""
    position: absolute
    width: 2px
    height: 100%
    background-color: $error
    top: 0
    left: 0

  .app-error-content
    margin-right: 20px
    max-height: 150px
    overflow: auto

    .title
      display: flex

    svg
      width: 20px
      height: 20px
      padding-right: 10px

    ul
      padding: 0 7px

      li
        list-style: inside

      p
        display: inline-flex
        color: $error

  .app-error-button
    display: flex
    align-items: center
    width: 12px

    svg
      width: 12px
      height: 12px
      cursor: pointer

      path
        stroke: $error
</style>
