<template>
  <!-- BODY / LISTE DES LOTS MÉTRÉS -->
  <div class="adding-versions-body body">
    <div class="search grid-x">
      <div class="cell shrink search">
        <app-search size="small" v-model="search" placeholder="Rechercher une version" />
      </div>
      <!-- FILTER MODAL -->
      <div class="cell shrink button">
        <filter-modal @change="filterVersions" @reset="resetFilters" @close="closeFilterModal" :numberOfInputs="Object.values(filters).filter(i => !!i).length">
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-radio-button
                label="Filtrer par prix"
                v-model="filters.order"
                :input="filters.order"
                :options="[
                  { name: 'price', label: 'Prix croissant' },
                  { name: '-price', label: 'Prix décroissant' }
                ]"
              />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-select
                label="Gamme"
                v-model="filters.rangeId"
                @input="resetModelOptions"
                :options="rangeOptions"
                :disabled="rangeOptions.length === 0"
              />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-select
                label="Modèle"
                v-model="filters.modelId"
                :options="modelOptions"
                :disabled="modelOptions.length === 0"
              />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-radio-button
                label="Chambres"
                name="roomCount"
                :value="filters.roomCount"
                v-model="filters.roomCount"
                :options="[
                  { name: '1', label: '1' },
                  { name: '2', label: '2' },
                  { name: '3', label: '3' },
                  { name: '4', label: '4' },
                  { name: '5', label: '5' },
                ]"
              />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-radio-button
                label="Niveau"
                name="floor"
                :value="filters.floorId"
                v-model="filters.floorId"
                :options="floors"
              />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-radio-button
                class="filter-garage"
                label="Garage"
                name="garage"
                :value="filters.garage"
                v-model="filters.garage"
                :options="[
                  { name: 'withoutGarage', label: 'Sans garage' },
                  { name: 'withGarage', label: 'Avec garage' },
                ]"
              />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell">
              <app-select
                label="Style"
                v-model="filters.styleId"
                :options="styleOptions"
                :disabled="styleOptions.length === 0"
              />
            </div>
          </div>
        </filter-modal>
      </div>
    </div>
    <app-table :headers="tableHeaders" :data="versions.data" :loading="isLoading">
      <template slot="loading">
        <app-spinner />
      </template>
      <template slot="empty-table">
        <p>Aucune données de disponible</p>
      </template>
      <template slot="versionMedia" slot-scope="{ data }">
        <img class="image" v-if="data.versionMedia" :src="data.versionMedia" alt="" />
        <div v-else class="image not-found">
          <upload class="icon-upload" />
        </div>
      </template>
      <template slot="versionMediaMain" slot-scope="{ data }">
        <img class="image" v-if="data.versionMediaMain" :src="data.versionMediaMain" alt="" />
      </template>
      <template slot="name" slot-scope="{ data }">
        <strong>{{ data.name }}</strong>
      </template>
      <template slot="model" slot-scope="{ data }">
        <strong>{{ data.model.name }}</strong>
      </template>
      <template slot="range" slot-scope="{ data }">
        <p>{{ data.range.name }}</p>
      </template>
      <template slot="surface" slot-scope="{ data }">
        <p>{{ data.surface }} m²</p>
      </template>
      <template slot="price" slot-scope="{ data }">
        <strong v-if="data.price || data.price === 0" class="price">{{ utils.formatCentToEuro(data.price, true) }}</strong>
        <app-spinner v-else small />
      </template>
      <template slot="action" slot-scope="{ data }">
        <app-button size="small" @click="$emit('add:version', data)" :disabled="versionsAdded.includes(data.versionId)">Choisir</app-button>
      </template>
    </app-table>
    <app-pagination
      v-if="versions.metadata"
      :limit="limit"
      :offset="versions.metadata.offset"
      :count="versions.metadata.count"
    />
  </div>
</template>

<script>
import FilterModal from '@/components/general/FilterModal.vue';
import utils from '@/services/utils/utils';
import versionApi from '@/services/api/version';
import styleApi from '@/services/api/style';
import floorApi from '@/services/api/floor';
import range from '@/services/api/range';
import model from '@/services/api/model';
import VersionStatus from '@/services/enums/versionStatus.enum';

import upload from '@/assets/img/upload.svg?inline';

export default {
  name: 'versions',
  components: {
    FilterModal,
    upload,
  },
  emits: ['add:version'],
  props: {
    versionsAdded: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      versions: {
        data: null,
        metadata: null,
      },
      limit: 20,
      tableHeaders: [
        { label: 'Pers.Ext', key: 'versionMedia', size: 2 },
        { label: 'Images principales', key: 'versionMediaMain', size: 2 },
        { label: 'Version', key: 'name', size: 2 },
        { label: 'Modèle', key: 'model', size: 2 },
        { label: 'Gamme', key: 'range', size: 1 },
        { label: 'Surface', key: 'surface', size: 'auto' },
        { label: 'Prix', key: 'price', size: 'auto' },
        { label: '', key: 'action', size: 'auto' },
      ],
      isLoading: true,
      models: [],
      search: null,
      filters: {
        order: null,
        rangeId: null,
        styleId: null,
        modelId: null,
        roomCount: null,
        floorId: null,
        garage: null,
      },
      rangeOptions: [],
      modelOptions: [],
      styleOptions: [],
      utils,
      floors: [],
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  mounted() {
    this.search = this.$route.query.search || null;
    this.filters.order = this.$route.query.order || null;
    this.filters.rangeId = this.$route.query.rangeId || null;
    this.filters.modelId = this.$route.query.modelId || null;
    this.filters.styleId = this.$route.query.styleId || null;
    this.filters.roomCount = this.$route.query.roomCount || null;
    this.filters.floorId = this.$route.query.floorId || null;
    this.filters.garage = this.$route.query.garage || null;
    this.$route.query.page = this.$route.query.page || 1;

    this.getData();
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    $route() {
      if (!this.isLoading) {
        this.getData();
      }
    },
    filters: {
      handler(value) {
        this.filters.rangeId = value.rangeId;
        this.filters.modelId = value.modelId;
        this.filters.styleId = value.styleId;
        this.filters.order = value.order;
        this.filters.roomCount = value.roomCount;
        this.filters.floorId = value.floorId;
        this.filters.garage = value.garage;
      },
      deep: true,
    },
    'filters.rangeId': {
      handler() {
        this.modelOptions = this.getModelOptions();
      },
    },
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getRanges();
      await this.getModels();
      await this.getStyles();
      await this.getFloors();
      this.modelOptions = await this.getModelOptions();
      await this.getVersions();
      this.isLoading = false;
    },
    async getRanges() {
      try {
        const ranges = await range.getAll();
        ranges.push(ranges.shift());
        this.rangeOptions = utils.formatOptions(
          ranges,
          (option) => option.rangeId,
          (option) => option.name,
          'choose',
        );
      } catch (error) {
        throw error;
      }
    },
    async getStyles() {
      try {
        const styles = await styleApi.getAll();
        this.styleOptions = utils.formatOptions(
          styles,
          (option) => option.styleId,
          (option) => option.name,
          'choose',
        );
      } catch (error) {
        throw error;
      }
    },
    async getModels() {
      try {
        const models = await model.getAll(null, null, null, null, true);
        this.models = models.data.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        throw error;
      }
    },
    getModelOptions() {
      if (this.filters.rangeId && this.filters.rangeId !== 'choose') {
        return utils.formatOptions(
          this.models.filter((modelOption) => (modelOption.range.rangeId === this.filters.rangeId)),
          (option) => option.modelId,
          (option) => option.name,
          'choose',
        );
      }

      return utils.formatOptions(
        this.models,
        (option) => option.modelId,
        (option) => option.name,
        'choose',
      );
    },
    async getFloors() {
      try {
        const floors = await floorApi.getAll();

        // On remplace "rez-de-chaussée", par "plein pied", pour faire varier les conditionsdu filtre
        if (floors && floors.length) {
          const index = floors.findIndex((floor) => floor.floorId === 'd0e1fedc-c9a8-4f59-8f30-66d897b0a951');
          if (index !== -1) {
            floors[index] = {
              ...floors[index],
              name: 'Plain-pied',
            };
          }
          this.floors = utils.formatOptions(
            floors,
            (option) => option.floorId,
            (option) => option.name,
          );
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des niveaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    filterVersions() {
      this.$router.push({
        query: {
          ...this.$route.query,
          order: this.filters.order || undefined,
          rangeId: this.filters.rangeId || undefined,
          modelId: this.filters.modelId || undefined,
          styleId: this.filters.styleId || undefined,
          roomCount: this.filters.roomCount || undefined,
          floorId: this.filters.floorId || undefined,
          garage: this.filters.garage || undefined,
          page: 1,
        },
      });
      this.getVersions();
    },
    closeFilterModal() {
      this.filters.order = this.$route.query.order || null;
      this.filters.rangeId = this.$route.query.rangeId || null;
      this.filters.modelId = this.$route.query.modelId || null;
      this.filters.styleId = this.$route.query.styleId || null;
      this.filters.roomCount = this.$route.query.roomCount || null;
      this.filters.floorId = this.$route.query.floorId || null;
      this.filters.garage = this.$route.query.garage || null;
    },
    async resetFilters() {
      this.filters = {
        order: null,
        rangeId: null,
        modelId: null,
        styleId: null,
        roomCount: null,
        floorId: null,
        garage: null,
      };
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          order: this.filters.order || undefined,
          rangeId: this.filters.rangeId || undefined,
          modelId: this.filters.modelId || undefined,
          styleId: this.filters.styleId || undefined,
          roomCount: this.filters.roomCount || undefined,
          floorId: this.filters.floorId || undefined,
          garage: this.filters.garage || undefined,
        },
      });
      this.getVersions();
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    resetModelOptions() {
      this.filters.modelId = undefined;
    },
    async getVersions() {
      this.isLoading = true;
      try {
        const response = await versionApi.getAll(
          this.$route.query.search,
          this.$route.query.rangeId,
          this.$route.query.modelId,
          this.$route.query.styleId,
          this.$route.query.landId,
          VersionStatus.PUBLIC,
          this.$route.query.order,
          this.computeOffset,
          this.limit,
          this.$route.query.roomCount,
          this.$route.query.floorId,
          this.$route.query.garage,
        );
        this.versions = response.data;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des versions',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.adding-versions-body
  @include screen
  .row
    @include row
  .search
    padding-bottom: 1rem
    .button
      margin-left: 1rem
  .price
    color: $primary
  img
    border-radius: 4px
  .image
    object-fit: cover
    width: 130px
    height: 90px
    border: 1px solid $line
    border-radius: 8px
  .not-found
    @include centered-container
    svg
      width: 50px
</style>
