<template>
  <label class="app-label" :class="{ mandatory: required }"><slot></slot></label>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.app-label
  display: block
  text-align: left
  @include heading-xs
  color: $label
  padding-bottom: 4px
  &.mandatory:after
    content: "*"
    display: inline-block
    font-size: 70%
    transform: translateX(0.3rem) translateY(-0.15rem)

</style>
