<template>
  <div class="uk-flex uk-flex-middle">
    <vk-modal
      :show="true"
      class="pdf-send-modal"
      center
      :style="{display: show ? 'block': 'none'}">
      <vk-modal-close @click.stop="" @click="closeModal"></vk-modal-close>
      <vk-modal-title @click.stop=""> PLANNING </vk-modal-title>
      <div @click.stop="">
        <div class="content">
          <div class="loader-container" v-if="isLoading">
            <spinner></spinner>
          </div>
          <vk-grid gutter="collapse" class="uk-child-width-expand">
            <div class="column uk-width-expand@m">
              <div class="input-container">
                <label>Sujet *</label>
                <input class="uk-input" type="text" v-model="subject" placeholder="Sujet" />
              </div>
              <div class="input-container">
                <label>Contenu du mail *</label>
                <textarea class="uk-input" type="text" v-model="html" placeholder="Contenu du mail" />
              </div>
            </div>
            <div class="column uk-width-2-5@m">
              <label>Destinataires</label>
              <div class="recipients-container">
                <div class="recipient" v-if="companiesToContact && companiesToContact.length === 0">
                  <span>Pas de mails trouvés pour les partenaires sélectionnés</span>
                </div>
                <div class="recipient" v-for="(comp, index) in companiesToContact" :key="index">
                  <p>{{ comp.name }}</p>
                  <div v-if="comp.contacts">
                    <span v-for="(contact, index) in comp.contacts" :key="contact ? contact.contactId : index">{{ contact ? contact.email : '' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </vk-grid>
        </div>
        <div>
          <vk-grid gutter="collapse" class="container uk-child-width-expand">
            <div class="column uk-width-expand@m"></div>
            <div class="column uk-width-auto@m">
              <vk-button @click="closeModal" class="secondary button">Annuler</vk-button>
            </div>
            <div class="column uk-width-auto@m">
              <vk-button :disabled="(companiesToContact && companiesToContact.length === 0) || !subject || !html" @click="sendPdf" class="primary button">Envoyer</vk-button>
            </div>
          </vk-grid>
        </div>
      </div>
    </vk-modal>
  </div>
</template>

<script>
import auth from '@/services/auth';
import pdf from '@/services/api/pdf';
import company from '@/services/api/company';
import contact from '@/services/api/contact';
import Spinner from '@/components/general/Spinner.vue';
import projectStep from '@/services/api/projectStep';
import member from '@/services/api/member';

export default {
  name: 'pdf-send-modal',
  components: {
    Spinner,
  },
  props: [
    'show',
    'project',
    'projectSteps',
    'company',
    'companies',
    'showAll',
    'brand',
    'firstWeek',
    'lastWeek',
  ],
  data() {
    return {
      showModal: true,
      subject: null,
      html: null,
      me: null,

      companiesToContact: [],
      isLoading: false,
    };
  },
  created() {
    this.getContacts();
  },
  async mounted() {
    await this.getMember();
    const { projectId } = this.$route.params;

    if (projectId) {
      this.subject = `Planning ${this.project.agency.name} | Chantier N°${this.project.reference} - ${this.project.address.postalCode} ${this.project.address.city}`;

      this.html = `Bonjour,\nveuillez trouver ci-joint le planning du chantier:\nN°${this.project.reference}`
        + ` - ${this.getCustomerName(this.project.customer)} - ${this.project.address.postalCode}  ${this.project.address.city}`
        + '\n\nBonne réception'
        + `\n\n${this.me.firstname} ${this.me.lastname}`
        + `\n${this.project.agency.name}`
        + `\n${this.project.agency.address.addressLine1} - ${this.project.agency.address.postalCode} ${this.project.agency.address.city}`
        + `\n${this.project.agency.phone}`
        + `\n${this.me.email}\n\n`;
    } else {
      const firstWeek = this.firstWeek.split('-')[1];
      const firstYear = this.firstWeek.split('-')[0];

      const lastWeek = this.lastWeek.split('-')[1];
      const lastYear = this.lastWeek.split('-')[0];

      this.subject = `Planning ${this.brand.name} | ${this.company.name} |  S${firstWeek} ${firstYear} - S ${lastWeek} ${lastYear} `;

      this.html = 'Bonjour,\nveuillez trouver ci-joint votre planning de chantiers pour la période suivante: \n'
        + `S ${firstWeek} ${firstYear} - S ${lastWeek} ${lastYear} `
        + '\n\nBonne réception'
        + `\n\n${this.me.firstname} ${this.me.lastname}`
        + `\n${this.brand.name}`
        + `\n${this.me.phone}`
        + `\n${this.me.email}\n\n`;
    }
  },
  beforeDestroy() {
    this.closeModal();
  },
  watch: {
    company: 'getContacts',
    companies: 'getContacts',
  },
  methods: {
    async getMember() {
      try {
        this.me = await member.getMe();
      } catch (error) {
        this.$displayNotification('L\'obtention de vos données a échouée.');
      }
    },
    getCustomerName(customer) {
      let customerName = '';

      if (customer.type === 'PERSON') {
        customerName = customer.gender === 'MALE' ? 'M. ' : 'Mme. ';
        customerName += customer.lastname !== customer.secondaryContactLastname ? `${customer.lastname.toUpperCase()} ` : '';
        customerName += customer.secondaryContactLastname && customer.secondaryContactGender === 'MALE' ? 'et M. ' : '';
        customerName += customer.secondaryContactLastname && customer.secondaryContactGender === 'FEMALE' ? 'et Mme. ' : '';
        customerName += customer.secondaryContactLastname && customer.secondaryContactLastname !== customer.lastname ? customer.secondaryContactLastname.toUpperCase() : '';
        customerName += customer.secondaryContactLastname && customer.secondaryContactLastname === customer.lastname ? customer.lastname.toUpperCase() : '';
      } else {
        customerName = customer.companyName;
      }
      return customerName;
    },
    async getContacts() {
      // Si on vient de la page partenaire
      if (this.company) {
        let favorites = null;
        let companyContacts = [];

        const allCompanyContacts = await contact.getByCompany(this.company.companyId);

        if (allCompanyContacts && allCompanyContacts.length) {
          favorites = allCompanyContacts.filter((m) => m.isFavorite);

          if (favorites && favorites.length) {
            companyContacts = favorites;
          } else {
            companyContacts.push(allCompanyContacts[0]);
          }

          this.companiesToContact = [{
            name: this.company.name,
            contacts: companyContacts,
          }];
        }
      } else {
        // Si on vient de la page projet
        const { companies } = this.$route.query;
        const selectedCompanies = companies || this.companies;

        const companiesList = [];
        const promises = [];
        if (selectedCompanies) {
          // vérfifier que les companies sont bien récupéré dans un tableau
          const companiesArray = Array.isArray(selectedCompanies) ? selectedCompanies : [selectedCompanies];
          // récupérer les objets entier pour avoir les addresses mails
          const companiesPromises = companiesArray.map((cId) => company.getById(cId));
          const fullCompanies = await Promise.all(companiesPromises);

          // récupérer les contacts pour chaque partenaires
          const companyContactsPromises = companiesArray.map((cId) => contact.getByCompany(cId));
          const companyContactsAll = await Promise.all(companyContactsPromises);

          fullCompanies.forEach((c) => {
            const matches = companyContactsAll.find((cc) => cc && cc.length > 0 && cc[0].companyId === c.companyId);
            let favorites = [];
            let companyContacts = [];

            if (matches && matches.length) {
              favorites = matches.filter((m) => m.isFavorite);

              if (favorites && favorites.length) {
                companyContacts = favorites;
              } else {
                companyContacts.push(matches[0]);
              }
              companiesList.push({
                name: c.name,
                contacts: companyContacts,
              });
            }
          });
        }
        await Promise.all(promises);
        this.companiesToContact = companiesList;
      }
    },
    closeModal(event) {
      if (this.show && event && !this.$el.contains(event.target)) {
        this.$emit('close');
      }
    },
    async autoConfirmProjectSteps() {
      const confirmPromises = this.projectSteps.map((ps) => {
        const newProjectStep = Object.assign(ps);

        const subtractedStart = this.$dayjs(ps.startedAt).subtract(ps.company.periodPreference, 'week');

        const isBetween = this.$dayjs().isBetween(subtractedStart, ps.startedAt, null, '[]');
        const isAfter = this.$dayjs().isAfter(ps.startedAt);

        const match = this.companiesToContact.find((ctc) => newProjectStep.companyId === ctc.companyId
          && (isAfter || isBetween));

        if (match) {
          return projectStep.edit({
            projectStepId: newProjectStep.projectStepId,
            projectId: newProjectStep.project.projectId,
            companyId: newProjectStep.company ? newProjectStep.company.companyId : null,
            companyCategoryId: newProjectStep.companyCategory ? newProjectStep.companyCategory.companyCategoryId : null,
            label: newProjectStep.label,
            rank: newProjectStep.rank,
            startedAt: newProjectStep.startedAt,
            closedAt: newProjectStep.closedAt,
            status: newProjectStep.status,
            type: newProjectStep.type,
            confirmed: true,
            confirmedAt: this.$dayjs().utc(),
            showToConfirm: newProjectStep.showToConfirm,
            comment: newProjectStep.comment,
            isPublicComment: newProjectStep.isPublicComment,
          });
        }
        return null;
      });

      const newProjectsSteps = await Promise.all(confirmPromises.filter((p) => p));
      return newProjectsSteps;
    },
    async sendPdf() {
      const {
        endDate, startDate, companyType, project, onlyMember,
      } = this.$route.query;
      const { companyId, projectId } = this.$route.params;
      this.isLoading = true;

      try {
        if (companyId) {
          await pdf.sendByCompany(
            companyId,
            auth.getToken(),
            project,
            startDate,
            endDate,
            onlyMember,
            this.subject,
            this.html.replace(/(?:\r\n|\r|\n)/g, '<br />'),
          );
        } else {
          await pdf.sendByProject(
            projectId,
            auth.getToken(),
            startDate,
            endDate,
            companyType,
            this.companies,
            this.subject,
            this.html.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            this.showAll,
          );
        }

        await this.autoConfirmProjectSteps();
        this.$emit('update');
        this.$emit('close');
        this.$displayNotification('Mails envoyés avec succès');
        this.isLoading = false;
      } catch (er) {
        this.$emit('update');
        this.$emit('close');
        this.$displayNotification('Echec de l\'envoi des mails');

        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.pdf-send-modal {
  opacity: 1;
  .loader-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    background-color: $gray-very-light;
    height: calc(100% - 92px);
    opacity: 0.5;
  }
  .input-container {
    margin-bottom: 20px;
    input, textarea {
      width: 90%!important;
      min-width: 200px!important;
      margin-bottom: 20px;
    }
    textarea {
      height: 200px;
    }
  }
  .uk-modal-dialog {
    min-width: 900px;
    opacity: 1;
  }
  .no-top-padding {
    padding-top: 0px!important;
  }
  .link {
    cursor: pointer;
  }
  .recipients-container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    overflow-y: auto;
    height: 265px;
  }
  .recipient {
    background-color: $gray-very-light;
    color: $gray-medium;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    p {
      font-weight: 600;
      margin-bottom: 10px;
    }
    span {
      display: block;
    }
  }
}
</style>
