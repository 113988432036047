<template>
  <!-- BODY / LISTE DES LOTS MÉTRÉS -->
  <div class="adding-lands-body body">
    <div class="search grid-x">
      <div class="cell shrink search">
        <app-search size="small" v-model="search" placeholder="Rechercher un terrain" />
      </div>
      <!-- FILTER MODAL -->
      <div class="cell shrink button">
          <filter-modal
            @change="filterLands"
            @reset="resetFilters"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-radio-button
                  label="Trier par"
                  name="orderBy"
                  :value="$route.query.orderBy"
                  v-model="filters.orderBy"
                  :options="[
                    { name: 'price', label: 'Prix croissant' },
                    { name: 'width', label: 'Façade croissante' }
                  ]"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-search-city
                  label="Commune"
                  placeholder="Nantes - 44000"
                  @select="setPreSelectedPostalCode"
                  @clear="resetSelectedPostalCode"
                  :selectedPostalCode="selectedPostalCode"
                />
              </div>
            </div>
          </filter-modal>
        </div>
    </div>
    <app-table :headers="tableHeaders" :data="lands.data" :loading="isLoading">
      <template slot="loading">
        <app-spinner />
      </template>
      <template slot="city" slot-scope="{ data }">
        <strong>
          {{ data.address.postalCodeInseeNumber.split('-')[0] }} - {{ data.address.city }}
        </strong>
      </template>
      <template slot="allotment" slot-scope="{ data }">
        <p>{{ data.allotment ? data.allotment.name : '' }}</p>
      </template>
      <template slot="company" slot-scope="{ data }">
        <p>{{ data.company ? data.company.name : '' }}</p>
      </template>
      <template slot="reference" slot-scope="{ data }">
        <p>{{ data.reference }}</p>
      </template>
      <template slot="lotNumber" slot-scope="{ data }">
        <p>{{ data.lotNumber }}</p>
      </template>
      <template slot="depth" slot-scope="{ data }">
        <p>{{ data.width }} m</p>
      </template>
      <template slot="surface" slot-scope="{ data }">
        <strong>{{ data.surface }} m²</strong>
      </template>
      <template slot="price" slot-scope="{ data }">
        <strong class="price">{{ utils.formatCentToEuro(data.price, true) }}</strong>
      </template>
      <template slot="empty-table">
        <p>Aucune donnée de disponible</p>
      </template>
      <template slot="action" slot-scope="{ data }">
        <app-button size="small" @click="$emit('add:land', data)" :disabled="landsAdded.includes(data.landId)">Choisir</app-button>
      </template>
    </app-table>
    <app-pagination
        v-if="lands.metadata"
        :limit="limit"
        :offset="lands.metadata.offset"
        :count="lands.metadata.count"
      />
  </div>
</template>

<script>
import FilterModal from '@/components/general/FilterModal.vue';
import utils from '@/services/utils/utils';
import landApi from '@/services/api/land';
import postalCodeApi from '@/services/api/postalCode';
import booleanEnum from '@/services/enums/booleanEnum.enum';

import status from '@/services/data/status.json';

import AppSearchCity from '@/basics/components/AppSearchCity.vue';

export default {
  name: 'lands',
  components: {
    FilterModal,
    AppSearchCity,
  },
  emits: ['add:land'],
  props: {
    landsAdded: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      lands: {
        data: null,
        metadata: null,
      },
      limit: 20,
      tableHeaders: [
        { label: 'Ville', key: 'city', size: 'auto' },
        { label: 'Lotissement', key: 'allotment', size: 'auto' },
        { label: 'Lotisseur', key: 'company', size: 1 },
        { label: 'Référence', key: 'reference', size: 1 },
        { label: 'Lot', key: 'lotNumber', size: 1 },
        { label: 'Façade', key: 'depth', size: 1 },
        { label: 'Surface', key: 'surface', size: 1 },
        { label: 'Prix', key: 'price', size: 1 },
        { label: '', key: 'action', size: 1 },
      ],
      isLoading: true,
      search: null,
      preSelectedPostalCode: null,
      selectedPostalCode: null,
      status,
      booleanEnum,

      filters: {
        orderBy: null,
        postalCodeInseeNumber: null,
      },
      utils,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  mounted() {
    this.search = this.$route.query.search || null;
    this.selectedView = this.$route.query.view || undefined;
    this.filters.orderBy = this.$route.query.orderBy || null;
    this.filters.postalCodeInseeNumber = this.$route.query.postalCodeInseeNumber || null;

    this.getData();
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getLands();
        this.isLoading = false;
      }
    },
    filters: {
      handler(value) {
        this.filters.orderBy = value.orderBy;
        this.filters.postalCodeInseeNumber = value.postalCodeInseeNumber;
      },
      deep: true,
    },
  },
  computed: {
    // Calcule de l'offset
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      if (this.$route.query.postalCodeInseeNumber) {
        this.getPostalCode(this.$route.query.postalCodeInseeNumber);
      }

      await this.getLands();
      this.isLoading = false;
    },
    // Récupère le code postal sélectionné
    setPreSelectedPostalCode(value) {
      this.preSelectedPostalCode = value.label;
      this.filters.postalCodeInseeNumber = value.name;
    },

    // Reset les valeurs du code postal sélectionné si le champ de recherche est vide
    resetSelectedPostalCode() {
      this.filters.postalCodeInseeNumber = null;
    },

    // Récupération du code postal sélectionné
    async getPostalCode(postalCodeInseeNumber) {
      try {
        const postalCode = await postalCodeApi.getOne(postalCodeInseeNumber);
        this.selectedPostalCode = `${postalCode.city} - ${postalCode.postalCodeInseeNumber.split('-')[0]}`;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du code postal',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async filterLands() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: this.filters.orderBy || undefined,
          postalCodeInseeNumber: this.filters.postalCodeInseeNumber || undefined,
          page: 1,
        },
      });

      if (this.preSelectedPostalCode) {
        this.selectedPostalCode = this.preSelectedPostalCode;
      }

      if (!this.$route.query.postalCodeInseeNumber) {
        this.selectedPostalCode = null;
      }
    },
    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.orderBy = this.$route.query.orderBy || null;
      this.filters.postalCodeInseeNumber = this.$route.query.postalCodeInseeNumber || null;

      if (!this.$route.query.postalCodeInseeNumber) {
        this.selectedPostalCode = null;
      }
    },
    resetFilters() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        orderBy: null,
        postalCodeInseeNumber: null,
      };

      // On supprime les query categoryId et lotId de l'URl
      this.$router.replace({
        query: {
          ...this.$route.query,
          orderBy: undefined,
          postalCodeInseeNumber: undefined,
        },
      });

      // On réinitialise les valeurs du label de la commune sélectionné
      this.preSelectedPostalCode = null;
      this.selectedPostalCode = null;
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    resetModelOptions() {
      this.filters.modelId = undefined;
    },
    async getLands() {
      this.isLoading = true;
      try {
        this.lands = await landApi.getAll(
          this.limit,
          this.computeOffset,
          this.$route.query.search,
          this.$route.query.orderBy,
          this.$route.query.agencyId,
          null,
          this.$route.query.postalCodeInseeNumber,
          true,
          null,
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lands',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.adding-lands-body
  @include screen
  .row
    @include row
  .search
    padding-bottom: 1rem
    .button
      margin-left: 1rem
  .price
    color: $primary
  img
    border-radius: 4px
  .image
    object-fit: cover
    width: 130px
    height: 90px
    border: 1px solid $line
    border-radius: 8px
  .not-found
    @include centered-container
    svg
      width: 50px
</style>
