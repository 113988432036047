import axios from 'axios';
import auth from '@/services/auth';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des customers
 * @param {string} limit: limit d'affichage par page
 * @param {string} offset: offset de la pagination
 * @param {string} search: paramètre de recherche, sur le nom du client, ou sa référence
 * @param {string[]} statuses: status du client, on pourra envoyer plusieurs status à la fois
 * @param {string} agencyId: id de l'agence
 * @param {boolean} noLimit: bool, pour savoir si on veut utiliser la pagination ou non
 */
const getAll = async (limit, offset, search, statuses, agencyId, noLimit, startDate, endDate) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(statuses && { statuses }),
        ...(agencyId && { agencyId }),
        ...(noLimit && { noLimit }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),

      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère un client avec son id
 * @param {string} customerId id du customer
 */
const getById = async (customerId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/${customerId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'un nouveau client
 * @param {object} customer Customer à créer
 */
const create = async (customer) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/customers`, customer);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un client
 * @param {object} customer: client à mettre à jour
 * @param {string} customerId: id du client à modifier
 */
const update = async (customerId, customer) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/customers/${customerId}`, customer);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Supprime un client
 * @param {string} allotmentId: id du client à supprimer
 */
const deleteOne = async (customerId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/customers/${customerId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupérer le nom d'un client formatté un client
 * @param {object} customer: objet du customer à retourner
 */
const getCustomersNames = (customer) => {
  let customersName;
  if (!customer.secondaryContactLastname || (customer.lastname.trim() === customer.secondaryContactLastname.trim())) {
    customersName = `${customer.lastname}`;
  } else if (customer.lastname.trim() !== customer.secondaryContactLastname.trim()) {
    customersName = `${customer.lastname}-${customer.secondaryContactLastname}`;
  }
  return customersName;
};

/**
 * Récupère une liste de client "chaud" ou liste d'opportunités par membre
 */
const getHotCustomersByMember = async (limit, offset) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/hot-customers`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const downloadCsv = (filters) => {
  try {
    const token = auth.getToken();
    const {
      statuses,
      startDate,
      endDate,
      search,
      agencyId,
    } = filters;
    const statusesQuery = (statuses && statuses.map((status) => `statuses[]=${status}`).join('&'));
    window.open(`${VUE_APP_API_URL}/customers/csv?token=${token}${statuses ? `&${statusesQuery}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${search ? `&search=${search}` : ''}${agencyId ? `&agencyId=${agencyId}` : ''}`, '_blank');
  } catch (err) {
    throw err;
  }
};

const customers = {};
customers.getAll = getAll;
customers.getById = getById;
customers.create = create;
customers.update = update;
customers.delete = deleteOne;
customers.getCustomersNames = getCustomersNames;
customers.downloadCsv = downloadCsv;
customers.getHotCustomersByMember = getHotCustomersByMember;

export default customers;
