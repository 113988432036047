<template>
  <div class="app-progress">
    <div class="part"
      v-for="(step, index) in data" :key="index"
      :class="{
        done: step.status === 'done',
        'in-progress': step.status === 'in-progress',
        'pending': step.status === 'pending',
        'large': step.type === 'large',
        'end': (index < data.length - 1 && data[index + 1].type === 'large') || (index === data.length - 1)
        }">
      <div v-if="step.type === 'large'">
        <img v-if="step.status === 'done'" class="part-done" src="@/assets/img/validated.svg" />
        <span v-else class="part-number">{{ step.number }}</span>
        <span class="part-title">{{ step.label }}</span>
      </div>
      <div class="step" v-else>
        <div class="fill"></div>
        <div class="dot"></div>
      </div>
      <div class="step end-step">
        <div class="fill"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: [Array],
  },
};
</script>

<style lang="sass">
.app-progress
  display: flex
  align-items: center
  width: 100%
  .part
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    &.end
      width: 200%
      flex-direction: row
      align-items: center
      .end-step
        display: initial!important
    &.large
      width: auto
      position: relative
    &.done
      .part-number, .step .dot, .step .fill
        background-color: $success-color
      .part-number, .step .dot
        border: $success-color solid 2px
      .part-title
        color: $success-color
    &.in-progress
      .part-number
        background-color: $main-color
        color: white
        border-color: $main-color
      .step .dot
        background-color: white
        border: $main-color solid 2px
      .step .fill
        background-color: $main-color
      .part-title
        color: $main-color
    &.pending
      .part-number, .step .dot
        background-color: white
        color: $light-color
        border: $light-color solid 2px
      .fill
        background-color: $light-color
      .part-title
        color: $light-color
    .part-title
      font-weight: 600
      position: absolute
      width: 80px
      left: -5px
      margin-top: 5px
    img.part-done
      width: 30px
      display: flex
    .part-number
      width: 30px
      height: 30px
      border-radius: 20px
      color: white
      font-weight: 600
      font-size: 16px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
  .step
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    &.end-step
      display: none
    .dot
      width: 13px
      height: 12px
      border-radius: 10px
      margin: auto
      display: flex
    .fill
      width: 100%
      height: 1px
      background-color: $light-color
</style>
