import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Upload des médias des versions
 * @param {string} versionId: id de la version
 * @param {File} file: image à upload
 * @returns données de la version, incluant l'URL du nouveau média
 */
const upload = async (versionId, file) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/versions/${versionId}/medias`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression d'un media de la version
 * @param {string} versionId: id de la version
 * @param {string} versionMediaId: id du média d'agence
 * @returns données de la version
 */
const deleteOne = async (versionId, versionMediaId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/versions/${versionId}/medias/${versionMediaId}`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupération des medias de la version
 * @param {string} versionId: id de la version
 * @returns données de l'agence
 */
const getAll = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions/${versionId}/medias`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupération des medias de la version publique
 * @param {string} versionId: id de la version
 * @returns données de l'agence
 */
const getPublic = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/versions/${versionId}/version-medias`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const versionMedia = {};
versionMedia.upload = upload;
versionMedia.delete = deleteOne;
versionMedia.getAll = getAll;
versionMedia.getPublic = getPublic;

export default versionMedia;
