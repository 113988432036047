<template>
  <div class="project-steps-in-progress hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <div>
          <h1>Liste des tâches</h1>
        </div>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body">
      <ProjectSteps />
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import ProjectSteps from '@/views/projectStep/ProjectSteps.vue';

export default {
  name: 'companies',
  components: {
    TabNav,
    ProjectSteps,
  },
  metaInfo: {
    title: 'Liste des tâches',
  },
  data() {
    return {
      // New data
      tabs: [
        {
          route: {
            name: 'projects-project-steps',
          },
          label: 'Tâches en-cours',
        },
        {
          route: {
            name: 'projects-project-steps-week',
          },
          label: 'Tâches / semaine',
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang='sass' scoped>
.project-steps-in-progress
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
</style>
