import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Upload des médias d'agence
 * @param {string} agencyId: id de l'agence
 * @param {File} file: image à upload
 * @returns données de l'agence, incluant l'URL du nouveau média
 */
const upload = async (agencyId, file) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/agencies/${agencyId}/medias`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression d'un media d'agence
 * @param {string} agencyId: id de l'agence
 * @param {string} agencyMediaId: id du média d'agence
 * @returns données de l'agence
 */
const deleteOne = async (agencyId, agencyMediaId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/agencies/${agencyId}/medias/${agencyMediaId}`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupération des medias de l'agence
 * @param {string} agencyId: id de l'agence
 * @returns données de l'agence
 */
const getAll = async (agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/${agencyId}/medias`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const agencyMedia = {};
agencyMedia.upload = upload;
agencyMedia.delete = deleteOne;
agencyMedia.getAll = getAll;

export default agencyMedia;
