<template>
  <div
    class="project-step-modal uk-flex uk-flex-middle"
    v-show="openModal === true && currentProjectStep">
    <!-- DETAIL DE LA TACHE / PLANNING -->
    <div v-show="modalType === 'edit'">
      <vk-modal
        :show="true" class="datepicker-modal project-steps-modal"
        :style="{display: openModal && modalType === 'edit'? 'block': 'none'}">
        <vk-modal-close></vk-modal-close>
        <vk-modal-title @click.stop="">
          {{ currentProjectStep ? currentProjectStep.label : '' }}
        </vk-modal-title>
        <div @click.stop="" v-if="currentProjectStep" class="modal-body">
          <vk-grid gutter="collapse" class="uk-child-width-expand">
            <div class="column uk-width-1-2@m">
              <div class="content partners">
                <label>Type :</label>
                <select class="uk-select uk-input" v-model="currentProjectStep.type" @change="valueChanged">
                  <option
                    v-for="(label, type) in buildTypes"
                    :key="type" :value="type">
                    {{ label }}
                  </option>
                </select>
              </div>
              <div class="content project-step-name">
                <label>Nom de la tâche:</label>
                <input class="uk-input" type="text" v-model="currentProjectStep.label" placeholder="Nom" @input="valueChanged">
              </div>
              <div class="content partners" v-if="currentProjectStep.type !== 'EVENT' && currentProjectStep.type !== 'CLOSE'">
                <label>Choix de la catégorie:</label>
                <select class="uk-select uk-input" v-if="companyCategories" v-model="selectedCompanyCategory" @change="setFilteredCompanies(); valueChanged()">
                  <option :value="null">Pas de catégorie</option>
                  <option
                    v-for="category in companyCategories"
                    :key="category.companyCategoryId" :value="category.companyCategoryId">
                    {{ category.label }} ({{ types.companyCategoryType[category.type] }})
                  </option>
                </select>
              </div>
              <div class="content partners" v-if="currentProjectStep.type !== 'EVENT' && currentProjectStep.type !== 'CLOSE'">
                <label>Choix du partenaire:</label>
                <select class="uk-select" v-model="selectedCompanyId" @change="valueChanged">
                  <option :value="null">Pas d'entreprise sélectionnée</option>
                  <option :value="company.companyId"
                    v-for="company in filteredCompanies"
                    :key="company.companyId">{{ company.name }}</option>
                </select>
              </div>
            </div>
            <div class="column uk-width-1-2@m">
              <div class="content date project-step-dates">
                <vk-grid gutter="collapse" class="container uk-child-width-expand">
                  <div class="column uk-width-expand@m">
                    <label class="title">Début</label>
                    <datepicker
                      v-model="currentProjectStep.startedAt"
                      @selected="valueChanged"
                      :language="french"
                      format="dd MMMM yyyy">
                  </datepicker>
                  </div>
                  <div class="column uk-width-auto@m right-col">
                    <label class="title">Validation</label>
                    <input
                      class="uk-checkbox"
                      :name="`start-${currentProjectStep.projectStepId}`"
                      type="checkbox"
                      @change="changeStartStatus"
                      :checked="currentProjectStep.status === 'IN_PROGRESS' || currentProjectStep.status === 'DONE'" />
                  </div>
                </vk-grid>
              </div>
              <div class="content date" v-if="currentProjectStep.type !== 'EVENT'">
                <vk-grid gutter="collapse" class="container uk-child-width-expand">
                  <div class="column uk-width-expand@m">
                    <label class="title">Fin</label>
                    <datepicker
                      v-model="currentProjectStep.closedAt"
                      @selected="valueChanged"
                      :language="french"
                      format="dd MMMM yyyy">
                    </datepicker>
                  </div>
                  <div class="column uk-width-auto@m right-col">
                    <label class="title">Validation</label>
                    <input
                      class="uk-checkbox"
                      :name="`end-${currentProjectStep.projectStepId}`"
                      type="checkbox"
                      @change="changeCloseStatus"
                      :checked="currentProjectStep.status === 'DONE'" />
                  </div>
                </vk-grid>
              </div>
              <div class="content date">
                <vk-grid gutter="collapse" class="container uk-child-width-expand">
                  <div class="column uk-width-expand@m switch-with-label">
                    <label >M'informer {{ selectedCompany ? selectedCompany.periodPreference : '3' }} semaines avant le démarrage</label>
                    <toggle-button
                      :sync="true"
                      v-model="currentProjectStep.showToConfirm"
                      color="#008DFF"/>
                  </div>
                </vk-grid>
              </div>
            </div>
            <div
              class="column uk-width-1-1@m"
              v-if="currentProjectStep.type !== 'EVENT' && currentProjectStep.type !== 'CLOSE' && selectedCompanyId !== null"
            >
              <div class="content export">
                <input
                  class="uk-checkbox"
                  :name="`is-public-comment`"
                  type="checkbox"
                  v-model="currentProjectStep.confirmed"/>
                <p>Confirmation de l'intervention du partenaire {{ currentProjectStep.confirmedAt ? `- Dernière demande faite le ${$dayjs(currentProjectStep.confirmedAt).format('DD/MM/YYYY')}` : null }}</p>
              </div>
            </div>
            <div class="column uk-width-1-1@m" v-if="currentProjectStep.type !== 'START' && currentProjectStep.type !== 'CLOSE'">
              <div class="content notes">
                <label class="title">Notes</label>
                <textarea class="uk-textarea" rows="10" v-model="currentProjectStep.comment" @input="valueChanged"></textarea>
              </div>
              <div class="content export">
                <input
                  class="uk-checkbox"
                  :name="`is-public-comment`"
                  type="checkbox"
                  v-model="currentProjectStep.isPublicComment" @change="valueChanged"/>
                <p>
                  <span>Exporter</span>
                  Vos notes seront visibles lors de l'exportation des rapports.
                </p>
              </div>
            </div>
          </vk-grid>
        </div>
        <div @click.stop="">
          <vk-grid gutter="collapse" class="container uk-child-width-expand">
            <div class="column uk-width-expand@m"></div>
            <div class="column uk-width-auto@m">
              <vk-button class="secondary button" @click="closeModal">Annuler</vk-button>
            </div>
            <div class="column uk-width-auto@m">
              <vk-button class="primary button" @click="saveData(currentProjectStep), closeModal">Valider</vk-button>
            </div>
          </vk-grid>
        </div>
      </vk-modal>
  </div>
  <div v-if="modalType === 'dropdown' && openModal">
    <div v-if="currentProjectStep">
      <DropdownActions
        v-if="currentProjectStep.type !== 'START' &&  currentProjectStep.type !== 'CLOSE'"
        :actions="dropdownContent"
        :initialValue="true"
        :onlyDropdown="true"
        :position="dropdownPosition"
        />
    </div>
  </div>
  <div v-show="modalType === 'delay'">
    <vk-modal class="delay-modal" center
      :show="true"
      :style="{display: openModal && modalType === 'delay'? 'block': 'none'}">
      <vk-modal-close @click="closeModal"></vk-modal-close>
      <vk-modal-title  @click.stop="">Décaler toutes les tâches suivantes</vk-modal-title>
      <div @click.stop="" class="content">
        <div class="container uk-width-1-2@m">
          <label>Nombre de semaines *</label>
          <input class="uk-input" type="number" v-model="delayInWeeks" @keypress="isInteger($event)"/>
        </div>
      </div>
      <div class="content">
        <label>* Cette tâche n'est pas incluse, seules les tâches suivantes seront décalées.</label>
      </div>
      <div>
        <vk-grid gutter="collapse" class="container uk-child-width-expand">
          <div class="column uk-width-expand@m"></div>
          <div class="column uk-width-auto@m">
            <vk-button @click="closeModal" class="secondary button">Annuler</vk-button>
          </div>
          <div class="column uk-width-auto@m">
            <vk-button @click="delayFollowingProjectSteps" class="primary button">Décaler</vk-button>
          </div>
        </vk-grid>
      </div>
    </vk-modal>
  </div>
  <div v-show="modalType === 'delete'">
    <vk-modal class="delete-modal" center
      :show="true"
      :style="{display: openModal && modalType === 'delete'? 'block': 'none'}">
      <vk-modal-close @click="closeModal"></vk-modal-close>
      <vk-modal-title @click.stop="">Attention</vk-modal-title>
      <div @click.stop="" class="content">
        <span>Êtes vous sûr de vouloir supprimer cette tâche?</span>
      </div>
      <div>
        <vk-grid gutter="collapse" class="container uk-child-width-expand">
          <div class="column uk-width-expand@m"></div>
          <div class="column uk-width-auto@m">
            <vk-button @click="closeModal" class="secondary button">Annuler</vk-button>
          </div>
          <div class="column uk-width-auto@m">
            <vk-button @click="deleteProjectStep" class="primary button">Supprimer</vk-button>
          </div>
        </vk-grid>
      </div>
    </vk-modal>
  </div>
  <div v-show="modalType === 'start'">
    <vk-modal class="datepicker-modal project-steps-modal" center
      :show="true"
      :style="{display: openModal && modalType === 'start'? 'block': 'none'}">
      <vk-modal-close @click="closeModal"></vk-modal-close>
      <vk-modal-title @click.stop="">Enregistrement de l'ouverture de chantier</vk-modal-title>
      <div @click.stop="" v-if="currentProjectStep" class="modal-body">
        <div class="content date">
          <strong class="title">Votre chantier est maintenant démarré. Saisissez ci-dessous la date effective de l'ouverture du chantier.</strong>
          <div class="container top-input">
            <label>Date effective d'ouverture de chantier</label>
            <datepicker v-model="currentProjectStep.startedAt"
              :language="french"
              :disabledDates="{days: [6,0] }">
            </datepicker>
          </div>
          <strong class="title">Les réglages de votre planning indiquent les objectifs suivants:</strong>
          <vk-grid gutter="collapse" class="uk-child-width-expand container top-input" v-if="project">
            <div class="column uk-width-1-5@m">
              <label class="title">Durée</label>
              <div class="container" v-if="currentProjectStep.startedAt">
                <div class="duration">
                  {{ getDayjsObject(project.closedStepDate).diff(getDayjsObject(currentProjectStep.startedAt), 'day') + 1 }} J
                </div>
              </div>
            </div>
            <div class="column uk-width-2-5@m right-col">
              <label class="title">Objectif de livraison</label>
              <div class="container">
                <div class="duration">
                  <span class="open-modal-date">{{ $dayjs(project.closedStepDate).format('D MMMM YYYY') }}</span>
                </div>
              </div>
            </div>
          </vk-grid>
          <div>
            <label class="label">Je valide les informations ci-dessus pour enregistrer l'ouverture du chantier.</label>
            <input
              class="uk-checkbox"
              :name="`confirmed-start-date`"
              type="checkbox"
              v-model="startDateIsConfirmed"/>
          </div>
        </div>
        <vk-button
          @click="closeModal"
          class="secondary button uk-margin-small-right">Annuler</vk-button>
        <vk-button
          @click="saveData(currentProjectStep), closeModal"
          type="primary"
          :disabled="currentProjectStep && !currentProjectStep.startedAt"
          class="primary button">Valider la date</vk-button>
        <vk-button
          @click="startProject()"
          type="primary"
          :disabled="currentProjectStep && !currentProjectStep.startedAt || !startDateIsConfirmed"
          class="primary button">Enregistrer l'ouverture du chantier</vk-button>
        </div>
      </vk-modal>
    </div>
    <div v-show="modalType === 'close'">
    <vk-modal class="datepicker-modal project-steps-modal" center
      :show="true"
      :style="{display: openModal && modalType === 'close'? 'block': 'none'}">
      <vk-modal-close @click="closeModal"></vk-modal-close>
      <vk-modal-title @click.stop="">Enregistrement de la livraison de chantier</vk-modal-title>
      <div @click.stop="" v-if="currentProjectStep">
        <div class="content date">
          <strong class="title">Votre chantier est maintenant terminé. Saisissez ci-dessous la date effective de livraison du chantier.</strong>
          <div class="container top-input">
            <label>Date effective de livraison de chantier</label>
            <datepicker v-model="currentProjectStep.startedAt"
              :language="french"
              :disabledDates="{days: [6,0] }">
            </datepicker>
          </div>
          <div class="container" v-if="project">
            <strong class="title">Les objectifs étaient les suivants:</strong>
            <div>
              <vk-grid gutter="collapse" class="uk-child-width-expand container top-input" v-if="project">
                <div class="column uk-width-1-5@m">
                  <label class="title">Objectif de Durée</label>
                  <div class="container" v-if="project.estimatedCloseDate">
                    <div class="duration">
                      {{ getDayjsObject(project.estimatedCloseDate).diff(project.startedStepDate, 'day') + 1 }} J
                    </div>
                  </div>
                </div>
                <div class="column uk-width-2-5@m right-col">
                  <label class="title">Objectif de livraison</label>
                  <div class="container">
                    <div class="duration">
                      <span class="open-modal-date">{{ $dayjs(project.estimatedCloseDate).format('DD MMMM YYYY') }}</span>
                    </div>
                  </div>
                </div>
              </vk-grid>
            </div>
          </div>
          <div>
            <label>Je valide les informations ci-dessus pour enregistrer la livraison du chantier.</label>
            <div class="column uk-width-auto@m">
              <input
                class="uk-checkbox"
                :name="`confirmed-start-date`"
                type="checkbox"
                v-model="closeDateIsConfirmed"/>
            </div>
            <div class="column uk-width-expand@m">
            </div>
          </div>
        </div>
        <vk-button
          @click="closeModal"
          class="secondary button uk-margin-small-right">Annuler</vk-button>
        <vk-button
          @click="saveData(currentProjectStep), closeModal"
          type="primary"
          :disabled="currentProjectStep && !currentProjectStep.startedAt"
          class="primary button">Valider la date</vk-button>
        <vk-button
          @click="closeProject()"
          type="primary"
          :disabled="currentProjectStep && !currentProjectStep.startedAt || !closeDateIsConfirmed"
          class="primary button">Enregistrer la livraison du chantier</vk-button>
        </div>
      </vk-modal>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';
import projectStep from '@/services/api/projectStep';
import project from '@/services/api/project';
import DropdownActions from '@/components/general/DropdownActions.vue';
import types from '@/services/data/types.json';
import memberApi from '@/services/api/member';
import companyApi from '@/services/api/company';

export default {
  name: 'project-step-modal',
  components: {
    Datepicker,
    DropdownActions,
  },
  props: [
    'project',
    'projectStep',
    'projectSteps',
    'companies',
    'companyCategories',
    'modalType',
    'listName',
    'openModal',
    'dropdownPosition',
  ],
  data() {
    return {
      french: fr,
      startDateIsConfirmed: false,
      closeDateIsConfirmed: false,

      dropdownContent: [],
      filteredCompanies: [],
      selectedCompanyCategory: null,
      selectedCompanyId: null,
      selectedCompany: null,
      currentProjectStep: null,
      delayInWeeks: null,
      show: false,
      types,
      buildTypes: {
        DEFAULT: 'Tâche classique',
        EVENT: 'Événement',
      },
      member: null,
    };
  },
  beforeDestroy() {
    this.closeModal();
    window.removeEventListener('click', this.closeModal);
  },
  watch: {
    modalType: 'loadData',
    openModal: 'loadData',
  },
  created() {
    setTimeout(() => {
      window.addEventListener('click', this.closeModal);
    }, 1000);
  },
  mounted() {
    this.getMe();
    this.loadData();
  },
  methods: {
    valueChanged() {
      this.currentProjectStep.confirmed = false;
    },
    async loadData() {
      if (this.openModal) {
        this.currentProjectStep = { ...this.projectStep };
        this.selectedCompanyCategory = this.projectStep.companyCategory.companyCategoryId;

        this.selectedCompanyId = this.currentProjectStep.company.companyId;
        this.selectedCompany = await companyApi.getById(this.selectedCompanyId);
        this.setFilteredCompanies();
      }
      this.loadDropdownContent();
    },
    loadDropdownContent() {
      if (this.listName === 'tasks') {
        this.dropdownContent = [
          {
            title: 'Éditer la tâche',
            callback: this.showModify,
          },
        ];
      } else {
        this.dropdownContent = [
          {
            title: 'Insérer une nouvelle tâche au-dessus',
            callback: this.addBefore,
          },
          {
            title: 'Insérer une nouvelle tâche en-dessous',
            callback: this.addAfter,
          },
          {
            title: 'Éditer la tâche',
            callback: this.showModify,
          },
          {
            title: 'Supprimer la tâche',
            callback: this.showConfirmDelete,
          },
          {
            title: 'Décaler les tâches suivantes',
            callback: this.showDelayFollowingProjectSteps,
          },
        ];
      }

      if (this.projectStep && this.projectStep.company.companyId) {
        this.dropdownContent.push({
          title: 'Fiche partenaire',
          callback: this.showCompany,
        });
      }
    },
    getDayjsObject(date) {
      return this.$dayjs(date).utc().startOf('day');
    },
    async startProject() {
      try {
        await project.start(this.currentProjectStep.startedAt, this.currentProjectStep.project.projectId);
        this.$emit('update');
        this.$emit('close');
      } catch (er) {
        //
      }
    },
    async closeProject() {
      try {
        await project.close(this.currentProjectStep.startedAt, this.currentProjectStep.project.projectId);
        this.$emit('update');
        this.$emit('close');
      } catch (er) {
        //
      }
    },
    getStepDuration() {
      return this.getDayjsObject(this.currentProjectStep.closedAt).diff(this.getDayjsObject(this.currentProjectStep.startedAt), 'day') + 1;
    },
    setFilteredCompanies() {
      /* Filtre les companies à partir de la valeur de companyCategoryId */
      if (this.member.isBrandAdmin) {
        this.filteredCompanies = this.companies.filter((c) => (c.companyCategories.find((cat) => cat === this.selectedCompanyCategory)));
      }
      if (this.member.isAgencyAdmin) {
        this.filteredCompanies = this.companies.filter((c) => (c.companyCategories.find((cat) => cat === this.selectedCompanyCategory)) && (c.isAgencyRegistered));
      }
      if (this.selectedCompanyId) {
        const matchingCompany = this.filteredCompanies.find((company) => company.companyId === this.selectedCompanyId);
        this.selectedCompanyId = matchingCompany ? this.selectedCompanyId : null;
      }
    },
    getTimeSpent() {
      let timespent = 0;
      if (this.currentProjectStep.startedAt && this.currentProjectStep.status === 'IN_PROGRESS') {
        timespent = this.getDayjsObject(new Date()).utc().diff(this.getDayjsObject(this.currentProjectStep.startedAt), 'day') + 1;
      }
      if (this.currentProjectStep.closedAt && this.currentProjectStep.status === 'DONE') {
        timespent = this.getStepDuration();
      }

      return timespent;
    },
    async getMe() {
      try {
        this.member = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async saveData() {
      if (this.$dayjs(this.currentProjectStep.closedAt).isBefore(this.currentProjectStep.startedAt)) {
        this.currentProjectStep.closedAt = this.currentProjectStep.startedAt;
      }
      if (this.currentProjectStep.startedAt
        && (this.currentProjectStep.type === 'START' || this.currentProjectStep.type === 'CLOSE' || this.currentProjectStep.type === 'EVENT')) {
        this.currentProjectStep.closedAt = this.currentProjectStep.startedAt;
      }

      // Si confirmed est à true, on set la valeur confirmedAt à la date actuelle
      if (this.currentProjectStep.confirmed) {
        this.currentProjectStep.confirmedAt = this.$dayjs().utc();
      } else {
        this.currentProjectStep.confirmedAt = null;
      }

      await projectStep.edit({
        projectStepId: this.currentProjectStep.projectStepId,
        projectId: this.currentProjectStep.project.projectId,
        companyId: this.currentProjectStep.type === 'EVENT' ? null : this.selectedCompanyId,
        companyCategoryId: this.currentProjectStep.type === 'EVENT' ? null : this.selectedCompanyCategory,
        label: this.currentProjectStep.label,
        rank: this.currentProjectStep.rank,
        startedAt: this.currentProjectStep.startedAt,
        closedAt: this.currentProjectStep.closedAt,
        status: this.currentProjectStep.status,
        type: this.currentProjectStep.type,
        confirmed: this.currentProjectStep.confirmed,
        confirmedAt: this.currentProjectStep.confirmedAt,
        showToConfirm: this.currentProjectStep.showToConfirm,
        comment: this.currentProjectStep.comment,
        isPublicComment: this.currentProjectStep.isPublicComment,
      });

      this.$emit('close');
      this.$emit('update');
    },
    changeStartStatus() {
      if (this.currentProjectStep.status === 'TODO') {
        this.currentProjectStep.status = 'IN_PROGRESS';
        if (this.currentProjectStep.type === 'EVENT') {
          this.changeCloseStatus();
        }
      } else {
        this.currentProjectStep.status = 'TODO';
      }
    },
    changeCloseStatus() {
      if (this.currentProjectStep.status === 'IN_PROGRESS' || this.currentProjectStep.status === 'TODO') {
        this.currentProjectStep.confirmed = true;
        this.currentProjectStep.status = 'DONE';
      } else {
        this.currentProjectStep.status = 'IN_PROGRESS';
      }
    },
    closeModal(event) {
      if (this.openModal && event && !this.$el.contains(event.target)) {
        this.$emit('close');
      }
    },
    isInteger(event) {
      /* Vérifie que le charactère est bien un chiffre */
      const { key } = event;
      if (key === '.' || key === ',') {
        event.preventDefault();
        return false;
      }

      return true;
    },
    async add(rank) {
      this.currentProjectStep.label = 'Tâche vide';
      this.currentProjectStep.startedAt = null;
      this.currentProjectStep.closedAt = null;

      if (rank) {
        this.currentProjectStep.rank = rank;
      }

      /* Faire les mises à jour des tâches */
      await projectStep.add(
        {
          projectId: this.currentProjectStep.project.projectId,
          label: 'Tâche vide',
          rank: this.currentProjectStep.rank,
          startedAt: this.currentProjectStep.closedAt,
          closedAt: this.$dayjs(this.currentProjectStep.closedAt).add(3, 'days'),
        },
        this.projectSteps,
      );
      this.$emit('close');
      this.$emit('update');
    },
    addBefore() {
      this.add();
    },
    addAfter() {
      this.add(this.currentProjectStep.rank + 1);
    },
    changeType(type) {
      this.$emit('changeType', type);
    },
    showModify() {
      this.changeType('edit');
    },
    showCompany() {
      this.$router.push({ name: 'company-companyId-read', params: { companyId: this.projectStep.company.companyId } });
    },
    showConfirmDelete() {
      this.changeType('delete');
    },
    async deleteProjectStep() {
      await projectStep.remove(this.currentProjectStep);

      this.$emit('close');
      this.$emit('update');
    },
    showDelayFollowingProjectSteps() {
      this.changeType('delay');
    },
    async delayFollowingProjectSteps() {
      this.isLoading = true;

      /* Faire les mises à jour des tâches */
      await projectStep.delayFollowingProjectSteps(
        {
          projectStepId: this.currentProjectStep.projectStepId,
          projectId: this.currentProjectStep.project.projectId,
          companyId: this.type === 'EVENT' ? null : this.selectedCompanyId,
          companyCategoryId: this.type === 'EVENT' ? null : this.selectedCompanyCategory,
          label: this.currentProjectStep.label,
          rank: this.currentProjectStep.rank,
          startedAt: this.currentProjectStep.startedAt,
          closedAt: this.currentProjectStep.closedAt,
          status: this.currentProjectStep.status,
          type: this.currentProjectStep.type,
          confirmed: this.currentProjectStep.confirmed,
        },
        this.projectSteps,
        this.delayInWeeks,
      );

      this.$emit('close');
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss">
  .project-steps-modal .uk-modal-dialog {
    width: 700px;
    min-width: 850px;
  }
  .project-steps-modal .uk-modal-dialog {
    width: 700px;
    min-width: 850px;
  }
.project-steps-modal, .delete-modal, .delay-modal {
  .confimation {
    width: 100%;
    .label {
      padding-left: 20px;
    }
  }
    .uk-modal-title {
    margin-bottom: 0;
    background-color: $white;
    small {
      display: block;
    }
  }
  .modal-body {
    margin-top: 10px;
  }
  .button {
    margin: 20px;
    margin-bottom: 20px!important;
  }
  button.primary {
    color: #ffffff;
    border: none;
    border-radius: 30px;
    background-color: $blue-light;
  }
  button:disabled {
    cursor: initial;
    background-color: $gray-light;
  }
  button.secondary {
    border: none;
    border-radius: 30px;
  }
  .content {
    background: #f3f4fa;
    padding: 15px 2rem !important;
    &.notes {
      display: flex;
      flex-direction: column;
    }
    &.export {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        margin: auto 0 auto 10px;
        color: $gray-regular;
        span {
          display: block;
          color: $gray-deep;
          font-weight: 600;
        }
      }
    }
    &.no-bottom {
      margin-bottom: 0;
      padding-bottom: 0!important;
    }
    strong.title {
      padding-bottom: 20px;
      display: block;
    }
    .duration {
      border: 1px solid $gray-light;
      border-radius: 5px;
      padding: 10px;
      text-align: left;
      align-self: baseline;
      font-weight: 600;
    }
    &.date {
      .title {
        text-align: left;
        width: 100%;
      }
      .container {
        width: 100%;
        &.top-input {
          margin-bottom: 20px;
        }
      }
      .vdp-datepicker__calendar header span {
        padding: 0.8rem 1rem;
      }
    }
  }
}
  .switch-with-label {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    align-items: center;
    height: 40px;
    margin-top: 18px;
    padding: 0 10px;
    border-radius: 6px;

    > * {
      padding: 0!important;
    }
  }
</style>
