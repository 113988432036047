<template>
  <div class="style hedifys-21">
    <form ref="update-style" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="style && style.name && style.styleId"
            :items="[
              { route: { name: 'styles'}, name: 'Styles'},
              { route: { name: 'styles-styleId', params: { packId: $route.params.styleId }}, name: style.name}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <div v-if="!isLoading">
            <h1 v-if="$route.params.styleId">{{ style.name }}</h1>
            <h1 v-else>Ajouter un style</h1>
          </div>
        </div>
        <div class="cell shrink" v-if="$route.params.styleId">
          <app-button theme="warning" size="large" @click="openDeleteModal" :disabled="isLoading">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit" :disabled="isLoading">{{ $route.params.styleId ? 'Sauvegarder' : 'Ajouter' }}</app-button>
        </div>
      </div>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <h3>Informations générales</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="style.name" label="Nom du style" placeholder="Nom du style..." required />
            </div>
            <div class="cell auto"></div>
          </div>
        </div>
        <div class="row">
          <PostalCode
            :isLoading="isImportLoading"
            :postalCodes="filteredStylePostalCodes"
            :borders="borders"
            :showInvalidFileMessage="showInvalidFileMessage"
            :showInvalidPostalCodeMessage="showInvalidPostalCodeMessage"
            @getPostalCodes="searchPostalCodes"
            @importPostalCodes="importPostalCodes"
            @addPostalCode="addPostalCode"
            @deletePostalCode="deletePostalCode"
            @hideInvalidPostalCodeMessage="showInvalidPostalCodeMessage = false"
            @hideInvalidFileMessage="showInvalidFileMessage = false"
            :invalidPostalCodes="invalidPostalCodes"
          />
        </div>
        <div class="card">
          <h3>Options du style</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <p>Ces options seront incluses dans le style des versions.</p>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell shrink">
              <app-search size="small" v-model="searchOptions" placeholder="Rechercher" />
            </div>
            <!-- FILTER MODAL -->
            <div class="cell shrink">
              <filter-modal
                @change="filterOptions"
                @reset="resetFilter"
                @close="closeFilterModal"
                :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
                class="filter-btn"
              >
                <div class="grid-x grid-margin-x row">
                  <div class="cell">
                    <app-radio-button
                      label="Trier par:"
                      v-model="filters.orderBy"
                      :options="[
                        { name: 'included', label: 'Inclus dans le style' },
                        { name: '-included', label: 'Non inclus' }
                      ]"
                    />
                  </div>
                </div>
              </filter-modal>
            </div>
          </div>
          <div class="options grid-x row grid-margin-x">
            <div class="cell auto">
              <app-table :headers="tableHeaders" :data="selectedOptions" :loading="isLoading" small>
                <template slot="loading">
                  <app-spinner />
                </template>
                <template slot="empty-table">
                  <p>Aucune données de disponible</p>
                </template>
                <template slot="name" slot-scope="{ data }">
                  <strong>{{ data.name }}</strong>
                </template>
                <template slot="theme" slot-scope="{ data }">
                  <p>{{ data.theme ? data.theme.name : null }}</p>
                </template>
                <template slot="lot" slot-scope="{ data }">
                  <p>{{ data.lot ? data.lot.name : null }}</p>
                </template>
                <template slot="category" slot-scope="{ data }">
                  <p>{{ data.category ? data.category.name : null }}</p>
                </template>
                <template slot="isIncluded" slot-scope="{ data }">
                  <app-checkbox
                    v-model="styleOptions"
                    :value="data.optionId"
                  />
                </template>
              </app-table>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import * as Papa from 'papaparse';

import styleApi from '@/services/api/style';
import postalCodeApi from '@/services/api/postalCode';
import optionApi from '@/services/api/option';
import utils from '@/services/utils/utils';
import PostalCode from '@/components/general/PostalCode.vue';
import FilterModal from '@/components/general/FilterModal.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

export default {
  // style est un mot réservé en html, donc ça ne pouvait pas être l'id du composant
  name: 'update-style',
  components: {
    PostalCode,
    FilterModal,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.style.name ? `${this.style.name} – Style` : 'Style',
    };
  },
  data() {
    return {
      style: {
        name: null,
      },
      isLoading: null,
      stylePostalCodes: [],
      filteredStylePostalCodes: [],
      borders: {
        type: 'FeatureCollection',
        name: 'borders',
        features: [],
      },
      tableHeaders: [
        { label: 'Option', key: 'name', size: 4 },
        { label: 'Thème', key: 'theme', size: 3 },
        { label: 'Lot', key: 'lot', size: 2 },
        { label: 'Catégorie', key: 'category', size: 2 },
        { label: 'Inclus dans le style', key: 'isIncluded', size: 1 },
      ],
      options: null,
      selectedOptions: null,
      styleOptions: null,
      showInvalidFileMessage: false,
      showInvalidPostalCodeMessage: false,
      invalidPostalCodes: null,

      // Filters
      filters: {
        orderBy: null,
      },
      searchOptions: null,
      isImportLoading: false,
    };
  },
  watch: {
    searchOptions() {
      this.debouncedUpdateSearchQuery();
    },
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.filterOptions, 500);
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getStyle();
      await this.getPostalCodes();
      this.filteredStylePostalCodes = this.stylePostalCodes;
      this.updateBorders();
      await this.getOptions();
      this.isLoading = false;
    },
    async filterOptions() {
      if (this.searchOptions && this.searchOptions.length) {
        this.selectedOptions = this.options.filter((o) => o.name && o.name.toLowerCase().includes(this.searchOptions.toLowerCase()));
      } else {
        this.selectedOptions = this.options;
      }
      if (this.filters.orderBy === 'included') {
        const included = this.selectedOptions.filter((o) => this.styleOptions.find((match) => match === o.optionId));
        const notIncluded = this.selectedOptions.filter((o) => !this.styleOptions.find((match) => match === o.optionId));
        this.selectedOptions = included.concat(notIncluded);
      } else if (this.filters.orderBy === '-included') {
        const included = this.selectedOptions.filter((o) => this.styleOptions.find((match) => match === o.optionId));
        const notIncluded = this.selectedOptions.filter((o) => !this.styleOptions.find((match) => match === o.optionId));
        this.selectedOptions = notIncluded.concat(included);
      }
    },
    async resetFilter() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        orderBy: null,
      };
      this.search = null;

      this.selectedOptions = this.options;
    },
    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.orderBy = this.$route.query.orderBy || null;
    },
    async getOptions() {
      try {
        this.options = await optionApi.getAll(null, null, null, null, null, true);
        this.selectedOptions = this.options;
        if (this.$route.params.styleId) {
          const stylOptions = await optionApi.getByStyle(this.$route.params.styleId);
          this.styleOptions = stylOptions.map((o) => o.optionId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des options',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getStyle() {
      try {
        if (this.$route.params.styleId) {
          this.style = await styleApi.getById(this.$route.params.styleId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du style',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getPostalCodes(search) {
      try {
        if (this.$route.params.styleId) {
          this.stylePostalCodes = await postalCodeApi.getByStyle(this.$route.params.styleId, search);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des codes postaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    searchPostalCodes(search) {
      this.filteredStylePostalCodes = this.stylePostalCodes.filter((postalCode) => postalCode.postalCodeInseeNumber.includes(search) || postalCode.city.includes(search) || !search);
      this.updateBorders();
    },
    updateBorders() {
      // Récupération des frontières pour les inclure dans l'objet 'borders' si il y a
      if (this.filteredStylePostalCodes.length > 0) {
        this.borders.features = this.filteredStylePostalCodes.map((cp) => cp.borders);
      } else {
        this.borders.features = [];
      }
    },
    importPostalCodes(file, search) {
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file[0]);

        reader.onload = (event) => {
          const csv = event.target.result;
          Papa.parse(csv, {
            complete: async (results) => {
              this.isImportLoading = true;
              const newPostalCodes = results.data;
              newPostalCodes.splice(0, 1);

              this.invalidPostalCodes = [];
              let parsedPostalCodes = newPostalCodes.map((npc) => (npc && npc.length ? npc[0] : null));

              // remove duplicates
              parsedPostalCodes = [...new Set(parsedPostalCodes)];
              const postalCodePromises = parsedPostalCodes.map((ppc) => this.addPostalCode(ppc, search, true));
              await Promise.all(postalCodePromises);

              if (this.invalidPostalCodes && this.invalidPostalCodes.length) {
                this.showInvalidFileMessage = true;
              }
              this.updateBorders();
              this.isImportLoading = false;
            },
          });
        };
        reader.onerror = () => {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de l\'envoi du fichier',
            cancelText: 'Ok',
            hasCancel: true,
          });
        };
      }
    },
    async addPostalCode(pc, search, isImported) {
      if (!isImported) {
        this.invalidPostalCodes = [];
      }
      try {
        await this.matchPostalCodes(pc, search);
      } catch (er) {
        this.invalidPostalCodes.push(pc);
      }

      if (!isImported && this.invalidPostalCodes && this.invalidPostalCodes.length) {
        this.showInvalidPostalCodeMessage = true;
      }

      this.updateBorders();
    },
    async matchPostalCodes(selectedPostalCode, search) {
      if (selectedPostalCode && selectedPostalCode.length) {
        const newPostalCodes = await postalCodeApi.getAll(selectedPostalCode);
        if (newPostalCodes && newPostalCodes.length) {
          newPostalCodes.forEach((pc) => {
            if (!this.stylePostalCodes.find((spc) => spc.postalCodeInseeNumber === pc.postalCodeInseeNumber)) {
              this.stylePostalCodes.push(pc);
            }
          });
        } else {
          this.invalidPostalCodes.push(selectedPostalCode);
        }
      }
      this.searchPostalCodes(search);
    },
    async deletePostalCode(postalCodeInseeNumber, search) {
      const postalCodeIndex = this.stylePostalCodes.findIndex((spc) => spc.postalCodeInseeNumber === postalCodeInseeNumber);
      this.stylePostalCodes.splice(postalCodeIndex, 1);
      this.searchPostalCodes(search);
    },
    async save() {
      this.isLoading = true;
      try {
        const parsedPostalCodes = this.stylePostalCodes.map((spc) => spc.postalCodeInseeNumber);

        if (this.$route.params.styleId) {
          await styleApi.update(this.style.styleId, {
            name: this.style.name,
            postalCodesInseeNumbers: parsedPostalCodes,
          });

          await optionApi.updateStyle(this.style.styleId, {
            options: this.styleOptions,
          });
        } else {
          const style = await styleApi.create({
            name: this.style.name,
            postalCodesInseeNumbers: parsedPostalCodes,
          });

          await optionApi.updateStyle(style.styleId, {
            options: this.styleOptions,
          });

          this.$router.push({ name: 'styles-styleId', params: { styleId: style.styleId } });
        }

        await this.getPostalCodes();
        this.filteredStylePostalCodes = this.stylePostalCodes;
        this.updateBorders();
        await this.getOptions();
        await this.getStyle();
        this.resetFilter();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde du style',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer le style',
        text: 'Êtes vous sûr de vouloir supprimer ce style ?',
        confirmText: 'Supprimer le style',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteStyle();
        },
      });
    },
    async deleteStyle() {
      if (this.$route.params.styleId) {
        this.isLoading = true;
        try {
          await styleApi.delete(this.$route.params.styleId);
          this.$notification.show({ text: 'Ce style a été supprimé avec succès !' });

          this.$router.push({ name: 'styles' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression du style',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.style
  .body
    @include screen
    .options
      min-height: 200px
      .data
        flex: auto
  form > .header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
</style>
