<template>
  <div class="land-map" :style="{height: `${height}px`}">
    <div class="map-container">
      <div class="map-loader">
        <app-spinner v-if="mapIsLoading"/>
        <p>Chargement de la carte...</p>
      </div>
      <div :class="{'map-hidden': mapIsLoading}" id="map"/>
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';

export default {
  name: 'land-map',

  props: {
    lands: {
      type: Array,
    },

    height: {
      type: Number,
    },
  },

  data() {
    return {
      map: null,
      mapIsLoading: true,
      initialMapSettings: {
        center: [2.7195924, 46.9259861],
        zoom: 4.5,
      },

      mapFeatures: [],

      isFirstLoad: true,
    };
  },

  watch: {
    lands: {
      handler() {
        this.setMapFeatures();
      },
      deep: true,
    },
  },

  mounted() {
    this.setMap();
  },

  methods: {
    // Setup de la carte
    setMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: process.env.VUE_APP_MAPBOX_STYLE_URL,
        center: this.initialMapSettings.center,
        zoom: this.initialMapSettings.zoom,
        dragPan: false,
        scrollZoom: false,
        doubleClickZoom: false,
      });

      this.loadLands();
    },

    // Chargement de la carte
    loadLands() {
      this.map.on('load', () => {
        this.mapIsLoading = false;

        // On appelle la création des layers si les terrains existent
        this.setLayers();
        this.setMapFeatures();
      });
    },

    // Création des layers pour chaque terrain
    setLayers() {
      const mapSource = this.map.getSource('lands');

      if (!mapSource) {
        // Ajout des sources
        this.map.addSource('lands', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: this.mapFeatures,
          },
        });

        // Création du layer de la surface pour chaque terrain
        this.map.addLayer({
          id: 'point',
          type: 'circle',
          source: 'lands',
          layout: {},
          paint: {
            'circle-radius': 15,
            'circle-color': 'rgba(54, 141, 247, .3)',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#368DF7',
          },
        });
      } else {
        mapSource.setData({
          type: 'FeatureCollection',
          features: this.mapFeatures,
        });
      }

      // On change le curseur lorsqu'on survole un terrain
      this.map.on('mouseenter', 'point', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      // On remet le curseur initial lorsqu'on ne survole plus un terrain
      this.map.on('mouseleave', 'point', () => {
        this.map.getCanvas().style.cursor = '';
      });

      // On redirige vers la page d'édition du terrain lorsqu'on clic sur son marqueur
      this.map.on('click', 'point', (event) => {
        if (!this.$route.params.landId) {
          this.$router.push({
            name: 'lands-landId',
            params: { landId: event.features[0].properties.landId },
          });
        }
      });

      // On centre la carte sur un terrain
      if (this.mapFeatures.length === 1) {
        this.map.flyTo({
          center: [
            this.mapFeatures[0].geometry.coordinates[0],
            this.mapFeatures[0].geometry.coordinates[1],
          ],
          zoom: 10,
        });

        this.isFirstLoad = false;
      }

      // On centre la carte sur l'ensemble des terrains
      if (this.mapFeatures.length > 1) {
        const coordinates = [];

        this.mapFeatures.forEach((ft) => {
          coordinates.push(ft.geometry.coordinates);
        });

        const line = turf.lineString(coordinates);
        const bbox = turf.bbox(line);

        this.map.fitBounds(bbox, { padding: 40, duration: 300 });

        this.isFirstLoad = false;
      }
      if (!this.mapFeatures.length) {
        this.isFirstLoad = false;
      }
    },

    // Création de l'objet feature pour les sources de point
    setMapFeatures() {
      if (this.lands.length >= 1 && !this.lands[0].lat && this.lands[0].coordinates) {
        this.mapFeatures = this.lands.map((land) => ({
          type: 'Feature',
          properties: {
            landId: land.landId,
          },
          geometry: {
            type: 'Point',
            coordinates: [land.coordinates.long, land.coordinates.lat],
          },
        }));

        // On met à jour les terrains sur la map si il ne s'agit pas du 1er chargement
        if (!this.isFirstLoad) {
          this.setLayers();
        }
      } else if (this.lands.length === 1 && this.lands[0].lat && this.lands[0].long) {
        this.mapFeatures = [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [this.lands[0].long, this.lands[0].lat],
            },
          },
        ];

        if (!this.isFirstLoad) {
          this.setLayers();
        }
      } else {
        this.mapFeatures = [];
        if (!this.isFirstLoad) {
          this.setLayers();
        }
      }
    },
  },
};
</script>

<style lang="sass">
.land-map
  background-color: $off-white
  box-shadow: $drop-min
  border-radius: $global-border-radius
  height: 100%
  .map-container
    position: relative
    display: flex
    align-items: center
    height: 100%
  .map-loader
    margin: auto
    p
      padding-top: 20px
      color: $info
  .map-hidden
    visibility: hidden

  #map
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    canvas
      width: 100%
      border-radius: $global-border-radius
    .mapboxgl-control-container
      display: none
    .mapboxgl-canvas
      cursor: default
</style>
