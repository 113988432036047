<!--
****************************************************************************************************
***                                      Collapse                                                ***
****************************************************************************************************

Collapse

*************
*** PROPS ***
*************

  Exemple :
  <app-collapse v-model="activeTab" :name="1">
    Lorem Ipsum
  </app-collapse>

  <app-collapse v-model="activeTab" :name="2">
    Lorem Ipsum
  </app-collapse>

  <app-collapse v-model="activeTab" :name="3">
    Lorem Ipsum
  </app-collapse>

-->
<template>
  <div class="app-collapse">
    <div class="app-collapse-content" ref="collapse"
      :style="{ height: `${scrollHeight}px` }">
      <div><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Number, Array],
    name: [String],
  },
  data() {
    return {
      scrollHeight: 0,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    input: 'init',
  },
  methods: {
    // $refs n'est pas réactive
    init() {
      const { scrollHeight } = this.$refs.collapse;

      if (Array.isArray(this.input)) {
        this.scrollHeight = this.input.includes(this.name) ? scrollHeight : 0;
      } else {
        this.scrollHeight = (this.input === this.name) ? scrollHeight : 0;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.app-collapse
  .app-collapse-content
    overflow: hidden
    transition: all 0.3s linear
    > div
      padding: 1rem 0
</style>
