<template>
  <div class="app-info">
    <info />
    <p>
      <slot />
    </p>
  </div>
</template>

<script>
import info from '@/assets/img/info.svg?inline';

export default {
  components: {
    info,
  },
};
</script>

<style lang="sass" scoped>
.app-info
  background: rgba($primary, 0.2)
  border-left: 2px solid $primary
  display: flex
  flex-direction: row
  align-items: center
  padding: 12px
  circle
    stroke: $primary
  path
    fill: $primary
  p
    color: $primary
    margin-left: 12px
</style>
