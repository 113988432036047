import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des contraintes
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/constraints`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des contraintes avec la condition 'OnlyAllowedVersions'
 */
const getAllWithAllowedVersionsCondition = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/constraints/allowed-versions-condition`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des contraintes avec la condition 'OnlyAllowedVersions' étant appliquées à une version donnée
 * @param {string} versionId
 */
const getWithAllowedVersionsConditionByVersion = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/constraints/allowed-versions-condition/${versionId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une contrainte par son id
 * @param {string} constraintId id de la contrainte
 */
const getById = async (constraintId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/constraints/${constraintId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression de la contrainte
 * @param {string} constraintId: id de la contrainte à récupérer
 */
const deleteOne = async (constraintId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/constraints/${constraintId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Met à jour une contrainte
 * @param {string} constraintId: id de la contrainte à mettre à jour
 * @param {object} data: données de la contrainte
 */
const update = async (constraintId, data) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/constraints/${constraintId}`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'une contrainte
 * @param {object} data: données de la contrainte
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/constraints`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const constraints = {};
constraints.getById = getById;
constraints.create = create;
constraints.getAll = getAll;
constraints.update = update;
constraints.delete = deleteOne;
constraints.getAllWithAllowedVersionsCondition = getAllWithAllowedVersionsCondition;
constraints.getWithAllowedVersionsConditionByVersion = getWithAllowedVersionsConditionByVersion;

export default constraints;
