var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"version-summary hedifys-21"},[_c('form',{ref:"version-constraints",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('div',{staticClass:"breadcrumbs grid-x row"},[_c('div',{staticClass:"cell auto"},[(_vm.version && _vm.version.name)?_c('breadcrumb',{attrs:{"items":[
          { route: { name: 'versions'}, name: 'Versions' },
          { route: { name: 'versions-versionId', params: { versionId: _vm.$route.params.versionId }}, name: _vm.version.reference }
        ]}}):_vm._e()],1)]),_c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v(_vm._s(_vm.version && _vm.version.reference ? _vm.version.reference : ''))])]),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","type":"submit"}},[_vm._v("Sauvegarder")])],1)]),_c('tab-nav',{attrs:{"tabs":_vm.tabs}}),(!_vm.isLoading && _vm.version)?_c('div',{staticClass:"body"},[_vm._m(0),_c('div',{staticClass:"card"},[_c('div',{staticClass:"grid-x row grid-margin-x"},[_c('div',{staticClass:"cell"},[_c('app-search',{attrs:{"size":"auto","placeholder":"Rechercher"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"constraints grid-x row grid-margin-x"},[_c('div',{staticClass:"cell auto"},[_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.filteredConstraints,"loading":_vm.isLoading,"small":""},scopedSlots:_vm._u([{key:"isIncluded",fn:function(ref){
        var data = ref.data;
return [_c('app-checkbox',{attrs:{"value":data.constraintId},model:{value:(_vm.currentSelectedConstraintsIds),callback:function ($$v) {_vm.currentSelectedConstraintsIds=$$v},expression:"currentSelectedConstraintsIds"}})]}},{key:"name",fn:function(ref){
        var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}}],null,false,434348611)},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2)],1)])])]):_c('div',{staticClass:"body"},[_c('div',{staticClass:"card spinner-container"},[_c('app-spinner')],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('h2',[_vm._v("Contraintes")]),_c('p',[_vm._v("Seules les contraintes \"Sélection Version\" sont présentes dans la liste.")])])}]

export { render, staticRenderFns }