<template>
  <div class="lots-surveys hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Lots métrés</h1>
      </div>
    </div>
    <!-- BODY / LISTE DES LOTS MÉTRÉS -->
    <div class="body">
      <div class="search grid-x">
        <div class="cell shrink search">
          <app-search size="small" v-model="search" placeholder="Rechercher un lot métré" />
        </div>
        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterLotsSurveys"
            @reset="resetFilers"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Catégorie"
                  v-model="filters.categoryId"
                  :options="categoryOptions"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Lot"
                  v-model="filters.lotId"
                  :options="lotOptions"
                  :disabled="lotOptions.length === 0"
                />
              </div>
            </div>
          </filter-modal>
        </div>
      </div>
      <app-table :headers="tableHeaders" :data="lotsSurveys" :loading="isLoading">
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="lot" slot-scope="{ data }">
          <p>{{ data.lot.name }}</p>
        </template>
        <template slot="category" slot-scope="{ data }">
          <p>{{ data.category.name }}</p>
        </template>
        <template slot="key" slot-scope="{ data }">
          <p>{{ data.key }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY')  }}</p>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import FilterModal from '@/components/general/FilterModal.vue';
import utils from '@/services/utils/utils';
import lot from '@/services/api/lot';
import category from '@/services/api/category';
import lotSurvey from '@/services/api/lotSurvey';

export default {
  name: 'lots-surveys',
  components: {
    FilterModal,
  },
  metaInfo: {
    title: 'Lots métrés',
  },
  data() {
    return {
      tableHeaders: [
        { label: 'Nom', key: 'name', size: 3 },
        { label: 'Lot', key: 'lot', size: 2 },
        { label: 'Catégorie', key: 'category', size: 2 },
        { label: 'Clé', key: 'key', size: 3 },
        { label: 'Créé le', key: 'createdAt', size: 2 },
      ],
      lots: [],
      lotsSurveys: [],
      lotSurvey: {
        name: null,
        key: null,
        lotName: null,
        categoryName: null,
      },
      categories: [],
      isLoading: true,
      search: null,
      filters: {
        categoryId: null,
        lotId: null,
      },
      isCreateModalOpen: null,
      categoryOptions: [],
      lotOptions: [],
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },

  mounted() {
    this.search = this.$route.query.search;
    this.filters.categoryId = this.$route.query.categoryId || null;
    this.filters.lotId = this.$route.query.lotId || null;
    this.getData();
  },

  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },

    filters: {
      handler(value) {
        this.filters.categoryId = value.categoryId;
        this.filters.lotId = value.lotId;
      },
      deep: true,
    },

    'filters.categoryId': {
      handler() {
        this.lotOptions = this.getLotOptions();
      },
    },
  },
  methods: {
    async getData() {
      await this.getLotsSurveys();
      await this.getLots();
      await this.getCategories();
      this.categoryOptions = utils.formatOptions(
        this.categories,
        (option) => option.categoryId,
        (option) => option.name,
        'choose',
      );
      this.lotOptions = await this.getLotOptions();
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }

      if (!this.isLoading) {
        this.getLotsSurveys();
      }
    },
    closeFilterModal() {
      this.filters.categoryId = this.$route.query.categoryId || null;
      this.filters.lotId = this.$route.query.lotId || null;
    },
    async filterLotsSurveys() {
      this.$router.push({
        query: {
          ...this.$route.query,
          categoryId: this.filters.categoryId || undefined,
          lotId: this.filters.lotId || undefined,
        },
      });
      this.getLotsSurveys();
    },
    async resetFilers() {
      this.filters = {
        categoryId: null,
        lotId: null,
      };
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          categoryId: undefined,
          lotId: undefined,
        },
      });
      this.isLoading = true;
      this.getLotsSurveys();
      this.isLoading = false;
    },
    async getLotsSurveys() {
      this.isLoading = true;
      try {
        this.lotsSurveys = await lotSurvey.getAll(
          this.$route.query.search,
          this.$route.query.categoryId,
          this.$route.query.lotId,
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async getCategories() {
      try {
        this.categories = await category.getAll();
      } catch (error) {
        throw error;
      }
    },
    async getLots() {
      try {
        this.lots = await lot.getAll(this.filters.categoryId);
      } catch (error) {
        throw error;
      }
    },
    // Formate la liste des lots pour le composant app-select
    getLotOptions() {
      if (this.filters.categoryId) {
        return utils.formatOptions(
          this.lots.filter((lotOption) => (lotOption.categoryId === this.filters.categoryId)),
          (option) => option.lotId,
          (option) => option.name,
          'choose',
        );
      }

      return utils.formatOptions(
        this.lots,
        (option) => option.lotId,
        (option) => option.name,
        'choose',
      );
    },
  },
};
</script>

<style lang='sass' scoped>
.lots-surveys
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
  .filter-container
    margin-left: auto
</style>
