<template>
  <div class="hedifys-21 home grid-y" v-if="brand">
    <div class="presentation cell shrink">
      <hedifys class="hedifys-logo" />
    </div>
    <edito class="cell shrink"
      v-if="edito && edito.announcementTitle && edito.announcementDescription && edito.announcementImage" :data="edito" />
    <div class="navigation grid-x cell shrink">
      <router-link class="cell shrink nav-item" :to="{ name: 'agencies' }">
        <div>
          <suitcase class="image fill" />
          <div class="title">Agences</div>
        </div>
      </router-link>
      <router-link class="cell shrink nav-item" :to="{ name: 'agenda' }">
        <div>
          <calendar class="image fill" />
          <div class="title">Agenda</div>
        </div>
      </router-link>
      <router-link class="cell shrink nav-item" :to="{ name: 'customers-unqualified' }">
        <div>
          <wallet class="image fill" />
          <div class="title">Contacts</div>
        </div>
      </router-link>
      <router-link class="cell shrink nav-item" :to="{ name: 'companies' }">
        <div>
          <handshake class="image fill" />
          <div class="title">Partenaires</div>
        </div>
      </router-link>
      <router-link class="cell shrink nav-item" :to="{ name: 'lands' }">
        <div>
          <layer class="image fill" />
          <div class="title">Terrains</div>
        </div>
      </router-link>
      <router-link class="cell shrink nav-item" :to="{ name: 'projects' }">
        <div>
          <worker class="image fill" />
          <div class="title">Chantiers</div>
        </div>
      </router-link>
      <router-link v-if="isBrandAdmin" class="cell shrink nav-item" :to="{ name: 'versions' }">
        <div>
          <house class="image fill" />
          <div class="title">Catalogue</div>
        </div>
      </router-link>
      <router-link v-if="isBrandAdmin" class="cell shrink nav-item" :to="{ name: 'categories' }">
        <div>
          <architecture class="image fill" />
          <div class="title">Structure</div>
        </div>
      </router-link>
      <router-link v-if="isBrandAdmin" class="cell shrink nav-item" :to="{ name: 'styles' }">
        <div>
          <ruler class="image fill" />
          <div class="title">Spécificité</div>
        </div>
      </router-link>
      <router-link v-if="isBrandAdmin" class="cell shrink nav-item" :to="{ name: 'coefficients' }">
        <div>
          <money class="image fill" />
          <div class="title">Financier</div>
        </div>
      </router-link>
      <router-link v-if="isBrandAdmin" class="cell shrink nav-item" :to="{ name: 'brand' }">
        <div>
          <setting class="image fill" />
          <div class="title">Brand</div>
        </div>
      </router-link>
      <router-link class="cell shrink nav-item" :to="{ name: 'diffusions-advertisements' }">
        <div>
          <speaker class="image fill" />
          <div class="title">Diffusion</div>
        </div>
      </router-link>
      <router-link class="cell shrink nav-item" :to="{ name: 'documents-space' }">
        <div>
          <div class="image-container">
            <document class="image fill" />
          </div>
          <div class="title">Espace Docs</div>
        </div>
      </router-link>
      <router-link v-if="isBrandAdmin" class="cell shrink nav-item" :to="{ name: 'indicators' }">
        <div>
          <div class="image-container">
            <statistics class="image fill" />
          </div>
          <div class="title">Indicateurs</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth';
import member from '@/services/api/member';
import wallet from '@/assets/img/nav/wallet.svg?inline';
import suitcase from '@/assets/img/nav/suitcase.svg?inline';
import handshake from '@/assets/img/nav/handshake.svg?inline';
import layer from '@/assets/img/nav/layer.svg?inline';
import worker from '@/assets/img/nav/worker.svg?inline';
import house from '@/assets/img/nav/house.svg?inline';
import architecture from '@/assets/img/nav/architecture.svg?inline';
import ruler from '@/assets/img/nav/ruler.svg?inline';
import money from '@/assets/img/nav/money.svg?inline';
import setting from '@/assets/img/setting.svg?inline';
import speaker from '@/assets/img/nav/speaker.svg?inline';
import calendar from '@/assets/img/nav/calendar.svg?inline';
import document from '@/assets/img/nav/document.svg?inline';
import statistics from '@/assets/img/nav/statistics.svg?inline';
import Edito from '@/components/general/Edito.vue';
import brandApi from '@/services/api/brand';

import hedifys from '@/assets/logos/hedifys.svg?inline';

export default {
  name: 'home',
  components: {
    wallet,
    suitcase,
    handshake,
    layer,
    worker,
    house,
    architecture,
    ruler,
    money,
    setting,
    speaker,
    hedifys,
    calendar,
    document,
    statistics,
    Edito,
  },
  metaInfo: {
    title: 'Accueil',
  },
  data() {
    return {
      brand: null,
      services: [],
      me: null,
      isBrandAdmin: null,
      edito: null,
    };
  },
  async mounted() {
    await this.getBrand();
    await this.getMe();
    await this.getEdito();
  },
  methods: {
    async getMe() {
      try {
        this.me = await member.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getEdito() {
      try {
        this.edito = await brandApi.getEdito(this.brand.brandId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: "Il y a eu un problème lors de la récupération de l'edito",
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.home
  display: flex
  flex-direction: column
  align-items: center
  min-height: 100%
  background-image: linear-gradient($gradient-light, $gradient-dark)
  .hedifys-logo
    margin: 24px
    width: 38px
    path
      fill: white
  .navigation
    padding-top: 50px
    max-width: 1300px
    margin: auto
    display: flex
    justify-content: center
    padding-bottom: 50px
    .nav-item
      background-color: $primary
      padding: 22px 30px
      margin: 12px
      width: 85px
      min-height: 85px
      border-radius: $global-border-radius
      transition: background-color 0.2s
      @include centered-container
      &:hover
        background-color: darken($primary, 2%)
      .cell-container
        display: flex
        flex-direction: column
        align-items: center
      .title
        color: white
        @include heading-md
        text-align: center
        margin-top: 10px
      .image
        width: 32px
        margin: 0 24px
      .image-container
        width: 100%
        display: flex
        justify-content: center

        text-align: center
</style>
