<template>
  <div class="agencies hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Agences</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="isCreateModalOpen = true">Ajouter une agence</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES AGENCES -->
    <div class="body">
      <div class="search grid-x">
        <div class="cell shrink grid-x">
          <app-search size="small" v-model="search" placeholder="Rechercher une agence" />
          <app-button v-if="me && me.isBrandAdmin" size="small" theme="secondary" @click="isAgencyConnectionModalOpen = true">Se connecter à une agence</app-button>
        </div>
        <div class="cell auto"></div>
      </div>
      <app-modal class="create-modal" :show="isAgencyConnectionModalOpen" size="medium" title="Se connecter en &quot;vue agence&quot;" @update:show="isAgencyConnectionModalOpen = false">
        <app-select class="cell" style="margin-bottom: 16px;" size="large" v-model="selectedAgency" label="Nom de l'agence" :options="[{ label: 'Choisir une agence', name: null }].concat(agenciesList)" />
        <app-select class="cell" style="margin-top: 16px;" v-if="selectedAgency !== null" size="large" v-model="selectedMember" label="Membre de l'agence" :options="[{ label: 'Choisir un membre', name: null }].concat(membersList)" />
        <app-select class="cell" style="margin-top: 16px;" v-else size="large" v-model="selectedMember" label="Membre de l'agence" :options="[{ label: 'Choisir un membre', name: null }]" />
        <div class="grid-x grid-margin-x">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-button theme="primary" style="margin-top: 50px;" size="medium" @click="loginAs(selectedMember)" >Se connecter</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
      </app-modal>
      <app-table :headers="tableHeaders" :data="agencies" :loading="isLoading" @line-cliked="goToAgency" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="address" slot-scope="{ data }">
          <p>{{ data.address ? data.address.addressLine1 : null }}</p>
        </template>
        <template slot="city" slot-scope="{ data }">
          <p>{{ data.address ? data.address.city : null }}</p>
        </template>
        <template slot="phone" slot-scope="{ data }">
          <p>{{ data.phone }}</p>
        </template>
        <template slot="secondaryPhone" slot-scope="{ data }">
          <p>{{ data.secondaryPhone }}</p>
        </template>
        <template slot="email" slot-scope="{ data }">
          <p>{{ data.email }}</p>
        </template>
      </app-table>
    </div>
    <app-modal class="create-modal" :show="isCreateModalOpen" size="medium" title="Nouvelle agence" @update:show="isCreateModalOpen = false">
      <form ref="agency" @submit.prevent="createAgency">
        <div class="grid-x grid-margin-x row">
          <div class="cell"><app-input v-model="agency.name" label="Nom de l'agence" placeholder="Nom de l'agence..." required /></div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell"><app-input v-model="agency.address.addressLine1" label="Adresse" placeholder="Adresse ligne 1..."  required /></div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell"><app-input v-model="agency.address.addressLine2"  placeholder="Adresse ligne 2..." label="Adresse line 2 (complément)" /></div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell auto"><app-input v-model="agency.address.city" label="Commune"  placeholder="Commune..." required /></div>
          <div class="cell auto"><app-input v-model="agency.address.postalCode" label="Code postal"  placeholder="Code postal..." required /></div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-input v-model="agency.phone" label="Téléphone 1" placeholder="Téléphone 1..." required />
          </div>
          <div class="cell auto">
            <app-input v-model="agency.email" label="Email" placeholder="Email..." type="email" required />
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" icon="add" type="submit">Créer l'agence</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
      </form>
    </app-modal>
  </div>
</template>

<script>
import agency from '@/services/api/agency';
import utils from '@/services/utils/utils';
import member from '@/services/api/member';
import auth from '@/services/auth';

export default {
  name: 'agencies',
  metaInfo: {
    title: 'Agences',
  },
  data() {
    return {
      me: null,
      selectedAgency: null,
      selectedMember: null,
      agencies: null,
      agenciesList: null,
      membersList: null,
      tableHeaders: [
        { label: 'Nom', key: 'name', size: 2 },
        { label: 'Adresse', key: 'address', size: 2 },
        { label: 'Ville', key: 'city', size: 2 },
        { label: 'Téléphones', key: 'phone', size: 2 },
        { label: '', key: 'secondaryPhone', size: 2 },
        { label: 'Mail', key: 'email', size: 2 },
      ],
      agency: {
        name: null,
        address: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          postalCode: null,
          phone: null,
          email: null,
        },
      },
      isLoading: true,
      search: null,
      isCreateModalOpen: null,
      isAgencyConnectionModalOpen: null,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
    this.getMe();
  },
  mounted() {
    this.search = this.$route.query.search;
    this.getAgencies();
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getAgencies();
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
    selectedAgency() {
      this.updateMembersList();
    },
  },
  methods: {
    async updateMembersList() {
      try {
        this.selectedMember = null;
        if (this.selectedAgency !== null) {
          const members = await member.getByAgency(this.selectedAgency);
          this.membersList = members.map((mb) => ({
            label: mb.firstname,
            name: mb.memberId,
          }));
        } else {
          this.membersList = null;
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des membres de l\'agence sélectionnée',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async loginAs(memberId) {
      try {
        if (memberId === null || this.selectedAgency === null) {
          this.$message.show({
            title: 'Erreur',
            text: 'Veuillez sélectionner une agence et un membre',
            cancelText: 'Ok',
            hasCancel: true,
          });
        } else {
          await auth.loginAs(memberId);
          window.location.reload();
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la connexion à une agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMe() {
      try {
        this.me = await member.getMe();
      } catch (error) {
        throw error;
      }
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    async getAgencies() {
      this.isLoading = true;
      try {
        this.agencies = await agency.getAll(this.$route.query.search);
        this.agenciesList = this.agencies.map((ag) => ({
          label: ag.name,
          name: ag.agencyId,
        }));
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    goToAgency(selectedAgency) {
      this.$router.push({ name: 'agencies-agencyId', params: { agencyId: selectedAgency.agencyId } });
    },
    async createAgency() {
      try {
        await agency.create(this.agency);
        this.$notification.show({ text: 'Cette agence a été ajoutée avec succès !' });

        await this.getAgencies();
        this.isCreateModalOpen = false;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'ajout l\'agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.agencies
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
    button
      margin-left: 1rem
</style>
