<template>
  <div class="pack hedifys-21">
    <form ref="pack" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="pack && pack.name && pack.packId"
            :items="[
              { route: { name: 'categories-packs'}, name: 'Packs'},
              { route: { name: 'categories-packs-packId', params: { packId: $route.params.packId }}, name: pack.name }
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <div v-if="!isLoading">
            <h1 v-if="$route.params.packId">{{ pack.name }}</h1>
            <h1 v-else>Ajouter un pack</h1>
          </div>
        </div>
        <div class="cell shrink" v-if="$route.params.packId">
          <app-button theme="warning" size="large" @click="openDeleteModal">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">{{ $route.params.packId ? 'Sauvegarder' : 'Ajouter' }}</app-button>
        </div>
      </div>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <h3>Informations générales</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="pack.name" label="Nom du pack" placeholder="Nom du pack..." required />
            </div>
            <div class="cell auto">
              <app-label>Gammes :</app-label>
              <div class="grid-x">
                <div class="range cell shrink" v-for="range in ranges" :key="range.rangeId">
                  <app-checkbox
                    v-model="pack.ranges"
                    :value="range.rangeId"
                  >{{ range.name }}</app-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="pack.description" label="Description" placeholder="Description..." />
            </div>
            <div class="cell auto">
            </div>
          </div>
        </div>
        <div class="card">
          <h3>Options du style</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <p>Ces options seront incluses dans le pack pour le configurateur.</p>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell shrink">
              <app-search size="small" v-model="searchOptions" placeholder="Rechercher" />
            </div>
            <!-- FILTER MODAL -->
            <div class="cell shrink">
              <filter-modal
                @change="filterOptions"
                @reset="resetFilter"
                @close="closeFilterModal"
                :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
                class="filter-btn"
              >
                <div class="grid-x grid-margin-x row">
                  <div class="cell">
                    <app-radio-button
                      label="Trier par :"
                      v-model="filters.orderBy"
                      :options="[
                        { name: 'included', label: 'Inclus dans le pack' },
                        { name: '-included', label: 'Non inclus' }
                      ]"
                    />
                  </div>
                </div>
              </filter-modal>
            </div>
          </div>
          <div class="options grid-x row grid-margin-x">
            <div class="cell auto">
              <app-table :headers="tableHeaders" :data="selectedOptions" :loading="isLoading" small>
                <template slot="loading">
                  <app-spinner />
                </template>
                <template slot="empty-table">
                  <p>Aucune données de disponible</p>
                </template>
                <template slot="name" slot-scope="{ data }">
                  <strong>{{ data.name }}</strong>
                </template>
                <template slot="theme" slot-scope="{ data }">
                  <p>{{ data.theme.name }}</p>
                </template>
                <template slot="lot" slot-scope="{ data }">
                  <p>{{ data.lot.name }}</p>
                </template>
                <template slot="category" slot-scope="{ data }">
                  <p>{{ data.category.name }}</p>
                </template>
                <template slot="isIncluded" slot-scope="{ data }">
                  <app-checkbox
                    v-model="includedInPack"
                    :value="data.optionId"
                  />
                </template>
                <template slot="isActiveDefault" slot-scope="{ data }">
                  <app-checkbox
                    v-model="baseOptions"
                    :value="data.optionId"
                    :disabled="!(!!includedInPack.find((el) => el === data.optionId))"
                  />
                </template>
              </app-table>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import packApi from '@/services/api/pack';
import optionApi from '@/services/api/option';
import rangeApi from '@/services/api/range';
import utils from '@/services/utils/utils';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import FilterModal from '@/components/general/FilterModal.vue';

export default {
  name: 'pack',
  components: {
    Breadcrumb,
    FilterModal,
  },
  metaInfo() {
    return {
      title: this.pack.name ? `${this.pack.name} – Pack` : 'Pack',
    };
  },
  data() {
    return {
      pack: {
        name: null,
        description: null,
        ranges: [],
      },
      filters: {
        orderBy: null,
      },
      isLoading: null,
      tableHeaders: [
        { label: 'Option', key: 'name', size: 4 },
        { label: 'Thème', key: 'theme', size: 2 },
        { label: 'Lot', key: 'lot', size: 2 },
        { label: 'Catégorie', key: 'category', size: 2 },
        { label: 'Inclus dans le pack', key: 'isIncluded', size: 1 },
        { label: 'Option de base', key: 'isActiveDefault', size: 1 },
      ],
      options: [],
      selectedOptions: [],
      preSelections: [],
      baseOptions: [],
      includedInPack: [],
      searchOptions: null,
      ranges: [],
    };
  },
  watch: {
    searchOptions() {
      this.debouncedUpdateSearchQuery();
    },
    includedInPack() {
      this.baseOptions.forEach((item, index) => {
        if (this.includedInPack.find((el) => el === item) === undefined) {
          this.baseOptions.splice(index, 1);
        }
      });
    },
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.filterOptions, 500);
  },
  mounted() {
    this.isLoading = true;
    this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      await this.getOptions();
      await this.getPack();
      await this.getRanges();
      this.includedInPack = [];
      this.baseOptions = [];
      if (this.$route.params.packId) {
        this.pack.ranges = this.pack.ranges.map((el) => el.rangeId);
      }
      this.preSelections.forEach((el) => {
        this.includedInPack.push(el.option.optionId);
        if (el.isActiveDefault) {
          this.baseOptions.push(el.option.optionId);
        }
      });
    },
    async getOptions() {
      try {
        this.options = await optionApi.getAll();
        this.selectedOptions = this.options;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des options',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getPack() {
      try {
        if (this.$route.params.packId) {
          this.pack = await packApi.getById(this.$route.params.packId);
          this.preSelections = await packApi.getPreSelections(this.$route.params.packId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du pack',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getRanges() {
      try {
        this.ranges = await rangeApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du pack',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async filterOptions() {
      if (this.searchOptions && this.searchOptions.length) {
        this.selectedOptions = this.options.filter((o) => o.name && o.name.toLowerCase().includes(this.searchOptions.toLowerCase()));
      } else {
        this.selectedOptions = this.options;
      }

      if (this.filters.orderBy === 'included') {
        const included = this.selectedOptions.filter((o) => this.includedInPack.find((match) => match === o.optionId));
        const notIncluded = this.selectedOptions.filter((o) => !this.includedInPack.find((match) => match === o.optionId));
        this.selectedOptions = included.concat(notIncluded);
      } else if (this.filters.orderBy === '-included') {
        const included = this.selectedOptions.filter((o) => this.includedInPack.find((match) => match === o.optionId));
        const notIncluded = this.selectedOptions.filter((o) => !this.includedInPack.find((match) => match === o.optionId));
        this.selectedOptions = notIncluded.concat(included);
      }
    },
    async resetFilter() {
      this.filters = {
        orderBy: null,
      };
      this.search = null;
      this.selectedOptions = this.options;
    },
    closeFilterModal() {
      this.filters.orderBy = null;
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.$route.params.packId) {
          if (!this.pack.ranges.length) {
            throw new Error('Sélectionnez au moins une gamme !');
          }
          await packApi.update(this.$route.params.packId, this.pack);
          const preSelectionArray = this.includedInPack.map((el) => ({ packId: this.$route.params.packId, optionId: el, isActiveDefault: this.baseOptions.find((e) => e === el) !== undefined }));
          await packApi.updatePreSelections(this.$route.params.packId, { preSelections: preSelectionArray });
        } else {
          if (!this.pack.ranges.length) {
            throw new Error('Sélectionnez au moins une gamme !');
          }
          const pack = await packApi.create(this.pack);
          const preSelectionArray = this.includedInPack.map((el) => ({ packId: pack.packId, optionId: el, isActiveDefault: !!(this.baseOptions.find((e) => e === el) === undefined) }));
          await packApi.updatePreSelections(pack.packId, { preSelections: preSelectionArray });
          this.$router.push({ name: 'categories-packs-packId', params: { packId: pack.packId } });
        }
        this.getData();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: `Il y a eu un problème lors de la sauvegarde du pack. ${er.message}`,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer le pack',
        text: 'Êtes vous sûr de vouloir supprimer ce pack ?',
        confirmText: 'Supprimer le pack',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deletePack();
        },
      });
    },
    async deletePack() {
      if (this.$route.params.packId) {
        this.isLoading = true;
        try {
          const { packId } = this.$route.params;
          await packApi.updatePreSelections(packId, { preSelections: [] });
          await packApi.delete(packId);
          this.$notification.show({ text: 'Ce pack a été supprimé avec succès !' });
          this.$router.push({ name: 'categories-packs' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression du pack',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.pack
  .body
    @include screen
    .range + .range
      margin-left: 2rem
    .options
      min-height: 345px
      .data
        flex: auto
  form > .header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
</style>
