<template>
  <label class="app-textarea">
    <span v-if="label" class="label" :class="{ mandatory: required }">{{ label }}</span>
    <textarea
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :maxlength="maxLength"
      @input="$emit('input', $event.target.value)"
      :rows="rows"
      :style="{ '--resize': resize }"
    />
  </label>
</template>

<script>
export default {
  name: 'app-textarea',
  props: {
    value: String,
    placeholder: {
      type: String,
      default: 'Text...',
    },
    label: {
      type: String,
    },
    rows: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    resize: {
      type: String,
      default: 'none',
    },
    maxLength: {
      type: Number,
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
};
</script>

<style lang='sass' scoped>
.app-textarea
  display: flex
  flex-direction: column
  width: 100%

  .label
    @include heading-xs
    color: $label
    padding-bottom: 4px

    &.mandatory:after
      content: "*"
      transform: translateX(0.2rem) translateY(-0.15rem)
      color: $label
      display: inline-block

  textarea
    @include paragraph
    display: flex
    padding: 14px 16px
    min-height: 3rem
    height: 100%
    font-family: inherit
    background-color: $off-white
    border: 1px solid $line
    border-radius: 4px
    color: $body
    resize: var(--resize, none)

    &:focus
      outline: none
      box-shadow: 0 0 2px $primary
      border: 1px solid $primary

    &:disabled, &:read-only
      background-color: $background
      color: $info
      pointer-events: none
      cursor: not-allowed
      &:read-only
        pointer-events: auto
        cursor: default

    &::placeholder
      color: $info
</style>
