import Vue from 'vue';
import Vuikit from 'vuikit';
import * as dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import Meta from 'vue-meta';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import ToggleButton from 'vue-js-toggle-button';
import VModal from 'vue-js-modal';
import '@vuikit/theme/dist/vuikit.min.css';
import App from './App.vue';
import router from './router';
import Intersection from './directives/intersection';

import Basics from './basics/index';

dayjs.locale(fr);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(localeData);

Vue.prototype.$dayjs = dayjs;

Vue.directive('intersection', Intersection);

Vue.use(ToggleButton);
Vue.use(VModal);
Vue.use(Vuikit);

Vue.use(PerfectScrollbar);
Vue.config.productionTip = false;

// les composants du starter-kit
Vue.use(Basics);
Vue.use(Meta);

// Filtre pour le formatage des dates au format JJ/MM/AAAA
Vue.filter('date', (value) => dayjs(value).format('DD/MM/YYYY'));

new Vue({
  router,
  render: (h) => h(App),
  data: {
    notificationMessages: [],
  },
}).$mount('#app');

// Afficher une notification
Vue.prototype.$displayNotification = function $displayNotification(message) {
  this.$root.notificationMessages.push(message);
};
