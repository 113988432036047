<template>
  <div class="options hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Options</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToOption">Ajouter une option</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES OPTIONS -->
    <div class="body">
      <div class="search grid-x">
        <div class="cell shrink">
          <app-search size="small" v-model="search" placeholder="Rechercher une option" />
        </div>
        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterOptions"
            @reset="resetFilers"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => !!i).length"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Type"
                  v-model="filters.type"
                  :options="typeOptions"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Catégorie"
                  v-model="filters.categoryId"
                  @input="resetLotOptions"
                  :options="categoryOptions"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Lot"
                  v-model="filters.lotId"
                  @input="resetThemeOptions"
                  :options="lotOptions"
                  :disabled="lotOptions.length === 0"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Thème"
                  v-model="filters.themeId"
                  :options="themeOptions"
                  :disabled="themeOptions.length === 0"
                />
              </div>
            </div>
          </filter-modal>
        </div>
      </div>
      <app-table :headers="tableHeaders" :data="options" :loading="isLoading" @line-cliked="goToOption" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="option" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="type" slot-scope="{ data }">
          <p>{{ findType(data.type) }}</p>
        </template>
        <template slot="theme" slot-scope="{ data }">
          <p>{{ data.theme.name }}</p>
        </template>
        <template slot="lot" slot-scope="{ data }">
          <p>{{ data.lot.name }}</p>
        </template>
        <template slot="category" slot-scope="{ data }">
          <p>{{ data.category.name }}</p>
        </template>
        <template slot="price" slot-scope="{ data }">
          <p>{{ data.formula }}</p>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import optionTypes from '@/services/data/optionTypes.json';
import option from '@/services/api/option';
import theme from '@/services/api/theme';
import lot from '@/services/api/lot';
import category from '@/services/api/category';
import utils from '@/services/utils/utils';
import FilterModal from '@/components/general/FilterModal.vue';

export default {
  name: 'options',
  components: {
    FilterModal,
  },
  metaInfo: {
    title: 'Options',
  },
  data() {
    return {
      options: [],
      tableHeaders: [
        { label: 'Option', key: 'option', size: 3 },
        { label: 'Type', key: 'type', size: 1 },
        { label: 'Thème', key: 'theme', size: 3 },
        { label: 'Lot', key: 'lot', size: 2 },
        { label: 'Catégorie', key: 'category', size: 2 },
        { label: 'Prix', key: 'price', size: 1 },
      ],
      filters: {
        type: null,
        categoryId: null,
        lotId: null,
        themeId: null,
      },
      typeOptions: [],
      categories: [],
      categoryOptions: [],
      lots: [],
      lotOptions: [],
      themes: [],
      themeOptions: [],
      isLoading: true,
      search: null,
      isCreateModalOpen: null,
      utils,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  mounted() {
    this.isLoading = true;
    this.search = this.$route.query.search || null;
    this.filters.type = this.$route.query.type || null;
    this.filters.categoryId = this.$route.query.categoryId || null;
    this.filters.lotId = this.$route.query.lotId || null;
    this.filters.themeId = this.$route.query.themeId || null;
    this.typeOptions = utils.formatOptions(
      optionTypes,
      (o) => o.name,
      (o) => o.label,
      'choose',
    );
    this.getData();
    this.isLoading = false;
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    $route() {
      if (!this.isLoading) {
        this.getData();
      }
    },
    filters: {
      handler(value) {
        this.filters.type = value.type;
        this.filters.categoryId = value.categoryId;
        this.filters.lotId = value.lotId;
        this.filters.themeId = value.themeId;
      },
      deep: true,
    },
    'filters.categoryId': {
      handler() {
        this.lotOptions = this.getLotOptions();
        this.themeOptions = this.getThemeOptions();
      },
    },
  },
  methods: {
    async getData() {
      await this.getCategories();
      await this.getLots();
      await this.getThemes();
      this.categoryOptions = utils.formatOptions(
        this.categories,
        (o) => o.categoryId,
        (o) => o.name,
        'choose',
      );
      this.lotOptions = await this.getLotOptions();
      this.themeOptions = await this.getThemeOptions();
      await this.getOptions();
    },
    async getCategories() {
      try {
        this.categories = await category.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getLots() {
      try {
        this.lots = await lot.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getThemes() {
      try {
        this.themes = await theme.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des thèmes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    findType(type) {
      return optionTypes.find((el) => el.name === type).label;
    },
    getLotOptions() {
      if (this.filters.categoryId && this.filters.categoryId !== 'choose') {
        return utils.formatOptions(
          this.lots.filter((lotOption) => (lotOption.categoryId === this.filters.categoryId)),
          (o) => o.lotId,
          (o) => o.name,
          'choose',
        );
      }
      return utils.formatOptions(
        this.lots,
        (o) => o.lotId,
        (o) => o.name,
        'choose',
      );
    },
    getThemeOptions() {
      if (this.filters.lotId && this.filters.lotId !== 'choose') {
        return utils.formatOptions(
          this.themes.filter((themeOption) => (themeOption.lot.lotId === this.filters.lotId)),
          (o) => o.themeId,
          (o) => o.name,
          'choose',
        );
      }
      return utils.formatOptions(
        this.themes,
        (o) => o.themeId,
        (o) => o.name,
        'choose',
      );
    },
    filterOptions() {
      this.$router.push({
        query: {
          ...this.$route.query,
          categoryId: this.filters.categoryId || undefined,
          lotId: this.filters.lotId || undefined,
          themeId: this.filters.themeId || undefined,
          type: this.filters.type || undefined,
        },
      });
      this.getOptions();
    },
    closeFilterModal() {
      this.filters.categoryId = this.$route.query.categoryId || null;
      this.filters.lotId = this.$route.query.lotId || null;
      this.filters.themeId = this.$route.query.themeId || null;
      this.filters.type = this.$route.query.type || null;
    },
    resetFilers() {
      this.filters = {
        type: null,
        categoryId: null,
        lotId: null,
        themeId: null,
      };
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          categoryId: this.filters.categoryId || undefined,
          lotId: this.filters.lotId || undefined,
          themeId: this.filters.themeId || undefined,
          type: this.filters.type || undefined,
        },
      });
      this.isLoading = true;
      this.getOptions();
      this.isLoading = false;
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    resetLotOptions() {
      this.filters.lotId = undefined;
    },
    resetThemeOptions() {
      this.filters.themeId = undefined;
    },
    async getOptions() {
      this.isLoading = true;
      try {
        this.options = await option.getAll(
          this.$route.query.search,
          this.$route.query.type,
          this.$route.query.categoryId,
          this.$route.query.lotId,
          this.$route.query.themeId,
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des options',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    goToOption(selectedOption) {
      if (selectedOption && selectedOption.optionId) {
        this.$router.push({ name: 'categories-options-optionId', params: { optionId: selectedOption.optionId } });
      } else {
        this.$router.push({ name: 'categories-options-new' });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.options
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
  .filter-container
    margin-left: auto
</style>
