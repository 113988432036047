import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des agences
 * @param {string} search: paramètre de recherche, sur le nom de l'agence
 */
const getAll = async (limit, offset, reportDate) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/advertisement-logs`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(reportDate && { reportDate }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

const advertisementLog = {};
advertisementLog.getAll = getAll;

export default advertisementLog;
