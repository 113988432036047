<template>
  <div class="opportunities hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Opportunités</h1>
      </div>
    </div>
    <!-- BODY / LISTE DES OPPORTUNITES -->
    <div class="body">
      <p class="description">Liste des contacts créés il y a plus de 30 jours ayant fait des avant-projets dans les moins de 30 jours.</p>
      <app-table :headers="tableHeaders" :data="hotCustomers.data" :loading="isLoading" @line-cliked="goToCustomerDetailsPage" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ nameFormat(data.firstname, data.lastname) }}</strong>
        </template>
        <template slot="creationDate" slot-scope="{ data }">
          <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY') }}</p>
        </template>
        <template slot="status" slot-scope="{ data }">
          <strong>{{ statusFormat(data.status)  }}</strong>
        </template>
        <template slot="newProjects" slot-scope="{ data }">
          <strong>{{ data.newProjectDraft }}</strong>
        </template>
        <template slot="lastProjectDraftCreatedAt" slot-scope="{ data }">
          <strong>{{ $dayjs(data.lastProjectDraftCreatedAt).format('DD/MM/YYYY') }}</strong>
        </template>
        <template slot="totalProjects" slot-scope="{ data }">
          <strong>{{ data.totalProjectDraft }}</strong>
        </template>
      </app-table>
      <!-- PAGINATION -->
      <app-pagination
          v-if="hotCustomers.metadata"
          :limit="limit"
          :offset="hotCustomers.metadata.offset"
          :count="hotCustomers.metadata.count"
        />
    </div>
  </div>
</template>

<script>
import memberApi from '@/services/api/member';
import customerApi from '@/services/api/customer';
import customerStatuses from '@/services/data/customerStatuses.json';

export default {
  name: 'opportunities',
  metaInfo: {
    title: 'opportunities',
  },
  data() {
    return {
      me: null,
      hotCustomers: {
        data: null,
        metatdata: null,
      },
      tableHeaders: [
        { label: 'Nom', key: 'name', size: 2 },
        { label: 'Date de création', key: 'creationDate', size: 2 },
        { label: 'Statut', key: 'status', size: 2 },
        { label: 'AVP New', key: 'newProjects', size: 2 },
        { label: 'Date dernier AVP', key: 'lastProjectDraftCreatedAt', size: 2 },
        { label: 'AVP Total', key: 'totalProjects', size: 2 },
      ],
      isLoading: true,
      limit: 10,
    };
  },
  created() {
    this.getMe();
  },
  mounted() {
    this.getHotCustomers();
    this.isLoading = false;
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }

      return null;
    },
  },
  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getHotCustomers();
        this.isLoading = false;
      }
    },
  },
  methods: {
    async getMe() {
      try {
        this.me = await memberApi.getMe();
      } catch (error) {
        throw error;
      }
    },
    async goToCustomerDetailsPage(selectedCustomer) {
      const {
        customerId,
        agencyId,
        lastname,
        firstname,
        email,
        phone,
        status,
        firstContact,
        brandKnowledge,
      } = selectedCustomer;
      await customerApi.update(customerId, {
        agencyId,
        lastname,
        firstname,
        email,
        phone,
        status,
        firstContact,
        brandKnowledge,
        isHotCustomerChecked: true,
      });
      this.$router.push({ name: 'customers-prospects-customerId', params: { customerId } });
    },
    async getHotCustomers() {
      this.isLoading = true;
      try {
        this.hotCustomers = await customerApi.getHotCustomersByMember(this.limit, this.computeOffset);
        this.isLoading = false;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des opportunités',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    nameFormat(firstname, lastname) {
      const firstNameLowCase = firstname.toLowerCase();
      const lastNameLowCase = lastname.toLowerCase();
      return `${firstNameLowCase[0].toUpperCase() + firstNameLowCase.slice(1)} ${lastNameLowCase[0].toUpperCase() + lastNameLowCase.slice(1)}`;
    },
    statusFormat(status) {
      return customerStatuses.find((cs) => cs.name === status).label;
    },
  },
};
</script>

<style lang='sass' scoped>
.opportunities
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
    button
      margin-left: 1rem
  .description
    @include description
</style>
