<template>
  <div class="diffusion-advertisement-packs">
    <form ref="diffusion-advertisement-packs" @submit.prevent="save">

      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Configuration diffusion</h1>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>

      <!-- TABS -->
      <tab-nav :tabs="tabs"/>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="grid-x row grid-margin-x">
            <div class="cell auto message">
              <app-info>
                Le pack actif changera automatiquement tous les {{ daysBetweenPackChange }} jours, le prochain changement est le {{ $dayjs().add(nextPackChange, 'days').format('DD/MM/YYYY') }}
              </app-info>
            </div>
        </div>
        <div class="table grid-x">
          <div class="cell auto">
            <app-table class="diffusion-advertisement-packs-table" :headers="tableHeaders" :data="tableData" :loading="isLoading">
              <template slot="empty-table">
                <p>Aucune données de disponible</p>
              </template>
              <template slot="agency" slot-scope="{ data }">
                <strong>{{ data.agency.name }}</strong>
              </template>
              <template slot="firstWeek" slot-scope="{ data }">
                <app-select
                  v-model="data.firstWeek.active"
                  :options="data.firstWeek.list"
                  :disabled="data.firstWeek.list.length < 2"
                />
              </template>
              <template slot="secondWeek" slot-scope="{ data }">
                <app-select
                  v-model="data.secondWeek.active"
                  :options="data.secondWeek.list"
                  :disabled="data.secondWeek.list.length < 2"
                />
              </template>
              <template slot="thirdWeek" slot-scope="{ data }">
                <app-select
                  v-model="data.thirdWeek.active"
                  :options="data.thirdWeek.list"
                  :disabled="data.thirdWeek.list.length < 2"
                />
              </template>
              <template slot="fourthWeek" slot-scope="{ data }">
                <app-select
                  v-model="data.fourthWeek.active"
                  :options="data.fourthWeek.list"
                  :disabled="data.fourthWeek.list.length < 2"
                />
              </template>
            </app-table>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import advertisementPackApi from '@/services/api/advertisementPack';
import agencyApi from '@/services/api/agency';

export default {
  name: 'diffusion-advertisement-packs',

  components: {
    TabNav,
  },

  metaInfo: {
    title: 'Configuration diffusion',
  },

  data() {
    return {
      tabs: [
        {
          route: {
            name: 'diffusions-quotas',
            params: {},
          },
          label: 'Gestion',
        },
        {
          route: {
            name: 'diffusions-statistics',
            params: {},
          },
          label: 'Statistiques',
        },
        {
          route: {
            name: 'diffusions-templates',
            params: {},
          },
          label: 'Templates',
        },
        {
          route: {
            name: 'diffusions-ubiflow',
            params: {},
          },
          label: 'Ubiflow',
        },
        {
          route: {
            name: 'diffusions-advertisement-packs',
            params: {},
          },
          label: 'Packs',
        },
        {
          route: {
            name: 'diffusions-logs',
            params: {},
          },
          label: 'Logs',
        },
      ],
      isLoading: null,
      tableHeaders: [
        { label: 'Agence', key: 'agency', size: 'auto' },
        { label: 'Pack 1', key: 'firstWeek', size: 'auto' },
        { label: 'Pack 2', key: 'secondWeek', size: 'auto' },
        { label: 'Pack 3', key: 'thirdWeek', size: 'auto' },
        { label: 'Pack 4', key: 'fourthWeek', size: 'auto' },
      ],
      tableData: [],
      advertisementPacks: [],
      agencies: [],
      nextPackChange: null,
      daysBetweenPackChange: null,
    };
  },
  async mounted() {
    this.getNextPackChange();
    await this.getData();
  },
  methods: {
    getNextPackChange() {
      const baseline = this.$dayjs().month(0).date(7).year(2022);
      const diffInDays = this.$dayjs().diff(baseline, 'days');

      this.daysBetweenPackChange = parseInt(process.env.VUE_APP_DAYS_BETWEEN_PACK_CHANGE, 10);
      this.nextPackChange = this.daysBetweenPackChange - (diffInDays % this.daysBetweenPackChange);

      const diffInXDays = Math.ceil(diffInDays / this.daysBetweenPackChange);
      this.tableHeaders[(diffInXDays % (this.tableHeaders.length - 1)) + 1].label += ' (actif)';
    },
    async getData() {
      this.isLoading = true;
      this.tableData = [];
      this.advertisementPacks = [];
      this.agencies = [];
      await this.getAgencies();
      this.tableData = this.agencies.map((agency) => ({
        agency: {
          agencyId: agency.agencyId,
          name: agency.name,
        },
        firstWeek: {
          active: null,
          list: [{
            name: null,
            label: 'Choisir',
          }],
        },
        secondWeek: {
          active: null,
          list: [{
            name: null,
            label: 'Choisir',
          }],
        },
        thirdWeek: {
          active: null,
          list: [{
            name: null,
            label: 'Choisir',
          }],
        },
        fourthWeek: {
          active: null,
          list: [{
            name: null,
            label: 'Choisir',
          }],
        },
      }));
      await this.getAdvertisementPacks();
      this.advertisementPacks.forEach((advertisementPack) => { // pour chaque pack
        this.tableData.forEach((agency) => {
          agency.firstWeek.list.push(this.advertisementPackFormat(advertisementPack));
          agency.secondWeek.list.push(this.advertisementPackFormat(advertisementPack));
          agency.thirdWeek.list.push(this.advertisementPackFormat(advertisementPack));
          agency.fourthWeek.list.push(this.advertisementPackFormat(advertisementPack));
        });

        if (advertisementPack && advertisementPack.agencies && advertisementPack.agencies.length) {
          advertisementPack.agencies.forEach((agency) => {
            const i = this.tableData.findIndex((el) => el.agency.agencyId === agency.agencyId);
            if (advertisementPack.weekOrder === 'FIRST') {
              this.tableData[i].firstWeek.active = advertisementPack.advertisementPackId;
            } else if (advertisementPack.weekOrder === 'SECOND') {
              this.tableData[i].secondWeek.active = advertisementPack.advertisementPackId;
            } else if (advertisementPack.weekOrder === 'THIRD') {
              this.tableData[i].thirdWeek.active = advertisementPack.advertisementPackId;
            } else if (advertisementPack.weekOrder === 'FOURTH') {
              this.tableData[i].fourthWeek.active = advertisementPack.advertisementPackId;
            }
          });
        }
      });
      this.isLoading = false;
    },
    advertisementPackFormat(advertisementPack) {
      return {
        name: advertisementPack.advertisementPackId,
        label: advertisementPack.name,
      };
    },
    async getAgencies() {
      try {
        this.agencies = await agencyApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAdvertisementPacks() {
      try {
        this.advertisementPacks = await advertisementPackApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des packs d’annonce',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        const packToUpdate = [];

        this.tableData.forEach((el) => {
          if (el.firstWeek.active) {
          // S'il y a un pack de choisi pour cette agence, cette semaine là
            packToUpdate.push(this.findAdvertisementPackToUpdate(el.agency, 'FIRST', el.firstWeek.active));
          } else {
            this.removeAdvertisementPack(packToUpdate, el);
          }
          if (el.secondWeek.active) {
            packToUpdate.push(this.findAdvertisementPackToUpdate(el.agency, 'SECOND', el.secondWeek.active));
          } else {
            this.removeAdvertisementPack(packToUpdate, el);
          }
          if (el.thirdWeek.active) {
            packToUpdate.push(this.findAdvertisementPackToUpdate(el.agency, 'THIRD', el.thirdWeek.active));
          } else {
            this.removeAdvertisementPack(packToUpdate, el);
          }
          if (el.fourthWeek.active) {
            packToUpdate.push(this.findAdvertisementPackToUpdate(el.agency, 'FOURTH', el.fourthWeek.active));
          } else {
            this.removeAdvertisementPack(packToUpdate, el);
          }
        });

        if (!packToUpdate.length) {
          throw new Error('Aucun pack de diffusion à mettre à jour.');
        }

        let parsedPacks = [];

        packToUpdate.flat().forEach((pack) => {
          let index = null;
          if (parsedPacks && parsedPacks.length) {
            index = parsedPacks.findIndex((p) => p.advertisementPackId === pack.advertisementPackId);
            if (index >= 0 && parsedPacks[index].data.agencies && parsedPacks[index].data.agencies.length) {
              parsedPacks[index].data.agencies.push(pack.data.agencyId);
            } else if (index >= 0) {
              parsedPacks[index].data.agencies = [pack.data.agencyId];
            } else {
              const { agencyId, ...newPackData } = pack.data;
              parsedPacks.push({
                ...pack,
                data: {
                  ...newPackData,
                  agencies: [pack.data.agencyId],
                },
              });
            }
          } else {
            const { agencyId, ...newPackData } = pack.data;
            parsedPacks = [{
              ...pack,
              data: {
                ...newPackData,
                agencies: [pack.data.agencyId],
              },
            }];
          }
        });

        if (parsedPacks && parsedPacks.length) {
          parsedPacks = parsedPacks.map((pack) => ({
            ...pack,
            data: {
              ...pack.data,
              agencies: pack.data.agencies.filter((agency) => agency),
            },
          }));
        }

        const promises = parsedPacks.map((el) => advertisementPackApi.update(el.advertisementPackId, el.data));
        await Promise.all(promises);
        this.$notification.show({ text: 'Les packs de diffusion ont été mis à jour avec succès !' });
        await this.getData();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: `Il y a eu un problème lors de la sauvegarde des packs de diffusion. ${er}`,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    removeAdvertisementPack(packToUpdate, el) {
      // Si aucun pack actif pour cette agence, cette semaine là, on vérifie qu'il n'y en avait pas un précédemment
      const oldAdvertisementPack = this.advertisementPacks.find((ap) => ap.agencies && ap.agencies.length && ap.agencies.find((a) => a.agencyId === el.agency.agencyId) && ap.weekOrder === el.weekOrder && packToUpdate.some((p) => p.advertisementPackId !== ap.advertisementPackId));
      if (oldAdvertisementPack) {
        packToUpdate.push({
          advertisementPackId: oldAdvertisementPack.advertisementPackId,
          data: {
            agencyId: null,
            name: oldAdvertisementPack.name,
            weekOrder: null,
            versions: oldAdvertisementPack.versions,
            status: oldAdvertisementPack.status,
          },
        });
      }
    },
    findAdvertisementPackToUpdate(agency, weekOrder, active) {
      const packToUpdate = [];
      const advertisementPack = this.advertisementPacks.find((el) => el.advertisementPackId === active);
      packToUpdate.push({
        advertisementPackId: advertisementPack.advertisementPackId,
        data: {
          agencyId: agency.agencyId,
          name: advertisementPack.name,
          weekOrder,
          versions: advertisementPack.versions,
          status: advertisementPack.status,
        },
      });
      // On vérifie s'il y avait déjà un pack de sélectionné précédemment
      const oldAdvertisementPack = this.advertisementPacks.find((el) => el.agencies && el.agencies.length && el.agencies.find((a) => a.agencyId === agency.agencyId) && el.weekOrder === weekOrder && el.advertisementPackId !== advertisementPack.advertisementPackId);
      if (oldAdvertisementPack) {
        packToUpdate.push({
          advertisementPackId: oldAdvertisementPack.advertisementPackId,
          data: {
            agencyId: null,
            name: oldAdvertisementPack.name,
            weekOrder: null,
            versions: oldAdvertisementPack.versions,
            status: oldAdvertisementPack.status,
          },
        });
      }
      return packToUpdate;
    },
  },
};
</script>

<style lang='sass'>
.diffusion-advertisement-packs
  .diffusion-advertisement-packs-table
    .header
      .cell
        padding-right: 20px
        &:last-child
          padding-right: 0
  .body
    @include screen
  form > .header
      @include header
      padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .card
    @include card
  &-table ::v-deep .data
    overflow: initial
</style>
