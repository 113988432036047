import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des terrains publiques par codes postaux
 * @param {string} brandId: id de la marque
 * @param {string} postalCodeInseeNumber: postal code insee number
 */
const getPublic = async (brandId, postalCodeInseeNumber) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${brandId}/lands`, {
      params: { ...(postalCodeInseeNumber && { postalCodeInseeNumber }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des terrains
 * @param {number} limit: nombre d'éléments à récupérer
 * @param {number} offset: offset de la liste
 * @param {string} search: paramètre de recherche
 * @param {string} orderBy: valeur pour laquelle il faut trier par ordre croissant
 * @param {string} agencyId: id de l'agence
 * @param {string} allotmentId: id d'un lotissement
 * @param {string} postalCodeInseeNumber: valeur pour laquelle il faut trier par code postal
 * @param {string} status: statut du terrain sur lequel on veut filtrer
 */
const getAll = async (limit, offset, search, orderBy, agencyId, companyId, postalCodeInseeNumber, isAvailableForAds, status, allotmentId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/lands`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(orderBy && { orderBy }),
        ...(agencyId && { agencyId }),
        ...(companyId && { companyId }),
        ...(allotmentId && { allotmentId }),
        ...(postalCodeInseeNumber && { postalCodeInseeNumber }),
        ...(isAvailableForAds && { isAvailableForAds }),
        ...(status && { status }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération d'un terrain
 * @param {string} landId: id du terrain à récupérer
 */
const getOne = async (landId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/lands/${landId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'un terrain
 * @param {object} data: données du terrain
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/lands`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mise à jour d'un terrain
 * @param {string} landId: id du terrain
 * @param {object} data: données du terrain
 */
const update = async (landId, data) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/lands/${landId}`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Suppression d'un terrain
 * @param {string} landId: id du terrain à supprimer
 */
const deleteOne = async (landId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/lands/${landId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Upload de l'image du terrain
 * @param {string} landId: id du terrain
 */
const uploadImage = async (file, landId) => {
  try {
    let response = null;
    if (file === null) {
      response = await axios.post(`${VUE_APP_API_URL}/lands/${landId}/image`);
    } else {
      response = await axios.post(`${VUE_APP_API_URL}/lands/${landId}/image`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const land = {};
land.getAll = getAll;
land.getPublic = getPublic;
land.getOne = getOne;
land.create = create;
land.update = update;
land.delete = deleteOne;
land.uploadImage = uploadImage;

export default land;
