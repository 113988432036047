var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adding-lands-body body"},[_c('div',{staticClass:"search grid-x"},[_c('div',{staticClass:"cell shrink search"},[_c('app-search',{attrs:{"size":"small","placeholder":"Rechercher un terrain"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"cell shrink button"},[_c('filter-modal',{staticClass:"filter-btn",attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return i !== null; }).length},on:{"change":_vm.filterLands,"reset":_vm.resetFilters,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell auto"},[_c('app-radio-button',{attrs:{"label":"Trier par","name":"orderBy","value":_vm.$route.query.orderBy,"options":[
                  { name: 'price', label: 'Prix croissant' },
                  { name: 'width', label: 'Façade croissante' }
                ]},model:{value:(_vm.filters.orderBy),callback:function ($$v) {_vm.$set(_vm.filters, "orderBy", $$v)},expression:"filters.orderBy"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-search-city',{attrs:{"label":"Commune","placeholder":"Nantes - 44000","selectedPostalCode":_vm.selectedPostalCode},on:{"select":_vm.setPreSelectedPostalCode,"clear":_vm.resetSelectedPostalCode}})],1)])])],1)]),_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.lands.data,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"city",fn:function(ref){
                var data = ref.data;
return [_c('strong',[_vm._v(" "+_vm._s(data.address.postalCodeInseeNumber.split('-')[0])+" - "+_vm._s(data.address.city)+" ")])]}},{key:"allotment",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.allotment ? data.allotment.name : ''))])]}},{key:"company",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.company ? data.company.name : ''))])]}},{key:"reference",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.reference))])]}},{key:"lotNumber",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.lotNumber))])]}},{key:"depth",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.width)+" m")])]}},{key:"surface",fn:function(ref){
                var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.surface)+" m²")])]}},{key:"price",fn:function(ref){
                var data = ref.data;
return [_c('strong',{staticClass:"price"},[_vm._v(_vm._s(_vm.utils.formatCentToEuro(data.price, true)))])]}},{key:"action",fn:function(ref){
                var data = ref.data;
return [_c('app-button',{attrs:{"size":"small","disabled":_vm.landsAdded.includes(data.landId)},on:{"click":function($event){return _vm.$emit('add:land', data)}}},[_vm._v("Choisir")])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune donnée de disponible")])])],2),(_vm.lands.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.lands.metadata.offset,"count":_vm.lands.metadata.count}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }