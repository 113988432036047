import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des lots métrés
 * @param {string || null} search: paramètre de recherche, sur le nom du lot métré
 * @param {string || null} categoryId: paramètre de recherche, sur la catégorie du lot métré
 * @param {string || null} lotId: paramètre de recherche, sur le lot du lot métré
 */
const getAll = async (search, categoryId, lotId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/lots-surveys/`, {
      params: {
        ...(search && { search }),
        ...(categoryId && { categoryId }),
        ...(lotId && { lotId }),
      },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const lotSurvey = {};
lotSurvey.getAll = getAll;

export default lotSurvey;
