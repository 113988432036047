<template>
  <div class="company-schedule">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="company && company.companyId && company.name"
          :items="[
            { route: { name: 'companies'}, name: 'Partenaires'},
            { route: { name: 'companies-companyId', params: { companyId: $route.params.companyId }}, name: company.name}
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <div>
          <h1>{{ !isLoading && $route.params.companyId && company ? company.name : ' ' }}</h1>
        </div>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav v-if="$route.params.companyId" :tabs="tabs && tabs.length ? tabs : mainTabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body">
      <!-- OLD CALENDAR (Hedifys chantier) -->
      <section class="hedifys-chantier" id="company-schedule">
        <FullCalendar
          class="company-calendar"
          defaultView="dayGridMonth"
          :plugins="calendarPlugins"
          :locales="calendarLocales"
          :weekends="true"
          :events="companyEvents"
        />
      </section>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import companyApi from '@/services/api/company';
import companyCategoryApi from '@/services/api/companyCategory';

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocale from '@fullcalendar/core/locales/fr';

import projectSteps from '@/services/api/projectStep';

export default {
  name: 'company-schedule',
  components: {
    TabNav,
    Breadcrumb,
    FullCalendar,
  },
  metaInfo() {
    return {
      title: this.company ? `${this.company.name} –  Partenaire` : 'Partenaire',
    };
  },
  data() {
    return {
      // New data
      mainTabs: [
        {
          route: {
            name: 'companies-companyId',
            params: { companyId: this.$route.params.companyId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'companies-companyId-projects',
            params: { companyId: this.$route.params.companyId },
          },
          label: 'Chantiers',
        },
        {
          route: {
            name: 'companies-companyId-schedule',
            params: { companyId: this.$route.params.companyId },
          },
          label: 'Disponibilités',
        },
      ],
      tabs: null,

      isLoading: true,
      company: null,

      // Old data
      companyEvents: [],
      calendarPlugins: [dayGridPlugin],
      calendarLocales: [frLocale],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      if (this.$route.params.companyId) {
        try {
          this.company = await companyApi.getById(this.$route.params.companyId);
          this.companyCategories = await companyCategoryApi.getAll();

          this.checkDeveloper();
          const companyEvents = await projectSteps.getSchedule(this.$route.params.companyId);

          this.companyEvents = companyEvents.map((event) => ({
            title: `Chantier ${event.title}`,
            start: this.$dayjs(event.start).format('YYYY-MM-DD'),
            end: this.$dayjs(event.end).format('YYYY-MM-DD'),
          }));
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors du chargement des données',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    checkDeveloper() {
      if (this.company && this.company.companyCategories && this.company.companyCategories.length && this.companyCategories && this.companyCategories.length) {
        let newTabs = this.mainTabs;
        const isDeveloper = this.companyCategories.filter(
          (category) => category.type === 'DEVELOPER'
            && this.company.companyCategories.includes(category.companyCategoryId),
        ).length > 0;
        if (isDeveloper) {
          newTabs = newTabs.concat([
            {
              route: {
                name: 'companies-companyId-allotments',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Lotissements',
            },
            {
              route: {
                name: 'companies-companyId-lands',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Terrains',
            },
          ]);
        }
        this.tabs = newTabs;
      }
    },
  },
};
</script>

<!-- New style -->
<style lang='sass' scoped>
.company-schedule
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .save
    margin-left: 16px
  .breadcrumbs
    @include breadcrumbs
  h3
    color: $primary
    margin-bottom: 10px
</style>

<!-- Old style -->
<style lang="scss">
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

#company-schedule{
  .title {
    color: $gray-dark;
    font-size: 30px;
    padding-top: 30px;
    padding: 30px 60px 0px 60px;
    font-weight: 500;
  }
  .company-calendar {
    padding: 30px 60px;
    background: white;
    h2 {
      color: $gray-medium;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .fc-event {
      padding: 0 5px;
      background: $gray-light;
      border-color: $gray-light;
    }
    .fc-today {
      background: lighten($blue-light, 30%);
    }
    .fc-icon {
      color: white;
    }
    .fc-icon-chevron-left {
      transform: rotate(90deg);
    }
    .fc-icon-chevron-right {
      transform: rotate(180deg);
    }
  }
}
</style>
