<template>
  <div class="member-account hedifys-21">
    <form ref="memberAccount" @submit.prevent="save">
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Votre compte</h1>
        </div>
        <div class="cell shrink">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <h3>Informations générales</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto"></div>
            <div class="cell shrink avatar">
              <app-avatar v-model="avatarUrl" @upload="uploadAvatar" @remove="removeAvatar" />
              <h4>Photo de profil</h4>
              <p>Cette photo sera affichée sur le site internet</p>
            </div>
            <div class="cell auto"></div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="member.firstname" type="text" placeholder="Prénom..." label="Prénom" required />
            </div>
            <div class="cell auto">
              <app-input v-model="member.lastname" type="text" placeholder="Nom de famille..." label="Nom de famille" required />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-select v-model="member.jobTitle" :options="jobOptions" label="Poste" required />
            </div>
            <div class="cell auto"></div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="member.phone" type="text" placeholder="Téléphone..." label="Numéro de téléphone" required />
            </div>
            <div class="cell auto">
              <app-input v-model="member.secondaryPhone" type="text" placeholder="Téléphone secondaire..." label="Numéro de téléphone secondaire" />
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import memberApi from '@/services/api/member';
import brandJobs from '@/services/data/brandJobs.json';
import agencyJobs from '@/services/data/agencyJobs.json';
import utils from '@/services/utils/utils';

export default {
  name: 'member-account',
  components: {
    TabNav,
  },
  metaInfo: {
    title: 'Votre compte',
  },
  data() {
    return {
      isLoading: false,
      member: {},
      tabs: [
        {
          route: {
            name: 'account',
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'account-password',
          },
          label: 'Mot de passe',
        },
      ],
      jobOptions: [],
      avatar: null,
      avatarUrl: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getMember();
    this.avatarUrl = this.member.photo;
    const isBrandAdmin = this.member.rules.find((el) => el.type === 'ADMIN' && el.brandId !== null);
    if (isBrandAdmin === undefined) {
      this.jobOptions = utils.formatOptions(
        agencyJobs,
        (option) => option.name,
        (option) => option.label,
      );
    } else {
      this.jobOptions = utils.formatOptions(
        brandJobs,
        (option) => option.name,
        (option) => option.label,
      );
    }
    this.isLoading = false;
  },
  methods: {
    async getMember() {
      try {
        this.member = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du compte',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        const member = await memberApi.updateMe({
          firstname: this.member.firstname,
          lastname: this.member.lastname,
          jobTitle: this.member.jobTitle,
          phone: this.member.phone,
          secondaryPhone: this.member.secondaryPhone,
        });
        try {
          await memberApi.uploadPhoto(member.memberId, this.avatar);
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la mise à jour de la photo de profil',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.$notification.show({ text: 'Votre compte a été mis à jour avec succès !' });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde des modifications du compte',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async uploadAvatar(event) {
      const file = new FormData();
      file.append('file', event);
      this.avatarUrl = URL.createObjectURL(event);
      this.avatar = file;
    },
    removeAvatar() {
      this.avatar = null;
      this.avatarUrl = null;
    },
  },
};
</script>

<style lang='sass' scoped>
.member-account
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
  .input
    margin-right: 16px
  .avatar
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 16px

    > div
      margin-bottom: 16px
</style>
