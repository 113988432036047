import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère des options par style
 * @param {string} styleId: id du style
 */
const getByStyle = async (styleId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/styles/${styleId}/options`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/** Récupère des options
 * @param {string} search:  paramètre de recherche, sur le nom de l'option
 * @param {string} type: paramètre de recherche, sur le type de l'option
 * @param {string} categoryId: paramètre de recherche, sur l'id de la catégorie de l'option
 * @param {string} lotId: paramètre de recherche, sur l'id du lot de l'option
 * @param {string} themeId: paramètre de recherche, sur l'id du thème de l'option
 */
const getAll = async (search, type, categoryId, lotId, themeId, isAvailableInStyles) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/options`, {
      params: {
        ...(isAvailableInStyles && { isAvailableInStyles }),
        ...(search && { search }),
        ...(type && { type }),
        ...(categoryId && { categoryId }),
        ...(lotId && { lotId }),
        ...(themeId && { themeId }),
      },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * @param {string} optionId id de l'option
 */
const getById = async (optionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/options/${optionId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Met à jour les options du style
 * @param {string} styleId: id du style à éditer
 * @param {object} options: liste d'options du style
 */
const updateStyle = async (styleId, options) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/styles/${styleId}/options`, options);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'une option
 * @param {option} option: objet de l'option à créer
 */
const create = async (option) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/options/`, option);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'une option
 * @param {option} option: nouvel objet de l'option
 * @param {string} optionId: id de l'option à récupérer
 */
const update = async (optionId, option) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/options/${optionId}`, option);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression de l'option
 * @param {string} optionId: id de l'option à récupérer
 */
const deleteOne = async (optionId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/options/${optionId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Retourn les options d'une règle géographique
 * @param {string} regulationId id de la règle
 */
const getByRegulation = async (regulationId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/regulations/${regulationId}/options`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Upload de l'image de l'option
 * @param {file} file: fichier à upload
 * @param {string} optionId: id de l'option
 */
const uploadImg = async (file, optionId) => {
  try {
    let response = null;
    if (file === null) {
      response = await axios.post(`${VUE_APP_API_URL}/options/${optionId}/image`);
    } else {
      response = await axios.post(`${VUE_APP_API_URL}/options/${optionId}/image`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Met à jour les options d'une règle géographique
 * @param {string} regulationId id de la règle
 * @param {object} options options à mettre à jour
*/
const updateRegulation = async (regulationId, options) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/regulations/${regulationId}/options`, options);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Retourne les options d'une contrainte
 * @param {string} constraintId id de la contrainte
 */
const getByConstraint = async (constraintId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/constraints/${constraintId}/options`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
* Met à jour les options d'une contrainte
* @param {string} constraintId id de la contrainte
* @param {object} options options à mettre à jour
*/
const updateConstraint = async (constraintId, options) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/constraints/${constraintId}/options`, options);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const option = {};
option.getByStyle = getByStyle;
option.getAll = getAll;
option.getById = getById;
option.create = create;
option.update = update;
option.delete = deleteOne;
option.uploadImg = uploadImg;
option.updateStyle = updateStyle;
option.updateRegulation = updateRegulation;
option.getByRegulation = getByRegulation;
option.updateConstraint = updateConstraint;
option.getByConstraint = getByConstraint;

export default option;
