<template>
  <div class="projects hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="new-header grid-x">
      <div class="cell auto">
        <div>
          <h1>Liste des chantiers</h1>
        </div>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" icon="add" size="large" type="submit" @click="projectCreate">Ajouter un chantier</app-button>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body" v-if="!isLoading">
      <section class="hedifys-chantier" id="projects" v-if="me">
        <!-- Filtres, Input de recherche et Bouton d'ajout d'un nouveau projet -->
        <div class="header uk-width-1-1">
          <vk-grid class="filters uk-width-1-1" gutter="collapse">
            <span v-if="me.isBrandAdmin || me.isAgencyAdmin">FILTRER:</span>
            <div class="uk-width-1-5" v-if="(agencies && agencies.length > 0) && me.isBrandAdmin">
              <select class="uk-select" v-model="selectedAgency">
                <option :value="null">Toutes les agences</option>
                <option :value="agency.agencyId" v-for="(agency, index) in agencies" :key="`projects-agency-${index}`">{{ agency.name }}</option>
              </select>
            </div>
            <div class="uk-width-1-5" v-if="(conductors && conductors.length > 0) && (me.isBrandAdmin || me.isAgencyAdmin)">
              <select class="uk-select" v-model="selectedConductor">
                <option :value="null">Tous les conducteurs</option>
                <option :value="conductor.memberId" v-for="(conductor, index) in conductors" :key="`projects-conductor-${index}`">
                  {{ conductor.lastname }} {{ conductor.firstname }}
                </option>
              </select>
            </div>
            <div class="uk-width-expand"></div>
            <div class="uk-width-1-5 uk-text-right">
              <input placeholder="Rechercher" class="uk-input uk-width-auto@m" type="text" v-model="searchText" />
            </div>
          </vk-grid>
        </div>

        <!-- Projets -->
        <vk-grid class="projects" v-if="projects && projects.length > 0" gutter="collapse">
          <div class="header uk-width-1-1  uk-child-width-expand">
            <vk-grid class="uk-width-1-1 uk-child-width-expand" gutter="collapse">
              <h6 class="uk-width-1-4">Dossier Clients</h6>
              <h6 class="uk-width-1-4">Lieu de construction</h6>
              <h6 class="uk-width-1-4">Construction</h6>
              <h6 class="uk-width-1-4">Chantiers</h6>
            </vk-grid>
          </div>
          <div @click="projectRead(project)" class="project uk-width-1-1 uk-child-width-expand" v-for="(project, index) in projects" :key="`projects-project-${index}`">
            <vk-grid class="uk-width-1-1 uk-child-width-expand" gutter="small">
              <div class="customer uk-width-1-4">
                <router-link v-if="project.status !== 'PENDING'" :to="{ name: 'projects-projectId-steps-gantt', params: { projectId : project.projectId } }">
                  <div class="column uk-width-auto@m gantt-link" @click.stop="">
                    <Chart />
                  </div>
                </router-link>
                <span class="reference">{{ `${project.reference} ` }}</span>
                <span class="name">
                  <template v-if="project.customer.type === 'PERSON'">
                    {{ getCustomersNames(project.customer) }}
                  </template>
                  <template v-else>
                    {{ `${project.customer.companyName}` }}
                  </template>
                </span>
                <br>
                <span class="agency">{{ `${project.agency.name} - ` }}</span>
                <span class="conductor">{{ `${project.member.firstname}-${project.member.lastname}` }}</span>
              </div>
              <div class="address uk-width-1-4">{{ `${project.address.postalCode} ${project.address.city}` }}</div>
              <div class="buildFilter uk-width-1-4"><p>{{ `${project.buildFilterLabel ? project.buildFilterLabel : 'Non défini. En attente de la validation du permis de construire.'}` }}</p></div>
              <div class="status uk-width-small uk-width-1-4">
                <p :class="project.status.toLowerCase()">{{ statuses[project.status] }}</p>
              </div>
            </vk-grid>
          </div>
        </vk-grid>

        <div class="nothing" v-else>
          <p>Aucun projet</p>
        </div>
        <app-pagination
              v-if="projects"
              :limit="limit"
              :offset="computeOffset"
              :count="count"
              />
      </section>
    </div>
    <div class="body" v-else>
      <div class="spinner-container">
        <app-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import project from '@/services/api/project';
import agency from '@/services/api/agency';
import member from '@/services/api/member';
import customer from '@/services/api/customer';
import statuses from '@/services/data/statuses.json';
import utils from '@/services/utils/utils';
import Chart from '@/assets/img/chart.svg?inline';

export default {
  name: 'projects',
  components: {
    TabNav,
    Chart,
  },
  metaInfo: {
    title: 'Liste des chantiers',
  },
  data() {
    return {
      // New data
      tabs: [
        {
          route: {
            name: 'projects',
          },
          label: 'Tout',
        },
        {
          route: {
            name: 'projects-pending',
          },
          label: 'En attente',
        },
        {
          route: {
            name: 'projects-todo',
          },
          label: 'Prévision',
        },
        {
          route: {
            name: 'projects-in-progress',
          },
          label: 'En-cours',
        },
        {
          route: {
            name: 'projects-done',
          },
          label: 'Livrés',
        },
      ],
      isLoading: true,
      count: null,
      limit: 25,
      statuses,
      me: null,
      projects: null,
      agencies: [],
      selectedAgency: null,
      conductors: [],
      selectedConductor: null,
      searchText: null,
    };
  },
  watch: {
    $route: {
      handler: 'getProjects',
      deep: true,
    },
    selectedAgency() {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, agencyId: this.selectedAgency, page: 1 } });
    },
    selectedConductor() {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, conductorId: this.selectedConductor, page: 1 } });
    },
    searchText() {
      this.debouncedUpdateSearchQuery();
    },
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return 0;
    },
  },
  async mounted() {
    if (this.$route.query.agencyId) {
      this.selectedAgency = this.$route.query.agencyId;
    }
    if (this.$route.query.conductorId) {
      this.selectedConductor = this.$route.query.conductorId;
    }
    if (this.$route.query.search) {
      this.searchText = this.$route.query.search;
    }
    this.isLoading = true;
    await this.getProjects();
    await this.getAgencies();
    await this.getConductors();
    await this.getMe();
    this.isLoading = false;
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 750);
  },
  methods: {
    getCustomersNames(selectedCustomer) {
      return customer.getCustomersNames(selectedCustomer);
    },
    async getProjects() {
      const queries = {
        ...this.$route.query,
        limit: this.limit,
        offset: this.computeOffset,
      };
      try {
        const { data, metadata } = await project.getAll(queries);
        this.projects = data;
        this.count = metadata.count;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des projets',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencies() {
      try {
        const allAgencies = await agency.getAll();

        if (allAgencies && allAgencies.length > 0) {
          this.agencies = allAgencies.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.agencies = [];
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getConductors() {
      try {
        const allConductors = await member.getAll();

        if (allConductors && allConductors.length > 0) {
          this.conductors = allConductors.sort((a, b) => a.lastname.localeCompare(b.lastname));
        } else {
          this.conductors = [];
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des conducteurs',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMe() {
      try {
        this.me = await member.getMe();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    projectCreate() {
      this.$router.push({ name: 'projects-new' });
    },
    projectRead(proj) {
      if (proj.status === 'PENDING') {
        this.$router.push({ name: 'projects-projectId', params: { projectId: proj.projectId } });
      } else {
        this.$router.push({ name: 'projects-projectId-steps', params: { projectId: proj.projectId } });
      }
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.searchText) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.searchText || undefined,
          },
        });
      }
    },
  },
};
</script>

<!-- New style -->
<style lang='sass' scoped>
.projects
  .body
    @include screen
  .new-header
    @include header
    padding-bottom: 4px
  .spinner-container
    @include spinner-container
</style>

<!-- Old style -->
<style lang="scss">
#projects {
  > .header {
    position: relative;
    background-color: $gray-very-light;
    button {
      color: #ffffff;
      border-radius: $global-border-radius;
      border: none;
      background-color: $blue-medium;
    }
    .search {
      input {
        margin: 0 20px 0 0;
        border-radius: $global-border-radius;
        padding-left: 20px;
        width: auto;
      }
    }
    .filters {
      margin: 0 0 20px 0;
      align-items: center;
      > div {
        margin: 0 0 0 10px;
        select {
          border-radius: $global-border-radius;
        }
      }
    }
  }
  .projects {
    background-color: #ffffff;
    .header {
      position: relative;
      padding: 15px 0 15px 15px;
      color: $gray-light;
      border-width: 0;
      border-left-width: 3rem;
      border-bottom-width: 1px;
      border-style: solid;
      border-color: $gray-light;
    }
    .project {
      position: relative;
      padding: 15px 0 15px 15px;
      border-style: solid;
      border-width: 0;
      cursor: pointer;
      &:not(:first-child) {
        border-bottom-width: 1px;
        border-bottom-color: $gray-light;
      }
      > .uk-grid {
        align-items: center;
      }
      .customer {
        padding-left: 5rem;
        span.reference, span.name {
          font-weight: 500;
          text-transform: uppercase;
        }
        span.reference {
          color: $gray-dark;
        }
        span.name {
          color: $blue-medium;
        }
        span.agency {
          text-transform: uppercase;
        }
        span.agency, span.conductor {
          color: $gray-regular;
        }
      }
      .address {
        color: $gray-dark;
        font-weight: 500;
      }
      .buildFilter p {
        color: $secondary-light;
      }
      .status {
        width: 6.5rem;
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px 8px;
          border-radius: 24px;
          border: 2px solid;
          &.pending {
            border-color: $warning;
            color: $warning;
            background: rgba(253, 176, 62, 0.10);
          }
          &.todo {
            border-color: $error;
            color: $error;
            background: rgba(244, 90, 90, 0.10);
          }
          &.in_progress {
            border-color: $primary;
            color: $primary;
            background: rgba(54, 141, 247, 0.10);
          }
          &.done {
            border-color: $success;
            color: $success;
            background: rgba(253, 176, 62, 0.10);
          }
        }
      }
      .gantt-link {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top:0;
        background-color: $blue-medium;
        height: 100%;
        width: 3rem;
      }
    }
  }
  .nothing {
    padding: 30px;
    background-color: #ffffff;
  }
}
</style>
