<template>
  <div class="companies hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="new-header grid-x">
      <div class="cell auto">
        <h1>Liste des partenaires</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToNewCompany">Ajouter un partenaire</app-button>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="body">
      <!-- CONTENT -->
      <div class="grid-x">
        <!-- SEARCH BAR -->
        <div class="cell small-3 search">
          <app-search
            v-model="search"
            placeholder="Rechercher un partenaire"
            size="auto"
          />
        </div>

        <div class="cell shrink">
          <app-button theme="secondary" size="small" @click="resetSearch">Réinitialiser</app-button>
        </div>

        <div class="cell auto"/>

        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterCompanies"
            @reset="resetFilters"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select :value="null" v-model="filters.selectedDepartment" :options="departments" />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select :value="null" v-model="filters.selectedCompanyCategoryType" :options="companyCategoryTypes" @input="filteredCompanyCategoriesByType" />
              </div>
            </div>
            <div class="grid-x grid-margin-x row" v-if="filteredCompanyCategories && filteredCompanyCategories.length > 1">
              <div class="cell">
                <app-select :value="null" v-model="filters.selectedCompanyCategory" :options="filteredCompanyCategories" />
              </div>
            </div>
          </filter-modal>
        </div>

        <!-- TOGGLE -->
        <div class="cell shrink align-self-middle">
          <app-toggle
            name='toggle'
            toggleType='text'
            :texts='Object.values(companyViews).filter((v) => member.isBrandAdmin ? v !== companyViews.AGENCY_REGISTERED : v !== companyViews.ALL)'
            v-model="selectedView"
            :selectedIcon="$route.query.view || member.isBrandAdmin ? 'Tous' : 'Répertoire'"
            @input="filterView"
          />
        </div>
      </div>

      <div>
        <!-- TABLE -->
        <app-table
          :headers="tableHeader"
          :data="companies.data"
          :loading="isLoading"
          @line-cliked="goToCompany"
          clickable
        >
          <template slot="loading">
            <app-spinner />
          </template>
          <template slot="favorite" slot-scope="{ data }">
            <favorite-empty :class="{'favorite-full': data.isFavorite}" @click.stop="updateFavorite(data)"/>
          </template>
          <template slot="company" slot-scope="{ data }">
            <span class="company-name">{{ data ? data.name.toUpperCase() : '' }}</span>
          </template>
          <template slot="address" slot-scope="{ data }">
            <span v-if="data.address">{{ data.address.postalCode }} {{ data.address.city }}</span>
          </template>
          <template slot="agency" slot-scope="{ data }">
            <span v-if="data.registeredAgency">{{ data.registeredAgency.name }}</span>
            <span v-else class="no-agency">Sans agence</span>
          </template>
          <template slot="phone" slot-scope="{ data }">
            <span>
            {{
              data.contacts && data.contacts.length && data.contacts.filter(c => c.isFavorite) && data.contacts.filter(c => c.isFavorite).length ?
                data.contacts.filter(c => c.isFavorite)[0].phone : ''
            }}
            </span>
          </template>
          <template slot="email" slot-scope="{ data }">
            <span>
            {{
              data.contacts && data.contacts.length && data.contacts.filter(c => c.isFavorite) && data.contacts.filter(c => c.isFavorite).length ?
                data.contacts.filter(c => c.isFavorite)[0].email : ''
            }}
            </span>
          </template>
          <template slot="categories" slot-scope="{ data }">
            <span>
            {{ getFullCompanyCategories(data.companyCategories).map(cc => cc ? cc.label : cc).join(', ') }}
            </span>
          </template>
          <template slot="projectAmount" slot-scope="{ data }">
            <strong class="project-amount">{{ data.projectAmount }}</strong>
          </template>
          <template slot="empty-table">
            <p>Aucun partenaire trouvé...</p>
          </template>
        </app-table>
      </div>

      <!-- PAGINATION -->
      <app-pagination
        v-if="companies.metadata"
        :limit="limit"
        :offset="companies.metadata.offset"
        :count="companies.metadata.count"
      />

    </div>
  </div>
</template>

<script>
import FilterModal from '@/components/general/FilterModal.vue';

import utils from '@/services/utils/utils';
import companyApi from '@/services/api/company';
import types from '@/services/data/types.json';
import companyCategoryApi from '@/services/api/companyCategory';
import favoriteApi from '@/services/api/favorite';
import departments from '@/services/data/departments.json';
import companyViews from '@/services/enums/companyViews.enum';
import memberApi from '@/services/api/member';
import FavoriteEmpty from '@/assets/logos/favoriteEmpty.svg?inline';

export default {
  name: 'companies',

  components: {
    FilterModal,
    FavoriteEmpty,
  },

  metaInfo: {
    title: 'Liste des partenaires',
  },

  data() {
    return {
      isLoading: null,

      companies: {
        data: null,
        metatdata: null,
      },
      limit: 25,
      search: null,

      filters: {
        selectedCompanyCategoryType: null,
        selectedCompanyCategory: null,
        selectedDepartment: null,
      },
      selectedView: null,

      types,
      typesParsed: null,
      companyCategoryTypes: [],

      jsonDepartments: departments,
      departments: [],

      companyCategories: [],
      filteredCompanyCategories: [],

      companyViews,
      member: {
        isBrandAdmin: true,
      },
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },

  async mounted() {
    this.typesParsed = Object.entries(types.companyCategoryType);

    // Formattage de la liste déroulante pour les types
    this.companyCategoryTypes = utils.formatOptions(
      this.typesParsed,
      (o) => o[0],
      (o) => o[1],
      { text: 'Choisir le type' },
    );

    // Formattage de la liste déroulante pour les départements
    this.departments = utils.formatOptions(
      this.jsonDepartments,
      (o) => o.code,
      (o) => `${o.code} - ${o.name}`,
      { text: 'Choisir le département' },
    );
    this.isLoading = true;

    // Récupération des données de l'utilisateur
    await this.getMember();

    // Initialisation des champs de recherche et des filtres
    this.search = this.$route.query.search || null;
    this.filters.selectedDepartment = this.$route.query.postalCode || null;
    this.filters.selectedCompanyCategoryType = this.$route.query.type || null;
    this.filters.selectedCompanyCategory = this.$route.query.category || null;
    this.selectedView = this.$route.query.view || this.member.isBrandAdmin ? 'Tous' : 'Répertoire';

    // Récupération des autres données
    await this.getCompanies();
    await this.getCompanyCategories();

    this.isLoading = false;
  },

  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getCompanies();
        this.isLoading = false;
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
  },

  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    tableHeader() {
      const tableHeader = [
        { label: 'Favoris', key: 'favorite', size: 'shrink' },
        { label: 'Partenaire', key: 'company', size: 'auto' },
        { label: 'Commune', key: 'address', size: 'auto' },
        { label: 'Agence', key: 'agency', size: 'auto' },
        { label: 'Tél.', key: 'phone', size: 'auto' },
        { label: 'Email', key: 'email', size: 'auto' },
        { label: 'Métiers', key: 'categories', size: 'auto' },
        { label: 'Chantiers', key: 'projectAmount', size: 1 },
      ];
      return tableHeader;
    },
  },

  methods: {
    // Redirection vers la page d'édition d'un partenaire
    goToCompany(selectedCompany) {
      this.$router.push({ name: 'companies-companyId', params: { companyId: selectedCompany.companyId } });
    },
    // Redirection vers la page de création d'un partenaire
    goToNewCompany() {
      this.$router.push({ name: 'companies-new' });
    },
    // Reset de la query de recherche
    resetSearch() {
      this.search = undefined;

      if (this.$route.query.search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: undefined,
            page: 1,
          },
        });
      }
    },
    // Reset des filtres
    resetFilters() {
      // Reset des valeurs du filtre
      this.filters = {
        selectedDepartment: null,
        selectedCompanyCategoryType: null,
        selectedCompanyCategory: null,
      };

      // Vérification que la route n'est pas la même qu'avant pour ne pas effectuer une requête pour rien
      if ((this.$route.query.type !== this.filters.selectedCompanyCategoryType && (this.$route.query.type || this.filters.selectedCompanyCategoryType))
          || (this.$route.query.postalCode !== this.filters.selectedDepartment && (this.$route.query.postalCode || this.filters.selectedDepartment))
          || (this.$route.query.category !== this.filters.selectedCompanyCategory && (this.$route.query.category || this.filters.selectedCompanyCategory))
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            postalCode: undefined,
            type: undefined,
            category: undefined,
            page: 1,
          },
        });
      }
    },
    closeFilterModal() {
      // Réaffectation des valeurs actuelles de la requête dans la modale à sa fermeture
      this.filters.selectedDepartment = this.$route.query.postalCode || null;
      this.filters.selectedCompanyCategoryType = this.$route.query.type || null;
      this.filters.selectedDepartment = this.$route.query.category || null;
    },
    // Mise à jour de la query de recherche générale
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
            page: 1,
          },
        });
      }
    },
    // Mise à jour des données avec les filtres
    filterCompanies() {
      // Vérification que la route n'est pas la même qu'avant pour ne pas effectuer une requête pour rien
      if ((this.$route.query.type !== this.filters.selectedCompanyCategoryType && (this.$route.query.type || this.filters.selectedCompanyCategoryType))
          || (this.$route.query.postalCode !== this.filters.selectedDepartment && (this.$route.query.postalCode || this.filters.selectedDepartment))
          || (this.$route.query.category !== this.filters.selectedCompanyCategory && (this.$route.query.category || this.filters.selectedCompanyCategory))
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            postalCode: this.filters.selectedDepartment || undefined,
            type: this.filters.selectedCompanyCategoryType || undefined,
            category: this.filters.selectedCompanyCategory || undefined,
            page: 1,
          },
        });
      }
    },
    filterView() {
      if (this.selectedView !== this.$route.query.view) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            view: this.selectedView || undefined,
            page: 1,
          },
        });
      }
    },
    async getMember() {
      try {
        this.member = await memberApi.getMe();
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du chargement des données de l\'utilisateur',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Récupération des partenaires
    async getCompanies() {
      try {
        this.companies = await companyApi.getAll(
          this.$route.query.type,
          this.limit,
          this.computeOffset,
          this.$route.query.search,
          this.$route.query.postalCode,
          this.$route.query.category,
          Object.keys(companyViews).find((key) => companyViews[key] === (this.$route.query.view || this.selectedView)),
          false,
        );
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du chargement des partenaires',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Récupération des catégories de partenaire
    async getCompanyCategories() {
      try {
        let allCompanyCategories = await companyCategoryApi.getAll();

        // Parcours de la liste des catégories et récupération du nom complet
        allCompanyCategories = allCompanyCategories.map((category) => {
          const typeName = this.typesParsed.find((type) => type[0] === category.type);
          return { ...category, typeName: typeName[1] };
        });
        this.companyCategories = allCompanyCategories.sort((a, b) => a.name.localeCompare(b.name));

        // Formattage de la liste déroulante pour les catégories
        this.filteredCompanyCategories = utils.formatOptions(
          this.companyCategories,
          (o) => o.companyCategoryId,
          (o) => `${o.label} (${o.typeName})`,
          { text: 'Choisir la catégorie' },
        );
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors du chargement des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    getFullCompanyCategories(selectedCompanyCategories) {
      return selectedCompanyCategories.map((selectedCompanyCategory) => this.companyCategories.find((cc) => cc.companyCategoryId === selectedCompanyCategory));
    },
    // Mise à jour des partenaires favoris
    async updateFavorite(selectedCompany) {
      const index = this.companies.data.map((e) => e.companyId).indexOf(selectedCompany.companyId);

      if (selectedCompany.isFavorite) {
        try {
          await favoriteApi.delete({ companyId: selectedCompany.companyId });
          this.companies.data[index].isFavorite = false;
        } catch (error) {
          //
        }
      } else {
        try {
          await favoriteApi.create({ companyId: selectedCompany.companyId });
          this.companies.data[index].isFavorite = true;
        } catch (error) {
          //
        }
      }
    },
    // Mise à jour des catégories lors du choix du type de catégorie
    filteredCompanyCategoriesByType(value) {
      // Reset de la catégorie
      this.filters.selectedCompanyCategory = null;
      this.$route.query.category = undefined;

      if (!value) {
        // Formattage de la liste déroulante pour les catégories
        this.filteredCompanyCategories = utils.formatOptions(
          this.companyCategories,
          (o) => o.companyCategoryId,
          (o) => `${o.label} (${o.typeName})`,
          { text: 'Choisir la catégorie' },
        );
      } else {
        // Récupération de toutes les catégories qui sont du type choisi
        const tmpCompanyCategories = this.companyCategories.filter((companyCat) => companyCat.type === value);

        // Formattage de la liste déroulante pour les catégories
        this.filteredCompanyCategories = utils.formatOptions(
          tmpCompanyCategories,
          (o) => o.companyCategoryId,
          (o) => `${o.label} (${o.typeName})`,
          'Choisir la catégorie',
        );
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.companies
  .body
    @include screen
  .new-header
    @include header
  .row
    @include row

  .search
    padding-bottom: 1rem
    margin-right: 1rem
    button
      margin-left: 1rem
  .company-name
    color: $blue-medium
    font-weight: bold
  .project-amount
    background-color: grey
    border-radius: 34px
    color: white!important
    padding: 10px 15px 10px 15px
  span
    box-sizing: border-box
    color: $gray-medium
    display: block
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  .no-agency
    color: red
  .filter-container
    display: flex
    align-items: center
    justify-content: center
    margin-right: 1rem
  .filter-btn
    margin-left: 1rem
  svg
    width: 30px
  .agency-registered-full
    path
      fill: $blue-medium
      stroke: none
  .favorite-full
    width: 30px
    path
      fill: $blue-medium
</style>
