<template>
  <div class="spinner">
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="length" fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <img class="logo" :src="hedifys" alt="" width="20">
  </div>
</template>
<script>
import logos from '@/assets/logos';

export default {
  name: 'spinner',
  data() {
    return {
      hedifys: logos.hedifys,
    };
  },
};
</script>
<style lang="scss">
$spinnerColors: #3c455b, #0C457F, #297DB9, #51ADF8;
$d: 175.6449737548828;
.spinner {
  position: relative;
  svg {
    width: 50px;
    height: 50px;

    left: 0;
    top: 0;
    position: absolute;

    transform: rotate(-90deg);

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) circle {
        stroke: nth($spinnerColors, $i);
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;

        animation: strokeanim 0.8s calc(.05s * (#{$i})) ease infinite;
        transform-origin: center center;
      }
    }
  }
  img {
    margin: 15px;
  }
}

@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -$d / 3;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -$d;
  }
}

@keyframes contanim {
  100% {
    transform: rotate(360deg)
  }
}
</style>
