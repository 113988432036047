import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère les packs
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/packs`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère un pack
 * @param {string} packId: id du pack à récupérer
 */
const getById = async (packId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/packs/${packId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère les pré-selections d'un pack
 * @param {string} packId: id du pack
 */
const getPreSelections = async (packId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/packs/${packId}/pre-selections`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère les pré-selections d'un pack
 * @param {string} packId: id du pack
 */
const updatePreSelections = async (packId, data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/packs/${packId}/pre-selections`, data);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'un pack
 * @param {agency} pack: nouvel objet du pack
 * @param {string} packId: id du pack à mettre à jour
 */
const update = async (packId, pack) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/packs/${packId}`, pack);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression d'un pack
 * @param {string} packId: id du pack à supprimer
 */
const deleteOne = async (packId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/packs/${packId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Crée un nouveau pack
 * @param {objet} pack pack à créer
 */
const create = async (pack) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/packs`, pack);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const pack = {};
pack.getAll = getAll;
pack.getPreSelections = getPreSelections;
pack.updatePreSelections = updatePreSelections;
pack.getById = getById;
pack.create = create;
pack.delete = deleteOne;
pack.update = update;

export default pack;
