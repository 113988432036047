<template>
  <app-modal
    class="select-customer-modal"
    size="large"
    :show="show"
    :title="title"
    @update:show="$emit('update:show', $event)"
  >
    <div class="search-container">
      <app-search v-model="search" placeholder="Rechercher un client"/>
    </div>
    <app-table
      :headers="headers"
      :data="customers"
      :loading="isLoading || isParentLoading"
      @line-cliked="selectedCustomer = $event"
      :selectedValue="selectedCustomer ? selectedCustomer.customerId : null"
      clickable
    >
      <template slot="loading">
        <app-spinner />
      </template>
      <template slot="empty-table">
        <p>Aucun client trouvé</p>
      </template>
      <template v-slot:name="{ data }">
        <p>{{ data.firstname }} {{ data.lastname }}</p>
      </template>
    </app-table>
    <app-pagination v-bind="metadata" ignoreRoute @update:page="getCustomers($event)"/>
    <app-button theme="primary" size="large" icon="add" @click="$emit('input', selectedCustomer)">Sélectionner le client</app-button>
  </app-modal>
</template>

<script>
import customerApi from '@/services/api/customer';
import utils from '@/services/utils/utils';

export default {
  name: 'select-customer-modal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Choisir un client',
    },
    isParentLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCustomer: this.value,
      isLoading: false,
      isError: false,
      customers: [],
      search: null,
      metadata: {
        limit: 5,
        offset: 0,
        count: 0,
      },

      headers: [
        { label: 'Référence', key: 'reference', size: '5' },
        { label: 'Nom', key: 'name', size: 'auto' },
      ],
    };
  },
  watch: {
    value() {
      this.selectedCustomer = this.value;
    },
    show() {
      if (this.show) {
        this.getCustomers();
      } else {
        this.metadata.offset = 0;
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    computeOffset(page) {
      if (page) {
        return (page - 1) * this.metadata.limit;
      }
      return null;
    },
    async getCustomers(page) {
      this.isError = false;
      this.isLoading = true;
      const offset = this.computeOffset(page);
      try {
        const customers = await customerApi.getAll(this.metadata.limit, offset, this.search);
        this.customers = customers.data;
        this.metadata = customers.metadata;
      } catch (er) {
        this.isError = true;
      }
      this.isLoading = false;
    },
    // Rafraichit la liste de façon limitée
    updateSearchQuery() {
      this.getCustomers(0);
    },
  },
};
</script>

<style lang="sass">
.select-customer-modal
  .header
    background-color: transparent
    box-shadow: none
  .search-container
    display: flex
    justify-content: center
  .modal-wrapper
    > .app-table
      margin: 2rem 0
    > .app-button
      margin: 3rem auto 0
</style>
