<template>
  <div class="versions hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Versions</h1>
      </div>
      <div class="cell shrink">
        <app-button v-if="isBrandAdmin" theme="primary" size="large" icon="add" @click="goToVersion">Ajouter une version</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES VERSIONS -->
    <div class="body">
      <div class="search grid-x">
        <div class="cell shrink search">
          <app-search size="small" v-model="search" placeholder="Rechercher une version" />
        </div>
        <!-- FILTER MODAL -->
        <div class="cell shrink button">
          <filter-modal @change="filterVersions" @reset="resetFilers" @close="closeFilterModal" :numberOfInputs="Object.values(filters).filter(i => !!i).length">
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-radio-button
                  label="Filtrer par prix"
                  v-model="filters.order"
                  :input="filters.order"
                  :options="[
                    { name: 'price', label: 'Prix croissant' },
                    { name: '-price', label: 'Prix décroissant' }
                  ]"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Gamme"
                  v-model="filters.rangeId"
                  @input="resetModelOptions"
                  :options="rangeOptions"
                  :disabled="rangeOptions.length === 0"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Modèle"
                  v-model="filters.modelId"
                  :options="modelOptions"
                  :disabled="modelOptions.length === 0"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Style"
                  v-model="filters.styleId"
                  :options="styleOptions"
                  :disabled="styleOptions.length === 0"
                />
              </div>
            </div>
          </filter-modal>
        </div>
      </div>
      <app-table :headers="tableHeaders" :data="versions.data" :loading="isLoading" @line-cliked="goToVersion" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="versionMedia" slot-scope="{ data }">
          <img class="image" v-if="data.versionMedia" :src="data.versionMedia" alt="Visuel de la version" />
          <div v-else class="image not-found">
            <upload class="icon-upload" />
          </div>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="reference" slot-scope="{ data }">
          <strong>{{ data.reference }}</strong>
        </template>
        <template slot="range" slot-scope="{ data }">
          <p>{{ data.range.name }}</p>
        </template>
        <template slot="surface" slot-scope="{ data }">
          <p>{{ data.surface }} m²</p>
        </template>
        <template slot="price" slot-scope="{ data }">
          <strong v-if="data.price || data.price === 0" class="price">{{ utils.formatCentToEuro(data.price, true) }}</strong>
          <app-spinner v-else small />
        </template>
        <template slot="surfaceHabitable" slot-scope="{ data }">
          <p v-if="data.surfaces && data.surfaces.length && data.price">
            {{
              data.surfaces && data.surfaces.length && data.price ?
                getPriceBySurface(data)
              : '0 €'
            }} /m²
          </p>
          <p v-else>0 €/m²</p>
        </template>
      </app-table>
      <app-pagination
        v-if="versions.metadata"
        :limit="limit"
        :offset="versions.metadata.offset"
        :count="versions.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import FilterModal from '@/components/general/FilterModal.vue';
import utils from '@/services/utils/utils';
import versionApi from '@/services/api/version';
import range from '@/services/api/range';
import model from '@/services/api/model';
import style from '@/services/api/style';
import memberApi from '@/services/api/member';

import upload from '@/assets/img/upload.svg?inline';

export default {
  name: 'versions',
  components: {
    FilterModal,
    upload,
  },
  metaInfo: {
    title: 'Versions',
  },
  data() {
    return {
      versions: {
        data: null,
        metadata: null,
      },
      limit: 6,
      tableHeaders: [
        { label: 'Visuel', key: 'versionMedia', size: 2 },
        { label: 'Version', key: 'name', size: 3 },
        { label: 'Référence', key: 'reference', size: 3 },
        { label: 'Gamme', key: 'range', size: 1 },
        { label: 'Surface', key: 'surface', size: 1 },
        { label: 'Prix', key: 'price', size: 1 },
        { label: 'Prix/m2', key: 'surfaceHabitable', size: 1 },
      ],
      version: {},
      isLoading: true,
      styles: [],
      models: [],
      ranges: [],
      search: null,
      filters: {
        order: null,
        rangeId: null,
        modelId: null,
        styleId: null,
      },
      rangeOptions: [],
      modelOptions: [],
      styleOptions: [],
      utils,

      pageIsInit: !!this.$route.query.page,
      me: null,
      isBrandAdmin: null,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  mounted() {
    this.search = this.$route.query.search || null;
    this.filters.order = this.$route.query.order || null;
    this.filters.rangeId = this.$route.query.rangeId || null;
    this.filters.modelId = this.$route.query.modelId || null;
    this.filters.styleId = this.$route.query.styleId || null;

    this.getData();
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    $route() {
      if (!this.isLoading) {
        if (this.pageIsInit) {
          this.getData();
        } else {
          this.pageIsInit = true;
        }
      }
    },
    filters: {
      handler(value) {
        this.filters.rangeId = value.rangeId;
        this.filters.modelId = value.modelId;
        this.filters.styleId = value.styleId;
        this.filters.order = value.order;
      },
      deep: true,
    },
    'filters.rangeId': {
      handler() {
        this.modelOptions = this.getModelOptions();
      },
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getMe();
      await this.getRanges();
      await this.getModels();
      await this.getStyles();
      this.rangeOptions = utils.formatOptions(
        this.ranges,
        (option) => option.rangeId,
        (option) => option.name,
        'choose',
      );
      this.styleOptions = utils.formatOptions(
        this.styles,
        (option) => option.styleId,
        (option) => option.name,
        'choose',
      );
      this.modelOptions = await this.getModelOptions();
      await this.getVersions();
      this.isLoading = false;
    },
    getPriceBySurface(version) {
      return (
        utils.formatCentToEuro(
          (version.price)
          / (
            version.surfaces.reduce((a, s) => (s.room && s.room.type === 'HABITABLE' ? s.area + a : a), 0)
            + (version.surfaces.reduce((a, s) => (s.room && s.room.type === 'NON_HABITABLE' ? s.area + a : a), 0) * 2)
          ),
          true,
          true,
        )
      );
    },
    async getRanges() {
      try {
        this.ranges = await range.getAll();
      } catch (error) {
        throw error;
      }
    },
    async getModels() {
      try {
        const models = await model.getAll(null, null, null, null, true);
        this.models = models.data;
      } catch (error) {
        throw error;
      }
    },
    async getStyles() {
      try {
        this.styles = await style.getAll();
      } catch (error) {
        throw error;
      }
    },
    getModelOptions() {
      if (this.filters.rangeId && this.filters.rangeId !== 'choose') {
        return utils.formatOptions(
          this.models.filter((modelOption) => (modelOption.range.rangeId === this.filters.rangeId)),
          (option) => option.modelId,
          (option) => option.name,
          'choose',
        );
      }

      return utils.formatOptions(
        this.models,
        (option) => option.modelId,
        (option) => option.name,
        'choose',
      );
    },
    filterVersions() {
      this.$router.push({
        query: {
          ...this.$route.query,
          order: this.filters.order || undefined,
          rangeId: this.filters.rangeId || undefined,
          modelId: this.filters.modelId || undefined,
          styleId: this.filters.styleId || undefined,
          page: 1,
        },
      });
      this.isLoading = true;
      this.getVersions();
      this.isLoading = false;
    },
    closeFilterModal() {
      this.filters.order = this.$route.query.order || null;
      this.filters.rangeId = this.$route.query.rangeId || null;
      this.filters.modelId = this.$route.query.modelId || null;
      this.filters.styleId = this.$route.query.styleId || null;
    },
    async resetFilers() {
      this.filters = {
        order: null,
        rangeId: null,
        modelId: null,
        styleId: null,
      };
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          order: this.filters.order || undefined,
          rangeId: this.filters.rangeId || undefined,
          modelId: this.filters.modelId || undefined,
          styleId: this.filters.styleId || undefined,
        },
      });
      this.isLoading = true;
      this.getVersions();
      this.isLoading = false;
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
            page: 1,
          },
        });
      }
    },
    resetModelOptions() {
      this.filters.modelId = undefined;
    },
    async getVersions() {
      this.isLoading = true;
      try {
        const response = await versionApi.getAll(
          this.$route.query.search,
          this.$route.query.rangeId,
          this.$route.query.modelId,
          null,
          this.$route.query.styleId,
          null,
          this.$route.query.order,
          this.computeOffset,
          this.limit,
        );
        this.versions = response.data;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des versions',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    goToVersion(selectedVersion) {
      if (selectedVersion && selectedVersion.versionId) {
        this.$router.push({ name: 'versions-versionId-summary', params: { versionId: selectedVersion.versionId } });
      } else {
        this.$router.push({ name: 'versions-new' });
      }
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.versions
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .search
    padding-bottom: 1rem
    .button
      margin-left: auto
  .price
    color: $primary
  img
    border-radius: 4px
  .image
    object-fit: cover
    width: 130px
    height: 90px
    border: 1px solid $line
    border-radius: 8px
  .not-found
    @include centered-container
    svg
      width: 50px
</style>
