<template>
  <div class="company-pdf">
    <div class="header">
      <div class="header-container-logo">
        <img v-if="brand" :src="brand.logo" alt="Logo de la marque Maisons Alysia">
      </div>
      <div v-if="company" class="header-container-main">
        <p class="header-title">PLANNING PARTENAIRE - {{ $dayjs().format('DD MMMM YYYY') }}</p>
        <p class="primary">{{ company.name }}</p>
        <p>{{ company.address.addressLine1 }} {{ company.address.postalCode }} {{ company.address.city }}</p>
        <p v-if="contacts">{{contacts[0].firstname[0]}}. {{ contacts[0].lastname }} - {{ contacts[0].email}} {{contacts[0].phone}}</p>
      </div>
      <div v-if="me" class="header-container-agency">
        <p class="edited-by">EDITÉ PAR :</p>
        <p class="primary">{{ me.firstname }} {{ me.lastname }}</p>
        <p>{{ me.email }}</p>
        <p>{{ me.phone }}</p>
      </div>
    </div>
    <div class="loader-container" v-if="isLoading">
      <spinner></spinner>
    </div>
    <div class="body" v-if="projectSteps.length > 0 && !isLoading" >
      <div class="table-headers">
        <div class="table-headers-column status-column">Statut</div>
        <div class="table-headers-column start-column">Début</div>
        <div class="table-headers-column end-column">Fin</div>
        <div class="table-headers-column project-column">Chantier</div>
        <div class="table-headers-column customer-column">Client</div>
        <div class="table-headers-column task-column">Tâches</div>
      </div>
      <div v-for="(week, index) in weeks" :key="index">
        <div class="header-weekly" v-if="week">
          <span class="week">{{`Semaine ${week.split('-')[1]}`}}</span>
        </div>
        <div class="project-step" v-for="(projectStep) in filterProjectsByWeek(week)" :key="projectStep.projectStepId">
          <div v-if="projectStep" class="cell status-cell">
            <div :class="getStatusClass(projectStep)">{{ getStatusLabel(projectStep) }}</div>
            <p>{{ projectStep.confirmed ?
            `Demande du ${$dayjs(projectStep.confirmedAt).format('DD/MM/YYYY')}`
            : 'Demande d\'intervention'
            }}</p>
          </div>
          <div class="cell start-cell">
            <p class="week-period">S{{ $dayjs(projectStep.startedAt).isoWeek() }}</p>
            <p>{{ $dayjs(projectStep.startedAt).format('DD-MM-YYYY') }}</p>
          </div>
          <div class="cell end-cell">
            <p class="week-period">S{{ $dayjs(projectStep.closedAt).isoWeek() }}</p>
            <p>{{ $dayjs(projectStep.closedAt).format('DD-MM-YYYY') }}</p>
          </div>
          <div class="cell project-cell">
            <p class="city">{{ projectStep.project.address.city }}</p>
            <p>{{ projectStep.project.address.addressLine1 }}</p>
          </div>
          <div class="cell customer-cell">
            <p class="primary">{{projectStep.customer.lastname}}</p>
            <p>Réf : {{projectStep.project.reference}} -  {{projectStep.agency.name}} </p>
          </div>
          <div class="cell task-cell">
            <p class="label-task">{{ projectStep.label }}</p>
            <p v-if="projectStep.isPublicComment" class="label-comment">{{ projectStep.comment }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-padding-small" v-if="projectSteps.length === 0 && !isLoading">
      <p>Aucune tâche pour cette entreprise.</p>
    </div>
    <div class="pdf-actions" v-if="$route.query.isHtml">
      <button type="button" class="format-choice active uk-button uk-button-default light" @click="goBack">Annuler</button>
      <button type="button" class="format-choice active uk-button uk-button-default"  @click="getPdfLink">Télécharger</button>
      <button type="button" class="format-choice active uk-button uk-button-default" @click="showPdfModal = true">Envoyer</button>
    </div>
    <pdf-send-modal
      v-if="$route.query.isHtml && brand && company && weeks && weeks.length"
      :show="showPdfModal"
      :company="company"
      :project="$route.query.project"
      :project-steps="projectSteps"
      :brand="brand"
      @close="showPdfModal = false"
      @update="getData"
      :firstWeek="weeks[0]"
      :lastWeek="weeks[weeks.length - 1]"
    />
    </div>
</template>

<script>
import company from '@/services/api/company';
import projectStep from '@/services/api/projectStep';
import auth from '@/services/auth';
import member from '@/services/api/member';
import pdfSendModal from '@/views/pdf/PdfSendModal.vue';
import Spinner from '@/components/general/Spinner.vue';
import contact from '@/services/api/contact';

export default {
  name: 'company-projects',
  components: {
    pdfSendModal,
    Spinner,
  },
  data() {
    return {
      projects: [],

      activeFilter: 'all',

      errorForbidden: false,
      showPdfModal: false,

      company: null,
      contacts: null,
      me: null,
      brand: null,
      projectSteps: [],
      weeks: [],
      firstWeek: null,
      lastWeek: null,

      isLoading: false,
    };
  },
  async created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        this.me = await member.getMe();
        this.brand = await auth.getBrand();
      } catch (er) {
        //
      }

      try {
        this.projectSteps = await projectStep.getByCompany(this.$route.params.companyId);
      } catch (er) {
        this.$displayNotification('Une erreur est survenue. Veuillez recharger la page.');
      }

      this.sortByProjects();

      if (this.$route.query.project) {
        this.projects = this.projects.filter((p) => p.projectId === this.$route.query.project);
        this.projectSteps = this.projects[0].projectSteps;
      }

      if (this.$route.query.onlyMember && (this.$route.query.onlyMember === true || this.$route.query.onlyMember === 'true')) {
        this.projects = this.projects.filter((p) => p.member.memberId === this.me.memberId);
        this.projectSteps = this.projectSteps.filter((p) => p.member.memberId === this.me.memberId);
      }

      try {
        let favorites = null;
        let companyContacts = [];

        this.company = await company.getById(this.$route.params.companyId);

        const allCompanyContacts = await contact.getByCompany(this.company.companyId);

        if (allCompanyContacts && allCompanyContacts.length) {
          favorites = allCompanyContacts.filter((m) => m.isFavorite);

          if (favorites && favorites.length) {
            companyContacts = favorites;
          } else {
            companyContacts.push(allCompanyContacts[0]);
          }

          this.contacts = companyContacts;
        }
      } catch (er) {
        this.$displayNotification('Une erreur est survenue. Veuillez recharger la page.');
      }
      this.projectSteps = this.projectSteps.filter((step) => step.status !== 'DONE');
      if (this.$route.query.startDate) {
        this.projectSteps = this.projectSteps.filter((step) => this.$dayjs(step.startedAt).isAfter(this.$dayjs.unix(this.$route.query.startDate), 'day')
          || this.$dayjs(step.startedAt).isSame(this.$dayjs.unix(this.$route.query.startDate), 'day') || (this.$dayjs(step.closedAt).isAfter(this.$dayjs.unix(this.$route.query.startDate), 'day') && this.$dayjs(step.startedAt).isBefore(this.$dayjs.unix(this.$route.query.startDate), 'day'))
          || step.type === 'START' || step.type === 'CLOSE' || step.startStatus === 'LATE' || step.closeStatus === 'LATE' || step.closeStatus === 'IN_PROGRESS');
      }

      if (this.$route.query.endDate) {
        this.projectSteps = this.projectSteps.filter((step) => this.$dayjs(step.closedAt).isBefore(this.$dayjs.unix(this.$route.query.endDate), 'day')
          || this.$dayjs(step.closedAt).isSame(this.$dayjs.unix(this.$route.query.endDate), 'day') || (this.$dayjs(step.startedAt).isBefore(this.$dayjs.unix(this.$route.query.endDate), 'day') && this.$dayjs(step.closedAt).isAfter(this.$dayjs.unix(this.$route.query.endDate), 'day'))
          || step.type === 'START' || step.type === 'CLOSE' || step.startStatus === 'LATE' || step.closeStatus === 'LATE' || step.closeStatus === 'IN_PROGRESS');
      }

      this.getWeeks();
      this.isLoading = false;
    },
    goBack() {
      this.$router.back();
    },
    getPdfLink() {
      const {
        endDate, startDate, project, onlyMember,
      } = this.$route.query;

      const {
        companyId,
      } = this.$route.params;

      const baseServer = process.env.VUE_APP_API_URL;
      let route = '';
      let selectParams = '';

      /* Création de l'url pour la vue projet */
      route = `/pdf/partenaires/${companyId}`;

      selectParams = project && !onlyMember ? `&project=${project}` : '';
      const query = `?token=${auth.getToken()}${selectParams}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${onlyMember ? '&onlyMember=true' : ''}`;

      window.open(baseServer + route + query, '_blank');
    },
    sortByProjects() {
      const projectIds = [];
      this.projects = [];

      this.projectSteps.forEach((pS) => {
        if (!projectIds.includes(pS.project.projectId)) {
          projectIds.push(pS.project.projectId);
        }
      });

      projectIds.forEach((projectId) => {
        const projectStepsByProjectId = this.projectSteps.filter((pS) => pS.project.projectId === projectId);
        this.projects.push({
          customer: projectStepsByProjectId[0].customer,
          member: projectStepsByProjectId[0].member,
          ...projectStepsByProjectId[0].project,
          projectSteps: projectStepsByProjectId,
        });
      });
    },

    getWeeks() {
      // On récupère toutes les semaines à afficher
      this.projectSteps.forEach((pS) => {
        // Si la tâche s'étale sur plusieurs semaines
        const start = this.$dayjs(pS.startedAt);
        const close = this.$dayjs(pS.closedAt);

        if (start.isoWeek() !== close.isoWeek()) {
          // On récupère les semaines entre les 2 dates
          let weeksBetween = start.isBefore(close) ? close.diff(start, 'week') : start.diff(close, 'week');

          if (weeksBetween === 0) {
            weeksBetween = 1;
          }

          const arrayOfInt = Array.from(new Array(weeksBetween + 1), (x, i) => i);

          let nextDate = start;
          arrayOfInt.forEach((int, index) => {
            if (index !== 0) {
              nextDate = nextDate.add(1, 'week');
            }
            const year = nextDate.startOf('isoWeek').year();
            const weekString = `${year}-${nextDate.isoWeek()}`;
            if (this.weeks.indexOf(weekString) === -1) {
              // On vérifie si le n° de la semaine a déjà été enregistré
              this.weeks.push(weekString);
            }
          });
        } else {
          const year = start.startOf('isoWeek').year();
          const weekString = `${year}-${start.isoWeek()}`;
          if (this.weeks.indexOf(weekString) === -1) {
            // On vérifie si le n° de la semaine a déjà été enregistré
            this.weeks.push(weekString);
          }
        }
      });

      this.weeks.sort((a, b) => {
        const [yearA, monthA] = a.split('-');
        const [yearB, monthB] = b.split('-');

        const monthA2digits = monthA.padStart(2, '0');
        const monthB2digits = monthB.padStart(2, '0');

        return parseInt(`${yearA}${monthA2digits}`, 10) - parseInt(`${yearB}${monthB2digits}`, 10);
      });
    },

    getWeekDate(weekString) {
      const year = weekString.split('-')[0];
      const week = weekString.split('-')[1];

      const startWeek = this.$dayjs().year(year).isoWeek(week).startOf('isoWeek')
        .format('MMMM');
      const endWeek = this.$dayjs().year(year).isoWeek(week).endOf('isoWeek')
        .format('MMMM');

      if (startWeek !== endWeek) {
        return `du ${this.$dayjs().year(year).isoWeek(week).startOf('isoWeek')
          .format('DD MMMM YYYY')} au ${this.$dayjs().year(year).isoWeek(week).endOf('isoWeek')
          .format('DD MMMM YYYY')}`;
      }

      return `du ${this.$dayjs().year(year).isoWeek(week).startOf('isoWeek')
        .format('DD')} au ${this.$dayjs().year(year).isoWeek(week).endOf('isoWeek')
        .format('DD MMMM YYYY')}`;
    },

    filterProjectsByWeek(weekString) {
      let monday = 0;
      let sunday = 0;

      const year = weekString.split('-')[0];
      const week = weekString.split('-')[1];

      if (week) {
        // On récupère le 1er jour de la semaine
        monday = this.$dayjs().year(year).isoWeek(week).startOf('isoWeek')
          .format();

        // On récupère le dernier jour de la semaine
        sunday = this.$dayjs().year(year).isoWeek(week).endOf('isoWeek')
          .format();
      }

      return this.projectSteps.filter((projects) => (
        (this.$dayjs(projects.startedAt).isBetween(this.$dayjs(monday), this.$dayjs(sunday))
              || this.$dayjs(projects.closedAt).isBetween(this.$dayjs(monday), this.$dayjs(sunday))
              || (this.$dayjs(projects.startedAt).isBefore(monday)
                && this.$dayjs(projects.closedAt).isAfter(sunday)
              )
        )
      ));
    },
    getStatusClass(pS) {
      let classToReturn = 'label-status ';
      if (pS.statusToDisplay === 'FORECAST') {
        classToReturn += 'forecast';
      }
      if (pS.statusToDisplay === 'PLANNED') {
        classToReturn += 'planned';
      }
      if (pS.statusToDisplay === 'NEW') {
        classToReturn += 'new';
      }
      if (pS.statusToDisplay === 'LATE') {
        classToReturn += 'late';
      }
      if (pS.statusToDisplay === 'IN_PROGRESS') {
        classToReturn += 'in-progress';
      }
      return classToReturn;
    },
    getStatusLabel(pS) {
      let labelToReturn = '';
      if (pS.statusToDisplay === 'FORECAST') {
        labelToReturn = 'Prévision';
      }
      if (pS.statusToDisplay === 'PLANNED') {
        labelToReturn = 'Planifié';
      }
      if (pS.statusToDisplay === 'NEW') {
        labelToReturn = 'Nouveau';
      }
      if (pS.statusToDisplay === 'LATE') {
        labelToReturn = 'Retard';
      }
      if (pS.statusToDisplay === 'IN_PROGRESS') {
        labelToReturn = 'En cours';
      }
      return labelToReturn;
    },
  },
};
</script>

<style lang="sass" scoped>
.company-pdf
  padding: 20px
  background-color: #FFF!important
  .header
    height: 60px
    display: flex
    justify-content: space-between
    align-items: center
    .header-container-logo
      flex: 1
      img
        width: 60%
    .header-container-main
      flex: 3
      p
        text-align: center
        margin: 0
        &.primary
          color: $primary!important
          font-weight: bold
          letter-spacing: 1px
          text-transform: uppercase
      .header-title
        font-size: 14px
        font-weight: bold
        letter-spacing: 1.2px
    .header-container-agency
      flex: 1
      p
        text-align: right
        margin: 0
        &.primary
          color: $primary!important
          font-weight: bold
          letter-spacing: 1px
          text-transform: uppercase
        &.edited-by
          color: $info!important
  .body
    margin-top: 20px
    .table-headers
      display: flex
      .table-headers-column
        background-color: $pdf-light-background!important
        font-size: 12px
        font-weight: bold
        letter-spacing: 1px
        text-transform: uppercase
        text-align: left
        padding: 6px
        &.status-column
          flex: 1
        &.start-column
          flex: 1
        &.end-column
          flex: 1
        &.project-column
          flex: 3
        &.customer-column
          flex: 3
        &.task-column
          flex: 3
    .header-weekly
      display: flex
      justify-content: center
      align-items: center
      height: 12px
      padding: 6px
      background-color: $label!important
      text-align: center
      .week
       color: #FFF!important
       text-transform: uppercase
       font-weight: 500
    .project-step
      display: flex
      flex-flow: row nowrap
      break-inside: avoid
      .cell
        border: 1px solid $line!important
        padding: 6px
        .week-period
          font-size: 20px
          padding: 5px
          font-weight: bold
          text-transform: uppercase
        &.status-cell
          flex: 1
          text-align: center
          .label-status
            display: flex
            justify-content: center
            align-items: center
            padding: 2px
            border-radius: 24px
            border: 2px solid
            &.new
              border-color: $error!important
              color: $error!important
              background: rgba(244, 90, 90, 0.10)!important
            &.late
              border-color: $warning!important
              color: $warning!important
              background: rgba(253, 176, 62, 0.10)!important
            &.planned
              border-color: $success!important
              color: $success!important
              background: rgba(253, 176, 62, 0.10)!important
            &.forecast
              border-color: $info!important
              color: $info!important
              background: rgba(159, 94, 209, 0.10)!important
            &.in-progress
              border-color: $primary!important
              color: $primary!important
              background: rgba(54, 141, 247, 0.10)!important
        &.start-cell
          flex: 1
          display: flex
          flex-flow: column nowrap
          justify-content: center
          align-items: center
        &.end-cell
          flex: 1
          display: flex
          flex-flow: column nowrap
          justify-content: center
          align-items: center
        &.project-cell
          flex: 3
          .city
            font-weight: bold
            text-transform: uppercase
        &.customer-cell
          flex: 3
          .primary
            color: $primary
            font-weight: bold
            letter-spacing: 1px
            text-transform: uppercase
        &.task-cell
          flex: 3
          .label-task
            font-weight: bold
          .label-comment
            color: red !important
button
  color: $white!important
  border-radius: 30px
  border: none
  background-color: $blue-medium!important
  margin-left: 20px

button.light
  background: $white!important
  color: $blue-dark!important

.pdf-actions
  position: fixed
  bottom: 30px
  right: 40px
  .action
    background-color: $blue-medium!important
</style>
