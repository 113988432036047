<template>
  <div class="more-options">
    <button @click="isOpen = !isOpen"
      @focusout="isOpen = false">
      <dots/>
    </button>
    <ul v-show="isOpen">
      <li class="option">
        <button @mousedown="$emit('update', id)">
          <draw/>
          <h5>Éditer</h5>
        </button>
      </li>
      <li class="option">
        <button @mousedown="$emit('delete', id)">
          <trash/>
          <h5>Supprimer</h5>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import dots from '@/assets/img/dots.svg?inline';
import draw from '@/assets/img/draw.svg?inline';
import trash from '@/assets/img/trash.svg?inline';

export default {
  name: 'more-options',
  components: {
    dots,
    draw,
    trash,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="sass">
.more-options
  >button
    margin-left: 16px
    background-color: transparent
    border: none
    border-radius: 50%
    padding: 4px
    &:hover
      background-color: rgba($info, 0.1)

  ul
    position: absolute
    top: 100%
    left: 50%
    width: 151px
    margin-top: 10px
    display: flex
    flex-direction: column
    padding: unset
    background-color: $off-white
    box-shadow: $drop-max
    border-radius: 4px
    z-index: 2

  .option
    cursor: pointer
    + .option
      border-top: 1px solid $line

    button,
    a
      display: flex
      background-color: transparent
      border: none
      margin: 0
      padding: 0
      width: 100%
      padding: 12px 8px
      &:hover
        background-color: rgba($info, 0.1)
    h5
      color: $secondary-light
      margin: auto 12px
</style>
