import axios from 'axios';
import favoriteContact from './favoriteContact';

const { VUE_APP_API_URL } = process.env;

const getByCompany = async (companyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/companies/${companyId}/contacts`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getById = async (contactId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/contacts/${contactId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const create = async (contact) => {
  try {
    const parsedContact = contact;
    const { isFavorite } = contact;
    delete parsedContact.isFavorite;
    const response = await axios.post(`${VUE_APP_API_URL}/contacts`, parsedContact);
    if (isFavorite) {
      // si on vient de créer un nouveau contact le mettant favoris, on ajoute la ligne dans les favoris
      await favoriteContact.create(response.data.data.contactId);
    }
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const edit = async (contact) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/contacts/${contact.contactId}`, contact);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const deleteOne = async (contactId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/contacts/${contactId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const customers = {};
customers.getByCompany = getByCompany;
customers.getById = getById;
customers.create = create;
customers.edit = edit;
customers.delete = deleteOne;

export default customers;
