<template>
  <div class="diffusion-quotas hedifys-21">
    <form ref="diffusion-quotas" @submit.prevent="save">

      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>Configuration diffusion</h1>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>

      <!-- TABS -->
      <tab-nav :tabs="tabs"/>

      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="grid-x title">
          <div class="cell auto">
            <h3>Quota des annonces par agence</h3>
          </div>
          <div class="cell shrink">
            <h3 class="quota-ubiflow">Total quotas Ubiflow : {{ totalUbiflowQuotas }}</h3>
          </div>
        </div>
        <div class="grid-x">
          <div class="cell small-5"></div>
          <div class="diffusers-header small-7">
            <div class="diffusers-title cell auto">
              <h6>Leboncoin</h6>
            </div>
            <div class="diffusers-title cell auto">
              <h6>Se loger</h6>
            </div>
            <div class="diffusers-title cell auto">
              <h6>Les Terrains.com</h6>
            </div>
          </div>
        </div>
        <div class="table grid-x">
          <div class="cell small-5">
            <app-table class="agencies" :headers="tableHeaders" :data="tableData" :loading="isLoading">
              <template slot="empty-table">
                <p>Aucune données de disponible</p>
              </template>
              <template slot="agency" slot-scope="{ data }">
                <strong>{{ data.agency }}</strong>
              </template>
              <template slot="city" slot-scope="{ data }">
                <p>{{ data.city }}</p>
              </template>
              <template slot="landVersionManual" slot-scope="{ data }">
                <app-input v-model.number="data.landVersionManual.value" type="number" placeholder="0" size="compact" />
              </template>
              <template slot="ubiflow" slot-scope="{ data }">
                <app-input v-model.number="data.ubiflow.value" type="number" placeholder="0" size="compact" />
              </template>
              <template slot="totalDiffusion" slot-scope="{ data }">
                <p>{{ computeTotalDiffusion(data.agencyId) }}</p>
              </template>
            </app-table>
          </div>
          <div class="cell small-7">
            <app-table class="diffusers" :headers="diffuserTableHeaders" :data="tableData" :loading="isLoading" quotas>
              <template slot="empty-table">
                <p>Aucune données de disponible</p>
              </template>
              <template slot="leboncoinLand" slot-scope="{ data }">
                <app-input v-model.number="data.leboncoinLand.value" type="number" placeholder="0" size="compact" />
              </template>
              <template slot="leboncoinLandVersion" slot-scope="{ data }">
                <app-input v-model.number="data.leboncoinLandVersion.value" type="number" placeholder="0" size="compact" />
              </template>
              <template slot="totalLeboncoin" slot-scope="{ data }">
                <p>{{ (data.leboncoinLandVersion.value + data.leboncoinLand.value) || 0 }}</p>
              </template>
              <template slot="seLogerLand" slot-scope="{ data }">
                <app-input v-model.number="data.seLogerLand.value" type="number" placeholder="0" size="compact" />
              </template>
              <template slot="seLogerLandVersion" slot-scope="{ data }">
                <app-input v-model.number="data.seLogerLandVersion.value" type="number" placeholder="0" size="compact" />
              </template>
              <template slot="totalSeLoger" slot-scope="{ data }">
                <p>{{ (data.seLogerLand.value + data.seLogerLandVersion.value) || 0 }}</p>
              </template>
              <template slot="lesTerrainsLand" slot-scope="{ data }">
                <app-input v-model.number="data.lesTerrainsLand.value" type="number" placeholder="0" size="compact" />
              </template>
              <template slot="lesTerrainsLandVersion" slot-scope="{ data }">
                <app-input v-model.number="data.lesTerrainsLandVersion.value" type="number" placeholder="0" size="compact" />
              </template>
              <template slot="totalLesTerrains" slot-scope="{ data }">
                <p>{{ (data.lesTerrainsLand.value + data.lesTerrainsLandVersion.value) || 0 }}</p>
              </template>
            </app-table>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import quotaApi from '@/services/api/quota';
import agencyApi from '@/services/api/agency';
import quotaType from '@/services/data/quotaType.json';

export default {
  name: 'diffusion-quotas',
  components: {
    TabNav,
  },
  metaInfo: {
    title: 'Configuration diffusion',
  },
  data() {
    return {
      quotas: [],
      agencies: [],
      tableData: [],
      totalDiffusion: 0,
      // Header du premier tableau (partie gauche)
      tableHeaders: [
        { label: 'Agence', key: 'agency', size: 4 },
        { label: 'Communes', key: 'city', size: 2 },
        { label: 'Max annonces manuelles', key: 'landVersionManual', size: 'auto' },
        { label: 'Quota Ubiflow', key: 'ubiflow', size: 'auto' },
        { label: 'Total diffusion', key: 'totalDiffusion', size: 1 },
      ],
      // Header du deuxième tableau (partie droite)
      diffuserTableHeaders: [
        { label: 'T', key: 'leboncoinLand', size: 'auto' },
        { label: 'T+M', key: 'leboncoinLandVersion', size: 'auto' },
        { label: 'Total', key: 'totalLeboncoin', size: 1 },
        { label: 'T', key: 'seLogerLand', size: 'auto' },
        { label: 'T+M', key: 'seLogerLandVersion', size: 'auto' },
        { label: 'Total', key: 'totalSeLoger', size: 1 },
        { label: 'T', key: 'lesTerrainsLand', size: 'auto' },
        { label: 'T+M', key: 'lesTerrainsLandVersion', size: 'auto' },
        { label: 'Total', key: 'totalLesTerrains', size: 'shrink' },
      ],
      tabs: [
        {
          route: {
            name: 'diffusions-quotas',
            params: {},
          },
          label: 'Gestion',
        },
        {
          route: {
            name: 'diffusions-statistics',
            params: {},
          },
          label: 'Statistiques',
        },
        {
          route: {
            name: 'diffusions-templates',
            params: {},
          },
          label: 'Templates',
        },
        {
          route: {
            name: 'diffusions-ubiflow',
            params: {},
          },
          label: 'Ubiflow',
        },
        {
          route: {
            name: 'diffusions-advertisement-packs',
            params: {},
          },
          label: 'Packs',
        },
        {
          route: {
            name: 'diffusions-logs',
            params: {},
          },
          label: 'Logs',
        },
      ],
      isLoading: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  computed: {
    // Total des quotas ubiflow de chaque agence
    totalUbiflowQuotas() {
      return this.tableData.length ? this.tableData.reduce((partialSum, el) => partialSum + (el.ubiflow.value ? el.ubiflow.value : 0), 0) : 0;
    },
  },
  methods: {
    // Nombre d'annonces par agence, tout diffuseur confondus
    computeTotalDiffusion(agencyId) {
      const agency = this.tableData.find((el) => el.agencyId === agencyId);
      return agency
      // "|| 0" pour le cas où l'input est vide (""||0 => 0)
        ? (agency.leboncoinLand.value || 0)
        + (agency.leboncoinLandVersion.value || 0)
        + (agency.seLogerLand.value || 0)
        + (agency.seLogerLandVersion.value || 0)
        + (agency.lesTerrainsLand.value || 0)
        + (agency.lesTerrainsLandVersion.value || 0) : 0;
    },
    async getData() {
      await this.getQuotas();
      await this.getAgencies();
      // Construction du tableau de données, par agence
      this.tableData = this.agencies.map((agency) => ({
        agencyId: agency.agencyId,
        agency: agency.name,
        // Les deux premiers chiffres correspondent au département
        city: agency.nbCities || 0,
        // Pour éviter d'avoir à rechercher dans les données lors de l'envoi au serveur
        // On conserve la valeur d'origine et le quotaId
        // La valeur par défaut est 0
        ubiflow: { value: 0, quotaId: null, oldValue: 0 },
        landVersionManual: { value: 0, quotaId: null, oldValue: 0 },
        leboncoinLand: { value: 0, quotaId: null, oldValue: 0 },
        leboncoinLandVersion: { value: 0, quotaId: null, oldValue: 0 },
        seLogerLand: { value: 0, quotaId: null, oldValue: 0 },
        seLogerLandVersion: { value: 0, quotaId: null, oldValue: 0 },
        lesTerrainsLand: { value: 0, quotaId: null, oldValue: 0 },
        lesTerrainsLandVersion: { value: 0, quotaId: null, oldValue: 0 },
      }));
      // Pour chaque quota, on ajoute la valeur si elle existe
      this.quotas.forEach((quota) => {
        const agency = this.tableData.find((el) => el.agencyId === quota.agencyId);
        if (agency) {
          const type = quotaType.find((t) => quota.type === t.name);
          if (!type) {
            return;
          }
          agency[type.label].value = quota.value;
          agency[type.label].oldValue = quota.value;
          agency[type.label].quotaId = quota.quotaId;
        }
      });
    },
    async getQuotas() {
      try {
        this.quotas = await quotaApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des quotas',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgencies() {
      try {
        this.agencies = await agencyApi.getNbCitiesAvailableLandByAgency();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        const quotasListToUpdate = [];
        const quotasListToCreate = [];
        this.tableData.forEach((agency) => {
          quotaType.forEach((type) => {
          // On vérifie que pour un même diffuseur, le total des annonces n'excède pas le quota ubiflow
            const diffuser = quotaType.find((el) => el.diffuser === type.diffuser && el.name !== type.name);
            if (diffuser.diffuser) {
              const totalDiffuser = (agency[type.label].value + agency[diffuser.label].value) || 0;
              const quotaUbiflow = agency.ubiflow.value || 0;
              if (totalDiffuser > quotaUbiflow) {
                throw new Error(`Le total des quotas d'annonces pour le diffuseur ${diffuser.diffuser} (${totalDiffuser}) de ${agency.agency} excède le quota Ubiflow (${quotaUbiflow}).`);
              }
            }
            if (agency[type.label].quotaId) {
            // On ajoute aux quotas à mettre à jour si quotaId existe (donc qu'il n'a pas à être créé)
            // Et si la nouvelle valeur est différente de l'ancienne
              if (agency[type.label].value !== agency[type.label].oldValue) {
                quotasListToUpdate.push({
                  quotaId: agency[type.label].quotaId,
                  data: {
                    agencyId: agency.agencyId,
                    type: type.name,
                    // Cas de l'input vide
                    value: (agency[type.label].value || 0),
                  },
                });
              }
            } else {
              quotasListToCreate.push({
                agencyId: agency.agencyId,
                type: type.name,
                // Cas de l'input vide
                value: (agency[type.label].value || 0),
              });
            }
          });
        });
        const quotaCreationPromises = quotasListToCreate.map((quota) => quotaApi.create(quota));
        const quotaUpdatePromises = quotasListToUpdate.map((quota) => quotaApi.update(quota.quotaId, quota.data));
        await Promise.allSettled([...quotaCreationPromises, ...quotaUpdatePromises]);
        this.$notification.show({ text: 'Les quotas ont été créés et mis à jour avec succès !' });
        await this.getData();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: `Il y a eu un problème lors de la sauvegarde des quotas. ${er}`,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass' scoped>
.diffusion-quotas
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .card
    @include card
  .quota-ubiflow
    color: $primary
  .title
    margin-bottom: 40px
  .agencies
    ::v-deep .data
      box-shadow: none
    ::v-deep .line:last-child
      border-bottom: 1px solid $off-white
  .diffusers-header
    display: flex
    flex-direction: row
    background-color: $line
  .diffusers-title
    display: flex
    justify-content: center
    padding: 8px 0
    h6
      color: $label
  .diffusers
    display: flex
    justify-content: flex-end
    position: relative
    &::after, &::before
      content: ""
      position: absolute
      top: 0
      width: 1px
      height: 100%
      background-color: $line
    &::after
      right: 66%
    &::before
      right: 34%
    ::v-deep .header
      height: inherit
      border-left: 1px solid $line
      border-right: 1px solid $line
    ::v-deep .data, .pending
      box-shadow: none
    ::v-deep .line
      background-color: $background
      border-left: 1px solid $line
      border-right: 1px solid $line
      &:first-child
        border-top: 1px solid $line
      &:last-child
        border-bottom: 1px solid $line
</style>
