var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"advertisements hedifys-21"},[_c('div',{staticClass:"header grid-x"},[_vm._m(0),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"advertisement":"primary","size":"large","icon":"add"},on:{"click":_vm.goToAdvertisement}},[_vm._v(" Ajouter une annonce ")])],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"search grid-x"},[_c('div',{staticClass:"cell shrink"},[_c('filter-modal',{staticClass:"filter-btn",attrs:{"numberOfInputs":Object.values(_vm.filters).filter(function (i) { return i !== null; }).length},on:{"change":_vm.filterAdvertisements,"reset":_vm.resetFilers,"close":_vm.closeFilterModal}},[_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-radio-button',{attrs:{"label":"Trier par","name":"isPublishable","value":_vm.$route.query.isPublishable,"options":[
                  { name: 'true', label: 'Publiables' },
                  { name: 'false', label: 'Non publiables' }
                ]},model:{value:(_vm.filters.isPublishable),callback:function ($$v) {_vm.$set(_vm.filters, "isPublishable", $$v)},expression:"filters.isPublishable"}})],1)]),_c('div',{staticClass:"grid-x grid-margin-x row"},[_c('div',{staticClass:"cell"},[_c('app-select',{attrs:{"label":"Agence","options":_vm.agencyOptions,"disabled":_vm.agencyOptions.length === 0},model:{value:(_vm.filters.agencyId),callback:function ($$v) {_vm.$set(_vm.filters, "agencyId", $$v)},expression:"filters.agencyId"}})],1)])])],1)]),_c('app-table',{attrs:{"headers":_vm.tableHeader,"data":_vm.advertisements.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToAdvertisement},scopedSlots:_vm._u([{key:"reference",fn:function(ref){
                var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.reference))])]}},{key:"agency",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.agency.name))])]}},{key:"land",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v("Lot "+_vm._s(data.land.lotNumber)+", "+_vm._s(data.land.address.addressLine1)+", "+_vm._s(data.land.city))])]}},{key:"version",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.version.name))])]}},{key:"isPublishable",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.isPublishable ? "Oui" : "Non"))])]}},{key:"createdAt",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs(data.createdAt).format('DD/MM/YYYY')))])]}},{key:"updatedAt",fn:function(ref){
                var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs(data.updatedAt).format('DD/MM/YYYY')))])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune donnée de disponible")])])],2),(_vm.advertisements.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.advertisements.metadata.offset,"count":_vm.advertisements.metadata.count}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Annonces manuelles")])])}]

export { render, staticRenderFns }