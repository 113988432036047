<template>
  <div class="projects-summary-pdf">
    <div class="header">
      <logo />
      <div>
        <h4>Synthèse des chantiers en cours</h4>
        <h5>Situation au {{ $dayjs().format('DD/MM/YYYY') }}</h5>
      </div>
    </div>

    <!-- Tableau -->
    <app-spinner v-if="isLoading" />
    <p v-else-if="!projects.length">Aucun chantier trouvé</p>

    <div class="table-container" v-else>
      <table class="summary-table">
        <thead>
          <tr>
            <th class="header-project-name">Chantiers</th>
            <th class="header-start">Ouverture chantier</th>
            <th v-for="step in projectsSummarySteps" :key="step.key" class="header-step">{{  step.label }}</th>
            <th class="header-estimated-close">Date fin estimée</th>
            <th class="header-close">Date livraison initiale</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in projects" :key="project.projectId">
            <th>
              <div class="project-name">{{ getProjectName(project) }}</div>
              <div class="project-address">{{ project.address.city }}</div>
            </th>
            <td>{{ formatDate(project.startDate) }}</td>
            <template v-for="step in projectsSummarySteps">
              <td
                v-if="isStepDisplayed(project.projectSteps[step.key])"
                :key="step.key"
                :class="{
                'in-progress': project.projectSteps[step.key].status === 'IN_PROGRESS',
                'done': project.projectSteps[step.key].status === 'DONE',
              }">
                <div class="step-week">{{ formatWeek(project.projectSteps[step.key].startedAt) }}</div>
                <div class="step-company">{{ project.projectSteps[step.key].company ?  project.projectSteps[step.key].company.name : '' }}</div>
              </td>
              <td v-else :key="step.key" :class="{ empty: step.isEmpty }"/>
            </template>
            <td>{{ formatWeek(project.estimatedDloseDate) }}</td>
            <td>{{ formatWeek(project.closeDate) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import logo from '@/assets/logos/hedifys.svg?inline';
import projectApi from '@/services/api/project';
import ProjectsSummarySteps from '@/services/data/projectsSummarySteps.json';

export default {
  components: {
    logo,
  },
  data() {
    return {
      // TODO
      projects: [],
      filters: {
        startDate: this.$route.query.startDate || undefined,
        endDate: this.$route.query.endDate || undefined,
        companyId: this.$route.query.companyId || undefined,
        agencyId: this.$route.query.agencyId || undefined,
      },
      isLoading: false,
    };
  },
  computed: {
    projectsSummarySteps() {
      return ProjectsSummarySteps.map((step) => ({
        ...step,
        isEmpty: this.projects.every((project) => !project.projectSteps[step.key]),
      }));
    },
  },
  async mounted() {
    await this.getProjectsSummary();
  },
  methods: {
    // Pas d'accès à this dans les filters malheuresement
    formatDate(date) {
      return this.$dayjs(date).format('DD/MM/YY');
    },
    formatWeek(date) {
      return `S${this.$dayjs(date).isoWeek()}`;
    },
    isStepDisplayed(projectStep) {
      if (!projectStep) return false;
      if (this.filters.companyId) {
        return projectStep.company && this.filters.companyId === projectStep.company.companyId;
      }
      return true;
    },
    async getProjectsSummary() {
      this.isLoading = true;
      try {
        const projects = await projectApi.getProjectsSummary(this.filters.agencyId, this.filters.startDate, this.filters.endDate);
        this.projects = projects.map((project) => {
          const projectSteps = {};
          project.projectSteps.forEach((projectStep) => {
            projectSteps[projectStep.stepKey] = projectStep;
          });
          return {
            ...project,
            projectSteps,
          };
        });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue en récupérant les projets. Veuillez réessayer plus tard',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    getProjectName: projectApi.getProjectName,
  },
};
</script>

<style lang="sass">
.projects-summary-pdf
  background: $white
  min-height: 100vh
  overflow-x: scroll
  .header
    background: $background !important
    padding: 8px 17px
    display: flex
    gap: 14px
    width: 100%
    svg
      width: 30px

  .table-container
    margin: 1rem
    border-bottom-width: 0

    .summary-table
      table-layout: fixed
      border-collapse: collapse
      th, td
        border: 1px solid $line
      th
        text-transform: uppercase
        background: $background !important
        font-size: 6px
        line-height: 10px
        font-weight: 700
        padding: 4px 2px
      thead
        th
          color: $body !important
      tbody
        th
          text-align: left
          font-size: 7px
          padding: 4px 2px
          .project-name
            color: $primary !important
          .project-address
            font-weight: 500
            color: $label
        td
          font-size: 7px
          font-weight: 400
          text-align: center
          padding: 2px 4px
          background: $white
          .step-week
            text-align: left
            font-size: 7px
            font-weight: 700
            color: $primary !important
          .step-company
            text-align: left
            font-size: 7px
            font-weight: 500
            text-transform: uppercase
            color: $body

          &.in-progress
            background: $primary !important
            .step-company, .step-week
              color: $white !important

          &.done, &.empty
            background: $loading !important
            .step-company, .step-week
              color: $label !important

</style>
