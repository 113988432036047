<template>
  <div class="postal-codes grid-x" :class="{large: largeMap}">
    <div class="cell postal-codes-map" :class="{auto: !largeMap}">
      <div class="map-container">
        <div class="map-loader">
          <app-spinner v-if="mapIsLoading"/>
          <p>Chargement de la carte...</p>
        </div>
        <div :class="{'map-hidden': mapIsLoading}" id="map"/>
        <div v-if="!mapIsLoading" class="map-control-button-container">
          <div @click="zoomIn"/>
          <div @click="zoomOut"/>
        </div>
      </div>
    </div>
    <div class="cell small-5 grid-y list-container">
      <div class="cell shrink grid-x row">
        <div class="cell auto">
          <app-search placeholder="Rechercher" v-model="search"/>
        </div>
        <div class="cell shrink import" v-if="!readonly">
          <app-button
            icon="import"
            size="small"
            theme="secondary"
            @click="openUploadFileWindow"
          >
            Importer
          </app-button>
        </div>
        <div class="cell shrink" v-if="!readonly">
          <app-button
            icon="add"
            size="small"
            @click="showInputAddHandler"
          >
            Ajouter
          </app-button>
        </div>
        <input
          ref="inputFile"
          @input="importPostalCodes($event.target.files)"
          type='file'
          accept="text/csv"
          hidden
        />
      </div>
      <app-error
        class="error-message row"
        v-if="showInvalidPostalCodeMessage"
        :title="`Ajout des codes postaux impossible${invalidPostalCodes && invalidPostalCodes.length ? ': ' + invalidPostalCodes[0]: ''}`"
        showCloseButton @click="hideInvalidPostalCodeMessage"
      />
      <app-error
        class="error-message row"
        v-else-if="showInvalidFileMessage"
        :title="`Certains codes postaux n'ont pas pu être importés${invalidPostalCodes && invalidPostalCodes.length ? ': ' + invalidPostalCodes.join(', '): ''}`"
        showCloseButton @click="hideInvalidFileMessage"
      />
      <div v-else-if="showInputAdd" class="cell shrink grid-x row">
        <div class="cell auto">
          <app-input
            :value="postalCodeToAdd"
            placeholder="Code postal ou numéro INSEE à 5 chiffres"
            @input="setPostalCode"
          />
        </div>
        <div class="cell shrink button-container">
          <app-button
            theme="primary"
            icon="add"
            size="round-small"
            @click="addPostalCode"
            :disabled="!enableAddPostalCode"
          />
        </div>
        <div class="cell shrink button-container">
          <app-button
            theme="warning"
            icon="remove"
            size="round-small"
            @click="hideInputAddHandler"
          />
        </div>
      </div>

      <div class="list cell auto">
        <app-table :headers="tableHeaders" :data="postalCodes" :loading="isLoading" small>
          <template slot="loading">
            <app-spinner />
          </template>
          <template slot="postalCodeInseeNumber" slot-scope="{ data }">
            <p>{{ data.postalCodeInseeNumber.split('-')[0] }}</p>
          </template>
          <template slot="action" slot-scope="{ data }" v-if="!readonly">
            <app-button
              @click="deletePostalCode(data.postalCodeInseeNumber)"
              theme='warning'
              icon='remove'
              size='round-small'
            />
          </template>
          <template slot="empty-table">
            <p>Aucune donnée de disponible</p>
          </template>
        </app-table>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import utils from '@/services/utils/utils';
import * as turf from '@turf/turf';

export default {
  name: 'postal-code',

  props: {
    postalCodes: {
      type: Array,
    },
    borders: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    showInvalidFileMessage: {
      type: Boolean,
    },
    showInvalidPostalCodeMessage: {
      type: Boolean,
    },
    invalidPostalCodes: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    largeMap: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      map: null,
      mapIsLoading: true,
      initialMapSettings: {
        center: [2.7195924, 46.9259861],
        zoom: 4.5,
      },

      showInputAdd: false,

      tableHeaders: [
        { label: 'Code postal', key: 'postalCodeInseeNumber', size: 3 },
        { label: 'Commune', key: 'city', size: 8 },
        { label: '', key: 'action', size: 1 },
      ],

      search: null,

      postalCodeToAdd: null,
      enableAddPostalCode: false,
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.getPostalCodes, 500);
  },

  mounted() {
    this.setMap();
  },

  watch: {
    // On vérifie si on renseigne bien un code postal ou un numéro insee
    postalCodeToAdd(value) {
      if (value && value.match(/\d/g)) {
        this.enableAddPostalCode = value.match(/\d/g).length === 5;
      }
    },

    search() {
      this.debouncedUpdateSearchQuery();
    },

    borders: {
      handler() {
        this.loadBorders();
      },
      deep: true,
    },
  },

  methods: {
    // Setup de la carte
    setMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: process.env.VUE_APP_MAPBOX_STYLE_URL,
        center: this.initialMapSettings.center,
        zoom: this.initialMapSettings.zoom,
        minZoom: 0,
        maxZoom: 20,
      });

      // Ajout des éléments de contrôles
      this.map.addControl(new mapboxgl.NavigationControl());

      this.loadBorders();
    },

    // Ajout des layers
    loadBorders() {
      if (this.mapIsLoading) {
        this.map.on('load', () => {
          this.mapIsLoading = false;

          // On appelle la création des layers si les frontières existent
          if (this.borders.features.length > 0) {
            this.setLayers();
          }
        });
      } else {
        // On appelle la création des layers si les frontières existent
        this.setLayers();
      }
    },

    // Création des layers pour chaque commune
    setLayers() {
      const mapSource = this.map.getSource('borders');

      if (!mapSource) {
        // Ajout des sources
        this.map.addSource('borders', {
          type: 'geojson',
          data: this.borders,
        });

        // Création du layer de la surface pour chaque commune
        this.map.addLayer({
          id: 'borders-area',
          type: 'fill',
          source: 'borders',
          layout: {},
          paint: {
            'fill-color': '#088',
            'fill-opacity': 0.6,
          },
        });

        // Création du layer des frontières pour chaque commune
        this.map.addLayer({
          id: 'borders-borders',
          type: 'fill',
          source: 'borders',
          layout: {},
          paint: {
            'fill-outline-color': '#088',
            'fill-color': 'transparent',
            'fill-opacity': 1,
          },
        });
      } else {
        mapSource.setData({
          type: 'FeatureCollection',
          features: this.borders.features,
        });
      }

      // On centre la carte sur l'ensemble des borders
      if (this.borders.features.length > 0) {
        const coordinates = [];

        this.borders.features.forEach((ft) => {
          coordinates.push(...ft.geometry.coordinates[0]);
        });

        const line = turf.lineString(coordinates);
        const bbox = turf.bbox(line);

        this.map.fitBounds(bbox, { padding: 20 });
      } else {
        this.map.flyTo({
          center: this.initialMapSettings.center,
          zoom: this.initialMapSettings.zoom,
          speed: 2,
          essential: true,
        });
      }
    },

    // Récupération des données des borders
    getPostalCodes() {
      this.$emit('getPostalCodes', this.search);
    },

    // Importation de la liste des codes postaux
    importPostalCodes(file) {
      this.hideInvalidFileMessage();
      this.$emit('importPostalCodes', file, this.search);

      // On reset l'input file
      this.$refs.inputFile.value = null;
    },

    // Ajout d'un code postal
    addPostalCode() {
      this.hideInvalidPostalCodeMessage();
      this.$emit('addPostalCode', this.postalCodeToAdd, this.search);
    },

    // Suppression d'un code postal de la liste
    deletePostalCode(postalCodeInseeNumber) {
      this.$emit('deletePostalCode', postalCodeInseeNumber, this.search);
    },

    // Simule un click sur l'input de type file pour ouvrir la fenêtre d'import des fichiers
    openUploadFileWindow() {
      this.$refs.inputFile.click();
    },

    // Affiche la barre d'ajout des codes postaux
    showInputAddHandler() {
      this.showInputAdd = true;
    },

    // Cache la barre d'ajout des codes postaux
    hideInputAddHandler() {
      this.postalCode = null;
      this.showInputAdd = false;
    },

    // Récupération du code postal entré manuellement
    setPostalCode(value) {
      this.postalCodeToAdd = value;
    },

    // On cache le message d'erreur des codes postaux non valides
    hideInvalidPostalCodeMessage() {
      this.$emit('hideInvalidPostalCodeMessage');
    },

    // On cache le message d'erreur des fichiers non valides
    hideInvalidFileMessage() {
      this.$emit('hideInvalidFileMessage');
    },

    // Zoom sur la carte
    zoomIn() {
      this.map.zoomIn();
    },

    // Dézoom sur la carte
    zoomOut() {
      this.map.zoomOut();
    },
  },
};
</script>

<style lang="sass">
.postal-codes
  background-color: $off-white
  box-shadow: $drop-min
  border-radius: $global-border-radius
  height: 450px
  &.large
    height: 975px
    .postal-codes-map
      height: 550px
    .list-container
      height: 380px
  .row
    margin: 0 0 16px 0
  .postal-codes-map
    background-color: $background
  .import
    margin: 0 24px
  .map-container
    position: relative
    display: flex
    align-items: center
    height: 100%
  .list-container
    padding: 24px
  .button-container
    display: flex
    align-items: center
    justify-content: center
    margin: 10px
  .map-loader
    margin: auto
    p
      padding-top: 20px
      color: $info
  .map-hidden
    visibility: hidden

  #map
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    canvas
      width: 100%
      border-top-right-radius: $global-border-radius
      border-bottom-right-radius: $global-border-radius
    .mapboxgl-control-container
      display: none
  .map-control-button-container
    position: absolute
    bottom: 15px
    right: 15px
    div
      position: relative
      width: 32px
      height: 32px
      background-color: $off-white
      border-radius: $global-border-radius
      box-shadow: $global-box-shadow
      cursor: pointer
      &::before, &::after
        content: ""
        position: absolute
        background-color: $secondary-dark
        width: 16px
        height: 2px
        top: calc(50% - 2px / 2)
        left: calc(50% - 16px / 2)
      &:first-of-type
        margin-bottom: 8px
        &::after
          transform: rotate(90deg)
</style>
