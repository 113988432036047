<template>
  <div class='themes hedifys-21'>
    <!-- HEADER / TITRE -->
    <div class='header grid-x'>
      <div class='cell auto'>
        <h1>Liste des thèmes</h1>
      </div>
      <div class="cell shrink">
        <app-button
          theme="primary"
          size="large"
          icon="add"
          @click="showCreateThemeModale = true"
        >
          Ajouter un thème
        </app-button>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="body">
      <!-- CONTENT -->
      <div class="search grid-x">
        <div class="cell shrink">
          <app-search size="small" v-model="search" placeholder="Rechercher" />
        </div>

        <!-- FILTER MODAL -->
        <div class="cell shrink filter-container">
          <filter-modal
            @change="filterThemes"
            @reset="resetFilers"
            @close="closeFilterModal"
            :numberOfInputs="Object.values(filters).filter(i => i !== null).length"
            class="filter-btn"
          >
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Catégorie"
                  v-model="filters.categoryId"
                  :options="categoryOptions"
                />
              </div>
            </div>
            <div class="grid-x grid-margin-x row">
              <div class="cell">
                <app-select
                  label="Lot"
                  v-model="filters.lotId"
                  :options="[{ name: null, label: 'Choisir' }, ...lotOptions]"
                  :disabled="lotOptions.length === 0"
                />
              </div>
            </div>
          </filter-modal>
        </div>
      </div>

      <!-- TABLE -->
      <app-table
        :headers="tableHeader"
        :data="themes"
        :loading="isLoading"
        @line-cliked="getSelectedTheme"
        clickable
      >
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="lot" slot-scope="{ data }">
          <p>{{ data.lot.name }}</p>
        </template>
        <template slot="category" slot-scope="{ data }">
          <p>{{ data.category.name }}</p>
        </template>
        <template slot="empty-table">
          <p>Aucune donnée de disponible</p>
        </template>
      </app-table>

      <!-- EDIT MODAL -->
      <app-modal
        class="edit-theme"
        :show="showCreateThemeModale"
        size="medium"
        :title="theme.themeId ? 'Modifier le thème' : 'Ajouter un thème'"
        @update:show="closeEditThemeModale"
      >
        <form ref="theme" @submit.prevent="editTheme">
          <div class="grid-x">
            <div class="cell">
              <app-input
                class="edit-theme-input-container"
                label="Nom du thème"
                placeholder="Nom"
                :value="theme.name"
                v-model="theme.name"
                required
              />
            </div>
          </div>

          <app-select
            class="edit-theme-input-container"
            label="Lot"
            v-model="theme.lotId"
            :options="lotOptions"
            required
          />

          <div class="edit-theme-input-container">
            <p class="edit-theme-label">Ce thème est-il paramétrable dans les styles ?</p>
            <div class="radio-group cell auto grid-x grid-margin-x">
              <div class="cell medium-2">
                <app-radio
                  name="isAvailableInStyles"
                  :value="true"
                  v-model="theme.isAvailableInStyles"
                >
                  Oui
                </app-radio>
              </div>
              <div class="cell medium-2">
                <app-radio
                  name="isAvailableInStyles"
                  :value="false"
                  v-model="theme.isAvailableInStyles"
                >
                  Non
                </app-radio>
              </div>
            </div>
          </div>

          <div class="edit-theme-input-container">
            <p class="edit-theme-label">Inclus au contrat Alysia</p>
            <div class="cell auto grid-x grid-margin-x">
              <div class="cell medium-2">
                <app-radio
                  name="isContractIncluded"
                  :value="true"
                  v-model="theme.isContractIncluded"
                >
                  Oui
                </app-radio>
              </div>
              <div class="cell medium-2">
                <app-radio
                  name="isContractIncluded"
                  :value="false"
                  v-model="theme.isContractIncluded"
                >
                  Non
                </app-radio>
              </div>
            </div>
          </div>

          <div class="edit-theme-input-container">
            <p class="edit-theme-label">Visibilité</p>
            <div class="cell auto grid-x grid-margin-x">
              <div class="cell medium-2">
                <app-radio
                  name="isPublic"
                  :value="false"
                  v-model="theme.isPublic"
                >
                  Privé
                </app-radio>
              </div>
              <div class="cell medium-2">
                <app-radio
                  name="isPublic"
                  :value="true"
                  v-model="theme.isPublic"
                >
                  Public
                </app-radio>
              </div>
            </div>
          </div>

          <div class="edit-theme-input-container grid-x grid-margin-x">
            <div class="cell auto">
              <app-select
                label='Comportement'
                v-model="theme.behavior"
                :options="behaviors"
                required
              />
            </div>

            <div class="cell shrink align-self-bottom">
              <app-input
                label="Rang"
                placeholder="Rang"
                type="number"
                v-model.number="theme.rank"
                required
                :min="1"
              />
            </div>
          </div>

          <div class="edit-theme-input-submit-container grid-x grid-margin-x">
            <div class="cell auto"/>
            <div v-if="theme.themeId" class='cell shrink'>
              <app-button
                theme="warning"
                size="large"
                icon="remove"
                @click="deleteTheme(theme.themeId)"
              >
                Supprimer
              </app-button>
            </div>
            <div class='cell shrink'>
              <app-button
                theme="primary"
                size="large"
                icon="edit"
                type="submit"
              >
                Enregistrer les modifications
              </app-button>
            </div>
            <div class="cell auto"/>
          </div>
        </form>
      </app-modal>
    </div>
  </div>
</template>

<script>
import themeApi from '@/services/api/theme';
import lotApi from '@/services/api/lot';
import categoryApi from '@/services/api/category';
import utils from '@/services/utils/utils';
import behaviors from '@/services/data/behaviors.json';
import FilterModal from '@/components/general/FilterModal.vue';
import AppModal from '@/basics/components/AppModal.vue';

export default {
  name: 'themes',

  components: {
    FilterModal,
    AppModal,
  },

  metaInfo: {
    title: 'Liste des thèmes',
  },

  data() {
    return {
      // Table
      tableHeader: [
        { label: 'Thème', key: 'name', size: 4 },
        { label: 'Lot', key: 'lot', size: 4 },
        { label: 'Catégorie', key: 'category', size: 4 },
      ],
      isLoading: false,

      // Themes
      themes: [],
      theme: {
        lotId: null,
        name: null,
        rank: null,
        isAvailableInStyles: false,
        isContractIncluded: true,
        isPublic: false,
        behavior: null,
      },

      // Lots
      lots: [],

      // Categories
      categories: [],

      // Behaviors
      behaviors,

      // Modale
      showCreateThemeModale: false,

      // Search
      search: null,

      // Filters
      filters: {
        categoryId: null,
        lotId: null,
      },

      // Options
      categoryOptions: [],
      lotOptions: [],
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },

  mounted() {
    this.search = this.$route.query.search;
    this.filters.categoryId = this.$route.query.categoryId || null;
    this.filters.lotId = this.$route.query.lotId || null;
    this.getData();
  },

  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },

    filters: {
      handler(value) {
        this.filters.categoryId = value.categoryId;
        this.filters.lotId = value.lotId;
      },
      deep: true,
    },

    'filters.categoryId': {
      handler() {
        this.lotOptions = this.getLotOptions();
      },
    },
  },

  methods: {
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });

        if (!this.isLoading) {
          this.getThemes();
        }
      }
    },

    async getData() {
      await this.getThemes();
      await this.getLots();
      await this.getCategories();
      this.categoryOptions = utils.formatOptions(
        this.categories,
        (option) => option.categoryId,
        (option) => option.name,
        'choose',
      );
      this.lotOptions = await this.getLotOptions();
    },

    // Récupération des thèmes
    async getThemes() {
      this.isLoading = true;
      try {
        this.themes = await themeApi.getAll(
          this.$route.query.search,
          this.$route.query.categoryId,
          this.$route.query.lotId,
        );
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des thèmes',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.isLoading = false;
    },

    // Récupère le thème sélectionné
    getSelectedTheme(selectedTheme) {
      this.themeId = selectedTheme.themeId;

      const {
        lot,
        createdAt,
        updatedAt,
        deletedAt,
        category,
        ...data
      } = selectedTheme;
      data.lotId = selectedTheme.lot.lotId;
      this.theme = data;

      this.showCreateThemeModale = true;
    },

    // Récupération des lots
    async getLots() {
      try {
        this.lots = await lotApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des catégories
    async getCategories() {
      try {
        this.categories = await categoryApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Édition d'un thème
    async editTheme() {
      if (this.theme.themeId) {
        try {
          const { themeId, ...data } = this.theme;
          await themeApi.update(this.theme.themeId, data);
          this.$notification.show({ text: 'Le thème a bien été mis à jour.' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la mise à jour du thème',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      } else {
        try {
          await themeApi.create(this.theme);
          this.$notification.show({ text: 'Le thème a bien été crée.' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la création du thème',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }

      this.closeEditThemeModale();
      this.getThemes();
    },

    // Suppression d'un thème
    async deleteTheme(themeId) {
      this.$message.show({
        title: 'Supprimer le thème',
        text: 'Souhaitez vous supprimer ce thème ?',
        confirmText: 'Supprimer le thème',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: async () => {
          try {
            await themeApi.delete(themeId);
            this.closeEditThemeModale();
            this.getThemes();
            this.$notification.show({ text: 'Le thème a bien été supprimé.' });
          } catch (error) {
            this.$message.show({
              title: 'Erreur',
              text: 'Il y a eu un problème lors de la suppression du thème',
              cancelText: 'Ok',
              hasCancel: true,
            });
          }
        },
      });
    },

    filterThemes() {
      // On pousse les query dans l'URL une fois les nouveaux filtres valider
      this.$router.push({
        query: {
          ...this.$route.query,
          categoryId: this.filters.categoryId || undefined,
          lotId: this.filters.lotId || undefined,
        },
      });

      // On récupère les thèmes avec les nouvelles query
      this.getThemes();
    },

    async resetFilers() {
      // On reset les valeurs de l'objet filters
      this.filters = {
        categoryId: null,
        lotId: null,
      };

      // On supprime les query categoryId et lotId de l'URl
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          categoryId: undefined,
          lotId: undefined,
        },
      });

      this.getThemes();
    },

    // À la fermeture de la modale des filtres
    closeFilterModal() {
      this.filters.categoryId = this.$route.query.categoryId || null;
      this.filters.lotId = this.$route.query.lotId || null;
    },

    // Fermeture de la modale d'édition
    closeEditThemeModale() {
      this.showCreateThemeModale = false;
      this.resetTheme();
    },

    // Réinitialisation de l'objet theme
    resetTheme() {
      this.theme = {
        lotId: null,
        name: null,
        rank: null,
        isAvailableInStyles: false,
        isContractIncluded: true,
        isPublic: false,
        behavior: null,
      };

      delete this.theme.themeId;
    },

    // Formate la liste des lots pour le composant app-select
    getLotOptions() {
      if (this.filters.categoryId) {
        return utils.formatOptions(
          this.lots.filter((lot) => (lot.categoryId === this.filters.categoryId)),
          (option) => option.lotId,
          (option) => option.name,
        );
      }

      return utils.formatOptions(
        this.lots,
        (option) => option.lotId,
        (option) => option.name,
      );
    },
  },
};
</script>

<style lang='sass' scoped>
.themes
  .body
    @include screen

  .header
    @include header

  .row
    @include row

  .search
    padding-bottom: 1rem
    button
      margin-left: 1rem

  .filter-btn
    margin-left: 1rem
  .filter-container
    margin-left: auto

  .edit-theme
    .edit-theme-input-container
      margin-bottom: 1rem
      &:first-of-type, &:last-of-type
        margin-top: 1rem

    .edit-theme-input-submit-container
      margin-top: 3rem

    .edit-theme-label
      font-weight: 500
      margin-bottom: .5rem
      &:first-of-type
        margin-top: .5rem
</style>
