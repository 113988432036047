import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des styles
 * @param {string} postalCodeInseeNumber: code postal du style, paramètre de recherche
 */
const getAll = async (postalCodeInseeNumber) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/styles/`, {
      params: { ...(postalCodeInseeNumber && { postalCodeInseeNumber }) },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère un style
 * @param {string} styleId: id du style à récupérer
 */
const getById = async (styleId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/styles/${styleId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'un style
 * @param {style} style: objet du style à créer
 */
const create = async (style) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/styles/`, style);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour du style
 * @param {style} style: nouvel objet du style
 * @param {string} styleId: id du style à récupérer
 */
const update = async (styleId, style) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/styles/${styleId}`, style);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression du style
 * @param {string} styleId: id du style à récupérer
 */
const deleteOne = async (styleId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/styles/${styleId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const style = {};
style.getAll = getAll;
style.getById = getById;
style.create = create;
style.update = update;
style.delete = deleteOne;

export default style;
