<template>
  <section class="hedifys-chantier build-steps">
    <div class="build-steps-header">
      <h1>Planning du chantier {{ project && project.reference ? `${project.reference} ${customerName}` : '' }}</h1>
      <app-button theme="secondary" icon="remove" @click="close">Revenir aux tâches du chantier</app-button>
    </div>
    <div class="loading" v-if="isLoading">
      <app-spinner />
    </div>
    <div class="gantt gantt-section" v-else>
      <perfect-scrollbar @scroll="handleScrollX" ref="scroll">
        <div
          class="gantt-container"
          :style="{ width: `${days * dayWidth}px`, paddingTop: isSticky ? '96px' : 0 }"
        >
          <div
            class="gantt-header"
            :style="{ width: `${(days) * dayWidth}px`, transform: `translateX(-${stickyOffsetLeft}px)`, willChange: 'transform'}"
          >
            <div>
              <span
                class="month"
                v-for="m in months"
                :key="`month${m}`"
                :style="{
                  width: `${$dayjs(startDate)
                    .add(m - 1, 'month')
                    .daysInMonth() * dayWidth}px`
                }"
              >
                {{
                  $dayjs(startDate)
                    .add(m - 1, "month")
                    .format("MMMM YYYY")
                }}
              </span>
            </div>
            <div>
              <span
                class="week"
                :style="{
                  width: `${($dayjs(startDate)
                    .endOf('isoWeek')
                    .diff($dayjs(startDate), 'day') +
                    1) *
                    dayWidth}px`
                }"
              >
                S{{
                  $dayjs(startDate)
                    .startOf("isoWeek")
                    .isoWeek()
                }}
              </span>
              <span
                class="week"
                v-for="w in weeks"
                :key="`week${w}`"
                :style="{ width: `${dayWidth * 7}px` }"
              >
                S{{
                  $dayjs(startDate)
                    .add(w, "week")
                    .isoWeek()
                }}
              </span>
              <span
                class="week"
                :style="{
                  width: `${($dayjs(endDate).diff($dayjs(endDate).startOf('isoWeek'), 'day') + 1) *
                    dayWidth}px`
                }"
              >
                S{{
                  $dayjs(endDate)
                    .startOf("isoWeek")
                    .isoWeek()
                }}
              </span>
            </div>
            <div>
              <span
                class="day"
                v-for="d in days"
                :key="`day${d}`"
                :style="{ width: `${dayWidth}px` }"
                :class="[
                  {
                    weekend: [6, 0].includes(
                      $dayjs(startDate)
                        .add(d - 1, 'day')
                        .day()
                    )
                  },
                  {
                    today:
                      $dayjs()
                        .startOf('day')
                        .diff($dayjs(startDate).add(d - 1, 'day'), 'day') === 0
                  }
                ]"
              >
                {{
                  $dayjs(startDate)
                    .add(d - 1, "day")
                    .format("DD")
                }}
              </span>
            </div>
          </div>

          <div
            class="tasks-project"
            :style="{
              background: `repeating-linear-gradient(
                  90deg,
                  white,
                  white ${dayWidth - 1}px,
                  #aaaaaa ${dayWidth}px
                )`
            }"
          >
            <project-step-gantt
              v-for="(projectStep, index) in projectSteps"
              :key="`project-step-gantt-${projectStep.projectStepId}`"
              v-model="projectSteps[index]"
              :start-date="startDate"
              :day-width="dayWidth"
              :companies="companies"
              :companyCategories="companyCategories"
              :projectId="projectStep.project.projectId"
              :project-steps="projectSteps"
              :disabled="projectStep.status === 'DONE'"
              :step-focused-id="stepFocusedId"
              @openModal="openProjectStepModal"
              @update="getData"
              @getFocusedStepPosition="getFocusedStepPosition"
              @updateBoundaries="updateBoundaries"
            >
            </project-step-gantt>
          </div>
        </div>
      </perfect-scrollbar>
      <project-step-modal
        :project="project"
        :projectStep="selectedProjectStep"
        :projectSteps="projectSteps"
        :companies="companies"
        :companyCategories="companyCategories"
        :modalType="modalType"
        :openModal="showProjectStepModal"
        :listName="'project'"
        :dropdownPosition="dropdownPosition"
        @close="showProjectStepModal = false"
        @changeType="changeType"
        @update="getData"
      />
    </div>
  </section>
</template>

<script>
import ProjectStepGantt from '@/views/projectStep/ProjectStepGantt.vue';
import projectStepModal from '@/views/projectStep/ProjectStepModal.vue';

import { fr } from 'vuejs-datepicker/dist/locale';

import projectStep from '@/services/api/projectStep';
import project from '@/services/api/project';
import company from '@/services/api/company';
import companyCategory from '@/services/api/companyCategory';
import customer from '@/services/api/customer';

export default {
  name: 'project-project-step',
  components: {
    ProjectStepGantt,
    projectStepModal,
  },
  metaInfo() {
    return {
      title: this.project && this.project.reference ? `${this.project.reference} ${this.customerName} – Chantier` : 'Chantier',
    };
  },
  data() {
    return {
      tabs: [
        {
          route: {
            name: 'projects-projectId',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'projects-projectId-steps',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Tâches',
        },
      ],

      french: fr,
      steps: [],
      filteredSteps: [],
      conductors: [],
      selectedConductor: null,
      selectedProjectStep: null,
      projectSteps: [],
      filteredProjectSteps: [],
      companies: [],
      companyCategories: [],
      projects: [],
      project: null,
      generationStart: null,
      isLoading: true,

      pendingFilter: true,
      inProgressFilter: true,
      todoFilter: true,
      lateFilter: true,
      doneFilter: true,
      toConfirmFilter: true,
      isListFormat: true,

      taskToDelay: null,
      showDelayModal: false,
      delayInWeeks: null,

      // Gantt
      dayWidth: 40,
      showPdfModal: false,
      isSticky: false,
      stickyOffsetLeft: 0,
      stepFocusedId: null,
      focusedStepPositionX: 0,
      focusedStepPositionY: 0,
      focusedStepWidth: 0,
      ganttPositionY: 0,

      // Type de modal à ouvrir depuis une projetStep
      modalType: null,
      showProjectStepModal: false,
      dropdownPosition: null,

      days: null,
      weeks: null,
      months: null,
      startDate: null,
      endDate: null,

      projectCompanies: [],

      projectStepsWithPartner: [],
      showCompaniesModal: false,

      isWeeklyDisplay: false,
      selectedWeek: {
        monday: null,
        sunday: null,
        number: null,
        current: {
          monday: null,
          sunday: null,
          number: null,
        },
        year: null,
      },

      allCompanies: null,
      allCompanyCategories: null,
    };
  },
  watch: {
    showCompaniesModal(value) {
      if (value) {
        this.getProjectStepsWithPartner();
      }
    },
  },
  computed: {
    customerName() {
      if (this.project && this.project.customer) {
        return this.project.customer.type === 'COMPANY' ? this.project.customer.companyName : customer.getCustomersNames(this.project.customer);
      }
      return '';
    },
  },
  async mounted() {
    this.isLoading = true;
    // Ces requêtes ne vont pas changer en fonction de la page,
    // comme les project steps, on peut donc ne les charger qu'une fois
    this.allCompanies = (await company.getAll()).data;
    this.allCompanyCategories = await companyCategory.getAll();

    await this.getData();
    this.getProjectStepsWithPartner();
    this.setFocusedStep();

    this.selectedWeek = {
      monday: this.$dayjs()
        .year(new Date().getFullYear())
        .isoWeek(this.$dayjs(new Date()).isoWeek())
        .startOf('isoWeek')
        .format(),
      sunday: this.$dayjs()
        .year(new Date().getFullYear())
        .isoWeek(this.$dayjs(new Date()).isoWeek())
        .endOf('isoWeek')
        .format(),
      number: this.$dayjs(new Date()).isoWeek(),
      current: {
        monday: this.$dayjs()
          .year(new Date().getFullYear())
          .isoWeek(this.$dayjs(new Date()).isoWeek())
          .startOf('isoWeek')
          .format(),
        sunday: this.$dayjs()
          .year(new Date().getFullYear())
          .isoWeek(this.$dayjs(new Date()).isoWeek())
          .endOf('isoWeek')
          .format(),
        number: this.$dayjs(new Date()).isoWeek(),
      },
      year: new Date().getFullYear(),
    };
    this.isLoading = false;
  },
  methods: {
    close() {
      this.$router.push({ name: 'projects-projectId-steps' });
    },
    async getData() {
      try {
        this.project = await project.getById(this.$route.params.projectId);

        this.generationStart = this.project.constructionPermitPlannedDate
          ? this.$dayjs(this.project.constructionPermitPlannedDate)
            .add(60, 'days')
            .startOf('isoWeek')
            .toDate()
          : null;
        this.projectSteps = await projectStep.getByProject(this.$route.params.projectId);
        this.filterShowToConfirm();

        this.filteredProjectSteps = this.projectSteps;
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } catch (error) {
        //
      }
      if (this.allCompanies && this.allCompanies.length > 0) {
        const alphaSorted = this.allCompanies.sort((a, b) => a.name.localeCompare(b.name));
        const onlyFavorite = alphaSorted.filter((c) => c.isFavorite);
        const noFavorite = alphaSorted.filter((c) => !c.isFavorite);

        this.companies = onlyFavorite.concat(noFavorite);
      } else {
        this.companies = [];
      }

      if (this.projectSteps && this.projectSteps.length > 0) {
        this.projectCompanies = this.companies.filter((c) => {
          const relatedProjectStep = this.projectSteps.find(
            (ps) => ps.company && ps.company.companyId === c.companyId,
          );
          return relatedProjectStep;
        });
      }
      try {
        this.companyCategories = this.allCompanyCategories.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        //
      }

      this.openModal = false;

      this.updateBoundaries();

      // this.isLoading = false;
    },
    updateBoundaries() {
      this.startDate = null;
      this.endDate = null;

      if (this.projectSteps && this.projectSteps.length > 0) {
        this.projectSteps.forEach((task) => {
          if (this.endDate < task.closedAt || !this.endDate) {
            this.endDate = task.closedAt;
          }
          if (this.startDate > task.startedAt || !this.startDate) {
            this.startDate = task.startedAt;
          }
        });

        this.startDate = this.$dayjs(this.startDate)
          .subtract(1, 'day')
          .startOf('month')
          .startOf('day');
        this.endDate = this.$dayjs(this.endDate)
          .add(1, 'day')
          .endOf('month')
          .startOf('day');

        this.days = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'day') + 1;
        this.weeks = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'week');
        this.months = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'month') + 1;
        if (
          this.$dayjs(this.endDate)
            .startOf('isoWeek')
            .isoWeek()
          === this.$dayjs({ ...this.startDate })
            .add(this.weeks, 'week')
            .isoWeek()
        ) {
          this.weeks -= 1;
        }
      }
    },
    setFocusedStep() {
      // Si le projet est en statut "En cours", on affiche la 1ère tâche dont le statut de démarrage est soit :
      // "à faire", "en cours" ou "en retard" si aucun des deux autres statut n'est trouvé
      if (this.project.status === 'IN_PROGRESS') {
        // On boucle sur tous les projectSteps jusqu'au 1er dont la valeur de startStatus correspond
        for (let i = 0; i < this.projectSteps.length; i += 1) {
          if (
            this.projectSteps[i].startStatus === 'TODO'
            || this.projectSteps[i].startStatus === 'IN_PROGRESS'
          ) {
            // On définit la valeur de stepFocusedId à l'ID du projectStep dont on souhaite récupérer la position
            this.stepFocusedId = this.projectSteps[i].projectStepId;
            return;
          }
        }
        for (let i = 0; i < this.projectSteps.length; i += 1) {
          if (this.projectSteps[i].startStatus === 'LATE') {
            // On définit la valeur de stepFocusedId à l'ID du projectStep dont on souhaite récupérer la position
            this.stepFocusedId = this.projectSteps[i].projectStepId;
            return;
          }
        }
      }

      // Si le projectStatus n'est pas IN_PROGRESS ou qu'aucun projectStep ne correspond aux status "à faire", "en cours" ou "en retard",
      // on assigne la valeur de stepFocusedId à la prochaine tâche la plus proche de la date du jour
      const newProjectSteps = this.projectSteps.slice();
      newProjectSteps.sort((a, b) => Math.abs(this.$dayjs(a.startedAt).diff(this.$dayjs(), 'day')) - Math.abs(this.$dayjs(b.startedAt).diff(this.$dayjs(), 'day')));
      this.stepFocusedId = newProjectSteps[0].projectStepId;
    },
    getFocusedStepPosition(value) {
      // On assigne les valeurs de position et de dimension du projectStep récupéré à focusedStepPositionX, focusedStepPositionY et focusedStepWidth
      this.focusedStepPositionX = value.positionX;
      this.focusedStepPositionY = value.positionY;
      this.ganttPositionY = this.$refs.scroll.$el.offsetTop;
      this.focusedStepWidth = value.width;

      this.setScrollPosition();
    },
    setScrollPosition() {
      // On attend le nouveau chargement du DOM pour avoir accès à la ref du gantt
      this.$nextTick(() => {
        // On calcule la valeur de scrollLeft pour placer la tâche souhaitée au centre du gantt horizontalement
        this.$refs.scroll.$el.scrollLeft = this.focusedStepPositionX - (window.innerWidth / 2 - this.focusedStepWidth / 2);

        // On calcule la valeur du scroll vertical pour placer la tâche souhaitée au centre du gantt verticalement
        const scrollY = this.focusedStepPositionY
          + this.ganttPositionY
          - (window.innerHeight + (96 + 60 - 40)) / 2;

        // On appel la fonction scrollAnimation avec pour destination la position en Y du gantt sur 1 seconde
        this.scrollAnimation(scrollY, 1000, this.enableUserScroll);
      });
    },
    scrollAnimation(destination, duration, callback) {
      // On récupère la position de la fenêtre et on créer un horodatage
      const start = window.pageYOffset;
      const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

      // On récupère les informations de la taille de la fenêtre ainsi que de la destination
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
        this.$refs.scroll.$el.scrollHeight,
        this.$refs.scroll.$el.offsetHeight,
        this.$refs.scroll.$el.clientHeight,
      );
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
      const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
          ? documentHeight - windowHeight
          : destinationOffset,
      );

      // On définit la fonction de easeInOutQuad pour créer une animation non linéaire
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

      // Si requestAnimationFrame n'est pas supporté par le navigateur
      if (!('requestAnimationFrame' in window)) {
        this.$el.scroll(0, destinationOffsetToScroll);
        return;
      }

      // On désactive le scroll utilisateur durant le scroll auto
      window.addEventListener('wheel', this.disableUserScroll, { passive: false });

      // Si non si requestAnimationFrame est supporté par le navigateur
      function scroll(elem) {
        const now = 'now' in window.performance ? performance.now() : new Date().getTime();
        const time = Math.min(1, (now - startTime) / duration);
        const timeFunction = easeInOutQuad(time);

        // On scroll verticalement
        elem.$el.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start));

        // On vérifie si la destination est atteinte ou si le temps de l'animation est écoulé
        if (elem.$el.pageYOffset === destinationOffsetToScroll || now >= startTime + duration) {
          callback();
          return;
        }
        requestAnimationFrame(() => { scroll(elem); });
      }
      scroll(this);
    },
    enableUserScroll() {
      window.removeEventListener('wheel', this.disableUserScroll);
    },
    disableUserScroll(event) {
      event.preventDefault();
    },
    openProjectStepModal(selectedProjectStep, modalType, position) {
      this.selectedProjectStep = selectedProjectStep;
      this.modalType = modalType;
      this.dropdownPosition = position;

      if (modalType === 'dropdown' && this.modalType === 'dropdown' && this.showProjectStepModal) {
        this.showProjectStepModal = false;
      } else {
        this.showProjectStepModal = true;
      }
    },
    sortByProjects() {
      const projectIds = [];
      this.projects = [];

      this.filteredProjectSteps.forEach((pS) => {
        if (!projectIds.includes(pS.project.projectId)) {
          projectIds.push(pS.project.projectId);
        }
      });

      projectIds.forEach((projectId) => {
        const projectStepsByProjectId = this.filteredProjectSteps.filter(
          (pS) => pS.project.projectId === projectId,
        );
        this.projects.push({
          project: {
            customer: projectStepsByProjectId[0].customer,
            member: projectStepsByProjectId[0].member,
            ...projectStepsByProjectId[0].project,
          },
          projectSteps: projectStepsByProjectId,
        });
      });
    },
    filterProjectSteps(pending, todo, inProgress, late, done, weekDisplay, toConfirm) {
      this.filteredProjectSteps = this.projectSteps.filter(
        (pS) => ((pending && pS.startStatus === 'PENDING' && pS.closeStatus === 'PENDING')
            || (todo && (pS.startStatus === 'TODO' || pS.closeStatus === 'TODO'))
            || (inProgress && pS.statusToDisplay === 'IN_PROGRESS')
            || (toConfirm && pS.showToConfirmThumbnail)
            || (late && (pS.statusToDisplay === 'LATE'))
              || (pending && pS.startStatus === 'PENDING' && pS.closeStatus === 'PENDING')
              || (done && pS.statusToDisplay === 'DONE'))
          && ((weekDisplay
            && (this.$dayjs(pS.startedAt).isBetween(
              this.$dayjs(this.selectedWeek.monday),
              this.$dayjs(this.selectedWeek.sunday),
            )
              || this.$dayjs(pS.closedAt).isBetween(
                this.$dayjs(this.selectedWeek.monday),
                this.$dayjs(this.selectedWeek.sunday),
              )
              || (this.$dayjs(pS.startedAt).isBefore(this.selectedWeek.monday)
                && this.$dayjs(pS.closedAt).isAfter(this.selectedWeek.sunday))))
            || !weekDisplay),
      );
      this.sortByProjects();
    },
    filterChanged(event, filterName) {
      if (filterName === 'pending') {
        this.filterProjectSteps(
          event.target.checked,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'todo') {
        this.filterProjectSteps(
          this.pendingFilter,
          event.target.checked,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'in_progress') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          event.target.checked,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'late') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          event.target.checked,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'done') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          event.target.checked,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'week_display') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          event.value,
          this.toConfirmFilter,
        );
      } else if (filterName === 'week_previous') {
        this.selectedWeek.monday = this.$dayjs(this.selectedWeek.monday)
          .subtract(1, 'week')
          .startOf('isoWeek')
          .format();
        this.selectedWeek.sunday = this.$dayjs(this.selectedWeek.monday)
          .endOf('isoWeek')
          .format();
        this.selectedWeek.number = this.$dayjs(this.selectedWeek.monday).isoWeek();
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'week_next') {
        this.selectedWeek.monday = this.$dayjs(this.selectedWeek.monday)
          .add(1, 'week')
          .startOf('isoWeek')
          .format();
        this.selectedWeek.sunday = this.$dayjs(this.selectedWeek.monday)
          .endOf('isoWeek')
          .format();
        this.selectedWeek.number = this.$dayjs(this.selectedWeek.monday).isoWeek();
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'week_monday') {
        this.selectedWeek.sunday = this.$dayjs(event)
          .endOf('isoWeek')
          .format();
        this.selectedWeek.number = this.$dayjs(event).isoWeek();
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          this.toConfirmFilter,
        );
      } else if (filterName === 'to_confirm') {
        this.filterProjectSteps(
          this.pendingFilter,
          this.todoFilter,
          this.inProgressFilter,
          this.lateFilter,
          this.doneFilter,
          this.isWeeklyDisplay,
          event.target.checked,
        );
      }
    },
    getDayjsObject(date) {
      return this.$dayjs(date)
        .utc()
        .startOf('day');
    },
    async generate() {
      try {
        await project.generate(this.project.projectId, this.generationStart);
        await this.getData();
        this.getProjectStepsWithPartner();

        this.showCompaniesModal = true;
      } catch (er) {
        //
      }
    },
    isInteger(event) {
      /* Vérifie que le charactère est bien un chiffre */
      const { key } = event;
      if (key === '.' || key === ',') {
        event.preventDefault();
        return false;
      }

      return true;
    },
    openDelayModal(task) {
      /* Delay the tasks after a task */
      this.taskToDelay = task;
      this.showDelayModal = true;
    },
    async delayFollowingProjectSteps() {
      this.isLoading = true;
      this.showDelayModal = false;

      /* Faire les mises à jour des tâches */
      await projectStep.delayFollowingProjectSteps(
        this.taskToDelay,
        this.projectSteps,
        this.delayInWeeks,
      );

      /* Rafraichir la liste */
      await this.getData();
      this.getProjectStepsWithPartner();

      this.isLoading = false;
    },
    async addProjectStep(newStep) {
      this.isLoading = true;

      /* Faire les mises à jour des tâches */
      await projectStep.add(newStep, this.projectSteps);

      /* Rafraichir la liste */
      await this.getData();
      this.getProjectStepsWithPartner();

      this.isLoading = false;
    },
    changeType(type) {
      this.modalType = type;
      this.openModal = true;
    },
    handleSticky(ev, el, offsetTop0) {
      this.isSticky = ev.scrollY >= offsetTop0 - 60;
    },
    handleScrollX(e) {
      this.stickyOffsetLeft = e.target.scrollLeft;
    },
    getProjectStepsWithPartner() {
      // obtenir les project_steps avec les partenaires
      const projectStepsWithPartner = this.projectSteps.filter(
        (step) => step.type !== 'START' && step.type !== 'CLOSE' && step.companyCategory.companyCategoryId,
      );

      // reordrer par companyCategoryId
      const tmpFilteredProjectStepsWithPartner = [];
      for (let index = 0; index < projectStepsWithPartner.length; index += 1) {
        const isCategoryExist = tmpFilteredProjectStepsWithPartner.some(
          (element) => element.companyCategory.companyCategoryId
            === projectStepsWithPartner[index].companyCategory.companyCategoryId,
        );

        if (!isCategoryExist) {
          tmpFilteredProjectStepsWithPartner.push(projectStepsWithPartner[index]);
        } else {
          const lastIndexElementWithCategory = tmpFilteredProjectStepsWithPartner
            .map((element) => element.companyCategory.companyCategoryId)
            .lastIndexOf(
              projectStepsWithPartner[index].companyCategory.companyCategoryId,
              tmpFilteredProjectStepsWithPartner.length - 1,
            );
          tmpFilteredProjectStepsWithPartner.splice(
            lastIndexElementWithCategory + 1,
            0,
            projectStepsWithPartner[index],
          );
        }
      }
      this.projectStepsWithPartner = tmpFilteredProjectStepsWithPartner;
    },
    filterShowToConfirm() {
      for (let i = 0; i < this.projectSteps.length; i += 1) {
      // On calcule la date correspondant au début de la tâche moins la période de préférence de l'entreprise
        const companyPeriodPreferenceWeekAgo = this.$dayjs(this.projectSteps[i].startedAt).subtract(this.projectSteps[i].company.periodPreference, 'week');

        // On vérifie si on se trouve entre le début de la tache et la date calculé précédemment
        const isBetween = this.$dayjs().isBetween(
          companyPeriodPreferenceWeekAgo,
          this.projectSteps[i].startedAt,
          null,
          '[]',
        );

        // On vérifie si la date de début de la tâche a été dépassé
        const isAfter = this.$dayjs().isAfter(this.projectSteps[i].startedAt);

        // On réunit les valeurs calculés afin de déterminer si on affiche ou non le label "À confirmer"
        if (
          (isBetween || isAfter)
          && this.projectSteps[i].company.companyId
          && this.projectSteps[i].showToConfirm
          && !this.projectSteps[i].confirmed
        ) {
          this.projectSteps[i].showToConfirmThumbnail = true;
        } else {
          this.projectSteps[i].showToConfirmThumbnail = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.build-steps {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 990;
  overflow-y: scroll;

  .build-steps-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 991;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 16px 56px;
    box-shadow: 0 2px 4px 0 $shadow;
  }

  .build-step {
    position: relative;
    min-height: 50px;
  }

  .gantt {
    width: 100%;
    .gantt-header {
      position: fixed;
      top: 80px;
      left: 0;
      z-index: 990;
      background-color: white;
      transition: transform ease-out 0.05s;
    }
    .ps {
      &::-webkit-scrollbar { // Cache la scrollbar mais garde la fontionnalité de scroll par glissement utile sur mobile
        display: none;
      }
      overflow-x: scroll !important;
      .ps__rail-x {
        position: fixed;
        top: 98vh;
        left: 0 !important;
        bottom: 0;
        height: 17px;
        opacity: 1;
        background-color: #E0E4ED;
        z-index: 1;
        .ps__thumb-x {
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #51ADF8;
        }
      }
    }
  }

  .gantt-section {
    margin-top: 80px;
  }

  .gantt-container {
    padding-top: 142px;
    text-align: left;
    color: $gray-medium;
    background: white;

    .month, .week, .day {
      display: inline-block;
      padding: 0.5rem 0 0.3rem;
      text-align: center;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: bold;
      border-top: 1px solid $gray-light;
      border-right: 1px solid $gray-light;
      box-sizing: border-box;
    }
    .day {
      font-weight: normal;
      border-bottom: 1px solid $gray-light;
      background: white;
      &.weekend {
        background: $gray-white;
      }
      &.today {
        border: 2px solid $orange;
      }
    }
    .tasks-project {
      padding-top: 95px;
      min-height: 500px;
      box-shadow: inset 0 2px 4px rgba(0,0,0,0.4);
      padding-bottom: 250px;
    }
  }

  .loading {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>
