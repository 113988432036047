<template>
  <div class="schedule-project-steps">
    <div v-if="project && projectSteps">
      <div class="loading" v-if="isLoading">
        <spinner></spinner>
      </div>
      <div v-else
        class="schedulelist">

        <vk-grid v-for="(projectStep, tindex) in projectSteps"
          :key="`project-step-${tindex}`" gutter="collapse"
          :class="{ 'uk-hidden': companyId && (projectStep.company ? companyId !== projectStep.company.companyId : true ) }"
          class="project-steps uk-flex uk-flex-middle">
          <project-pdf-project-step
            :projectStep="projectStep"
            :filter="filter"
            :companies="companies"
            :projectId="project.projectId"
            :day-width="dayWidth"
            :key="`project-step-${tindex}`"
            :index="tindex"
            :type="type"
            :start-date="startDate"
            @update="fetchData()" />
        </vk-grid>
        <p class="h-color-gray-dark" v-if="!projectSteps || projectSteps.length === 0">Aucune tâche n'est disponible avec ces critères</p>
      </div>
    </div>
    <div class="uk-padding" v-else-if="!isLoading">Ce projet n'a pas de date de démarrage.</div>
  </div>
</template>

<script>
import { fr } from 'vuejs-datepicker/dist/locale';
import company from '@/services/api/company';

import ProjectPdfProjectStep from '@/views/pdf/ProjectPdfProjectStep.vue';
import Spinner from '@/components/general/Spinner.vue';

export default {
  name: 'project-pdf-planning',
  components: {
    ProjectPdfProjectStep,
    Spinner,
  },
  props: ['project', 'filter', 'companyId', 'dayWidth', 'rawProjectSteps', 'type', 'company'],
  data() {
    return {
      companies: null,
      showModal: {},
      french: fr,
      saveDateEdit: undefined,
      labels: {
        normal: null,
        done: null,
        todo: 'à faire',
        late: 'retard',
        critical: 'urgent',
      },
      modalTabIndex: 1,
      projectSteps: null,
      isLoading: true,

      startDate: null,
      xToday: 0,
      days: 0,

      delayInWeeks: 0,
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      try {
        if (this.company && this.company.companyId) {
          this.companies = [this.company];
        } else {
          this.companies = (await company.getAll()).data;
        }
      } catch (er) {
        //
      }
      this.projectSteps = this.rawProjectSteps;
      this.filterStepsWithQuery();
      this.isLoading = false;
    },
    filterStepsWithQuery() {
      const { endDate, startDate } = this.$route.query;
      const selectedCompany = this.$route.query.company;
      /* Filter les steps avec les infos en paramêtre de la route */
      const filteredSteps = this.projectSteps.filter((step) => (
        ((startDate && startDate <= this.$dayjs(step.startedAt).unix() * 1000) || !startDate)
        && ((endDate && endDate >= this.$dayjs(step.closedAt).unix() * 1000) || !endDate)
        && ((selectedCompany && step.company && selectedCompany === step.company.companyId) || !selectedCompany)));

      return filteredSteps;
    },
  },
};
</script>
<style lang="scss">
  #project-pdf {
    .project-steps {
      break-inside: avoid-page;
    }
  }
</style>
