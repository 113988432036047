<template>
  <div class="version-prices hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          :items="[
            { route: { name: 'versions'}, name: 'Versions'},
            { route: { name: 'versions-versionId', params: { agencyId: $route.params.agencyId }}, name: version ? version.reference : '' }
          ]"
        />
      </div>
    </div>

    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>{{ version ? version.reference : '' }}</h1>
      </div>
    </div>

    <!-- TABS -->
    <tab-nav :tabs="tabs" />

    <!-- BODY / FORMULAIRE -->
    <div class="body">
      <div v-if="!isLoading">
        <div class="grid-x row">
          <div class="cell auto">
            <h2 class="price">Prix marque HT </h2>
            <p>(hors coefficient agence, options obligatoires)</p>
          </div>
          <div class="cell shrink">
            <h2>{{ utils.formatCentToEuro(getCategoriesTotalPrice(versionPrices), true) }}</h2>
          </div>
        </div>

        <app-accordion v-for="(category, index) in versionPrices" :key="`category-${index}`" class="app-accordion">
          <!-- ACCORDION HEADER -->
          <template slot="header">
            <div class="grid-x grid-margin-x">
              <div class="cell shrink">
                <img :src="category.icon" alt="icon" class="icon"/>
              </div>
              <div class="cell auto">
                <h3>{{ category.name }}</h3>
              </div>
              <div class="cell shrink">
                <h3>Total : {{ utils.formatCentToEuro(getLotsTotalPrice(category.lots), true) }}</h3>
              </div>
            </div>
          </template>

          <!-- ACCORDION CONTENT -->
          <template v-for="(lot, index) in category.lots" slot="content">
            <div :key="`lot-${index}`" class="app-accordion-content">
              <div class="grid-x grid-margin-x">
                <div class="cell shrink">
                  <img
                    v-if="lot.image"
                    class="illustration"
                    :src="lot.image"
                    alt="Visuel du lot"
                  />
                  <div v-else class="illustration not-found">
                    <upload class="icon-upload" />
                  </div>
                </div>
                <div class="cell auto content-header">
                  <h3>Lot : {{ lot.name }}</h3>
                  <p><strong>Description technique :</strong> {{ lot.description }}</p>
                </div>
                <div class="cell medium-1"/>
                <div class="cell shrink align-self-middle">
                  <h3>{{ utils.formatCentToEuro(getLotsSurveysTotalPrice(lot.lotsSurveys), true) }}</h3>
                </div>
              </div>

              <div v-if="lot.lotsSurveys.length > 0">
                <app-card-option v-for="(lotSurvey, index) in lot.lotsSurveys" :key="`lot-survey-${index}`">
                  <template slot="content">
                    <div class="grid-x grid-margin-x">
                      <div class="cell auto">
                        <div class="option-title">
                          <h4>{{ lotSurvey.name }}</h4>
                          <h5 class="tag tag-info">lot métré</h5>
                        </div>
                        <p class="option-description" v-if="lotSurvey.description">{{ lotSurvey.description }}</p>
                      </div>
                      <div class="cell shrink align-self-middle">
                        <h3 class="price">{{ utils.formatCentToEuro(lotSurvey.price, true) }}</h3>
                      </div>
                    </div>
                  </template>
                </app-card-option>
              </div>

              <p v-else class="lot-survey-not-found">Aucun lot métré de disponible pour ce lot</p>
            </div>
          </template>
        </app-accordion>
      </div>

      <!-- LOADER -->
      <div v-else class="loader">
        <app-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import versionApi from '@/services/api/version';
import categoryApi from '@/services/api/category';
import lotsApi from '@/services/api/lot';
import lotsSurveysApi from '@/services/api/lotSurvey';
import lotsSurveysSpecificationsApi from '@/services/api/lotSurveySpecification';
import utils from '@/services/utils/utils';
import upload from '@/assets/img/upload.svg?inline';

export default {
  name: 'version-prices',

  components: {
    TabNav,
    Breadcrumb,
    upload,
  },

  metaInfo() {
    return {
      title: this.version ? `${this.version.reference} – Version` : 'Version',
    };
  },

  data() {
    return {
      tabs: [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Prix',
        },
      ],
      isLoading: false,

      version: null,
      categories: null,
      lots: null,
      lotsSurveys: null,
      lotsSurveysSpecifications: null,

      versionPrices: [],

      utils,
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      this.isLoading = true;

      await this.getVersion();
      await this.getCategories();
      await this.getLots();
      await this.getLotsSurveys();
      await this.getLotsSurveysSpecifications();
      await this.getMergedData();

      this.isLoading = false;
    },

    // Récupération de la version
    async getVersion() {
      try {
        this.version = await versionApi.getById(this.$route.params.versionId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la version.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des catégories
    async getCategories() {
      try {
        this.categories = await categoryApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des catégories.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des lots
    async getLots() {
      try {
        this.lots = await lotsApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des lots métrés
    async getLotsSurveys() {
      try {
        this.lotsSurveys = await lotsSurveysApi.getAll();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des lots métrés spécifications
    async getLotsSurveysSpecifications() {
      try {
        this.lotsSurveysSpecifications = await lotsSurveysSpecificationsApi.getByVersionId(this.$route.params.versionId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés spécifications.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des lots métrés spécifications
    getMergedData() {
      this.versionPrices = this.categories.map((cat) => {
        // On récupère les lots qui appartiennent à la catégorie
        let lots = this.lots.filter((lot) => lot.categoryId === cat.categoryId);

        // On supprime certaines propriétés des lots et on ajoute les lots métrés correspondants
        lots = lots.map((lot) => ({
          image: lot.image,
          name: lot.name,
          description: lot.description,
          lotsSurveys: this.lotsSurveys
            .filter((lotSurvey) => lotSurvey.lot.lotId === lot.lotId)
            .map((lotSurvey) => {
              const lotSurveySpecification = this.lotsSurveysSpecifications.filter((lsp) => lsp.lotSurvey.lotSurveyId === lotSurvey.lotSurveyId)[0];

              return {
                name: lotSurvey.name,
                price: lotSurveySpecification ? lotSurveySpecification.price : 0,
                description: lotSurveySpecification ? lotSurveySpecification.description : null,
              };
            }),
        }));

        return {
          name: cat.name,
          icon: cat.icon,
          lots,
        };
      });
      // On récupère uniquement les catégories qui comporte au moins un lot
      this.versionPrices = this.versionPrices.filter((versionPrice) => versionPrice.lots.length > 0);
    },

    // Calcule du prix totale des lots métrés
    getLotsSurveysTotalPrice(lotsSurveys) {
      return lotsSurveys.reduce((a, b) => a + b.price, 0);
    },

    // Calcule du prix totale des lots
    getLotsTotalPrice(lots) {
      return lots.reduce((a, b) => a + this.getLotsSurveysTotalPrice(b.lotsSurveys), 0);
    },

    // Calcule du prix totale des catégories
    getCategoriesTotalPrice(categories) {
      return categories.reduce((a, b) => a + this.getLotsTotalPrice(b.lots), 0);
    },
  },
};
</script>

<style lang='sass' scoped>
.version-prices
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .breadcrumbs
    @include breadcrumbs
  .row
    @include row
  .duplicate
    margin-left: 1rem
  .spinner-container
    @include spinner-container
  .card
    @include card
  .price
    color: $primary
  .illustration
    width: 90px
    height: 90px
    border: 1px solid $line
    border-radius: 10px
  .not-found
    @include centered-container
    svg
      width: 50px
  .app-accordion-content
    border-bottom: 1px solid $line
    padding: 20px 25px 40px 25px
    &:not(:first-of-type)
      border-top: 1px solid $line
  .content-header
    h3
      margin-bottom: 5px
  .icon
    width: 24px
  .app-card-option
    margin-top: 8px
    &:first-of-type
      margin-top: 25px
  .option-title
    display: flex
    align-items: center
    .tag
      margin-left: 20px
    .tag-info
      @include tag-info
  .option-description
    margin-top: 10px
  .lot-survey-not-found
    text-align: center
    margin-top: 40px
</style>
