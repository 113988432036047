import slugify from 'slugify';
import { create, all } from 'mathjs';

const mathjs = create(all);
mathjs.config({ number: 'number', precision: 14 });

/**
 * Similaire au _debounce de lodash: Retarde l'exécution de la fonction de 'wait'ms à chaque appel afin d'appeler la fonction seulement quand il n'y a plus de changement
 * @param {function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Int} wait : temps d'attente (ms) sans appel à la fonction avant de l'exécuter
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const debounce = (func, wait) => {
  let timeout;
  return function executedFunc(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Formate les options pour le composant app-select
const formatOptions = (options, nameFunc, labelFunc, addValue) => {
  const formattedOptions = options.map((option) => ({ name: nameFunc(option), label: labelFunc(option) }));

  if (addValue === 'choose') {
    formattedOptions.unshift({ name: null, label: 'Choisir' });
  } else if (addValue && addValue.text) {
    formattedOptions.unshift({ name: null, label: addValue.text });
  }

  return formattedOptions;
};

// Format les variables techniques
const formatVariableKey = (value) => {
  let formattedValue = slugify(
    value,
    {
      replacement: '_',
      remove: undefined,
      lower: true,
      strict: true,
      locale: 'fr',
    },
  );

  formattedValue = [formattedValue.slice(0, 0), '$', formattedValue.slice(0)].join('');
  return formattedValue;
};

// Convertit une string en valeur booléenne ou null
const parseBoolean = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return null;
};

// On ne peut finalement pas faire les changements avec toLocalString, ça ne fonctionne pas sur Safari
const numberToPrice = (n) => {
  const parts = n.toString().split('.');

  return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${(parts[1] ? `,${parts[1].slice(0, 2)}` : '')} €`;
};

// Formate les prix en centime en euro, possibilité de formater le résultat pour l'affichage utilisateur
const formatCentToEuro = (value, viewerFormat, round) => {
  if (value || value === 0) {
    let formattedPrice = null;
    if (!round) {
      formattedPrice = parseFloat(mathjs.format(mathjs.chain(value).divide(100).done(), { precision: mathjs.config.precision, notation: 'fixed' }));
    } else {
      formattedPrice = parseInt(mathjs.format(Math.round(mathjs.chain(value).divide(100).done()), { precision: mathjs.config.precision, notation: 'fixed' }), 10);
    }
    if (viewerFormat) {
      if (!round) {
        return numberToPrice(formattedPrice);
      }
      return numberToPrice(formattedPrice);
    }
    return formattedPrice;
  }
  return 0;
};

// Formate les prix en euro en centimes, possibilité de formater le résultat pour l'affichage utilisateur
const formatEuroToCent = (value, viewerFormat) => {
  const formattedPrice = mathjs.round(mathjs.format(mathjs.chain(value).multiply(100).done(), { precision: mathjs.config.precision, notation: 'fixed' }));
  if (viewerFormat) {
    return formattedPrice.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  return formattedPrice;
};

// Formate les prix en euro en centimes, possibilité de formater le résultat pour l'affichage utilisateur
const formatEuro = (value, viewerFormat) => {
  const formattedPrice = parseFloat(mathjs.format(mathjs.chain(value).done(), { precision: mathjs.config.precision, notation: 'fixed' }));

  if (viewerFormat) {
    return formattedPrice.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  return formattedPrice;
};

const utils = {};
utils.debounce = debounce;
utils.formatOptions = formatOptions;
utils.formatVariableKey = formatVariableKey;
utils.parseBoolean = parseBoolean;
utils.formatCentToEuro = formatCentToEuro;
utils.formatEuroToCent = formatEuroToCent;
utils.formatEuro = formatEuro;

export default utils;
