<template>
  <div class="company-projects hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="company && company.companyId && company.name"
          :items="[
            { route: { name: 'companies'}, name: 'Partenaires'},
            { route: { name: 'companies-companyId', params: { companyId: $route.params.companyId }}, name: company.name}
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <div>
          <h1>{{ !isLoading && $route.params.companyId && company ? company.name : ' ' }}</h1>
        </div>
      </div>
    </div>
    <!-- TABS -->
      <tab-nav v-if="$route.params.companyId" :tabs="tabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body">
      <ProjectSteps />
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import ProjectSteps from '@/views/projectStep/ProjectSteps.vue';
import companyApi from '@/services/api/company';
import companyCategoryApi from '@/services/api/companyCategory';

export default {
  name: 'companies',
  components: {
    TabNav,
    Breadcrumb,
    ProjectSteps,
  },
  metaInfo() {
    return {
      title: this.company ? `${this.company.name} –  Partenaire` : 'Partenaire',
    };
  },
  data() {
    return {
      // New data
      companyCategories: [],

      company: null,
      isLoading: true,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getCompany();
    this.isLoading = false;
  },

  computed: {
    tabs() {
      let tabs = [
        {
          route: {
            name: 'companies-companyId',
            params: { companyId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'companies-companyId-projects',
            params: { companyId: null },
          },
          label: 'Chantiers',
        },
        {
          route: {
            name: 'companies-companyId-schedule',
            params: { companyId: null },
          },
          label: 'Disponibilités',
        },
      ];
      if (this.company && this.company.companyCategories && this.company.companyCategories.length && this.companyCategories && this.companyCategories.length) {
        const isDeveloper = this.companyCategories.filter(
          (category) => category.type === 'DEVELOPER'
            && this.company.companyCategories.includes(category.companyCategoryId),
        ).length > 0;
        if (isDeveloper) {
          tabs = tabs.concat([
            {
              route: {
                name: 'companies-companyId-allotments',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Lotissements',
            },
            {
              route: {
                name: 'companies-companyId-lands',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Terrains',
            },
          ]);
        }
      }

      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { companyId: this.$route.params.companyId },
        },
        label: el.label,
      }));
    },
  },
  methods: {
    async getCompany() {
      if (this.$route.params.companyId) {
        try {
          this.companyCategories = await companyCategoryApi.getAll();
          this.company = await companyApi.getById(this.$route.params.companyId);
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors du chargement du partenaire',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
    goToCompany() {
      this.$router.push({ name: 'companies-new' });
    },
  },
};
</script>

<style lang='sass' scoped>
.company-projects
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .breadcrumbs
    @include breadcrumbs
</style>
