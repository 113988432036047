var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"allotments hedifys-21"},[_c('div',{staticClass:"header grid-x"},[_vm._m(0),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"theme":"primary","size":"large","icon":"add"},on:{"click":_vm.goToAllotment}},[_vm._v("Ajouter un lotissement")])],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell shrink search"},[_c('app-search',{attrs:{"size":"small","placeholder":"Rechercher un lotissement"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.isBrandAdmin)?_c('div',{staticClass:"cell small-2"},[_c('app-select',{attrs:{"value":null,"options":_vm.agencies},on:{"input":_vm.agencyChange},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}})],1):_vm._e(),_c('div',{staticClass:"cell auto"})]),_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.allotments.data,"loading":_vm.isLoading,"clickable":""},on:{"line-cliked":_vm.goToAllotment},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.name))])]}},{key:"company",fn:function(ref){
var data = ref.data;
return [_c('p',{class:{'no-registered-company-color' : !data.isCompanyRegistered}},[_vm._v(_vm._s(data.company ? data.company.name : null))])]}},{key:"landsCount",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(((data.activeLandsCount) + " / " + (data.landsCount))))])]}},{key:"postalCode",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.address && data.address.postalCodeInseeNumber ? data.address.postalCodeInseeNumber.split('-')[0] : null))])]}},{key:"city",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.address.city))])]}},{key:"agency",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.agency && data.agency.agencyId ? data.agency.name : '-'))])]}},{key:"createdAt",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs(data.createdAt).format('DD/MM/YYYY')))])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2),(_vm.allotments.metadata)?_c('app-pagination',{attrs:{"limit":_vm.limit,"offset":_vm.allotments.metadata.offset,"count":_vm.allotments.metadata.count}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Lotissements")])])}]

export { render, staticRenderFns }