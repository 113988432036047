import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère une version
 * @param {string} versionId: id de la version à récupérer
 */
const getById = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions/${versionId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère un prix pour une version
 * @param {string} versionId: id de la version à récupérer
 */
const getPriceById = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions/${versionId}/price`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des versions
 * @param {string} search: paramètre de recherche, sur le nom de la version
 * @param {string} rangeId: paramètre de recherche, sur l'id de la gamme
 * @param {string} modelId: paramètre de recherche, sur l'id du modèle
 * @param {string} styleId: paramètre de recherche, sur l'id du style
 * @param {VersionStatus} status: paramètre de recherche, sur le statut de la version
 * @param {string} landId: paramètre de recherche, sur l'id du terrain
 * @param {string} order: paramètre de tri, sur les prix
 * @param {string} offset: nombre d'éléments à sauter avant de faire la recherche
 * @param {string} limit: nombre d'éléments à récupérer
 * @param {string} roomCount: paramètre de recherche, sur le nombre de chambre
 * @param {string} floorId: paramètre de recherche, sur l'id de l'étage
 * @param {string} garage: paramètre de recherche, sur la présence d'un garage
 */
const getAll = async (search, rangeId, modelId, styleId, landId, status, order, offset, limit, roomCount, floorId, garage) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions`, {
      params: {
        ...(search && { search }),
        ...(rangeId && rangeId !== 'ALL' && { rangeId }),
        ...(modelId && modelId !== 'ALL' && { modelId }),
        ...(styleId && styleId !== 'ALL' && { styleId }),
        ...(landId && { landId }),
        ...(status && { status }),
        ...(order && { order }),
        ...(offset && { offset }),
        ...(limit && { limit }),
        ...(roomCount && { roomCount }),
        ...(floorId && { floorId }),
        ...(garage && { garage }),
      },
    });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère toutes les versions d'un modèle
 * @param {*} modelId: id du modèle dont les versions sont récupérées
 */
const getVersionsModel = async (modelId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/models/${modelId}/versions`);
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Duplication d'une version
 * @param {string} versionId: id de la version à dupliquer
 */
const duplicate = async (versionId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/versions/${versionId}/duplicate`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression d'une version
 * @param {string} versionId: id de la version à supprimer
 */
const deleteOne = async (versionId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/versions/${versionId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des spécifications de variables d'une version
 * @param {string} versionId id de la version dont on souhaite récupérer les spécifications de variables
 */
const getVariablesSpecifications = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions/${versionId}/variables-specifications`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour de la version
 * @param {version} version: nouvel objet de la version
 * @param {string} versionId: id de la version à modifier
 */
const update = async (versionId, version) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/versions/${versionId}`, version);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Met à jour les spécifications de variables d'une version (la route gère l'ajout, la modification et la suppression)
 * @param {object} variablesSpecifications objet des spécifications de variables à mettre à jour
 * @param {string} versionId id de la version dont on souhaite récupérer les spécifications de variables
 */
const updateVariablesSpecifications = async (variablesSpecifications, versionId) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/versions/${versionId}/variables-specifications`, variablesSpecifications);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'une version
 * @param {version} version: objet de la version à créer
 */
const create = async (version) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/versions/`, version);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une version
 * @param {string} versionId: id de la version à récupérer
 */
const getPublicById = async (versionId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/versions/${versionId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const version = {};
version.getAll = getAll;
version.getById = getById;
version.getPriceById = getPriceById;
version.getVersionsModel = getVersionsModel;
version.duplicate = duplicate;
version.delete = deleteOne;
version.getVariablesSpecifications = getVariablesSpecifications;
version.updateVariablesSpecifications = updateVariablesSpecifications;
version.update = update;
version.create = create;
version.getPublicById = getPublicById;

export default version;
