<template>
  <div class="app-radio-button" :class={inline}>
    <p>{{ label }}</p>
    <div class="group-btn grid-x">
      <div class="cell shrink" v-for="option in options" :key="option.name">
        <label>
          <input
            type="radio"
            :value="option.name"
            :checked="value === option.name"
            @click="selectedOption(option)"
          />
          <div>
            <span>{{ option.label }}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-radio-button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {
      type: [String, Boolean],
    },
    inline: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    selectedOption(option) {
      if (!this.required && option.name === this.value) {
        this.$emit('input', null);
      } else {
        this.$emit('input', option.name);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.app-radio-button
  display: flex
  flex-direction: column
  > p
    @include heading-xs
    color: $label
    margin-bottom: 8px

  .group-btn
    display: flex
    flex-direction: row
    > div
      position: relative
      margin-right: 6px

    input[type="radio"]
      cursor: pointer
      position: absolute
      top: 0
      left: 0
      height: 0
      width: 0
      // &:checked ~ div
      display: none
      &:checked ~ div
        background-color: $primary
        border-color: $primary
        > span
          color: $off-white
      ~ div
        padding: 8px 12px
  label
    display: inline-flex
    cursor: pointer
    user-select: none
    background-color: $off-white
    border: 1px solid $line
    border-radius: 4px
    margin-bottom: 10px
    > span
      @include heading-xs
      color: $secondary-light

.app-radio-button.inline
  flex-direction: row
  align-items: center
  > p
    margin-bottom: 0
    margin-right: 8px
</style>
