<template>
  <nav class="side-nav cell" :class="{ shrinked: shrinked }">
    <div class="header">
      <router-link :to="{ name: 'home' }">
        <img src="~@/assets/logos/hedifys.svg" alt="" width="30">
      </router-link>
    </div>
    <div class="body">
      <!-- PARTIE AGENCE  -->
      <div class="main grid-x" :class="{ selected: openedItem === 'agencies' }" @click="goTo('agencies', 'agencies')">
        <suitcase class="image fill" />
        <div class="title">Agences</div>
        <div class="cell auto"></div>
      </div>
      <!-- PARTIE AGENDA -->
      <div class="main grid-x" :class="{ selected: openedItem === 'agenda' }" @click="goTo('agenda', 'agenda')">
        <calendar class="image fill"/>
        <div class="title">Agenda</div>
        <div class="cell auto"></div>
      </div>
      <!-- PARTIE CONTACTS  -->
      <div>
        <div class="main grid-x" @click="open('customers')" :class="{ selected: openedItem === 'customers' }">
          <wallet class="image fill" />
          <span class="title">Contacts</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'customers' }">
          <router-link :to="{ name: 'customers-unqualified' }">À qualifier</router-link>
          <router-link :to="{ name: 'customers-prospects' }">Prospects</router-link>
          <router-link :to="{ name: 'customers-projects-drafts' }">Avant-projets</router-link>
          <router-link :to="{ name: 'opportunities' }" v-if="!isBrandAdmin">Opportunités</router-link>
        </div>
      </div>
      <!-- PARTIE PARTENAIRES  -->
      <div>
        <div class="main grid-x" @click="open('companies')" :class="{ selected: openedItem === 'companies' }">
          <handshake class="image fill" />
          <span class="title">Partenaires</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'companies' }">
          <router-link :to="{ name: 'companies' }">Partenaires</router-link>
          <router-link v-if="isBrandAdmin" :to="{ name: 'companies-categories' }">Métiers</router-link>
        </div>
      </div>
      <!-- PARTIE TERRAINS  -->
      <div>
        <div class="main grid-x" @click="open('lands')" :class="{ selected: openedItem === 'lands' }">
          <layer class="image fill" />
          <span class="title">Terrains</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'lands' }">
          <router-link :to="{ name: 'lands-allotments' }">Lotissements</router-link>
          <router-link :to="{ name: 'lands' }">Terrains</router-link>
        </div>
      </div>
      <!-- PARTIE CHANTIERS  -->
      <div>
        <div class="main grid-x" @click="open('projects')" :class="{ selected: openedItem === 'projects' }">
          <worker class="image fill" />
          <span class="title">Chantiers</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'projects' }">
          <router-link :to="{ name: 'projects-customers' }">Clients</router-link>
          <router-link :to="{ name: 'projects' }">Chantiers</router-link>
          <router-link :to="{ name: 'projects-summary' }">Synthèse</router-link>
          <router-link v-if="!isBrandAdmin" :to="{ name: 'projects-project-steps' }">Tâches chantier</router-link>
          <router-link v-if="(me && me.isAgencyAdmin) || isBrandAdmin" :to="{ name: 'projects-build-filters' }">Gabarits</router-link>
          <router-link v-if="!isBrandAdmin" :to="{ name: 'projects-companies-reports' }">Rapports</router-link>
        </div>
      </div>
      <!-- PARTIE CATALOGUE  -->
      <div v-if="isBrandAdmin">
        <div class="main grid-x" @click="open('versions')" :class="{ selected: openedItem === 'versions' }">
          <house class="image fill" />
          <span class="title">Catalogue</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'versions' }">
          <router-link :to="{ name: 'versions-ranges' }">Gammes</router-link>
          <router-link :to="{ name: 'versions-models' }">Modèles</router-link>
          <router-link :to="{ name: 'versions' }">Versions</router-link>
        </div>
      </div>
      <!-- PARTIE STRUCTURE  -->
      <div v-if="isBrandAdmin">
        <div class="main grid-x" @click="open('categories')" :class="{ selected: openedItem === 'categories' }">
          <architecture class="image fill" />
          <span class="title">Structure</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'categories' }">
          <router-link :to="{ name: 'categories' }">Catégories</router-link>
          <router-link :to="{ name: 'categories-lots' }">Lots</router-link>
          <router-link :to="{ name: 'categories-themes' }">Thèmes</router-link>
          <router-link :to="{ name: 'categories-options' }">Options</router-link>
          <router-link :to="{ name: 'categories-packs' }">Packs</router-link>
          <router-link :to="{ name: 'categories-rooms' }">Distribution</router-link>
        </div>
      </div>
      <!-- PARTIE SPÉCIFICITÉ  -->
      <div v-if="isBrandAdmin">
      <div class="main grid-x" @click="open('styles')" :class="{ selected: openedItem === 'styles' }">
          <ruler class="image fill" />
          <span class="title">Spécificités</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'styles' }">
          <router-link :to="{ name: 'styles' }">Styles</router-link>
          <router-link :to="{ name: 'styles-regulations' }">Règles</router-link>
          <router-link :to="{ name: 'styles-constraints' }">Contraintes</router-link>
        </div>
      </div>
      <!-- PARTIE FINANCIER  -->
      <div v-if="isBrandAdmin">
        <div class="main grid-x" @click="open('coefficients')" :class="{ selected: openedItem === 'coefficients' }">
          <money class="image fill" />
          <span class="title">Financier</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'coefficients' }">
          <router-link :to="{ name: 'coefficients' }">Marges</router-link>
          <router-link :to="{ name: 'coefficients-variables' }">Paramètres de coût</router-link>
          <router-link :to="{ name: 'coefficients-lots-surveys' }">Lots métrés</router-link>
          <router-link :to="{ name: 'coefficients-pushes' }">Push de prix</router-link>
        </div>
      </div>
      <!-- PARTIE MARQUE  -->
      <div v-if="isBrandAdmin" class="main grid-x" @click="goTo('brand', 'brand')" :class="{ selected: openedItem === 'brand' }">
        <setting class="image fill" />
        <span class="title">Marque</span>
        <div class="cell auto"></div>
      </div>
      <!-- PARTIE DIFFUSION  -->
      <div>
        <div class="main grid-x" @click="open('diffusions')" :class="{ selected: openedItem === 'diffusions' }">
          <speaker class="image fill" />
          <span class="title">Diffusions</span>
          <div class="cell auto"></div>
          <arrow class="arrow stroke" />
        </div>
        <div class="secondary grid-y" :class="{ opened: openedItem === 'diffusions' }">
          <router-link v-if="isBrandAdmin" :to="{ name: 'diffusions-quotas' }">Configuration diffusions</router-link>
          <router-link v-if="isBrandAdmin" :to="{ name: 'diffusions-advertisement-diffusion-packs' }">Packs diffusions</router-link>
          <router-link :to="{ name: 'diffusions-advertisements' }">Annonces</router-link>
        </div>
      </div>
      <!-- PARTIE DOCUMENT  -->
      <div class="main grid-x" @click="goTo('documents-space', 'documents-space')" :class="{ selected: openedItem === 'documents'}">
        <document class="image fill" />
        <span class="title">Espace Docs</span>
        <div class="cell auto"></div>
      </div>
      <!-- PARTIE INDICATEURS  -->
      <div v-if="isBrandAdmin" class="main grid-x" @click="goTo('indicators', 'indicators')" :class="{ selected: openedItem === 'indicators' }">
        <statistics class="image fill" />
        <span class="title">Indicateurs</span>
        <div class="cell auto"></div>
      </div>
    </div>
    <div class="shrink">
      <div class="menu-icon">
        <dashes class="icon" v-if="shrinked" @click="shrinked = !shrinked" />
        <dots class="icon" v-else @click="shrinked = !shrinked" />
      </div>
    </div>
  </nav>
</template>

<script>
import arrow from '@/assets/img/nav/arrow.svg?inline';
import wallet from '@/assets/img/nav/wallet.svg?inline';
import calendar from '@/assets/img/nav/calendar.svg?inline';
import suitcase from '@/assets/img/nav/suitcase.svg?inline';
import dots from '@/assets/img/nav/dots.svg?inline';
import dashes from '@/assets/img/nav/dashes.svg?inline';
import layer from '@/assets/img/nav/layer.svg?inline';
import handshake from '@/assets/img/nav/handshake.svg?inline';
import worker from '@/assets/img/nav/worker.svg?inline';
import house from '@/assets/img/nav/house.svg?inline';
import architecture from '@/assets/img/nav/architecture.svg?inline';
import ruler from '@/assets/img/nav/ruler.svg?inline';
import money from '@/assets/img/nav/money.svg?inline';
import setting from '@/assets/img/setting.svg?inline';
import speaker from '@/assets/img/nav/speaker.svg?inline';
import document from '@/assets/img/nav/document.svg?inline';
import statistics from '@/assets/img/nav/statistics.svg?inline';
import memberApi from '@/services/api/member';

export default {
  name: 'side-nav',
  components: {
    arrow,
    wallet,
    calendar,
    suitcase,
    dots,
    dashes,
    layer,
    handshake,
    worker,
    house,
    architecture,
    ruler,
    money,
    setting,
    speaker,
    document,
    statistics,
  },
  data() {
    return {
      isBrandAdmin: null,
      openedItem: null,
      shrinked: false,
      me: null,
    };
  },
  watch: {
    $route() {
      this.openCurrentpage();
    },
  },
  async mounted() {
    await this.getMe();
    this.openCurrentpage();
  },
  methods: {
    openCurrentpage() {
      if (this.$route.name) {
        const mainItem = this.$route.name.split('-')[0];
        this.openedItem = mainItem;
      }
    },
    async open(item) {
      this.openedItem = this.openedItem === item ? null : item;
      if (item) {
        this.shrinked = false;
      }
    },
    async goTo(routeName, mainItem) {
      this.openedItem = mainItem;
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.side-nav
  background-color: $title
  max-width: 244px
  position: relative
  transition: max-width 0.6s
  height: 100vh
  z-index: 5

  /* width */
  ::-webkit-scrollbar
    width: 7px

  /* Track */
  ::-webkit-scrollbar-track
    background: transparent

  /* Handle */
  ::-webkit-scrollbar-thumb
    background: $label
    border-radius: 10px

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover
    background: $label

  .body
    overflow-y: auto
    overflow-x: hidden
    height: calc(100% - 150px)
    .main
      padding: 10px 0
      cursor: pointer
      border-top-right-radius: 5px
      border-bottom-right-radius: 5px
      border-left: 3px solid $title
      margin: 5px 5px 5px 0
      transition: background-color 0.1s, width 1s
      width: 224px
      position: relative
      display: flex
      align-items: center
      .image
        margin: 0 24px
        width: 16px
        transition: margin-right 0.3s
      .arrow
        width: 8px
        position: absolute
        right: 14px
        transition: transform 0.3s, margin-left 0.2s, margin-right 0.2s
      .title
        color: white
        @include heading-sm-bold
        overflow: hidden
        transition: max-width 0.6s, margin-left 0.3s, margin-right 0.3s
        max-width: 100px
        margin-left: 0
        margin-right: 0
      &.selected
        background-color: $body
        border-left: 3px solid $primary
        .arrow
          transform: rotate(90deg)
        .fill
          path
            fill: $primary
        .stroke
          path
            stroke: $primary
    .secondary
      margin-left: 64px
      max-height: 0
      overflow: hidden
      transition: max-height 0.1s, max-width 0.4s
      a
        color: white
        @include heading-sm
        margin-top: 10px
        margin-bottom: 14px
      .router-link-active
        color: $primary
      &.opened
        max-height: 300px
  .shrink
    position: absolute
    margin: auto
    width: 100%
    display: flex
    justify-content: flex-start
    cursor: pointer
    .menu-icon
      margin-left: 24px
      margin-top: 12px
    .icon
      width: 40px
  &.shrinked
    max-width: 100px
    .main
      width: 90px
      .image
        margin-right: 0px
      .title
        max-width: 0
    .secondary
      max-width: 0
      max-height: 0!important
      overflow-y: hidden
  .header
    margin: 24px
</style>
