<template>
  <div class="option-checkbox" :class="{ 'disabled': disabled }">
      <label>
        <input type="checkbox"
          :checked="reverse ? Array.isArray(input) && !input.includes(value) : Array.isArray(input) && input.includes(value)"
          @change="onChange()"
          @blur="$emit('blur')"
          :disabled="disabled"
          :required="required"
        />
        <span class="checkmark">
          <checked />
        </span>
        <div class="value">
          <slot></slot>
        </div>
      </label>
  </div>
</template>

<script>
import checked from '@/assets/img/checked.svg?inline';

export default {
  name: 'option-checkbox',
  components: {
    checked,
  },
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Array, Number],
    value: {
      type: [String],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    propInput() {
      return this.input;
    },
  },
  methods: {
    onChange() {
      let response = null;
      response = Array.isArray(this.input) ? this.input : [];

      if (response.includes(this.value)) {
        response.splice(response.indexOf(this.value), 1);
      } else {
        response.push(this.value);
      }
      this.$emit('input', response);
    },
  },
};
</script>

<style lang="sass" scoped>
.option-checkbox
  display: inline-flex

  label
    @include paragraph
    position: relative
    user-select: none
    cursor: pointer
    display: flex
    align-items: center

  svg
    transform: translateY(-50%)
    opacity: 0
    width: 12px
    height: 8px
    margin-top: 4px
    margin-left: 2px

  input[type="checkbox"]
    position: absolute
    display: none
    top: 0
    left: 0
    height: 0
    width: 0

    &:active
      ~ .checkmark
        background-color: $primary
        border-color: $primary

        svg
          opacity: 1

    &:checked
      ~ .checkmark
        background-color: $primary
        border-color: $primary

        svg
          opacity: 1

    &:focus ~ .checkmark
      @include drop-color($primary)

  .checkmark
    height: 16px
    width: 16px
    background-color: $off-white
    border-radius: 2px
    border: 1px solid $line
    cursor: pointer
    transition: 0.3s ease-in-out

.disabled
  .checkmark, label
    cursor: initial
.value
  margin-left: 14px
</style>
