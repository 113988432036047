<template>
  <div class="hedifys-21 two-factor">
    <div class="presentation cell shrink">
      <router-link :to="{name: 'login'}"><hedifys class="hedifys-logo" /></router-link>
    </div>
    <div class="form-container">
      <router-link :to="{name: 'login'}"><Close /></router-link>
      <form @submit.prevent="verifyTwoFactorCode">
        <div class="row">
          <h2>Double authentification</h2>
          <SendSMS />
          <p v-if="member">Un code vient de vous être envoyé par SMS au {{ member.phone | formatPhone }}. Celui-ci valide votre authentification pour 90 jours.</p>
        </div>
        <div class="row">
          <app-input type="text" v-model="code" label="Code" placeholder="Indiquer le code reçu" required />
          <p v-if="wrongCode" class="error">Le code est incorrect</p>
        </div>
        <div class="row">
          <app-button theme="primary" type="submit">Valider</app-button>
          <p>Si vous n'avez pas reçu le code, cliquez sur <a href="#" @click="sendSMS">renvoyer</a></p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import authApi from '@/services/auth';
import memberApi from '@/services/api/member';

import hedifys from '@/assets/logos/hedifys.svg?inline';
import SendSMS from '@/assets/img/send-sms.svg?inline';
import Close from '@/assets/img/close.svg?inline';

export default {
  name: 'member-two-factor-auth',
  components: {
    hedifys,
    SendSMS,
    Close,
  },
  metaInfo: {
    title: 'Double Authentification',
  },
  data() {
    return {
      code: null,
      member: null,
      isSendingSMS: false,
      wrongCode: false,
    };
  },
  mounted() {
    this.getMe();
  },
  filters: {
    formatPhone(phone) {
      return phone ? phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 XX XX XX $5') : '';
    },
  },
  methods: {
    async getMe() {
      try {
        this.member = await memberApi.getMe({ fromTwoFactor: true });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu une erreur lors de la récupération de vos informations',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async verifyTwoFactorCode() {
      try {
        this.wrongCode = false;
        const result = await authApi.verifyTwoFactorCode(this.code);
        if (result) {
          this.$router.push({ name: 'home' });
        } else {
          this.wrongCode = true;
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu une erreur lors de la vérification de votre code',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async sendSMS() {
      try {
        if (!this.isSendingSMS) {
          this.wrongCode = false;
          this.isSendingSMS = true;
          await authApi.sendTwoFactorCode();
          setTimeout(() => {
            this.isSendingSMS = false;
          }, 10000);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu une erreur lors de l\'envoi du SMS',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass'>
.two-factor
  display: flex
  flex-direction: column
  align-items: center
  height: 100%
  background-image: linear-gradient($gradient-light, $gradient-dark)
  .hedifys-logo
    margin: 24px
    width: 38px
    path
      fill: white
  .form-container
    width: 35%
    margin: 0 auto
    padding: 48px 32px
    border-radius: 4px
    background-color: white
    text-align: center
    position: relative
    .row
      margin-bottom: 40px
      .error
        color: $error
      &:first-child > *
        margin-bottom: 24px
      &:first-child > p, .label, .error
          text-align: left
      &:last-child
        margin-bottom: 0
    a
      color: black
      text-decoration: underline
    > a > svg
      width: 16px
      position: absolute
      top: 24px
      right: 24px
      path
        stroke: black
    button
      width: 100%
      margin-bottom: 8px
</style>
