import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

const sendByProject = async (projectId, token, startDate, endDate, companyType, companies, subject, html, showAll) => {
  try {
    const body = {
      companies,
      subject,
      html,
    };
    const response = await axios.post(`${VUE_APP_API_URL}/pdf/chantiers/${projectId}?${token
      ? `token=${token}&` : ''}${startDate
      ? `startDate=${startDate}&` : ''}${endDate
      ? `endDate=${endDate}&` : ''}${companyType
      ? `companyType=${companyType}&` : ''}${showAll
      ? `showAll=${showAll}&` : ''}`, body);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const getPdfUrl = async (projectId, query) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/pdf/chantiers/${projectId}?${query}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const sendByCompany = async (companyId, token, project, startDate, endDate, onlyMember, subject, html) => {
  try {
    const body = {
      subject,
      html,
    };
    const response = await axios.post(`${VUE_APP_API_URL}/pdf/partenaires/${companyId}?${token
      ? `token=${token}&` : ''}${project
      ? `project=${project}&` : ''}${startDate
      ? `startDate=${startDate}&` : ''}${endDate
      ? `endDate=${endDate}&` : ''}${onlyMember
      ? `onlyMember=${onlyMember}&` : ''}`, body);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const sendReports = async (selectedCompanies, token) => {
  try {
    const body = {
      selectedCompanies,
      token,
    };
    const response = await axios.post(`${VUE_APP_API_URL}/pdf/partenaires/send-reports`, { body });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const generateReports = async (origin, selectedCompanies, isPeriodPreference, startDate, endDate, path) => {
  const body = {
    origin,
    selectedCompanies,
    isPeriodPreference,
    startDate,
    endDate,
    path,
  };
  const response = await axios.post(`${VUE_APP_API_URL}/companies/generate-path-report-pdf`, { body });
  return response.data;
};

const pdf = {};
pdf.sendByProject = sendByProject;
pdf.getPdfUrl = getPdfUrl;
pdf.sendByCompany = sendByCompany;
pdf.generateReports = generateReports;
pdf.sendReports = sendReports;

export default pdf;
