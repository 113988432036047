<template>
  <div class="customer hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="$route.params.customerId && customer && customer.lastname"
          :items="[
            {
              route: {
                name: routeStatus
              },
              name: routeStatus  === 'customers-unqualified'? 'À qualifier' : routeStatus === 'customers-prospects' ? 'Prospects' : 'Clients'
            },
            { route: { name: `${routeStatus}-customerId`,
                params: { customerId: $route.params.customerId }},
              name: `${customer.firstname} ${customer.lastname}`
            }
          ]"
        />
      </div>
    </div>

    <!-- HEADER / TITRE -->
    <div class="new-header grid-x">
      <div class="cell shrink">
        <h1>{{ `${customer.firstname} ${customer.lastname}` }}</h1>
      </div>
        <div class="cell auto tag-container">
          <h5 class="tag" :class="tagColor">{{ customerStatusName }}</h5>
        </div>
      <div class="cell shrink">
        <app-button
          theme="primary"
          size="large"
          icon="add"
          @click="addCustomerEvent"
        >
          Ajouter un évènement
        </app-button>
      </div>
    </div>

    <!-- TABS -->
    <tab-nav :tabs="tabs" v-if="$route.params.customerId" />

    <!-- BODY -->
    <div class="body" v-if="!isLoading && customer">
      <app-calendar
        minTime="07:00:00"
        maxTime="22:00:00"
        :events="fullcalendarCustomerEvents"
        @eventClick="openEvent"
        @dateClick="handleDateClick"
        :disabledEvents="fullcalendarAgencyEvents"
        isFullListAvailable
      />
    </div>
    <div class="body" v-else>
      <div class="card spinner-container">
        <app-spinner />
      </div>
    </div>

    <!-- MODALE-->
    <app-modal-draggable
      :title="customerEvent.customerEventId ? (customerEvent.canceledAt ? 'Événement annulé' : 'Détails de l\'évènement') : 'Nouvel évènement'"
      @show="showCustomerEventModal = true"
      @hide="showCustomerEventModal = false"
    >
      <form @submit.prevent="customerEvent.customerEventId ? updateCustomerEvent() : createCustomerEvent()">
        <app-select
          class="category-select"
          :options="categoryOptions"
          label="Catégorie du rendez-vous"
          v-model="customerEvent.category"
          required
          :disabled="!!customerEvent.canceledAt"
        />
        <app-input
          label="Nom de l'évènement"
          placeholder="Nom..."
          v-model="customerEvent.name"
          required
          :disabled="!!customerEvent.canceledAt"
        />
        <div class="phone-input-container">
          <span class="phone-text">Numéros de téléphone à prévenir par SMS</span>
          <div>
            <app-checkbox
              :value="customer.phone"
              v-model="phoneNumber"
              :disabled="!!customerEvent.canceledAt">
              Principal : {{ customer.phone }}
            </app-checkbox>
          </div>
          <div>
            <app-checkbox
              :value="customer.secondaryContactPhone"
              v-model="phoneNumberSecondary"
              :disabled="!customer.secondaryContactPhone || !!customerEvent.canceledAt">
              Secondaire : {{ customer.secondaryContactPhone }}
            </app-checkbox>
          </div>
          <app-input
            type="text"
            placeholder="Autre numéro"
            v-model="phoneNumberThird"
            :disabled="!!customerEvent.canceledAt"
            size="large"
          />
        </div>
        <div class="form-row">
          <div class="date-container">
            <app-datepicker
              label="Date de début"
              v-model="customerEvent.startedAt"
              required
              :disabled="!!customerEvent.canceledAt"
            />
            <app-timepicker
              :value="$dayjs(customerEvent.startedAt).format('HH:mm')"
              @input="customerEvent.startedAt = setTime(customerEvent.startedAt, $event)"
              :disabled="!!customerEvent.canceledAt"
              min="07:00"
              max="22:00"
            />
          </div>
          <div class="date-container">
            <app-datepicker
              label="Date de fin"
              v-model="customerEvent.endedAt"
              :disabledDates="{ to: customerEvent.startedAt }"
              :disabled="!!customerEvent.canceledAt"
            />
            <app-timepicker
              :value="$dayjs(customerEvent.endedAt).format('HH:mm')"
              @input="customerEvent.endedAt = setTime(customerEvent.endedAt, $event)"
              :min="minEndedAtTime"
              max="22:00"
              :disabled="!!customerEvent.canceledAt"
            />
          </div>
        </div>
        <app-textarea type="textarea" label="Description" v-model="customerEvent.description" placeholder="Description de l'évènement..." :disabled="!!customerEvent.canceledAt" />
        <div class="cta-containers" v-if="!customerEvent.canceledAt">
          <template v-if="customerEvent.customerEventId">
            <app-button type="submit" icon="save">Enregistrer les modifications</app-button>
            <app-button theme="secondary" @click="cancelCustomerEvent">Annuler l'événement</app-button>
          </template>
          <app-button v-else type="submit" icon="add">Créer un événement</app-button>
        </div>
      </form>
    </app-modal-draggable>
  </div>
</template>

<script>
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import TabNav from '@/components/layouts/TabNav.vue';

import customerApi from '@/services/api/customer';
import customerEventApi from '@/services/api/customerEvent';

import customerEventCategories from '@/services/data/customerEventCategories.json';
import customerStatuses from '@/services/data/customerStatuses.json';

export default {
  name: 'customer-agenda',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.customer.lastname ? `${this.customer.lastname} –  Agenda` : 'Agenda',
    };
  },
  data() {
    return {
      // Données pour l'onglet
      isLoading: null,
      customerEvents: [],
      agencyEvents: [],
      showCustomerEventModal: false,
      customerEvent: {
        customerEventId: null,
        name: null,
        startedAt: null,
        endedAt: null,
        category: null,
        description: null,
        phone: null,
        secondaryPhone: null,
        thirdPhone: null,
      },
      categoryOptions: customerEventCategories.filter((cat) => !cat.name.startsWith('FREE')),
      phoneNumber: [],
      phoneNumberSecondary: [],
      phoneNumberThird: null,

      // Données pour le header
      customer: {
        agencyId: null,
        gender: null,
        lastname: null,
        firstname: null,
        status: 'UNQUALIFIED',
        phone: null,
        secondaryContactPhone: null,
      },
    };
  },
  watch: {
    // Reset le customerEvent à la fermeture de la modale
    showCustomerEventModal(isOpening) {
      if (!isOpening) {
        this.$modal.hide('modal-draggable');
        this.resetCustomerEvent();
        this.getCustomerEvents();
      } else {
        this.$modal.show('modal-draggable');
      }
    },
    'customerEvent.startedAt': {
      handler() {
        const { startedAt, canceledAt, endedAt } = this.customerEvent;
        if (startedAt && !canceledAt && !endedAt) {
          this.customerEvent.endedAt = this.$dayjs(startedAt).add(1, 'hour').toDate();
        }
      },
    },
    'customerEvent.category': {
      handler() {
        if (this.customerEvent.category) {
          const categoryLabel = customerStatuses.find((s) => s.name === this.customerEvent.category)?.label;
          this.customerEvent.name = `${categoryLabel || 'RDV'} - ${this.customer.firstname} ${this.customer.lastname}`;
        }
      },
    },
  },
  computed: {
    // Statut du client à afficher en header
    customerStatusName() {
      const customer = customerStatuses.find((el) => el.name === this.customer.status);
      return customer ? customer.label : null;
    },
    // Horaire minimal à respecter pour la fin du rdv
    minEndedAtTime() {
      const { startedAt, endedAt } = this.customerEvent;
      if (this.$dayjs(startedAt).isSame(endedAt, 'day')) {
        return this.$dayjs(startedAt).format('HH:mm');
      }
      return '07:00';
    },
    // Événements clients (et agence) formaté pour fullcalendar
    fullcalendarCustomerEvents() {
      return this.customerEvents.map((evt) => ({
        id: evt.customerEventId,
        title: evt.name,
        start: evt.startedAt,
        end: evt.endedAt,
        description: evt.description,
        classNames: evt.canceledAt ? ['canceled'] : [evt.category.toLowerCase().replace('_', '-')],
      }));
    },
    fullcalendarAgencyEvents() {
      return this.agencyEvents
        .filter((evt) => evt.customer?.customerId !== this.$route.params.customerId)
        .map((evt) => ({
          id: evt.customerEventId,
          title: evt.name,
          start: evt.startedAt,
          end: evt.endedAt,
          description: evt.description,
          classNames: evt.canceledAt ? ['canceled'] : [evt.category.toLowerCase().replace('_', '-')],
        }));
    },
    routeStatus() {
      // on déduit les liens, à partir de la route actuelle
      const routeStatus = this.$route.name.split('-')[1];
      if (routeStatus === 'unqualified' || routeStatus === 'prospects') {
        return `customers-${routeStatus}`;
      }
      return 'projects-customers';
    },
    tagColor() {
      return {
        'tag-primary': this.customer.status === 'CLIENT',
        'tag-secondary': this.customer.status !== 'CLIENT' && this.customer.status !== 'UNQUALIFIED',
        'tag-neutral': this.customer.status === 'UNQUALIFIED',
      };
    },
    tabs() {
      // initialisation des tabs
      const tabs = [
        {
          route: {
            name: `${this.routeStatus}-customerId`,
            params: { customerId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-draft-projects`,
            params: { customerId: null },
          },
          label: 'Avant-projets',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-contact`,
            params: { customerId: null },
          },
          label: 'Prise de contact',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-agenda`,
            params: { customerId: null },
          },
          label: 'Agenda',
        },
      ];
      // Pour les clients, on a ajoute les projets
      if (this.routeStatus === 'projects-customers') {
        tabs.push(
          {
            route: {
              name: `${this.routeStatus}-customerId-projects`,
              params: { customerId: null },
            },
            label: 'Projets',
          },
        );
      }
      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { customerId: this.$route.params.customerId },
        },
        label: el.label,
      }));
    },
  },
  async mounted() {
    await this.getData();

    if (this.routeStatus === 'projects-customers') {
      // On set la valeur du status par défaut à 'Client'
      this.customer.status = 'CLIENT';
    }

    this.phoneNumber = this.customer.phone.toString();
  },
  methods: {
    addCustomerEvent() {
      this.resetCustomerEvent();
      this.showCustomerEventModal = true;
    },
    // Ouvrir la modale au click sur le calendrier
    handleDateClick({ date }) {
      this.resetCustomerEvent();
      this.customerEvent.startedAt = new Date(new Date(date).setHours(date.getHours()));
      if (this.customer?.phone) this.phoneNumber.push(this.customer.phone);
      this.showCustomerEventModal = true;
    },
    // Ouvre la modale d'un événement-client
    openEvent(event) {
      this.resetCustomerEvent();
      const customerEvent = this.customerEvents.find((customerEvt) => customerEvt.customerEventId === event.event.id);
      this.customerEvent = customerEvent;
      if (this.customerEvent?.phone) this.phoneNumber.push(customerEvent.phone);
      if (this.customerEvent?.secondaryPhone) this.phoneNumberSecondary.push(customerEvent.secondaryPhone);
      if (this.customerEvent?.thirdPhone) this.phoneNumberThird = customerEvent.thirdPhone;
      this.showCustomerEventModal = true;
    },
    resetCustomerEvent() {
      this.customerEvent = {
        customerEventId: null,
        name: null,
        startedAt: null,
        endedAt: null,
        category: null,
        description: null,
      };
      this.phoneNumber = [];
      this.phoneNumberSecondary = [];
      this.phoneNumberThird = null;
    },
    // Modifie l'horaire d'un timestamp selon la valeur d'un timepicker
    setTime(date, time) {
      const [hour, minute] = time.split(':');
      // Utilises la date du jour si date invalide
      const isDateInvalid = !date || Number.isNaN((new Date(date)).getTime());

      const dateDayjs = this.$dayjs(isDateInvalid ? undefined : date).hour(hour).minute(minute);
      return dateDayjs.toDate();
    },
    async getData() {
      this.isLoading = true;

      if (this.$route.params.customerId) {
        await Promise.all([this.getCustomer(), this.getCustomerEvents()]);
        await this.getAgencyEvents();
      } else {
        this.customer.agencyId = this.agencies[0].agencyId;
      }
      this.isLoading = false;
    },
    // Requête API : récupère le client
    async getCustomer() {
      try {
        this.customer = await customerApi.getById(this.$route.params.customerId);

        if (!this.customer.address) {
          this.customer.address = {
            addressLine1: null,
            postalCode: null,
            city: null,
          };
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du contact',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Requête API : récupère la liste des rdv du client
    async getCustomerEvents() {
      try {
        const response = await customerEventApi.getByCustomer(this.$route.params.customerId);
        this.customerEvents = response.data;
        this.customerEvents.forEach((e) => {
          e.startedAt = new Date(e.startedAt);
          e.endedAt = new Date(e.endedAt);
        });
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des rdv du contact',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Requête API : récupère la liste des rdv de l'agence du client
    async getAgencyEvents() {
      try {
        const response = await customerEventApi.getAll(null, this.customer.agencyId);
        this.agencyEvents = response.data;
        this.agencyEvents.forEach((e) => {
          e.startedAt = new Date(e.startedAt);
          e.endedAt = new Date(e.endedAt);
        });
      } catch (e) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des rdv de l\'agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Requête API : créé un rdv client
    async createCustomerEvent() {
      try {
        await customerEventApi.create({
          customerId: this.$route.params.customerId,
          category: this.customerEvent.category,
          name: this.customerEvent.name,
          description: this.customerEvent.description,
          endedAt: this.customerEvent.endedAt,
          startedAt: this.customerEvent.startedAt,
          phone: this.phoneNumber.length ? this.phoneNumber[0] : this.customer.phone,
          secondaryPhone: this.phoneNumberSecondary[0] || null,
          thirdPhone: this.phoneNumberThird || null,
          email: this.customerEvent.email || this.customer.email,
          recurrencePeriod: 'NO_REPEAT',
        });
        this.showCustomerEventModal = false;
        this.$notification.show({ text: 'Rdv ajouté avec succès !' });

        this.updateCustomerStatus(); // Mettre à jour le statut au cas où il aurait changé
        await Promise.all([this.getCustomerEvents(), this.getAgencyEvents()]);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de l\'ajout du rdv.',
          hasConfirm: true,
        });
      }
    },
    // Requête API : modifie un événement-client
    async updateCustomerEvent() {
      try {
        const { customerEventId, ...customerEvent } = this.customerEvent;
        const response = await customerEventApi.update(
          customerEventId,
          {
            name: customerEvent.name,
            startedAt: customerEvent.startedAt,
            endedAt: customerEvent.endedAt,
            description: customerEvent.description,
            category: customerEvent.category,
            customerId: this.$route.params.customerId,
            phone: this.phoneNumber.length ? this.phoneNumber[0] : this.customer.phone,
            secondaryPhone: this.phoneNumberSecondary[0] || null,
            thirdPhone: this.phoneNumberThird || null,
            email: customerEvent.email || this.customer.email,
            recurrencePeriod: 'NO_REPEAT',
          },
        );

        // Modifie le rdv dans le calendrier
        const index = this.customerEvents.findIndex((evt) => evt.customerEventId === response.data.customerEventId);
        this.customerEvents.splice(index, 1, response.data);

        this.showCustomerEventModal = false;
        this.$notification.show({ text: 'Rdv mis à jour avec succès !' });

        this.updateCustomerStatus(); // Mettre à jour le statut au cas où il aurait changé
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de la mise à jour du rdv.',
          hasConfirm: true,
        });
      }
    },
    // Requête API : annule un événement-client
    async cancelCustomerEvent() {
      try {
        const { customerEventId } = this.customerEvent;
        await customerEventApi.cancel(customerEventId);

        this.showCustomerEventModal = false;
        await this.getCustomerEvents();
        this.$notification.show({ text: 'Rdv annulé avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de l\'annulation du rdv.',
          hasConfirm: true,
        });
      }
    },
    // Requête API: récupère le statut d'un client (nécessaire après création/modification d'un rdv)
    async updateCustomerStatus() {
      try {
        const customer = await customerApi.getById(this.$route.params.customerId);
        if (this.customer && this.customer.status) {
          this.customer.status = customer.status;
        }
      } catch (e) {
        // ne ren faire: le statut du client ne sera juste pas à jour
      }
    },
  },
};
</script>

<style lang='sass'>
.customer
  .body
    @include screen
  .new-header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  h3
    color: $primary
    margin-bottom: 10px
  .tag-container
    display: flex
    align-items: center
    margin-left: 16px
    > h5
      height: fit-content
  .tag-primary
    @include tag-primary
  .tag-secondary
    @include tag-secondary
  .modal-wrapper
    form
      display: flex
      flex-direction: column
      gap: 16px
      align-items: flex-start
      .phone-input-container
        width: 100%
        .phone-text
          @include label
        >div
          margin-top: 8px
      .form-row
        display: flex
        gap: 16px
        justify-content: stretch
        width: 100%
        .date-container
          width: 100%
          display: flex
          gap: 8px
          align-items: flex-end
          .app-datepicker
            width: 100%
      .category-select
        @include customer-event-category-select
      .cta-containers
        margin: 0
        width: 100%
        display: flex
        gap: 16px
        justify-content: center
  .app-calendar
    .fc-event
      &.r1
        border: $category-r1
        background: $category-r1
      &.r2
        border: $category-r2
        background: $category-r2
      &.r3
        border: $category-r3
        background: $category-r3
      &.r4
        border: $category-r4
        background: $category-r4
      &.land-visit
        border: $category-land-visit
        background: $category-land-visit
      &.contract-signature
        border: $category-contract-signature
        background: $category-contract-signature
      &.map
        border: $category-map
        background: $category-map
      &.purchase-agreement
        border: $category-purchase-agreement
        background: $category-purchase-agreement
      &.canceled
        border: $line
        background: $line
        text-decoration: line-through darken($label, 10%)
        > .fc-content span
          color: $label
      &.disabled
        opacity: 0.3
    .fc-list-item
      &.r1 .fc-event-dot
        background: $category-r1
      &.r2 .fc-event-dot
        background: $category-r2
      &.r3 .fc-event-dot
        background: $category-r3
      &.r4 .fc-event-dot
        background: $category-r4
      &.land-visit .fc-event-dot
        background: $category-land-visit
      &.contract-signature .fc-event-dot
        background: $category-contract-signature
      &.purchase-agreement .fc-event-dot
        background: $category-purchase-agreement
      &.map .fc-event-dot
        background: $category-map
      &.canceled .fc-event-dot
        background: $line
</style>
