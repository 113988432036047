<template>
  <div class="agency-members hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="agency && agency.name"
          :items="[
            { route: { name: 'agencies'}, name: 'Agences'},
            { route: { name: 'agencies-agencyId', params: { agencyId: $route.params.agencyId }}, name: agency.name}
          ]"
        />
      </div>
    </div>

    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>{{ agency && agency.name ? agency.name : '' }}</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="openInviteModal">Inviter un membre</app-button>
      </div>
    </div>

    <!-- TABS -->
    <tab-nav :tabs="tabs" />
    <div class="body">
      <app-table :headers="tableHeaders" :data="members" :loading="isLoading" @line-cliked="openUpdateModal" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template v-if="!isLoading" slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="photo" slot-scope="{ data }">
          <div class="photo">
            <img v-if="data.photo" :src="data.photo" />
            <avatar v-else />
          </div>
        </template>
        <template slot="firstname" slot-scope="{ data }">
          <strong>{{ data.firstname }}</strong>
        </template>
        <template slot="lastname" slot-scope="{ data }">
          <strong>{{ data.lastname }}</strong>
        </template>
        <template slot="email" slot-scope="{ data }">
          <p>{{ data.email }}</p>
        </template>
        <template slot="phone" slot-scope="{ data }">
          <p>{{ data.phone }}</p>
        </template>
        <template slot="jobTitle" slot-scope="{ data }">
          <p>{{ data.jobTitle && agencyJobs.find(j => j.name === data.jobTitle) ? agencyJobs.find(j => j.name === data.jobTitle).label : data.jobTitle }}</p>
        </template>
        <template slot="link">
          <div class="arrow-container">
            <arrow class="arrow" />
          </div>
        </template>
      </app-table>
    </div>

    <!-- MEMBER EDIT MODAL --- -->
    <app-modal class="edit-member" :show="isUpdateModalOpen" size="medium" title="Modification du membre" @update:show="isUpdateModalOpen = false">
      <form ref="agency" @submit.prevent="updateMember">
        <div class="grid-x grid-margin-x row">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-avatar v-model="selectedMember.photo" @upload="storePhoto" @remove="removePhoto" />
          </div>
          <div class="cell auto"></div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell"><app-input v-model="selectedMember.email" label="Email" placeholder="Email..." type="email" required /></div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-input v-model="selectedMember.phone" label="Téléphone 1" placeholder="Téléphone 1..." />
          </div>
          <div class="cell auto">
            <app-input v-model="selectedMember.secondaryPhone" label="Téléphone 2" placeholder="Téléphone 2..." />
          </div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-input v-model="selectedMember.firstname" label="Prénom" placeholder="Prénom 1..." required />
          </div>
          <div class="cell auto">
            <app-input v-model="selectedMember.lastname" label="Nom" placeholder="Nom..." required />
          </div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell">
            <app-select
              label="Poste"
              v-model="selectedMember.jobTitle"
              :options="agencyJobs" />
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-button v-if="me && selectedMember && selectedMember.memberId !== me.memberId" theme="warning" size="large" icon="remove" @click="removeRule">Enlever les droits</app-button>
          </div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" icon="edit" type="submit">Modifier le membre</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
      </form>
    </app-modal>

    <!-- MEMBER CREATION MODAL --- -->
    <app-modal class="create-member" :show="isInviteModalOpen" size="small" title="Inviter un nouveau membre" @update:show="isInviteModalOpen = false">
      <form ref="agency" @submit.prevent="inviteMember">
        <div class="grid-x grid-margin-x row">
          <div class="cell">
            <p>Le nouveau membre recevra un mail l'invitant à finaliser le création de son compte. Si celui-ci possède déjà une agence, alors elle sera ajouté à sa liste d'agences.</p>
          </div>
        </div>
        <div class="grid-x grid-margin-x row">
          <div class="cell"><app-input v-model="inviteEmail" label="Email" placeholder="Email..." type="email" required /></div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto"></div>
          <div class="cell shrink">
            <app-button theme="primary" size="large" icon="add" type="submit">Inviter</app-button>
          </div>
          <div class="cell auto"></div>
        </div>
      </form>
    </app-modal>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import agency from '@/services/api/agency';
import member from '@/services/api/member';
import auth from '@/services/auth';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

import avatar from '@/assets/img/avatar.svg?inline';
import arrow from '@/assets/img/arrow.svg?inline';
import agencyJobs from '@/services/data/agencyJobs.json';

export default {
  name: 'agency-members',
  components: {
    TabNav,
    Breadcrumb,
    avatar,
    arrow,
  },
  metaInfo() {
    return {
      title: this.agency ? `${this.agency.name} – Agence` : 'Agence',
    };
  },
  data() {
    return {
      agencyJobs,
      tabs: [],
      brand: null,
      members: null,
      agency: null,
      tableHeaders: [
        { label: '', key: 'photo', size: 1 },
        { label: 'Prénom', key: 'firstname', size: 1 },
        { label: 'Nom', key: 'lastname', size: 1 },
        { label: 'Email', key: 'email', size: 'auto' },
        { label: 'Téléphone', key: 'phone', size: 2 },
        { label: 'Poste', key: 'jobTitle', size: 2 },
        { label: '', key: 'link', size: 1 },
      ],
      isLoading: true,
      isUpdateModalOpen: false,
      isInviteModalOpen: false,

      selectedMember: {},
      selectedPhoto: null,
      inviteEmail: null,
      me: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.me = await member.getMe();
    this.tabs = [
      {
        route: {
          name: 'agencies-agencyId',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Informations',
      },
      {
        route: {
          name: 'agencies-agencyId-postal-codes',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Secteurs',
      },
      {
        route: {
          name: 'agencies-agencyId-medias',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Médias',
      },
      {
        route: {
          name: 'agencies-agencyId-members',
          params: { agencyId: this.$route.params.agencyId },
        },
        label: 'Membres',
      },
    ];
    // Masque l'onglet média aux admins d'agence
    if (!this.me.isBrandAdmin) {
      const index = this.tabs.map((e) => e.label).indexOf('Médias');
      this.tabs.splice(index, 1);
    }
    await this.getBrand();
    await this.getMembers();
    await this.getAgency();
    this.isLoading = false;
  },
  methods: {
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAgency() {
      try {
        if (this.$route.params.agencyId) {
          this.agency = await agency.getById(this.$route.params.agencyId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de l\' agence',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMembers() {
      try {
        if (this.$route.params.agencyId) {
          this.members = await member.getByAgency(this.$route.params.agencyId);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des membres',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    openUpdateModal(selectedMember) {
      this.selectedMember = { ...selectedMember };
      this.isUpdateModalOpen = true;
    },
    openInviteModal() {
      this.isInviteModalOpen = true;
    },
    async inviteMember() {
      this.isLoading = true;
      try {
        if (this.inviteEmail && this.$route.params.agencyId && this.brand.brandId) {
          await member.invite(this.inviteEmail, this.brand.brandId, this.$route.params.agencyId);
          this.$notification.show({ text: 'Ce membre a été invité ! \n Il sera ajouté à la liste, une fois son inscription faite' });
          this.isInviteModalOpen = false;

          this.getMembers();
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de l\'invitation du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async updateMember() {
      this.isLoading = true;
      try {
        if (this.selectedMember && this.selectedMember.memberId) {
          const { memberId } = this.selectedMember;
          delete this.selectedMember.rule;
          delete this.selectedMember.deletedAt;
          delete this.selectedMember.updatedAt;
          delete this.selectedMember.createdAt;
          delete this.selectedMember.createdAt;
          await member.update(memberId, this.selectedMember);

          if (this.selectedPhoto) {
            await this.uploadPhoto();
          }

          this.isUpdateModalOpen = false;

          this.getMembers();
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    storePhoto(event) {
      const file = new FormData();
      file.append('file', event);
      this.selectedPhoto = file;
      this.selectedMember.photo = URL.createObjectURL(event);
    },
    async removePhoto() {
      this.selectedPhoto = null;
      this.selectedMember.photo = null;
    },
    async uploadPhoto() {
      try {
        const updatedMember = await member.uploadPhoto(this.selectedMember.memberId, this.selectedPhoto);
        this.avatar = updatedMember.data.photo;
      } catch (er) {
        throw er;
      }
    },
    async removeRule() {
      const ruleId = this.selectedMember && this.selectedMember.rule ? this.selectedMember.rule.ruleId : null;
      if (ruleId) {
        this.$message.show({
          title: 'Enlever les droits',
          text: 'Attention, si vous confirmez, l\'utilisateur ne pourra plus accéder à cette agence',
          confirmText: 'Enlever les droits',
          hasConfirm: true,
          cancelText: 'Annuler',
          hasCancel: true,
          onConfirm: async () => {
            this.isLoading = true;
            try {
              await member.deleteRule(ruleId);
              this.isUpdateModalOpen = false;
              await this.getMembers();
            } catch (er) {
              this.$message.show({
                title: 'Erreur',
                text: 'Il y a eu un problème lors de la suppression des droits du membre',
                cancelText: 'Ok',
                confirmText: '',
                hasCancel: true,
                hasConfirm: false,
              });
            }
            this.isLoading = false;
          },
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.agency-members
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .photo
    background-color: $line
    border-radius: 50%
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    svg
      margin-top: 9px
      width: 30px
      height: 30px
      fill: $off-white
  .arrow-container
    display: flex
    justify-content: flex-end
    .arrow
      height: 12px
      path
        stroke: $info
</style>
