<template>
  <div class="version-lots hedifys-21">
    <form ref="version-lots" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            :items="[
              { route: { name: 'versions'}, name: 'Versions'},
              { route: { name: 'versions-versionId', params: { agencyId: $route.params.agencyId }}, name: version ? version.reference : '' }
            ]"
          />
        </div>
      </div>

      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>{{ version ? version.reference : '' }}</h1>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>

      <!-- TABS -->
      <tab-nav :tabs="tabs" />

      <!-- BODY / FORMULAIRE -->
      <div class="body">
        <div v-if="!isLoading && version">
          <div class="row">
            <h2>Lots métrés</h2>
            <p>Le prix de cette version est constitué de plusieurs montants et paramètres à compléter.</p>
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell small-6">
              <div class="card">
                <div class="grid-x grid-margin-x row">
                  <div class="cell auto">
                    <h3 class="version-lots-card-title">Prix marque TTC</h3>
                    <p class="details">(Attention à l’agence, ce prix peut évoluer)</p>
                  </div>
                  <div class="cell shrink"/>
                  <div class="cell shrink">
                    <h3>{{ utils.formatCentToEuro(getBrandTotalPrice, true) }}</h3>
                  </div>
                </div>
                <div class="grid-x grid-margin-x row">
                  <div class="cell auto align-self-middle">
                    <p class="paragraph-bold">Marge version HT {{ brand.name }}</p>
                  </div>
                  <div class="cell shrink"/>
                  <div class="cell medium-5">
                    <app-input
                      v-model.number="version.brandMargin"
                      placeholder="Marge"
                      type="number"
                      :step="0.01"
                      unit="€"
                      :min="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="mergedLotsSurveys && mergedLotsSurveys.length" class="grid-x grid-margin-x">
            <div class="cell small-12">
              <div class="card">
                <div class="grid-x grid-margin-x row">
                  <div class="cell shrink">
                    <h3 class="version-lots-card-title">Lots métrés</h3>
                  </div>
                  <div class="cell shrink">
                    <app-switch v-model="showDescription" :checked="showDescription" />
                  </div>
                  <div class="cell auto">
                    <h4>Afficher les prix</h4>
                  </div>
                  <div class="cell shrink">
                    <h3>Total HT : {{ utils.formatCentToEuro(getLotsSurveysTotalPrice, true) }}</h3>
                  </div>
                </div>
                <div class="grid-x grid-margin-x row">
                  <div class="cell auto">
                    <app-table
                      :headers="tableHeader"
                      :data="mergedLotsSurveys"
                      small
                    >
                      <template slot="loading">
                        <app-spinner />
                      </template>
                      <template slot="lotSurvey" slot-scope="{ data }">
                        <strong>{{ data.name }}</strong>
                      </template>
                      <template slot="description" slot-scope="{ data }">
                        <app-input v-model="data.description" placeholder="Description"/>
                      </template>
                      <template slot="price" slot-scope="{ data }">
                        <app-input
                          v-model.number="data.price"
                          placeholder="Prix"
                          type="number"
                          :step="0.01"
                          unit="€"
                          :min="0"
                        />
                      </template>
                      <template slot="empty-table">
                        <p>Aucune donnée de disponible</p>
                      </template>
                    </app-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- LOADER -->
        <div v-else class="loader">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import versionApi from '@/services/api/version';
import lotSurveyApi from '@/services/api/lotSurvey';
import lotSurveySpecificationApi from '@/services/api/lotSurveySpecification';
import utils from '@/services/utils/utils';
import auth from '@/services/auth';

import { create, all } from 'mathjs';

export default {
  name: 'version-lots',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.version ? `${this.version.reference} – Version` : 'Version',
    };
  },
  data() {
    return {
      tabs: [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Prix',
        },
      ],
      isLoading: false,

      tableHeader: [
        { label: 'Lot métré', key: 'lotSurvey', size: 3 },
        { label: 'Prix', key: 'price', size: 2 },
        { label: 'Description', key: 'description', size: 7 },
      ],
      showDescription: true,

      brand: null,
      version: null,
      lotsSurveys: null,
      lotsSurveysSpecifications: null,
      mergedLotsSurveys: [],

      utils,
      mathjs: null,
    };
  },

  mounted() {
    this.mathjs = create(all);
    this.mathjs.config({ number: 'number', precision: 14 });

    this.getData();
  },

  watch: {
    // Affiche / cache la colonne description du tableau
    showDescription(value) {
      if (value) {
        this.tableHeader.splice(2, 0, { label: 'Description', key: 'description', size: 7 });
        this.tableHeader[0].size = 3;
        this.tableHeader[1].size = 2;
      } else {
        this.tableHeader.splice(2, 1);
        this.tableHeader[0].size = 9;
        this.tableHeader[1].size = 3;
      }
    },
  },

  computed: {
    // Calcule du totale du prix de la marque, total des lots métrés + la marque de la marque
    getBrandTotalPrice() {
      if (this.version) {
        return this.mathjs
          .chain(this.getLotsSurveysTotalPrice)
          .add(utils.formatEuroToCent(this.version.brandMargin))
          .multiply(1.2)
          .done();
      }
      return 0;
    },

    // Calcule du totale des prix des lots métrés
    getLotsSurveysTotalPrice() {
      if (this.mergedLotsSurveys) {
        return this.mergedLotsSurveys.reduce(
          (a, b) => this.mathjs
            .chain(a).add(parseFloat(b.price === '' ? 0 : utils.formatEuroToCent(b.price))).done(), 0,
        );
      }
      return null;
    },
  },

  methods: {
    // Récupération des données
    async getData() {
      this.isLoading = true;

      await this.getBrand();
      await this.getVersion();
      await this.getLotsSurveys();
      await this.getLotsSurveysSpecifications();
      await this.getMergedLotsSurveys();

      this.isLoading = false;
    },

    // Récupération de la marque
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération de la version
    async getVersion() {
      try {
        const data = await versionApi.getById(this.$route.params.versionId);

        // On converti brandMargin en euro
        this.version = { ...data, brandMargin: utils.formatCentToEuro(data.brandMargin ? data.brandMargin : this.brand.margin) };
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la version.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des lots métrés
    async getLotsSurveys() {
      try {
        this.lotsSurveys = await lotSurveyApi.getAll(null, null, null);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération des lots métrés spécification
    async getLotsSurveysSpecifications() {
      try {
        const data = await lotSurveySpecificationApi.getByVersionId(this.$route.params.versionId);

        // On converti le prix de chaque lot métré en euro
        this.lotsSurveysSpecifications = data.map((lotSurvey) => ({ ...lotSurvey, price: utils.formatCentToEuro(lotSurvey.price) }));
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lots métrés spécification.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // On merge les lots métrés et les lots métrés spécifications
    getMergedLotsSurveys() {
      this.mergedLotsSurveys = this.lotsSurveys.map((lotSurvey) => {
        const matchLotSurvey = this.lotsSurveysSpecifications.find((obj) => obj.lotSurvey.lotSurveyId === lotSurvey.lotSurveyId);

        if (matchLotSurvey) {
          return {
            name: lotSurvey.name,
            description: matchLotSurvey.description,
            price: matchLotSurvey.price,
            lotSurveyId: lotSurvey.lotSurveyId,
          };
        }

        return {
          name: lotSurvey.name,
          description: null,
          price: 0,
          lotSurveyId: lotSurvey.lotSurveyId,
        };
      });
    },

    // Sauvegarde des données
    async save() {
      // Sauvegarde de la version
      try {
        // On reformat l'objet de la version à envoyer pour qu'il convienne la route d'édition
        const editedVersion = JSON.parse(JSON.stringify(this.version));
        editedVersion.modelId = this.version.model.modelId;

        // On supprime les propriétés non attendus dans la route
        delete editedVersion.model;
        delete editedVersion.range;
        delete editedVersion.versionMedia;
        delete editedVersion.versionMediaMain;
        delete editedVersion.surface;
        delete editedVersion.price;
        delete editedVersion.brandId;
        delete editedVersion.numberOfRooms;
        delete editedVersion.createdAt;
        delete editedVersion.updatedAt;
        delete editedVersion.deletedAt;
        delete editedVersion.habitableSurface;
        delete editedVersion.surfaces;

        // On converti brandMargin en centimes
        editedVersion.brandMargin = utils.formatEuroToCent(editedVersion.brandMargin);

        await versionApi.update(this.$route.params.versionId, editedVersion);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour de la version.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      // Sauvegarde des lots métrés
      try {
        // On supprime la propriété name des lots métrés spécifications
        const data = this.mergedLotsSurveys.map(({ name, ...keepingData }) => ({
          ...keepingData,
          price: keepingData.price ? Math.round(utils.formatEuroToCent(keepingData.price)) : 0,
        }));

        await lotSurveySpecificationApi.update(
          this.$route.params.versionId,
          data,
        );
        this.$notification.show({ text: 'Version mise à jour avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la mise à jour des lots métrés.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.getData();
    },
  },
};
</script>

<style lang='sass'>
.version-lots
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .breadcrumbs
    @include breadcrumbs
  .row
    @include row
  .duplicate
    margin-left: 1rem
  .spinner-container
    @include spinner-container
  .card
    @include card
  .version-lots-card-title
    color: $primary
    margin-bottom: 4px
  .details
    @include details
  .paragraph-bold
    @include paragraph-bold
  h2
    margin-bottom: 10px
  .app-table .data.auto
    height: initial
    flex: initial
</style>
