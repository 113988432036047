const TemplateMedia = Object.freeze({
  TEMPLATE_LAND_MAIN_PHOTO: 'template-land-main-photo',
  TEMPLATE_LAND_LAYER_PHOTO: 'template-land-layer-photo',
  TEMPLATE_LAND_SECONDARY_PHOTO: 'template-land-secondary-photos',
  TEMPLATE_LAND_VERSION_MAIN_PHOTO: 'template-land-version-main-photo',
  TEMPLATE_LAND_VERSION_LAYER_PHOTO: 'template-land-version-layer-photo',
  TEMPLATE_LAND_VERSION_SECONDARY_PHOTO: 'template-land-version-secondary-photos',
});

export default TemplateMedia;
