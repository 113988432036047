<template>
<div class="app-timepicker">
  <input
    type="time"
    :value="value"
    :disabled="disabled"
    :required="required"
    :min="min"
    :max="max"
    @input="$emit('input', $event.target.value)"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
  />
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
};
</script>

<style lang='sass' scoped>
.app-timepicker
  input
    border: 1px solid $line
    border-radius: $global-border-radius
    padding: 8px
    background: $off-white
    color: $body
    &::-webkit-calendar-picker-indicator
      filter: invert(77%) sepia(11%) saturate(434%) hue-rotate(188deg) brightness(84%) contrast(96%)
    &:focus
      outline: none
      box-shadow: 0 0 2px $primary
      border: 1px solid $primary
    &:disabled
      background-color: $background
      color: $label
      pointer-events: none
      cursor: not-allowed
</style>
