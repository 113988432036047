import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère une agence
 * @param {string} postalCodeInseeNumber: postalCodeInseeNumber de l'agence
 */
const getByPostalCode = async (postalCodeInseeNumber) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/postal-codes/${postalCodeInseeNumber}/agency`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère une agence
 * @param {string} agencyId: id de l'agence à récupérer
 */
const getById = async (agencyId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/${agencyId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupère la liste des agences
 * @param {string} search: paramètre de recherche, sur le nom de l'agence
 */
const getAll = async (search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Création d'une agence
 * @param {agency} agency: objet de l'agence à créer
 */
const create = async (agency) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/agencies/`, agency);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Mise à jour d'une agence
 * @param {agency} agency: nouvel objet de l'agence
 * @param {string} agencyId: id de l'agence à récupérer
 */
const update = async (agencyId, agency) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/agencies/${agencyId}`, agency);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Suppression de l'agence
 * @param {string} agencyId: id de l'agence à récupérer
 */
const deleteOne = async (agencyId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/agencies/${agencyId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupération du nombre de communes par agence possédant des terrains disponibles
 */
const getNbCitiesAvailableLandByAgency = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/quota-cities`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const agency = {};
agency.getAll = getAll;
agency.getById = getById;
agency.create = create;
agency.update = update;
agency.delete = deleteOne;
agency.getByPostalCode = getByPostalCode;
agency.getNbCitiesAvailableLandByAgency = getNbCitiesAvailableLandByAgency;

export default agency;
