import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère tous les thèmes
 * @param {string} search: terme de recherche
 * @param {string} categoryId: catégorie à rechercher
 * @param {string} lotId: lot à rechercher
 */
const getAll = async (search, categoryId, lotId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/themes/`, {
      params: {
        ...(search && { search }),
        ...(categoryId && { categoryId }),
        ...(lotId && { lotId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Créer un thème
 * @param {object} data: données du thème à créer
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/themes/`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Met à jour un thème
 * @param {string} themeId: id du thème à mettre à jour
 * @param {object} data: données du thème
 */
const update = async (themeId, data) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/themes/${themeId}`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Supprime un thème
 * @param {string} themeId: id du thème à supprimer
 */
const deleteOne = async (themeId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/themes/${themeId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const theme = {};
theme.getAll = getAll;
theme.create = create;
theme.update = update;
theme.delete = deleteOne;

export default theme;
