<template>
  <vk-modal :show.sync="showModal" class="companies-add-modal" center>
    <vk-modal-close @click="showModal = false"></vk-modal-close>
    <div class="header">
      Ajouter des partenaires
    </div>
    <div class="container">
      <vk-grid v-if="projectSteps && projectSteps.length > 0" gutter="collapse" class="uk-child-width-1-1">
        <div v-for="(step, index) in projectSteps" :key="`partner-add-modal-step-${index}`">
          <div class="company-category" v-if="(index === 0) || (projectSteps[index - 1]) && (projectSteps[index - 1].companyCategory.companyCategoryId !== step.companyCategory.companyCategoryId)">
              {{ step.companyCategory.label }}
              {{ displayCompanyCategoryType(step.companyCategory.companyCategoryId) ? ` - ${displayCompanyCategoryType(step.companyCategory.companyCategoryId)}` : '' }}
          </div>

          <vk-grid class="task uk-child-width-expand" gutter="collapse">
            <div class="label">{{ step.label }}</div>
            <div class="company">
              <select class="uk-select" v-model="step.company.companyId">
                <option :value="null">Pas d'entreprise sélectionnée</option>
                <option :value="company.companyId"
                  v-for="company in companies.filter(company => company.companyCategories.includes(step.companyCategory.companyCategoryId) && company.isAgencyRegistered)"
                  :key="company.companyId">{{ company.name }}</option>
              </select>
            </div>
          </vk-grid>
        </div>
      </vk-grid>
    </div>

    <vk-grid gutter="collapse">
      <div class="column uk-width-expand">
        <div class="actions">
          <vk-button class="secondary button" @click="showModal = false">Annuler</vk-button>
          <vk-button class="primary button" @click="saveData()">Valider</vk-button>
        </div>
      </div>
    </vk-grid>
  </vk-modal>
</template>

<script>
import types from '@/services/data/types.json';
import projectStep from '@/services/api/projectStep';

export default {
  name: 'companies-add-modal',
  props: ['show', 'companies', 'companyCategories', 'projectStepsWithPartner'],
  data() {
    return {
      projectSteps: [],
      showModal: false,
      selectedCompany: null,
      loading: true,
    };
  },
  async created() {
    this.loading = false;
  },
  watch: {
    show(newValue) {
      /* Ouvrir ou fermer la modale quand le bouléen en props change */
      this.showModal = newValue;
      this.projectSteps = JSON.parse(JSON.stringify(this.projectStepsWithPartner));
    },
    showModal(newValue) {
      /* Envoyer un évenement au parent, quand on ferme la modale */
      if (!newValue) {
        this.$emit('close');
      }
    },
  },
  methods: {
    async saveData() {
      try {
        await Promise.all(this.projectSteps.map(async (step) => {
          await projectStep.edit({
            projectStepId: step.projectStepId,
            projectId: step.project.projectId,
            companyId: step.company.companyId,
            companyCategoryId: step.companyCategory.companyCategoryId,
            label: step.label,
            rank: step.rank,
            startedAt: step.startedAt,
            closedAt: step.closedAt,
            status: step.status,
            type: step.type,
            confirmed: step.confirmed,
          });
        }));
        this.$notification.show({ text: 'Ce project a été modifié avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'La sauvegarde des partenaires a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.showModal = false;
      this.$emit('companies-updated');
    },
    displayCompanyCategoryType(companyCategoryId) {
      let companyCategoryType = this.companyCategories.find((companyCategory) => companyCategory.companyCategoryId === companyCategoryId);

      if (companyCategoryType) {
        companyCategoryType = types.companyCategoryType[companyCategoryType.type];
      } else {
        companyCategoryType = null;
      }
      return companyCategoryType;
    },
  },
};
</script>

<style lang="scss">
.companies-add-modal {
  .header {
    padding: 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    background-color: white;
  }
  .container {
    .company-category {
      padding: 15px 30px;
      color: $blue-light;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid #BAC3D4;
    }
    .task {
      padding: 15px 30px;
      border-bottom: 1px solid #BAC3D4;
    }
  }
  .actions {
    display: flex;
    margin: 0 !important;
    justify-content: flex-end;
  }
}
</style>
