<template>
  <div class="version-summary hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="version && version.name"
          :items="[
            { route: { name: 'versions'}, name: 'Versions' },
            { route: { name: 'versions-versionId', params: { versionId: $route.params.versionId }}, name: version.reference }
          ]"
        />
      </div>
    </div>

    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>{{ version && version.reference ? version.reference : '' }}</h1>
      </div>
      <div class="cell shrink" v-if="isBrandAdmin">
        <app-button
          theme="warning"
          size="large"
          icon="remove"
          @click="openDeleteModal"
        >
          Supprimer
        </app-button>
      </div>
      <div class="cell shrink duplicate" v-if="isBrandAdmin">
        <app-button
          theme="primary"
          size="large"
          @click="duplicateVersion"
        >
          Dupliquer
        </app-button>
      </div>
    </div>

    <!-- TABS -->
    <tab-nav :tabs="tabs" v-if="isBrandAdmin" />

    <!-- BODY / FORMULAIRE -->
    <div class="body" v-if="!isLoading && version">

      <!-- DESCRIPTION & ILLUSTRATION -->
      <div class="card">
        <div class="grid-x grid-margin-x row">
          <div class="cell medium-4 media-container">
            <img
              v-if="version.versionMedia"
              :src="version.versionMedia"
              class="illustration"
              alt="Image"
            />
            <div v-else class="illustration not-found">
              <upload />
            </div>
          </div>
          <div class="cell medium-8">
            <h2>Maison {{ version.numberOfRooms }} chambre{{ version.numberOfRooms > 1 ? 's' : '' }} - {{version.surface ? ` ${version.surface} m²`: '' }} - {{ version.name }}</h2>
            <h3 class="version-reference">{{ version.reference }}</h3>
            <h2 class="version-price">{{ utils.formatCentToEuro(version.price, true) }}</h2>
            <h5 class="version-price-description">prix agence TTC</h5>
            <p class="version-description">{{ version.description }}</p>
          </div>
        </div>
      </div>

      <div class="grid-x grid-margin-x">

        <!-- SURFACES & PIÈCES -->
        <div class="cell medium-6">
          <div class="card version-surfaces">
            <div class="grid-x grid-margin-x row version-surfaces-header">
              <div class="cell auto">
                <h4>Surface totale</h4>
                <h3>{{ version.surface }} m²</h3>
              </div>
              <div class="cell auto">
                <h4 class="version-align-right">Surface totale habitable</h4>
                <h3 class="version-align-right">{{ habitableSurface }} m²</h3>
              </div>
            </div>

            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <div class="grid-x grid-margin-x">
                  <div class="cell auto version-row">
                    <p>Largeur façade</p>
                    <p class="version-align-right">{{ version.width }} m</p>
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto version-row">
                    <p>Nombre de pièces</p>
                    <p class="version-align-right">{{ surfaces && surfaces.length ? surfaces.length : 0 }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <div v-for="surface in surfaces" :key="surface.surfaceId" class="grid-x grid-margin-x">
                  <div class="cell auto version-row">
                    <p>{{ surface.room.name }}</p>
                    <p class="version-align-right">{{ surface.area }} m²</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- DÉTAILS VERSION -->
        <div class="cell medium-6">
          <div class="card version-details">
            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <h3>Détails version</h3>
              </div>
            </div>

            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-input
                  label="Gamme"
                  :value="version.range.name"
                  disabled
                />
              </div>
            </div>

            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-input
                  label="Modèle"
                  :value="version.model.name"
                  disabled
                />
              </div>
            </div>

            <div class="grid-x grid-margin-x row">
              <div class="cell auto">
                <app-input
                  label="Style"
                  :value="style.name"
                  disabled
                />
              </div>
            </div>

            <div class="grid-x grid-margin-x row">
              <div class="cell medium-8">
                <app-input
                  label="Type de toit"
                  :value="roofType[version.roofType]"
                  disabled
                />
              </div>

              <div class="cell medium-4">
                <app-input
                  label="Degré du toit"
                  :value="`${roofDegree[version.roofDegree]} °`"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body" v-else>
      <div class="card spinner-container">
        <app-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

import utils from '@/services/utils/utils';
import roofType from '@/services/enums/roofType.enum';
import roofDegree from '@/services/enums/roofDegree.enum';

import versionApi from '@/services/api/version';
import styleApi from '@/services/api/style';
import surfaceApi from '@/services/api/surface';
import memberApi from '@/services/api/member';

import upload from '@/assets/img/upload.svg?inline';

export default {
  name: 'version-summary',

  components: {
    TabNav,
    Breadcrumb,
    upload,
  },

  metaInfo() {
    return {
      title: this.version ? `${this.version.reference} – Version` : 'Version',
    };
  },

  data() {
    return {
      isLoading: false,
      version: null,
      style: null,
      utils,
      roofType,
      roofDegree,
      surfaces: null,
      me: null,
      isBrandAdmin: null,
    };
  },
  computed: {
    habitableSurface() {
      return this.surfaces && this.surfaces.length
        ? this.surfaces.reduce((a, s) => (s.room.type === 'HABITABLE' ? a + s.area : a), 0).toFixed(2) : 0;
    },
    tabs() {
      const tabs = [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: null },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: null },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: null },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: null },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: null },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: null },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: null },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: null },
          },
          label: 'Prix',
        },
      ];

      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { versionId: this.$route.params.versionId },
        },
        label: el.label,
      }));
    },
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },

  methods: {
    // Récupération des données
    async getData() {
      this.isLoading = true;
      await this.getMe();

      await this.getVersion();
      await this.getStyle();

      this.isLoading = false;
    },

    // Récupération de la version
    async getVersion() {
      try {
        if (this.$route.params.versionId) {
          this.version = await versionApi.getById(this.$route.params.versionId);
          this.surfaces = await surfaceApi.getByVersion(this.$route.params.versionId);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la version',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Récupération de la version
    async getStyle() {
      try {
        if (this.version.styleId) {
          this.style = await styleApi.getById(this.version.styleId);
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du style',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    // Demande de confirmation pour la suppression de la version
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer la version',
        text: 'Êtes vous sûr de vouloir supprimer cette version ?',
        confirmText: 'Supprimer la version',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteVersion();
        },
      });
    },

    // Suppression de la version
    async deleteVersion() {
      if (this.$route.params.versionId) {
        this.isLoading = true;

        try {
          await versionApi.delete(this.$route.params.versionId);

          this.$notification.show({ text: 'Cette version a été supprimée avec succès !' });
          this.$router.push({ name: 'versions' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression de la version',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }

        this.isLoading = false;
      }
    },

    // Duplication de la version
    async duplicateVersion() {
      if (this.$route.params.versionId) {
        this.isLoading = true;

        try {
          const newVersion = await versionApi.duplicate(this.$route.params.versionId);

          this.$notification.show({ text: 'Cette version a été dupliqué avec succès !' });
          this.$router.push({ name: 'versions-versionId-summary', params: { versionId: newVersion.versionId } });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la duplication de la version',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }

        this.isLoading = false;
      }
    },
    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.version-summary
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .breadcrumbs
    @include breadcrumbs
  .row
    @include row
  .duplicate
    margin-left: 1rem
  .spinner-container
    @include spinner-container
  .card
    @include card
    h3
      margin: 0 0 16px 0
  .media-container
    @include centered-container
    .illustration
      border-radius: 4px
    .not-found
      @include centered-container
      svg
        width: 70px
  .version-reference
    text-transform: uppercase
    color: $info
  .version-price
    margin-top: 24px
    color: $primary
  .version-price-description
    color: $info
  .version-description
    margin: 1rem 8rem 0 0
    color: $label

  .version-surfaces, .version-details
    h3
      color: $primary
  .version-surfaces-header
    margin-bottom: 2rem
  .version-align-right
    text-align: right

  .version-row
    display: flex
    justify-content: space-between
    padding-bottom: 12px
    margin-bottom: 12px
    border-bottom: 1px solid $line
</style>
