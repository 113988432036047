<template>
  <div class="projects-summary">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1 class="title">Synthèse</h1>
      </div>
    </div>

    <div class="content">
      <!-- Filtres -->
      <div class="filters-container">
        <span>Filtrer par :</span>
        <app-select v-if="user && user.isBrandAdmin" v-model="filters.agencyId" :options="agencies" size="small"></app-select>
        <app-select v-model="filters.companyId" :options="companies" />
        <span>Période :</span>
        <app-datepicker
          :value="filters.startDate"
          @input="handleFilterDateInput('startDate', $event)"
          :language="fr"
          monday-first/>
        <app-datepicker
        :value="filters.endDate"
          @input="handleFilterDateInput('endDate', $event)"
          :language="fr"
          monday-first/>
        <div class="cta-container">
          <app-button icon="print" @click="openPrintPage">Imprimer</app-button>
        </div>
      </div>

      <!-- Tableau -->
      <app-spinner v-if="isLoading" />
      <p v-else-if="!projects.length">
        Aucun chantier trouvé
      </p>
      <div class="table-container" v-else>
        <table class="summary-table">
          <thead>
            <tr>
              <th class="header-project-name">Chantiers</th>
              <th class="header-start">Ouverture chantier</th>
              <th v-for="step in projectsSummarySteps" :key="step.key" class="header-step">{{  step.label }}</th>
              <th class="header-estimated-close">Date fin estimée</th>
              <th class="header-close">Date livraison initiale</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="project in projects" :key="project.projectId">
              <th>
                <div class="project-name">{{ getProjectName(project) }}</div>
                <div class="project-address">{{ project.address.city }}</div>
              </th>
              <td>{{ formatDate(project.startDate) }}</td>
              <template v-for="step in projectsSummarySteps">
                <td
                  v-if="isStepDisplayed(project.projectSteps[step.key])"
                  :key="step.key"
                  :class="{
                  'in-progress': project.projectSteps[step.key].status === 'IN_PROGRESS',
                  'done': project.projectSteps[step.key].status === 'DONE',
                }">
                  <div class="step-week">{{ formatWeek(project.projectSteps[step.key].startedAt) }}</div>
                  <div class="step-company">{{ project.projectSteps[step.key].company ?  project.projectSteps[step.key].company.name : '' }}</div>
                </td>
                <td v-else :key="step.key" :class="{ empty: step.isEmpty }"/>
              </template>
              <td>{{ formatWeek(project.estimatedDloseDate) }}</td>
              <td>{{ formatWeek(project.closeDate) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import { fr } from 'vuejs-datepicker/dist/locale';
import projectApi from '@/services/api/project';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';
import ProjectsSummarySteps from '@/services/data/projectsSummarySteps.json';

export default {
  data() {
    return {
      projects: [],
      agencies: [],
      filters: {
        startDate: this.$route.query.startDate || undefined,
        endDate: this.$route.query.endDate || undefined,
        companyId: this.$route.query.companyId || undefined,
        agencyId: this.$route.query.agencyId || undefined,
      },
      user: null,
      isLoading: false,
      fr,
    };
  },
  computed: {
    companies() {
      const companies = new Map();
      this.projects.forEach(({ projectSteps }) => {
        Object.values(projectSteps).forEach(({ company }) => {
          if (company) {
            companies.set(company.name, { name: company.companyId, label: company.name });
          }
        });
      });
      return [{ name: undefined, label: 'Tous les partenaires' }].concat(Array.from(companies.values()));
    },
    projectsSummarySteps() {
      return ProjectsSummarySteps.map((step) => ({
        ...step,
        isEmpty: this.projects.every((project) => !project.projectSteps[step.key]),
      }));
    },
  },
  async mounted() {
    // Récupération de l'agence
    await this.getMe();
    if (this.user.isAgencyAdmin) {
      this.filters.agencyId = this.user.rules[0].agencyId;
    } else {
      await this.getAgencies();
      if (!this.filters.agencyId) this.filters.agencyId = this.agencies[0].name;
    }

    // Récupération des projets
    await this.getProjectsSummary();
  },
  watch: {
    'filters.agencyId': 'handleFilterChange',
    'filters.startDate': 'handleFilterChange',
    'filters.endDate': 'handleFilterChange',
    'filters.companyId': {
      handler() {
        this.$router.push({ query: { ...this.$route.query, ...this.filters } });
      },
    },
  },
  methods: {
    handleFilterDateInput(filterName, date) {
      this.filters[filterName] = date ? this.$dayjs(date).toISOString() : undefined;
    },
    handleFilterChange() {
      this.$router.push({ query: { ...this.$route.query, ...this.filters } });
      this.filters.companyId = undefined;
      this.getProjectsSummary();
    },
    // Pas d'accès à this dans les filters malheuresement
    formatDate(date) {
      return this.$dayjs(date).format('DD/MM/YY');
    },
    formatWeek(date) {
      return `S${this.$dayjs(date).isoWeek()}`;
    },
    isStepDisplayed(projectStep) {
      if (!projectStep) return false;
      if (this.filters.companyId) {
        return projectStep.company && this.filters.companyId === projectStep.company.companyId;
      }
      return true;
    },
    openPrintPage() {
      window.open(this.$router.resolve({ name: 'projects-summary-pdf', query: this.$route.query }).href, '_blank');
    },
    async getMe() {
      try {
        this.user = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue en récupérant votre compte. Veuillez réessayer plus tard',
          hasCancel: true,
        });
      }
    },
    async getProjectsSummary() {
      this.isLoading = true;
      try {
        const projects = await projectApi.getProjectsSummary(this.filters.agencyId, this.filters.startDate, this.filters.endDate);
        this.projects = projects.map((project) => {
          const projectSteps = {};
          project.projectSteps.forEach((projectStep) => {
            projectSteps[projectStep.stepKey] = projectStep;
          });
          return {
            ...project,
            projectSteps,
          };
        });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue en récupérant les projets. Veuillez réessayer plus tard',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll();
        this.agencies = agencies.map((agency) => ({ name: agency.agencyId, label: agency.name }));
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue en récupérant les agences. Veuillez réessayer plus tard',
          hasCancel: true,
        });
      }
    },
    getProjectStep(project, step) {
      const projectStep = project.projectSteps.find(({ key }) => key === step.key);
      return projectStep || { company: null, startedAt: null };
    },
    getProjectName: projectApi.getProjectName,
  },
};
</script>

<style lang="sass" scoped>
.projects-summary
  .header
    @include header
  .content
    padding: 1rem

  .filters-container
    display: flex
    gap: 12px
    align-items: center
    flex-grow: 1
    margin: 1rem 0

    .app-select
      max-width: 200px

    span
      font-weight: bold

    span:not(:first-child)
      flex-grow: 2
      text-align: right
    .cta-container
      display: flex
      // flex-grow: 1
      justify-content: flex-end

  .table-container
    width: 100%
    max-height: calc(100vh - 200px)
    overflow-x: scroll
    border: 1px solid $line
    border-bottom-width: 0

    .summary-table
      table-layout: fixed
      border-spacing: 0
      th
        text-transform: uppercase
        background: $background
        font-size: 10px
        line-height: 14px
        font-weight: 700
        padding: 5px 16px
        &.header-project-name
          min-width: 200px
        &.header-open
          min-width: 100px
        &.header-step
          min-width: 100px
        &.header-close, &.header-estimated-close
          min-width: 78px
      thead
        th
          position: sticky
          top: 0
          color: $body
          border: 1px solid $line
          border-top-width: 0
          border-left-width: 0
          &:last-child
            border-right-width: 0
          &:first-child
            left: 0
            z-index: 2
      tbody
        th
          position: sticky
          left: 0
          border: 1px solid $line
          border-left-width: 0
          text-align: left
          border-top-width: 0
          .project-name
            color: $primary
            font-size: 12px
            text-decoration: underline
          .project-address
            font-weight: 500
            color: $label
        td
          border: 1px solid $line
          border-top-width: 0
          border-left-width: 0
          font-size: 12px
          font-weight: 400
          text-align: center
          padding: 5px 8px
          background: $white
          &:last-child
            border-right-width: 0
          .step-week
            text-align: right
            font-size: 10px
            font-weight: 700
            color: $primary
          .step-company
            text-align: left
            font-size: 10px
            font-weight: 500
            text-transform: uppercase
            color: $body

          &.in-progress
            background: $primary
            .step-company, .step-week
              color: $white

          &.done, &.empty
            background: $loading
            .step-company, .step-week
              color: $label

</style>
