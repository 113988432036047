<template>
  <div class="version-extensions hedifys-21">
    <form ref="version-extensions" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            :items="[
              { route: { name: 'versions'}, name: 'Versions'},
              { route: { name: 'versions-versionId', params: { agencyId: $route.params.agencyId }}, name: version ? version.reference : '' }
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>{{ version ? version.reference : '' }}</h1>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="version">
        <div class="row">
          <h2>Gestion des agrandissements</h2>
          <p>
            Gérez les agrandissements ci-dessous, vous pouvez ajouter et placer jusqu’à 2 lignes d’agrandissements.
            <br>
            Sélectionnez les pièces traversées par la ligne d’agrandissement et leur longueur (en m).
          </p>
        </div>
        <div class="grid-x" :class="{ 'grid-margin-x': blueprints && blueprints.length }">
          <div class="cell shrink positions">
            <div class="card grid-y blueprints" ref="draggableContainer" v-if="blueprints && blueprints.length">
              <img v-for="blueprint in blueprints" :src="blueprint.url" :key="blueprint.versionMediaId"  />
            </div>
            <div
              class="line-container"
              ref="firstExtension"
              v-if="version.isFirstExtensionActive && blueprints && blueprints.length"
              :style="{
                left: `${version.firstExtensionBlueprintPosition}px`
              }">
              <div class="line"></div>
              <div class="slider-container grid-x">
                <left-arrow class="arrow" />
                <div
                  @mousedown.prevent="(e) => dragMouseDown(e, 'firstExtension')"
                  class="slider">1</div>
                <right-arrow class="arrow" />
              </div>
            </div>
            <div
              class="line-container"
              ref="secondExtension"
              v-if="version.isSecondExtensionActive"
              :style="{
                left: `${version.secondExtensionBlueprintPosition}px`
              }">
              <div class="line"></div>
              <div class="slider-container grid-x">
                <left-arrow class="arrow" />
                <div
                  @mousedown.prevent="(e) => dragMouseDown(e, 'secondExtension')"
                  class="slider">2</div>
                <right-arrow class="arrow" />
              </div>
            </div>
          </div>
          <div class="cell auto">
            <div class="card">
              <h3>Surfaces totales</h3>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto">
                  <app-input v-model.number="version.width" label="Largeur de façade" placeholder="Largeur de façade..." unit="m" type="number" :step="0.01" />
                </div>
                <div class="cell auto">
                  <app-input v-model.number="version.glazedSurface" label="Surface vitrée" placeholder="Surface vitrée..." unit="m" type="number" :step="0.01" />
                </div>
                <div class="cell auto">
                  <app-input v-model.number="version.depth" label="Profondeur" placeholder="Profondeur..." unit="m" type="number" :step="0.01" />
                </div>
              </div>
            </div>
            <div v-for="(extension, index) in extensions" :key="index">
              <div class="card" v-if="version[extension.isActiveKey]">
                <div class="grid-x grid-margin-x row floor-header">
                  <h3 class="cell shrink">{{ extension.title }}</h3>
                  <div class="cell shrink align-self-middle">
                    <app-button @click="deleteExtension(extension)" theme='warning' icon='remove' size='round-small'/>
                  </div>
                  <div class="cell auto"/>
                  <div class="cell shrink">
                    <app-button theme="primary" icon="add" @click="openRoomsModal(extension)">Ajouter des pièces</app-button>
                  </div>
                </div>
                <div
                  class="grid-x grid-margin-x row"
                  v-if="
                  extensionsSurfaces
                  && extensionsSurfaces.filter((vs) => vs[extension.valueKey] !== null)
                  && extensionsSurfaces.filter((vs) => vs[extension.valueKey] !== null).length"
                >
                  <div class="cell auto">
                    <app-table
                      :headers="tableHeader"
                      :data="extensionsSurfaces.filter((vs) => vs[extension.valueKey] !== null)" small>
                      <template slot="loading">
                        <app-spinner />
                      </template>
                      <template slot="icon" slot-scope="{ data }">
                        <img :src="data.room.icon" alt="icon" class="room-icon"/>
                      </template>
                      <template slot="name" slot-scope="{ data }">
                        <strong>{{ data.room.name }}</strong>
                      </template>
                      <template slot="type" slot-scope="{ data }">
                        <p>{{ roomTypes.find(t => t.name === data.room.type).label }}</p>
                      </template>
                      <template slot="extensionDepth" slot-scope="{ data }">
                        <app-input
                          v-model.number="data[extension.valueKey]"
                          placeholder="Largeur"
                          unit="m"
                          type="number"
                          :step="0.01"
                          :min="0"
                        />
                      </template>
                      <template slot="delete" slot-scope="{ data }">
                        <app-button @click="removeSurface(data, extension.valueKey)" theme='warning' icon='remove' size='round-small'/>
                      </template>
                      <template slot="empty-table">
                        <p>Aucune donnée de disponible</p>
                      </template>
                    </app-table>
                  </div>
                </div>
                <div class="grid-x grid-margin-x row">
                  <div class="cell shrink switch">
                    <app-switch v-model="extension.isLimitActive">
                      <h4>Largeur d'agrandissement limitée</h4>
                    </app-switch>
                  </div>
                  <div class="cell auto"></div>
                  <div class="cell shrink" v-if="extension.isLimitActive">
                    <app-input v-model.number="version[extension.limitKey]" placeholder="Largeur..." unit="m" type="number" :step="0.01" :min="0" />
                  </div>
                </div>
              </div>
            </div>
            <app-button v-if="!version.isFirstExtensionActive || !version.isSecondExtensionActive" class="add-extension" icon="add" theme="primary" size="large" @click="addExtension">
              Ajouter une ligne d'agrandissement
            </app-button>
          </div>
        </div>
      </div>
    </form>
    <app-modal class="rooms-modal" :show="isRoomsModalOpen" size="large" title="Ajouter des pièces" @update:show="isRoomsModalOpen = false">
      <form ref="rooms" @submit.prevent="addRooms">
        <div class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-search v-model="search" />
          </div>
        </div>
        <div class="grid-x grid-margin-x row" v-if="modalSurfaces && modalSurfaces.length">
          <div class="cell auto">
            <app-table :headers="tableHeaderModale" :data="modalSurfaces" small>
              <template slot="loading">
                <app-spinner />
              </template>
              <template slot="checkbox" slot-scope="{ data }">
                <app-checkbox :value="data.surfaceId" v-model="selectedSurfaces" ></app-checkbox>
              </template>
              <template slot="icon" slot-scope="{ data }">
                <img :src="data.room.icon" alt="icon" class="room-icon"/>
              </template>
              <template slot="name" slot-scope="{ data }">
                <strong>{{ data.room.name }}</strong>
              </template>
              <template slot="type" slot-scope="{ data }">
                <p>{{ roomTypes.find(t => t.name === data.room.type).label }}</p>
              </template>
            </app-table>
          </div>
        </div>
        <div v-else class="grid-x grid-margin-x row">
          <div class="cell auto">
            <p>Aucune pièce n'est disponible</p>
          </div>
        </div>
        <div class="grid-x grid-margin-x" v-if="modalSurfaces && modalSurfaces.length">
          <div class="cell auto"/>
          <div class='cell shrink'>
            <app-button theme="primary" size="large" icon="edit" type="submit">Ajouter les pièces</app-button>
          </div>
          <div class="cell auto"/>
        </div>
      </form>
    </app-modal>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import roomTypes from '@/services/data/roomTypes.json';
import utils from '@/services/utils/utils';
import leftArrow from '@/assets/img/grab-arrow-left.svg?inline';
import rightArrow from '@/assets/img/grab-arrow-right.svg?inline';

import versionApi from '@/services/api/version';
import roomApi from '@/services/api/room';
import surfaceApi from '@/services/api/surface';
import versionMediaApi from '@/services/api/versionMedia';

export default {
  name: 'version-extensions',
  components: {
    TabNav,
    Breadcrumb,
    leftArrow,
    rightArrow,
  },
  metaInfo() {
    return {
      title: this.version ? `${this.version.reference} – Version` : 'Version',
    };
  },
  data() {
    return {
      roomTypes,
      tabs: [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Prix',
        },
      ],
      isLoading: null,
      tableHeader: [
        { label: 'Pièce', key: 'icon', size: 1 },
        { label: '', key: 'name', size: 3 },
        { label: 'Type', key: 'type', size: 3 },
        { label: 'Profondeur', key: 'extensionDepth', size: 'auto' },
        { label: '', key: 'delete', size: 'shrink' },
      ],

      tableHeaderModale: [
        { label: '', key: 'checkbox', size: 1 },
        { label: 'Pièce', key: 'icon', size: 1 },
        { label: '', key: 'name', size: 5 },
        { label: 'Type', key: 'type', size: 'auto' },
      ],

      version: null,
      rooms: null,

      versionSurfaces: null,
      extensionsSurfaces: null,

      selectedSurfaces: null,
      modalSurfaces: null,
      modalExtension: null,
      search: null,

      isRoomsModalOpen: false,

      selectedExtension: null,
      isDragging: null,
      clientX: null,
      movementX: 0,

      extensions: [{
        title: 'Ligne 1 - Pièces traversées',
        valueKey: 'firstExtensionDepth',
        isActiveKey: 'isFirstExtensionActive',
        limitKey: 'firstExtensionMaxWidth',
        isLimitActive: null,
      }, {
        title: 'Ligne 2 - Pièces traversées',
        valueKey: 'secondExtensionDepth',
        isActiveKey: 'isSecondExtensionActive',
        limitKey: 'secondExtensionMaxWidth',
        isLimitActive: null,
      }],
      blueprints: null,
    };
  },
  mounted() {
    this.getData();
  },
  created() {
    this.debouncedUpdateRoomsList = utils.debounce(this.updateRoomsList, 500);
  },
  watch: {
    search() {
      this.debouncedUpdateRoomsList();
    },
  },
  methods: {
    updateRoomsList() {
      const fullSurfacesList = this.versionSurfaces.filter((vs) => !this.extensionsSurfaces.find((e) => e.surfaceId === vs.surfaceId));
      this.modalSurfaces = fullSurfacesList.filter((s) => s.room.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    dragMouseDown(event, extensionName) {
      this.clientX = event.clientX;
      this.isDragging = true;
      this.selectedExtension = extensionName;

      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event) {
      if (this.isDragging) {
        this.movementX = this.clientX - event.clientX;
        this.clientX = event.clientX;

        const newValue = this.$refs[this.selectedExtension].offsetLeft - this.movementX;
        const maxValue = this.$refs.draggableContainer.clientWidth - this.$refs[this.selectedExtension].clientWidth;

        if (newValue > 0 && newValue < maxValue) {
          if (this.selectedExtension === 'firstExtension') {
            this.version.firstExtensionBlueprintPosition = newValue;
          } else {
            this.version.secondExtensionBlueprintPosition = newValue;
          }

          this.$refs[this.selectedExtension].style.left = `${newValue}px`;
        }
      }
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.isDragging = false;
      this.selectedExtension = null;
    },
    async getData() {
      this.isLoading = true;
      try {
        if (this.$route.params.versionId) {
          this.version = await versionApi.getById(this.$route.params.versionId);
          this.rooms = await roomApi.getAll();
          this.versionSurfaces = await surfaceApi.getByVersion(this.$route.params.versionId);
          this.versionSurfaces = this.versionSurfaces.filter((vs) => vs.area > 0);
          this.extensionsSurfaces = this.versionSurfaces.filter((vs) => vs.firstExtensionDepth || vs.firstExtensionDepth === 0 || vs.secondExtensionDepth || vs.secondExtensionDepth === 0);

          this.extensions[0].isLimitActive = this.version.firstExtensionMaxWidth !== null;
          this.extensions[1].isLimitActive = this.version.secondExtensionMaxWidth !== null;

          const medias = await versionMediaApi.getAll(this.$route.params.versionId);
          this.blueprints = medias && medias.length ? medias.filter((m) => m.type === 'BLUEPRINT') : null;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des données',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    removeSurface(surface, valueKey) {
      const surfaceIndex = this.extensionsSurfaces.findIndex((s) => s.room.roomId === surface.room.roomId);
      this.extensionsSurfaces[surfaceIndex][valueKey] = null;
    },
    openRoomsModal(extension) {
      this.modalSurfaces = this.versionSurfaces.filter((vs) => {
        const noFound = !this.extensionsSurfaces.find((e) => e.surfaceId === vs.surfaceId);
        const emptyValues = this.extensionsSurfaces.find((e) => e.surfaceId === vs.surfaceId && e[extension.valueKey] === null);
        return noFound || emptyValues;
      });
      this.selectedSurfaces = null;
      this.modalExtension = extension;

      this.isRoomsModalOpen = true;
    },
    deleteExtension(extension) {
      this.version[extension.isActiveKey] = false;
    },
    addRooms() {
      if (this.selectedSurfaces && this.selectedSurfaces.length) {
        // On modifie les surfaces si elles existent déjà, sinon on les ajoute
        const editedSurfaces = this.selectedSurfaces.map((surfaceId) => {
          const surface = this.versionSurfaces.find((s) => s.surfaceId === surfaceId);
          const editedSurface = this.extensionsSurfaces.find((s) => s.surfaceId === surfaceId);
          const newSurface = !editedSurface ? surface : editedSurface;
          newSurface[this.modalExtension.valueKey] = 0;

          return newSurface;
        });

        // on garde ce qui n'a pas été modifié
        const oldSurface = this.extensionsSurfaces.filter((e) => !editedSurfaces.find((s) => s.surfaceId === e.surfaceId));

        this.extensionsSurfaces = oldSurface.concat(editedSurfaces);

        this.modalSurfaces = null;
        this.modalExtension = null;
        this.isRoomsModalOpen = false;
      }
    },
    closeRoomsModal() {
      this.isRoomsModalOpen = false;
    },
    addExtension() {
      if (!this.version.isFirstExtensionActive) {
        this.version.isFirstExtensionActive = true;
      } else {
        this.version.isSecondExtensionActive = true;
      }
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.$route.params.versionId) {
          this.version.firstExtensionMaxWidth = this.extensions[0].isLimitActive ? this.version.firstExtensionMaxWidth : null;
          this.version.secondExtensionMaxWidth = this.extensions[1].isLimitActive ? this.version.secondExtensionMaxWidth : null;

          // ajouter la mise à jour de la version
          const parsedSurfaces = this.extensionsSurfaces.map((s) => ({
            surfaceId: s.surfaceId,
            firstExtensionDepth: s.firstExtensionDepth,
            secondExtensionDepth: s.secondExtensionDepth,
          }));

          await surfaceApi.updateExtensionsByVersion(this.$route.params.versionId, { extensions: parsedSurfaces });

          const parsedVersion = this.version;

          const { modelId } = this.version.model;
          delete parsedVersion.surface;
          delete parsedVersion.price;
          delete parsedVersion.model;
          delete parsedVersion.range;
          delete parsedVersion.versionMedia;
          delete parsedVersion.versionMediaMain;
          delete parsedVersion.createdAt;
          delete parsedVersion.updatedAt;
          delete parsedVersion.deletedAt;
          delete parsedVersion.brandId;
          delete parsedVersion.numberOfRooms;
          delete parsedVersion.habitableSurface;
          delete parsedVersion.surfaces;

          await versionApi.update(this.$route.params.versionId, {
            ...parsedVersion,
            modelId,
          });

          this.versionSurfaces = await surfaceApi.getByVersion(this.$route.params.versionId);
          this.versionSurfaces = this.versionSurfaces.filter((vs) => vs.area > 0);
          this.version = await versionApi.getById(this.$route.params.versionId);
          this.extensionsSurfaces = this.versionSurfaces.filter((vs) => vs.firstExtensionDepth || vs.firstExtensionDepth === 0 || vs.secondExtensionDepth || vs.secondExtensionDepth === 0);

          this.$notification.show({ text: 'Les extensions ont bien été mises à jour !' });
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde de la version',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang='sass'>
.version-extensions
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .card
    @include card
  h2
    margin-bottom: 10px !important
  .save
    margin-left: 16px
  .app-table .data.auto
    height: initial
    flex: initial
  .floor-header
    h3
      margin-left: 1rem
      margin-right: 1rem
      margin-top: auto
      margin-bottom: auto
  .rooms-modal .app-search.large
    max-width: none
  .add-extension
    width: 100%
  .switch
    @include centered-container
    height: 40px
    h4
      color: $label
  .blueprints
    padding: 0
    img
      width: 360px
  .slider
    color: white
    background-color: $primary
    padding: 5px 12px
    border-radius: 20px
    cursor: grab
  .positions
    position: relative
    height: fit-content
  .line-container
    position: absolute
    bottom: 0
    top: 0
    .line
      width: 3px
      background-color: $primary
      height: 100%
      margin: auto
    .arrow
      height: 20px
      margin: 0 3px
    .slider-container
      @include centered-container
</style>
