<template>
  <div class="packs hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Packs</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="goToPack">Ajouter un pack</app-button>
      </div>
    </div>
    <!-- BODY / LISTE DES PACKS -->
    <div class="body">
      <p class="description">Le pack est une liste d'options et bases, pour chaque option il indique si l'option est incluse et sélectionnée ou non par défaut dans le configurateur.</p>
      <app-table :headers="tableHeaders" :data="packs.data" :loading="isLoading" @line-cliked="goToPack" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="range" slot-scope="{ data }">
          <p>{{ formatRanges(data.ranges) }}</p>
        </template>
      </app-table>
    </div>
  </div>
</template>

<script>
import pack from '../../services/api/pack';

export default {
  name: 'packs',
  metaInfo: {
    title: 'Packs',
  },
  data() {
    return {
      packs: [],
      pack: {},
      tableHeaders: [
        { label: 'Nom du pack', key: 'name', size: 3 },
        { label: 'Gamme', key: 'range', size: 3 },
      ],
      isLoading: true,
      isCreateModalOpen: null,
    };
  },
  mounted() {
    this.getPacks();
  },
  methods: {
    async getPacks() {
      this.isLoading = true;
      try {
        this.packs = await pack.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des packs',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    goToPack(selectedPack) {
      if (selectedPack && selectedPack.packId) {
        this.$router.push({ name: 'categories-packs-packId', params: { packId: selectedPack.packId } });
      } else {
        this.$router.push({ name: 'categories-packs-new' });
      }
    },
    formatRanges(ranges) {
      return ranges.map((el) => el.name).join(', ');
    },
  },
};
</script>

<style lang='sass' scoped>
.packs
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .description
    @include description
</style>
