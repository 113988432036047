<template>
  <section class="app-accordion" :class="{'separator': !showContent}">
    <!-- HEADER -->
    <header @click="openAccordion" class="header">
      <div class="grid-x grid-margin-x">
        <div class="cell auto">
          <slot name="header"/>
        </div>
        <div class="cell shrink">
          <div class="arrow" :class="{'arrow-down': !showContent}">
            <span/>
          </div>
        </div>
      </div>
    </header>

    <!-- CONTENT -->
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <section v-if="showContent" class="content">
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <slot name="content"/>
          </div>
        </div>
      </section>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'app-accordion',

  props: {
    isDefaultOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showContent: false,
    };
  },

  mounted() {
    this.showContent = this.isDefaultOpen || false;
  },

  methods: {
    openAccordion() {
      this.showContent = !this.showContent;
    },

    beforeEnter(el) {
      const element = el;
      element.style.height = '0';
    },

    enter(el) {
      const element = el;
      element.style.height = `${element.scrollHeight}px`;
    },

    beforeLeave(el) {
      const element = el;
      element.style.height = `${element.scrollHeight}px`;
    },

    leave(el) {
      const element = el;
      element.style.height = '0';
    },
  },
};
</script>

<style lang="sass" scoped>
.app-accordion
  &.separator
    border-bottom: 1px solid $info

  .header
    background: $line
    padding: 20px 25px
    cursor: pointer

    img
      filter: brightness(75%)

    .arrow
      border: 0
      background-color: transparent
      display: flex
      align-items: center
      height: 100%

      span
        display: inline-block
        height: 0
        width: 0
        border-right: 7px solid transparent
        border-bottom: 7px solid $secondary-light
        border-left: 7px solid transparent
        transition: .3s

    .arrow-down
      span
        transform: rotate(180deg)
        transition: .3s

  .content
    background-color: $off-white
    border-left: 1px solid $line
    border-right: 1px solid $line
    transition: 300ms ease-in-out
    overflow: hidden
</style>
