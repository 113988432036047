import { render, staticRenderFns } from "./VersionPrices.vue?vue&type=template&id=14c22860&scoped=true&"
import script from "./VersionPrices.vue?vue&type=script&lang=js&"
export * from "./VersionPrices.vue?vue&type=script&lang=js&"
import style0 from "./VersionPrices.vue?vue&type=style&index=0&id=14c22860&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14c22860",
  null
  
)

export default component.exports