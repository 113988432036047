var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"diffusion-quotas hedifys-21"},[_c('form',{ref:"diffusion-quotas",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('div',{staticClass:"header grid-x"},[_vm._m(0),_c('div',{staticClass:"cell shrink save"},[_c('app-button',{attrs:{"theme":"primary","size":"large","type":"submit"}},[_vm._v("Sauvegarder")])],1)]),_c('tab-nav',{attrs:{"tabs":_vm.tabs}}),(!_vm.isLoading)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"grid-x title"},[_vm._m(1),_c('div',{staticClass:"cell shrink"},[_c('h3',{staticClass:"quota-ubiflow"},[_vm._v("Total quotas Ubiflow : "+_vm._s(_vm.totalUbiflowQuotas))])])]),_vm._m(2),_c('div',{staticClass:"table grid-x"},[_c('div',{staticClass:"cell small-5"},[_c('app-table',{staticClass:"agencies",attrs:{"headers":_vm.tableHeaders,"data":_vm.tableData,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"agency",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(_vm._s(data.agency))])]}},{key:"city",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.city))])]}},{key:"landVersionManual",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"0","size":"compact"},model:{value:(data.landVersionManual.value),callback:function ($$v) {_vm.$set(data.landVersionManual, "value", _vm._n($$v))},expression:"data.landVersionManual.value"}})]}},{key:"ubiflow",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"0","size":"compact"},model:{value:(data.ubiflow.value),callback:function ($$v) {_vm.$set(data.ubiflow, "value", _vm._n($$v))},expression:"data.ubiflow.value"}})]}},{key:"totalDiffusion",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.computeTotalDiffusion(data.agencyId)))])]}}],null,false,3677480894)},[_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2)],1),_c('div',{staticClass:"cell small-7"},[_c('app-table',{staticClass:"diffusers",attrs:{"headers":_vm.diffuserTableHeaders,"data":_vm.tableData,"loading":_vm.isLoading,"quotas":""},scopedSlots:_vm._u([{key:"leboncoinLand",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"0","size":"compact"},model:{value:(data.leboncoinLand.value),callback:function ($$v) {_vm.$set(data.leboncoinLand, "value", _vm._n($$v))},expression:"data.leboncoinLand.value"}})]}},{key:"leboncoinLandVersion",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"0","size":"compact"},model:{value:(data.leboncoinLandVersion.value),callback:function ($$v) {_vm.$set(data.leboncoinLandVersion, "value", _vm._n($$v))},expression:"data.leboncoinLandVersion.value"}})]}},{key:"totalLeboncoin",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s((data.leboncoinLandVersion.value + data.leboncoinLand.value) || 0))])]}},{key:"seLogerLand",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"0","size":"compact"},model:{value:(data.seLogerLand.value),callback:function ($$v) {_vm.$set(data.seLogerLand, "value", _vm._n($$v))},expression:"data.seLogerLand.value"}})]}},{key:"seLogerLandVersion",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"0","size":"compact"},model:{value:(data.seLogerLandVersion.value),callback:function ($$v) {_vm.$set(data.seLogerLandVersion, "value", _vm._n($$v))},expression:"data.seLogerLandVersion.value"}})]}},{key:"totalSeLoger",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s((data.seLogerLand.value + data.seLogerLandVersion.value) || 0))])]}},{key:"lesTerrainsLand",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"0","size":"compact"},model:{value:(data.lesTerrainsLand.value),callback:function ($$v) {_vm.$set(data.lesTerrainsLand, "value", _vm._n($$v))},expression:"data.lesTerrainsLand.value"}})]}},{key:"lesTerrainsLandVersion",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","placeholder":"0","size":"compact"},model:{value:(data.lesTerrainsLandVersion.value),callback:function ($$v) {_vm.$set(data.lesTerrainsLandVersion, "value", _vm._n($$v))},expression:"data.lesTerrainsLandVersion.value"}})]}},{key:"totalLesTerrains",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s((data.lesTerrainsLand.value + data.lesTerrainsLandVersion.value) || 0))])]}}],null,false,4248572021)},[_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune données de disponible")])])],2)],1)])]):_c('div',{staticClass:"body"},[_c('div',{staticClass:"card spinner-container"},[_c('app-spinner')],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Configuration diffusion")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell auto"},[_c('h3',[_vm._v("Quota des annonces par agence")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell small-5"}),_c('div',{staticClass:"diffusers-header small-7"},[_c('div',{staticClass:"diffusers-title cell auto"},[_c('h6',[_vm._v("Leboncoin")])]),_c('div',{staticClass:"diffusers-title cell auto"},[_c('h6',[_vm._v("Se loger")])]),_c('div',{staticClass:"diffusers-title cell auto"},[_c('h6',[_vm._v("Les Terrains.com")])])])])}]

export { render, staticRenderFns }