<template>
  <div class="task-pdf container uk-flex uk-flex-middle">
    <div class="task-pdf uk-flex uk-flex-middle" v-if="index === 0">
      <!-- HEADER -->
      <div>
        <h6 class="column-header">Taches / Entreprises</h6>
      </div>

      <vk-grid class="uk-width-expand uk-flex-right table-header" gutter="collapse" matched>
        <div class="uk-text-center uk-flex uk-flex-middle">
          <!--  DATE DE DEBUT DE LA TACHE-->
          <div class="uk-width-auto icon">
            <h6 class="column-header">Début</h6>
          </div>

          <div class="uk-width-auto date-col"></div>

          <!-- JOURS PASSES SUR LA TACHE -->
          <div class="uk-width-auto icon">
            <h6 class="column-header">Durée</h6>
          </div>

          <!--  DATE DE FIN DE LA TACHE-->
          <div class="uk-width-auto icon">
            <h6 class="column-header">Fin</h6>
          </div>
          <div class="uk-width-auto date-col">
            <p></p>
          </div>
        </div>
      </vk-grid>
    </div>
    <div class="task-pdf uk-flex uk-flex-middle">
      <!-- DEFINITION DE LA TACHE AVEC LE NOM DU SOUS-TRAITANT -->
      <div class="task-definition">
        <h4 class="task-name">{{ projectStep.label }}</h4>
        <div v-if="type === 'project'">
          <h4 v-if="projectStep.company && projectStep.company.companyId"
            :class="{ unconfirmed: !projectStep.confirmed, confirmed: projectStep.confirmed }">
            {{ `${projectStep.company.name}` }} <br>
            {{ filterShowToConfirm() ? ': Demande d\'intervention' : projectStep.confirmed ? `: Demande du ${$dayjs(projectStep.confirmedAt).format('DD/MM/YYYY')}` : null }}
          </h4>
          <h4 v-else-if="projectStep.companyCategory && projectStep.companyCategory.companyCategoryId">{{ projectStep.companyCategory.name }} : Aucun partenaire</h4>
          <h4 class="h-color-gray-regular event" v-else-if="projectStep.type === 'EVENT'">>>> ÉVÈNEMENT</h4>
          <h4 class="h-color-gray-regular event" v-else-if="projectStep.type === 'START'">>>> DÉMARRAGE</h4>
          <h4 class="h-color-gray-regular event" v-else-if="projectStep.type === 'CLOSE'">>>> FIN</h4>
          <h4 class="h-color-gray-regular" v-else>---</h4>
        </div>
        <div v-if="type === 'company'">
          <h4 v-if="projectStep.company && projectStep.company.companyId"
            :class="{ unconfirmed: !projectStep.confirmed, confirmed: projectStep.confirmed }">
            {{ filterShowToConfirm() ? 'Demande d\'intervention' : projectStep.confirmed ? `Demande du ${$dayjs(projectStep.confirmedAt).format('DD/MM/YYYY')}` : null }}
          </h4>
        </div>
      </div>

      <!-- DETAIL DE LA TACHE / PLANNING -->
      <vk-grid class="task-detail uk-width-expand uk-flex-right" gutter="collapse" matched>
        <div class="uk-text-center uk-flex uk-flex-middle">
          <!-- ETAT DE LA TACHE -->
          <div class="uk-width-auto task-state-column">
            <div class="uk-width-auto task-state-container"
              v-if="labels[projectStep.startStatus]">
              <div class="task-state" :class="[stateColor(projectStep.startStatus, 'start')]">
                <span> {{ labels[projectStep.startStatus] }}</span>
              </div>
            </div>
            <div class="uk-width-auto task-state-container"
              v-if="labels[projectStep.closeStatus]">
              <div class="task-state" :class="[stateColor(projectStep.closeStatus, 'close')]">
                <span> {{ labels[projectStep.closeStatus] }}</span>
              </div>
            </div>
            <div class="uk-width-auto task-state-container"
              v-if="filterShowToConfirm()">
              <div class="task-state bg-to-confirm">
                <span> {{ labels.TO_CONFIRM }}</span>
              </div>
            </div>
          </div>

          <!--  DATE DE DEBUT DE LA TACHE-->
          <div class="uk-width-auto icon">
            <h4 class="uk-margin-remove">
              S{{ $dayjs(projectStep.startedAt).isoWeek() }}
            </h4>
          </div>

          <!--  DATE DE DEBUT DE LA TACHE-->
          <div class="uk-width-auto date-col">
              <p class="uk-margin-remove date h-color-gray-dark">
                {{ $dayjs(projectStep.startedAt).format('D MMMM YYYY') }}
              </p>
          </div>

          <!-- JOURS PASSES SUR LA TACHE -->
          <!-- TODO API calcul diff jour ajd - startDate -->
          <div class="uk-width-auto icon">
            <p class="uk-margin-remove">{{ getStepDuration() }}J</p>
          </div>

          <!--  DATE DE FIN DE LA TACHE-->
          <div class="uk-width-auto icon">
              <h4 class="uk-margin-remove h-color-gray-dark">
               S{{ $dayjs(projectStep.closedAt).isoWeek() }}
              </h4>
          </div>
          <div class="uk-width-auto date-col">
              <p class="uk-margin-remove date">
                {{ $dayjs(projectStep.closedAt).format('D MMMM YYYY') }}
              </p>
          </div>
        </div>
      </vk-grid>
    </div>
    <div class="task-pdf uk-flex uk-flex-middle comment" v-if="projectStep.isPublicComment && projectStep.comment">
      <p class="uk-margin-remove" v-html="projectStep.comment.replace(/\r?\n/g, '<br>')"></p>
    </div>
  </div>
</template>

<script>
import { fr } from 'vuejs-datepicker/dist/locale';
import types from '@/services/data/types.json';

export default {
  name: 'schedule-pdf-project-step',
  props: ['projectStep', 'filter', 'projectId', 'companies', 'startDate', 'dayWidth', 'index', 'type'],
  data() {
    return {
      showModal: false,
      french: fr,
      saveDateEdit: undefined,

      labels: {
        INACTIVE: null,
        DONE: null,
        TODO: 'à faire',
        LATE: 'retard',
        CRITICAL: 'urgent',
        TO_CONFIRM: 'à confirmer',
      },

      active: false,

      filteredCompanies: [],
      types,
    };
  },
  created() {
  },
  watch: {
  },
  methods: {
    getStepDuration() {
      return this.$dayjs(this.projectStep.closedAt).diff(this.$dayjs(this.projectStep.startedAt), 'day') + 1;
    },
    getStatus() {
      if (this.projectStep.startStatus === 'TODO' || this.projectStep.closeStatus === 'TODO') {
        return 'bg-todo';
      } if (this.projectStep.startStatus === 'IN_PROGRESS') {
        return 'bg-done';
      } if (this.projectStep.startStatus === 'LATE' || this.projectStep.startStatus === 'LATE') {
        return 'bg-late';
      } if (this.projectStep.startStatus === 'DONE') {
        return 'bg-done';
      }
      return '';
    },
    stateColor(status, type) {
      if (status === 'TODO') {
        return 'bg-todo';
      } if ((status === 'IN_PROGRESS' && type === 'start') || status === 'DONE') {
        return 'bg-done';
      } if (status === 'LATE') {
        return 'bg-late';
      }
      return 'bg-pending';
    },
    filterShowToConfirm() {
      // On calcule la date correspondant au début de la tâche moins la période de préférence de l'entreprise
      const companyPeriodPreferenceWeekAgo = this.$dayjs(this.projectStep.startedAt).subtract(this.projectStep.company.periodPreference, 'week');

      // On vérifie si on se trouve entre le début de la tache et la date calculé précédemment
      const isBetween = this.$dayjs().isBetween(companyPeriodPreferenceWeekAgo, this.projectStep.startedAt, null, '[]');

      // On vérifie si la date de début de la tâche a été dépassé
      const isAfter = this.$dayjs().isAfter(this.projectStep.startedAt);

      // On réunit les valeurs calculés afin de déterminer si on affiche ou non le label "À confirmer"
      return !!((isBetween || isAfter) && this.projectStep.company.companyId && this.projectStep.showToConfirm && !this.projectStep.confirmed);
    },
  },
};
</script>

<style lang="scss">
  .schedulelist .container.task-pdf {
    display: contents;
  }
  .schedulelist .task-pdf {
    border-bottom: 1px solid $gray-light;
    position: relative;
    width: 100%;
    min-width: 700px;
  }
  .task-pdf {
    width: 100%;
    .h-bg-gray-regular {
      cursor: auto !important;
      .icon-planning_stop{
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 11px;
          width: 6px;
          height: 12px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
    .task-definition {
      max-width: 350px;
      overflow: hidden;
      p:not(.table-header) {
        margin: 0;
        font-weight: 500;
      }
      .task-name {
        color: $gray-dark!important;
      }
      .company-name {
        color: $blue-medium!important;
        font-weight: 500;
      }
      .confirmed, .unconfirmed {
        font-weight: 500;
        text-transform: uppercase;
      }
      .confirmed {
        color: $blue-medium!important;
      }
      .unconfirmed {
        color: $orange!important;
      }
      .event {
        color: $event!important;
      }
    }
    .task-detail, .table-header{
      width: 100%;
      position: absolute;
      height: 100%;
      > div {
        > div:not(.task-state), button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
          height: 100%;
        }
        .task-state {
          padding: 5px 7px;
          border-radius: 30px;
          text-transform: uppercase;
          margin-right: 10px;
          display: flex;
          align-items: center;
          span {
            color: white !important;
            font-weight: 500;
          }
        }
        .task-duration {
          width: 200px;
          color: $gray-light;
        }
        .week {
          display: inline-block;
          min-width: 60px;
        }
        .icon {
          width: 50px;
          span, p:not(.column-header) {
            color: $gray-dark;
          }
          span {
            padding-right: 10px !important;
            align-self: start;
            color: $blue-light;
            opacity: 0.5;
          }
        }
      }
    }
    .column-header {
      margin-bottom: 5px;
      text-transform: uppercase;
      color: $label;
    }
    .table-header {
      padding-bottom: 10px;
    }
    .task-detail {
      position: relative!important;
      .task-state-column {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end!important;
        .task-state-container {
          margin: 3px 0;
        }
      }
      > div {
        > div:not(.task-state-column) {
          border-left: 1px solid $gray-light;
          padding: 15px 0;
        }
      }
    }
    .date {
      text-align: left;
      width: 100%;
      padding: 0px 10px 0px 10px;
    }
    .date-col {
      width: 145px;
    }
    &.comment {
      p {
        padding: 20px;
        color: $gray-regular;
      }
    }
  }

</style>
