import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des customerContactLog d'un customer
 * @param {string} customerId: l'id du customer
 */
const getAll = async (customerId) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/${customerId}/customer-contact-log`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'un nouveau customerContactLog
 * @param {object} body: body du CustomerContactLog à créer
 */
const create = async (body) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/customer-contact-log`, body);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const customerContactLog = {};
customerContactLog.getAll = getAll;
customerContactLog.create = create;
export default customerContactLog;
