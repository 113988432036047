import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère toutes les variables
 * @param {string} search: terme de recherche
 * @param {string} unit: unité
 * @param {boolean || string} isReadonly: lecteur seulement
 */
const getAll = async (search, unit, isReadonly) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/variables`, {
      params: {
        ...(search && { search }),
        ...(unit && { unit }),
        ...((isReadonly || isReadonly === false) && { isReadonly }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Créer une variable
 * @param {object} data: données de la variable à créer
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/variables/`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Met à jour une variable
 * @param {string} variableId: id de la variable à mettre à jour
 * @param {object} data: données de la variable
 */
const update = async (variableId, data) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/variables/${variableId}`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Supprime une variable
 * @param {string} variableId: id de la variable à supprimer
 */
const deleteOne = async (variableId) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/variables/${variableId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const variable = {};
variable.getAll = getAll;
variable.create = create;
variable.update = update;
variable.delete = deleteOne;

export default variable;
