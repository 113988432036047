<template>
  <div class="company-allotments hedifys-21">
    <!-- BREADCRUMBS -->
    <div class="breadcrumbs grid-x row">
      <div class="cell auto">
        <breadcrumb
          v-if="company && company.companyId && company.name"
          :items="[
            { route: { name: 'companies'}, name: 'Partenaires'},
            { route: { name: 'companies-companyId', params: { companyId: $route.params.companyId }}, name: company.name}
          ]"
        />
      </div>
    </div>
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <div>
          <h1>{{ !isLoading && $route.params.companyId && company ? company.name : ' ' }}</h1>
        </div>
      </div>
    </div>
    <!-- TABS -->
    <tab-nav v-if="$route.params.companyId" :tabs="tabs" />
    <!-- BODY / FORMULAIRE -->
    <div class="body">
      <div class="search grid-x">
        <div class="cell shrink">
          <app-search size="small" v-model="search" placeholder="Rechercher un lotissement" />
        </div>
        <div class="cell auto"></div>
      </div>
      <app-table :headers="tableHeaders" :data="allotments ? allotments.data : []" :loading="isLoading" @line-cliked="goToAllotment" clickable>
        <template slot="loading">
          <app-spinner />
        </template>
        <template slot="empty-table">
          <p>Aucune données de disponible</p>
        </template>
        <template slot="name" slot-scope="{ data }">
          <strong>{{ data.name }}</strong>
        </template>
        <template slot="landsCount" slot-scope="{ data }">
          <p>{{ `${data.activeLandsCount} / ${data.landsCount}`}}</p>
        </template>
        <template slot="postalCode" slot-scope="{ data }">
          <p>{{ data.address && data.address.postalCodeInseeNumber ? data.address.postalCodeInseeNumber.split('-')[0] : null }}</p>
        </template>
        <template slot="city" slot-scope="{ data }">
          <p>{{ data.address.city }}</p>
        </template>
        <template slot="agency" slot-scope="{ data }">
          <p>{{ data.agency && data.agency.agencyId ? data.agency.name : '-' }}</p>
        </template>
        <template slot="createdAt" slot-scope="{ data }">
          <p>{{ $dayjs(data.createdAt).format('DD/MM/YYYY') }}</p>
        </template>
      </app-table>
      <app-pagination
        v-if="allotments && allotments.metadata"
        :limit="limit"
        :offset="allotments.metadata.offset"
        :count="allotments.metadata.count"
      />
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import companyApi from '@/services/api/company';
import allotmentApi from '@/services/api/allotment';
import companyCategory from '@/services/api/companyCategory';
import utils from '@/services/utils/utils';

export default {
  name: 'company-allotments',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.company ? `${this.company.name} –  Partenaire` : 'Partenaire',
    };
  },
  data() {
    return {
      // New data
      isLoading: false,
      company: null,
      allotments: null,
      limit: 10,
      tableHeaders: [
        { label: 'Nom du lotissement', key: 'name', size: 2 },
        { label: 'Lots actifs', key: 'landsCount', size: 2 },
        { label: 'Code postal', key: 'postalCode', size: 2 },
        { label: 'Ville', key: 'city', size: 2 },
        { label: 'Agence', key: 'agency', size: 2 },
        { label: 'Créée le', key: 'createdAt', size: 2 },
      ],
      search: null,
    };
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  async mounted() {
    this.isLoading = true;
    try {
      // récupération des métiers
      const companyCategories = await companyCategory.getAll();

      if (companyCategories && companyCategories.length > 0) {
        this.companyCategories = companyCategories.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.companyCategories = [];
      }
    } catch (er) {
      this.$message.show({
        title: 'Erreur',
        text: 'Il y a eu un problème lors du chargement des métiers',
        cancelText: 'Ok',
        hasCancel: true,
      });
    }
    this.search = this.$route.query.search;
    await this.getCompany();
    await this.getAllotments(this.computeOffset);
    this.isLoading = false;
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    tabs() {
      let tabs = [
        {
          route: {
            name: 'companies-companyId',
            params: { companyId: null },
          },
          label: 'Informations',
        },
      ];
      if (this.company && this.company.companyCategories && this.company.companyCategories.length && this.companyCategories && this.companyCategories.length) {
        const isSupplierOrContractor = this.companyCategories.filter(
          (category) => (category.type === 'SUPPLIER' || category.type === 'SUBCONTRACTOR')
            && this.company.companyCategories.includes(category.companyCategoryId),
        ).length > 0;
        if (isSupplierOrContractor) {
          tabs = tabs.concat([
            {
              route: {
                name: 'companies-companyId-projects',
                params: { companyId: null },
              },
              label: 'Chantiers',
            },
            {
              route: {
                name: 'companies-companyId-schedule',
                params: { companyId: null },
              },
              label: 'Disponibilités',
            },
          ]);
        }

        const isDeveloper = this.companyCategories.filter(
          (category) => category.type === 'DEVELOPER'
            && this.company.companyCategories.includes(category.companyCategoryId),
        ).length > 0;
        if (isDeveloper) {
          tabs = tabs.concat([
            {
              route: {
                name: 'companies-companyId-allotments',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Lotissements',
            },
            {
              route: {
                name: 'companies-companyId-lands',
                params: { companyId: this.$route.params.companyId },
              },
              label: 'Terrains',
            },
          ]);
        }
      }

      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { companyId: this.$route.params.companyId },
        },
        label: el.label,
      }));
    },
  },
  watch: {
    $route() {
      if (!this.isLoading) {
        this.getAllotments(this.computeOffset);
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
  },
  methods: {
    goToAllotment(allotment) {
      this.$router.push({ name: 'lands-allotments-allotmentId', params: { allotmentId: allotment.allotmentId } });
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
    async getAllotments(offset) {
      try {
        this.allotments = await allotmentApi.getAll(this.limit, offset, this.search, this.$route.params.companyId);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lotissement',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCompany() {
      if (this.$route.params.companyId) {
        try {
          this.company = await companyApi.getById(this.$route.params.companyId);
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors du chargement du partenaire',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
    },
  },
};
</script>

<!-- New style -->
<style lang='sass' scoped>
.company-allotments
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .save
    margin-left: 16px
  .breadcrumbs
    @include breadcrumbs
  h3
    color: $primary
    margin-bottom: 10px
  .row
    @include row
  .search
    padding-bottom: 1rem
</style>
