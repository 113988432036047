<template>
  <div class="allotment hedifys-21">
    <form ref="allotment" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="allotment && allotment.allotmentId && allotment.name"
            :items="[
              { route: { name: 'lands-allotments'}, name: 'Lotissements'},
              { route: { name: 'lands-allotments-allotmentId', params: { allotmentId: $route.params.allotmentId }}, name: allotment.name}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <div v-if="!isLoading">
            <h1 v-if="$route.params.allotmentId">{{ allotment.name }}</h1>
            <h1 v-else>Ajouter un lotissement</h1>
          </div>
        </div>
        <div class="cell shrink" v-if="$route.params.allotmentId">
          <app-button theme="warning" size="large" icon="remove" @click="openDeleteModal">Supprimer</app-button>
        </div>
        <div class="cell shrink save">
          <app-button v-if="$route.params.allotmentId" theme="primary" size="large" type="submit">Sauvegarder</app-button>
          <app-button v-else theme="primary" icon="add" size="large" type="submit">Ajouter</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav v-if="$route.params.allotmentId" :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading">
        <div class="card">
          <h3>Informations générales</h3>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="allotment.name" label="Nom du lotissement" placeholder="Nom du lotissement..." required />
            </div>
            <div class="cell auto">
              <app-select
                label="Lotisseur"
                :options="companyOptions"
                v-model="allotment.company.companyId"
              />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-label>PDF présentation du lotissement</app-label>
              <app-input-file accept="application/pdf" :filename="filename" :url="allotment.presentationPdf" @upload="uploadFile" @remove="removeFile" />
              <p class="max-size-pdf">Taille maximale : 6 Mo</p>
            </div>
            <div class="cell auto">
              <app-input v-model.number="allotment.separationDistance" type="number" size="small" label="Distance de mitoyenneté (m)" placeholder="m..." required />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell small-6 align-self-bottom">
              <app-input
                label="Frais d'adaptation au terrain"
                placeholder="Prix"
                v-model.number="allotment.priceLandAdaptation"
                type="number"
                :step="0.01"
                required
              />
            </div>
            <div class="cell small-6">
              <app-input
                label="Frais règlementation locale"
                placeholder="Prix"
                v-model.number="allotment.priceLocalRegulation"
                type="number"
                :step="0.01"
                required
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="grid-x">
            <div class="cell auto">
              <h3>Adresse</h3>
            </div>
            <div class="cell auto">
              <h3>Description</h3>
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell auto">
              <app-input v-model="allotment.address.addressLine1" label="Adresse" placeholder="Adresse..." />
            </div>
            <div class="cell auto">
              <app-textarea
                label="Notes"
                placeholder="Décrivez le lotissement en quelques mots..."
                v-model="allotment.description"
              />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell small-6">
              <app-input v-model="allotment.address.addressLine2" label="Adresse ligne 2 (complément)" placeholder="Adresse ligne 2 ..." />
            </div>
            <div class="cell auto">
              <app-textarea
                label="Descriptif commercial"
                placeholder="Description utilisée dans le template des petites annonces."
                v-model="allotment.commercialDescription"
              />
            </div>
          </div>
          <div class="grid-x row grid-margin-x">
            <div class="cell small-6">
              <app-search-city
                label="Code postal"
                placeholder="Nantes - 44000"
                @select="setSelectedPostalCode"
                @clear="resetSelectedPostalCode"
                :selectedPostalCode="selectedPostalCode"
                required
              />
            </div>
          </div>
        </div>
        <div class="grid-x">
          <div class="card card-divider cell auto">
            <h3>Styles</h3>
            <div class="grid-x row grid-margin-x">
              <div class="cell auto checkbox-container">
                <p>Les styles suivants s’appliquent sur ce lotissement, vous pouvez cocher/décocher pour activer ou désactiver les styles ce terrain.</p>
                <div v-if="allotment.address.postalCodeInseeNumber" class="checkbox-container checkbox-grid-style">
                  <div v-for="style in styles" :key="style.styleId">
                    <app-checkbox :value="style.styleId" v-model="allotment.excludedStyles" reverse>{{ style.name }}</app-checkbox>
                  </div>
                </div>
                <div v-else class="heading">
                  <p>Saisissez le code postal du lotissement avant de pouvoir faire la sélection des styles.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card cell auto">
            <h3>Règles</h3>
            <div class="grid-x row grid-margin-x">
              <div class="cell auto checkbox-container">
                <p>Les règles suivantes s’appliquent sur ce lotissement, vous pouvez cocher/décocher pour activer ou désactiver les règles ce terrain.</p>
                <div v-if="allotment.address.postalCodeInseeNumber" class="checkbox-container checkbox-grid-regulation">
                  <div v-for="regulation in regulations" :key="regulation.regulationId">
                    <app-checkbox :value="regulation.regulationId" v-model="allotment.excludedRegulations" reverse>{{ regulation.name }}</app-checkbox>
                  </div>
                </div>
                <div v-else class="heading">
                  <p>Saisissez le code postal du lotissement avant de pouvoir faire la sélection des règles.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import allotmentApi from '@/services/api/allotment';
import postalCodeApi from '@/services/api/postalCode';
import styleApi from '@/services/api/style';
import regulationApi from '@/services/api/regulation';
import companyApi from '@/services/api/company';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import utils from '@/services/utils/utils';
import AppSearchCity from '@/basics/components/AppSearchCity.vue';
import auth from '@/services/auth';
import memberApi from '@/services/api/member';
import TabNav from '@/components/layouts/TabNav.vue';

export default {
  name: 'allotment',
  components: {
    Breadcrumb,
    AppSearchCity,
    TabNav,
  },
  metaInfo() {
    return {
      title: this.allotment.name ? `${this.allotment.name} – Lotissement` : 'Lotissement',
    };
  },
  data() {
    return {
      allotment: {
        name: null,
        company: {
          companyId: null,
        },
        address: {
          addressLine1: null,
          addressLine2: null,
          postalCodeInseeNumber: null,
        },
        description: null,
        commercialDescription: null,
        separationDistance: null,
        excludedStyles: [],
        excludedRegulations: [],
      },
      selectedPostalCode: null,
      isLoading: null,
      company: [],
      companyOptions: [],
      styles: [],
      regulations: [],
      file: null,
      filename: null,
      brand: null,
      member: null,
      tabs: [
        {
          route: {
            name: 'lands-allotments-allotmentId',
            params: {
              allotmentId: this.$route.params.allotmentId,
            },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'lands-allotments-allotmentId-lands',
            params: {
              allotmentId: this.$route.params.allotmentId,
            },
          },
          label: 'Terrains',
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getMe();
    await this.getData();
    this.isLoading = false;
  },
  watch: {
    async selectedPostalCode() {
      await this.getStyles();
      await this.getRegulations();
    },
  },
  methods: {
    async uploadFile(event) {
      const file = new FormData();
      file.append('file', event);
      this.file = file;
    },
    removeFile() {
      this.file = null;
      this.allotment.presentationPdf = null;
      this.filename = null;
    },
    async getData() {
      await this.getBrand();
      await this.getAllotment();
      await this.getCompany();
      this.companyOptions = utils.formatOptions(
        this.company,
        (option) => option.companyId,
        (option) => option.name,
        'choose',
      );
      await this.getStyles();
      await this.getRegulations();
    },
    // Récupération de la marque
    async getBrand() {
      try {
        this.brand = await auth.getBrand();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération de la marque.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getAllotment() {
      try {
        if (this.$route.params.allotmentId) {
          this.allotment = await allotmentApi.getById(this.$route.params.allotmentId);

          if (this.allotment) {
            this.allotment.priceLandAdaptation = utils.formatCentToEuro(this.allotment.priceLandAdaptation);
            this.allotment.priceLocalRegulation = utils.formatCentToEuro(this.allotment.priceLocalRegulation);
            this.allotment.separationDistance = this.allotment.separationDistance || this.allotment.separationDistance === 0
              ? this.allotment.separationDistance : this.brand.defaultSeparationDistance;

            // on récupére la valeur de la mitoyenneté dans la marque, s'il n'y en a pas
            this.getPostalCode(this.allotment.address.postalCodeInseeNumber);
            if (!this.allotment.excludedStyles) {
              this.allotment.excludedStyles = [];
            }
            if (!this.allotment.excludedRegulations) {
              this.allotment.excludedRegulations = [];
            }
            if (this.allotment.presentationPdf) {
              this.filename = this.allotment.presentationPdf.substring(this.allotment.presentationPdf.lastIndexOf('-') + 1, this.allotment.presentationPdf.lastIndexOf('?'));
            }
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: `Il y a eu un problème lors de la récupération du lotissement. Erreur : ${er?.response.data.message || ''}`,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCompany() {
      try {
        const type = 'DEVELOPER';
        const companies = await companyApi.getAll(type);
        if (this.member.isBrandAdmin) {
          this.company = companies.data;
        }
        if (this.member.isAgencyAdmin) {
          this.company = companies.data.filter((c) => c.isAgencyRegistered);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des lotisseurs',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getMe() {
      try {
        this.member = await memberApi.getMe();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getStyles() {
      try {
        if (this.allotment.address.postalCodeInseeNumber) {
          this.styles = await styleApi.getAll(this.allotment.address.postalCodeInseeNumber);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des styles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getRegulations() {
      try {
        if (this.allotment.address.postalCodeInseeNumber) {
          this.regulations = await regulationApi.getAll(this.allotment.address.postalCodeInseeNumber);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des règles',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getPostalCode(postalCodeInseeNumber) {
      try {
        const postalCode = await postalCodeApi.getOne(postalCodeInseeNumber);
        this.selectedPostalCode = `${postalCode.city} - ${postalCode.postalCodeInseeNumber.split('-')[0]}`;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du code postal',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.$route.params.allotmentId) {
          const allotmentObj = {
            companyId: this.allotment.company.companyId,
            name: this.allotment.name,
            address: this.allotment.address,
            postalCodeInseeNumber: this.allotment.address.postalCodeInseeNumber,
            description: this.allotment.description,
            commercialDescription: this.allotment.commercialDescription,
            priceLandAdaptation: utils.formatEuroToCent(this.allotment.priceLandAdaptation),
            priceLocalRegulation: utils.formatEuroToCent(this.allotment.priceLocalRegulation),
            separationDistance: parseInt(this.allotment.separationDistance, 10),
            excludedStyles: this.allotment.excludedStyles,
            excludedRegulations: this.allotment.excludedRegulations,
          };
          await allotmentApi.update(this.$route.params.allotmentId, allotmentObj);
          await allotmentApi.upload(this.$route.params.allotmentId, this.file);
          this.$notification.show({ text: 'Ce lotissement a été mis à jour avec succès !' });
        } else {
          let allotmentObj = {
            ...this.allotment,
            priceLandAdaptation: utils.formatEuroToCent(this.allotment.priceLandAdaptation),
            priceLocalRegulation: utils.formatEuroToCent(this.allotment.priceLocalRegulation),
            postalCodeInseeNumber: this.allotment.address.postalCodeInseeNumber,
          };
          delete allotmentObj.company;
          delete allotmentObj.address.postalCodeInseeNumber;
          allotmentObj = { ...allotmentObj, companyId: this.allotment.company.companyId };
          const allotment = await allotmentApi.create(allotmentObj);
          await allotmentApi.upload(allotment.allotmentId, this.file);
          this.$router.push({ name: 'lands-allotments-allotmentId', params: { allotmentId: allotment.allotmentId } });
          this.$notification.show({ text: 'Ce lotissement a été créé avec succès !' });
        }

        this.getData();
      } catch (er) {
        if (!this.$route.params.allotmentId) {
          this.allotment = {
            name: null,
            company: {
              companyId: null,
            },
            address: {
              addressLine1: null,
              addressLine2: null,
              postalCodeInseeNumber: null,
            },
            description: null,
            commercialDescription: null,
            separationDistance: null,
            excludedStyles: [],
            excludedRegulations: [],
          };
          this.selectedPostalCode = null;
          this.file = null;
          this.filename = null;
        }
        this.$message.show({
          title: 'Erreur',
          text: `Il y a eu un problème lors de la sauvegarde du lotissement. Erreur : ${er.response.data.message}`,
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    resetSelectedPostalCode() {
      this.selectedPostalCode = null;
      this.allotment.address.postalCodeInseeNumber = null;
    },
    setSelectedPostalCode(value) {
      this.selectedPostalCode = value.label;
      this.allotment.address.postalCodeInseeNumber = value.name;
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer le lotissement',
        text: 'Êtes vous sûr de vouloir supprimer ce lotissement ?',
        confirmText: 'Supprimer le lotissement',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteAllotment();
        },
      });
    },
    async deleteAllotment() {
      if (this.$route.params.allotmentId) {
        this.isLoading = true;
        try {
          await allotmentApi.delete(this.$route.params.allotmentId);
          this.$notification.show({ text: 'Ce lotissement a été supprimé avec succès !' });

          this.$router.push({ name: 'lands-allotments' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression du lotissement',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.allotment
  .body
    @include screen
    .options
      min-height: 200px
      .data
        flex: auto
  form > .header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .row-margin
    margin: 0
  .max-size-pdf
    margin-top: 4px
    font-size: $font-xs !important
    line-height: 12px
    color: $label !important
  .card-divider
    margin-right: 30px
  .card
    @include card
    p
      @include details
      color: $label
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
  .checkbox-container
    > div
      margin-top: 24px
    .heading
      p
        @mixin paragraph
        color: $info
        text-align: center

  .checkbox-grid-style
    display: grid
    grid-template-columns: repeat(3, 1fr)
    > div:nth-child(-n+3)
      margin-top: 0

  .checkbox-grid-regulation
    display: grid
    grid-template-columns: repeat(2, 1fr)
    > div:nth-child(-n+2)
      margin-top: 0
</style>
